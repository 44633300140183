import { Layout } from 'components/Layout';
import { useMatchMedia } from 'hooks/useMatchMedia';
import React from 'react';
import { Col, Row } from 'reactstrap';

export const LayoutDetailPages = ({
  layoutConfig,
  navBarContent,
  children,
}) => {
  const { isMobile } = useMatchMedia();

  return (
    <Layout {...layoutConfig}>
      <Row>
        {!isMobile && <Col xs={3}>{navBarContent}</Col>}
        <Col xs={isMobile ? 12 : 9} className={!isMobile && 'ps-4'}>
          {children}
        </Col>
      </Row>
    </Layout>
  );
};

import React from 'react';
import { CardTitle, ShadowCard } from 'components/Common';
import { ClientEditContacts } from './ClientEditContacts';

export const ClientContactsCard = () => {
  return (
    <ShadowCard className="mt-4" id="contacts" bodyStyle={{ padding: '24px' }}>
      <CardTitle title="client.contacts" />

      <ClientEditContacts />
    </ShadowCard>
  );
};

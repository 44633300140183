import { SCROLL_STYLE } from 'common/data/constantsStyle';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { getUserId, maxUploadSize } from 'helpers/jwt_helper';
import { showToastError } from 'helpers/utils/toast';
import { useModal } from 'hooks/useModal';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { addMessage } from 'store/actions';
import styled from 'styled-components';
import { getCurrentChatState } from './ChatRoomCard';

const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledPaperclip = styled(StyledEmptyButton)`
  i {
    font-size: 24px;
    left: 0px;
    color: ${({ theme }) => theme.colors.gray600};
    position: absolute;
    box-sizing: border-box;
    top: 10%;
    transform: rotate(-45deg);
  }
`;

const StyledMessageInput = styled(Input)`
  ${SCROLL_STYLE}

  && {
    resize: none;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
  }
`;

const StyledFileCount = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  font-size: 10px;
  top: 0%;
  left: 18px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.danger};
`;

const ERROR_TEXT_MAX_SIZE_FILE = `The file must not exceed ${
  maxUploadSize() / (1024 * 1024)
} MB`;

export const ACCEPT_FILE_TYPES = [
  '.jpg',
  '.jpeg',
  '.png',
  '.tiff',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.odt',
  '.eml',
  '.msg',
];

export const ChatMessageBox = ({ disabled }) => {
  const dispatch = useDispatch();
  const { detail } = useSelector(getCurrentChatState);
  const { isOpen, handleCloseModal, handleOpenModal } = useModal();

  const [message, setMessage] = useState({
    text: '',
    files: [],
  });

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxSize: maxUploadSize(),
    accept: ACCEPT_FILE_TYPES,
    onDrop: (_acceptedFiles, fileRejections) => {
      fileRejections.forEach(file => {
        file.errors.forEach(error => {
          if (error.code === 'file-too-large') {
            showToastError(ERROR_TEXT_MAX_SIZE_FILE);
          }
        });
      });
    },
  });

  useEffect(() => {
    setMessage(prev => ({ ...prev, files: acceptedFiles }));
  }, [acceptedFiles]);

  const handleSendMessage = event => {
    event?.preventDefault();

    try {
      const formData = new FormData();
      if (message.text.trim().length > 0) {
        formData.append('message', message.text);
      }

      if (message.files.length > 0) {
        message.files.forEach(file => {
          formData.append('documents', file);
        });
      }

      if (detail.user.id) {
        formData.append('user', detail.user.id);
      }

      dispatch(addMessage({ formData }));
      setMessage({ text: '', files: [] });
    } catch (error) {
      console.error('error', error);
    } finally {
      handleCloseModal();
    }
  };

  const isCurrentOperator = useMemo(
    () => detail?.last_operator?.id === getUserId(),
    [detail?.last_operator?.id, getUserId],
  );

  return (
    <>
      {isOpen && (
        <ModalConfirmDelete
          isOpenModal={isOpen}
          textHeader="chat.sendMessage"
          textBody="chat.confirmText"
          handleCloseModal={handleCloseModal}
          buttonText="chat.confirm"
          handleDelete={handleSendMessage}
        />
      )}

      <form
        className="d-flex justify-content-between"
        onSubmit={isCurrentOperator ? handleSendMessage : handleOpenModal}
      >
        <StyledInputWrapper className="w-100 d-flex" {...getRootProps()}>
          <input {...getInputProps()} />

          <StyledPaperclip onClick={open}>
            {message.files.length > 0 && (
              <StyledFileCount className="d-flex align-items-center justify-content-center text-white">
                {message.files.length}
              </StyledFileCount>
            )}

            <i className="bx bx-paperclip" />
          </StyledPaperclip>

          <StyledMessageInput
            type="textarea"
            value={message.text}
            onKeyDown={event => {
              if (event.ctrlKey && event.keyCode === 13) {
                setMessage(prev => ({
                  ...prev,
                  text: (prev.text += '\r\n'),
                }));
              } else if (!event.ctrlKey && event.keyCode === 13) {
                event.preventDefault();
                isCurrentOperator ? handleSendMessage() : handleOpenModal();
              }
            }}
            onChange={event =>
              setMessage(prev => ({ ...prev, text: event.target.value }))
            }
            style={{ borderRadius: 0, paddingLeft: 35 }}
            className="border-0 w-100 h-100 border-bottom"
            placeholder="Write a message"
          />
        </StyledInputWrapper>

        <Button
          disabled={
            disabled ||
            (message.files.length === 0 && message.text.trim().length === 0)
          }
          className="d-flex align-items-center justify-content-center ms-4"
          style={{ borderRadius: 40, padding: '13px 40px' }}
          color="primary"
          onKeyPress={event => {
            if (event.code === 13) {
              detail?.last_operator?.id === getUserId()
                ? handleSendMessage()
                : handleOpenModal();
            }
          }}
          onClick={
            detail?.last_operator?.id === getUserId()
              ? handleSendMessage
              : handleOpenModal
          }
        >
          <i className="bx bx-paper-plane fs-5" />
        </Button>
      </form>
    </>
  );
};

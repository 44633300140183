import { Accordion, FormikInput, StyledField } from 'components/Common';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';

const StyledHelpText = styled(Label)`
  font-size: 12px;
  font-weight: 100;
  color: red;
`;

const formFields = [
  {
    type: 'text',
    name: 'deliveryName',
    key: 3.1,
    label: 'client.recipientName',
    placeholder: 'client.recipientNamePlaceholder',
    maxLength: 50,
  },

  {
    type: 'text',
    name: 'addressLineFirst',
    key: 3.2,
    label: 'client.addressLineFirst',
    placeholder: 'client.addressLineFirstPlaceholder',
  },

  {
    type: 'text',
    name: 'city',
    key: 3.3,
    label: 'client.city',
    placeholder: 'client.cityPlaceholder',
  },

  {
    type: 'text',
    name: 'state',
    key: 3.4,
    label: 'client.state',
    placeholder: 'client.statePlaceholder',
  },

  {
    type: 'text',
    name: 'postalCode',
    key: 3.5,
    label: 'client.zipCode',
    placeholder: 'client.zipCodePlaceholder',
    maxLength: 15,
  },
];

// const getWeight = index => {
//   if (index === 0 || index === 1) {
//     return 6;
//   }

//   return 12;
// };

export const ApplicantDeliveryForm = ({
  indexApplicant,
  currentApplicant,
  disabled,
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <StyledField className="w-100 mt-4 p-4">
      <Accordion title="order.enterAddress">
        <div className="d-flex flex-wrap">
          <Col xs={12} className="mt-4">
            <FormikInput
              label="delivery.mailAllowed"
              name="mailBox"
              errors={
                formik.errors.applicants?.[indexApplicant]?.address?.mailBox
              }
              customInput={
                <div className="d-flex">
                  <FormGroup check>
                    <Label check className="display-5">
                      <Input
                        disabled={disabled}
                        onChange={() => {
                          formik.setFieldValue(
                            `applicants[${indexApplicant}].address.mailBox`,
                            'yes',
                          );
                        }}
                        checked={currentApplicant?.address?.mailBox === 'yes'}
                        type="radio"
                      />
                      {t('order.yes')}
                    </Label>
                  </FormGroup>

                  <FormGroup className="ms-4" check>
                    <Label check className="display-5">
                      <Input
                        disabled={disabled}
                        onChange={() => {
                          formik.setFieldValue(
                            `applicants[${indexApplicant}].address.mailBox`,
                            'no',
                          );
                        }}
                        type="radio"
                        checked={currentApplicant?.address?.mailBox === 'no'}
                      />
                      {t('visa.no')}
                    </Label>
                  </FormGroup>
                </div>
              }
            />
          </Col>

          {formFields.map(field => (
            <Col
              key={field.id}
              xs={12}
              // lg={getWeight(index)}
            >
              <FormikInput
                name={`applicants[${indexApplicant}].address.${field.name}`}
                disabled={disabled}
                value={
                  formik.values.applicants?.[indexApplicant]?.address?.[
                    field.name
                  ]
                }
                label={field.label}
                placeholder={field.placeholder}
                handleChange={formik.handleChange}
                errors={
                  formik.errors.applicants?.[indexApplicant]?.address?.[
                    field.name
                  ]
                }
              />
              {formik.errors?.applicants?.[indexApplicant]?.address?.[
                field.name
              ] && (
                <StyledHelpText>
                  {
                    formik.errors?.applicants?.[indexApplicant]?.address?.[
                      field.name
                    ]
                  }
                </StyledHelpText>
              )}
            </Col>
          ))}

          <Col xs={12}>
            <FormikInput
              label="common.country"
              name={`applicants[${indexApplicant}].address.country`}
              errors={
                formik.errors.applicants?.[indexApplicant]?.address?.country
              }
              customInput={
                <SelectCountry
                  isDisabled={disabled}
                  isAvailableDelivery
                  invalid={
                    formik.errors.applicants?.[indexApplicant]?.address?.country
                  }
                  onChange={country =>
                    formik.setFieldValue(
                      `applicants[${indexApplicant}].address.country`,
                      country?.value,
                    )
                  }
                  value={
                    formik.values.applicants?.[indexApplicant]?.address?.country
                  }
                  name="country"
                />
              }
            />
          </Col>
        </div>
      </Accordion>
    </StyledField>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import styled from 'styled-components';

export const StyledButtonCreate = styled(Button)`
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  i {
    margin-right: 8px;
    font-size: 20px;
  }
`;

export const ButtonCreate = ({ handleCreate, text = 'common.create' }) => {
  const { t } = useTranslation();

  return (
    <StyledButtonCreate onClick={handleCreate} color="primary">
      <i className="bx bx-plus" />

      {t(text)}
    </StyledButtonCreate>
  );
};

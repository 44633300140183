import React, { useMemo } from 'react';
import { useEffect } from 'react';
import { Select } from 'components/Common/Select';
import { convertVisaOptions } from './utils';
import { isNil } from 'lodash';
import { getVisaTypes as getVisaTypesAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const getVisaTypesState = state => state.references.visaTypes;

export const VisaTypeSelector = ({
  value,
  onChange,
  error,
  isDisabled,
  country,
  applicantNationality,
  visasTypes,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const visaTypes = useSelector(getVisaTypesState);

  useEffect(() => {
    if (!visasTypes) {
      dispatch(
        getVisaTypesAction({
          // valid_countries: country, // todo: delete valid_countries
          country: country,
          valid_nationalities: applicantNationality,
        }),
      );
    }
  }, [country, applicantNationality]);

  const allOptions = convertVisaOptions(visasTypes ?? visaTypes);

  const val = useMemo(() => {
    if (isNil(value)) {
      return value;
    }

    return allOptions.find(item => item.value === value) ?? null;
  }, [allOptions, value, i18n.language]);

  const options = allOptions.filter(option => option.is_active === true);

  return (
    <Select
      className="w-100"
      placeholder={t('order.visaCategoryPlaceholder')}
      value={val}
      isDisabled={isDisabled}
      isClearable
      onChange={onChange}
      options={options}
      classNamePrefix="select2-selection"
      invalid={Boolean(error)}
    />
  );
};

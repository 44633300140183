import {
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_DETAIL,
  GET_INVOICES,
} from './actionTypes';

const INIT_STATE = {
  invoices: [],
  invoiceDetail: {
    details: {},
    isLoading: true,
    error: {},
  },
  error: {},
  loading: false,
};

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        loading: true,
      };

    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
      };

    case GET_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          isLoading: true,
        },
      };

    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          details: action.payload,
          isLoading: false,
        },
      };

    case GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default Invoices;

import React from 'react';
import {
  getUserRole,
  isUserEmployee,
  isUserIndividual,
} from 'helpers/jwt_helper';

import {
  AggregatedInvoicesImage,
  ApplicantsImage,
  // ChatImage,
  ClientsImage,
  DeliversImage,
  // FeedImage,
  InvoiceImage,
  OrderImage,
  ServicesImage,
  TariffsImage,
  UsersImage,
  // WizardImage,
  DocumentsImage,
  TxtFileImage,
} from 'assets/images/visa';

const IS_ADMIN = getUserRole() === 'admin';
const IS_OPERATOR = getUserRole() === 'operator';
const IS_MANAGER = getUserRole() === 'manager';
const IS_USER = getUserRole() === 'user';

const OPERATOR_OR_MANAGER = IS_OPERATOR || IS_MANAGER;

export const menuItems = [
  {
    key: 'orders-nav',
    isVisible:
      OPERATOR_OR_MANAGER || IS_ADMIN || isUserEmployee || isUserIndividual,
    link: '/orders',
    title: 'sideBar.order',
    icon: <OrderImage />,
  },
  {
    key: 'invoices-nav',
    isVisible: IS_OPERATOR || IS_ADMIN || isUserIndividual,
    link: '/invoices',
    title: 'sideBar.invoices',
    icon: <InvoiceImage />,
  },
  {
    key: 'deliveries-nav',
    isVisible: IS_OPERATOR || IS_ADMIN,
    link: '/deliveries',
    title: 'sideBar.delivery',
    icon: <DeliversImage />,
  },
  {
    key: 'clients-nav',
    isVisible: IS_OPERATOR || IS_ADMIN,
    link: '/clients',
    title: 'sideBar.clients',
    icon: <ClientsImage />,
  },
  {
    key: 'services-nav',
    isVisible: IS_ADMIN,
    link: '/services',
    title: 'sideBar.services',
    icon: <ServicesImage />,
  },
  {
    key: 'users-nav',
    isVisible: IS_ADMIN,
    link: '/users',
    title: 'sideBar.users',
    icon: <UsersImage />,
  },
  {
    key: 'tariffs-nav',
    isVisible: IS_ADMIN,
    link: '/tariffs',
    title: 'sideBar.tariffs',
    icon: <TariffsImage />,
  },
  {
    key: 'aggregate-nav',
    isVisible: IS_ADMIN,
    link: '/reports',
    title: 'sideBar.reports',
    icon: <AggregatedInvoicesImage />,
  },
  {
    key: 'applicants-nav',
    isVisible: IS_MANAGER || isUserIndividual,
    link: '/applicants',
    title: 'sideBar.applicants',
    icon: <ApplicantsImage />,
  },
  {
    key: 'export-nav',
    isVisible: IS_ADMIN,
    link: '/export',
    title: 'common.export',
    icon: <TxtFileImage />,
  },
  {
    key: 'orderTypes-nav',
    isVisible: IS_ADMIN,
    link: '/orderTypes',
    title: 'orderTypes.orderTypes',
    icon: <TariffsImage />,
  },
  // {
  //   key: 'order-feed-nav',
  //   isVisible: IS_OPERATOR || IS_ADMIN_OPERATOR,
  //   link: '/order/feed',
  //   title: 'sideBar.ordersFeed',
  //   icon: <FeedImage />,
  //   beta: true,
  // },
  // {
  //   key: 'chat-nav',
  //   isVisible: IS_OPERATOR || IS_MANAGER || IS_ADMIN_OPERATOR,
  //   link: '/chats',
  //   title: 'sideBar.chats',
  //   icon: <ChatImage />,
  //   beta: IS_MANAGER,
  // },
  // {
  //   key: 'users-nav-manager',
  //   isVisible: IS_MANAGER,
  //   link: '/users',
  //   title: 'sideBar.users',
  //   icon: <UsersImage />,
  // },
  // {
  //   key: 'wizard-nav',
  //   isVisible: IS_OPERATOR || IS_ADMIN_OPERATOR,
  //   link: '/wizard/start',
  //   title: 'sideBar.wizard',
  //   icon: <WizardImage />,
  // },
  {
    key: 'user-orders-nav',
    isVisible: IS_USER,
    link: '/user/orders',
    title: 'sideBar.order',
    icon: <OrderImage />,
  },
  {
    key: 'current-user-nav',
    isVisible: isUserEmployee,
    link: '/applicant',
    title: 'sideBar.applicant',
    icon: <UsersImage />,
  },
  {
    key: 'documents-nav',
    isVisible: isUserEmployee,
    link: '/documents',
    title: 'common.documents',
    icon: <DocumentsImage />,
  },
];

import React from 'react';
import { Card, CardBody } from 'reactstrap';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  border: none;
  border-radius: ${({ theme }) => theme.radius.s};
  box-shadow: ${props => props.$boxShadow};
`;

export const ShadowCard = ({
  boxShadow = '0px 10px 20px rgba(18, 38, 63, 0.0313726)',
  bodyStyle = {},
  children,
  ...rest
}) => {
  return (
    <StyledCard $boxShadow={boxShadow} {...rest}>
      <CardBody style={bodyStyle}>{children}</CardBody>
    </StyledCard>
  );
};

import { Select } from 'components/Common';
import React, { useEffect, useMemo } from 'react';
import { operatorGetDeliveryMethods } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EMPTY_VALUE } from 'common/data/constants';

export const DeliveryMethodSelect = ({
  value,
  handleChange,
  invalid,
  ...props
}) => {
  const { t } = useTranslation();
  const deliveryMethods = useSelector(
    state => state.admin.delivery.methods?.data?.results,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(operatorGetDeliveryMethods());
  }, []);

  const convertedOptions = useMemo(() => {
    if (deliveryMethods && deliveryMethods.length > 0) {
      return deliveryMethods.map(option => ({
        value: option.method,
        label: option.method_display,
        item: option,
      }));
    }
  }, [deliveryMethods]);

  return (
    <Select
      placeholder={t('delivery.methodPlaceholder')}
      isClearable
      classNamePrefix="select2-selection"
      options={[EMPTY_VALUE, ...(convertedOptions ?? [])]}
      value={convertedOptions?.find(option => option?.value === value)}
      onChange={handleChange}
      invalid={invalid}
      {...props}
    />
  );
};

import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  closeOrderAlert,
  createOrder,
  fetchOrder,
  openOrderAlert,
  updateOrder,
} from './actions';

const initState = {
  order: {
    client: {},
    applicants: [],
  },
  isSubmitting: false,
  alert: {
    alertMessage: null,
    isOpen: false,
    type: null,
  },
  isLoading: false,
};

const order = createReducer(initState, {
  [closeOrderAlert]: state => ({
    ...state,
    alert: {
      alertMessage: null,
      isOpen: false,
      type: null,
    },
  }),

  [openOrderAlert]: (state, action) => ({
    ...state,
    alert: {
      alertMessage: action.payload.message,
      isOpen: true,
      type: action.payload.type,
    },
  }),

  [fetchOrder]: () => ({
    alert: {
      alertMessage: null,
      isOpen: false,
      type: null,
    },
    order: {
      client: {},
      applicants: [],
    },
    isLoading: true,
  }),
  [fetchOrder.success]: (state, action) => ({
    ...state,
    order: action.payload,
    isLoading: false,
  }),
  [fetchOrder.failure]: state => ({
    ...state,
    order: { client: {}, applicants: [] },
    isLoading: false,
  }),

  [createOrder]: state => ({
    ...state,
    isSubmitting: true,
  }),
  [createOrder.success]: (state, action) => ({
    ...state,
    order: action.payload,
    alert: {
      alertMessage: null,
      isOpen: false,
      type: null,
    },
    isSubmitting: false,
  }),

  [createOrder.failure]: state => ({
    ...state,
    isSubmitting: false,
  }),
  [updateOrder]: state => ({
    ...state,
    isSubmitting: true,
  }),
  [updateOrder.success]: (state, action) => ({
    ...state,
    order: action.payload,
    alert: {
      alertMessage: null,
      isOpen: false,
      type: null,
    },
    isSubmitting: false,
  }),
  [updateOrder.failure]: state => ({
    ...state,
    isSubmitting: false,
  }),
});

export default combineReducers({
  order,
});

import { addDays, isWeekend } from 'date-fns';
import { calculateDate } from 'helpers/api-requests/references';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';

export const DAY_OF_WEEK = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const compareDates = (firstDate, secondDate) => {
  const dateFirst = firstDate?.getTime();
  const dateSecond = secondDate?.getTime();

  if (!dateSecond) {
    return dateFirst;
  }

  if (!dateFirst) {
    return dateSecond;
  }

  if (dateFirst >= dateSecond) {
    return dateFirst;
  } else {
    return dateSecond;
  }
};

export const getWorkDays = date => {
  if (!isWeekend(date)) {
    return date ?? null;
  }

  const dayOfWeek = date.getDay();

  switch (dayOfWeek) {
    case DAY_OF_WEEK.sunday:
      return addDays(date, 1);
    case DAY_OF_WEEK.saturday:
      return addDays(date, 2);

    default:
      return date;
  }
};

const getEstimate = async serviceIds => {
  try {
    const response = await calculateDate(uniq(serviceIds));

    if (response?.success) {
      const dateFromApi = getWorkDays(new Date(response.data.date));

      return dateFromApi;
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const handleGetInitialEstimate = async (serviceIds = []) => {
  const estimateDate = await getEstimate(serviceIds);

  return estimateDate;
};

export const useDescribeToEstimate = applicantData => {
  const [minimalEstimate, setMinimalEstimate] = useState(null);

  const serviceIds = applicantData?.services.map(service => service.product_id);

  const preparedEstimate = getWorkDays(
    addDays(applicantData?.firstDateEntry, 1),
  );

  const initialEstimate = compareDates(preparedEstimate, minimalEstimate);

  useEffect(() => {
    if (serviceIds.length) {
      handleGetInitialEstimate(serviceIds).then(response =>
        setMinimalEstimate(response),
      );
    }
  }, [applicantData.firstDateEntry]);

  return { minimalEstimate, initialEstimate };
};

import {
  GET_ORDERS_USER,
  GET_ORDERS_USER_SUCCESS,
  GET_ORDERS_USER_FAIL,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
} from './actionTypes';

const INIT_STATE = {
  profile: {},
  orders: [],
  error: null,
  isLoading: false,
};

const clientCabinet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE:
    case CHANGE_EMAIL:
    case CHANGE_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ORDERS_USER:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORDERS_USER_SUCCESS:
      return {
        ...state,
        orders: action.payload.results,
        count: action.payload.count,
        isLoading: false,
      };
    case GET_ORDERS_USER_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case GET_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default clientCabinet;

import React from 'react';
import { MarkdownRenderer } from 'markdown-react-renderer';

export const Markdown = ({ markdown }) => {
  return (
    <MarkdownRenderer
      markdown={markdown}
      components={{
        a: props => <a target="_blank" {...props} />,
      }}
    />
  );
};

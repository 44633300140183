import jwt_decode from 'jwt-decode';

export const getExpirationDate = jwtToken => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

export const parseToken = () => {
  const authUser = localStorage.getItem('authUser');
  try {
    return authUser ? JSON.parse(authUser) : {};
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const isExpired = exp => {
  if (!exp) {
    return false;
  }

  return Date.now() > exp;
};

export const getUserRole = () => {
  const { access } = parseToken();

  if (access) {
    const { role } = jwt_decode(access);
    const isAdmin = role === 'admin';
    const isOperator = role === 'operator';
    const isManager = role === 'manager';
    const isEmployee = role === 'employee';
    const isIndividual = role === 'individual';

    if (isAdmin) {
      return 'admin';
    }

    if (isOperator) {
      return 'operator';
    }

    if (isManager) {
      return 'manager';
    }

    if (isEmployee) {
      return 'employee';
    }

    if (isIndividual) {
      return 'individual';
    }
  }
};

export const isUserAdmin = getUserRole() === 'admin';
export const isUserOperator =
  getUserRole() === 'operator' || getUserRole() === 'admin';
export const isUserManager = getUserRole() === 'manager';
export const isUserIndividual = getUserRole() === 'individual';
export const isUserClient = isUserIndividual || isUserManager;
export const isUserEmployee = getUserRole() === 'employee';
// export const isUserOperator = getUserRole()?.includes('operator');

export const getUserId = () => {
  const { access } = parseToken();

  if (access) {
    const { user_id } = jwt_decode(access);
    return user_id;
  }
  return 0;
};

export const getFirstAvailableRoute = () => {
  const role = getUserRole();

  // handle redirect for operator admin user
  // if (isOperatorAdmin(role)) {
  //   return '/orders';
  // }

  switch (role) {
    case 'admin':
      return '/orders';
    case 'operator':
      return '/orders';
    case 'manager':
      return '/orders';
    case 'individual':
      return '/orders';
    case 'employee':
      return '/applicant';
    default:
      return '/login';
  }
};

export const userCanViewPrice = () => {
  const { access } = parseToken();
  const { can_view_price } = jwt_decode(access);
  return can_view_price;
};

export const userCanCreateOrder = () => {
  const { access } = parseToken();
  if (access) {
    const { can_create_order } = jwt_decode(access);
    return can_create_order;
  }
  return false;
};

export const maxUploadSize = () => {
  const { access } = parseToken();
  if (access) {
    const { max_upload_size } = jwt_decode(access);
    return max_upload_size;
  }
  return 10485760; // 10 MB
};

export const getAddressString = address => {
  const addressName = address.name || '';
  const country = address.country.name || address.country.country.name || '';
  const state = address.state || '';
  const city = address.city || '';
  const address1 = address.address1 || '';

  return `${addressName}${
    addressName && ','
  } ${country} ${state} ${city} ${address1}`;
};

export const createReducer = (initState, reducers) => {
  return (state = initState, action) => {
    return Object.entries(reducers).reduce((acc, [actionType, fn]) => {
      if (actionType !== action.type) {
        return acc;
      }
      return fn(state, action);
    }, state);
  };
};

import { createQueryActions } from 'helpers/store/createQueryActions';

export const getAddresses = createQueryActions('getAddresses');

export const createAddresses = createQueryActions('createAddresses');

export const updateAddress = createQueryActions('updateAddress');

export const deleteAddress = createQueryActions('deleteAddress');

export const collectAddresses = createQueryActions('collectAddresses');

export const selectAddresses = createQueryActions('selectAddresses');

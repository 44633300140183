import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FIRST_PAGE,
  ORDERS_VIEW,
  PAGES,
  EMPTY_VALUE_WITH_LABEL,
  ORDER_STATUS,
} from '../../common/data/constants';

import { Alert, Col, Row, Button, ButtonGroup } from 'reactstrap';
import OrdersTable from './components/OrdersTable';
import { PaginationLm } from '../Common/PaginationLm';
import { convertDateToAPI } from 'helpers/converters';
import Loader from 'components/Common/Loader';
import { Layout } from 'components/Layout';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { ShadowCard } from 'components/Common/ShadowCard';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersFeed } from 'store/actions';

const getOrdersFeedState = state => state.admin.rest.events;

export const OrdersFeedComponent = () => {
  const { events, count, loading } = useSelector(getOrdersFeedState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchState, setSearchState] = useState({
    status: EMPTY_VALUE_WITH_LABEL,
    startDate: null,
    endDate: null,
    perPage: PAGES[0],
    page: FIRST_PAGE,
  });
  const [view, setView] = useState(
    localStorage.getItem('feedView') ?? ORDERS_VIEW.table,
  );

  useEffect(() => {
    filterData();
  }, [searchState]);

  const handleChangeSearchParam = (key, value) =>
    setSearchState(searchState => ({
      ...searchState,
      [key]: value,
      page: FIRST_PAGE,
    }));

  const filterData = () => {
    dispatch(
      getOrdersFeed({
        new_status: searchState.status ? searchState.status.value : null,
        created_at_start: convertDateToAPI(searchState.startDate),
        created_at_end: convertDateToAPI(searchState.endDate),
        page: searchState.page,
        page_size: searchState.perPage.value,
      }),
    );
  };

  const onChangeView = value => {
    localStorage.setItem('feedView', value);
    setView(value);
  };

  const handleChangeDateRange = dates => {
    const [start, end] = dates;
    setSearchState(searchState => ({
      ...searchState,
      startDate: start,
      endDate: end,
      page: FIRST_PAGE,
    }));
  };

  const showPagination = searchState.perPage.value < count;

  return (
    <Layout title={t('Orders feed')} breadcrumb={'Orders feed'}>
      <ShadowCard>
        <Row className="p-0 mb-2 w-100 row justify-content-end">
          <ButtonGroup>
            <Button
              type="button"
              color="info"
              outline
              active={view === ORDERS_VIEW.table}
              onClick={() => onChangeView(ORDERS_VIEW.table)}
            >
              <i className="bx bx-sm bx-table" />
            </Button>

            <Button
              type="button"
              color="info"
              outline
              active={view === ORDERS_VIEW.board}
              onClick={() => onChangeView(ORDERS_VIEW.board)}
            >
              <i className="bx bx-sm bx-columns" />
            </Button>
          </ButtonGroup>
        </Row>

        <Row className="mb-4 align-items-center">
          <Col md={4} xs={12}>
            <StatusPicker
              $type="gray"
              value={searchState.status}
              statuses={ORDER_STATUS}
              handlePickStatus={status =>
                handleChangeSearchParam('status', status)
              }
            />
          </Col>

          <Col xs={4}>
            <DateRangePicker
              onChange={handleChangeDateRange}
              startDate={searchState.startDate}
              endDate={searchState.endDate}
            />
          </Col>

          <Col xs={3}>
            <PerPageSelect
              perPage={searchState.perPage}
              setPage={page => handleChangeSearchParam('page', page)}
              setPerPage={perPage =>
                handleChangeSearchParam('perPage', perPage)
              }
            />
          </Col>
        </Row>

        {loading ? (
          <Row className="d-flex justify-content-center">
            <Loader isLoading={loading} />
          </Row>
        ) : (
          <>
            {events && events.length > 0 ? (
              view !== ORDERS_VIEW.board && <OrdersTable events={events} />
            ) : (
              <Alert color="info">{t('Empty feed')}</Alert>
            )}
            <Row>
              <Col className="col-12">
                {showPagination && (
                  <PaginationLm
                    count={count}
                    perPage={searchState.perPage.value}
                    active={searchState.page}
                    selectPage={page => handleChangeSearchParam('page', page)}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </ShadowCard>
    </Layout>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import styled, { css } from 'styled-components';

import { PAGES, FIRST_PAGE } from '../../common/data/constants';

export const StyledDropdownToggle = styled(DropdownToggle)`
  background: none !important;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.mainText} !important;
  font-size: 14px;

  .input-group .btn {
    position: relative;
    z-index: 0;
  }

  ${({ invalid, theme }) =>
    invalid &&
    css`
      color: ${theme.colors.danger}!important;
    `}
`;

const StyledText = styled.span`
  font-size: 14px;
  color: #6a7187;
`;

const StyledDropDownMenu = styled(DropdownMenu)`
  border-radius: 8px;
  box-shadow: -4px 8px 12px rgb(74 87 102 / 30%);
`;

export const PerPageSelect = ({
  perPage,
  setPage,
  setPerPage,
  pages = PAGES,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-end">
      <UncontrolledDropdown group>
        <StyledText className="me-2">{t('common.showBy')}</StyledText>

        <StyledDropdownToggle className="p-0">
          {perPage?.value ?? perPage}

          <i className="bx bx-chevron-down" />
        </StyledDropdownToggle>

        <StyledDropDownMenu>
          {pages
            .filter(page => page.label !== perPage?.label)
            .map(page => (
              <DropdownItem
                onClick={() => {
                  setPage(FIRST_PAGE);
                  setPerPage(page);
                }}
                key={page.value}
              >
                {page.label}
              </DropdownItem>
            ))}
        </StyledDropDownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

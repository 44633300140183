import { get, put, post } from 'helpers/api_helper';
import authHeader from 'helpers/jwt-token-access/auth-token-header';
import { EMPLOYEE, DOCUMENTS } from 'helpers/url-helpers/employee';

export const fetchEmployee = () =>
  get(EMPLOYEE, {
    headers: authHeader(),
  });
export const putEmployee = ({ applicant }) =>
  put(EMPLOYEE, applicant, {
    headers: authHeader(),
  });

export const fetchDocuments = params =>
  get(DOCUMENTS, {
    headers: authHeader(),
    params,
  });
export const fetchDocument = id =>
  get(`${DOCUMENTS}${id}/`, {
    headers: authHeader(),
    responseType: 'blob',
  });
export const putDocument = (id, formData) =>
  put(`${DOCUMENTS}${id}/`, formData, {
    headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
  });
export const deleteDocumentReq = id =>
  post(`${DOCUMENTS}${id}/archive/`, _, {
    headers: authHeader(),
  });

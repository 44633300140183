export const SCROLL_STYLE = `
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }
  
  &::-webkit-scrollbar-track {
    background: #0000;
    border-radius: 20px;
  }
`;

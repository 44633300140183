import classNames from 'classnames';
import {
  DATE_FORMAT_TIME,
  DATE_FORMAT_WITH_SECONDS,
} from 'common/data/constants';
import { Badge, BlueLink } from 'components/Common';
import { formatDate } from 'helpers/formatDate';
import { getUserId } from 'helpers/jwt_helper';
import React, { useMemo } from 'react';

import { StyledAvatar } from './ChatCard';
import { ChatDocument } from './ChatDocument';

const myId = getUserId();

export const ChatMessage = ({ message, className, nextMessage }) => {
  const userName = useMemo(() => {
    if (message.user?.first_name && message.user?.last_name) {
      return `${message.user.first_name} ${message.user.last_name}`;
    }
    return null;
  }, [message.user]);

  return (
    <div
      style={{
        marginBottom: 32,
        overscrollBehavior: 'contain',
      }}
      className={classNames('d-flex w-100', className)}
      key={message.id}
    >
      <div className="h-100 me-3">
        <StyledAvatar
          name={userName}
          size={44}
          className="fs-4 fw-semibold"
          maxInitials={2}
          round
        />
      </div>

      <div className="w-100">
        <div className="d-flex mb-2 justify-content-between">
          <div className="d-flex">
            <BlueLink
              className="fs-6 fw-500 me-2"
              to={`/users/${message.user?.id}`}
            >
              {userName}
            </BlueLink>

            {myId === message.user?.id && (
              <Badge
                className="text-center align-middle d-flex align-items-center"
                text="roles.operator"
              />
            )}
          </div>

          <div className="d-flex justify-content-end">
            <span className="text-muted">
              {formatDate(
                message.created_at,
                new Date(nextMessage?.created_at).getDay() ===
                  new Date(message.created_at).getDay() ||
                  message.id === nextMessage?.id
                  ? DATE_FORMAT_TIME
                  : DATE_FORMAT_WITH_SECONDS,
              )}
            </span>
          </div>
        </div>

        <div className="d-flex flex-column align-items-start w-75">
          <p className="m-0 fs-14 " style={{ whiteSpace: 'pre-wrap' }}>
            {message.message}
          </p>

          {message.documents?.length
            ? message.documents.map(doc => (
                <ChatDocument key={doc.id} document={doc} />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

import {
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  GET_ORDERS_USER,
  GET_PROFILE,
} from './actionTypes';
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getUserOrders,
  getProfile,
  updateProfile,
  changePassword,
} from 'helpers/api-requests/admin';
import {
  getOrdersUserSuccess,
  getOrdersUserFail,
  getProfileSuccess,
  getProfileFail,
} from './actions';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';

function* fetchOrdersUser({ payload }) {
  try {
    const response = yield call(getUserOrders, payload);
    if (response.success) {
      yield put(getOrdersUserSuccess(response.data));
    } else {
      yield put(getOrdersUserFail(response.data));
    }
  } catch (error) {
    yield put(getOrdersUserFail(response.data));
  }
}

function* fetchProfile(params) {
  try {
    const response = yield call(getProfile, params);
    if (response.success) {
      yield put(getProfileSuccess(response.data));
    } else {
      yield put(getProfileFail(response.data));
    }
  } catch (error) {
    yield put(getProfileFail(error));
  }
}

function* changeEmail(payload) {
  try {
    const response = yield call(updateProfile, { email: payload.data });
    if (response.success) {
      showToastSuccess(response.message);
      yield put(getProfileSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(getProfileFail(response.message));
    }
  } catch (error) {
    showToastError(response.message);
    yield put(getProfileFail(response.message));
  }
}

function* editPassword(payload) {
  try {
    const response = yield call(changePassword, payload.data);
    if (response.success) {
      showToastSuccess(response.message);
    } else {
      showToastError(response.message);
      yield put(getProfileFail(response.message));
    }
  } catch (error) {
    showToastError(response.message);
    yield put(getProfileFail(response.message));
  }
}

function* clientCabinetSaga() {
  yield takeEvery(GET_ORDERS_USER, fetchOrdersUser);
  yield takeEvery(GET_PROFILE, fetchProfile);
  yield takeEvery(CHANGE_EMAIL, changeEmail);
  yield takeEvery(CHANGE_PASSWORD, editPassword);
}

export default clientCabinetSaga;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import styled, { css } from 'styled-components';

const StyledBreadcrumbItem = styled(BreadcrumbItem)`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  a {
    color: ${({ theme }) => theme.colors.blue200};
  }

  ${({ active, theme }) =>
    active &&
    css`
      a {
        color: ${theme.colors.mainText};
      }
    `}
`;

export const Breadcrumbs = ({ breadcrumbs, title }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumb className="ps-3" listClassName="m-0 p-0 bg-transparent">
      {breadcrumbs.map(({ title, to }, index) => (
        <StyledBreadcrumbItem key={index}>
          <Link to={to}>{t(title)}</Link>
        </StyledBreadcrumbItem>
      ))}

      <StyledBreadcrumbItem active>
        <Link to="#">{t(title)}</Link>
      </StyledBreadcrumbItem>
    </Breadcrumb>
  );
};

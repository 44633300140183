//CALENDER
import { getUserRole } from '../jwt_helper';
const USER_ROLE = getUserRole();

// const getUserSimpleRole = roles => {
//   if (Array.isArray(roles)) {
//     return roles?.includes('operator') ? 'operator' : roles[0];
//   } else {
//     return roles;
//   }
// };

const getUserSimpleRole = role => {
  if (role === 'admin') {
    return 'operator';
  }
  return role;
};

export const getDocumentUploadUrl = applicantId => {
  const base_url = `/${getUserSimpleRole(USER_ROLE)}`;
  return USER_ROLE === 'employee'
    ? `${base_url}/documents/`
    : `${base_url}/applicants/${applicantId}/documents/`;
};

export const GET_EVENTS = '/events';
export const ADD_NEW_EVENT = '/add/event';
export const UPDATE_EVENT = '/update/event';
export const DELETE_EVENT = '/delete/event';
export const GET_CATEGORIES = '/categories';

export const GET_PROFILE = `/common/profile/`;
export const CHANGE_PASSWORD = '/common/password/change/';
export const CHANGE_EMAIL = '/common/email/change/';

//CHATS
export const GET_CHATS = `/operator/chats/`;
export const CHANGE_CHAT_STATUS = `/operator/chats/`;
export const GET_GROUPS = '/groups';
export const GET_CONTACTS = '/contacts';
export const GET_MESSAGES = `/operator/chats/`;
export const ADD_MESSAGE = `/operator/chats/`;

//TARIFFS
export const GET_TARIFFS = `/operator/tariffs/`;
export const GET_TARIFFS_PRODUCT = `/operator/tariffs/products/`;

//ORDERS
export const GET_ORDERS = '/orders';

//CART DATA
export const GET_CART_DATA = '/cart';

//CUSTOMERS
export const GET_CUSTOMERS = '/customers';

//SHOPS
export const GET_SHOPS = '/shops';

//CRYPTO
export const GET_WALLET = '/wallet';
export const GET_CRYPTO_ORDERS = '/crypto/orders';

//INVOICES
export const GET_INVOICES = '/invoices';
export const GET_INVOICE_DETAIL = '/invoice';

//PROJECTS
export const GET_PROJECTS = '/projects';
export const GET_PROJECT_DETAIL = '/project';

//TASKS
export const GET_TASKS = '/tasks';

export const OPERATOR_SERVICES_URL = `/${getUserSimpleRole(
  USER_ROLE,
)}/products/`;

export const USERS_URL = `/${getUserSimpleRole(USER_ROLE)}/users/`;

export const SERVICE_URL = `/admin/products/`;

export const GET_LOGS = '/operator/logs/';

export const ORGANIZATIONS_URL = `/operator/organizations/`;

export const ORDERS_URL = `/${getUserSimpleRole(USER_ROLE)}/orders/`;

export const ORDERS_FEED_URL = `/operator/orders/feed/`;

export const ORDER_CUSTOMER = '/operator/orders/{id}/customers/';
export const ORDER_CUSTOMER_ITEM =
  '/operator/orders/{id}/customers/{customer_pk}/';

export const ORDER_CUSTOMER_STATUS =
  '/operator/orders/{id}/customers/{customer_pk}/status/';

// REFERENCES
// export const VISA_TYPES = '/references/visa/types/';
export const VISA_TYPES = '/references/country/visas/';

export const GET_COUNTRIES = '/references/countries/';
export const GET_ADDRESSES = '/references/address/';
export const GET_REGIONS = '/references/regions/';
export const GET_VS_URGENCY_LIST = '/references/urgencies/';

export const GET_REFERENCES_CONFIG = '/references/config/';

// OLD
export const GET_USER_PROFILE = '/user';

export const TAX = '/operator/tax';
export const TAX_LIST = '/operator/tax';

export const MANAGER_INVITES_LIST = '/manager/users/invite/';
export const MANAGER_CREATE_INVITE = `/${USER_ROLE}/users/invite/`;

export const OPERATOR_INVITES_LIST = '/operator/users/invite';
export const OPERATOR_CREATE_INVITE = `/${USER_ROLE}/users/invite/`;

export const OPERATOR_ADD_INVITE = 'operator/users';

export const OPERATOR_INVOICES_LIST = `/${getUserSimpleRole(USER_ROLE)}/bills`;
export const OPERATOR_INVOICES_GENERATE = '/operator/bills/generate';

export const OPERATOR_APPLICANTS_ORGANIZATION_LIST =
  '/operator/applicants/organization/{organization_pk}/';
export const OPERATOR_APPLICANTS_USER_LIST =
  '/operator/applicants/user/{user_pk}/';

export const OPERATOR_ORGANIZATION_ADD_APPLICANT =
  '/operator/applicants/organization/{organization_pk}/';

export const OPERATOR_USER_ADD_APPLICANT =
  '/operator/applicants/user/{user_pk}/';

export const PAY_SOFORT_INIT = '/payments/sofort/init'; ///payments/sofort/init/{bill_id}/{lang}/
export const PAY_SOFORT_HOOK = '/payments/sofort/hook';
export const PAY_SOFORT_WEBHOOK = '/payments/sofort/webhook';
export const PAY_SOFORT_ABORT = '/payments/abort';

export const PAY_STRIPE_INIT = '/payments/stripe/init';
export const PAY_STRIPE_SUCCESS = '/payments/stripe/success';
export const PAY_STRIPE_WEBHOOK = '/payments/stripe/webhook';

export const REGISTER_INVITED_USER = '/auth/signup/';

export const USER_GET_ORDER_LIST = '/user/orders';
export const USER_GET_ORDER = '/user/orders/';

export const OPERATOR_DELIVERY_METHODS = '/operator/delivery/methods/';
export const OPERATOR_DELIVERY_ADDRESSES = '/operator/delivery/addresses/';
export const OPERATOR_DELIVERY_SHIPMENTS = '/operator/delivery/shipments/';
export const OPERATOR_DELIVERY_SHIPMENT = '/operator/delivery/shipments/{id}/';

export const OPERATOR_DELIVERY_SHIPMENT_UPS =
  '/operator/delivery/shipments/{id}/ups/issue/';

export const OPERATOR_DELIVERY_SHIPMENT_LABEL =
  '/operator/delivery/shipments/{id}/shipment_label/';

export const GET_CLIENTS = '/operator/clients/';
export const CREATE_CLIENT = '/operator/clients/';
export const GET_CLIENT_DETAIL =
  '/operator/applicants/{client_type}/{client_id}/';

export const OPERATOR_APPLICANT = '/operator/applicants';

export const GET_INDIVIDUAL = '/operator/users';

export const USER_PASSWORD = '/auth/invitation/confirm/{token}/password/';

export const CHANGE_INVOICE = '/operator/bills/{id}/status';

export const APPLICANT_DOCUMENT = `/operator/applicants/{id}/documents/{document_id}/`;

export const AGGREGATED_INVOICES = '/operator/bills/aggregated/';

export const APPLICANT_DOCUMENT_FILTERED = `/${getUserSimpleRole(
  USER_ROLE,
)}/documents/filter/`;

export const ACCOUNTING_CODES_URL = `/${getUserSimpleRole(
  USER_ROLE,
)}/accounting/`;

export const TAGS = `operator/tariffs/tags/`;

export const PASSPORT_SCANNER_URL = `/${getUserSimpleRole(
  USER_ROLE,
)}/passport_scanner/scan/`;

export const PASSPORT_SCANNER_CHECK_URL = `/${getUserSimpleRole(
  USER_ROLE,
)}/passport_scanner/check/`;

export const GET_ORDER_TYPES_URL = `/${getUserSimpleRole(
  USER_ROLE,
)}/order_types/`;

export const EXTRACT_PASSPORT_IMG_FROM_PDF_URL = `/${getUserSimpleRole(
  USER_ROLE,
)}/pdf_reader/`;

import { takeEvery, put, call } from 'redux-saga/effects';

// Login Redux States
import { EDIT_PROFILE } from './actionTypes';
import { profileSuccess, profileError } from './actions';

//Include Both Helper File with needed methods
import {
  postFakeProfile,
  postJwtProfile,
} from '../../../helpers/api-requests/auth';

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtProfile, '/post-jwt-profile', {
        username: user.username,
        idx: user.idx,
      });
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      });
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

export default ProfileSaga;

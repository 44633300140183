import { Input } from 'reactstrap';
import { formatCurrency } from 'helpers/formatCurrency';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { isUserOperator, userCanViewPrice } from 'helpers/jwt_helper';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { EditPrice } from '../../Common/EditPrice';
import { StyledEmptyButton } from '../../../pages/Tariff/TariffServiceRow';
import { convertPriceToAPI } from 'helpers/converters';

const StyledLine = styled.tr`
  ${({ disabled }) =>
    disabled &&
    css`
      filter: opacity(50%);
    `}
`;

export const ApplicantServicesRow = ({ service, index, isDisabledFields }) => {
  const formik = useFormikContext();
  const hidePrice = !userCanViewPrice();

  const {
    i18n: { language },
  } = useTranslation();

  const [isEditPriceMode, setIsEditPriceMode] = useState(false);
  const [servicePrice, setServicePrice] = useState(null);

  const serviceForm = formik.values.applicants?.[index].services?.find(
    product => product.id === service.id,
  );
  const serviceValues = serviceForm || service;

  const isDisableRow = useCallback(
    id => {
      const product = formik.values.applicants?.[index].services?.find(
        product => product.id === id,
      );

      // If order is closed, all rows are disabled.

      if (isDisabledFields) {
        return true;
      } else if (product?.is_visa_type) {
        // If operator and product is visa type, it depends on the invoice status
        if (isUserOperator) {
          return product?.is_invoice_locked;
        } else {
          return true;
        }
      } else if (product?.isAdministrative && !isUserOperator) {
        return true;
      } else if (product?.is_invoice_locked) {
        return true;
      } else {
        return false;
      }
      // return product?.is_visa_type || product?.is_invoice_locked || isDisabledFields;
      // return (
      //   formik.values.applicants?.[index].services?.find(
      //     product => product.id === id,
      //   )?.is_visa_type || isDisabledFields
      // );
    },
    [formik.values.applicants?.[index].services, isDisabledFields],
  );

  const handleToggleCheckbox = service => {
    let services = [];
    formik.values.applicants?.[index].statusApplicant === 'draft' &&
      formik.setFieldValue(`applicants[${index}].firstDateEntry`, null);

    formik.values.applicants?.[index].statusApplicant === 'draft' &&
      formik.setFieldValue(
        `applicants[${index}].documentsToApplicantEstimatedAt`,
        null,
      );

    if (
      formik.values.applicants?.[index].services?.find(
        product => product.id === service?.id,
      )
    ) {
      services = formik.values.applicants?.[index].services.filter(
        product => product.id !== service.id,
      );
      formik.setFieldValue(`applicants[${index}].unCheckedServices`, [
        ...(formik.values.applicants?.[index].unCheckedServices ?? []),
        service.id,
      ]);
    } else {
      services = [...formik.values.applicants?.[index].services, service];
    }

    formik.setFieldValue(`applicants[${index}].services`, services);
  };

  const handleOpenEditMode = service => {
    setIsEditPriceMode(true);
    setServicePrice({
      currentId: service.id,
      price:
        service.product_net_price_cents === 0
          ? undefined
          : formatCurrency(service.product_net_price_cents),
    });
  };

  const handleChangePrice = event =>
    setServicePrice(prev => ({ ...prev, price: event.target.value }));

  const disableEditPrice = () => {
    setServicePrice(null);
    setIsEditPriceMode(false);
  };

  const updatePriceService = () => {
    const services = formik.values.applicants?.[index].services;
    const serviceIndex = services.findIndex(
      product => product.id === servicePrice.currentId,
    );

    const tax_percent = service.product_tax_percent_human / 100;
    const price_cents = convertPriceToAPI(servicePrice.price);
    const tax_cents = price_cents * tax_percent;
    const price_total = price_cents + tax_cents;

    const prefix = `applicants[${index}].services[${serviceIndex}]`;

    formik.setFieldValue(`${prefix}.product_price`, price_cents);
    formik.setFieldValue(`${prefix}.product_net_price_cents`, price_cents);
    formik.setFieldValue(`${prefix}.product_tax_total_cents`, tax_cents);
    formik.setFieldValue(`${prefix}.product_total_price_cents`, price_total);

    disableEditPrice();
  };

  return (
    <StyledLine
      disabled={isDisableRow(service.id) && !isDisabledFields}
      key={service.id}
    >
      <td className="col-1">
        <Input
          type="checkbox"
          checked={serviceForm ? 1 : 0}
          disabled={isDisableRow(service.id)}
          onChange={() => handleToggleCheckbox(service)}
        />
      </td>

      <td className="col-3">{service?.name[language]}</td>

      <td className="col-3">{service?.product_type?.type_display}</td>

      {!hidePrice && (
        <>
          {isEditPriceMode ? (
            <>
              <td className="col-2">
                <EditPrice
                  defaultPrice={formatCurrency(
                    serviceValues.product_net_price_cents,
                  )}
                  handleChangePrice={handleChangePrice}
                  handleCloseEdit={disableEditPrice}
                  handleSavePrice={updatePriceService}
                  // disabled={
                  //   formatCurrency(service.product_net_price_cents) ===
                  //   servicePrice?.price
                  // }
                />
              </td>
            </>
          ) : (
            <>
              <td className="col-1 text-nowrap">
                {formatCurrency(serviceValues.product_net_price_cents)}
                {serviceForm && isUserOperator && (
                  <StyledEmptyButton
                    className="ms-1"
                    onClick={() => handleOpenEditMode(service)}
                  >
                    <i className="bx bx-edit-alt" />
                  </StyledEmptyButton>
                )}
              </td>
            </>
          )}

          <td className="col-3">{`${formatCurrency(
            serviceValues.product_tax_total_cents,
          )} (${serviceValues.product_tax_percent_human}%)`}</td>

          <td className="col-2 fw-semibold">
            {formatCurrency(serviceValues.product_total_price_cents)}
          </td>
        </>
      )}
    </StyledLine>
  );
};

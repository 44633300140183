import React from 'react';
import styled from 'styled-components';

const StyledText = styled.span`
  font-size: 14px;
  color: #6a7187;
`;

export const ResponsiveFilters = ({ children, lastItem, count }) => (
  <div className="d-flex justify-content-between  flex-wrap align-items-center">
    <div className="d-flex flex-wrap align-items-center ">{children}</div>
    <div className="mb-3">
      {count?.value >= 0 && (
        <StyledText className="me-2">
          {count?.label || 'Count'}: &nbsp;
          {count?.value}
        </StyledText>
      )}
    </div>

    <div className="mb-3">{lastItem}</div>
  </div>
);

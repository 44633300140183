import { AddApplicantImage } from 'assets/images/visa';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody } from 'reactstrap';
import styled from 'styled-components';

const StyledCardBody = styled(CardBody)`
  border-radius: ${({ theme }) => theme.radius.s};
  padding: 12px 24px;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 10px;
      padding: 6px 24px;
    }
  } ;
`;

const StyledSpan = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledButton = styled(Button)`
  padding: 12px 84px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary}!important;
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const ApplicantAdd = ({ handleCreateApplicant, title }) => {
  const { t } = useTranslation();

  return (
    <Card className="border-0 mt-4">
      <StyledCardBody className="bg-primary d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <AddApplicantImage />

          <StyledSpan className="ms-2">
            {title ? title : t('order.moreApplicant')}
          </StyledSpan>
        </div>

        <StyledButton onClick={handleCreateApplicant} className="text-primary">
          {t('common.add')}
        </StyledButton>
      </StyledCardBody>
    </Card>
  );
};

import classNames from 'classnames';
import { CardTitle, ShadowCard, StyledPhoneInput } from 'components/Common';
import { StyledInput, StyledLabel } from 'components/Common/FormikInput';
import { useFormikContext } from 'formik';
import { DEFAULT_POINTS } from 'helpers/styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const applicantContactsFields = [
  {
    id: 1,
    label: 'auth.email',
    name: 'email',
    placeholder: 'auth.enterEmail',
    type: 'text',
    isShort: false,
  },
  {
    id: 2,
    label: 'common.phone',
    name: 'phone',
    type: 'phone',
    isShort: true,
  },
];

export const ApplicantContactsCard = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <ShadowCard id="contacts" className="mb-4" bodyStyle={{ padding: 24 }}>
      <CardTitle title="applicants.contacts" />

      {applicantContactsFields.map((field, index, arr) => (
        <Row
          key={field.id}
          className={classNames('p-0 align-items-center', [
            arr.length !== index + 1 && 'mb-4',
          ])}
        >
          <Col {...DEFAULT_POINTS.label}>
            <StyledLabel
              invalid={formik.errors[field.name] && formik.touched[field.name]}
            >
              {t(field.label)}
            </StyledLabel>
          </Col>

          <Col
            {...DEFAULT_POINTS.input}
            sm={field.isShort ? 5 : 9}
            lg={field.isShort ? 5 : 9}
          >
            {field.type === 'text' && (
              <StyledInput
                name={field.name}
                placeholder={t(field.placeholder)}
                value={formik.values[field.name]}
                invalid={
                  formik.errors[field.name] && formik.touched[field.name]
                }
                onChange={formik.handleChange}
              />
            )}

            {field.type === 'phone' && (
              <StyledPhoneInput
                inputStyle={{ width: '100%' }}
                name={field.name}
                errors={formik.errors[field.name] && formik.touched[field.name]}
                onChange={phone => formik.setFieldValue(field.name, phone)}
                value={formik.values[field.name]}
              />
            )}
          </Col>
        </Row>
      ))}
    </ShadowCard>
  );
};

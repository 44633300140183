import { REPORT_BREADCRUMBS } from 'common/data/beadcrumbs';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { Layout } from 'components/Layout';
import { useFormik, FormikProvider } from 'formik';
import { INVOICE_VALIDATOR } from 'forms/validators';
import { convertInvoiceFromAPI, convertInvoiceToAPI } from 'helpers/converters';
import { useModal } from 'hooks/useModal';
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  createAggregatedInvoice,
  getAggregatedInvoice,
  updateAggregatedInvoice,
  updateStatusAggregatedInvoice,
} from 'store/actions';
import { ReportClientCard } from './ReportClientCard';
import { ReportHaderControl } from './ReportHaderControl';
import { ReportInvoiceCard } from './ReportInvoiceCard';

const initialValues = {
  client: null,
  countries: null,
  periodStart: null,
  periodEnd: null,
  number: null,
  invoices: [],
};

const getAggregatedInvoiceSelect = state =>
  state.aggregatedInvoice.aggregatedInvoice.aggregatedInvoice;

export const Report = memo(() => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { isOpen, handleCloseModal, handleOpenModal } = useModal();
  const { invoice, isLoading } = useSelector(getAggregatedInvoiceSelect);
  const [isEditMode, setIsEditMode] = useState(!id);
  const [reportStatus, setReportStatus] = useState({});

  const handleSubmit = values => {
    if (id) {
      dispatch(
        updateAggregatedInvoice({
          id,
          invoice: convertInvoiceToAPI(values),
          closeEdit: () => setIsEditMode(false),
        }),
      );
      return;
    }

    dispatch(
      createAggregatedInvoice({
        push,
        invoice: convertInvoiceToAPI(values),
      }),
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues,
    validationSchema: INVOICE_VALIDATOR,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (id) {
      dispatch(getAggregatedInvoice(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && Number(id) === invoice?.id) {
      formik.resetForm({ values: convertInvoiceFromAPI(invoice) });
    }
  }, [id, invoice]);

  const handleChangeStatus = () => {
    dispatch(updateStatusAggregatedInvoice({ id, status: reportStatus.value }));
    handleCloseModal();
  };

  const handleResetInitial = () => {
    formik.resetForm({ values: convertInvoiceFromAPI(invoice) });
    setIsEditMode(false);
  };

  return (
    <FormikProvider value={formik}>
      <Layout
        isScrollableHeader
        loading={isLoading}
        breadcrumbs={REPORT_BREADCRUMBS}
        title={id ? 'reports.aggregatedInvoice' : 'reports.createInvoice'}
        extraContent={
          <ReportHaderControl
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
            handleResetInitial={handleResetInitial}
          />
        }
      >
        {isOpen && (
          <ModalConfirmDelete
            handleCloseModal={handleCloseModal}
            buttonText="reports.change"
            isOpenModal={isOpen}
            textHeader="reports.changeStatus"
            textBody="reports.confirmChange"
            handleDelete={handleChangeStatus}
          />
        )}

        <Row>
          <Col xs={12}>
            <ReportClientCard
              isEditMode={isEditMode}
              handleOpenModal={handleOpenModal}
              report={invoice}
              setReportStatus={setReportStatus}
            />
            {formik.values.client && (
              <ReportInvoiceCard isEditMode={isEditMode} />
            )}
          </Col>
        </Row>
      </Layout>
    </FormikProvider>
  );
});

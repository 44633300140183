import i18n from 'i18n';

export const PAGES = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const CHAT_TYPES = [
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' },
];

export const VAT_STATUSES = [
  { value: true, label: 'common.enabled' },
  { value: false, label: 'common.disabled' },
];

export const AGGREGATE_INVOICE_STATUSES = [
  { value: 'wait_payment', label: i18n.t('reports.waitingPayment') },
  { value: 'paid', label: i18n.t('reports.paid') },
  { value: 'draft', label: i18n.t('reports.draft') },
];

export const REGIONS = [{ value: 1, label: 'common.eu' }];

export const VERBS = [
  { value: 'failed login attempt', label: 'Failed login attempt' },
  { value: 'logged in', label: 'Logged in' },
  { value: 'created object', label: 'Created object' },
  { value: 'uploaded file', label: 'Uploaded file' },
  { value: 'deleted file', label: 'Deleted file' },
  { value: 'blocked user', label: 'Blocked user' },
  { value: 'order status updated', label: 'Order status updated' },
  { value: 'unblocked user', label: 'Unblocked user' },
  { value: 'updated object', label: 'Updated object' },
  { value: 'created organization', label: 'Created organization' },
  { value: 'user registered', label: 'User registered' },
  { value: 'accepted invitation', label: 'Accepted invitation' },
  { value: 'user changed password', label: 'User changed password' },
];

export const DATE_FORMAT = 'dd-MM-yyyy HH:mm:ss';
export const DATE_FORMAT_WITHOUT_SECONDS = 'dd-MM-yyyy HH:mm';
export const DATE_FORMAT_SHORT = 'dd-MM-yyyy';
export const DATE_FORMAT_SHORT_REVERSE = 'yyyy-MM-dd';
export const DATE_FORMAT_WITHOUT_SECONDS_REVERSE = 'yyyy-MM-dd HH:mm';
export const DATE_FORMAT_PLACEHOLDER = 'MM-DD-YYYY';

// New disign format
export const DATE_FORMAT_WITH_SECONDS = 'd MMM yyyy, HH:mm';
export const DATE_FORMAT_WITH_SYMBOL = 'd MMM yyyy';
export const DATE_FORMAT_TIME = 'HH:mm';

export const FIRST_PAGE = 1;

export const SELECT_CLASSNAME = 'select2-selection';

export const ORDERS_VIEW = {
  table: 'table',
  board: 'board',
};

export const YES = 'yes';
export const NO = 'no';

export const YES_NO_OPTIONS = [
  { value: YES, label: i18n.t('common.yes') },
  { value: NO, label: i18n.t('common.no') },
];

export const ROLE_ADMIN = 'admin';
export const ROLE_OPERATOR = 'operator';
// export const ROLE_USER = 'user';
export const ROLE_MANAGER = 'manager';
export const ROLE_EMPLOYEE = 'employee';

export const USER_ROLE_CHOICES = [
  { label: i18n.t('roles.admin'), value: ROLE_ADMIN },
  { label: i18n.t('roles.operator'), value: ROLE_OPERATOR },
  // { label: i18n.t('roles.user'), value: ROLE_USER },
  { label: i18n.t('roles.manager'), value: ROLE_MANAGER },
  { label: i18n.t('roles.employee'), value: ROLE_EMPLOYEE },
];

export const USER_STATUS = [
  { label: 'services.active', value: 'active' },
  { label: 'client.blocked', value: 'blocked' },
];

export const USER_SEX = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];

export const SERVICES_STATUS = [
  { label: 'services.active', value: 'active' },
  { label: 'services.archived', value: 'archived' },
];

export const CLIENT_TYPES = [
  { label: 'client.individual', value: 'individual' },
  { label: 'client.legal', value: 'legal' },
];

export const CLIENT_STATUS = [
  { label: 'client.active', value: 'active' },
  { label: 'client.blocked', value: 'blocked' },
];

export const CLIENT_CATEGORIES = [
  { label: 'Crew', value: 'category_crew' },
  { label: 'Firma', value: 'category_firma' },
];

export const ORDER_STATUS = [
  { label: 'orders.status.new', value: 'new' },
  { label: 'orders.status.draft', value: 'draft' },
  { label: 'orders.status.in_progress', value: 'in_progress' },
  // { label: 'orders.status.returned', value: 'returned' },
  { label: 'orders.status.completed', value: 'completed' },
  { label: 'orders.status.cancelled', value: 'cancelled' },
  { label: 'orders.status.declined', value: 'declined' },
];

export const STATUS_HISTORY = [
  { date: new Date(), status: 'Status Changed To New' },
  { date: new Date(), status: 'Status Changed To In progress' },
  { date: new Date(), status: 'Status Changed To Completed' },
  { date: new Date(), status: 'Status Changed To Declined' },
  { date: new Date(), status: 'Status Changed To New' },
  { date: new Date(), status: 'Status Changed To In progress' },
  { date: new Date(), status: 'Status Changed To Completed' },
  { date: new Date(), status: 'Status Changed To Declined' },
];

export const DELIVERY = [
  { label: 'delivery.dhl', value: 'dhl' },
  { label: 'delivery.ups', value: 'ups' },
  { label: 'delivery.go', value: 'go' },
];

export const ORGANIZATION_STATUS = [
  { value: 'active', label: 'delivery.active' },
  { value: 'blocked', label: 'delivery.blocked' },
  { value: 'archived', label: 'delivery.archived' },
];

export const DOC_TYPES = [
  { value: 'passport', label: 'document.passport' },
  { value: 'invitation', label: 'document.invitation' },
  { value: 'medical_insurance', label: 'document.medical_insurance' },
  { value: 'other', label: 'document.other' },
  { value: 'application_form', label: 'document.application_form' },
  { value: 'work_place_reference', label: 'document.work_place_reference' },
  { value: 'bank_statement', label: 'document.bank_statement' },
  { value: 'crew_id', label: 'document.crew_id' },
  { value: 'citizenship_withdrawal', label: 'document.citizenship_withdrawal' },
  { value: 'photo', label: 'document.photo' },
  {
    value: 'germany_residence_confirmation',
    label: 'document.germany_residence_confirmation',
  },
  { value: 'diploma', label: 'document.diploma' },
  {
    value: 'hiv_absence_certificate',
    label: 'document.hiv_absence_certificate',
  },
  { value: 'tourist_reference', label: 'document.tourist_reference' },
  { value: 'gdpr', label: 'document.gdpr' },
  { value: 'birth_certificate', label: 'document.birth_certificate' },
  { value: 'previous_visa', label: 'document.previous_visa' },
  { value: 'previous_visa_passport', label: 'document.previous_visa_passport' },
];

export const INVOICE_STATUS = {
  draft: 'invoices.draft',
  // created: 'invoices.created',
  wait_payment: 'invoices.waitPayment',
  paid: 'invoices.paid',
  cancelled: 'invoices.cancelled',
};

export const INVOICE_STATUSES = [
  { value: 'draft', label: 'invoices.draft' },
  { value: 'wait_payment', label: 'invoices.waitPayment' },
  { value: 'paid', label: 'invoices.paid' },
  { value: 'cancelled', label: 'invoices.cancelled' },
];

export const PDF = 'pdf';
export const XLS = 'xls';
export const TXT = 'txt';

export const ORDERS_STATUS = {
  draft: 'orders.status.draft',
  new: 'orders.status.new',
  in_progress: 'orders.status.in_progress',
  completed: 'orders.status.completed',
  cancelled: 'orders.status.cancelled',
  declined: 'orders.status.declined',
  returned: 'orders.status.returned',
  // declined: 'orders.status.declined',
  // docs_missing: 'orders.status.docs_missing',
  // invitation_ready: 'orders.status.invitation_ready',
  // docs_collected: 'orders.status.docs_collected',
  // docs_originals_received: 'orders.status.docs_originals_received',
  // at_the_consulate: 'orders.status.at_the_consulate',
  // visa_ready: 'orders.status.visa_ready',
  // documents_sent: 'orders.status.documents_sent',
  // paused: 'orders.status.paused',
};

export const TAXES_STATUS = [
  { label: 'Active', value: 'active' },
  { label: 'Archived', value: 'archived' },
];

export const EMPTY_VALUE = {
  value: '',
  label: '-',
};

export const EMPTY_VALUE_WITH_LABEL = {
  value: '',
  label: i18n.t('common.all'),
};

export const TAX = {
  label: '19%',
  taxPrice: 0.19,
  priceWithTax: 1.19,
};

export const UPS_PROVIDER_NAME = 'ups';

export const GERMANY_PHONE_CODE = '+49';

export const ORDER_PAYMENT_STATUSES = [
  { label: 'Not ready', value: 'not_ready' },
  { label: 'Not paid', value: 'not_paid' },
  { label: 'Paid', value: 'paid' },
];

import { useState } from 'react';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(null);

  const handleCloseModal = () => setIsOpen(null);

  const handleOpenModal = value => setIsOpen(value ?? true);

  return {
    isOpen,
    handleCloseModal,
    handleOpenModal,
    [Symbol.iterator]() {
      const values = Object.values(this);
      let index = -1;

      return {
        next() {
          index++;

          return {
            value: values[index],

            done: index >= values.length,
          };
        },
      };
    },
  };
};

import React from 'react';
import {
  ErrorNotificationImage,
  SuccessNotificationImage,
} from 'assets/images/visa';
import { toast } from 'react-toastify';

const convertMessageFromAPI = message => {
  if (typeof message === 'string') {
    return message;
  }

  if (Array.isArray(message)) {
    return Object.values(message.at()).at();
  }

  if (typeof message === 'object') {
    return Object.values(message).at();
  }
};

const toastConfig = {
  autoClose: 5000,
  position: 'top-center',
  theme: 'colored',
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  onClose: undefined,
};

export const showToastError = text => {
  toast.error(convertMessageFromAPI(text), {
    ...toastConfig,
    icon: <ErrorNotificationImage />,
  });
};

export const showToastSuccess = text => {
  toast.success(convertMessageFromAPI(text), {
    ...toastConfig,
    icon: <SuccessNotificationImage />,
  });
};

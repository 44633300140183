import { createQueryActions } from 'helpers/store/createQueryActions';

export const getApplicants = createQueryActions('getApplicants');

export const getApplicant = createQueryActions('getApplicant');

export const updateApplicant = createQueryActions('updateApplicant');

export const createApplicant = createQueryActions('createApplicant');

export const inviteApplicant = createQueryActions('inviteApplicant');

export const deleteApplicant = createQueryActions('deleteApplicant');

import { EMPTY_VALUE_WITH_LABEL } from 'common/data/constants';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from 'reactstrap';
import styled, { css } from 'styled-components';

export const StyledDropdownToggle = styled(DropdownToggle)`
  background: #495057;
  border: 0;
  padding: 8px 12px;
  color: white;
  max-height: 40px;
  min-height: 40px;

  ${({ $type, theme }) =>
    $type === 'gray' &&
    css`
      background-color: ${theme.colors.gray200};
      color: ${theme.colors.mainText};
    `}
`;

export const StyledClearButton = styled(StyledEmptyButton)`
  position: absolute !important;
  right: 25px;
`;

export const StyledDropDownMenu = styled(DropdownMenu)`
  border-radius: 8px;
  max-height: 200px;
  width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: -4px 8px 12px rgb(74 87 102 / 30%);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #0000;
    border-radius: 20px;
  }
`;

export const StyledState = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const StyledStatus = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const StatusPicker = memo(
  ({
    value,
    handlePickStatus,
    className,
    statuses,
    $type,
    placeholder = 'common.state',
    empty_value_with_label,
    disabled = false,
  }) => {
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const currentValues = useMemo(
      () => [
        empty_value_with_label
          ? empty_value_with_label
          : EMPTY_VALUE_WITH_LABEL,
        ...statuses,
      ], //.filter(status => status?.label !== value?.label),
      [statuses, value],
    );

    const handleChangeSearch = text => setSearch(text);
    const isSelected =
      typeof value === 'object'
        ? value?.value
          ? true
          : false
        : value
        ? true
        : false;
    const getFilteredOptions = useMemo(() => {
      if (!search) {
        return currentValues;
      }

      return currentValues.filter(values =>
        values.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }, [currentValues, search]);

    return (
      <UncontrolledDropdown className={className}>
        <StyledDropdownToggle
          $type={$type}
          className="d-flex flex-row align-items-center justify-content-center"
          style={isSelected ? { background: '#495057', color: 'white' } : {}}
        >
          <StyledStatus className="text-nowrap">
            <StyledState className="me-1">{t(placeholder)}:</StyledState>
            {t(value?.label)}
          </StyledStatus>
        </StyledDropdownToggle>

        <StyledDropDownMenu>
          {statuses.length > 5 && (
            <div tabIndex={0} role="menuitem" className="px-3 d-flex mb-2">
              <Input
                onChange={event => handleChangeSearch(event.target.value)}
                value={search}
                tabIndex={0}
                role="menuitem"
                placeholder={t('common.search')}
              />

              {search.length > 0 && (
                <StyledClearButton
                  className="d-flex align-items-center py-2"
                  onClick={() => handleChangeSearch('')}
                >
                  <i className="bx bx-x" />
                </StyledClearButton>
              )}
            </div>
          )}

          {getFilteredOptions.map(status => (
            <DropdownItem
              disabled={disabled}
              className="text-wrap"
              onClick={() => {
                handlePickStatus(status);
                handleChangeSearch('');
              }}
              key={status.label}
            >
              {t(status.label)}
            </DropdownItem>
          ))}

          {getFilteredOptions.length === 0 && (
            <div className="py-2 text-muted fs-14 text-center">
              {t('common.optionsNotFound')}
            </div>
          )}
        </StyledDropDownMenu>
      </UncontrolledDropdown>
    );
  },
);

import React from 'react';
import { Button, FormGroup, Input } from 'reactstrap';

// Redux
import FormikInput from 'components/Common/FormikInput';
import { BlueLink, StyledPhoneInput } from 'components/Common';
import { StyledTextHead } from 'pages/Authentication/Login';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { Label } from 'components/Services/ServiceForm';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';

export const StepRegistrationForm = ({
  registrationSteps,
  registrationStep,
  setRegistrationStep,
  extraContent,
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <form onSubmit={formik.handleSubmit}>
      {registrationSteps[registrationStep]?.prevStep && (
        <p className="mb-4">
          <StyledEmptyButton
            className="text-black d-flex align-items-center"
            onClick={() =>
              setRegistrationStep(registrationSteps[registrationStep].prevStep)
            }
          >
            <i className="bx bx-arrow-back me-2" />
            {t('auth.back')}
          </StyledEmptyButton>
        </p>
      )}

      <StyledTextHead className="mb-4">
        {t(registrationSteps[registrationStep]?.title)}
      </StyledTextHead>

      {registrationSteps[registrationStep].extraComponents}

      {registrationSteps[registrationStep]?.inputs &&
        registrationSteps[registrationStep]?.inputs.map(field => (
          <FormikInput
            key={field.key}
            label={t(field.label)}
            type={field.type}
            name={field.name}
            groupStyle="mb-0"
            onBlur={formik.handleBlur}
            placeholder={field.placeholder}
            value={formik.values[field.name]}
            handleChange={formik.handleChange}
            errors={
              formik.errors[field.name] && formik.touched[field.name]
                ? formik.errors[field.name]
                : null
            }
            customInput={(() => {
              switch (field.type) {
                case 'countrySelect':
                  return (
                    <SelectCountry
                      name={field.name}
                      onChange={country => {
                        formik.setFieldValue(
                          field.name,
                          country?.value ?? null,
                        );
                        formik.setFieldTouched(field.name, true, false);
                      }}
                      value={formik.values[field.name]}
                      invalid={
                        formik.errors[field.name] && formik.touched[field.name]
                          ? formik.errors[field.name]
                          : null
                      }
                    />
                  );
                case 'phone':
                  return (
                    <StyledPhoneInput
                      name={field.name}
                      onBlur={formik.handleBlur}
                      value={formik.values[field.name]}
                      onChange={value =>
                        formik.setFieldValue(field.name, value)
                      }
                      errors={
                        formik.errors[field.name] && formik.touched[field.name]
                          ? formik.errors[field.name]
                          : null
                      }
                    />
                  );
                default:
                  return null;
              }
            })()}
          />
        ))}

      {registrationStep === 'password' && (
        <FormGroup className="mb-4">
          <Label className="m-0 d-flex align-items-center">
            <Input
              type="checkbox"
              invalid={formik.errors.termOfuse && formik.touched.termOfuse}
              style={{ margin: '0px 12px 0px 0px' }}
              name="termOfuse"
              onChange={formik.handleChange}
              checked={formik.values.termOfuse}
            />
            {t('auth.iAgree')}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://visaoffice.eu/terms-conditions-page/"
              className="ms-1 text-primary"
            >
              {t('auth.termsOfUses')}
            </a>
          </Label>
        </FormGroup>
      )}

      {extraContent}

      {!registrationSteps[registrationStep].extraComponents && (
        <>
          <div className="mt-2">
            <Button
              block
              color="primary"
              type="submit"
              style={{ padding: '12px 0' }}
            >
              {t(
                registrationStep === 'password'
                  ? 'auth.signUp'
                  : 'common.continue',
              )}
            </Button>
          </div>

          {registrationStep === 'names' && (
            <div className="w-100 d-flex mt-4 justify-content-center">
              <p className="fw-500 fs-6">
                {t('auth.alreadyHaveAccount')}{' '}
                <BlueLink to="/login" className="text-decoration-underline">
                  {t('auth.login')}
                </BlueLink>
              </p>
            </div>
          )}
        </>
      )}
    </form>
  );
};

import { StyledUnblock } from 'components/Common/UserControlButtons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const InviteButton = ({ handleInvite, isDisabled, title }) => {
  const { t } = useTranslation();

  return (
    <StyledUnblock
      className="w-100 border-0 d-flex justify-content-center"
      onClick={handleInvite}
      disabled={isDisabled}
    >
      <p className="mb-0">{title ? title : t('invites.invite')}</p>
    </StyledUnblock>
  );
};

import { StyledButtonCreate } from 'components/Common/ButtonCreate';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AddServiceButton = ({
  isActiveAdd,
  handleAddService,
  handleCancelAddService,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 d-flex align-items-center justify-content-end">
      <StyledButtonCreate
        className="py-2 me-4"
        color="primary"
        style={{ padding: '0px 32px' }}
        onClick={handleAddService}
        disabled={!isActiveAdd}
      >
        {t('common.add')}
      </StyledButtonCreate>

      <StyledUpload
        style={{ padding: '0px 32px' }}
        className="py-2"
        onClick={handleCancelAddService}
      >
        {t('common.cancel')}
      </StyledUpload>
    </div>
  );
};

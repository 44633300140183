import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label, Row } from 'reactstrap';
import { DEFAULT_POINTS } from 'helpers/styles/constants';

export const OrderTypeEditDates = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <>
      <Row className="d-flex mt-3 flex-wrap">
        <Col
          xs={12}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Doocuments from consulate</Label>
        </Col>

        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Estimated at</Label>

          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableDocumentsFromConsEstimatedAt}
            onChange={() =>
              formik.setFieldValue(
                'enableDocumentsFromConsEstimatedAt',
                !formik.values.enableDocumentsFromConsEstimatedAt,
              )
            }
          />
        </Col>

        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Actual at</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableDocumentsFromConsActualAt}
            onChange={() =>
              formik.setFieldValue(
                'enableDocumentsFromConsActualAt',
                !formik.values.enableDocumentsFromConsActualAt,
              )
            }
          />
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          xs={12}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Doocuments to consulate</Label>
        </Col>
        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Estimated at</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableDocumentsToConsEstimatedAt}
            onChange={() =>
              formik.setFieldValue(
                'enableDocumentsToConsEstimatedAt',
                !formik.values.enableDocumentsToConsEstimatedAt,
              )
            }
          />
        </Col>

        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Actual at</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableDocumentsToConsActualAt}
            onChange={() =>
              formik.setFieldValue(
                'enableDocumentsToConsActualAt',
                !formik.values.enableDocumentsToConsActualAt,
              )
            }
          />
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          xs={12}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Doocuments to applicant</Label>
        </Col>
        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Estimated at</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableDocumentsToApplicantEstimatedAt}
            onChange={() =>
              formik.setFieldValue(
                'enableDocumentsToApplicantEstimatedAt',
                !formik.values.enableDocumentsToApplicantEstimatedAt,
              )
            }
          />
        </Col>
        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Actual at</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableDocumentsToApplicantActualAt}
            onChange={() =>
              formik.setFieldValue(
                'enableDocumentsToApplicantActualAt',
                !formik.values.enableDocumentsToApplicantActualAt,
              )
            }
          />
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          xs={12}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Gulltig</Label>
        </Col>
        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">From</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableGultigFrom}
            onChange={() =>
              formik.setFieldValue(
                'enableGultigFrom',
                !formik.values.enableGultigFrom,
              )
            }
          />
        </Col>
        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">To</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableGultigTo}
            onChange={() =>
              formik.setFieldValue(
                'enableGultigTo',
                !formik.values.enableGultigTo,
              )
            }
          />
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          className="p-0 d-flex align-items-center v-align-top"
          {...DEFAULT_POINTS.label}
        >
          <Label className="m-0 display-5">
            {t('orderTypes.enableFirstDateOfEntry')}
          </Label>

          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableFirstDateOfEntry}
            onChange={() =>
              formik.setFieldValue(
                'enableFirstDateOfEntry',
                !formik.values.enableFirstDateOfEntry,
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

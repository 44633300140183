import React, { useEffect, useState } from 'react';
import { Col, Row, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getClientFilter, deleteClient } from 'store/clients/actions';
import { useHistory } from 'react-router-dom';
import {
  CLIENT_STATUS,
  CLIENT_TYPES,
  EMPTY_VALUE_WITH_LABEL,
} from 'common/data/constants';
import { FIRST_PAGE, PAGES } from '../../common/data/constants';
import { debounce } from 'lodash';
import { PaginationLm } from 'components/Common/PaginationLm';
import { Layout } from 'components/Layout';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { StyledTable } from 'components/Table/StyledTable';
import { ButtonCreate } from 'components/Common/ButtonCreate';
import { ShadowCard } from 'components/Common/ShadowCard';
import { Badge, BlueLink, Loader } from 'components/Common';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { typeColor } from 'helpers/styles/typeColor';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { ButtonControlTable } from 'components/Tariffs/ButtonControlTable';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';

const getClientsState = state => state.clients;

const Clients = () => {
  const { clients, isLoading } = useSelector(getClientsState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [searchState, setSearchState] = useState({
    page: FIRST_PAGE,
    search: '',
    perPage: PAGES[0],
    status: EMPTY_VALUE_WITH_LABEL,
    clientType: EMPTY_VALUE_WITH_LABEL,
  });

  const { handleCloseModal, handleOpenModal, isOpen } = useModal();

  useEffect(() => {
    const { page, search, perPage, clientType, status } = searchState;

    dispatch(
      getClientFilter({
        client_type: clientType.value,
        search,
        page,
        status: status.value,
        page_size: perPage.value,
      }),
    );
  }, [searchState]);

  const showPagination = searchState.perPage.value < clients.count;

  const handleChangeSearchParam = (key, value) => {
    if (key === 'page') {
      setSearchState(searchState => ({ ...searchState, page: value }));
      return;
    }

    setSearchState(searchState => ({
      ...searchState,
      page: FIRST_PAGE,
      [key]: value,
    }));
  };

  const getClientsActions = client => {
    const pathToClient = `/clients/${client.id}/${client.client_type.client_type}`;

    return [
      {
        title: 'common.open',
        id: 1,
        function: () => push(pathToClient),
      },
      {
        title: 'common.delete',
        id: 2,
        function: () => handleOpenModal(client),
      },
    ];
  };

  const handleDeleteClient = client => {
    dispatch(deleteClient({ client, handleCloseModal }));
  };

  return (
    <Layout
      title="sideBar.clients"
      extraContent={
        <ButtonCreate
          handleCreate={() => push('/clients/create', { isEdit: true })}
        />
      }
    >
      <ShadowCard>
        <Row className="align-items-center mb-3">
          <Col xxl={4} md={4} xs={3}>
            <SearchInput
              defaultValue={searchState.search}
              handleChange={debounce(
                event => handleChangeSearchParam('search', event.target.value),
                200,
              )}
            />
          </Col>

          <Col xs={5} className="d-flex ps-0">
            <StatusPicker
              placeholder="client.clientType"
              className="me-3"
              $type="gray"
              statuses={CLIENT_TYPES}
              handlePickStatus={value =>
                handleChangeSearchParam('clientType', value)
              }
              value={searchState.clientType}
            />

            <StatusPicker
              placeholder="common.status"
              $type="gray"
              className="me-3"
              statuses={CLIENT_STATUS}
              handlePickStatus={value =>
                handleChangeSearchParam('status', value)
              }
              value={searchState.status}
            />
          </Col>

          <Col xs={3}>
            <PerPageSelect
              perPage={searchState.perPage}
              setPage={page => handleChangeSearchParam('page', page)}
              setPerPage={perPage =>
                handleChangeSearchParam('perPage', perPage)
              }
            />
          </Col>
        </Row>

        {isLoading && (
          <Row
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <Loader isLoading={isLoading} />
          </Row>
        )}

        {!isLoading && clients.results?.length === 0 && (
          <Alert color={typeColor.info}>{t('client.notFound')}</Alert>
        )}

        {isOpen && (
          <ModalConfirmDelete
            isOpenModal={isOpen}
            textHeader="client.delete"
            textBody="client.deleteConfirm"
            handleCloseModal={handleCloseModal}
            handleDelete={() => handleDeleteClient(isOpen)}
          />
        )}

        {!isLoading && clients.results?.length > 0 && (
          <>
            <div className="table-responsive">
              <StyledTable>
                <thead>
                  <tr>
                    <th>{t('common.name')}</th>

                    <th>{t('common.type')}</th>

                    <th>{t('common.status')}</th>

                    <th />
                  </tr>
                </thead>

                <tbody>
                  {clients.results.map(client => (
                    <tr key={`${client.client_type.client_type}-${client.id}`}>
                      <td className="col-2">
                        <BlueLink
                          to={{
                            pathname: `/clients/${client.id}/${client.client_type.client_type}`,
                          }}
                        >
                          {client.name}
                        </BlueLink>
                      </td>

                      <td className="col-4">
                        {client.client_type.client_type_display}
                      </td>

                      <td>
                        <Badge
                          text={client.status}
                          color={convertStatusToColor(client.status)}
                        />
                      </td>

                      <td>
                        <ButtonControlTable
                          actions={getClientsActions(client)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>

            <Col xs={12}>
              {showPagination && (
                <PaginationLm
                  count={clients.count}
                  perPage={searchState.perPage.value}
                  active={searchState.page}
                  selectPage={page => handleChangeSearchParam('page', page)}
                />
              )}
            </Col>
          </>
        )}
      </ShadowCard>
    </Layout>
  );
};

export default Clients;

import { getProfileState } from 'components/CommonForBoth/TopbarDropdown/ProfileMenu';
import { StyledAvatar } from 'pages/Chat_new/ChatCard';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';

const StyledEmptyDropdownToggle = styled(DropdownToggle)`
  padding: 0px;
  border: none;

  && {
    background-color: ${({ theme }) => theme.colors.transparent};
  }
`;

export const UserActionDropDown = () => {
  const user = useSelector(getProfileState);
  const { t } = useTranslation();
  const { push } = useHistory();

  const userName = useMemo(
    () => `${user?.first_name ?? ''} ${user?.last_name ?? ''}`,
    [user?.first_name, user?.last_name],
  );

  const userActions = [
    {
      title: 'profile.profile',
      onClick: () => push('/profile'),
    },
    {
      title: 'auth.logout',
      onClick: () => push('/logout'),
    },
  ];

  return (
    <UncontrolledDropdown>
      <StyledEmptyDropdownToggle>
        <StyledAvatar name={userName} size={40} maxInitials={2} round />
      </StyledEmptyDropdownToggle>

      <DropdownMenu>
        {userActions.map(action => (
          <DropdownItem key={action.title} onClick={action.onClick}>
            {t(action.title)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ShadowCard } from 'components/Common/ShadowCard';
import FormikInput from 'components/Common/FormikInput';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { StyledPhoneInput } from 'components/Common/StyledPhoneInput';
import { Select } from 'components/Common';
import { useDispatch, useSelector } from 'react-redux';
import { collectAddresses, selectAddresses } from 'store/addresses/actions';
import { useTranslation } from 'react-i18next';
import { GERMANY_PHONE_CODE } from 'common/data/constants';
import styled from 'styled-components';

export const StyledError = styled.span`
  font-size: 12px;
  padding: 0 !important;
  margin: 0 !important;
`;

export const DeliveryForm = ({ prefix, title, className, formik }) => {
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);

  const { t } = useTranslation();

  const {
    availableAddresses,
    // selectedAddressValueFrom,
    // selectedAddressValueTo,
  } = useSelector(state => state.addresses.addresses);

  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(collectAddresses());
  }, [formik.values]);

  const formikAddressFrom = formik.values.from;
  const formikAddressTo = formik.values.to;

  const getDeliveryAddresses = () => {
    if (prefix === 'from') {
      // The following replaced right below

      // if (prefix === 'from') {
      //   return selectedAddressValueTo !== undefined
      //     ? availableAddresses.filter(
      //         item => item.value !== selectedAddressValueTo,
      //       )
      //     : availableAddresses;
      // }

      // return selectedAddressValueFrom !== undefined
      //   ? availableAddresses.filter(
      //       item => item.value !== selectedAddressValueFrom,
      //     )
      //   : availableAddresses;

      return formik.values?.from
        ? availableAddresses.filter(
            item =>
              !(
                item?.address?.address1 === formikAddressFrom.address &&
                item.address?.city === formikAddressFrom.city &&
                item.address?.country?.id === formikAddressFrom.country &&
                item.address?.email === formikAddressFrom.email &&
                item.address?.name === formikAddressFrom.name &&
                item.address?.phone === formikAddressFrom.phone &&
                item.address?.postal_code === formikAddressFrom.code
              ),
          )
        : // ? availableAddresses
          availableAddresses;
    }

    return formik.values?.to
      ? availableAddresses.filter(
          item =>
            !(
              item?.address?.address1 === formikAddressTo.address &&
              item.address?.city === formikAddressTo.city &&
              item.address?.country?.id === formikAddressTo.country &&
              item.address?.email === formikAddressTo.email &&
              item.address?.name === formikAddressTo.name &&
              item.address?.phone === formikAddressTo.phone &&
              item.address?.postal_code === formikAddressTo.code
            ),
        )
      : availableAddresses;
  };

  useEffect(() => {
    // setSelectedOption();
    setSelectedAddress();

    if (prefix === 'from') {
      if (formikAddressFrom.city) {
        setSelectedOption(formikAddressFrom);
        setSelectedAddress({
          address: formikAddressFrom,
          label: `${formikAddressFrom.companyName} ${formikAddressFrom.name} - ${formikAddressFrom.city} ${formikAddressFrom.address}`,
          value: 'config-address',
        });
      }
    } else {
      if (formikAddressTo.city) {
        setSelectedOption(formikAddressTo);
        setSelectedAddress({
          address: formikAddressTo,
          label: `${formikAddressTo.companyName} ${formikAddressTo.name} - ${formikAddressTo.city} ${formikAddressTo.address}`,
          // value: 'config-address'
        });
      }
    }
  }, [formik.values]);

  const handleChange = value => {
    formik.setFieldValue(`${prefix}_addressSelector`, value.value);

    setSelectedOption(value);

    if (value.value === 'other') {
      formik.setFieldValue(`${prefix}.name`, '');
      formik.setFieldValue(`${prefix}.email`, '');
      formik.setFieldValue(`${prefix}.phone`, '');
      formik.setFieldValue(`${prefix}.address`, '');
      formik.setFieldValue(`${prefix}.code`, '');
      formik.setFieldValue(`${prefix}.city`, '');
      formik.setFieldValue(`${prefix}.state`, '');
      formik.setFieldValue(`${prefix}.country`, '');
      formik.setFieldValue(`${prefix}.companyName`, '');

      dispatch(
        selectAddresses({
          prefix:
            prefix === 'to'
              ? 'selectedAddressValueTo'
              : 'selectedAddressValueFrom',
          value: undefined,
        }),
      );
    }

    if (value.value !== 'other') {
      formik.setFieldValue(`${prefix}.name`, value.address.name || '');
      formik.setFieldValue(`${prefix}.email`, value.address.email || '');
      formik.setFieldValue(`${prefix}.phone`, value.address.phone || '');
      formik.setFieldValue(
        `${prefix}.address`,
        value.address.address || value.address.address1 || '',
      );
      formik.setFieldValue(
        `${prefix}.code`,
        value.address.postalCode || value.address.postal_code || '',
      );
      formik.setFieldValue(`${prefix}.city`, value.address.city || '');
      formik.setFieldValue(`${prefix}.state`, value.address.state || '');
      formik.setFieldValue(
        `${prefix}.country`,
        value.address.country?.id || null,
      );
      formik.setFieldValue(
        `${prefix}.companyName`,
        value.address.company || '',
      );

      dispatch(
        selectAddresses({
          prefix:
            prefix === 'to'
              ? 'selectedAddressValueTo'
              : 'selectedAddressValueFrom',
          value: value.value,
        }),
      );
    }
  };

  return (
    <ShadowCard
      className={className}
      bodyStyle={{ backgroundColor: '#F8F8FB', borderRadius: '8px' }}
    >
      <Row className="align-items-center">
        <Col xs={2}>{t(title)}</Col>

        <Col xs={10}>
          <Select
            name={`${prefix}.select_address`}
            classNamePrefix="select2-selection"
            options={getDeliveryAddresses()}
            value={selectedAddress}
            onChange={handleChange}
          />
          {formik.errors[prefix]?.select_address && (
            <StyledError className="text-danger mt-1">
              {formik.errors[prefix]?.select_address}
            </StyledError>
          )}
        </Col>
      </Row>

      {selectedOption && (
        <Row className="p-0 mt-3">
          <Col lg={6}>
            <FormikInput
              value={formik.values[prefix]?.companyName}
              placeholder="Enter company name"
              label="Company"
              name={`${prefix}.companyName`}
              handleChange={formik.handleChange}
              maxLength={35}
              errors={
                formik.errors[prefix]?.companyName
                // && formik.touched[prefix]?.companyName
              }
              // onBlur={formik.handleBlur}
            />

            <FormikInput
              value={formik.values[prefix]?.name}
              placeholder="delivery.namePlaceholder"
              label="delivery.name"
              name={`${prefix}.name`}
              handleChange={formik.handleChange}
              maxLength={35}
              errors={
                formik.errors[prefix]?.name
                //  && formik.touched[prefix]?.name
              }
              // onBlur={formik.handleBlur}
            />

            <FormikInput
              label="common.phone"
              errors={
                formik.errors[prefix]?.phone && formik.touched[prefix]?.phone
              }
              name={`${prefix}.phone`}
              // onBlur={formik.handleBlur}
              customInput={
                <StyledPhoneInput
                  value={formik.values[prefix]?.phone || GERMANY_PHONE_CODE}
                  placeholder={t('common.phonePlaceholder')}
                  onChange={event =>
                    formik.setFieldValue(`${prefix}.phone`, event)
                  }
                  errors={
                    formik.errors[prefix]?.phone
                    // && formik.touched[prefix]?.phone
                  }
                />
              }
            />

            <FormikInput
              value={formik.values[prefix]?.email}
              placeholder="auth.enterEmail"
              label="auth.email"
              name={`${prefix}.email`}
              // onBlur={formik.handleBlur}
              type="email"
              handleChange={formik.handleChange}
              errors={
                formik.errors[prefix]?.email
                // && formik.touched[prefix]?.email
              }
            />

            <FormikInput
              value={formik.values[prefix]?.code}
              placeholder="delivery.postalCodePlaceholder"
              label="delivery.postalCode"
              name={`${prefix}.code`}
              // onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              maxLength={9}
              errors={
                formik.errors[prefix]?.code
                // && formik.touched[prefix]?.code
              }
            />
          </Col>

          <Col lg={6}>
            <FormikInput
              errors={
                formik.errors[prefix]?.country &&
                formik.touched[prefix]?.country
              }
              label="common.country"
              // onBlur={formik.handleBlur}
              name={`${prefix}.country`}
              customInput={
                <SelectCountry
                  isAvailableDelivery
                  value={formik.values[prefix]?.country}
                  placeholder={'common.countryPlaceholder'}
                  onChange={option =>
                    formik.setFieldValue(
                      `${prefix}.country`,
                      option?.value ?? null,
                    )
                  }
                  invalid={
                    formik.errors[prefix]?.country
                    // && formik.touched[prefix]?.country
                  }
                />
              }
            />

            <FormikInput
              value={formik.values[prefix]?.state}
              placeholder="delivery.statePlaceholder"
              label="delivery.state"
              name={`${prefix}.state`}
              // onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              errors={
                formik.errors[prefix]?.state
                // && formik.touched[prefix]?.state
              }
            />

            <FormikInput
              value={formik.values[prefix]?.city}
              placeholder="delivery.cityPlaceholder"
              label="delivery.city"
              // onBlur={formik.handleBlur}
              name={`${prefix}.city`}
              handleChange={formik.handleChange}
              maxLength={30}
              errors={
                formik.errors[prefix]?.city
                // && formik.touched[prefix]?.city
              }
            />

            <FormikInput
              value={formik.values[prefix]?.address}
              placeholder="delivery.addressPlaceholder"
              label="delivery.address"
              name={`${prefix}.address`}
              className="h-100"
              // onBlur={formik.handleBlur}
              type="textarea"
              style={{
                resize: 'none',
                minHeight: '146px',
              }}
              handleChange={formik.handleChange}
              maxLength={35}
              errors={
                formik.errors[prefix]?.address
                // && formik.touched[prefix]?.address
              }
            />
          </Col>
        </Row>
      )}
    </ShadowCard>
  );
};

import { typeColor } from 'helpers/styles/typeColor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge as BadgeBootstrap, Button } from 'reactstrap';
import styled, { css } from 'styled-components';

const StyledBadge = styled(BadgeBootstrap)`
  padding: 2px 8px;
  font-weight: 600;
  font-size: 11px !important;
  line-height: 16px;
  text-transform: uppercase;

  ${({ type, theme }) => {
    switch (type) {
      case typeColor.primary:
        return css`
          color: ${theme.colors.primary} !important;
          background-color: ${theme.colors.blue100} !important;
        `;
      case typeColor.danger:
        return css`
          color: ${theme.colors.red500} !important;
          background-color: ${theme.colors.red200} !important;
        `;
      case typeColor.dark:
        return css`
          color: ${theme.colors.mainText} !important;
          background-color: ${theme.colors.gray100} !important;
        `;
      case typeColor.info:
        return css`
          color: ${theme.colors.primary} !important;
          background-color: ${theme.colors.blue100} !important;
        `;
      case typeColor.secondary:
        return css`
          color: ${theme.colors.mainText} !important;
          background-color: ${theme.colors.gray100} !important;
        `;
      case typeColor.success:
        return css`
          color: ${theme.colors.green500} !important;
          background-color: ${theme.colors.green100} !important;
        `;
      case typeColor.warning:
        return css`
          color: ${theme.colors.primary} !important;
          background-color: ${theme.colors.blue100} !important;
        `;

      default:
        return css`
          color: ${theme.colors.white} !important;
          background-color: ${theme.colors.gray400} !important;
        `;
    }
  }};
`;

const StyledBadgeButton = styled(Button)`
  padding: 2px 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  border: none;

  ${({ $type, theme }) => {
    switch ($type) {
      case typeColor.primary:
        return css`
          color: ${theme.colors.primary}!important;
          background-color: ${theme.colors.blue100}!important;
        `;
      case typeColor.danger:
        return css`
          color: ${theme.colors.red500}!important;
          background-color: ${theme.colors.red200}!important;
        `;
      case typeColor.dark:
        return css`
          color: ${theme.colors.mainText}!important;
          background-color: ${theme.colors.gray100}!important;
        `;
      case typeColor.info:
        return css`
          color: ${theme.colors.primary}!important;
          background-color: ${theme.colors.blue100}!important;
        `;
      case typeColor.secondary:
        return css`
          color: ${theme.colors.mainText}!important;
          background-color: ${theme.colors.gray100}!important;
        `;
      case typeColor.success:
        return css`
          color: ${theme.colors.green500}!important;
          background-color: ${theme.colors.green100}!important;
        `;
      case typeColor.warning:
        return css`
          color: ${theme.colors.primary}!important;
          background-color: ${theme.colors.blue100}!important;
        `;

      default:
        return css`
          color: ${theme.colors.white}!important;
          background-color: ${theme.colors.gray400}!important;
        `;
    }
  }}
`;

export const Badge = ({ color, text, className, onClick }) => {
  const { t } = useTranslation();

  return onClick ? (
    <StyledBadgeButton onClick={onClick} className={className} $type={color}>
      {t(text)}
    </StyledBadgeButton>
  ) : (
    <StyledBadge className={className} type={color} pill>
      {t(text)}
    </StyledBadge>
  );
};

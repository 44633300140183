import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { SelectBox } from './CustomSelect';
import { getCities } from '../../helpers/api-requests/admin';
import { useTranslation } from 'react-i18next';

const CitiesSelect = props => {
  const { countryId = 183, value = [], update } = props;
  const {
    i18n: { language },
  } = useTranslation();
  const [citiesList, setCitiesList] = useState([]);

  const getCitiesList = () => {
    if (citiesList.length === 0) {
      getCities({ countryId })
        .then(response => {
          const results = response?.data?.results ?? [];
          if (results.length > 0) {
            const citiesStrings = results.map(({ translations }) => ({
              value: translations[language],
              label: translations[language],
            }));
            setCitiesList(citiesStrings);
            return;
          }
          setCitiesList([]);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getCitiesList();
  }, []);

  const handleChange = newValue => {
    update(newValue);
  };

  return (
    <SelectBox
      isMulti
      SelectComponent={CreatableSelect}
      options={citiesList}
      value={value}
      isSearchable
      getOptionLabel={option => option.label}
      getOptionValue={option => option.value}
      onChange={handleChange}
    />
  );
};

export default CitiesSelect;

import React from 'react';
import { Select } from 'components/Common/Select';
import { useMemo } from 'react';
import { convertVisaOptions } from './utils';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

export const VisaDurationSelector = ({
  value,
  onChange,
  error,
  visaType,
  currentVisaTypes,
  isDisable,
  isClearable = true,
}) => {
  const visaTypes = useSelector(state => state.references.visaTypes);
  const { t } = useTranslation();

  const currentVisa = currentVisaTypes
    ? currentVisaTypes.find(visa => visa.id === visaType)
    : visaTypes.find(visa => visa.id === visaType);

  const options = useMemo(() => {
    return convertVisaOptions(currentVisa?.durations ?? []);
  }, [currentVisa?.durations, i18n.language]);

  const val = useMemo(() => {
    if (isNil(value)) {
      return value;
    }
    return options.find(item => item.value === value) ?? null;
  }, [options, value]);

  return (
    <Select
      className="w-100"
      placeholder={t('order.validTermVisaPlaceholder')}
      options={options}
      value={val}
      isClearable={isClearable}
      isDisabled={!currentVisa?.durations || options.length === 0 || isDisable}
      onChange={onChange}
      classNamePrefix="select2-selection"
      invalid={Boolean(error)}
    />
  );
};

import React, { memo, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { getLayoutState } from 'components/VerticalLayout';
import { StyledAvatar } from 'pages/Chat_new/ChatCard';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  background: none !important   ;
  color: ${({ theme }) => theme.colors.sideBarImage} !important;
  font-size: 24px;
  padding: 0 !important;

  &:hover {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

const StyledUserName = styled.div`
  span {
    color: ${({ theme }) => theme.colors.white} !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    color: ${({ theme }) => theme.colors.sideBarText} !important;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
`;

export const getProfileState = state => state.clientCabinet.profile;

const ProfileMenu = memo(() => {
  const user = useSelector(getProfileState);
  const { t } = useTranslation();
  const typeSideBar = useSelector(getLayoutState);
  const history = useHistory();

  const userName = useMemo(
    () => `${user?.first_name ?? ''} ${user?.last_name ?? ''}`,
    [user?.first_name, user?.last_name],
  );
  const roleName = useMemo(() => {
    if (user?.is_operator && user?.is_admin) return 'roles.admin';
    if (user?.is_operator) return 'roles.operator';
    if (user?.is_manager) return 'roles.manager';
    if (user?.is_client_single) return 'client.individual';
    if (user?.is_employee) return 'roles.employee';
  }, [user]);

  return (
    <div className="d-flex align-items-center w-100 justify-content-center">
      <Link to="/profile">
        <StyledAvatar name={userName} size={40} maxInitials={2} round />
      </Link>

      {typeSideBar && (
        <>
          <StyledUserName className="d-flex justify-content-center flex-column ms-2 w-75">
            <Link to="/profile">
              <span>
                {userName?.length > 15
                  ? `${user?.first_name} ${user?.last_name?.[0]}.`
                  : userName}
              </span>
            </Link>

            <p>{t(roleName)}</p>
          </StyledUserName>

          <div>
            <StyledButton
              onClick={() => history.push('/logout')}
              className="dropdown-item d-flex justify-content-end"
            >
              <i className="bx bx-log-out"></i>
            </StyledButton>
          </div>
        </>
      )}
    </div>
  );
});

export default ProfileMenu;

import { call, put, takeEvery } from 'redux-saga/effects';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';
import i18n from 'i18n';
import {
  getApplicants,
  getApplicant,
  updateApplicant,
  inviteApplicant,
} from './actions';
import {
  fetchApplicants,
  fetchApplicant,
  putApplicant,
  inviteApplicantRequest,
  deleteApplicantRequest,
} from 'helpers/api-requests/manager';
import { deleteApplicant } from 'store/applicants/actions';
import { isUserEmployee } from 'helpers/jwt_helper';
import { fetchEmployee, putEmployee } from 'helpers/api-requests/employee';

function* getApplicantsSaga({ payload }) {
  try {
    const response = yield call(fetchApplicants, payload);

    if (response.success) {
      yield put(
        getApplicants.success({
          applicants: response.data.results,
          count: response.data.count,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(getApplicants.failure(response.data));
    }
  } catch (error) {
    showToastError(i18n.t('notifications.errorApplicants'));
    yield put(getApplicants.failure(error));
  }
}

function* getApplicantSaga({ payload }) {
  try {
    const response = yield call(
      isUserEmployee ? fetchEmployee : fetchApplicant,
      payload,
    );

    if (response.success) {
      yield put(getApplicant.success(response.data));
    } else {
      showToastError(response.message);
      yield put(getApplicant.failure(response.data));
    }
  } catch (error) {
    showToastError(i18n.t('notifications.errorApplicant'));
    yield put(getApplicant.failure(error));
  }
}

function* updateApplicantSaga({ payload }) {
  try {
    const response = yield call(
      isUserEmployee ? putEmployee : putApplicant,
      payload,
    );

    if (response.success) {
      yield put(updateApplicant.success(response.data));
      showToastSuccess(i18n.t('notifications.applicantUpdate'));
    } else {
      showToastError(response.message);
      yield put(updateApplicant.failure(response.data));
    }
  } catch (error) {
    showToastError(i18n.t('notifications.errorApplicantUpdate'));
    yield put(updateApplicant.failure(error));
  }
}

function* inviteApplicantSaga({ payload }) {
  try {
    const response = yield call(inviteApplicantRequest, payload.id);

    if (response.success) {
      yield put(inviteApplicant.success(payload.id));
      showToastSuccess(i18n.t('notifications.applicantInvited'));
      return;
    }

    showToastError(i18n.t('notifications.errorApplicantInvite'));
  } catch (error) {
    showToastError(i18n.t('notifications.errorApplicantInvite'));
    yield put(inviteApplicant.failure(error));
  }
}

function* deleteApplicantSaga({ payload }) {
  try {
    const response = yield call(deleteApplicantRequest, payload.id);

    if (response.success) {
      yield put(deleteApplicant.success(payload.id));
      showToastSuccess(i18n.t('applicants.deleted'));
      payload.handleCloseModal();
    } else {
      yield put(deleteApplicant.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error.message);
    yield put(deleteApplicant.failure(error));
  }
}

function* applicantsSaga() {
  yield takeEvery(getApplicants, getApplicantsSaga);
  yield takeEvery(inviteApplicant, inviteApplicantSaga);
  yield takeEvery(getApplicant, getApplicantSaga);
  yield takeEvery(updateApplicant, updateApplicantSaga);
  yield takeEvery(deleteApplicant, deleteApplicantSaga);
}

export default applicantsSaga;

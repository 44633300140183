import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const GeneratePDF = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledUpload className="ps-3 pe-3" onClick={onClick}>
      <i className="bx bx-download" /> {t('invoices.downloadPdf')}
    </StyledUpload>
  );
};

import { Col, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { FieldArray, useFormikContext } from 'formik';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { DATE_FORMAT_SHORT } from 'common/data/constants';
import { ShadowCard, CardTitle, StyledField } from 'components/Common';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { StyledInput, StyledLabel } from 'components/Common/FormikInput';
import { addDays } from 'date-fns';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { DEFAULT_POINTS } from 'helpers/styles/constants';
import { useMatchMedia } from 'hooks/useMatchMedia';

const name = 'passports';

export const ApplicantsPassportsCard = () => {
  const { t } = useTranslation();
  const { setFieldValue, values, errors, touched } = useFormikContext();
  const { isMobile } = useMatchMedia();

  const passports = useMemo(() => {
    return values.passports;
  }, [values.passports]);

  return (
    <div id="passports" className="mb-4">
      <ShadowCard bodyStyle={{ padding: 24 }} className="mb-4">
        <CardTitle title="applicants.passports" />

        <FieldArray
          validateOnChange
          name={name}
          render={({ push, remove }) => (
            <>
              {passports?.length > 0 &&
                passports.map((passport, index) => (
                  <StyledField className="p-3 mb-4" key={passport.id ?? index}>
                    <CardTitle
                      isSmallText
                      title={`${t('applicants.passport')} ${
                        passports[index].countryDetail?.shortLabel ?? index + 1
                      }`}
                      extraContent={
                        passports?.length > 1 && (
                          <div className="d-flex justify-content-end w-100">
                            <StyledEmptyButton
                              className="d-flex align-items-center fs-14"
                              type="button"
                              color="gray600"
                              onClick={() => remove(index)}
                            >
                              <i className="bx bx-trash me-2" />

                              {!isMobile && t('common.delete')}
                            </StyledEmptyButton>
                          </div>
                        )
                      }
                    />

                    <Row className={'p-0 align-items-center mb-4'}>
                      <Col {...DEFAULT_POINTS.label}>
                        <StyledLabel
                          invalid={errors.passports?.[index]?.country}
                        >
                          {t('common.country')}
                        </StyledLabel>
                      </Col>

                      <Col {...DEFAULT_POINTS.input}>
                        <SelectCountry
                          isAvailableCitizenship
                          invalid={errors.passports?.[index]?.country}
                          className="w-100"
                          placeholder={t('common.countryPlaceholder')}
                          value={passports[index].country}
                          name={`${name}[${index}].country`}
                          onChange={option => {
                            setFieldValue(
                              `${name}[${index}].country`,
                              option?.value,
                            );

                            setFieldValue(
                              `${name}[${index}].countryDetail`,
                              option,
                            );
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className={'p-0 align-items-center mb-4'}>
                      <Col {...DEFAULT_POINTS.label}>
                        <StyledLabel
                          invalid={
                            errors.passports?.[index]?.number &&
                            touched.passports?.[index]?.number
                          }
                        >
                          {t('common.number')}
                        </StyledLabel>
                      </Col>

                      <Col {...DEFAULT_POINTS.input}>
                        <StyledInput
                          placeholder={t('passport.numberPlaceholder')}
                          value={passports[index].number}
                          name={`${name}.${index}.number`}
                          invalid={errors.passports?.[index]?.number}
                          onChange={option =>
                            setFieldValue(
                              `${name}.${index}.number`,
                              option.target.value,
                            )
                          }
                        />
                      </Col>
                    </Row>

                    <Row className={'p-0 align-items-center mb-4'}>
                      <Col {...DEFAULT_POINTS.label}>
                        <StyledLabel
                          invalid={
                            errors.passports?.[index]?.issue_date &&
                            touched.passports?.[index]?.issue_date
                          }
                        >
                          {t('passport.issueNumber')}
                        </StyledLabel>
                      </Col>

                      <Col {...DEFAULT_POINTS.input} sm={6} lg={5}>
                        <StyledDatePicker
                          name={`${name}.${index}.issue_date`}
                          placeholderText={t('common.datePlaceholder')}
                          invalid={errors.passports?.[index]?.issue_date}
                          selectsStart
                          value={passports[index].issue_date}
                          dateFormat={DATE_FORMAT_SHORT}
                          startDate={passports[index].issue_date}
                          endDate={passports[index].end_date}
                          selected={passports[index].issue_date}
                          onChange={option =>
                            setFieldValue(`${name}.${index}.issue_date`, option)
                          }
                          maxDate={new Date()}
                        />
                      </Col>
                    </Row>

                    <Row className={'p-0 align-items-center'}>
                      <Col {...DEFAULT_POINTS.label}>
                        <StyledLabel
                          invalid={
                            errors.passports?.[index]?.end_date &&
                            touched.passports?.[index]?.end_date
                          }
                        >
                          {t('passport.endDate')}
                        </StyledLabel>
                      </Col>

                      <Col {...DEFAULT_POINTS.input} sm={6} lg={5}>
                        <StyledDatePicker
                          name={`${name}.${index}.end_date`}
                          placeholderText={t('common.datePlaceholder')}
                          invalid={errors.passports?.[index]?.end_date}
                          selectsEnd
                          value={passports[index].end_date}
                          selected={passports[index].end_date}
                          dateFormat={DATE_FORMAT_SHORT}
                          onChange={option =>
                            setFieldValue(`${name}.${index}.end_date`, option)
                          }
                          startDate={passports[index].issue_date}
                          endDate={passports[index].end_date}
                          minDate={
                            addDays(passports[index].issue_date, 1) ?? null
                          }
                        />
                      </Col>
                    </Row>
                  </StyledField>
                ))}

              <StyledUpload
                style={{ fontSize: '14px' }}
                className="d-flex flex-nowrap align-items-center"
                onClick={() =>
                  push({
                    country: null,
                    number: '',
                    issue_date: null,
                    end_date: null,
                  })
                }
              >
                <i className="bx bx-plus fs-4 me-2" />{' '}
                {t('passport.addPassport')}
              </StyledUpload>
            </>
          )}
        />
      </ShadowCard>
    </div>
  );
};

import { CardTitle, ShadowCard } from 'components/Common';
import React from 'react';
import { Row } from 'reactstrap';
import { ServiceLanguage } from './ServiceLanguage';

export const ServiceNameCard = () => {
  return (
    <ShadowCard className="mt-4" bodyStyle={{ padding: '24px' }} id="names">
      <CardTitle title="common.nameAndTranslation" />

      <Row>
        <ServiceLanguage title="languages.en" prefix="en" />

        <ServiceLanguage title="languages.de" prefix="de" />
      </Row>
    </ShadowCard>
  );
};

import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { NO, YES, YES_NO_OPTIONS } from 'common/data/constants';
import {
  Select,
  StyledColBold,
  FormikInput,
  StyledField,
  Accordion,
} from 'components/Common';
import { isUserOperator } from 'helpers/jwt_helper';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';

const formFields = [
  {
    type: 'text',
    name: 'upsName',
    key: 3.1,
    label: 'delivery.name',
    placeholder: 'delivery.namePlaceholder',
    maxLength: 50,
  },

  {
    type: 'text',
    name: 'upsAddressLineFirst',
    key: 3.2,
    label: 'client.addressLineFirst',
    placeholder: 'client.addressLineFirstPlaceholder',
  },

  {
    type: 'text',
    name: 'upsCity',
    key: 3.3,
    label: 'client.city',
    placeholder: 'client.cityPlaceholder',
  },

  {
    type: 'text',
    name: 'upsState',
    key: 3.4,
    label: 'client.state',
    placeholder: 'client.statePlaceholder',
  },

  {
    type: 'text',
    name: 'upsPostalCode',
    key: 3.5,
    label: 'client.zipCode',
    placeholder: 'client.zipCodePlaceholder',
    maxLength: 15,
  },
];

export const ApplicantUPSForm = ({
  currentApplicant,
  index,
  isApplicantInWork,
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const disabled = (!isUserOperator && isApplicantInWork) || isDisabledFields;
  const isDisabledFields = !formik.values.orderDetail?.isEditable;

  return (
    <>
      <Col xs={12} className="mt-4">
        <StyledColBold className="display-4">
          {t('ups.sendDocuments')}
        </StyledColBold>
      </Col>

      <FormGroup row className="align-items-center mt-4">
        <Col xs={12} xl={2}>
          <Label>{t('ups.useShippingLabel')}</Label>
        </Col>

        <Col xs={10} xl={10}>
          <Select
            // isClearable
            className="w-50"
            value={YES_NO_OPTIONS.filter(option => {
              if (currentApplicant?.upsLabel) {
                return option.value === currentApplicant.upsLabel;
              }
              return option.value === NO;
            })}
            options={[...YES_NO_OPTIONS]}
            onChange={option =>
              formik.setFieldValue(
                `applicants[${index}].upsLabel`,
                option.value,
              )
            }
            isDisabled={disabled}
            classNamePrefix="select2-selection"
          />
        </Col>
      </FormGroup>

      {formik.values.applicants?.[index]?.upsLabel === YES && (
        <StyledField className="w-100 mt-4 p-4">
          <Accordion title="order.enterAddress">
            <div className="d-flex flex-wrap mt-4">
              {formFields.map(field => (
                <Col
                  key={field.id}
                  xs={12}
                  // lg={getWeight(index)}
                >
                  <FormikInput
                    name={`applicants[${index}].${field.name}`}
                    disabled={disabled}
                    value={formik.values.applicants?.[index]?.[field.name]}
                    label={field.label}
                    placeholder={field.placeholder}
                    handleChange={formik.handleChange}
                    errors={formik.errors.applicants?.[index]?.[field.name]}
                  />
                </Col>
              ))}

              <Col xs={12}>
                <FormikInput
                  label="common.country"
                  name={`applicants[${index}].upsCountry`}
                  errors={formik.errors.applicants?.[index]?.upsCountry}
                  customInput={
                    <SelectCountry
                      isDisabled={disabled}
                      isAvailableUPSLabel
                      invalid={formik.errors.applicants?.[index]?.upsCountry}
                      onChange={country =>
                        formik.setFieldValue(
                          `applicants[${index}].upsCountry`,
                          country?.value,
                        )
                      }
                      value={formik.values.applicants?.[index]?.upsCountry}
                      name="upsCountry"
                    />
                  }
                />
              </Col>
            </div>
          </Accordion>
        </StyledField>
      )}

      <hr className="mt-4 mb-4" />
    </>
  );
};

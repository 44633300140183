import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'store/actions';
import {
  getOrderCountries,
  getDeliveryCountries,
  getCitizenshipCountries,
  getUPSLabelCountries,
} from 'store/references/actionTypes';

const getCountryState = state => state.references.countries;
const getOrderCountriesState = state => state.references.orderCountries;
const getDeliveryCountriesState = state => state.references.deliveryCountries;
const getCitizenshipCountriesState = state =>
  state.references.citizenshipCountries;
const getUPSLabelCountriesState = state => state.references.upsLabelCountries;

export const useCountries = (
  isAvailableOrder,
  isAvailableDelivery,
  isAvailableCitizenship,
  isAvailableUPSLabel,
) => {
  const dispatch = useDispatch();

  const countries = useSelector(
    isAvailableOrder
      ? getOrderCountriesState
      : isAvailableDelivery
      ? getDeliveryCountriesState
      : isAvailableCitizenship
      ? getCitizenshipCountriesState
      : isAvailableUPSLabel
      ? getUPSLabelCountriesState
      : getCountryState,
  );

  useEffect(() => {
    if (!countries || countries.length === 0) {
      dispatch(
        isAvailableOrder
          ? getOrderCountries({ available_for_order: true })
          : isAvailableDelivery
          ? getDeliveryCountries({ available_for_delivery: true })
          : isAvailableCitizenship
          ? getCitizenshipCountries({ available_for_citizenship: true })
          : isAvailableUPSLabel
          ? getUPSLabelCountries({ available_for_ups_shipping_label: true })
          : getCountries(),
      );
    }
  }, []);

  const countriesOptions = () =>
    countries?.map(item => ({
      value: item.id,
      label: `[${item.country.code}] ${item.country.name}`,
      shortLabel: item.country.name,
    }));

  const countriesOptionsShort = () =>
    countries?.map(item => ({
      value: item.id,
      label: item.country.name,
    }));

  const countriesConsulatesOptionsShort = () => {
    const options = [];
    countries.forEach(country => {
      options.push({
        value: country.id,
        label: country.country.name,
      });
      country.consulates.forEach(consulate => {
        options.push({
          value: `${country.id}_${consulate.id}`,
          label: `  - ${country.country.name} / ${consulate.name}`,
        });
      });
    });

    return options;
  };

  return {
    countries,
    countriesOptions: countriesOptions(),
    countriesOptionsShort: countriesOptionsShort(),
    countriesConsulatesOptionsShort: countriesConsulatesOptionsShort(),
  };
};

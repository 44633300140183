import React, { useEffect, useState } from 'react';
import { ShadowCard } from 'components/Common/ShadowCard';
import { Nav, NavItem } from 'reactstrap';
import styled, { css } from 'styled-components';
import { AlertOrderForm } from '../AlertOrderForm';
import { Price } from './OrderPrice';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'helpers/formatDate';
import { DATE_FORMAT } from 'common/data/constants';
import { Link } from 'react-scroll';
import classNames from 'classnames';
import {
  isUserEmployee,
  isUserClient,
  isUserOperator,
  userCanViewPrice,
} from 'helpers/jwt_helper';
import { BlueLink } from 'components/Common';

const StyledNavItem = styled(NavItem)`
  border-left: 4px solid #0000;

  color: ${({ theme }) => theme.colors.gray400};
  font-weight: 500;
  font-size: 16px;

  line-height: 24px;

  ${({ invalid, theme }) =>
    invalid &&
    css`
      color: ${theme.colors.danger} !important;
    `}

  padding: 4px 0px 4px 16px !important;
  padding-left: 20px !important;
  box-sizing: content-box;
`;

const StyledHead = styled.div`
  padding-left: 18px;

  span {
    color: ${({ theme }) => theme.colors.gray400};
    font-weight: 500;
  }

  p {
    font-weight: 600;
  }
`;

const StyledSticky = styled.div`
  top: 93px;

  @media (max-height: 560px) {
    height: 60vh;
    overflow-y: auto;
  }
`;

export const OrderNavbar = ({
  applicants,
  allServices,
  isVisibleInvoices,
  order,
  extraContent,
}) => {
  const formik = useFormikContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const [active, setActive] = useState('client');
  const hidePrice = !userCanViewPrice();

  const handleSelectActiveItem = id =>
    setTimeout(() => {
      setActive(id);
    }, 700);

  const handleScroll = () => {
    setActive(null);
  };

  const getClientName = order => {
    return order?.customer_organization?.name || order?.client?.full_name;
  };

  const getClientLink = order => {
    const legal = order.customer_organization ? true : false;

    return `/clients/${
      legal ? order.customer_organization.id : order.client.id
    }/${legal ? 'legal' : 'individual'}`;
  };

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener('scroll', handleScroll);
    };

    watchScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledSticky className="position-sticky">
      <ShadowCard
        bodyStyle={{
          minHeight: '300px',
          borderRadius: '8px',
        }}
        className="mb-4"
      >
        {id && (
          <StyledHead className="d-flex flex-column">
            <span>{t('order.orderDetail')}</span>

            {isUserOperator && (
              <BlueLink to={getClientLink(order.order)}>
                <p className="mt-2">{getClientName(order.order)}</p>
              </BlueLink>
            )}

            <p className="mt-1">
              {formatDate(formik.values.orderDetail?.dateCreate, DATE_FORMAT)}
            </p>

            <p>#{formik.values.orderDetail?.number}</p>

            <hr className="m-0 mb-3" />
          </StyledHead>
        )}

        <Nav vertical>
          <Link
            offset={-110}
            spy
            hashSpy
            tabIndex={0}
            spyThrottle={100}
            activeClass={active ?? 'active-navbar'}
            className={classNames('mb-3', {
              'active-navbar': active === 'client',
            })}
            onClick={() => handleSelectActiveItem('client')}
            to="client"
          >
            <StyledNavItem invalid={formik.errors?.client}>
              {t(
                isUserClient || isUserEmployee
                  ? 'order.detail'
                  : 'order.client',
              )}
            </StyledNavItem>
          </Link>

          {applicants &&
            applicants.length > 0 &&
            applicants.map((applicant, index) => (
              <Link
                hashSpy
                offset={-100}
                key={`${applicant.applicantDetail.id}-`}
                onClick={() =>
                  handleSelectActiveItem(`${applicant.applicantDetail.id}-`)
                }
                spy
                tabIndex={0}
                activeClass={active ?? 'active-navbar'}
                className={classNames('mb-3', {
                  'active-navbar':
                    active === `${applicant.applicantDetail.id}-`,
                })}
                to={`applicant-${index + 1}`}
              >
                <StyledNavItem invalid={formik.errors?.applicants?.[index]}>
                  {applicant.applicantDetail.firstName &&
                  applicant.applicantDetail.lastName
                    ? ` ${applicant.applicantDetail.firstName} ${applicant.applicantDetail.lastName}`
                    : `${t('order.applicant')} ${index + 1}`}
                </StyledNavItem>
              </Link>
            ))}

          {formik.values.applicants?.[0]?.applicantDetail.id &&
            !isUserClient &&
            !isUserEmployee &&
            id && (
              <Link
                tabIndex={0}
                spy
                onClick={() => handleSelectActiveItem(`delivery`)}
                activeClass={active ?? 'active-navbar'}
                className={classNames('mb-3', {
                  'active-navbar': active === 'delivery',
                })}
                to="delivery"
              >
                <StyledNavItem invalid={formik.errors?.delivery}>
                  {t('order.delivery')}
                </StyledNavItem>
              </Link>
            )}

          {isVisibleInvoices && (
            <Link
              tabIndex={0}
              activeClass={active ?? 'active-navbar'}
              className={classNames({
                'active-navbar': active === 'invoices',
              })}
              spy
              hashSpy
              onClick={() => handleSelectActiveItem('invoices')}
              to="invoices"
            >
              <StyledNavItem>{t('sideBar.invoices')}</StyledNavItem>
            </Link>
          )}
        </Nav>
      </ShadowCard>

      {extraContent}

      <AlertOrderForm />

      {!hidePrice && formik.values.applicants?.[0]?.applicantDetail?.id && (
        <Price allServices={allServices} />
      )}
    </StyledSticky>
  );
};

import { fetchService, updateService } from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as adminApi from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import i18n from 'i18n';

function* generalServiceFetch({ payload }) {
  try {
    const response = yield call(adminApi.fetchDetailedService, {
      serviceId: payload.serviceId,
      serviceType: payload.serviceType,
    });
    if (response.success) {
      yield put(fetchService.success(response.data));
    } else {
      yield put(fetchService.failure(response));
      showToastError(i18n.t('notifications.errorGetServices'));
    }
  } catch (error) {
    yield put(fetchService.failure(error));
  }
}

function* serviceUpdate({ payload }) {
  try {
    const response = yield call(adminApi.updateServiceDetails, {
      serviceId: payload.serviceId,
      serviceType: payload.serviceType,
      data: payload.data,
    });
    if (response.success) {
      showToastSuccess(i18n.t('notifications.serviceUpdated'));
      yield put(updateService.success(response.data));
    } else {
      yield put(updateService.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(fetchService.failure(error));
  }
}

function* serviceSaga() {
  yield takeEvery(fetchService, generalServiceFetch);
  yield takeEvery(updateService, serviceUpdate);
}

export default serviceSaga;

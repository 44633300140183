import classNames from 'classnames';
import {
  DATE_FORMAT_SHORT,
  SELECT_CLASSNAME,
  USER_SEX,
} from 'common/data/constants';
import { CardTitle, Select, ShadowCard } from 'components/Common';
import { StyledInput, StyledLabel } from 'components/Common/FormikInput';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { useFormikContext } from 'formik';
import { DEFAULT_POINTS } from 'helpers/styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { isUserIndividual, isUserEmployee } from 'helpers/jwt_helper';

const applicantInformationFields = [
  {
    id: 1,
    label: 'auth.username',
    name: 'userName',
    type: 'text',
    disabled: true,
    skip: isUserEmployee || isUserIndividual,
  },
  {
    id: 2,
    label: 'auth.firstName',
    name: 'firstName',
    placeholder: 'auth.enterFirstName',
    type: 'text',
    disabled: false,
  },
  {
    id: 3,
    label: 'auth.lastName',
    name: 'lastName',
    placeholder: 'auth.enterLastName',
    type: 'text',
    disabled: false,
  },
  {
    id: 4,
    label: 'auth.additionalName',
    name: 'additionalName',
    placeholder: 'auth.enterAdditionalName',
    type: 'text',
    disabled: false,
  },
  {
    id: 5,
    label: 'common.gender',
    name: 'sex',
    placeholder: 'common.genderPlaceholder',
    type: 'select',
    options: USER_SEX,
    isShort: true,
    disabled: false,
  },
  {
    id: 6,
    label: 'client.dateOfBirth',
    name: 'birthday',
    placeholder: 'common.datePlaceholder',
    type: 'date',
    isShort: true,
    disabled: false,
  },
];

export const ApplicantDetailsCard = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <ShadowCard id="information" className="mb-4" bodyStyle={{ padding: 24 }}>
      <CardTitle title="common.information" />

      {applicantInformationFields.map(
        (field, index, arr) =>
          !field.skip && (
            <Row
              key={field.id}
              className={classNames('p-0 align-items-center', [
                arr.length !== index + 1 && 'mb-4',
              ])}
            >
              <Col {...DEFAULT_POINTS.label}>
                <StyledLabel
                  invalid={
                    formik.errors[field.name] && formik.touched[field.name]
                  }
                >
                  {t(field.label)}
                </StyledLabel>
              </Col>

              <Col
                {...DEFAULT_POINTS.input}
                sm={field.isShort ? 5 : 9}
                lg={field.isShort ? 5 : 9}
              >
                {field.type === 'text' && (
                  <StyledInput
                    name={field.name}
                    placeholder={t(field.placeholder)}
                    value={formik.values[field.name]}
                    disabled={field.disabled}
                    invalid={
                      formik.errors[field.name] && formik.touched[field.name]
                    }
                    onChange={formik.handleChange}
                  />
                )}

                {field.type === 'select' && (
                  <Select
                    placeholder={t(field.placeholder)}
                    classNamePrefix={SELECT_CLASSNAME}
                    value={
                      field.options.find(
                        ({ value }) => value === formik.values[field.name],
                      ) ?? null
                    }
                    onChange={option =>
                      formik.setFieldValue(field.name, option?.value)
                    }
                    options={field.options}
                    invalid={
                      formik.errors[field.name] && formik.touched[field.name]
                    }
                  />
                )}

                {field.type === 'date' && (
                  <StyledDatePicker
                    name={field.name}
                    placeholderText={t(field.placeholder)}
                    dateFormat={DATE_FORMAT_SHORT}
                    selected={formik.values.birthday}
                    value={formik.values.birthday}
                    invalid={formik.errors.birthday}
                    onChange={option =>
                      formik.setFieldValue(field.name, option)
                    }
                    maxDate={new Date()}
                  />
                )}
              </Col>
            </Row>
          ),
      )}
    </ShadowCard>
  );
};

import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Label } from 'reactstrap';

const StyledHelpText = styled(Label)`
  font-size: 12px;
  font-weight: 500;
`;

export const OrderTypeFileInput = ({ name, helptext = null, ...props }) => {
  const formik = useFormikContext();

  return (
    <>
      <input
        type="file"
        onChange={e => {
          const reader = new FileReader();
          reader.readAsDataURL(e.currentTarget.files[0]);
          reader.onload = () => {
            formik.setFieldValue(`${name}`, reader.result);
          };
        }}
        {...props}
      />
      {helptext && <StyledHelpText>{helptext}</StyledHelpText>}
    </>
  );
};

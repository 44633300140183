import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label, Row } from 'reactstrap';
import { DEFAULT_POINTS } from 'helpers/styles/constants';

export const OrderTypeMainEdit = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <>
      <Row className="d-flex mt-3 flex-wrap">
        <Col
          className="p-0 d-flex align-items-center"
          {...DEFAULT_POINTS.label}
        >
          <Label className="m-0 display-5">
            {t('orderTypes.translationEn')}
          </Label>
        </Col>
        <Col className="p-0" {...DEFAULT_POINTS.input}>
          <Input
            type="text"
            placeholder={t('orderTypes.translationEnPlaceholder')}
            name="translationEn"
            value={formik.values?.translationEn}
            onChange={formik.handleChange}
            invalid={formik.errors.translationEn}
          />
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          className="p-0 d-flex align-items-center"
          {...DEFAULT_POINTS.label}
        >
          <Label className="m-0 display-5">
            {t('orderTypes.translationDe')}
          </Label>
        </Col>
        <Col className="p-0" {...DEFAULT_POINTS.input}>
          <Input
            type="text"
            placeholder={t('orderTypes.translationDePlaceholder')}
            name="translationDe"
            value={formik.values?.translationDe}
            onChange={formik.handleChange}
            // invalid={formik.errors.translationDe}
          />
        </Col>
      </Row>

      <Row id="clientSettings" className="d-flex mt-3 flex-wrap">
        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">{t('orderTypes.isActive')}</Label>

          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.isActive}
            onChange={() =>
              formik.setFieldValue('isActive', !formik.values.isActive)
            }
          />
        </Col>

        <Col
          xs={6}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">
            {t('orderTypes.enableForIndividual')}
          </Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableForIndividual}
            onChange={() =>
              formik.setFieldValue(
                'enableForIndividual',
                !formik.values.enableForIndividual,
              )
            }
          />
        </Col>

        <Col
          xs={12}
          xl={4}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">
            {t('orderTypes.enableForLegal')}
          </Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableForLegal}
            onChange={() =>
              formik.setFieldValue(
                'enableForLegal',
                !formik.values.enableForLegal,
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

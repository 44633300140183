import {
  GET_VISA_TYPES_FAIL,
  GET_VISA_TYPES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAIL,
  CLEAR_ADDRESS_LIST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAIL,
  GET_VISA_TYPES,
  GET_REGIONS,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAIL,
  GET_REFERENCES_CONFIG,
  GET_REFERENCES_CONFIG_SUCCESS,
  GET_REFERENCES_CONFIG_FAIL,
} from './actionTypes';

const INIT_STATE = {
  visaUrgencies: [],
  visaDurations: [],
  visaTypes: [],
  countries: [],
  addresses: [],
  orderCountries: [],
  urgencies: [],
  address: null,
  regions: [],
  error: {},
  loading: false,
  deliveryCountries: [],
  config: null,
  citizenshipCountries: [],
  upsLabelCountries: [],
};

const references = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VISA_TYPES:
      return {
        ...state,
        loading: true,
      };
    case GET_VISA_TYPES_SUCCESS:
      return {
        ...state,
        visaTypes: action.payload,
        loading: false,
      };

    case GET_VISA_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };

    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case `getOrderCountries/request`:
      return {
        ...state,
      };
    case `getOrderCountries/success`:
      return {
        ...state,
        orderCountries: action.payload,
      };
    case `getOrderCountries/failure`:
      return {
        ...state,
        error: action.payload,
      };
    case `getDeliveryCountries/request`:
      return {
        ...state,
      };
    case `getDeliveryCountries/success`:
      return {
        ...state,
        deliveryCountries: action.payload,
      };
    case `getDeliveryCountries/failure`:
      return {
        ...state,
        error: action.payload,
      };

    case `getCitizenshipCountries/request`:
      return {
        ...state,
      };
    case `getCitizenshipCountries/success`:
      return {
        ...state,
        citizenshipCountries: action.payload,
      };
    case `getCitizenshipCountries/failure`:
      return {
        ...state,
        error: action.payload,
      };

    case `getUPSLabelCountries/request`:
      return {
        ...state,
      };
    case `getUPSLabelCountries/success`:
      return {
        ...state,
        upsLabelCountries: action.payload,
      };
    case `getUPSLabelCountries/failure`:
      return {
        ...state,
        error: action.payload,
      };

    case `getUrgenciesAction/request`:
      return {
        ...state,
      };
    case `getUrgenciesAction/success`:
      return {
        ...state,
        urgencies: action.payload,
      };
    case `getUrgenciesAction/failure`:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };

    case GET_ADDRESSES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.payload,
      };

    case GET_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ADDRESS_LIST:
      return {
        ...state,
        addresses: [],
      };

    case GET_REGIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload.results,
      };

    case GET_REGIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_REFERENCES_CONFIG:
      return {
        ...state,
      };

    case GET_REFERENCES_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.payload,
      };

    case GET_REFERENCES_CONFIG_FAIL:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default references;

import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label, Row } from 'reactstrap';

import { DEFAULT_POINTS } from 'helpers/styles/constants';
import { Select } from 'components/Common';
import styled from 'styled-components';

const APPLICAT_QUANTITY_OPTIONS = [1, 2, 3, 4, 5].map(i => ({
  label: i,
  value: i,
}));

const StyledHelpText = styled(Label)`
  font-size: 12px;
  font-weight: 100;
  color: red;
`;

export const OrderTypeEditApplicant = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <>
      <Row className="d-flex mt-3 flex-wrap">
        <Col
          xs={12}
          xl={3}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Applicant</Label>
        </Col>

        <Col
          xs={6}
          xl={3}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Quantity</Label>

          <Select
            menuPlacement="auto"
            options={APPLICAT_QUANTITY_OPTIONS}
            value={APPLICAT_QUANTITY_OPTIONS.find(
              option => option.value === formik.values.applicantMaxQuantity,
            )}
            className="m-3 vbo-select display-5"
            onChange={val =>
              formik.setFieldValue('applicantMaxQuantity', val.value)
            }
          />
        </Col>

        <Col
          xs={6}
          xl={3}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Birthdate</Label>

          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableApplicantBirthdate}
            onChange={() =>
              formik.setFieldValue(
                'enableApplicantBirthdate',
                !formik.values.enableApplicantBirthdate,
              )
            }
          />
        </Col>

        <Col
          xs={6}
          xl={3}
          className="p-0 d-flex align-items-center v-align-top"
        >
          <Label className="m-0 display-5">Passport</Label>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableApplicantPassport}
            onChange={() =>
              formik.setFieldValue(
                'enableApplicantPassport',
                !formik.values.enableApplicantPassport,
              )
            }
          />
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          className="p-0 d-flex align-items-center v-align-top"
          {...DEFAULT_POINTS.label}
        >
          <Label className="m-0 display-5">
            {t('orderTypes.enableContactPerson')}
          </Label>
        </Col>

        <Col className="p-0" {...DEFAULT_POINTS.input}>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableContactPerson}
            onChange={() =>
              formik.setFieldValue(
                'enableContactPerson',
                !formik.values.enableContactPerson,
              )
            }
          />
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          className="p-0 d-flex align-items-center v-align-top"
          {...DEFAULT_POINTS.label}
        >
          <Label className="m-0 display-5">
            {t('orderTypes.enableResidency')}
          </Label>
        </Col>

        <Col className="p-0" {...DEFAULT_POINTS.input}>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableResidency}
            onChange={() =>
              formik.setFieldValue(
                'enableResidency',
                !formik.values.enableResidency,
              )
            }
          />
          <StyledHelpText className="m-3">
            Must activate passport.
          </StyledHelpText>
        </Col>
      </Row>

      <Row className="d-flex mt-3 flex-wrap">
        <Col
          className="p-0 d-flex align-items-center v-align-top"
          {...DEFAULT_POINTS.label}
        >
          <Label className="m-0 display-5">
            {t('orderTypes.enableDescription')}
          </Label>
        </Col>

        <Col className="p-0" {...DEFAULT_POINTS.input}>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableDescription}
            onChange={() =>
              formik.setFieldValue(
                'enableDescription',
                !formik.values.enableDescription,
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

import { call, put, takeEvery } from 'redux-saga/effects';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import i18n from 'i18n';
import {
  deleteDocument,
  getDocument,
  getDocuments,
  uploadDocument,
} from './actions';
import {
  deleteDocumentReq,
  fetchDocument,
  fetchDocuments,
  putDocument,
} from 'helpers/api-requests/employee';

function* fetchDocumentsSaga({ payload }) {
  try {
    const response = yield call(fetchDocuments, payload);

    if (response.success) {
      yield put(
        getDocuments.success({
          documents: response.data.results,
          count: response.data.count,
        }),
      );
    }
  } catch (error) {
    showToastError(error.message);
    yield put(getDocuments.failure(error));
  }
}

function* deleteDocumentSaga({ payload }) {
  try {
    const response = yield call(deleteDocumentReq, payload.id);

    if (response.success) {
      yield put(deleteDocument.success(payload.id));
      showToastSuccess(i18n.t('docuemtns.deleted'));
      payload.handleCloseModal();
    } else {
      yield put(deleteDocument.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error.message);
    yield put(deleteDocument.failure(error));
  }
}

function* fetchDocumentSaga({ payload }) {
  try {
    const response = yield call(fetchDocument, payload);

    if (response.success) {
      yield put(getDocument.success(response.data));
      return;
    }
    yield put(getDocument.failure(response.message));
    showToastError(response.message);
  } catch (error) {
    showToastError(error.message);
    yield put(getDocument.failure(error));
  }
}

function* updateDocumentSaga({ payload }) {
  try {
    const response = yield call(putDocument, payload.id, payload.formData);

    if (response.success) {
      yield put(uploadDocument.success(response.data));
      showToastSuccess(response.message);
      payload.handleCloseModal();
      return;
    }
    showToastError(response.message);
  } catch (error) {
    showToastError(error.message);
    yield put(uploadDocument.failure(error));
  }
}

function* documents() {
  yield takeEvery(getDocuments, fetchDocumentsSaga);
  yield takeEvery(deleteDocument, deleteDocumentSaga);
  yield takeEvery(getDocument, fetchDocumentSaga);
  yield takeEvery(uploadDocument, updateDocumentSaga);
}

export default documents;

// Gets the logged in user data from local session
import axios from 'axios';
import * as url from '../url-helpers/auth';
import { post } from '../api_helper';

export const getLoggedInUser = () => {
  const user = localStorage.getItem('authUser');
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found';
            break;
          case 500:
            message =
              'Sorry! something went wrong, please contact our support team';
            break;
          case 401:
            message = 'Invalid credentials';
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = data =>
  post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found';
            break;
          case 500:
            message =
              'Sorry! something went wrong, please contact our support team';
            break;
          case 401:
            message = 'Invalid credentials';
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = data => post(url.POST_JWT_LOGIN, data);

//Sign up method

export const postJwtSignup = data => post(url.POST_JWT_SIGNUP, data);

// Verify Jwt Token Method
export const postJwtVerify = data => post(url.POST_JWT_VERIFY, data);

// Refresh Jwt Token Method
export const postJwtRefresh = data => post(url.POST_JWT_REFRESH, data);

// postForgetPwd
export const postJwtForgetPwd = data =>
  post(url.POST_JWT_PASSWORD_FORGET, data);

//Reset PasswordPage
export const resetPassword = ({ token, password }) =>
  post(url.POST_RESET_PASSWORD, { password, token });

export const confirmEmail = token => post(url.CONFIRM_EMAIL, { token });

import { CLIENT_TABS } from 'common/data/tabs';
import { StyledNavItem } from 'components/Order/Tabs/ApplicantBody';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Nav, NavLink } from 'reactstrap';

export const ClientTabs = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <Nav pills className="flex-nowrap">
      <StyledNavItem className="me-4" active={activeTab === CLIENT_TABS[0]}>
        <NavLink
          tabIndex={1}
          className="bg-transparent pt-0 px-0"
          onClick={() => setActiveTab(CLIENT_TABS[0])}
        >
          {t('client.requisites')}
        </NavLink>
      </StyledNavItem>

      {id && (
        <StyledNavItem active={activeTab === CLIENT_TABS[1]}>
          <NavLink
            tabIndex={1}
            className="bg-transparent pt-0 px-0 "
            onClick={() => setActiveTab(CLIENT_TABS[1])}
          >
            {t('client.applicants')}
          </NavLink>
        </StyledNavItem>
      )}
    </Nav>
  );
};

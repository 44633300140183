import { CardTitle, ShadowCard } from 'components/Common';
import ServicesTable from 'components/Invoices/ServicesTable';
import React from 'react';
import { Col } from 'reactstrap';

export const InvoiceServiceCard = ({ invoiceDetails }) => (
  <ShadowCard bodyStyle={{ padding: '24px' }} id="services" className="mt-4">
    <Col xs={12}>
      <CardTitle title="invoices.services" />
    </Col>

    {invoiceDetails &&
      invoiceDetails?.details &&
      invoiceDetails.details?.applicants?.length > 0 &&
      invoiceDetails.details.applicants.map((applicant, index, applicants) => (
        <React.Fragment key={applicant.id}>
          <p className="h6 fw-semibold mb-3">
            {applicant.customer_first_name} {applicant.customer_last_name}
          </p>

          <ServicesTable services={applicant.products} />

          {applicants.length !== 1 && index !== applicants.length - 1 && (
            <Col xs={12}>
              <hr className="my-4" />
            </Col>
          )}
        </React.Fragment>
      ))}
  </ShadowCard>
);

import React, { useEffect, useMemo } from 'react';
import { isNil } from 'lodash';
import AsyncSelect from 'react-select/async';
import { getClientFilter } from 'store/clients/actions';
import * as adminApi from 'helpers/api-requests/admin';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const PAGE_SIZE = 1200;

export const StyledAsyncSelect = styled(AsyncSelect)`
  .select2-selection {
    &__indicators {
      padding: 0px;

      span {
        background-color: transparent;
      }
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.placeholders}!important;
      opacity: 0.5;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__indicator {
      padding-right: 0;
    }

    &__control {
      border-radius: 8px;
      padding: 2px ${({ theme }) => theme.spacing.m};
      border-color: ${({ theme }) => theme.colors.gray200};

      svg {
        fill: ${({ theme }) => theme.colors.mainText};
      }
      ${({ invalid, theme }) =>
        invalid &&
        css`
          border-color: ${theme.colors.danger};
          &.select2-selection__control--is-focused {
            box-shadow: 0 0 0 0.15rem rgb(244 106 106 / 25%);
          }

          :hover {
            border-color: ${theme.colors.danger};
          }
        `};
    }

    &__single-value {
      font-size: 14px;
      margin: 0;
    }

    &__value-container {
      padding: 0px;
    }
  }
`;

export const SelectClient = ({
  name,
  clientType,
  value,
  onChange,
  disabled,
  placeholder,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const { clients, isLoading } = useSelector(state => state.clients);

  useEffect(() => {
    if (clientType) {
      dispatch(
        getClientFilter({
          client_type: clientType,
          page_size: PAGE_SIZE,
          status: 'active',
        }),
      );
    }
  }, [clientType]);

  const convertOptions = options =>
    options.map(item => ({
      label: item.name,
      value: item.id,
    }));

  const options = useMemo(() => {
    return convertOptions(clients.results ?? []);
  }, [clientType, clients]);

  const val = useMemo(() => {
    if (isNil(value)) {
      return value;
    }
    return options.find(item => item.value === value) ?? null;
  }, [options, value]);

  return (
    <StyledAsyncSelect
      name={name}
      isClearable
      classNamePrefix="select2-selection"
      className="vbo-select display-5"
      placeholder={placeholder}
      isDisabled={disabled || isDisabled || options.length === 0 || isLoading}
      onChange={onChange}
      value={val}
      defaultOptions={options}
      loadOptions={search =>
        adminApi
          .getClientFilter({
            search,
            client_type: clientType,
            perPage: PAGE_SIZE,
            status: 'active',
          })
          .then(res => convertOptions(res.data.results))
      }
    />
  );
};

import { DATE_FORMAT_WITH_SYMBOL } from 'common/data/constants';
import i18n from 'i18n';
import React, { memo } from 'react';
import { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  height: 36px;
  background: #dee0e7;
  border-radius: 8px;
  border: 0px;
  width: 100%;
  font-size: 14px;
  max-height: 40px;
  min-height: 40px;
`;

registerLocale('lang', i18n.language === 'de' ? de : en);

const StyledData = styled.span`
  font-weight: 500;
`;

export const DateRangePicker = memo(
  ({
    startDate,
    value,
    endDate,
    onChange,
    placeholder = 'common.dates',
    className,
    isSingle,
  }) => {
    const { t } = useTranslation();
    const isSelected = startDate ? true : false;

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <StyledButton
        className="pe-4"
        onClick={onClick}
        ref={ref}
        style={isSelected ? { background: '#495057', color: 'white' } : {}}
      >
        <span className="me-2 text-nowrap">{t(placeholder)}: </span>
        <StyledData className="me-3">
          {value ? value : t('common.all')}
        </StyledData>
      </StyledButton>
    ));

    return (
      <DatePicker
        selected={startDate}
        locale={i18n.language === 'de' ? de : en}
        className={className}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        isClearable
        value={value}
        dateFormat={DATE_FORMAT_WITH_SYMBOL}
        peekNextMonth
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        showMonthDropdow
        showYearDropdown
        dropdownMode="select"
        customInput={<ExampleCustomInput />}
        selectsRange={!isSingle}
        selectsDisabledDaysInRange
      />
    );
  },
);

import React, { Fragment, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { menuItems } from './menuItems';
import { useSelector } from 'react-redux';
import { getLayoutState } from '.';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';

export const MenuListItem = styled.li`
  ${({ active }) =>
    active &&
    css`
      color: white;
    `}
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.sideBarText};
  font-style: normal;
  line-height: 24px;

  ${({ $active }) =>
    $active &&
    css`
      color: white;

      svg {
        fill: white !important;
      }
    `}

  svg {
    fill: ${({ theme }) => theme.colors.sideBarImage};
  }

  &:hover {
    color: white;

    svg {
      fill: white !important;
    }
  }

  &:focus {
    color: white;

    svg {
      fill: white !important;
    }
  }
`;

export const StyledBeta = styled.p`
  color: ${({ theme }) => theme.colors.sideBarImage};
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 24px;
  z-index: 22;
`;

const StyledDiv = styled.div`
  max-height: 600px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #0000;
    border-radius: 20px;
  }

  @media (max-height: 800px) {
    max-height: 500px;
  }

  @media (max-height: 756px) {
    max-height: 450px;
  }

  @media (max-height: 705px) {
    max-height: 400px;
  }

  @media (max-height: 650px) {
    max-height: 350px;
  }

  @media (max-height: 600px) {
    max-height: 300px;
  }

  @media (max-height: 550px) {
    max-height: 250px;
  }

  @media (max-height: 500px) {
    max-height: 200px;
  }

  @media (max-height: 400px) {
    max-height: 150px;
  }

  @media (max-height: 350px) {
    max-height: 120px;
  }

  @media (max-height: 300px) {
    max-height: 100px;
  }
`;

const SidebarContent = memo(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const typeSideBar = useSelector(getLayoutState);

  return (
    <StyledDiv>
      <ul
        className={classNames('list-unstyled d-flex flex-wrap m-0', [
          !typeSideBar && 'justify-content-center',
        ])}
      >
        {menuItems.map(
          item =>
            item.isVisible && (
              <Fragment key={item.key}>
                {item.beta && <StyledBeta>Beta</StyledBeta>}
                <StyledLink
                  className="w-100 h-100 mb-4"
                  $active={pathname.includes(item.link)}
                  to={item.link}
                  id={item.key}
                >
                  <MenuListItem
                    className={classNames('d-flex align-items-center m-0', [
                      !typeSideBar && 'justify-content-center',
                    ])}
                    key={item.key}
                  >
                    {item.icon}

                    {!typeSideBar && (
                      <UncontrolledTooltip placement="right" target={item.key}>
                        {t(item.title)}
                      </UncontrolledTooltip>
                    )}

                    {typeSideBar && (
                      <div className="ms-2 fw-500">{t(item.title)}</div>
                    )}
                  </MenuListItem>
                </StyledLink>
              </Fragment>
            ),
        )}
      </ul>
    </StyledDiv>
  );
});

export default SidebarContent;

export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL';

export const GET_CLIENT_DETAIL = 'GET_CLIENT_DETAIL';
export const GET_CLIENT_DETAIL_SUCCESS = 'GET_CLIENT_DETAIL_SUCCESS';
export const GET_CLIENT_DETAIL_FAIL = 'GET_CLIENT_DETAIL_FAIL';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL';

export const ADD_APPLICANT_SUCCESS = 'ADD_APPLICANT_SUCCESS';

export const GET_ORGANIZATION_INFO = 'GET_CLIENT_INFO';
export const GET_CLIENT_INFO_SUCCESS = 'GET_CLIENT_INFO_SUCCESS';
export const GET_CLIENT_INFO_FAIL = 'GET_CLIENT_INFO_FAIL';
export const GET_INDIVIDUAL_INFO = 'GET_INDIVIDUAL_INFO';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';

export const CHANGE_APPLICANT = 'CHANGE_APPLICANT';
export const CHANGE_APPLICANT_FAIL = 'CHANGE_APPLICANT_FAIL';
export const CHANGE_APPLICANT_SUCCESS = 'CHANGE_APPLICANT_SUCCESS';

export const GET_CLIENT_FILTER = 'GET_CLIENT_FILTER';
export const GET_CLIENT_FILTER_SUCCESS = 'GET_CLIENT_FILTER_SUCCESS';
export const GET_CLIENT_FILTER_FAIL = 'GET_CLIENT_FILTER_FAIL';

export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';

export const DELETE_CLIENT_APPLICANT = 'DELETE_CLIENT_APPLICANT';
export const DELETE_CLIENT_APPLICANT_SUCCESS =
  'DELETE_CLIENT_APPLICANT_SUCCESS';
export const DELETE_CLIENT_APPLICANT_FAIL = 'DELETE_CLIENT_APPLICANT_FAIL';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;

  i {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.placeholders};
    padding-left: ${({ theme }) => theme.spacing.m};
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    transform: translateY(-50%);
  }

  ${({ $isBlue, theme }) =>
    $isBlue &&
    css`
      i {
        color: ${theme.colors.blue200};
      }
    `}
`;

const StyledInput = styled(Input)`
  border: 1px solid #a6b0cf;
  padding: 7px 34px;
  min-height: 40px;
  border-radius: ${({ theme }) => theme.radius.s};
  font-size: 14px;

  ${({ $isBlue, theme }) =>
    $isBlue &&
    css`
      border-color: ${theme.colors.blue200};
    `}
`;

export const SearchInput = ({
  value,
  handleChange,
  className,
  defaultValue,
  placeholder = 'common.search',
  isBlue,
}) => {
  const nameRef = useRef();
  const { t } = useTranslation();

  const handleFocusInput = () => {
    nameRef.current.focus();
  };

  return (
    <StyledWrapper $isBlue={isBlue}>
      <i onClick={handleFocusInput} className="bx bx-search" />

      <StyledInput
        $isBlue={isBlue}
        type="text"
        innerRef={nameRef}
        name="search"
        defaultValue={defaultValue}
        className={className}
        value={value}
        onChange={handleChange}
        placeholder={t(placeholder)}
      />
    </StyledWrapper>
  );
};

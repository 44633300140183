import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import { fetchService, updateService } from './actions';

const initState = {
  service: {
    data: undefined,
    isLoading: false,
    error: undefined,
  },
};

const service = createReducer(initState.service, {
  [fetchService]: state => ({
    ...state,
    data: null,
    isLoading: true,
  }),
  [fetchService.success]: (state, action) => ({
    ...state,
    data: action.payload,
    isLoading: false,
  }),
  [fetchService.failure]: (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false,
  }),
  [updateService]: state => ({
    ...state,
    isLoading: true,
  }),
  [updateService.success]: (state, action) => ({
    ...state,
    data: action.payload,
    isLoading: false,
  }),
  [updateService.failure]: (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false,
  }),
});

export default combineReducers({
  service,
});

import { all, fork } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ProfileSaga from './auth/profile/saga';
import chatSaga from './chat/saga';
import invoiceSaga from './invoices/saga';
import adminSaga from './admin/saga';
import referencesSaga from './references/saga';
import wizardSaga from './wizard/saga';
import clientsSaga from './clients/saga';
import clientCabinetSaga from './clientCabinet/saga';
import serviceSaga from './services/saga';
import tariffSaga from './tariffs/saga';
import orderSaga from './order/saga';
import aggregatedSaga from './aggregatedInvoices/saga';
import applicantsSaga from './applicants/saga';
import documentsSaga from './documents/saga';
import addressesSaga from './addresses/saga';
import orderTypesSaga from './orderTypes/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(orderSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(invoiceSaga),
    fork(adminSaga),
    fork(referencesSaga),
    fork(wizardSaga),
    fork(clientsSaga),
    fork(clientCabinetSaga),
    fork(serviceSaga),
    fork(tariffSaga),
    fork(aggregatedSaga),
    fork(applicantsSaga),
    fork(documentsSaga),
    fork(addressesSaga),
    fork(orderTypesSaga),
  ]);
}

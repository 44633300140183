import { StyledInput } from 'components/Common/FormikInput';
import {
  StyledButtonCancel,
  StyledButtonSave,
} from 'components/Common/StyledButtonSave';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import styled from 'styled-components';

export const StyledError = styled.span`
  font-size: 10px;
`;

const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
`;

const TariffsForm = ({ formik, handleCloseEditMode }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { push } = useHistory();

  const { values, errors, handleChange, handleSubmit } = formik;

  return (
    <div>
      <Row className="p-0 mb-4 align-items-top">
        <Col xs={3} lg={3} xxl={2}>
          <StyledLabel className="m-0">{t('tariffs.tariffName')}</StyledLabel>
        </Col>

        <Col xs={6} lg={6}>
          <StyledInput
            name="name"
            value={values.name}
            maxLength={100}
            placeholder="Enter tariff name"
            onChange={handleChange}
            invalid={errors.name}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-top">
        <Col xs={3} lg={3} xxl={2}>
          <StyledLabel className="m-0">{t('common.description')}</StyledLabel>
        </Col>

        <Col xs={6} lg={6}>
          <StyledInput
            name="description"
            placeholder={t('common.descriptionPlaceholder')}
            type="textarea"
            maxLength={100}
            style={{ resize: 'none', height: 80 }}
            value={values.description}
            onChange={handleChange}
            invalid={errors.description}
          />
        </Col>
      </Row>

      {id && (
        <Row>
          <Col xs={3} lg={3} xxl={2}></Col>

          <Col
            xs={5}
            lg={3}
            className="d-flex gap-2 flex-nowrap justify-content-between"
          >
            <StyledButtonSave
              className="w-50"
              onClick={handleSubmit}
              disabled={!formik.dirty}
              color="primary"
            >
              {t('common.save')}
            </StyledButtonSave>

            <StyledButtonCancel
              className="w-50 btn"
              onClick={id ? handleCloseEditMode : () => push('/tariffs')}
            >
              {t('common.cancel')}
            </StyledButtonCancel>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TariffsForm;

import {
  EMPTY_VALUE_WITH_LABEL,
  FIRST_PAGE,
  PAGES,
  SERVICES_STATUS,
  VAT_STATUSES,
} from 'common/data/constants';

import { getVisaTypes } from 'helpers/api-requests/admin';
import { SERVICE_TYPES } from 'common/data/services';
import Loader from 'components/Common/Loader';
import { PaginationLm } from 'components/Common/PaginationLm';
import { Layout } from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import { getServices, operatorDeleteService } from 'store/actions';
import { debounce } from 'lodash';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { StyledTable } from 'components/Table/StyledTable';
import { ButtonCreate } from 'components/Common/ButtonCreate';
import { ShadowCard } from 'components/Common/ShadowCard';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { Badge, BlueLink } from 'components/Common';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import classNames from 'classnames';
import { useCountries } from 'hooks/useCountries';
import { ResponsiveFilters } from 'components/Common/ResponsiveFilters';
import i18n from 'i18n';
import { useModal } from 'hooks/useModal';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { useCookies } from 'react-cookie';
import { getRegionsData } from 'helpers/api-requests/admin';
import SortButton from 'components/SortButton';
import styled from 'styled-components';
import { getOrderTypesRequest } from 'helpers/api-requests/admin';

const StyledText = styled.span`
  font-size: 14px;
  color: #6a7187;
  padding: 8px 8px;
  cursor: pointer;
  background-color: powderblue;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
`;

const getServicesState = state => state.admin.rest.services;

const isAvailableOrder = true;

const Services = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();

  const { services, count, loading } = useSelector(getServicesState);
  const { countriesOptionsShort } = useCountries(isAvailableOrder);
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();
  const [regionList, setRegionList] = useState([]);

  const getRegionsList = () => {
    getRegionsData()
      .then(response => {
        const results = response?.data?.results ?? [];
        if (results.length > 0) {
          const regions = results.map(region => ({
            value: region.id,
            label: region.name,
          }));
          setRegionList(regions);
          return;
        }
        setRegionList([]);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRegionsList();
  }, []);

  const handleDeleteService = id => {
    dispatch(operatorDeleteService({ id, handleCloseModal }));
  };

  const [cookies, setCookie] = useCookies([
    'services',
    'durations',
    'urgencies',
  ]);
  const [search, setSearch] = useState(cookies.services?.search || '');

  const [visaTypeList, setVisaTypeList] = useState([]);
  const [visaDurationList, setVisaDurationList] = useState(
    cookies?.durations?.durations ?? [],
  );
  const [visaUrgencyList, setVisaUrgencyList] = useState(
    cookies?.urgencies?.urgencies ?? [],
  );
  const [ordering, setOrdering] = useState('-pk');

  const DEFAULT_SEARCH_VALS = {
    page: FIRST_PAGE,
    ordering: ordering,
    serviceType: EMPTY_VALUE_WITH_LABEL,
    status: EMPTY_VALUE_WITH_LABEL,
    region: EMPTY_VALUE_WITH_LABEL,
    vat: EMPTY_VALUE_WITH_LABEL,
    country: EMPTY_VALUE_WITH_LABEL,
    visaType: EMPTY_VALUE_WITH_LABEL,
    visaDuration: EMPTY_VALUE_WITH_LABEL,
    visaUrgency: EMPTY_VALUE_WITH_LABEL,
    orderType: EMPTY_VALUE_WITH_LABEL,
  };

  const [searchState, setSearchState] = useState(
    cookies.services?.searchState || {
      perPage: PAGES[0],
      ...DEFAULT_SEARCH_VALS,
    },
  );

  const handleChangeSearchParam = (field, value) => {
    setSearchState(prev => ({
      ...prev,
      page: FIRST_PAGE,
      [field]: value,
    }));
  };

  const handleChangeSearch = debounce(
    searchQuery => setSearch(searchQuery),
    // 500,
    50,
  );

  // If search filters are used.
  const [searchHasValue, setSearchHasValue] = useState(false);

  useEffect(() => {
    setSearchHasValue(
      search?.length > 0 ||
        [
          searchState?.orderType,
          searchState?.country,
          searchState?.region,
          searchState?.serviceType,
          searchState?.status,
          searchState?.vat,
        ].some(el => el?.value) ||
        [
          searchState?.visaDuration,
          searchState?.visaType,
          searchState?.visaUrgency,
        ].some(val => val?.length > 0),
    );
  }, [search, searchState]);

  const [orderTypes, setOrderTypes] = useState([]);

  useEffect(() => {
    getOrderTypesRequest().then(response => {
      if (response.success) {
        setOrderTypes(
          response.data.results?.map(orderType => ({
            label: orderType?.translations?.[i18n.language],
            value: orderType.id,
          })),
        );
      }
    });
  }, [search]);

  const getVisaTypeList = () => {
    if (searchState.country.value) {
      getVisaTypes({
        country: searchState.country.value,
      })
        .then(response => {
          const results = response?.data?.results ?? [];

          if (results.length > 0) {
            const visaTypes = results.map(item => ({
              label: item.translations[i18n.language],
              value: item.id,
              durations: item?.durations,
              urgencies: item?.urgencies,
            }));
            setVisaTypeList(visaTypes);
            return;
          }
          setVisaTypeList([]);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!searchState?.country?.value) {
      resetVisaType();
    } else {
      getVisaTypeList();
    }
  }, [searchState?.country]);

  useEffect(() => {
    if (!searchState?.visaType?.value) {
      resetVisaDuration();
      resetVisaUrgency();
    }
  }, [searchState?.visa_type]);

  const resetVisaType = () => {
    handleChangeSearchParam('visaType', null);
    setVisaTypeList([]);
  };

  const resetVisaDuration = () => {
    handleChangeSearchParam('visaDuration', null);
    setVisaDurationList([]);
  };

  const resetVisaUrgency = () => {
    handleChangeSearchParam('visaUrgency', null);
    setVisaUrgencyList([]);
  };

  const resetFilters = () => {
    setSearchState({ perPage: searchState.perPage, ...DEFAULT_SEARCH_VALS });
    setSearch('');
    handleChangeSearch('');
    setSearchHasValue(false);
  };

  useEffect(() => {
    dispatch(
      getServices({
        page: searchState.page,
        page_size: searchState.perPage.value,
        ordering: ordering,
        product_type: searchState.serviceType?.value ?? '',
        status: searchState.status.value,
        customer_nationality_region: searchState.region?.value ?? '',
        country: searchState.country?.value ?? '',
        tax_enabled: searchState.vat?.value ?? '',
        visa_type: searchState.visaType?.value ?? '',
        visa_duration: searchState.visaDuration?.value ?? '',
        visa_urgency: searchState.visaUrgency?.value ?? '',
        order_type: searchState.orderType?.value ?? '',
        search,
      }),
    );
  }, [searchState, ordering, search]);

  useEffect(() => {
    setCookie('services', { searchState, search }, { path: '/', maxAge: 3600 });
  }, [searchState, search]);

  const showPagination = searchState.perPage.value < count;

  return (
    <Layout
      extraContent={
        <ButtonCreate
          handleCreate={() => push('/services/create', { isOpenEdit: true })}
        />
      }
      title={t('sideBar.services')}
    >
      <ShadowCard>
        <Row>
          <Col xs={12}>
            <ResponsiveFilters
              lastItem={
                <PerPageSelect
                  perPage={searchState.perPage}
                  setPerPage={value =>
                    handleChangeSearchParam('perPage', value)
                  }
                  setPage={value => handleChangeSearchParam('page', value)}
                />
              }
            >
              <div className="me-3 mb-3">
                <SearchInput
                  type="text"
                  name="search"
                  // defaultValue={search}
                  value={search}
                  handleChange={event => handleChangeSearch(event.target.value)}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="order.orderType"
                  $type="gray"
                  statuses={orderTypes}
                  handlePickStatus={value =>
                    handleChangeSearchParam('orderType', value)
                  }
                  value={searchState.orderType}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="common.type"
                  $type="gray"
                  statuses={SERVICE_TYPES}
                  handlePickStatus={value =>
                    handleChangeSearchParam('serviceType', value)
                  }
                  value={searchState.serviceType}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="common.country"
                  $type="gray"
                  statuses={countriesOptionsShort}
                  handlePickStatus={value => {
                    handleChangeSearchParam('country', value);
                    resetVisaDuration();
                    resetVisaUrgency();
                  }}
                  value={searchState.country}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="services.visaType"
                  $type="gray"
                  statuses={visaTypeList}
                  disabled={!searchState?.country?.value}
                  handlePickStatus={value => {
                    handleChangeSearchParam('visaType', value);
                    resetVisaDuration();
                    resetVisaUrgency();

                    const durations =
                      value?.durations?.map(item => ({
                        value: item.id,
                        label: item.translations[i18n.language],
                      })) || [];

                    setCookie(
                      'durations',
                      { durations },
                      { path: '/', maxAge: 3600 },
                    );
                    setVisaDurationList(durations);

                    const urgencies =
                      value?.urgencies?.map(item => ({
                        value: item.id,
                        label: item.translations[i18n.language],
                      })) || [];

                    setCookie(
                      'urgencies',
                      { urgencies },
                      { path: '/', maxAge: 3600 },
                    );
                    setVisaUrgencyList(urgencies);
                  }}
                  value={searchState.visaType}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="services.visaDuration"
                  $type="gray"
                  statuses={visaDurationList}
                  disabled={!searchState?.visaType?.value}
                  handlePickStatus={value =>
                    handleChangeSearchParam('visaDuration', value)
                  }
                  value={searchState.visaDuration}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="services.visaUrgency"
                  $type="gray"
                  statuses={visaUrgencyList}
                  disabled={!searchState?.visaType?.value}
                  handlePickStatus={value =>
                    handleChangeSearchParam('visaUrgency', value)
                  }
                  value={searchState.visaUrgency}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="common.region"
                  $type="gray"
                  statuses={regionList}
                  handlePickStatus={value =>
                    handleChangeSearchParam('region', value)
                  }
                  value={searchState.region}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="common.status"
                  $type="gray"
                  statuses={SERVICES_STATUS}
                  handlePickStatus={value =>
                    handleChangeSearchParam('status', value)
                  }
                  value={searchState.status}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="invoices.vat"
                  $type="gray"
                  classNameButton="w-100"
                  statuses={VAT_STATUSES}
                  handlePickStatus={value =>
                    handleChangeSearchParam('vat', value)
                  }
                  value={searchState.vat}
                />
              </div>

              {searchHasValue && (
                <div className="me-3 mb-3">
                  <StyledText onClick={resetFilters} className="me-2">
                    {t('common.resetFilters')}
                  </StyledText>
                </div>
              )}
            </ResponsiveFilters>

            {isOpen && (
              <ModalConfirmDelete
                isOpenModal={isOpen}
                textHeader="services.delete"
                textBody="services.deleteConfirm"
                handleCloseModal={handleCloseModal}
                handleDelete={() => handleDeleteService(isOpen)}
              />
            )}

            {loading && (
              <Row className="d-flex justify-content-center">
                <Loader isLoading={loading} />
              </Row>
            )}

            {!loading && services.length === 0 && (
              <Alert color="info">{t('services.notFound')}</Alert>
            )}

            {!loading && services.length > 0 && (
              <>
                <div className="table-responsive">
                  <StyledTable>
                    <thead>
                      <tr>
                        <th className="col-1">{t('common.id')}</th>

                        <th className="col-2">
                          {t('common.name')}
                          <SortButton
                            ordering={ordering}
                            name={'name'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th className="col-2">{t('order.orderType')}</th>

                        <th>
                          {t('common.type')}
                          <SortButton
                            ordering={ordering}
                            name={'product_type'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th>
                          {t('services.visaType')}
                          <SortButton
                            ordering={ordering}
                            name={'visa_type'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th>
                          {t('services.visaDuration')}
                          <SortButton
                            ordering={ordering}
                            name={'visa_duration'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th>
                          {t('services.visaUrgency')}
                          <SortButton
                            ordering={ordering}
                            name={'visa_urgency'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th>
                          {t('common.country')}
                          <SortButton
                            ordering={ordering}
                            name={'country'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th>
                          {t('common.region')}
                          <SortButton
                            ordering={ordering}
                            name={'customer_nationality_region'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th>
                          {t('common.nationality')}
                          <SortButton
                            ordering={ordering}
                            name={'customer_nationality'}
                            onSort={setOrdering}
                          />
                        </th>

                        <th>{t('common.status')}</th>

                        <th>{t('common.vatEnabled')}</th>

                        <th />
                      </tr>
                    </thead>

                    <tbody>
                      {services.map(
                        ({
                          name,
                          id,
                          status,
                          customer_nationality,
                          product_type,
                          country,
                          customer_nationality_region,
                          order_type,
                          tax_enabled,
                          visa_type,
                          visa_duration,
                          visa_urgency,
                        }) => (
                          <tr key={id}>
                            <td>{id}</td>

                            <td>
                              <BlueLink
                                to={`/services/${id}/${product_type.type}`}
                              >
                                {name[i18n.language]}
                              </BlueLink>
                            </td>

                            <td>
                              {
                                orderTypes.find(
                                  oType => oType.value === order_type,
                                )?.label
                              }
                            </td>

                            <td className="text-nowrap">
                              {t(
                                SERVICE_TYPES.find(
                                  item => item.value === product_type.type,
                                ).label,
                              )}
                            </td>

                            <td className="text-nowrap">
                              {visa_type?.translations[i18n.language]}
                            </td>

                            <td className="text-nowrap">
                              {visa_duration?.translations[i18n.language]}
                            </td>

                            <td className="text-nowrap">
                              {visa_urgency?.translations[i18n.language]}
                            </td>

                            <td className="text-nowrap">
                              {country?.country?.name ?? t('common.all')}
                            </td>

                            <td className="text-nowrap">
                              {customer_nationality_region?.name ??
                                t('common.all')}
                            </td>

                            <td className="text-nowrap">
                              {customer_nationality?.country?.name ??
                                t('common.all')}
                            </td>

                            <td className="text-nowrap">
                              <Badge
                                color={convertStatusToColor(status)}
                                text={
                                  SERVICES_STATUS.find(
                                    stat => stat.value === status,
                                  )?.label
                                }
                              />
                            </td>

                            <td>
                              <i
                                className={classNames('fs-5', [
                                  tax_enabled
                                    ? 'bx bx-check-circle text-success'
                                    : 'bx bx-x-circle text-danger',
                                ])}
                              />
                            </td>

                            <td>
                              <StyledEmptyButton
                                onClick={() => handleOpenModal(id)}
                              >
                                <i className="bx bxs-trash-alt" />
                              </StyledEmptyButton>
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </StyledTable>
                </div>

                <Row>
                  <Col xs={12}>
                    {showPagination && (
                      <PaginationLm
                        count={count}
                        perPage={searchState.perPage.value}
                        active={searchState.page}
                        selectPage={val =>
                          setSearchState(prev => ({
                            ...prev,
                            page: val,
                          }))
                        }
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </ShadowCard>
    </Layout>
  );
};

export default Services;

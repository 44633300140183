import { CardTitle, ShadowCard } from 'components/Common';
import { NavBar } from 'components/Common/NavBar';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmailWithFormik from './EmailWithFormik';
import PasswordWithFormik from './PasswordWithFormik';
import { isUserEmployee, isUserIndividual } from 'helpers/jwt_helper';
import { AddressesCard } from './AddressesCard';
import { LayoutDetailPages } from 'hoc/LayoutDetailPages';
import classNames from 'classnames';
import { useMatchMedia } from 'hooks/useMatchMedia';

const getProfileState = state => state.clientCabinet;

const isVisibleAddress = isUserIndividual || isUserEmployee;

const profileNav = (() => {
  let defaultNav = [
    { title: 'common.information', id: 1, link: 'information', offset: -110 },
    { title: 'auth.password', id: 2, link: 'password' },
  ];

  if (isVisibleAddress) {
    return defaultNav.concat({
      title: 'client.addresses',
      id: 3,
      link: 'addresses',
    });
  }

  return defaultNav;
})();

const Profile = memo(() => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { profile } = useSelector(getProfileState);
  const { isMobile } = useMatchMedia();

  return (
    <LayoutDetailPages
      layoutConfig={{
        title: profile.first_name
          ? `${profile.first_name} ${profile.last_name}`
          : t('profile.profile'),
        isScrollableHeader: true,
        extraContent: (
          <div
            className={classNames(
              'd-flex w-100',
              isMobile ? 'justify-content-start' : 'justify-content-end',
            )}
          >
            <StyledUpload
              className="px-4 d-flex align-items-center"
              onClick={() => push('/logout')}
            >
              <i className="bx bx-log-out me-2 fs-4" /> {t('profile.logOut')}
            </StyledUpload>
          </div>
        ),
      }}
      navBarContent={<NavBar scrollableHeader components={profileNav} />}
    >
      <ShadowCard id="information" bodyStyle={{ padding: 24 }}>
        <CardTitle title="common.information" />

        <EmailWithFormik profile={profile} />
      </ShadowCard>

      <ShadowCard className="my-4" id="password" bodyStyle={{ padding: 24 }}>
        <CardTitle title="auth.password" />

        <PasswordWithFormik />
      </ShadowCard>

      {isVisibleAddress && (
        <ShadowCard id="addresses" bodyStyle={{ padding: 24 }}>
          <CardTitle title="client.addresses" />

          <AddressesCard />
        </ShadowCard>
      )}
    </LayoutDetailPages>
  );
});

export default Profile;

import { useRef, useEffect } from 'react';

export const useUnload = fn => {
  const callback = useRef(fn);

  callback.current = fn;

  useEffect(() => {
    const onUnload = (...args) => callback.current?.(...args);

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
};

/* INVOICES */
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL';

export const GET_INVOICE_DETAIL = 'GET_INVOICE_DETAIL';
export const GET_INVOICE_DETAIL_SUCCESS = 'GET_INVOICE_DETAIL_SUCCESS';
export const GET_INVOICE_DETAIL_FAIL = 'GET_INVOICE_DETAIL_FAIL';

export const CHANGE_INVOICE_STATUS = 'CHANGE_INVOICE_STATUS';

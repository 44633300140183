import {
  OPEN_LEFT_SIDEBAR,
  TOGGLE_LEFT_SIDEBAR,
  CLOSE_LEFT_SIDEBAR,
} from './actionTypes';

const INIT_STATE = {
  leftSideBarType: JSON.parse(localStorage.getItem('Layout')) ?? true,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LEFT_SIDEBAR:
      localStorage.setItem('Layout', JSON.stringify(!state.leftSideBarType));
      return {
        ...state,
        leftSideBarType: !state.leftSideBarType,
      };

    case OPEN_LEFT_SIDEBAR:
      return {
        ...state,
        leftSideBarType: true,
      };
    case CLOSE_LEFT_SIDEBAR:
      return {
        ...state,
        leftSideBarType: false,
      };
    default:
      return state;
  }
};

export default Layout;

import { Select } from 'components/Common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectApplicantsAddresses = ({
  isDisabled,
  value,
  applicantsWithAddresses,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      applicantsWithAddresses.map(applicant => ({
        value: applicant?.applicantDetail?.id,
        label: `${applicant?.applicantDetail.firstName} ${
          applicant?.applicantDetail.lastName
        }, ${applicant?.address?.city ?? ''} ${
          applicant?.address?.postalCode ?? ''
        }`,
      })),
    [applicantsWithAddresses],
  );

  return (
    <Select
      className="w-100"
      options={options}
      onChange={onChange}
      placeholder={t('order.applicantsAddressesPlaceholder')}
      isDisabled={isDisabled}
      value={options.find(item => item.value === value)}
      classNamePrefix="select2-selection"
      {...props}
    />
  );
};

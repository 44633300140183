import classNames from 'classnames';
import { FIRST_PAGE } from 'common/data/constants';
import React, { useMemo } from 'react';
import { PaginationLink } from 'reactstrap';
import { StyledPagination, StyledPaginationItem } from './StyledPagination';

export const PaginationLm = ({
  count,
  active,
  perPage,
  selectPage,
  variant = 'horizontal',
}) => {
  const isFullPages = count % perPage === 0;
  const pageCount = isFullPages
    ? Math.trunc(count / perPage)
    : Math.trunc(count / perPage) + 1;
  let startPage = 1;
  if (pageCount > 1 && active > 2 && active < pageCount - 2) {
    startPage = active - 2;
  } else if (pageCount > 4 && active >= pageCount - 2) {
    startPage = pageCount - 4;
  }
  const pages = Array(pageCount >= 5 ? 5 : pageCount)
    .fill()
    .map((v, i) => startPage + i);

  const LAST_PAGE = pageCount;
  const PREV = !(active > 1);

  const selectPagePagination = (e, page) => {
    e.preventDefault();
    selectPage(page);
  };

  const isVisibleLast = useMemo(() => {
    return !(LAST_PAGE === pages.length || pages.includes(LAST_PAGE));
  }, [LAST_PAGE, pages]);

  const isVisibleFirst = useMemo(() => {
    return !pages.includes(FIRST_PAGE);
  }, [pages]);

  return (
    <StyledPagination
      className={classNames([
        'pagination justify-content-center mt-2 ',
        variant === 'vertical' && 'flex-column',
      ])}
    >
      <StyledPaginationItem disabled={PREV}>
        <PaginationLink
          previous
          href=""
          onClick={e => selectPagePagination(e, active - 1)}
        >
          <i className="bx bx-left-arrow-alt" />
        </PaginationLink>
      </StyledPaginationItem>

      {isVisibleFirst && (
        <>
          <StyledPaginationItem disabled={PREV}>
            <PaginationLink
              previous
              href=""
              onClick={e => selectPagePagination(e, FIRST_PAGE)}
            >
              1
            </PaginationLink>
          </StyledPaginationItem>

          {pages[0] !== 2 && pages[0] !== 3 && (
            <StyledPaginationItem disabled>
              <PaginationLink href="">...</PaginationLink>
            </StyledPaginationItem>
          )}

          {pages.at() === 3 && (
            <StyledPaginationItem>
              <PaginationLink href="" onClick={e => selectPagePagination(e, 2)}>
                2
              </PaginationLink>
            </StyledPaginationItem>
          )}
        </>
      )}

      {pages.map(page => (
        <StyledPaginationItem key={page} active={page === active}>
          <PaginationLink href="" onClick={e => selectPagePagination(e, page)}>
            {page}
          </PaginationLink>
        </StyledPaginationItem>
      ))}

      {isVisibleLast && (
        <>
          {pages.at(-1) !== LAST_PAGE - 2 && pages.at(-1) !== LAST_PAGE - 1 && (
            <StyledPaginationItem disabled>
              <PaginationLink href="">...</PaginationLink>
            </StyledPaginationItem>
          )}

          {pages.at(-1) === LAST_PAGE - 2 && (
            <StyledPaginationItem>
              <PaginationLink
                href=""
                onClick={e => selectPagePagination(e, LAST_PAGE - 1)}
              >
                {LAST_PAGE - 1}
              </PaginationLink>
            </StyledPaginationItem>
          )}

          <StyledPaginationItem disabled={pageCount === active}>
            <PaginationLink
              last
              href=""
              onClick={e => selectPagePagination(e, LAST_PAGE)}
            >
              {LAST_PAGE}
            </PaginationLink>
          </StyledPaginationItem>
        </>
      )}

      <StyledPaginationItem disabled={pageCount === active}>
        <PaginationLink
          next
          href=""
          onClick={e => selectPagePagination(e, active + 1)}
        >
          <i className="bx bx-right-arrow-alt" />
        </PaginationLink>
      </StyledPaginationItem>
    </StyledPagination>
  );
};

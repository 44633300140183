import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import { useFormikContext } from 'formik';
import { VISUM_IO_URL } from 'helpers/url-helpers/common';
import { visumIOCreateQuestionnaireRequest } from 'helpers/api-requests/visum_io';

export const VisumIOFrame = ({
  document,
  visumIntegrationAccessToken,
  applicantIndex,
}) => {
  const formik = useFormikContext();
  const visumIntegrationDocType = document.visum_integration?.document_type;
  const visumIntegrationVisaStatus =
    formik.values.applicants[applicantIndex].visumIntegrationVisaStatus ??
    'NOT_STARTED';

  // if no visumIntegrationAccessToken, we create a visum.io questionnaire.
  // else: we get existing form using visumIntegrationAccessToken as identifier for questionnaire.

  const getUrl = accessToken => `${VISUM_IO_URL}${accessToken}`;

  const createQuestionnaire = documentType => {
    visumIOCreateQuestionnaireRequest(documentType).then(response => {
      const accessToken = response.data?.access_token;
      setQuestionnaireUrl(getUrl(accessToken));
      formik.setFieldValue(
        `applicants[${applicantIndex}].visumIntegrationAccessToken`,
        accessToken,
      );
    });
  };

  useEffect(() => {
    if (!visumIntegrationAccessToken) {
      createQuestionnaire(visumIntegrationDocType);
    }
  }, []);
  const [questionnaireUrl, setQuestionnaireUrl] = useState(
    getUrl(visumIntegrationAccessToken),
  );

  return visumIntegrationVisaStatus === 'NOT_STARTED' ? (
    <iframe
      src={questionnaireUrl}
      width="1640px"
      height="1320px"
      id=""
      className=""
      display="block"
      position="relative"
    />
  ) : (
    <Col xs={12} className=" d-flex mb-4 alert alert-info">
      Thanks. We have received your application!
    </Col>
  );
};

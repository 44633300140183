import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap';

export const TariffDisplay = ({ name, description }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="p-0 mb-4">
        <Col xs={3} lg={3} xxl={2}>
          <Label className="text-muted fw-normal m-0">
            {t('tariffs.tariffName')}
          </Label>
        </Col>

        <Col xs={6}>
          <Label className="m-0">{name}</Label>
        </Col>
      </Row>

      <Row className="p-0">
        <Col xs={3} lg={3} xxl={2}>
          <Label className="text-muted fw-normal m-0">
            {t('common.description')}
          </Label>
        </Col>

        <Col xs={6}>
          <Label className="m-0">{description}</Label>
        </Col>
      </Row>
    </div>
  );
};

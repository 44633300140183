import { useMatchMedia } from 'hooks/useMatchMedia';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { closeLeftSidebar } from 'store/actions';
import Sidebar from './Sidebar';
import { MobileSidebar } from './MobileSidebar';
import classNames from 'classnames';

const StyledMainContent = styled.div`
  min-height: 100vh;
  overflow: clip;
  width: 100%;
`;

const StyledSideBar = styled.div`
  top: 0;
  height: 100vh;
  position: sticky;
  z-index: 1001;
  background-color: ${({ theme }) => theme.colors.sideBarBg}!important;
  bottom: 0;
  margin-top: 0;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  transition: width 0.1s ease 0s;

  ${({ $type }) =>
    $type
      ? css`
          width: 250px;
        `
      : css`
          width: 70px;
        `}
`;

export const getLayoutState = state => state.Layout.leftSideBarType;

const Layout = memo(({ children }) => {
  const dispatch = useDispatch();
  const typeSideBar = useSelector(getLayoutState);

  const { isTablet, isDesktop, isMobile } = useMatchMedia();

  useEffect(() => {
    if (isTablet || !isDesktop) {
      dispatch(closeLeftSidebar());
    }
  }, [isTablet, isDesktop]);

  return (
    <div
      className={classNames('d-flex', { 'flex-wrap flex-column': isMobile })}
    >
      {isMobile ? (
        <MobileSidebar />
      ) : (
        <StyledSideBar $type={typeSideBar}>
          <Sidebar />
        </StyledSideBar>
      )}

      <StyledMainContent>{children}</StyledMainContent>
    </div>
  );
});

export default Layout;

import {
  OPERATOR_GET_DELIVERY_METHODS,
  OPERATOR_GET_DELIVERY_METHODS_SUCCESS,
  OPERATOR_GET_DELIVERY_METHODS_FAIL,
} from '../actionTypes';
import { createReducer } from '../../../helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  operatorGetDeliveryAddresses,
  operatorGetDeliveryShipments,
  operatorGetDeliveryShipment,
  operatorDeliveryShipmentDelete,
} from '../actions';

const INIT_STATE = {
  methods: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  addresses: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  shipments: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  shipment: { loading: false, data: undefined, error: undefined },
};

const methods = createReducer(INIT_STATE.methods, {
  [OPERATOR_GET_DELIVERY_METHODS]: state => ({
    ...state,
    loading: true,
    error: undefined,
  }),
  [OPERATOR_GET_DELIVERY_METHODS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    data: action.payload,
    error: undefined,
  }),
  [OPERATOR_GET_DELIVERY_METHODS_FAIL]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
});

const addresses = createReducer(INIT_STATE.addresses, {
  [operatorGetDeliveryAddresses]: state => ({
    ...state,
    loading: true,
    error: null,
  }),
  [operatorGetDeliveryAddresses.success]: (state, action) => ({
    ...state,
    data: action.payload,
    loading: false,
    error: null,
  }),
  [operatorGetDeliveryAddresses.failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
});

const shipments = createReducer(INIT_STATE.shipments, {
  [operatorGetDeliveryShipments]: state => ({
    ...state,
    loading: true,
    error: undefined,
  }),
  [operatorGetDeliveryShipments.success]: (state, action) => ({
    ...state,
    loading: false,
    data: action.payload,
    error: undefined,
  }),
  [operatorGetDeliveryShipments.failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [operatorDeliveryShipmentDelete]: state => ({
    ...state,
    loading: true,
    error: undefined,
  }),

  [operatorDeliveryShipmentDelete.success]: (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    data: {
      results: state.data.results.filter(
        delivery => delivery.id !== action.payload,
      ),
    },
  }),

  [operatorDeliveryShipmentDelete.failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
});
const shipment = createReducer(INIT_STATE.shipment, {
  [operatorGetDeliveryShipment]: state => ({
    ...state,
    loading: true,
    error: undefined,
  }),
  [operatorGetDeliveryShipment.success]: (state, action) => ({
    ...state,
    loading: false,
    data: action.payload,
    error: undefined,
  }),
  [operatorGetDeliveryShipment.failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
});

export const delivery = combineReducers({
  methods,
  shipments,
  addresses,
  shipment,
});

import {
  CREATE_CLIENT,
  CREATE_CLIENT_FAIL,
  CREATE_CLIENT_SUCCESS,
  GET_CLIENTS,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_SUCCESS,
  GET_CLIENT_DETAIL,
  GET_CLIENT_DETAIL_FAIL,
  GET_CLIENT_DETAIL_SUCCESS,
  ADD_APPLICANT_SUCCESS,
  GET_ORGANIZATION_INFO,
  GET_INDIVIDUAL_INFO,
  GET_CLIENT_INFO_FAIL,
  GET_CLIENT_INFO_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  CHANGE_APPLICANT,
  GET_CLIENT_FILTER,
  GET_CLIENT_FILTER_SUCCESS,
  GET_CLIENT_FILTER_FAIL,
  CHANGE_APPLICANT_SUCCESS,
  CHANGE_APPLICANT_FAIL,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_APPLICANT,
  DELETE_CLIENT_APPLICANT_SUCCESS,
  DELETE_CLIENT_APPLICANT_FAIL,
} from './actionTypes';

const INIT_STATE = {
  clients: [],
  client: {
    details: {},
    applicants: [],
    isSubmitting: false,
  },
  isLoading: false,
  error: null,
};

const clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS:
    case GET_CLIENT_FILTER:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CLIENT_FILTER_SUCCESS:
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        isLoading: false,
      };

    case GET_CLIENT_FILTER_FAIL:
    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case CREATE_CLIENT:
      return {
        ...state,
        isLoading: true,
        client: { ...state.client, isSubmitting: true },
      };

    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        client: { ...state.client, isSubmitting: false },
      };

    case CREATE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        client: { ...state.client, isSubmitting: false },
      };

    case GET_CLIENT_DETAIL:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        client: {
          ...state.client,
          applicants: action.payload.results,
        },
      };

    case GET_CLIENT_DETAIL_FAIL:
      return {
        ...state,
        client: {},
        isLoading: false,
      };

    case DELETE_CLIENT:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clients: {
          ...state.clients.results,
          results: state.clients.results.filter(
            client =>
              client.base_client_id !== action.payload.client.base_client_id,
          ),
        },
      };

    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case DELETE_CLIENT_APPLICANT:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_CLIENT_APPLICANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        client: {
          ...state.client.applicants,
          applicants: state.client.applicants.filter(
            applicant => applicant.id !== action.payload.id,
          ),
        },
      };

    case DELETE_CLIENT_APPLICANT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ADD_APPLICANT_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          applicants: [...state.client.applicants, action.payload],
        },
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        isLoading: true,
        client: { isSubmitting: true },
      };
    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        isLoading: false,
        client: { isSubmitting: false },
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        client: {
          ...state.client,
          details: action.payload,
          isSubmitting: false,
        },
      };

    case CHANGE_APPLICANT:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        client: {
          ...state.client,
          isSubmitting: true,
        },
      };

    case CHANGE_APPLICANT_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          isSubmitting: false,
          applicants: [
            ...state.client.applicants.map(item => {
              if (item.id === action.payload.id) {
                return {
                  ...action.payload,
                };
              }
              return item;
            }),
          ],
        },
        isLoading: false,
        error: null,
      };

    case CHANGE_APPLICANT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        client: {
          ...state.client,
          isSubmitting: false,
        },
      };

    case GET_ORGANIZATION_INFO:
      return {
        ...state,
        client: {},
        isLoading: true,
      };
    case GET_INDIVIDUAL_INFO:
      return {
        ...state,
        client: {},
        isLoading: true,
      };
    case GET_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          details: action.payload,
        },
        isLoading: false,
      };
    case GET_CLIENT_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default clients;

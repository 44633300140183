import { StyledPhoneInput } from 'components/Common';
import { StyledInput, StyledLabel } from 'components/Common/FormikInput';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const name = 'contacts';

export const ClientEditContacts = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <>
      {formik.values.clientType === 'legal' && (
        <>
          <Row className="p-0 mb-4 align-items-center">
            <Col xs={3}>
              <StyledLabel className="m-0">{t('client.contact')}</StyledLabel>
            </Col>

            <Col xs={9}>
              <StyledInput
                name={`${name}.contactName`}
                value={formik.values.contacts?.contactName}
                placeholder={t('client.contactPlaceholder')}
                onChange={formik.handleChange}
                invalid={formik.errors?.contacts?.contactName}
              />
            </Col>
          </Row>

          <Row className="p-0 mb-4 align-items-center">
            <Col xs={3}>
              <StyledLabel className="m-0">{t('client.jobTitle')}</StyledLabel>
            </Col>

            <Col xs={9}>
              <StyledInput
                name={`${name}.contactJobTitle`}
                value={formik.values.contacts?.contactJobTitle}
                placeholder={t('client.jobTitlePlaceholder')}
                onChange={formik.handleChange}
                invalid={formik.errors?.contacts?.contactJobTitle}
              />
            </Col>
          </Row>
        </>
      )}

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.phone')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledPhoneInput
            value={formik.values.contacts?.phone ?? ''}
            placeholder={t('client.phonePlaceholder')}
            country="de"
            onChange={value => formik.setFieldValue(`${name}.phone`, value)}
            errors={formik.errors?.contacts?.phone}
          />
        </Col>
      </Row>

      {formik.values.clientType === 'legal' && (
        <Row className="p-0 mb-4 align-items-center">
          <Col xs={3}>
            <StyledLabel className="m-0">{t('client.fax')}</StyledLabel>
          </Col>

          <Col xs={5}>
            <StyledPhoneInput
              value={formik.values.contacts?.fax ?? ''}
              placeholder={t('client.faxPlaceholder')}
              country="de"
              onChange={value => formik.setFieldValue(`${name}.fax`, value)}
              errors={formik.errors?.contacts?.fax}
            />
          </Col>
        </Row>
      )}

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('auth.email')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.email`}
            value={formik.values.contacts?.email}
            placeholder={t('auth.enterEmail')}
            onChange={formik.handleChange}
            invalid={formik.errors?.contacts?.email}
          />
        </Col>
      </Row>
    </>
  );
};

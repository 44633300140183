import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  deleteTariffServices,
  fetchTariffServices,
  fetchTariff,
  fetchTariffs,
  updateTariff,
  updateTariffServicePrice,
  fetchCurrentTariffServices,
  deleteTariff,
} from './actions';

const initState = {
  tariffDetail: {
    tariff: {},
    isLoading: false,
    services: {
      results: [],
      count: null,
      prev: null,
      next: null,
    },
  },
  tariffs: {
    tariffs: [],
    count: null,
    previous: null,
    next: null,
    loading: false,
  },
};

const tariffs = createReducer(initState, {
  [fetchTariff]: state => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      isLoading: true,
    },
  }),
  [fetchTariff.success]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      tariff: {
        ...state.tariffDetail.tariff,
        description: action.payload.description,
        id: action.payload.id,
        name: action.payload.name,
        tags: action.payload.tags,
      },
      isLoading: false,
    },
  }),

  [fetchCurrentTariffServices]: state => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      isLoadingServices: true,
    },
  }),

  [fetchCurrentTariffServices.success]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      tariff: {
        ...state.tariffDetail.tariff,
        products: action.payload.results,
        productsSearch: {
          count: action.payload.count,
          num_pages: action.payload.num_pages,
        },
      },
      isLoadingServices: false,
    },
  }),

  [fetchCurrentTariffServices.failure]: state => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      tariff: {
        ...state.tariffDetail.tariff,
        products: {},
        productsSearch: {},
      },
      isLoadingServices: false,
    },
  }),

  [fetchTariff.failure]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      error: action.payload,
      isLoading: false,
    },
  }),

  [fetchTariffs]: state => ({
    ...state,
    tariffs: { ...state.tariffs, loading: true },
  }),
  [fetchTariffs.success]: (state, action) => ({
    ...state,
    tariffs: { ...action.payload, loading: false },
  }),
  [fetchTariffs.failure]: (state, action) => ({
    ...state,
    tariffs: { ...state.tariffs, error: action.payload },
  }),

  [deleteTariff]: state => ({
    ...state,
  }),
  [deleteTariff.success]: (state, action) => ({
    ...state,
    tariffs: {
      ...state.tariffs,
      tariffs: state.tariffs.tariffs.filter(
        tariff => tariff.id !== action.payload,
      ),
    },
  }),
  [deleteTariff.failure]: (state, action) => ({
    ...state,
    tariffs: { ...state.tariffs, error: action.payload },
  }),

  [updateTariff]: state => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      isLoading: false,
      isSubmitting: true,
    },
  }),
  [updateTariff.success]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      tariff: action.payload,
      isLoading: false,
      isSubmitting: false,
    },
  }),
  [updateTariff.failure]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      error: action.payload,
      isLoading: false,
      isSubmitting: false,
    },
  }),

  [updateTariffServicePrice.success]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      tariff: {
        ...state.tariffDetail.tariff,
        products: state.tariffDetail.tariff.products.map(product => {
          if (product.product_id === action.payload.product_id) {
            return action.payload;
          }
          return product;
        }),
      },
    },
  }),
  [updateTariffServicePrice.failure]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      error: action.payload,
      isLoading: false,
    },
  }),

  [fetchTariffServices]: state => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      isLoadingServices: true,
    },
  }),
  [fetchTariffServices.success]: (state, action) => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      isLoadingServices: false,
      services: action.payload,
    },
  }),
  [fetchTariffServices.failure]: state => ({
    ...state,
    tariffDetail: {
      ...state.tariffDetail,
      isLoadingServices: false,
      services: {},
    },
  }),

  [deleteTariffServices]: state => ({
    ...state,
  }),
  [deleteTariffServices.success]: (state, action) => {
    return {
      ...state,
      tariffDetail: {
        ...state.tariffDetail,
        tariff: {
          ...state.tariffDetail.tariff,
          products: state.tariffDetail.tariff.products.filter(
            product => !action.payload.selectedServices.includes(product.id),
          ),
        },
      },
    };
  },
});

export default combineReducers({
  tariffs,
});

import React, { useEffect, useMemo, useState } from 'react';
import { EMPTY_VALUE_WITH_LABEL } from 'common/data/constants';
import { Select } from 'components/Common';
import { getOrderTypeServicesRequest } from 'helpers/api-requests/admin';
import i18n from 'i18n';
import styled from 'styled-components';
import { Label } from 'reactstrap';

const StyledHelpText = styled(Label)`
  font-size: 12px;
  font-weight: 500;
`;

export const OrderTypeServiceSelector = ({
  value,
  helptext = null,
  ...props
}) => {
  const orderTypeId = props.orderType;
  const countryId = props.country;
  const [services, setServices] = useState([]);

  useEffect(() => {
    getOrderTypeServicesRequest(orderTypeId, { country: countryId })
      .then(response => {
        const results = response?.data?.results ?? [];
        if (results.length > 0) {
          setServices(results);
        }
      })
      .catch(err => console.log(err));
  }, []);

  const options = useMemo(() => {
    return services.map(item => ({
      value: item.id,
      label: item.name[i18n.language],
    }));
  }, [services]);

  const val = useMemo(() => {
    return options.find(item => item.value === value);
  }, [options, value]);

  return (
    <>
      <Select
        value={val}
        options={[EMPTY_VALUE_WITH_LABEL, ...options]}
        className="w-100"
        classNamePrefix="select2-selection"
        {...props}
      />
      {helptext && <StyledHelpText>{helptext}</StyledHelpText>}
    </>
  );
};

import { CardTitle, ShadowCard } from 'components/Common';
import { formatCurrency } from 'helpers/formatCurrency';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap';

export const InvoicePaymentCard = ({ invoiceDetails }) => {
  const { t } = useTranslation();

  return (
    <ShadowCard bodyStyle={{ padding: '24px' }} id="payment" className="mt-4">
      <Row>
        <Col xs={12}>
          <CardTitle title="invoices.forPayment" />
        </Col>
      </Row>

      <Row className="p-0 mb-3">
        <Col xs={6} lg={3}>
          <Label className="text-muted fw-normal">{t('invoices.net')}</Label>
        </Col>

        <Col xs={6} lg={2}>
          <Label>
            {formatCurrency(invoiceDetails.details.bill_net_price_euro)}
          </Label>
        </Col>
      </Row>

      <Row>
        <Col xs={6} lg={3}>
          <Label className="text-muted fw-normal">{t('invoices.vat')}</Label>
        </Col>

        <Col xs={6} lg={2}>
          <Label>
            {formatCurrency(invoiceDetails.details.bill_tax_total_euro)}
          </Label>
        </Col>

        <Col xs={12}>
          <hr className="mb-4" />
        </Col>

        <Col xs={6} lg={3}>
          <Label className="text-muted fw-normal">{t('invoices.total')}</Label>
        </Col>

        <Col xs={6} lg={2}>
          <Label style={{ fontSize: '20px' }} className="fw-semibold">
            {formatCurrency(invoiceDetails.details.bill_total_price_euro)}
          </Label>
        </Col>
      </Row>
    </ShadowCard>
  );
};

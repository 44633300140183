import { CardTitle, ShadowCard } from 'components/Common';
import FormikInput, { StyledLabel } from 'components/Common/FormikInput';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

export const UserPasswordCard = () => {
  const { values, errors, handleChange } = useFormikContext();
  const { t } = useTranslation();

  return (
    <ShadowCard className="mt-4" bodyStyle={{ padding: '24px 24px 0 24px' }}>
      <CardTitle title="auth.password" />

      <Row className="p-0 align-items-center">
        <Col xs={3}>
          <StyledLabel className="mb-4">{t('auth.password')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <FormikInput
            errors={errors.password}
            placeholder={t('users.newPasswordPlaceholder')}
            value={values.password}
            handleChange={handleChange}
            name="password"
            type="password"
          />
        </Col>
      </Row>

      <Row className="p-0 align-items-center">
        <Col xs={3}>
          <StyledLabel className="mb-4">{t('auth.repeatPassword')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <FormikInput
            groupStyle="m-0 mb-0"
            placeholder={t('auth.repeatPasswordPlaceholder')}
            errors={errors.verifyPassword}
            value={values.verifyPassword}
            handleChange={handleChange}
            name="verifyPassword"
            type="password"
          />
        </Col>
      </Row>
    </ShadowCard>
  );
};

import { post } from 'helpers/api_helper';
import { VISUM_IO_SERVICE_URL } from 'helpers/url-helpers/common';
import authHeader from '../jwt-token-access/auth-token-header';

export const visumIOCreateQuestionnaireRequest = docType =>
  post(
    VISUM_IO_SERVICE_URL,
    { document_type: docType },
    { headers: authHeader() },
  );

import React from 'react';
import { CardTitle, ShadowCard } from 'components/Common';

import { ClientEditAddress } from './ClientEditAddress';

export const ClientAddressCard = () => (
  <ShadowCard className="mt-4" id="address" bodyStyle={{ padding: '24px' }}>
    <CardTitle title="client.address" />

    <ClientEditAddress />
  </ShadowCard>
);

import React from 'react';
import * as adminApi from 'helpers/api-requests/admin';
import { SELECT_CLASSNAME } from 'common/data/constants';
import { StyledAsyncPaginate } from 'pages/Report/SelectClients';
import { useTranslation } from 'react-i18next';

const PAGE_SIZE = 50;

export const OrganizationsSelect = ({ invalid, ...props }) => {
  const { t } = useTranslation();

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await adminApi.getOrganizations({
      search,
      page,
      page_size: PAGE_SIZE,
    });

    return {
      options: response.data.results,
      hasMore: Boolean(response.data.next),
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <StyledAsyncPaginate
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      loadOptions={loadOptions}
      isClearable
      invalid={invalid}
      placeholder={t('users.organisationPlaceholder')}
      debounceTimeout={200}
      classNamePrefix={SELECT_CLASSNAME}
      additional={{
        page: 1,
      }}
      {...props}
    />
  );
};

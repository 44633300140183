import React from 'react';
import classnames from 'classnames';

const SORT_TYPES = { asc: 'asc', desc: 'desc', default: '' };

const SortButton = props => {
  const { ordering, name } = props;

  const icon =
    name === ordering
      ? SORT_TYPES.asc
      : `-${name}` === ordering
      ? SORT_TYPES.desc
      : SORT_TYPES.default;

  const calcOrder = () => {
    switch (ordering) {
      case name:
        return `-${name}`;
      case `-${name}`:
        return undefined;
      default:
        return name;
    }
  };

  const sort = e => {
    e.preventDefault();
    props.onSort(calcOrder());
  };
  return (
    <a href="" onClick={sort}>
      <i
        className={classnames([
          'bx',
          icon === SORT_TYPES.default && 'bx-sort',
          icon === SORT_TYPES.asc && 'bx-down-arrow-alt',
          icon === SORT_TYPES.desc && 'bx-up-arrow-alt',
        ])}
      />
    </a>
  );
};

export default SortButton;

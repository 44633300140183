import { createReducer } from 'helpers/store/createReducer';
import {
  addMessage,
  changeChatStatus,
  getChatDetail,
  getChats,
  getMessages,
  getMoreMessages,
  selectChatRoom,
} from './actions';

const INIT_STATE = {
  chats: {
    results: [],
    count: 0,
    next: null,
    prev: null,
    loading: false,
  },
  selectedChat: {
    id: null,
    detail: {
      id: null,
      created_at: null,
      updated_at: null,
      status: null,
      has_unread_from_user: false,
    },
    messages: {
      count: null,
      next: null,
      messages: [],
    },
  },
};

const Chats = createReducer(INIT_STATE, {
  [getChats]: state => ({
    ...state,
    chats: { ...state.chats, loading: true },
  }),
  [getChats.success]: (state, action) => ({
    ...state,
    chats: { ...action.payload, loading: false },
  }),
  [getChats.failure]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [getMessages]: state => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
    },
  }),
  [getMessages.success]: (state, action) => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
      isLoading: false,
      messages: action.payload,
    },
  }),
  [getMessages.failure]: state => ({
    ...state,
    isLoading: false,
  }),

  [addMessage]: state => ({
    ...state,
  }),
  [addMessage.success]: (state, action) => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
      messages: {
        ...state.selectedChat.messages,
        messages: [action.payload, ...state.selectedChat.messages.messages],
      },
    },
  }),
  [addMessage.failure]: state => ({
    ...state,
  }),

  [changeChatStatus]: state => ({
    ...state,
  }),
  [changeChatStatus.success]: (state, action) => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
      detail: action.payload,
    },
  }),
  [changeChatStatus.failure]: state => ({
    ...state,
  }),

  [selectChatRoom]: (state, action) => ({
    ...state,
    selectedChat: {
      ...INIT_STATE.selectedChat,
      id: action.payload,
      isLoading: true,
    },
  }),

  [getMoreMessages]: state => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
      messages: {
        ...state.selectedChat.messages,
        isLoading: true,
      },
    },
  }),
  [getMoreMessages.success]: (state, action) => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
      messages: {
        ...state.selectedChat.messages,
        isLoading: false,
        next: action.payload.next,
        messages: state.selectedChat.messages.messages
          .concat(action.payload.messages)
          .sort(
            (prev, next) =>
              new Date(next.created_at) - new Date(prev.created_at),
          ),
      },
    },
  }),
  [getMoreMessages.failure]: state => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
      messages: {
        isLoading: false,
        ...state.selectedChat.messages,
        next: null,
      },
    },
  }),

  [getChatDetail]: state => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
    },
  }),
  [getChatDetail.success]: (state, action) => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
      isLoading: false,
      detail: action.payload,
    },
  }),
  [getChatDetail]: state => ({
    ...state,
    selectedChat: {
      ...state.selectedChat,
    },
  }),
});

export default Chats;

import { Table } from 'reactstrap';
import styled, { css } from 'styled-components';

export const StyledTable = styled(Table)`
  vertical-align: middle;
  color: #1b1a1f !important;

  thead {
    background: #f8f8fb;
    border-bottom: 0px solid #0000 !important;
    font-weight: 600;
    padding: 14px 16px;
  }

  th {
    font-size: 14px;
    padding: 14px 16px;
    font-weight: 600 !important;
  }

  ${({ $borderless }) =>
    $borderless &&
    css`
      thead,
      tbody,
      tfoot,
      tr,
      td,
      th {
        border: none !important;
      }
    `}

  ${({ $headerShort }) =>
    $headerShort &&
    css`
      padding: 8px 16px !important;
    `}

  td {
    font-size: 14px;
    padding: 14px 16px;
  }

  thead th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  tbody tr:first-child {
    border-top: none !important;
  }

  tfoot {
    font-weight: 500;
  }

  a {
    font-weight: 500;
  }
  tr:hover td {
    background: #fffbf5;
  }
`;

export const StyledTableHead = styled.th`
  padding: 8px 16px !important;
`;

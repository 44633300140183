import { Loader, PaginationLm } from 'components/Common';
import { StyledTable, StyledTableHead } from 'components/Table/StyledTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Row } from 'reactstrap';
import { TariffServiceRow } from './TariffServiceRow';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentTariffServices } from 'store/tariffs/actions';
import SortButton from '../../components/SortButton';

export const TariffServiceTable = ({ searchParams, setPage }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ordering, setOrdering] = useState(undefined);

  const {
    tariff: { products: services, productsSearch: { count } = {} } = {},
    isLoadingServices,
  } = useSelector(state => state.tariffs.tariffs.tariffDetail);

  useEffect(() => {
    dispatch(
      fetchCurrentTariffServices({
        id,
        params: {
          product_type: searchParams.serviceType.value,
          country: searchParams.country.value,
          visa_type: searchParams.visaType?.value,
          visa_duration: searchParams.visaDuration?.value,
          visa_urgency: searchParams.visaUrgency?.value,
          region: searchParams.region?.value,
          vat: searchParams.vat.value,
          search: searchParams.search,
          page: searchParams.page,
          page_size: searchParams.perPage.value,
          ordering: ordering,
          tag: searchParams.tag,
        },
      }),
    );
  }, [searchParams, ordering, id]);

  const showPagination = useMemo(
    () => searchParams.perPage.value < count,
    [count, searchParams.perPage],
  );

  return (
    <>
      {!isLoadingServices && count > 0 ? (
        <StyledTable className="mb-0" responsive>
          <thead>
            <tr>
              <StyledTableHead>
                {t('common.name')}
                <SortButton
                  ordering={ordering}
                  name={'name'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>
                {t('common.type')}
                <SortButton
                  ordering={ordering}
                  name={'type'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>
                {t('services.visaType')}
                <SortButton
                  ordering={ordering}
                  name={'visa_type'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>
                {t('services.visaDuration')}
                <SortButton
                  ordering={ordering}
                  name={'visa_duration'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>
                {t('services.visaUrgency')}
                <SortButton
                  ordering={ordering}
                  name={'visa_urgency'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>
                {t('common.country')}
                <SortButton
                  ordering={ordering}
                  name={'country'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>
                {t('common.region')}
                <SortButton
                  ordering={ordering}
                  name={'region'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>
                {t('common.nationality')}
                <SortButton
                  ordering={ordering}
                  name={'nationality'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>{t('common.tags')}</StyledTableHead>

              <StyledTableHead>
                {t('common.price')}
                <SortButton
                  ordering={ordering}
                  name={'price'}
                  onSort={setOrdering}
                />
              </StyledTableHead>

              <StyledTableHead>{t('common.tax')}</StyledTableHead>

              <StyledTableHead>{t('tariffs.priceTotal')}</StyledTableHead>

              <StyledTableHead />
            </tr>
          </thead>

          <tbody>
            {services.map(service => (
              <TariffServiceRow service={service} key={service.product_id} />
            ))}
          </tbody>
        </StyledTable>
      ) : isLoadingServices ? (
        <Row className="d-flex justify-content-center">
          <Loader isLoading={isLoadingServices} />
        </Row>
      ) : (
        <Alert color="info">{t('services.notFound')}</Alert>
      )}

      {showPagination && (
        <PaginationLm
          count={count}
          active={searchParams.page}
          perPage={searchParams.perPage.value}
          selectPage={setPage}
        />
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { getCountries } from 'store/actions';

export const DualCountryListSelector = ({ selected, onChange }) => {
  const countries = useSelector(state => state.references.countries);
  const dispatch = useDispatch();

  const countriesConverted = countries && [
    {
      label: 'EU',
      options: countries
        ?.filter(country => country.is_eu_country)
        .map(item => ({ value: item.id, label: item.country?.name })),
    },
    {
      label: 'Non EU',
      options: countries
        ?.filter(country => !country.is_eu_country)
        .map(item => ({ value: item.id, label: item.country?.name })),
    },
  ];

  useEffect(() => {
    if (!countries || countries.length === 0) {
      dispatch(getCountries());
    }
  }, []);

  return (
    <DualListBox
      canFilter
      alignActions="top"
      options={countriesConverted}
      selected={selected}
      onChange={onChange}
      icons={{
        moveToAvailable: <span className="bx bx-left-arrow" />,
        moveAllToAvailable: [
          <span key={0} className="bx bx-left-arrow" />,
          <span key={1} className="bx bx-left-arrow" />,
        ],
        moveToSelected: <span className="bx bx-right-arrow" />,
        moveAllToSelected: [
          <span key={0} className="bx bx-right-arrow" />,
          <span key={1} className="bx bx-right-arrow" />,
        ],
      }}
    />
  );
};

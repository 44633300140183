import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';

const StyledDropdownToggle = styled(DropdownToggle)`
  border-radius: 50% !important;
  padding: 0;
  color: ${({ theme }) => theme.colors.gray600};
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;

  &:hover {
    border: none;
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  i {
    font-size: 16px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 50% !important;
  padding: 0;
  color: ${({ theme }) => theme.colors.gray600};
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;

  &:hover {
    border: none;
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  i {
    font-size: 16px;
  }
`;

export const ButtonControlTable = ({ actions, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-end">
      {actions ? (
        <UncontrolledDropdown>
          <StyledDropdownToggle className="d-flex align-items-center p-1">
            <i className="bx bx-dots-vertical-rounded" />
          </StyledDropdownToggle>

          <DropdownMenu container="body">
            {actions.length > 0 &&
              actions.map(action => (
                <DropdownItem
                  disabled={action.disabled}
                  onClick={action.function}
                  key={action.id}
                >
                  {t(action.title)}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <StyledButton
          onClick={onClick}
          className="d-flex align-items-center p-1"
        >
          <i className="bx bx-dots-vertical-rounded" />
        </StyledButton>
      )}
    </div>
  );
};

import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import React from 'react';
import { Input, InputGroup } from 'reactstrap';

export const EditFilename = ({
  defaultValue,
  handleChangeFilename,
  handleSaveFilename,
  handleCloseEdit,
}) => {
  const handleSubmitFilename = event => {
    if (event.keyCode === 13) {
      handleSaveFilename();
      return;
    }
    //On press Esc blur
    if (event.keyCode === 27) {
      handleCloseEdit();
    }
  };

  const extractFileName = value => value.split('.').slice(0, -1).join('.');

  const extractFileExt = value => value.split('.').pop();

  return (
    <div className="d-flex flex-nowrap justify-content-between">
      <InputGroup className="d-flex flex-nowrap">
        <Input
          type="text"
          defaultValue={extractFileName(defaultValue)}
          onChange={handleChangeFilename}
          autoFocus
          placeholder="Enter filename without extension"
          style={{ textDecoration: 'none' }}
          onKeyDown={handleSubmitFilename}
        />

        <div className="d-flex py-2 pe-3 rounded-end border-bottom border-top border-end ">
          <StyledEmptyButton onClick={handleSaveFilename}>
            <i className="bx bx-check" />
          </StyledEmptyButton>

          <StyledEmptyButton onClick={handleCloseEdit}>
            <i className="bx bx-x" />
          </StyledEmptyButton>
        </div>
        <p style={{ padding: '10px 5px 0' }}>.{extractFileExt(defaultValue)}</p>
      </InputGroup>
    </div>
  );
};

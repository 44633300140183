import { ShadowCard } from 'components/Common';
import { StyledNavItem } from 'components/Order/Tabs/ApplicantBody';
import { SearchInput } from 'components/SearchInput/SerchInput';
import React, { Fragment, useEffect, useState } from 'react';
import { Nav, NavLink } from 'reactstrap';
import { ChatCard } from './ChatCard';
import styled from 'styled-components';
import { getUserId } from 'helpers/jwt_helper';
import { useTable } from 'hooks/useTable';
import { useInView } from 'react-intersection-observer';
import { getChats } from 'helpers/api-requests/admin';
import { SCROLL_STYLE } from 'common/data/constantsStyle';
import { useDispatch, useSelector } from 'react-redux';
import { selectChatRoom } from 'store/actions';
import { useTranslation } from 'react-i18next';

const chatTabs = [
  { title: 'common.all', key: '' },
  { title: 'services.active', key: 'active' },
  { title: 'services.archived', key: 'archived' },
  { title: 'chat.onlyMy', key: getUserId() },
];

export const StyledScrollableDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  ${SCROLL_STYLE};
`;

const additionalFields = {
  perPage: 25,
  next: null,
};

export const getActiveChatState = state => state.chat.selectedChat.id;

export const ChatsCard = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeChat = useSelector(getActiveChatState);
  const [activeTab, setActiveTab] = useState(0);
  const [chats, setChats] = useState({
    chats: [],
    next: null,
    count: null,
  });

  const { searchState, handleChangeSearchParam } = useTable(additionalFields);

  useEffect(() => {
    getChats({
      payload: {
        page: searchState.page,
        search: searchState.search,
        page_size: searchState.perPage,
        last_operator: activeTab === 3 ? chatTabs[activeTab].key : undefined,
        status: activeTab !== 3 ? chatTabs[activeTab].key : undefined,
      },
    }).then(response => {
      if (response.success) {
        setChats(() => ({
          chats: [...chats.chats, ...response.data.results],
          next: response.data.next,
          count: response.data.count,
        }));
      }
    });
  }, [searchState, activeTab]);

  useEffect(() => {
    if (inView) {
      handleChangeSearchParam('page', searchState.page + 1);
    }
  }, [inView]);

  const handleSelectChat = id => {
    dispatch(selectChatRoom(id));
  };

  return (
    <ShadowCard bodyStyle={{ padding: '24px 0' }}>
      <div className="px-3 mb-2">
        <SearchInput
          isBlue
          handleChange={event => {
            handleChangeSearchParam('search', event.target.value);
            setChats(() => ({
              next: null,
              chats: [],
            }));
          }}
        />
      </div>

      <Nav
        className="mt-4 px-3 d-flex flex-nowrap justify-content-between"
        pills
      >
        {chatTabs.map((tab, index) => (
          <StyledNavItem
            className="p-0 me-3"
            active={tab.key === chatTabs[activeTab].key}
            onClick={() => {
              setActiveTab(index);
              handleChangeSearchParam('page', 1);
              setChats(() => ({
                next: null,
                chats: [],
              }));
            }}
            key={tab.key}
          >
            <NavLink className="p-0 text-center" tabIndex={0}>
              {t(tab.title)}
            </NavLink>
          </StyledNavItem>
        ))}
      </Nav>

      <StyledScrollableDiv style={{ height: '73vh' }} className="mt-2">
        {chats.count === 0 && (
          <div className="d-flex mt-4 w-100 flex-column align-items-center text-muted justify-content-center">
            <i style={{ fontSize: 50 }} className="bx bx-message-x" />
            {t('chat.notFound')}
          </div>
        )}

        {chats.chats?.length > 0 &&
          chats.chats.map(chat => (
            <Fragment key={chat.id}>
              <ChatCard
                name={`${chat.user?.first_name} ${chat.user?.last_name ?? ''}`}
                active={activeChat === chat.id}
                date={chat.updated_at}
                isNewMessage={chat.has_unread_from_user}
                handleSelectMessage={() =>
                  activeChat !== chat.id && handleSelectChat(chat.id)
                }
              />

              {activeChat !== chat.id && (
                <div style={{ padding: '0 16px 0px 65px' }}>
                  <hr className="w-100 m-0" />
                </div>
              )}
            </Fragment>
          ))}

        {chats.next && <div style={{ height: 1 }} ref={ref} />}
      </StyledScrollableDiv>
    </ShadowCard>
  );
};

import { BlueLink } from 'components/Common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import i18n from 'i18n';
import classNames from 'classnames';

export const AddTariffServiceRow = ({
  service,
  handleSelectService,
  handleOpenServiceDetail,
  selectedServices,
  // handleSetPrice,
  copyFromTariff,
}) => {
  const { t } = useTranslation();
  const currentSelectedService = selectedServices.find(
    currentService => currentService.id === service.id,
  );

  return (
    <tr key={service.id}>
      <td className="col-1">
        <Input
          onChange={() => handleSelectService(service.id)}
          type="checkbox"
          checked={!!currentSelectedService}
        />
      </td>

      <td className="col-2">
        <BlueLink
          to="#"
          onClick={() => {
            handleOpenServiceDetail(service.id, service.product_type);
          }}
        >
          {service.name[i18n.language]}
        </BlueLink>
      </td>

      <td className="col-2">{service.product_type.type_display}</td>

      <td className="text-nowrap col-1">
        {service.visa_type?.translations[i18n.language] ?? t('common.all')}
      </td>

      <td className="text-nowrap col-1">
        {service.visa_duration?.translations[i18n.language] ?? t('common.all')}
      </td>

      <td className="text-nowrap col-1">
        {service.visa_urgency?.translations[i18n.language] ?? t('common.all')}
      </td>

      <td className="text-nowrap col-1">
        {service.country?.country?.name ?? t('common.all')}
      </td>

      <td className="text-nowrap col-1">
        {service.customer_nationality_region?.name ?? t('common.all')}
      </td>

      <td className="text-nowrap col-1">
        {service.customer_nationality?.country?.name ?? t('common.all')}
      </td>

      {copyFromTariff && (
        <td className="text-nowrap col-1">
          {!isNaN(service.price) ? (
            <>€{(service.price / 100).toFixed(2)}</>
          ) : (
            <>-</>
          )}
        </td>
      )}

      <td>
        <i
          className={classNames('fs-5', [
            service.tax_enabled
              ? 'bx bx-check-circle text-success'
              : 'bx bx-x-circle text-danger',
          ])}
        />
      </td>

      {/*<td*/}
      {/*  className={classNames(*/}
      {/*    ['text-nowrap'],*/}
      {/*    ['col-4'],*/}
      {/*    [currentSelectedService && 'p-0'],*/}
      {/*  )}*/}
      {/*>*/}
      {/*  {currentSelectedService && (*/}
      {/*    <PriceInput*/}
      {/*      placeholder={t('tariffs.enterPrice')}*/}
      {/*      className="border-1 h-25"*/}
      {/*      value={currentSelectedService?.price}*/}
      {/*      onChange={event => handleSetPrice(event.target.value, service.id)}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</td>*/}
    </tr>
  );
};

export const getTitles = (steps, ln) => {
  let titles = [];
  steps.forEach(s => {
    s.layouts.forEach(l => {
      const filtered = l.fields
        .filter(f => f.data && f.data.translations[ln].indexOf('**') !== -1)
        .map(f => f.data.translations[ln].replaceAll('**', ''));
      titles = [...titles, ...filtered];
    });
  });
  return titles;
};

import { ChangeStatus } from 'components/ChangeStatus/ChangeStatus';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeChatStatus } from 'store/actions';
import { StyledAvatar } from './ChatCard';
import { getCurrentChatState } from './ChatRoomCard';
import { getActiveChatState } from './ChatsCard';

const CHAT_STATUSES = [
  { value: 'active', label: 'services.active' },
  { value: 'archived', label: 'services.archived' },
];

export const ChatRoomHeader = ({ isLoading }) => {
  const { detail } = useSelector(getCurrentChatState);
  const { t } = useTranslation();
  const activeChat = useSelector(getActiveChatState);
  const dispatch = useDispatch();

  const userName = useMemo(() => {
    if (detail.user?.first_name && detail.user?.last_name) {
      return `${detail.user.first_name} ${detail.user.last_name}`;
    }
    return null;
  }, [detail.user]);

  const handleChangeStatus = status => {
    dispatch(
      changeChatStatus({
        chatId: activeChat,
        params: { status: status.value },
      }),
    );
  };

  return (
    !isLoading &&
    activeChat &&
    userName && (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center w-100">
          <StyledAvatar
            name={userName}
            size={44}
            className="fs-4 fw-semibold me-2"
            maxInitials={2}
            round
          />

          <div className="d-flex flex-column">
            <p className="m-0 fs-6 fw-500 text-start text-nowrap">
              {userName.length > 30
                ? `${userName.substring(0, 15)}.`
                : userName}
            </p>

            <p className="text-muted text-start m-0 fs-14">
              {t('chat.active')}
            </p>
          </div>

          <ChangeStatus
            value={detail.status}
            className="w-100 d-flex justify-content-end"
            statuses={CHAT_STATUSES}
            onChange={handleChangeStatus}
          />
        </div>

        <hr className="mt-2 mb-0" />
      </div>
    )
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components/Layout';

import {
  operatorGetDeliveryShipment,
  getCountries,
  operatorDeliveryShipmentDelete,
} from 'store/actions';
import { DELIVERIES_BREADCRUMBS } from 'common/data/beadcrumbs';

import { DeliveryGeneralInfo } from './DeliveryGeneralInfo';
import { DeliveryPlace } from './DeliveryPlace';
import { NavBar } from 'components/Common/NavBar';

import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { useModal } from 'hooks/useModal';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { useHistory } from 'react-router-dom';

const deliveryNav = [
  { title: 'common.information', id: 1, link: 'information', offset: -110 },
  { title: 'delivery.deliveryFrom', id: 2, link: 'from' },
  { title: 'delivery.deliveryTo', id: 3, link: 'to' },
];

const Delivery = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { push } = useHistory();

  const { data, loading } = useSelector(state => state.admin.delivery.shipment);
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();

  const getDeliveryNavbar = deliveryNav => {
    if (data?.from_address && data?.to_address) {
      return deliveryNav;
    }

    return [deliveryNav[0]];
  };

  const handleDeleteDelivery = id => {
    dispatch(operatorDeliveryShipmentDelete({ id, handleCloseModal }));
    push('/deliveries');
  };

  useEffect(() => {
    dispatch(operatorGetDeliveryShipment(id));
    dispatch(getCountries());
  }, []);

  return (
    <Layout
      isScrollableHeader
      title={t('Delivery details')}
      loading={loading}
      breadcrumbs={DELIVERIES_BREADCRUMBS}
    >
      {isOpen && (
        <ModalConfirmDelete
          isOpenModal={isOpen}
          textHeader="delivery.delete"
          textBody="delivery.deleteConfirm"
          handleCloseModal={handleCloseModal}
          handleDelete={() => handleDeleteDelivery(isOpen.id)}
        />
      )}
      <Row>
        <Col xs={3}>
          <NavBar
            scrollableHeader
            components={getDeliveryNavbar(deliveryNav)}
            extraContent={
              <StyledUpload
                className="w-100 border-0 d-flex justify-content-center mb-4"
                disabled={!data?.is_deletable}
                onClick={() => handleOpenModal(data)}
              >
                <i className="bx bx-trash me-2" />
                {t('common.delete')}
              </StyledUpload>
            }
          />
        </Col>

        <Col xs={9} className="ps-4">
          <Col xs={12}>
            <DeliveryGeneralInfo loading={loading} delivery={data} />
          </Col>

          {data?.from_address && (
            <Col xs={12}>
              <DeliveryPlace prefix="from" delivery={data} />
            </Col>
          )}

          {data?.to_address && (
            <Col xs={12}>
              <DeliveryPlace prefix="to" delivery={data} />
            </Col>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default Delivery;

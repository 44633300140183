import { CardTitle } from 'components/Common';
import { StyledButtonCancel } from 'components/Common/StyledButtonSave';
import { StyledModal } from 'components/CreateApplicantModal/ApplicantModalForm';
import i18n from 'i18n';
import ReactMarkdown from 'react-markdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardImg,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import styled from 'styled-components';
import { StyledScrollableDiv } from 'pages/Chat_new/ChatsCard';
import { authLink } from 'helpers/jwt-token-access/auth-token-header';
import rehypeRaw from 'rehype-raw';
import { Label } from 'components/Services/ServiceForm';
import { VisumIOFrame } from './VisumIOFrame';

const StyledModalCancel = styled(StyledButtonCancel)`
  padding: 8px 32px;
`;

const StyledContainer = styled.div`
  a {
    text-decoration: underline;
  }
`;

const StyledDocumentImage = styled(CardImg)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const DocumentDetailModal = ({
  handleCloseModal,
  isOpenModal,
  document,
  visumIntegrationAccessToken,
  applicantIndex,
}) => {
  const { t } = useTranslation();

  const documentDescriptionTitle =
    document?.description?.description_title[i18n.language];
  const documentDescriptionSource =
    document?.description?.description_source[i18n.language];
  const documentDescriptionRequirements =
    document?.description?.description_requirements[i18n.language];
  const hasDescription = [
    documentDescriptionTitle,
    documentDescriptionSource,
    documentDescriptionRequirements,
  ].some(c => c);
  const hasVisumIOIntegration = !!document.visum_integration?.document_type;

  return (
    <StyledModal isOpen={isOpenModal} toggle={handleCloseModal} centered>
      <ModalHeader toggle={handleCloseModal} className="border-0 pb-2 p-4">
        {document.name[i18n.language]}
      </ModalHeader>

      <div className="w-100 ps-4 pe-4">
        <hr className="m-0" />
      </div>

      <ModalBody className="p-4">
        <Row>
          {hasDescription && (
            <Col xs={12}>
              {/* <CardTitle title="Details" /> */}

              {document.description?.description_title && (
                <div
                  key={`${document?.id}_documentDescriptionTitle`}
                  className="d-flex mb-4"
                >
                  <Col xs={3}>
                    <Label className="m-0 fs-14">
                      {t('order.documentDescriptionTitle')}
                    </Label>
                  </Col>

                  <Col xs={9}>
                    <StyledContainer className="fs-14">
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {document.description?.description_title[i18n.language]}
                      </ReactMarkdown>
                    </StyledContainer>
                  </Col>
                </div>
              )}

              {documentDescriptionSource && (
                <div
                  key={`${document?.id}_documentDescriptionSource`}
                  className="d-flex mb-4"
                >
                  <Col xs={3}>
                    <Label className="m-0 fs-14">
                      {t('order.documentDescriptionSource')}
                    </Label>
                  </Col>

                  <Col xs={9}>
                    <StyledContainer className="fs-14">
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {documentDescriptionSource}
                      </ReactMarkdown>
                    </StyledContainer>
                  </Col>
                </div>
              )}

              {documentDescriptionRequirements && (
                <div
                  key={`${document?.id}_documentDescriptionRequirements`}
                  className="d-flex mb-4"
                >
                  <Col xs={3}>
                    <Label className="m-0 fs-14">
                      {t('order.documentDescriptionRequirements')}
                    </Label>
                  </Col>

                  <Col xs={9}>
                    <StyledContainer className="fs-14">
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {documentDescriptionRequirements}
                      </ReactMarkdown>
                    </StyledContainer>
                  </Col>
                </div>
              )}

              <Col xs={12}>
                <hr className="mb-4 w-100" />
              </Col>
            </Col>
          )}

          {hasVisumIOIntegration && (
            <Col xs={12}>
              <div className="d-flex mb-4">
                <VisumIOFrame
                  document={document}
                  visumIntegrationAccessToken={visumIntegrationAccessToken}
                  applicantIndex={applicantIndex}
                />
              </div>
            </Col>
          )}

          {!hasVisumIOIntegration && document.questionnaire && (
            <Col xs={12}>
              <CardTitle title="Questionnaire" />

              <div className="d-flex mb-4">
                <Col xs={3}>
                  <i className="bx bx-download" />
                  <a
                    style={{ color: 'blue' }}
                    href={`${process.env.REACT_APP_API_URL}${document.questionnaire}`}
                  >
                    {' '}
                    Download{' '}
                  </a>
                </Col>
                <Col xs={9}>
                  You need to send us a questionnaire with more information.
                  Fill in the gaps and upload it for this document.
                </Col>
              </div>
              <div className="w-100 ps-4 pe-4">
                <hr className="m-0" />
              </div>
            </Col>
          )}

          {!hasVisumIOIntegration && document.example && (
            <Col xs={12}>
              <CardTitle title="Example" />

              <StyledScrollableDiv style={{ height: '60vh' }}>
                <Col
                  className="d-flex justify-content-center w-100 mt-2"
                  key={document.example}
                >
                  <Card className="border-0 w-100 d-flex align-items-center">
                    <StyledDocumentImage
                      style={{ width: '60%' }}
                      src={authLink(
                        `${process.env.REACT_APP_API_URL}${document.example}`,
                      )}
                      alt={document.example}
                    />
                  </Card>
                </Col>
              </StyledScrollableDiv>
              <div className="w-100 ps-4 pe-4">
                <hr className="m-0" />
              </div>
            </Col>
          )}
        </Row>
      </ModalBody>

      <ModalFooter className="border-0 py-2 p-4">
        <div>
          <StyledModalCancel onClick={handleCloseModal}>
            {t('common.close')}
          </StyledModalCancel>
        </div>
      </ModalFooter>
    </StyledModal>
  );
};

import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { VisaTypeSelector } from 'components/VisaSelectors/VisaTypeSelector';
import { VisaUrgencySelector } from 'components/VisaSelectors/VisaUrgencySelector';
import { VisaDurationSelector } from 'components/VisaSelectors/VisaDurationSelector';
import { SelectRegion } from 'components/SelectRegion';
import { StyledLabel } from 'components/Common/FormikInput';
import { Select } from 'components/Common';
import { SELECT_CLASSNAME, SERVICES_STATUS } from 'common/data/constants';
import { useParams } from 'react-router-dom';
import {
  INVITATION_SERVICE_VALUE,
  GENERAL_SERVICE_VALUE,
  SERVICE_TYPES,
  DELIVERY_SERVICE_VALUE,
} from 'common/data/services';
import { useFormikContext } from 'formik';
import { isVisaServiceType } from 'helpers/serviceType';
import styled from 'styled-components';
import { ChangeStatus } from 'components/ChangeStatus/ChangeStatus';
import { useTranslation } from 'react-i18next';
import { fetchOrderTypes } from 'store/orderTypes/actions';
import { SelectOrderType } from 'components/SelectOrderType/SelectOrderType';
import { getOrderTypeRequest } from 'helpers/api-requests/admin';

export const Label = styled(StyledLabel)`
  font-weight: 500;
  font-size: 14px;
`;

const getOrderTypesState = state => state.orderTypes.orderTypes.orderTypes;

export const ServiceForm = () => {
  const dispatch = useDispatch();
  const formik = useFormikContext();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { orderTypes } = useSelector(getOrderTypesState);
  const orderTypesConverted =
    orderTypes &&
    orderTypes?.map(item => ({
      value: item.id,
      label: item.translations[i18n.language],
    }));

  useEffect(() => {
    dispatch(fetchOrderTypes());
  }, []);

  const isVisa = useMemo(
    () => isVisaServiceType(formik.values.serviceType),
    [formik.values.serviceType],
  );
  const regex = /^([1-9][0-9]*|0?)$/;

  const [orderType, setOrderType] = useState();
  useEffect(() => {
    const orderTypeId = formik.values.orderType;
    if (orderTypeId) {
      getOrderTypeRequest(orderTypeId)
        .then(response => {
          if (response.success) {
            setOrderType(response.data);
          } else {
            showToastError(response.message);
          }
        })
        .catch(err => console.log(err));
    }
  }, [formik.values.orderType]);

  return (
    <Row>
      <Col xs={12}>
        <FormGroup className="mb-4" row>
          {id ? (
            <>
              <Col xs={12}>
                <Label className="text-muted fw-normal" xs={4} lg={3}>
                  ID
                </Label>

                <Label className="mb-0">{id}</Label>
              </Col>

              <Col xs={12}>
                <Label className="text-muted fw-normal" xs={4} lg={3}>
                  {t('orderTypes.orderType')}
                </Label>

                <Label className="mb-0">
                  {t(
                    orderTypesConverted.find(
                      ({ value }) => value === formik.values.orderType,
                    )?.label,
                  )}
                </Label>
              </Col>

              <Col xs={12}>
                <Label className="text-muted fw-normal" xs={4} lg={3}>
                  {t('services.serviceType')}
                </Label>

                <Label className="mb-0">
                  {t(
                    SERVICE_TYPES.find(
                      ({ value }) => value === formik.values.serviceType,
                    )?.label,
                  )}
                </Label>
              </Col>

              <Col xs={12}>
                <hr className="w-100 mt-4 mb-0" />
              </Col>
            </>
          ) : (
            <>
              <Label invalid={formik.errors.serviceType} xs={4} lg={3}>
                {t('orderTypes.orderType')}
              </Label>

              <Col xs={8} lg={9}>
                <SelectOrderType
                  className="mb-3"
                  // isDisabled={isDisabledField}
                  invalid={formik.errors?.orderType}
                  // className="w-100 p-0"
                  value={formik.values.orderType}
                  // name={'orderType'}
                  onChange={option => {
                    formik.setFieldValue('orderType', option?.value);
                  }}
                />
              </Col>

              <Label invalid={formik.errors.serviceType} xs={4} lg={3}>
                {t('services.serviceType')}
              </Label>

              <Col xs={8} lg={9}>
                <Select
                  placeholder={t('services.serviceTypePlaceholder')}
                  classNamePrefix={SELECT_CLASSNAME}
                  isDisabled={id}
                  options={SERVICE_TYPES}
                  value={SERVICE_TYPES.find(
                    ({ value }) => value === formik.values.serviceType,
                  )}
                  onChange={option =>
                    formik.setFieldValue('serviceType', option.value)
                  }
                  invalid={formik.errors.serviceType}
                />
              </Col>
            </>
          )}
        </FormGroup>
      </Col>

      {formik.values.status && (
        <Col xs={12}>
          <FormGroup className="mb-4" row>
            <Label xs={4} lg={3}>
              {t('common.status')}
            </Label>

            <Col xs={8} lg={9} className="d-flex align-items-center">
              <ChangeStatus
                statuses={SERVICES_STATUS}
                value={formik.values.status}
                onChange={status =>
                  formik.setFieldValue('status', status.value)
                }
              />
            </Col>
          </FormGroup>
        </Col>
      )}

      {(isVisa ||
        (orderType?.country_is_required &&
          formik.values.serviceType === GENERAL_SERVICE_VALUE) ||
        (orderType?.country_is_required &&
          formik.values.serviceType === INVITATION_SERVICE_VALUE)) && (
        <Col xs={12}>
          <FormGroup className="mb-4" row>
            <Label xs={4} lg={3}>
              {t('common.country')}
            </Label>

            <Col xs={4} lg={5}>
              <SelectCountry
                isAvailableOrder
                placeholder={
                  formik.values.serviceType === GENERAL_SERVICE_VALUE
                    ? t('common.all')
                    : t('common.countryPlaceholder')
                }
                invalid={formik.errors.country}
                value={formik.values.country}
                onChange={val => {
                  formik.setFieldValue('visaUrgency', null);
                  formik.setFieldValue('visaDuration', null);
                  formik.setFieldValue('visaType', null);
                  formik.setFieldValue('country', val?.value ?? '');
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      )}

      {orderType?.country_is_required &&
        formik.values.serviceType === GENERAL_SERVICE_VALUE &&
        !formik.values.exceptNationalities?.length && (
          <Col xs={12}>
            <FormGroup className="mb-4" row>
              <Label xs={4} lg={3}>
                {t('services.customerNationality')}
              </Label>

              <Col xs={4} lg={5}>
                <SelectCountry
                  placeholder={
                    formik.values.serviceType === GENERAL_SERVICE_VALUE
                      ? t('common.all')
                      : t('common.customerNationalityPlaceholder')
                  }
                  value={formik.values.nationality ?? ''}
                  onChange={val => {
                    formik.setFieldValue('nationality', val?.value);
                    formik.setFieldValue('exceptNationality', null);
                  }}
                />
              </Col>
            </FormGroup>
          </Col>
        )}

      {orderType?.country_is_required &&
        formik.values.serviceType === GENERAL_SERVICE_VALUE &&
        !formik.values.nationality && (
          <Col xs={12}>
            <FormGroup className="mb-4" row>
              <Label xs={4} lg={3}>
                {t('services.exceptNationalities')}
              </Label>

              <Col xs={4} lg={5}>
                <SelectCountry
                  isMulti
                  placeholder={t('services.exceptNationalitiesPlaceholder')}
                  value={formik.values.exceptNationalities?.map(country => ({
                    value: country.id,
                    label: country.country?.name,
                    shortLabel: country.country.code,
                  }))}
                  onChange={countries => {
                    formik.setFieldValue(
                      'exceptNationalities',
                      countries?.map(country => ({
                        id: country.value,
                        country: {
                          code: country.shortLabel,
                          name: country.label,
                        },
                      })),
                    );
                    formik.setFieldValue('nationality', null);
                  }}
                />
              </Col>
            </FormGroup>
          </Col>
        )}

      {(isVisa || formik.values.serviceType === INVITATION_SERVICE_VALUE) && (
        <>
          <Col xs={12}>
            <FormGroup className="mb-4" row>
              <Label xs={4} lg={3}>
                {t('services.region')}
              </Label>

              <Col xs={4} lg={5}>
                <SelectRegion
                  isClearable
                  // placeholder={t('services.regionPlaceholder')}
                  placeholder={t('common.all')}
                  value={formik.values.nationalityRegion}
                  onChange={val =>
                    formik.setFieldValue('nationalityRegion', val?.value)
                  }
                />
              </Col>
            </FormGroup>
          </Col>

          <Col xs={12}>
            <FormGroup className="mb-4" row>
              <Label xs={4} lg={3}>
                {t('services.visaType')}
              </Label>

              <Col xs={4} lg={5}>
                <VisaTypeSelector
                  country={formik.values.country}
                  isDisabled={!formik.values.country}
                  error={formik.errors.visaType}
                  value={formik.values.visaType}
                  onChange={val => {
                    if (!val) {
                      formik.setFieldValue('visaUrgency', null);
                      formik.setFieldValue('visaDuration', null);
                    }
                    formik.setFieldValue('visaType', val?.value);
                  }}
                />
              </Col>
            </FormGroup>
          </Col>

          {/* {formik.values.serviceType !== INVITATION_SERVICE_VALUE && (
            <>
              <Col xs={12}>
                <FormGroup className="mb-4" row>
                  <Label xs={4} lg={3}>
                    {t('order.visaProcessing')}
                  </Label>

                  <Col xs={4} lg={5}>
                    <VisaUrgencySelector
                      visaType={formik.values.visaType}
                      value={formik.values.visaUrgency}
                      onChange={val =>
                        formik.setFieldValue('visaUrgency', val?.value)
                      }
                      error={formik.errors.visaUrgency}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </>
          )} */}

          <Col xs={12}>
            <FormGroup className="mb-4" row>
              <Label xs={4} lg={3}>
                {t('services.visaDuration')}
              </Label>

              <Col xs={4} lg={5}>
                <VisaDurationSelector
                  visaType={formik.values.visaType}
                  value={formik.values.visaDuration}
                  error={formik.errors.visaDuration}
                  onChange={val =>
                    formik.setFieldValue('visaDuration', val?.value)
                  }
                />
              </Col>
            </FormGroup>
          </Col>
        </>
      )}

      {formik.values.serviceType !== INVITATION_SERVICE_VALUE && (
        <>
          <Col xs={12}>
            <FormGroup className="mb-4" row>
              <Label xs={4} lg={3}>
                {t('order.visaProcessing')}
              </Label>

              <Col xs={4} lg={5}>
                <VisaUrgencySelector
                  // visaType={formik.values.visaType}
                  value={formik.values.visaUrgency}
                  onChange={val => {
                    formik.setFieldValue('visaUrgency', val?.value);
                  }}
                  error={formik.errors.visaUrgency}
                />
              </Col>
            </FormGroup>
          </Col>
        </>
      )}

      {formik.values.serviceType !== INVITATION_SERVICE_VALUE && (
        <Col xs={12}>
          <FormGroup inline className="mb-3 d-flex">
            <div className="d-flex align-items-center">
              <Input
                className="m-0"
                type="checkbox"
                name="taxEnabled"
                checked={formik.values.taxEnabled}
                value={formik.values.taxEnabled}
                onChange={formik.handleChange}
              />

              <Label className="m-0 ms-2">{t('services.enableVAT')}</Label>
            </div>
          </FormGroup>
        </Col>
      )}

      <Col xs={12}>
        <FormGroup inline className="mb-3 d-flex">
          <div className="d-flex align-items-center">
            <Input
              className="m-0"
              type="checkbox"
              name="isAdministrative"
              checked={formik.values.isAdministrative}
              value={formik.values.isAdministrative}
              onChange={formik.handleChange}
            />

            <Label className="m-0 ms-2">{t('services.isAdministrative')}</Label>
          </div>
        </FormGroup>
      </Col>

      {formik.values.serviceType !== DELIVERY_SERVICE_VALUE && (
        <Col xs={12}>
          <FormGroup inline className="mb-4 row">
            <Label xs={4} lg={3}>
              {t('services.processing')}
            </Label>
            <Col xs={4} lg={5}>
              <Input
                className="m-0"
                type="number"
                min="0"
                name="processing"
                checked={formik.values.processing}
                value={formik.values.processing}
                onChange={formik.handleChange}
                onKeyPress={e => {
                  e.preventDefault();
                  if (!regex.test(e.target.value + e.key)) {
                    return;
                  }
                  formik.setFieldValue('processing', e.target.value + e.key);
                }}
              />
            </Col>
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'store/actions';

export const ViewCountry = ({ id }) => {
  const countries = useSelector(state => state.references.countries);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!countries || countries.length === 0) {
      dispatch(getCountries());
    }
  }, [id]);

  if (typeof id === 'object') {
    return countries?.find(item => item?.id === id?.id)?.country.name ?? '-';
  }
  return countries?.find(item => item.id === id)?.country.name ?? '-';
};

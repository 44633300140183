import { Pagination, PaginationItem } from 'reactstrap';
import styled, { css } from 'styled-components';

export const StyledPagination = styled(Pagination)`
  ul {
    gap: 8px;
    margin: 0px;
  }

  button {
    padding: 0px;
    width: 32px;
    height: 32px;
    border-radius: ${({ theme }) => theme.radius.s} !important;
  }
`;

export const StyledPaginationItem = styled(PaginationItem)`
  button {
    color: ${({ theme }) => theme.colors.gray600};
    border: 1px solid ${({ theme }) => theme.colors.transparent}!important;
  }

  .page-link:focus {
    box-shadow: none;
  }

  i {
    color: ${({ theme }) => theme.colors.mainText};
  }

  ${({ active, theme }) =>
    active &&
    css`
      button {
        border: 1px solid ${theme.colors.primary}!important;
        background-color: ${theme.colors.primary} !important;
        color: ${theme.colors.white}!important;
      }
    `}
`;

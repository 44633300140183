import React, { useMemo } from 'react';
import { Accordion } from 'components/Common/Accordion';
import { Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ShadowCard } from 'components/Common/ShadowCard';
import { SelectOrderType } from 'components/SelectOrderType/SelectOrderType';
import { DEFAULT_POINTS } from 'helpers/styles/constants';

export const SelectOrderTypeCard = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const isDisabledField = useMemo(() => {
    const baseCond = formik.values?.applicants?.length >= 1;
    return (
      !!formik.values.client.country ||
      !!formik.values.orderDetail.number ||
      !!formik.values.client?.client ||
      baseCond
    );
  }, [
    formik.values.client,
    formik.values.orderDetail,
    formik.values?.applicants?.length,
  ]);

  return (
    <ShadowCard id="client" className="mb-4" bodyStyle={{ padding: '24px' }}>
      <Accordion title={'order.orderType'}>
        <div className="d-flex p-0 mt-4 flex-wrap">
          <Col className="d-flex p-0" {...DEFAULT_POINTS.label}>
            <Label className="display-5 p-2">{t('order.orderType')}</Label>
          </Col>

          <Col {...DEFAULT_POINTS.input} className="d-flex p-0 ">
            <FormGroup check>
              <Label check className="display-5">
                <SelectOrderType
                  isDisabled={isDisabledField}
                  invalid={formik.errors?.orderType}
                  className="w-100 p-0"
                  value={
                    formik.values.orderDetail?.orderType ??
                    formik.values?.orderType
                  }
                  error={formik.errors?.orderType}
                  name={'orderType'}
                  onChange={option => {
                    formik.setFieldValue('orderType', option?.value);
                  }}
                />
              </Label>
            </FormGroup>
          </Col>
        </div>
      </Accordion>
    </ShadowCard>
  );
};

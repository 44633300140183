import { getFirstAvailableRoute } from 'helpers/jwt_helper';
import { showToastError } from 'helpers/utils/toast';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const PaymentsAbort = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  useLayoutEffect(() => {
    push(getFirstAvailableRoute());
    showToastError(t('payments.abort'));
  }, []);

  return null;
};

export default PaymentsAbort;

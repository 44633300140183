import { takeEvery, put, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, VERIFY_TOKEN } from './actionTypes';
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  verifyResult,
} from './actions';

//Include Both Helper File with needed methods
import {
  postJwtLogin,
  postJwtVerify,
} from '../../../helpers/api-requests/auth';
import { getFirstAvailableRoute } from '../../../helpers/jwt_helper';
import { showToastError } from 'helpers/utils/toast';
import i18n from 'i18n';

function* loginUser({ payload }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtLogin, {
        username: payload.user.user.username,
        password: payload.user.user.password,
      });

      if (response.success) {
        localStorage.setItem('authUser', JSON.stringify(response.data));
        yield put(loginSuccess(response));
        window.location.href = getFirstAvailableRoute();
      } else {
        yield put(apiError(''));
        showToastError(response.message);
      }
    }
  } catch (error) {
    yield put(apiError(error));
    showToastError(i18n.t('notifications.serverNotAvailable'));
  }
}

function* verifyToken({ payload: { history } }) {
  try {
    const authUser = localStorage.getItem('authUser');
    const { access } = authUser ? JSON.parse(authUser) : {};
    const responseVerify = yield call(postJwtVerify, { token: access });
    if (responseVerify.success) {
      yield put(verifyResult(true));
    } else {
      yield put(verifyResult(false));
      yield put(logoutUserSuccess());
      history.push('/login');
    }
  } catch (error) {
    yield put(verifyResult(false));
    yield put(apiError(error));
    showToastError(i18n.t('notifications.serverNotAvailable'));
    history.push('/login');
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser');
    yield put(logoutUserSuccess());
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(VERIFY_TOKEN, verifyToken);
}

export default authSaga;

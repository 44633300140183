import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { closeOrderAlert } from 'store/order/actions';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  border-radius: 10px;
`;

export const AlertOrderForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { alertMessage, isOpen, type } = useSelector(
    state => state.order.order.alert,
  );
  const [alert, setAlert] = useState({ message: '' });
  const [show, setShow] = useState(false);

  const handleCloseAlert = () => {
    dispatch(closeOrderAlert());
    setShow(false);
  };

  useEffect(() => {
    if (isOpen) {
      setAlert({ message: alertMessage });
      setShow(true);
      setTimeout(() => {
        handleCloseAlert();
      }, 3000);
    }
  }, [isOpen]);

  return (
    <StyledAlert
      className="w-100 mt-4"
      color={type}
      isOpen={show}
      toggle={handleCloseAlert}
    >
      {t(alert.message)}
    </StyledAlert>
  );
};

export const NON_AUTH_PADDING = { padding: 40 };

export const DEFAULT_LABEL_SIZES = { xs: 6, sm: 3, lg: 2 };

export const DEFAULT_INPUT_SIZES = { xs: 12, sm: 9, lg: 10 };

export const DEFAULT_POINTS = {
  input: DEFAULT_INPUT_SIZES,
  label: DEFAULT_LABEL_SIZES,
};

import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAIL,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_FAIL,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAIL,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_FAIL,
  GET_SERVICES_FAIL,
  GET_SERVICES_SUCCESS,
  UPDATE_SERVICES_SUCCESS,
  UPDATE_SERVICES_FAIL,
  CREATE_SERVICES_FAIL,
  CREATE_SERVICES_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_ORDERS_FEED_SUCCESS,
  GET_ORDERS_FEED_FAIL,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAIL,
  OPERATOR_GET_DELIVERY_METHODS,
  OPERATOR_GET_DELIVERY_METHODS_SUCCESS,
  OPERATOR_GET_DELIVERY_METHODS_FAIL,
  GET_FILTERED_SERVICES,
  GET_FILTERED_SERVICES_SUCCESS,
  GET_FILTERED_SERVICES_FAIL,
  GET_ORDERS_FEED,
  GET_SERVICES,
  GET_ORDERS,
  GET_LOGS,
  GET_USER,
  GET_OPERATOR_APPLICANTS_USER_LIST_FAIL,
  GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_FAIL,
  GET_OPERATOR_APPLICANTS_USER_LIST_SUCCESS,
  GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_SUCCESS,
  GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST,
  GET_OPERATOR_APPLICANTS_USER_LIST,
  CREATE_USER,
  UPDATE_USER,
} from '../actionTypes';
import { combineReducers } from 'redux';
import { delivery } from './delivery';
import { inviteUser, operatorDeleteService } from '../actions';

const INIT_STATE = {
  users: {
    users: [],
    count: null,
    prev: null,
    next: null,
  },
  user: {},
  error: {},
  services: {
    services: [],
    count: null,
    prev: null,
    next: null,
    loading: false,
  },
  service: {},
  filteredServices: {
    loading: true,
    services: [],
  },
  orders: {
    orders: [],
    requestCount: 0,
    count: null,
    prev: null,
    next: null,
    isLoading: false,
  },
  doc: {},
  order: {},
  logs: {
    logs: [],
    count: null,
    prev: null,
    next: null,
    isLoading: false,
  },
  applicants: {
    users: null,
    organizations: null,
    isLoading: false,
  },
};

const rest = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: { isLoading: true },
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: { ...action.payload, isLoading: false },
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        users: { ...state.users, isLoading: false },
        error: action.payload,
      };

    case GET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          currentUser: {},
          isLoading: true,
        },
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        user: { currentUser: action.payload.user, isLoading: false },
      };

    case GET_USER_FAIL:
      return {
        ...state,
        user: { currentUser: {}, isLoading: false },
        error: action.payload,
      };

    case CREATE_USER:
      return {
        ...state,
        user: { ...state.user, isSubmitting: true },
      };

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        userCreated: action.payload,
        user: { ...state.user, isSubmitting: false },
      };

    case CREATE_USER_FAIL:
      return {
        ...state,
        userCreated: action.payload,
        user: { ...state.user, isSubmitting: false },
      };

    case GET_SERVICE_SUCCESS:
      return {
        ...state,
        service: action.payload,
      };

    case GET_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, isSubmitting: true },
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: { currentUser: action.payload.data, isSubmitting: false },
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        user: { ...state.user, isSubmitting: false },
        error: action.payload,
      };

    case DELETE_USER:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: {
          ...state.users.users,
          users: state.users.users.filter(user => user.id !== action.payload),
        },
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map(user => {
            if (user.id === action.payload.user.id) {
              return action.payload.user;
            }

            return user;
          }),
        },
        user: { currentUser: action.payload.user },
      };
    case BLOCK_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map(user => {
            if (user.id === action.payload.user.id) {
              return action.payload.user;
            }

            return user;
          }),
        },
        user: { currentUser: action.payload.user },
      };
    case UNBLOCK_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case operatorDeleteService:
      return {
        ...state,
      };
    case operatorDeleteService.failure.toString():
      return {
        ...state,
        error: action.payload,
      };
    case operatorDeleteService.success.toString():
      return {
        ...state,
        services: {
          ...state.services,
          services: state.services.services.filter(
            service => service.id !== action.payload,
          ),
        },
      };

    case inviteUser.toString():
      return {
        ...state,
      };
    case inviteUser.failure.toString():
      return {
        ...state,
        error: action.payload,
      };
    case inviteUser.success.toString():
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map(user => {
            if (user.id === action.payload) {
              return { ...user, isInvited: true };
            }

            return user;
          }),
        },
        user: { currentUser: { ...state.user.currentUser, isInvited: true } },
      };
    case CREATE_SERVICES_SUCCESS:
      return {
        ...state,
        serviceCreated: action.payload,
      };

    case CREATE_SERVICES_FAIL:
      return {
        ...state,
        serviceCreated: action.payload,
      };

    case UPDATE_SERVICES_SUCCESS:
      return {
        ...state,
        service: { ...state.service, ...action.payload },
      };

    case UPDATE_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      };

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LOGS:
      return {
        ...state,
        logs: { logs: [], count: null, isLoading: true },
      };
    case GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: { ...action.payload, isLoading: false },
      };

    case GET_LOGS_FAIL:
      return {
        ...state,
        logs: { ...state.logs, isLoading: false },
        error: action.payload,
      };

    case GET_SERVICES:
      return {
        ...state,
        services: { ...state.services, loading: true },
      };

    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: { ...action.payload, loading: false },
      };

    case GET_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_FILTERED_SERVICES:
      return {
        ...state,
        filteredServices: {
          ...state.filteredServices,
          services: [],
          loading: true,
        },
      };

    case GET_FILTERED_SERVICES_SUCCESS:
      return {
        ...state,
        filteredServices: {
          ...state.filteredServices,
          services: action.payload.results,
          loading: false,
        },
      };

    case GET_FILTERED_SERVICES_FAIL:
      return {
        ...state,
        filteredServices: {
          ...state.filteredServices,
          loading: false,
        },
      };

    case GET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          requestCount: state.orders.requestCount + 1,
          isLoading: true,
        },
      };

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: (() => {
          if (action.payload.requestCount === state.orders.requestCount) {
            return {
              ...action.payload,
              isLoading: false,
            };
          }

          return { ...state.orders };
        })(),
      };

    case GET_ORDERS_FAIL:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
        error: action.payload,
      };

    case GET_ORDERS_FEED:
      return {
        ...state,
        events: { ...state.events, loading: true },
      };

    case GET_ORDERS_FEED_SUCCESS:
      return {
        ...state,
        events: { ...action.payload, loading: false },
      };

    case GET_ORDERS_FEED_FAIL:
      return {
        ...state,
        events: action.payload,
      };

    case UPLOAD_DOCUMENT_SUCCESS:
    case UPLOAD_DOCUMENT_FAIL:
      return {
        ...state,
        doc: action.payload,
      };

    case GET_OPERATOR_APPLICANTS_USER_LIST:
    case GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          isLoading: true,
        },
      };

    case GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          organizations: action.payload?.results,
          isLoading: false,
        },
      };

    case GET_OPERATOR_APPLICANTS_USER_LIST_SUCCESS:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          users: action.payload?.results,
          isLoading: false,
        },
      };

    case GET_OPERATOR_APPLICANTS_USER_LIST_FAIL:
    case GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_FAIL:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          isLoading: false,
        },
      };

    case OPERATOR_GET_DELIVERY_METHODS:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          loading: true,
          error: undefined,
        },
      };
    case OPERATOR_GET_DELIVERY_METHODS_SUCCESS:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };
    case OPERATOR_GET_DELIVERY_METHODS_FAIL:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default combineReducers({ rest, delivery });

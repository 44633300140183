import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BlueLink,
  CardTitle,
  Checkbox,
  Loader,
  PaginationLm,
  ShadowCard,
} from 'components/Common';
import { CHECKBOX_STATES } from 'components/Order/Tabs/ApplicantServices';
import { StyledTable } from 'components/Table/StyledTable';
import { formatCurrency } from 'helpers/formatCurrency';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Input, Row } from 'reactstrap';
import { deleteInvoiceFromAggregate, getCandidateInvoice } from 'store/actions';
import { useParams } from 'react-router-dom';
import { InvoicesTotalPrice } from './InvoicesTotalPrice';
import { useTable } from 'hooks/useTable';
import { convertDateToAPI } from 'helpers/converters';
import { DATE_FORMAT_WITH_SYMBOL, INVOICE_STATUS } from 'common/data/constants';
import { currentTableData } from 'helpers/currentTableDate';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { formatDate } from 'helpers/formatDate';
import { useTranslation } from 'react-i18next';

export const getInvoiceState = state =>
  state.aggregatedInvoice.aggregatedInvoice.invoicesCandidate;

export const ReportInvoiceCard = ({ isEditMode }) => {
  const formik = useFormikContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, invoices, count } = useSelector(getInvoiceState);
  const [checked, setChecked] = useState(CHECKBOX_STATES.empty);
  const [countriesSelected, setCountriesSelected] = useState([]);

  const { searchState, showPagination, handleChangeSearchParam } = useTable(
    { perPage: { value: 5 } },
    isEditMode ? count : formik.values.invoices.length,
  );

  const isDraftReport = useMemo(
    () =>
      formik.values?.status?.status === t(INVOICE_STATUS.draft).toLowerCase(),
    [formik.values?.status?.status],
  );

  const fetchInvoices = () => {
    let page = searchState.page;

    const countries = formik.values?.countries?.map(country => country?.value);
    if (countries?.sort().toString() !== countriesSelected?.sort().toString()) {
      page = 1;
      setCountriesSelected(countries);
    }

    const countriesReq = countries?.join(',');

    return getCandidateInvoice({
      ordering: 'create_at',
      page: page,
      page_size: searchState.perPage.value,
      client_type: id
        ? formik.values.client.type
        : formik.values.client?.client_type?.client_type,
      client_id: formik.values.client?.id,
      period_from: convertDateToAPI(formik.values.periodStart),
      period_to: convertDateToAPI(formik.values.periodEnd),
      countries: countriesReq,
      id,
    });
  };
  useEffect(() => {
    if ((isDraftReport && isEditMode) || (!id && isEditMode)) {
      dispatch(fetchInvoices());
    }
  }, [
    formik.values.client?.name,
    formik.values.periodStart,
    formik.values.periodEnd,
    formik.values.countries,
    searchState,
    isEditMode,
  ]);

  useEffect(() => {
    handleChangeSearchParam('page', 1);
  }, [
    formik.isSubmitting,
    isEditMode,
    formik.values.periodStart,
    formik.values.periodEnd,
    formik.values.countries,
  ]);

  const idCurrentInvoices = useMemo(
    () => invoices.map(invoice => invoice.id),
    [invoices],
  );

  const currentTableInvoices = useMemo(
    () =>
      formik.values.invoices.filter(stateInvoice =>
        idCurrentInvoices?.includes(stateInvoice.id),
      ),
    [idCurrentInvoices, formik.values.invoices],
  );

  useEffect(() => {
    if (currentTableInvoices.length === invoices.length && count > 0) {
      setChecked(CHECKBOX_STATES.checked);
      return;
    }

    if (currentTableInvoices.length > 0)
      setChecked(CHECKBOX_STATES.indeterminate);

    if (currentTableInvoices.length === 0) setChecked(CHECKBOX_STATES.empty);
  }, [currentTableInvoices, invoices]);

  const handleChange = () => {
    if (checked === CHECKBOX_STATES.checked) {
      formik.setFieldValue(
        'invoices',
        formik.values.invoices.filter(
          stateInvoice => !idCurrentInvoices?.includes(stateInvoice.id),
        ),
      );
    } else if (checked === CHECKBOX_STATES.empty) {
      formik.setFieldValue(
        'invoices',
        Array.from(new Set([...invoices, ...formik.values.invoices])),
      );
    } else if (checked === CHECKBOX_STATES.indeterminate) {
      formik.setFieldValue(
        'invoices',
        formik.values.invoices.filter(
          stateInvoice => !idCurrentInvoices?.includes(stateInvoice.id),
        ),
      );
    }
  };

  const handleToggeCheckbox = invoice => {
    let tempInvoices = [];

    if (formik.values.invoices?.find(product => product.id === invoice?.id)) {
      tempInvoices = formik.values.invoices.filter(
        product => product.id !== invoice.id,
      );
    } else {
      tempInvoices = [...formik.values.invoices, invoice];
    }

    formik.setFieldValue(`invoices`, tempInvoices);
  };
  const isCurrentInvoices = useMemo(
    () =>
      isEditMode
        ? invoices
        : currentTableData(
            formik.values.invoices.sort(
              (prev, next) =>
                (new Date(next.created_at) - new Date(prev.created_at)) * -1,
            ),
            searchState.page,
            searchState.perPage.value,
          ),
    [formik.values.invoices, invoices, id, isEditMode, searchState],
  );

  const handleDeleteInvoice = invoiceId =>
    dispatch(deleteInvoiceFromAggregate({ id, invoiceId }));

  return (
    <ShadowCard className="mt-4" id="invoices" bodyStyle={{ padding: 24 }}>
      <CardTitle title="Invoices" />

      {isLoading && (
        <Row className="d-flex align-items-center justify-content-center">
          <Loader isLoading={isLoading} />
        </Row>
      )}

      {!isLoading && isCurrentInvoices?.length === 0 && (
        <Alert color="info">{t('reports.notFound')}</Alert>
      )}

      {!isLoading && isCurrentInvoices?.length > 0 && (
        <>
          <StyledTable $borderless $headerShort>
            <thead>
              <tr>
                {(!id || isEditMode) && (
                  <th>
                    <Checkbox checked={checked} onChange={handleChange} />
                  </th>
                )}

                <th>{t('reports.waitPaymentAt')}</th>

                <th>{t('orderTypes.orderType')}</th>

                <th>{t('invoices.invoiceNumber')}</th>

                <th>{t('invoices.orderNumber')}</th>

                <th>{t('invoices.customer')}</th>

                <th>{t('common.country')}</th>

                <th>{t('invoices.invoicePrice')}</th>

                <th />
              </tr>
            </thead>

            <tbody>
              {isCurrentInvoices.map(invoice => (
                <tr key={invoice.id}>
                  {(!id || isEditMode) && (
                    <td>
                      <Input
                        checked={
                          formik.values.invoices?.find(
                            invoiceInState => invoiceInState.id === invoice.id,
                          )
                            ? 1
                            : 0
                        }
                        type="checkbox"
                        onChange={() => handleToggeCheckbox(invoice)}
                      />
                    </td>
                  )}

                  <td>
                    {formatDate(
                      invoice.wait_payment_at,
                      DATE_FORMAT_WITH_SYMBOL,
                    )}
                  </td>

                  <td>{invoice.order.order_type}</td>

                  <td>
                    <BlueLink to={`/invoices/${invoice.id}`}>
                      {invoice.number}
                    </BlueLink>
                  </td>

                  <td>
                    <BlueLink to={`/orders/${invoice.order?.id}`}>
                      {invoice.order.number}
                    </BlueLink>
                  </td>

                  <td>{invoice.client.name}</td>

                  <td>{invoice?.country?.country?.name ?? '---'}</td>

                  <td>{formatCurrency(invoice.bill_total_price_euro)}</td>

                  {isDraftReport && !isEditMode && (
                    <td>
                      <div className="d-flex justify-content-end">
                        <StyledEmptyButton
                          onClick={() => handleDeleteInvoice(invoice.id)}
                        >
                          <i className="bx bx-x fs-6 text-danger" />
                        </StyledEmptyButton>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}

      {showPagination && !formik.isSubmitting && (
        <PaginationLm
          count={isEditMode ? count : formik.values.invoices.length}
          active={searchState.page}
          perPage={searchState.perPage.value}
          selectPage={page => handleChangeSearchParam('page', page)}
        />
      )}

      {formik.values.invoices?.length > 0 && <InvoicesTotalPrice />}
    </ShadowCard>
  );
};

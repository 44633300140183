import {
  CHANGE_EMAIL,
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
} from './actionTypes';

export const getProfile = params => ({
  type: GET_PROFILE,
  payload: params,
});

export const getProfileSuccess = chats => ({
  type: GET_PROFILE_SUCCESS,
  payload: chats,
});

export const getProfileFail = error => ({
  type: GET_PROFILE_FAIL,
  payload: error,
});

export const updateProfile = data => ({
  type: UPDATE_PROFILE,
  data,
});

export const updateProfileSuccess = message => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: message,
});

export const updateProfileFail = error => ({
  type: UPDATE_PROFILE_FAIL,
  payload: error,
});

export const changePassword = data => ({
  type: CHANGE_PASSWORD,
  data,
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFail = error => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: error,
});

export const changeEmail = data => ({
  type: CHANGE_EMAIL,
  data,
});

export const changeEmailSuccess = () => ({
  type: CHANGE_EMAIL_SUCCESS,
});

export const changeEmailFail = error => ({
  type: CHANGE_EMAIL_FAIL,
  payload: error,
});

import axios from 'axios';
import i18n from 'i18n';

//apply base url for axios
const API_URL =
  process.env.REACT_APP_API_URL ||
  'https://api.visa-backoffice.test.apps.senaptek.com';

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.request.use(config => {
  config.headers['Accept-Language'] = i18n.language;
  return config;
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(err => err.response);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err => err.response);
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, data, config).then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}

export async function upload(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
    .catch(err => err.response);
}

export async function updateFile(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then(response => response.data)
    .catch(err => err.response);
}

export const fakeSleep = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(1);
    }, 300);
  });

import React, { useState, useEffect } from 'react';
import { CardTitle, ShadowCard } from 'components/Common';
import { StyledTable } from 'components/Table/StyledTable';
import { BlueLink } from 'components/Common';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { Alert, Col, Row } from 'reactstrap';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

export const OrderTypeCountriesCard = ({ orderType }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);

  const resetCountries = () => setCountries(orderType?.countries);

  useEffect(() => {
    resetCountries();
  }, [orderType]);

  const handleSearch = search => {
    resetCountries();

    search
      ? setCountries(
          countries.filter(c => c.name.toLowerCase().includes(search)),
        )
      : setCountries(orderType.countries);
  };

  return (
    <ShadowCard
      id="countriesSettings"
      className="mt-4"
      bodyStyle={{ padding: '24px' }}
    >
      <CardTitle title="orderTypes.countries" />

      <ShadowCard>
        <Row className="align-items-center mb-3">
          <Col xs={12}>
            <SearchInput
              handleChange={debounce(
                event => handleSearch(event.target.value),
                400,
              )}
            />
          </Col>
        </Row>
      </ShadowCard>

      <StyledTable className="mt-0">
        <tbody>
          {countries?.map((country, index) => (
            <tr key={country.id}>
              <td>
                {country.visas ? (
                  <h6 key={index}>
                    {index + 1}. {country.name}
                  </h6>
                ) : (
                  <BlueLink
                    key={index}
                    to={`/orderTypes/${orderType.id}/country/${country.id}/documents`}
                  >
                    {index + 1}. {country.name}
                  </BlueLink>
                )}
                {country.visas &&
                  country.visas.map((visa, v_index) => (
                    <BlueLink
                      key={v_index}
                      to={`/orderTypes/${orderType.id}/country/${country.id}/visa_type/${visa.id}/documents`}
                    >
                      {visa.name} {v_index + 1 !== country.visas.length && ','}
                      &nbsp;
                    </BlueLink>
                  ))}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>

      {!countries?.length && (
        <Alert color="info">{t('auth.userNotFound')}</Alert>
      )}
    </ShadowCard>
  );
};

import { TARIFFS_BREADCRUMBS } from 'common/data/beadcrumbs';
import { CardTitle, StyledButtonGray } from 'components/Common';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';

import { ShadowCard } from 'components/Common/ShadowCard';
import { HeadControlButtons } from 'components/HeadControllButtons/HeadControllButtons';
import { Layout } from 'components/Layout';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import TariffsForm from 'components/Tariffs/TariffForm';
import { useFormik } from 'formik';
import { TARIFF_VALIDATOR } from 'forms/validators';
import { createTariff } from 'helpers/api-requests/admin';
import { showToastError } from 'helpers/utils/toast';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { fetchTariff, updateTariff, deleteTariff } from 'store/tariffs/actions';
import styled from 'styled-components';
import { TariffDisplay } from './TariffDisplay';
import { TariffServiceCard } from './TariffServiceCard';

export const StyledError = styled.span`
  font-size: 10px;
`;

const initialData = {
  name: '',
  description: '',
};

const Tariff = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const { tariff, isLoading, isSubmitting } = useSelector(
    state => state.tariffs.tariffs.tariffDetail,
  );

  const [isOpenDeleteTariff, setIsOpenDeleteTariff] = useState(null);
  const [isOpenEditMode, setIsOpenEditMode] = useState(
    id ? state?.isEdit : true,
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchTariff({ id }));
    }
  }, []);

  const handleSubmitTariff = (values, { resetForm }) => {
    if (id) {
      dispatch(
        updateTariff({
          id,
          tariff: values,
          closeEditMode: () => setIsOpenEditMode(false),
        }),
      );
    } else {
      setIsSubmit(true);
      createTariff(values)
        .then(response => {
          if (response.success) {
            push(`/tariffs/${response.data.id}`, { isAddService: true });
            resetForm({ values: initialData });
            setIsOpenEditMode(false);
          } else {
            showToastError(response.message?.[0]?.name ?? '');
            return;
          }
        })
        .catch(err => {
          showToastError(`Error: ${err}`);
          return;
        })
        .finally(() => setIsSubmit(false));
    }
  };

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: TARIFF_VALIDATOR,
    validateOnChange: false,
    onSubmit: handleSubmitTariff,
  });

  useEffect(() => {
    if (tariff && id) {
      formik.resetForm({ values: tariff });
    }
  }, [tariff]);

  const handleCloseModal = () => {
    setIsOpenDeleteTariff(null);
  };

  const handleDeleteTariff = id => {
    dispatch(deleteTariff({ id }));
    handleCloseModal();
    push('/tariffs');
  };

  const currentTitle = useMemo(
    () => (id ? (isLoading ? '' : tariff?.name) : 'tariffs.create'),
    [id, isLoading, tariff?.name],
  );

  return (
    <Layout
      isScrollableHeader
      title={currentTitle}
      breadcrumbs={TARIFFS_BREADCRUMBS}
      loading={isLoading}
      extraContent={
        id ? (
          <div
            style={{ marginTop: '-20px' }}
            className="d-flex w-100 align-items-center justify-content-end"
          >
            <StyledUpload
              className="d-flex align-items-center ps-3 pe-3"
              onClick={() =>
                setIsOpenDeleteTariff({
                  id: tariff.id,
                  name: tariff.name,
                })
              }
            >
              <i className="bx bx-trash me-2" />
              {t('tariffs.delete')}
            </StyledUpload>
          </div>
        ) : (
          <HeadControlButtons
            title="tariffs.tariff"
            typeCancel="gray"
            path="/tariffs"
            isDisabledButton={!formik.dirty || isSubmitting || isSubmit}
            handleSubmit={formik.handleSubmit}
          />
        )
      }
    >
      <ShadowCard bodyStyle={{ padding: '24px' }}>
        <CardTitle
          extraContent={
            !isOpenEditMode && (
              <div className="w-100 d-flex justify-content-end">
                <StyledButtonGray
                  className="d-flex flex-nowrap px-3"
                  onClick={() => setIsOpenEditMode(true)}
                >
                  <i className="bx bxs-edit-alt" />

                  {t('common.edit')}
                </StyledButtonGray>
              </div>
            )
          }
          title={t('common.information')}
        />

        {isOpenEditMode ? (
          <TariffsForm
            handleCloseEditMode={() => setIsOpenEditMode(false)}
            formik={formik}
          />
        ) : (
          <TariffDisplay description={tariff.description} name={tariff.name} />
        )}
      </ShadowCard>

      {id && <TariffServiceCard tags={tariff?.tags} />}

      {isOpenDeleteTariff && (
        <ModalConfirmDelete
          isOpenModal={isOpenDeleteTariff}
          textHeader="tariffs.delete"
          textBody="tariffs.deleteConfirm"
          handleCloseModal={handleCloseModal}
          handleDelete={() => handleDeleteTariff(isOpenDeleteTariff.id)}
        />
      )}
    </Layout>
  );
};

export default Tariff;

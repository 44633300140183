import { TXT } from 'common/data/constants';
import { StyledDiv } from 'components/HeadControllButtons/HeadControllButtons';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { useFormikContext } from 'formik';
import { downloadDatevFile } from 'helpers/api-requests/admin';
import { downloadDocument } from 'helpers/downloadDocument';
import { showToastError } from 'helpers/utils/toast';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { convertDateToAPI } from 'helpers/converters';
import React, { useState } from 'react';

export const AccountingCodesHeaderControl = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const [isLoadFile, setIsLoadFile] = useState({
    txt: false,
  });

  const formatPeriod = period => {
    return {
      created_at_from: convertDateToAPI(period.periodStart),
      created_at_to: convertDateToAPI(period.periodEnd),
    };
  };

  const getDatevFile = (period, typeFile) => {
    period = formatPeriod(period);

    setIsLoadFile(prev => ({ ...prev, [typeFile]: true }));
    downloadDatevFile(period)
      .then(response => {
        if (response.success) {
          downloadDocument(
            new Blob([response.data.results]),
            response.data.filename,
          );
        } else {
          showToastError(response.message);
        }
        setIsLoadFile(prev => ({ ...prev, [typeFile]: false }));
      })
      .catch(err => showToastError(err));
  };

  return (
    <StyledDiv className="d-flex w-100 justify-content-end align-items-center">
      <StyledUpload
        style={{ padding: '12px' }}
        disabled={!formik.values.periodStart || !formik.values.periodEnd}
        onClick={() => getDatevFile({ ...formik.values }, TXT)}
        className="d-flex align-items-center justify-content-center"
      >
        {isLoadFile.txt ? (
          <Spinner
            className="me-2"
            style={{ padding: '0.75rem' }}
            color="light"
            size="sm"
            type="grow"
          >
            Loading...
          </Spinner>
        ) : (
          <i className="bx bx-download me-2 fs-4" />
        )}
        {t('reports.downloadTxt')}
      </StyledUpload>
    </StyledDiv>
  );
};

import { useFormikContext } from 'formik';
import React from 'react';
import { StyledInput } from 'components/Common/FormikInput';
import { Col, FormGroup } from 'reactstrap';
import { Label } from './ServiceForm';
import { useTranslation } from 'react-i18next';

export const ServiceLanguage = ({ prefix, title }) => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <Col xs={12}>
      <h4 className="fs-6 mb-4 fw-semibold">
        {t(title)} {t('services.language')}
      </h4>

      <FormGroup className="mb-4" row>
        <Label xs={4} lg={3}>
          {t('common.name')}
        </Label>

        <Col xs={8} lg={9}>
          <StyledInput
            placeholder={
              prefix === 'en'
                ? t(`services.englishLangName`)
                : t(`services.germanLangName`)
            }
            name={`name.${prefix}`}
            value={formik.values.name[prefix]}
            onChange={formik.handleChange}
            invalid={formik.errors.name?.[prefix]}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label xs={4} lg={3}>
          {t('common.description')}
        </Label>

        <Col xs={8} lg={9}>
          <StyledInput
            placeholder={
              prefix === 'en'
                ? t(`services.englishLangDiscription`)
                : t(`services.germanLangDiscription`)
            }
            name={`description.${prefix}`}
            invalid={formik.errors.description?.[prefix]}
            type="textarea"
            $noResize
            value={formik.values.description[prefix]}
            onChange={formik.handleChange}
          />
        </Col>
      </FormGroup>
    </Col>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ErrorNotificationImage } from 'assets/images/visa';

const StyledErrorMessage = styled.div`
  margin: 80px 20px 20px;
`;

const StyledAlert = styled(Alert)`
  border: none;
  background-color: ${({ theme }) => theme.colors.red200};
  display: flex;
  align-items: center;
  padding: 12px 24px;
  box-shadow: 0px 8px 20px rgba(255, 0, 0, 0.15);
  border-radius: 8px;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static defaultProps = {
    errorMessage: ErrorBoundary.defaultErrorMessage,
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <StyledErrorMessage>
          <StyledAlert color="danger">
            <ErrorNotificationImage />

            <span className="ms-2">
              {this.props.i18n.t('common.errorTryAgainLater')}
            </span>
          </StyledAlert>
        </StyledErrorMessage>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);

import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CardTitle } from 'components/Common';
import { Col, Label, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { uploadDocument } from 'store/documents/actions';
import { maxUploadSize } from 'helpers/jwt_helper';

const ERROR_TEXT_MAX_SIZE_FILE = `The file must not exceed ${
  maxUploadSize() / (1024 * 1024)
} MB`;

export const StyledDropZone = styled.div`
  border-radius: 8px;
  border: 4px dashed ${({ theme }) => theme.colors.gray200};

  &:hover {
    border-color: ${({ theme }) => theme.colors.mainText};
    transition: 500ms;
  }
`;

export const ACCEPT_FILE_TYPES = [
  '.jpg',
  '.jpeg',
  '.png',
  '.tiff',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.odt',
  '.eml',
  '.msg',
];

export const UpdateDocumentModal = ({
  document,
  handleCloseModal,
  ...props
}) => {
  const DROPZONE_CONFIG = {
    multiple: false,
    noKeyboard: true,
    maxSize: maxUploadSize(),
    accept: ACCEPT_FILE_TYPES,
    onDrop: (_acceptedFiles, fileRejections) => {
      fileRejections.forEach(file => {
        file.errors.forEach(error => {
          if (error.code === 'file-too-large') {
            showToastError(ERROR_TEXT_MAX_SIZE_FILE);
          }
        });
      });
    },
  };

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { getInputProps, acceptedFiles, getRootProps } =
    useDropzone(DROPZONE_CONFIG);

  const handleUploadFile = () => {
    const formData = new FormData();

    formData.append('document', document.id);
    formData.append('file', acceptedFiles.at());

    dispatch(uploadDocument({ id: document.id, formData, handleCloseModal }));
  };

  return (
    <ModalConfirmDelete
      handleCloseModal={handleCloseModal}
      textHeader="documents.updateDocument"
      {...props}
    >
      <StyledDropZone
        className="p-3 d-flex flex-column align-items-center mb-4"
        {...getRootProps()}
      >
        <input {...getInputProps()} multiple={false} />

        <i className="bx bx-plus fs-1" />

        <p className="text-center">{t('documents.placeholder')}</p>
      </StyledDropZone>

      {acceptedFiles?.length > 0 && (
        <>
          <CardTitle title="Current file" />

          <Row className="p-0 mb-4">
            <Col xs={4}>
              <Label className="text-muted fw-normal m-0">
                {t('documents.documentName')}
              </Label>
            </Col>

            <Col xs={6}>
              <Label className="m-0">{acceptedFiles.at().name}</Label>
            </Col>
          </Row>

          <Row className="p-0 mb-4">
            <Col xs={4}>
              <Label className="text-muted fw-normal m-0">
                {t('common.type')}
              </Label>
            </Col>

            <Col xs={6}>
              <Label className="m-0">
                {document.document.name[i18n.language]}
              </Label>
            </Col>
          </Row>

          <hr className="w-100 my-4" />
        </>
      )}

      <StyledUpload
        disabled={acceptedFiles.length === 0}
        onClick={handleUploadFile}
        className="me-3"
      >
        <i className="bx bx-download me-2" />

        {t('order.uploadFile')}
      </StyledUpload>
    </ModalConfirmDelete>
  );
};

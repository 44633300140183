import { CLIENT_TYPES } from 'common/data/constants';
import { NavBar } from 'components/Common/NavBar';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import {
  operatorCreateInvite,
  operatorResetPassword,
} from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import { getClientsState } from 'pages/Clients/Client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useFormikContext } from 'formik';

const clientNav = [
  { title: 'common.information', id: 1, link: 'information', offset: -110 },
  { title: 'common.contacts', id: 2, link: 'contacts' },
  { title: 'common.address', id: 3, link: 'address' },
];

export const ClientNavBar = () => {
  const { client } = useSelector(getClientsState);
  const { client_type, id } = useParams();
  const { t } = useTranslation();
  const formik = useFormikContext();

  const getClientNav = () => {
    if (formik.values.clientType === 'legal') {
      return clientNav.concat({
        title: t('client.options'),
        id: 4,
        link: 'options',
      });
    }
    return clientNav;
  };

  const addInvite = async () => {
    try {
      const response = await operatorCreateInvite(id);

      if (response.success) {
        showToastSuccess(t('client.invited'));
      } else {
        showToastError(response.message);
      }
    } catch (error) {
      showToastError(error);
    }
  };

  const resetPassword = async () => {
    try {
      const response = await operatorResetPassword(id);

      if (response.success) {
        showToastSuccess(response.message);
      } else {
        showToastError(response.message);
      }
    } catch (error) {
      showToastError(t('client.noEmail'));
    }
  };

  return (
    <NavBar
      components={getClientNav()}
      scrollableHeader
      topHeigth="141px !important"
      extraContent={
        client_type === CLIENT_TYPES[0].value && (
          <>
            {!client?.details?.last_login && client?.details?.email && (
              <Button
                color="success"
                onClick={addInvite}
                className="w-100 mb-4"
              >
                {t('client.inviteUser')}
              </Button>
            )}

            {client?.details?.email && (
              <StyledUpload className="w-100" onClick={resetPassword}>
                {t('client.resetPassword')}
              </StyledUpload>
            )}
          </>
        )
      }
    />
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Nav, NavItem, NavLink, Row } from 'reactstrap';
import styled, { css } from 'styled-components';
import { ApplicantMainInfo } from './ApplicantMainInfo';
import { ApplicantServices } from './ApplicantServices';
import { ApplicantDates } from './ApplicantDates';
import { ApplicantDocuments } from './ApplicantDocuments';
import { useFormikContext } from 'formik';
import {
  getApplicantDocumentFilter,
  getVisaTypes,
} from 'helpers/api-requests/admin';
import { useTranslation } from 'react-i18next';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { optionalField } from 'forms/shortcuts';
import { NO } from 'common/data/constants';

export const StyledNavItem = styled(NavItem)`
  a {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray400};

    &:hover {
      color: ${({ theme }) => theme.colors.gray400};
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      a {
        color: ${theme.colors.primary};
        background-color: ${theme.colors.white};

        &:hover {
          color: ${theme.colors.primary};
        }
      }

      border-bottom: 3px solid ${theme.colors.primary}!important;
    `}

  .nav-link.disabled {
    color: #e5e5e5;
  }
  &.is-invalid {
    .nav-link {
      color: ${({ theme }) => theme.colors.danger};
    }
    border-bottom-color: ${({ theme }) => theme.colors.danger} !important;
  }
`;

const StyledNav = styled(Nav)`
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ApplicantBody = ({
  index,
  useFieldPreviousVisa,
  useFieldPreviousUssr,
  orderTypeFieldsSettings,
}) => {
  const { values, errors } = useFormikContext();
  const { t } = useTranslation();
  const [isDisabledDate, setIsDisabledDate] = useState(true);
  const [currentActiveTab, setCurrentActiveTab] = useState('mainInformation');
  const [visaTypes, setVisaTypes] = useState([]);
  const applicantContainer = document.getElementById(`applicant-${index + 1}`);

  const handleChangeTab = tab => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
    }
  };

  useEffect(() => {
    getVisaTypes({
      // valid_countries: values.client.country, // todo: delete for visa type
      country: values.client.country,
      valid_nationalities:
        values?.applicants?.[index].applicantDetail.citizenship,
    }).then(visas => {
      if (visas.success) {
        setVisaTypes(visas.data.results);
      }
    });
  }, [
    values.client.country,
    values?.applicants?.[index].applicantDetail.citizenship,
  ]);

  const currentVisa = useMemo(
    () =>
      visaTypes.find(visa => visa.id === values.applicants?.[index].visaType),
    [visaTypes, values.applicants?.[index].visaType],
  );

  const isDisabledServices = useMemo(
    () =>
      (orderTypeFieldsSettings?.enableVisaType &&
        !values.applicants?.[index]?.visaType) ||
      (orderTypeFieldsSettings?.enableVisaType &&
        !values.applicants?.[index]?.visaUrgency) ||
      (orderTypeFieldsSettings?.enableVisaType &&
        currentVisa?.durations.length > 0 &&
        !values.applicants?.[index]?.visaDuration) ||
      !values.applicants?.[index]?.visaUrgency ||
      (orderTypeFieldsSettings?.enableDeliveryMethod &&
        !values.applicants?.[index]?.deliveryMethod),
    // ||
    // values.applicants?.[index]?.hasResidency === NO
    [
      values.applicants?.[index]?.visaType,
      values.applicants?.[index]?.visaUrgency,
      values.applicants?.[index]?.visaDuration,
      values.applicants?.[index]?.isVisible,
      values.applicants?.[index]?.deliveryMethod,
      values.applicants?.[index]?.hasResidency,
      currentVisa,
    ],
  );

  const isDisabledDocuments = useMemo(
    () =>
      values.applicants?.[index]?.services?.length === 0 ||
      values.applicants?.[index]?.hasResidency === NO,
    [
      values.applicants?.[index]?.services,
      values.applicants?.[index]?.hasResidency,
    ],
  );
  const getDisabledDates = useCallback(() => {
    getApplicantDocumentFilter({
      order_type: values.orderType,
      number: values.orderDetail?.number ?? null,
      client_id: values.client.client,
      client_type: values.client.clientType,
      nationality: values.applicants[index].applicantDetail.citizenship,
      applicant: values.applicants[index].applicantDetail.id,
      country: values.client.country,
      previous_visa: values.applicants[index].previusVisa,
      visa_type: values.applicants[index].visaType,
      visa_duration: optionalField(values.applicants[index].visaDuration),
      products: values.applicants[index].services.map(
        service => service.product_id,
      ),
    })
      .then(response => {
        if (response.success) {
          const required_docs_count = response.data.documents.results.filter(
            doc => doc.options.ALLOW_MULTIPLE === false,
          ).length;

          const uploaded_required_docs_count =
            response.data.documents.results.filter(
              doc =>
                // !doc.options.ALLOW_MULTIPLE && doc.profile_document !== null,
                (!doc.options.ALLOW_MULTIPLE &&
                  doc.options.IS_REUSABLE &&
                  doc.profile_document !== null) ||
                // Notice files that reusable is false do not have profile document.
                // Must compare with uploaded applicant document
                (!doc.options.ALLOW_MULTIPLE &&
                  !doc.options.IS_REUSABLE &&
                  values.applicants?.[index].files.find(
                    applicant_file =>
                      applicant_file.file.document.id === doc.id,
                  )),
            ).length;

          const required_docs_are_uploaded =
            required_docs_count === uploaded_required_docs_count;

          setIsDisabledDate(isDisabledDocuments || !required_docs_are_uploaded);
        }
      })
      .catch(() => {
        setIsDisabledDate(
          isDisabledDocuments || values.applicants?.[index].files.length === 0,
        );
      });
  });

  useEffect(() => {
    getDisabledDates();
  }, [values.applicants?.[index].files, isDisabledDocuments]);

  const isMainError = useMemo(
    () =>
      !!errors.applicants?.[index]?.visaType ||
      !!errors.applicants?.[index]?.deliveryMethod ||
      !!errors.applicants?.[index]?.address ||
      !!errors.applicants?.[index]?.addressAnotherApplicant,
    [
      errors.applicants?.[index]?.visaType,
      errors.applicants?.[index]?.deliveryMethod,
      errors.applicants?.[index]?.address,
      errors.applicants?.[index]?.addressAnotherApplicant,
    ],
  );

  const tabs = useMemo(
    () => ({
      mainInformation: {
        key: 'mainInformation',
        content: (
          <ApplicantMainInfo
            currentVisa={currentVisa}
            index={index}
            visaTypes={visaTypes}
            useFieldPreviousVisa={useFieldPreviousVisa}
            useFieldPreviousUssr={useFieldPreviousUssr}
            orderTypeFieldsSettings={orderTypeFieldsSettings}

            // visa_order_require_residency={values.visa_order_require_residency}
          />
        ),
        title: 'order.mainInformation',
        next: 'services',
        error: isMainError,
      },
      services: {
        key: 'services',
        content: <ApplicantServices index={index} />,
        disabled: isDisabledServices,
        title: 'sideBar.services',
        next: 'documents',
        prev: 'mainInformation',
      },

      documents: {
        key: 'documents',
        content: <ApplicantDocuments index={index} />,
        title: 'common.documents',
        disabled: isDisabledDocuments,
        next: 'dates',
        prev: 'services',
      },
      dates: {
        key: 'dates',
        content: (
          <ApplicantDates
            index={index}
            orderTypeFieldsSettings={orderTypeFieldsSettings}
          />
        ),
        disabled: isDisabledDate,
        title: 'common.datesTab',
        prev: 'documents',
      },
    }),
    [
      index,
      isDisabledServices,
      currentVisa,
      visaTypes,
      isDisabledDocuments,
      isDisabledDate,
      isMainError,
      orderTypeFieldsSettings,
    ],
  );

  const handleSetNextTab = () => {
    applicantContainer?.scrollIntoView(true);
    setCurrentActiveTab(tabs[currentActiveTab].next);
  };

  return (
    <div className="d-flex flex-column">
      <Row className="p-0 m-0">
        <StyledNav className="mt-4 p-0 g-2 d-flex flex-nowrap" pills>
          {Object.values(tabs).map((tab, index) => (
            <StyledNavItem
              className={`p-0 me-3 text-nowrap ${
                tab.error ? 'is-invalid' : ''
              }`}
              active={currentActiveTab === tab.key}
              key={tab.key}
            >
              <NavLink
                className="p-0 text-center"
                tabIndex={0}
                disabled={tab.disabled}
                onClick={() => {
                  if (tab.disabled) {
                    return;
                  }
                  handleChangeTab(tab.key);
                }}
              >
                {`${index + 1}. ${t(tab.title)} `}
              </NavLink>
            </StyledNavItem>
          ))}
        </StyledNav>
      </Row>

      {tabs[currentActiveTab].content}

      {tabs[currentActiveTab]?.next && (
        <div className="d-flex justify-content-end mt-4">
          <StyledEmptyButton
            disabled={tabs[tabs[currentActiveTab].next].disabled}
            onClick={handleSetNextTab}
          >
            {t('common.continue')}
          </StyledEmptyButton>
        </div>
      )}
    </div>
  );
};

import { CardTitle, ShadowCard } from 'components/Common';
import React from 'react';
import { OrderTypeEditApplicant } from './OrderTypeEditApplicant';

export const OrderTypeApplicantCard = () => {
  return (
    <ShadowCard
      id="applicantsSettings"
      className="mt-4"
      bodyStyle={{ padding: '24px' }}
    >
      <CardTitle title="orderTypes.applicants" />

      <OrderTypeEditApplicant />
    </ShadowCard>
  );
};

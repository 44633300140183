import { ShadowCard } from 'components/Common';
import { confirmEmail } from 'helpers/api-requests/auth';
import { useLoader } from 'hooks/useLoader';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { StyledTextHead } from './Login';

export const ConfirmEmail = () => {
  const { token, username } = useParams();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { handleStartLoad, handleEndLoad, Loader } = useLoader();
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleConfirmEmail = () => {
      handleStartLoad();
      confirmEmail(token)
        .then(response => {
          if (response.success) {
            return;
          }

          setError(response.message);
        })
        .catch(err => setError(err))
        .finally(handleEndLoad);
    };

    if (token) {
      handleConfirmEmail();
    }
  }, [token]);

  return (
    <ShadowCard bodyStyle={{ padding: 40 }}>
      {Loader ?? (
        <>
          <StyledTextHead className="mb-4">
            {error ? error : t('auth.emailConfirmSuccess')}
          </StyledTextHead>

          <Button
            color="primary"
            block
            onClick={() => push(`/login/username/${username}`)}
          >
            {t('auth.goToLogin')}
          </Button>
        </>
      )}
    </ShadowCard>
  );
};

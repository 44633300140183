import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_VALIDATED_WIZARD_STEP,
  GET_WIZARD,
  GET_WIZARD_NEXT_STEP,
  GET_WIZARD_STEP,
} from './actionTypes';

import {
  getWizardSuccess,
  getWizardFail,
  getWizardNextStepSuccess,
  getWizardNextStepFail,
  getValidatedWizardStepSuccess,
  getValidatedWizardStepFail,
  getWizardStepSuccess,
  getWizardStepFail,
} from './actions';

import {
  getNextStepWizard,
  getValidateStepWizard,
  getWizard,
  getWizardStep,
} from '../../helpers/api-requests/admin';

function* fetchWizard({ payload }) {
  try {
    const response = yield call(getWizard, payload);
    if (response.success) {
      yield put(getWizardSuccess(response.data));
    } else {
      yield put(getWizardFail(response.data));
    }
  } catch (error) {
    yield put(getWizardFail(error));
  }
}

function* fetchWizardStep({ payload }) {
  try {
    const response = yield call(getWizardStep, payload);
    if (response.success) {
      yield put(getWizardStepSuccess(response.data));
    } else {
      yield put(getWizardStepSuccess(response.data));
    }
  } catch (error) {
    yield put(getWizardStepFail(error));
  }
}

function* fetchWizardNextStep({ payload }) {
  try {
    const response = yield call(getNextStepWizard, payload);
    if (response.success) {
      yield put(getWizardNextStepSuccess(response));
    } else {
      yield put(getWizardNextStepFail(response));
    }
  } catch (error) {
    yield put(getWizardNextStepFail(error));
  }
}

function* fetchValidatedWizardStep({ payload }) {
  try {
    const response = yield call(getValidateStepWizard, payload);
    if (response.success) {
      yield put(getValidatedWizardStepSuccess(response));
    } else {
      yield put(getValidatedWizardStepFail(response));
    }
  } catch (error) {
    yield put(getValidatedWizardStepFail(error));
  }
}

function* wizardSaga() {
  yield takeEvery(GET_WIZARD, fetchWizard);
  yield takeEvery(GET_WIZARD_NEXT_STEP, fetchWizardNextStep);
  yield takeEvery(GET_WIZARD_STEP, fetchWizardStep);
  yield takeEvery(GET_VALIDATED_WIZARD_STEP, fetchValidatedWizardStep);
}

export default wizardSaga;

import { showToastSuccess } from 'helpers/utils/toast';
import qs from 'qs';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

const PaymentsSuccess = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    const currentInvoice = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).invoice;

    push(`/invoices/${currentInvoice}`);
    showToastSuccess(t('payments.success'));
  }, []);

  return null;
};

export default PaymentsSuccess;

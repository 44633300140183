import classNames from 'classnames';
import { FIRST_PAGE } from 'common/data/constants';
import { Loader, PaginationLm } from 'components/Common';
import { Checkbox } from 'components/Common/Checkbox';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { StyledTable } from 'components/Table/StyledTable';
import { useFormikContext } from 'formik';
import { getFilteredServices, getTags } from 'helpers/api-requests/admin';
import { currentTableData } from 'helpers/currentTableDate';
import { isUserOperator, userCanViewPrice } from 'helpers/jwt_helper';
import { showToastError } from 'helpers/utils/toast';
import uniq from 'lodash/uniq';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert, FormGroup } from 'reactstrap';
import { ApplicantServicesRow } from './ApplicantServicesRow';

export const CHECKBOX_STATES = {
  checked: 'Checked', // All selected
  indeterminate: 'Indeterminate', // Some selected
  empty: 'Empty', // None selected
};

const ALL_TAG = { name: 'common.all', id: -1 };

export const ApplicantServices = ({ index }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { clients } = useSelector(state => state.clients);
  const formik = useFormikContext();

  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [checked, setChecked] = useState(CHECKBOX_STATES.empty);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState(ALL_TAG.name);

  const clientValues = formik.values.client;

  const orderTypeId = formik.values.orderType;

  const currentClientTariff = useMemo(() => {
    if (clientValues.client && clients.results) {
      return clients.results.find(client => client.id === clientValues.client)
        ?.tariff;
    }
  }, [clientValues.client, clients]);

  const hidePrice = !userCanViewPrice();

  const isDisabledFields = !formik.values.orderDetail?.isEditable;

  useEffect(() => {
    if (!currentClientTariff?.id || !isUserOperator) {
      return;
    }

    getTags({ tariff: currentClientTariff.id }).then(response => {
      if (response.success) {
        const preparedTags = Array.from(new Set(response.data.results));

        setTags(preparedTags);
      }
    });
  }, [currentClientTariff?.id]);

  // fetch services from filter

  useEffect(() => {
    setLoading(true);
    getFilteredServices({
      order_type: orderTypeId,
      client: isUserOperator
        ? {
            type: formik.values.client.clientType,
            id: formik.values.client.client,
          }
        : undefined,
      order_applicant: formik.values.applicants?.[index]?.customerId,
      country: formik.values.client?.country,
      nationality:
        formik.values.applicants?.[index].applicantDetail?.citizenship,
      delivery_method: formik.values.applicants?.[index].deliveryMethod,
      visa_type: formik.values.applicants?.[index].visaType ?? undefined,
      visa_duration:
        formik.values.applicants?.[index].visaDuration ?? undefined,
      visa_urgency: formik.values.applicants?.[index].visaUrgency ?? undefined,
    })
      .then(response => {
        if (response.success) {
          setServices(response.data.results);
          return;
        }

        showToastError(response.message);
      })
      .catch(err => showToastError(err))
      .finally(() => setLoading(false));
  }, [
    formik.values.client,
    formik.values.applicants?.[index].applicantDetail?.citizenship,
    formik.values.applicants?.[index].visaType,
    formik.values.applicants?.[index].visaDuration,
    formik.values.applicants?.[index].visaUrgency,
    formik.values.applicants?.[index].deliveryMethod,
  ]);

  useEffect(() => {
    if (
      formik.values.applicants?.[index].services?.length === services.length &&
      services.length > 0
    ) {
      setChecked(CHECKBOX_STATES.checked);
      return;
    }

    if (formik.values.applicants?.[index].services?.length > 0)
      setChecked(CHECKBOX_STATES.indeterminate);

    if (formik.values.applicants?.[index].services?.length === 0)
      setChecked(CHECKBOX_STATES.empty);
  }, [formik.values.applicants?.[index].services]);

  const handleChange = () => {
    formik.values.applicants?.[index].statusApplicant === 'draft' &&
      formik.setFieldValue(`applicants[${index}].firstDateEntry`, null);

    formik.values.applicants?.[index].statusApplicant === 'draft' &&
      formik.setFieldValue(
        `applicants[${index}].documentsToApplicantEstimatedAt`,
        null,
      );
    //Depending on the state of the checkbox, services get tired
    if (checked === CHECKBOX_STATES.checked) {
      //If the current state of the checkbox is checked, delete all services
      formik.setFieldValue(`applicants.[${index}].services`, []);
      formik.setFieldValue(`applicants[${index}].unCheckedServices`, [
        ...(formik.values.applicants?.[index].unCheckedServices ?? []),
        ...formik.values.applicants?.[index].services.map(
          service => service.id,
        ),
      ]);
    } else if (checked === CHECKBOX_STATES.empty) {
      //If the current state of the checkbox is empty, all objects are added
      activeTag === ALL_TAG.name
        ? formik.setFieldValue(`applicants.[${index}].services`, services)
        : formik.setFieldValue(
            `applicants.[${index}].services`,
            services.filter(service =>
              service.tags?.some(tag => tag === activeTag),
            ),
          );
    } else if (checked === CHECKBOX_STATES.indeterminate) {
      //If the current state of the checkbox is Indeterminate, all services are deleted
      formik.setFieldValue(`applicants.[${index}].services`, []);
      formik.setFieldValue(`applicants[${index}].unCheckedServices`, [
        ...(formik.values.applicants?.[index].unCheckedServices ?? []),
        ...formik.values.applicants?.[index].services.map(
          service => service.id,
        ),
      ]);
    }
  };

  const defaultChecked = services => {
    const formikServiceId = formik.values.applicants?.[index].services.map(
      product => product.id,
    );
    const unCheckedServices =
      formik.values.applicants?.[index].unCheckedServices;

    const autoSelected = services
      .filter(
        service => service.auto_select && !formikServiceId.includes(service.id),
      )
      .filter(service =>
        unCheckedServices ? !unCheckedServices.includes(service.id) : true,
      );

    const result = [
      ...formik.values.applicants?.[index].services,
      ...autoSelected,
    ];

    formik.setFieldValue(`applicants.[${index}].services`, result);
  };

  useEffect(() => {
    if (services && !isDisabledFields) {
      defaultChecked(services);
    }
  }, [services]);

  const filteredServices = useMemo(() => {
    setCurrentPage(1);
    let l1 = services.filter(service =>
      service.name[language].toLowerCase().includes(searchQuery.toLowerCase()),
    );

    const l2 = formik.values.applicants?.[index].services.filter(
      c_service =>
        !l1
          .map(key => {
            return key.id;
          })
          .includes(c_service.id),
    );

    const initialServices =
      l2.length > 0
        ? [
            ...l1,
            // l2 is commented since only searched for services should be visible
            // ...l2
          ]
        : l1;

    return activeTag === ALL_TAG.name
      ? initialServices
      : initialServices.filter(service => {
          return service.tags?.some(tag => tag === activeTag);
        });
  }, [searchQuery, services, activeTag]);

  const sortedServices = useCallback(
    services =>
      services.sort(
        (a, b) =>
          Boolean(
            formik.values.applicants?.[index].services.find(
              service => service.id === b.id,
            ),
          ) -
          Boolean(
            formik.values.applicants?.[index].services.find(
              service => service.id === a.id,
            ),
          ),
      ),
    [formik.values.applicants?.[index].services, filteredServices],
  );

  const showPagination = 10 < filteredServices.length;
  const currentServices = currentTableData(
    sortedServices(filteredServices),
    currentPage,
    10,
  );

  const tagsFromServices = useMemo(() => {
    const servicesTags = services?.reduce(
      (acc, service) => (service.tags ? [...acc, ...service.tags] : acc),
      [],
    );

    return uniq(servicesTags);
  }, [services]);

  const mergedTags = useMemo(() => {
    const filteredTags = tags.filter(
      tag => !!tagsFromServices.includes(tag.name),
    );

    return [ALL_TAG.name, ...filteredTags.map(tag => tag.name)];
  }, [tags, tagsFromServices]);

  const handleChangeTag = tag => () => {
    setCurrentPage(FIRST_PAGE);
    setActiveTag(tag);
  };

  return (
    <div className="mt-4">
      <FormGroup row>
        <SearchInput
          value={searchQuery}
          handleChange={event => setSearchQuery(event.target.value)}
          className="w-50"
        />
      </FormGroup>

      {isUserOperator && (
        <div className="d-flex align-items-center overflow-auto">
          {mergedTags.map(tag => (
            <button
              role="button"
              onClick={handleChangeTag(tag)}
              key={tag}
              className={classNames(
                'ms-2',
                activeTag === tag &&
                  'font-weight-bold text-primary py-1 px-4 border-0',
              )}
            >
              {t(tag)}
            </button>
          ))}
        </div>
      )}

      {loading && (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Loader isLoading={loading} />
        </div>
      )}

      {!loading && filteredServices.length === 0 && (
        <Alert className="mt-3" color="info">
          {t('Services not found')}
        </Alert>
      )}

      {!loading && filteredServices.length > 0 && (
        <>
          <StyledTable className="mt-3" responsive>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    disabled={
                      (!isUserOperator &&
                        formik.values.applicants?.[index].services?.some(
                          service => service.is_visa_type,
                        )) ||
                      isDisabledFields
                    }
                    checked={checked}
                    onChange={handleChange}
                  />
                </th>

                <th>{t('common.service')}</th>

                <th>{t('common.type')}</th>

                {!hidePrice && (
                  <>
                    <th>{t('common.price')}</th>

                    <th>{t('common.tax')}</th>

                    <th>{t('common.total')}</th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {currentServices.map(service => (
                <ApplicantServicesRow
                  service={service}
                  index={index}
                  key={service.id}
                  isDisabledFields={isDisabledFields}
                />
              ))}
            </tbody>
          </StyledTable>

          {showPagination && (
            <PaginationLm
              count={filteredServices.length}
              perPage={10}
              active={currentPage}
              selectPage={setCurrentPage}
            />
          )}
        </>
      )}
    </div>
  );
};

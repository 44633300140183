export const defaultTheme = {
  colors: {
    white: '#fff',
    transparent: '#0000',
    gray: '#74788d',
    grayDark: '#343a40',
    primary: '#556ee6',
    secondary: '#74788d',
    success: '#34c38f',
    info: '#50a5f1',
    warning: '#f1b44c',
    danger: '#F73C14',
    light: '#eff2f7',
    dark: '#343a40',
    sideBarText: '#a6b0cf',
    sideBarImage: '#6a7187',
    sideBarBg: '#2a3042',
    mainText: '#1b1a1f',
    placeholders: '#171a24',
    gray100: '#EFF2F7',
    gray200: '#DEE0E7',
    gray400: '#9397a4',
    gray600: '#6A7187',
    gray800: '#495057',
    green500: '#34C38F',
    blue100: '#E7EBFF',
    blue200: '#A6B0CF',
    green100: '#E8FFF7',
    red500: '#F73C14',
    red200: '#FFECE8',
  },
  spacing: {
    xs: '6px',
    s: '8px',
    sm: '12px',
    m: '16px',
    sl: '24px',
    l: '32px',
    xl: '64px',
  },
  //border-radius
  radius: {
    xs: '6px',
    s: '8px',
    m: '16px',
    l: '32px',
    xl: '64px',
  },
};

import {
  GET_WIZARD,
  GET_WIZARD_FAIL,
  GET_WIZARD_SUCCESS,
  GET_WIZARD_NEXT_STEP,
  GET_WIZARD_NEXT_STEP_FAIL,
  GET_WIZARD_NEXT_STEP_SUCCESS,
  SET_WIZARD_FORM_FIELD,
  SET_WIZARD_META,
  SET_WIZARD_FILE_FIELD,
  SET_WIZARD_HELPER_FIELD,
  SET_WIZARD_FIELDS_BY_RULES,
  GET_VALIDATED_WIZARD_STEP,
  GET_VALIDATED_WIZARD_STEP_SUCCESS,
  GET_VALIDATED_WIZARD_STEP_FAIL,
  SET_WIZARD_INITIAL,
  GET_WIZARD_STEP,
  GET_WIZARD_STEP_SUCCESS,
  GET_WIZARD_STEP_FAIL,
} from './actionTypes';

export const getWizard = query => ({
  type: GET_WIZARD,
  payload: query,
});

export const getWizardSuccess = data => ({
  type: GET_WIZARD_SUCCESS,
  payload: data,
});

export const getWizardFail = error => ({
  type: GET_WIZARD_FAIL,
  payload: error,
});

export const getWizardStep = query => ({
  type: GET_WIZARD_STEP,
  payload: query,
});

export const getWizardStepSuccess = data => ({
  type: GET_WIZARD_STEP_SUCCESS,
  payload: data,
});

export const getWizardStepFail = error => ({
  type: GET_WIZARD_STEP_FAIL,
  payload: error,
});

export const getWizardNextStep = query => ({
  type: GET_WIZARD_NEXT_STEP,
  payload: query,
});

export const getWizardNextStepSuccess = data => ({
  type: GET_WIZARD_NEXT_STEP_SUCCESS,
  payload: data,
});

export const getWizardNextStepFail = error => ({
  type: GET_WIZARD_NEXT_STEP_FAIL,
  payload: error,
});

export const getValidatedWizardStep = query => ({
  type: GET_VALIDATED_WIZARD_STEP,
  payload: query,
});

export const getValidatedWizardStepSuccess = data => ({
  type: GET_VALIDATED_WIZARD_STEP_SUCCESS,
  payload: data,
});

export const getValidatedWizardStepFail = error => ({
  type: GET_VALIDATED_WIZARD_STEP_FAIL,
  payload: error,
});

export const setWizardFormField = data => ({
  type: SET_WIZARD_FORM_FIELD,
  payload: data,
});

export const setWizardHelperField = data => ({
  type: SET_WIZARD_HELPER_FIELD,
  payload: data,
});

export const setFileField = data => ({
  type: SET_WIZARD_FILE_FIELD,
  payload: data,
});

export const setFieldsByRules = data => ({
  type: SET_WIZARD_FIELDS_BY_RULES,
  payload: data,
});

export const setWizardMeta = data => ({
  type: SET_WIZARD_META,
  payload: data,
});

export const setWizardInitial = data => ({
  type: SET_WIZARD_INITIAL,
  payload: data,
});

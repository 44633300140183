import { CardTitle, ShadowCard } from 'components/Common';
import React from 'react';
import { OrderTypeMainEdit } from './OrderTypeEditMain';

export const OrderTypeMainCard = () => {
  return (
    <ShadowCard id="mainSettings" bodyStyle={{ padding: '24px' }}>
      <CardTitle title="orderTypes.main" />

      <OrderTypeMainEdit />
    </ShadowCard>
  );
};

import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  getApplicant,
  getApplicants,
  inviteApplicant,
  updateApplicant,
  deleteApplicant,
} from './actions';

const initState = {
  applicantDetail: {
    isLoading: false,
    applicant: null,
    isSubmitting: false,
  },
  applicants: {
    applicants: [],
    count: null,
    isLoading: false,
  },
};

const applicants = createReducer(initState, {
  [getApplicant]: state => ({
    ...state,
    applicantDetail: {
      ...state.applicantDetail,
      isLoading: true,
    },
  }),
  [getApplicant.success]: (state, { payload }) => ({
    ...state,
    applicantDetail: {
      applicant: payload,
      isLoading: false,
    },
  }),
  [getApplicant.failure]: state => ({
    ...state,
    applicantDetail: {
      ...state.applicantDetail,
      isLoading: false,
    },
  }),

  [getApplicants]: state => ({
    ...state,
    applicants: {
      ...state.applicants,
      isLoading: true,
    },
  }),
  [getApplicants.success]: (state, { payload }) => ({
    ...state,
    applicants: {
      applicants: payload.applicants,
      count: payload.count,
      isLoading: false,
    },
  }),
  [getApplicants.failure]: state => ({
    ...state,
    applicants: {
      ...state.applicants,
      isLoading: false,
    },
  }),

  [updateApplicant]: state => ({
    ...state,
    applicantDetail: {
      ...state.applicantDetail,
      isSubmitting: true,
    },
  }),
  [updateApplicant.success]: (state, { payload }) => ({
    ...state,
    applicantDetail: {
      ...state.applicantDetail,
      applicant: payload,
      isSubmitting: false,
    },
  }),
  [updateApplicant.failure]: state => ({
    ...state,
    applicantDetail: {
      ...state.applicantDetail,
      isSubmitting: false,
    },
  }),

  [inviteApplicant]: state => ({
    ...state,
  }),
  [inviteApplicant.success]: (state, { payload }) => ({
    ...state,
    applicantDetail: {
      ...state.applicantDetail,
      applicant: { ...state.applicantDetail.applicant, is_invited: true },
    },
    applicants: {
      ...state.applicants,
      applicants: state.applicants.applicants.map(applicant => {
        if (applicant.id === payload) {
          return { ...applicant, is_invited: true };
        }

        return applicant;
      }),
    },
  }),
  [inviteApplicant.failure]: state => ({
    ...state,
  }),

  [deleteApplicant]: state => ({
    ...state,
  }),
  [deleteApplicant.success]: (state, action) => ({
    ...state,
    applicants: {
      ...state.applicants,
      applicants: state.applicants.applicants.filter(
        applicant => applicant.id !== action.payload,
      ),
    },
  }),
  [deleteApplicant.failure]: (state, action) => ({
    ...state,
    applicants: { ...state.applicants, error: action.payload },
  }),
});

export default combineReducers({
  applicants,
});

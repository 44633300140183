import classNames from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Breadcrumbs } from './Common';
import Loader from './Common/Loader';
import { useMatchMedia } from 'hooks/useMatchMedia';

const StyledMainContent = styled.div`
  min-height: 100vh;
  min-width: 100%;
  padding: 0px ${({ theme }) => `${theme.spacing.l} ${theme.spacing.l}`};
  background: #f8f8fb;

  ${({ $fixHeight }) =>
    $fixHeight &&
    css`
      height: 100vh;
    `}

  ${({ $isLayoutMobile, theme }) =>
    $isLayoutMobile &&
    css`
      padding-left: ${theme.spacing.m};
      padding-right: ${theme.spacing.m};
      margin-top: 62px;
    `}
`;

const StyledTitle = styled.p`
  font-weight: 600;
  white-space: nowrap;
  font-size: 32px;
  padding-left: ${({ theme }) => theme.spacing.m};
  line-height: 42px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      line-height: 24px;
      font-size: 24px;
    `}
`;

const StyledSticker = styled.header`
  ${({ $isSticky, $isMobile }) =>
    $isSticky &&
    css`
      position: sticky;
      position: -webkit-sticky;
      top: ${$isMobile ? '55px' : '0px'};
      z-index: 11;
      background: #f8f8fb;
    `}
`;

const StyledContainer = styled.div`
  display: flex;

  @media (max-width: 766px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const scrollableHeader = [
  'order',
  'service',
  'delivery details',
  'client',
  'invoice detail',
  'aggregated invoice',
  'create invoice',
  'create client',
];

const getScrollableHeader = (title, allowedTitles) =>
  allowedTitles.some(allowedTitle =>
    title?.toLowerCase().includes(allowedTitle),
  );

export const Layout = memo(
  ({
    title,
    isScrollableHeader,
    breadcrumbs,
    loading = false,
    extraContent,
    children,
    isVerticalExtraContent,
    fixHeight,
    extraMobileContent,
  }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { isMobile } = useMatchMedia();

    return (
      <StyledMainContent $isLayoutMobile={isMobile} $fixHeight={fixHeight}>
        <StyledSticker
          $isMobile={isMobile}
          $isSticky={
            getScrollableHeader(t(title), scrollableHeader) ||
            isScrollableHeader
          }
          className={classNames(
            id || isScrollableHeader ? 'py-3' : 'py-4',
            isMobile && 'py-1',
          )}
        >
          {breadcrumbs && !isMobile && (
            <Breadcrumbs breadcrumbs={breadcrumbs} title={t(title)} />
          )}

          <StyledContainer
            className={classNames([isVerticalExtraContent && 'flex-column'])}
          >
            <StyledTitle $isMobile={isMobile} className="mb-0 me-3">
              {t(title)}
            </StyledTitle>

            {!loading &&
              (extraContent && isMobile ? (
                <div className="mt-3 d-flex">{extraContent}</div>
              ) : (
                extraContent
              ))}

            {extraMobileContent && isMobile && (
              <div className="mt-4">{extraMobileContent}</div>
            )}
          </StyledContainer>
        </StyledSticker>

        {loading ? (
          <Row className="d-flex justify-content-center">
            <Loader isLoading={loading} />
          </Row>
        ) : (
          <div className="p-0 d-flex">
            <Col className="p-0" xs={12}>
              {children}
            </Col>
          </div>
        )}
      </StyledMainContent>
    );
  },
);

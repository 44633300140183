import { Button } from 'reactstrap';
import styled from 'styled-components';

export const StyledButtonCancel = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: ${({ theme }) => theme.radius.s};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  .btn:disabled {
    color: ${({ theme }) => theme.colors.white}!important;
  }
`;

export const StyledButtonSave = styled(Button)`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border: none;
`;

import { FIRST_PAGE, PAGES } from 'common/data/constants';
import { BlueLink } from 'components/Common';
import { ButtonCreate } from 'components/Common/ButtonCreate';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { PaginationLm } from 'components/Common/PaginationLm';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { ShadowCard } from 'components/Common/ShadowCard';
import { Layout } from 'components/Layout';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { StyledTable } from 'components/Table/StyledTable';
import { ButtonControlTable } from 'components/Tariffs/ButtonControlTable';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import { deleteTariff, fetchTariffs } from 'store/tariffs/actions';

const Tariffs = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { tariffs, count, previous, next, loading } = useSelector(
    state => state.tariffs.tariffs.tariffs,
  );

  const [searchState, setSearchState] = useState({
    perPage: PAGES[0],
    page: FIRST_PAGE,
    search: '',
  });
  const [isOpenDeleteTariff, setIsOpenDeleteTariff] = useState(null);

  useEffect(() => {
    dispatch(
      fetchTariffs({
        page: searchState.page,
        page_size: searchState.perPage.value,
      }),
    );
  }, [searchState.page, searchState.perPage]);

  const handleChangeSearchParam = (field, value) => {
    setSearchState(prev => ({
      ...prev,
      page: FIRST_PAGE,
      [field]: value,
    }));
  };

  const handleCloseModalDelete = () => {
    setIsOpenDeleteTariff(null);
  };

  const handleDeleteTariff = id => {
    dispatch(deleteTariff({ id }));
    handleCloseModalDelete();
  };

  const filteredTariffs = useMemo(() => {
    handleChangeSearchParam('page', FIRST_PAGE);

    return tariffs.filter(tariff =>
      tariff.name.toLowerCase().includes(searchState.search.toLowerCase()),
    );
  }, [searchState.search, tariffs]);

  const showPagination = searchState.perPage.value < count;

  const debouncedHandleChange = debounce(
    event => handleChangeSearchParam('search', event.target.value),
    300,
  );

  const getTariffsActions = id => [
    {
      id: 1,
      title: 'common.open',
      function: () => push(`/tariffs/${id}`),
    },
    {
      id: 2,
      title: 'common.edit',
      function: () => push(`/tariffs/${id}`, { isEdit: true }),
    },
    {
      id: 3,
      title: 'tariffs.addServices',
      function: () => push(`/tariffs/${id}`, { isAddService: true }),
    },
    {
      id: 4,
      title: 'common.delete',
      function: () =>
        setIsOpenDeleteTariff({
          id,
        }),
    },
  ];

  return (
    <Layout
      extraContent={
        <ButtonCreate handleCreate={() => push('/tariffs/create')} />
      }
      loading={loading}
      title={t('sideBar.tariffs')}
    >
      <ShadowCard>
        {isOpenDeleteTariff && (
          <ModalConfirmDelete
            isOpenModal={isOpenDeleteTariff}
            textHeader="tariffs.delete"
            textBody="tariffs.deleteConfirm"
            handleCloseModal={handleCloseModalDelete}
            handleDelete={() => handleDeleteTariff(isOpenDeleteTariff.id)}
          />
        )}

        <Row>
          <Col xs={12}>
            <Row className="d-flex align-items-center mb-3">
              <Col xs={4}>
                <SearchInput handleChange={debouncedHandleChange} />
              </Col>

              <Col xs={8}>
                <div>
                  <PerPageSelect
                    value={searchState.perPage}
                    perPage={searchState.perPage}
                    setPerPage={value =>
                      handleChangeSearchParam('perPage', value)
                    }
                    setPage={value => handleChangeSearchParam('page', value)}
                  />
                </div>
              </Col>
            </Row>

            {filteredTariffs && filteredTariffs.length > 0 ? (
              <>
                <div className="table-responsive">
                  <StyledTable>
                    <thead>
                      <tr>
                        <th>{t('common.name')}</th>
                        <th>{t('common.description')}</th>
                        <th>Tags</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredTariffs.map(tariff => (
                        <tr key={tariff.id}>
                          <td className="col-4">
                            <BlueLink to={`/tariffs/${tariff.id}`}>
                              {tariff.name}
                            </BlueLink>
                            &nbsp;
                            {tariff.is_default && (
                              <i
                                className="text text-warning bx bx-crown"
                                title="Selected by default for new clients."
                              />
                            )}
                          </td>

                          <td>{tariff.description}</td>
                          <td>
                            {tariff.tags?.map((tag, i) =>
                              i === tariff.tags.length - 1
                                ? `${tag}`
                                : `${tag}, `,
                            )}
                          </td>

                          <td>
                            <ButtonControlTable
                              actions={getTariffsActions(tariff.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                </div>

                <Col xs={12}>
                  {showPagination && (
                    <PaginationLm
                      count={count}
                      perPage={searchState.perPage.value}
                      active={searchState.page}
                      next={next}
                      prev={previous}
                      selectPage={val =>
                        setSearchState(prev => ({
                          ...prev,
                          page: val,
                        }))
                      }
                    />
                  )}
                </Col>
              </>
            ) : (
              <Alert color="info">{t('tariffs.notFound')}</Alert>
            )}
          </Col>
        </Row>
      </ShadowCard>
    </Layout>
  );
};

export default Tariffs;

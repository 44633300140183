import React, { useState } from 'react';
import { AddressDisplay } from './AddressDisplay';
import { AddressEditForm } from './AddressEditForm';
import { deleteAddress } from 'store/addresses/actions';
import { useDispatch } from 'react-redux';
import { StyledField } from 'components/Common';
import classNames from 'classnames';
import { createAddresses, updateAddress } from 'store/addresses/actions';
import { convertClientAddressToAPI } from 'helpers/converters';

export const AddressForm = ({
  initialEdit,
  address,
  className,
  handleCancelCreate,
}) => {
  const [isEditMode, setIsEditMode] = useState(initialEdit);
  const dispatch = useDispatch();

  const handleOpenEditMode = () => setIsEditMode(true);
  const handleCloseEditMode = () => {
    if (address.id) {
      setIsEditMode(false);
    } else {
      handleCancelCreate(address.tempId);
    }
  };
  const handleDeleteAddress = () => dispatch(deleteAddress(address.id));

  const handleSave = values => {
    if (values.id) {
      dispatch(
        updateAddress({
          id: values.id,
          address: convertClientAddressToAPI(values),
          closeEdit: handleCloseEditMode,
        }),
      );

      return;
    }

    dispatch(
      createAddresses({
        address: convertClientAddressToAPI(values),
        closeEdit: handleCloseEditMode,
      }),
    );
  };

  return (
    <StyledField className={classNames('p-4', className)}>
      {isEditMode ? (
        <AddressEditForm
          address={address}
          handleCloseEditMode={handleCloseEditMode}
          handleSave={handleSave}
        />
      ) : (
        <AddressDisplay
          handleDeleteAddress={handleDeleteAddress}
          handleOpenEditMode={handleOpenEditMode}
          address={address}
        />
      )}
    </StyledField>
  );
};

import { CardTitle, ShadowCard, StyledColBold } from 'components/Common';
import { useCountries } from 'hooks/useCountries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';

export const DeliveryPlace = ({ delivery, prefix = 'to' }) => {
  const { countries } = useCountries();
  const { t } = useTranslation();

  const getDeliveryCountry = country =>
    countries.find(item => item?.id === country)?.country?.name;

  return (
    <ShadowCard id={prefix} bodyStyle={{ padding: '24px' }} className="mt-4">
      <CardTitle
        title={
          prefix === 'to'
            ? t('delivery.deliveryTo')
            : t('delivery.deliveryFrom')
        }
      />

      <div className="d-flex w-100 flex-lg-nowrap flex-wrap">
        <Col xs={12}>
          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('delivery.address')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">{delivery[`${prefix}_address`] ?? '-'}</p>
            </StyledColBold>
          </FormGroup>

          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('delivery.city')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">{delivery[`${prefix}_city`] ?? '-'}</p>
            </StyledColBold>
          </FormGroup>

          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('common.state')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">{delivery[`${prefix}_state`] ?? '-'}</p>
            </StyledColBold>
          </FormGroup>

          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('common.country')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">
                {delivery.to_country
                  ? getDeliveryCountry(delivery[`${prefix}_country`])
                  : '-'}
              </p>
            </StyledColBold>
          </FormGroup>

          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('delivery.company')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">{delivery[`${prefix}_company`] ?? '-'}</p>
            </StyledColBold>
          </FormGroup>

          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('delivery.name')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">{delivery[`${prefix}_name`] ?? '-'}</p>
            </StyledColBold>
          </FormGroup>

          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('auth.email')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">{delivery[`${prefix}_email`] ?? '-'}</p>
            </StyledColBold>
          </FormGroup>

          <FormGroup className="mb-4 align-items-center" row>
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('common.phone')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">
                {delivery[`${prefix}_phone`]
                  ? delivery[`${prefix}_phone`]
                  : '-'}
              </p>
            </StyledColBold>
          </FormGroup>

          <div className="d-flex align-items-center">
            <Col lg={3} xs={6}>
              <Label className="text-muted fw-normal m-0">
                {t('delivery.postalCode')}
              </Label>
            </Col>

            <StyledColBold
              style={{ fontSize: '14px' }}
              className="d-flex ps-2 flex-column"
              lg={8}
              xs={6}
            >
              <p className="m-0">{delivery[`${prefix}_postal_code`] ?? '-'}</p>
            </StyledColBold>
          </div>
        </Col>
      </div>
    </ShadowCard>
  );
};

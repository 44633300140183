//LOGIN
export const POST_FAKE_LOGIN = '/post-fake-login';
export const POST_JWT_LOGIN = '/auth/token/';
export const POST_JWT_SIGNUP = '/auth/signup/individual/';
export const POST_JWT_VERIFY = '/auth/token/verify/';
export const POST_JWT_REFRESH = '/auth/token/refresh/';
export const POST_JWT_PASSWORD_FORGET = '/auth/password/reset/request/';
export const POST_FAKE_PASSWORD_FORGET = '/fake-forget-pwd';
//REGISTER
export const POST_FAKE_REGISTER = '/post-fake-register';
//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/post-fake-profile';

//RESET PASSWORD
export const POST_RESET_PASSWORD = '/auth/password/reset/';

export const CONFIRM_EMAIL = '/auth/email/confirm/';

import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label, Row } from 'reactstrap';
import { DEFAULT_POINTS } from 'helpers/styles/constants';

import { DualCountryListSelector } from 'components/Common/DualCountryListSelector';

export const OrderTypeEditCountry = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <>
      <Row className="d-flex mt-3 flex-wrap">
        <Col
          className="p-0 d-flex align-items-center v-align-top"
          {...DEFAULT_POINTS.label}
        >
          <Label className="m-0 display-5">
            {t('orderTypes.enableCountry')}
          </Label>
        </Col>

        <Col className="p-0" {...DEFAULT_POINTS.input}>
          <Input
            className="m-3"
            type="checkbox"
            checked={formik.values.enableCountry}
            onChange={() =>
              formik.setFieldValue(
                'enableCountry',
                !formik.values.enableCountry,
              )
            }
          />
        </Col>
      </Row>
      {formik.values.enableCountry && (
        <>
          <Row className="d-flex mt-3 flex-wrap">
            <Col
              className="p-0 d-flex align-items-center v-align-top"
              {...DEFAULT_POINTS.label}
            >
              <Label className="m-0 display-5">
                {t('orderTypes.availableForCountries')}
              </Label>
            </Col>

            <Col className="p-0" {...DEFAULT_POINTS.input}>
              <DualCountryListSelector
                selected={formik.values.availableForCountries}
                onChange={newValue => {
                  formik.setFieldValue('availableForCountries', newValue);
                }}
              />
            </Col>
          </Row>

          <Row className="d-flex mt-3 flex-wrap">
            <Col
              className="p-0 d-flex align-items-center v-align-top"
              {...DEFAULT_POINTS.label}
            >
              <Label className="m-0 display-5">
                {t('orderTypes.enableVisaType')}
              </Label>
            </Col>

            <Col className="p-0" {...DEFAULT_POINTS.input}>
              <Input
                className="m-3"
                type="checkbox"
                checked={formik.values.enableVisaType}
                onChange={() =>
                  formik.setFieldValue(
                    'enableVisaType',
                    !formik.values.enableVisaType,
                  )
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

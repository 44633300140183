import { SELECT_CLASSNAME } from 'common/data/constants';
import { getClientFilter } from 'helpers/api-requests/admin';
import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import styled, { css } from 'styled-components';

const PAGE_SIZE = 50;

export const StyledAsyncPaginate = styled(AsyncPaginate)`
  .select2-selection {
    &__indicators {
      padding: 0px;

      span {
        background-color: transparent;
      }
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.placeholders}!important;
      opacity: 0.5;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__indicator {
      padding-right: 0;
    }

    &__control {
      border-radius: 8px;
      padding: 2px ${({ theme }) => theme.spacing.m};
      border-color: ${({ theme }) => theme.colors.gray200};

      svg {
        fill: ${({ theme }) => theme.colors.mainText};
      }
      ${({ invalid, theme }) =>
        invalid &&
        css`
          border-color: ${theme.colors.danger};
          &.select2-selection__control--is-focused {
            box-shadow: 0 0 0 0.15rem rgb(244 106 106 / 25%);
          }

          :hover {
            border-color: ${theme.colors.danger};
          }
        `};
    }

    &__single-value {
      font-size: 14px;
      margin: 0;
    }

    &__value-container {
      padding: 0px;
    }
  }
`;

export const SelectClients = props => {
  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await getClientFilter({
      search,
      page,
      page_size: PAGE_SIZE,
      status: 'active',
    });

    return {
      options: response.data.results,
      hasMore: Boolean(response.data.next),
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <StyledAsyncPaginate
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      loadOptions={loadOptions}
      isClearable
      debounceTimeout={200}
      classNamePrefix={SELECT_CLASSNAME}
      additional={{
        page: 1,
      }}
      {...props}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getUserInfo, setUserPassword } from 'helpers/api-requests/admin';
import { showToastError } from 'helpers/utils/toast';
import { useFormik } from 'formik';
import FormikInput from 'components/Common/FormikInput';
import { SET_PASSWORD_VALIDATOR } from 'forms/validators';
import { postJwtLogin } from 'helpers/api-requests/auth';
import { useDispatch } from 'react-redux';
import { loginSuccess } from 'store/actions';
import { ShadowCard } from 'components/Common';
import { NON_AUTH_PADDING } from 'helpers/styles/constants';
import { StyledTextHead } from './Login';
import { getFirstAvailableRoute } from 'helpers/jwt_helper';

const SetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState(null);

  const submitHandle = async values => {
    try {
      const response = await setUserPassword(token, values.password);

      if (response.success) {
        postJwtLogin({
          username: userInfo.username,
          password: values.password,
        })
          .then(response => {
            if (response.success) {
              localStorage.setItem('authUser', JSON.stringify(response.data));
              dispatch(loginSuccess(response));
              window.location.href = getFirstAvailableRoute();
            }
          })
          .catch(err => showToastError(err));
      } else {
        showToastError(response.message);
      }
    } catch (error) {
      showToastError(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      verifyPassword: '',
    },
    validationSchema: SET_PASSWORD_VALIDATOR,
    onSubmit: submitHandle,
  });

  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo(token);

      if (response.success) {
        setUserInfo(response.data);
      } else {
        showToastError(response.message);
      }
    } catch (error) {
      showToastError(error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <ShadowCard
      className="m-4"
      bodyStyle={{ ...NON_AUTH_PADDING, maxWidth: 530 }}
    >
      {userInfo ? (
        <form onSubmit={formik.handleSubmit}>
          <StyledTextHead className="mb-4">
            {t('auth.setPassword')}
          </StyledTextHead>

          {userInfo?.organization && (
            <FormikInput
              label="auth.organization"
              value={userInfo.organization}
              disabled
            />
          )}

          <FormikInput label="auth.name" value={userInfo?.full_name} disabled />

          <FormikInput
            label="auth.password"
            type="password"
            name="password"
            placeholder="auth.passwordPlaceholder"
            onBlur={formik.handleBlur}
            value={formik.values.password}
            handleChange={formik.handleChange}
            errors={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : null
            }
          />

          <FormikInput
            label="auth.repeatPassword"
            type="password"
            name="verifyPassword"
            placeholder="auth.passwordPlaceholder"
            onBlur={formik.handleBlur}
            value={formik.values.verifyPassword}
            handleChange={formik.handleChange}
            errors={
              formik.errors.verifyPassword && formik.touched.verifyPassword
                ? formik.errors.verifyPassword
                : null
            }
          />

          <Button
            color="primary"
            block
            style={{ padding: '12px 0' }}
            className="waves-effect waves-light"
            type="submit"
            disabled={!formik.dirty}
          >
            {t('auth.savePassword')}
          </Button>
        </form>
      ) : (
        <Alert color="primary">{t('auth.userNotFound')}</Alert>
      )}
    </ShadowCard>
  );
};

export default SetPassword;

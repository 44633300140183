import React from 'react';
import { UKImage, DEImage } from 'assets/images/visa';

const languages = {
  de: {
    label: 'languages.de',
    flag: <DEImage />,
    key: 'de',
    text: 'languages.de',
  },
  en: {
    label: 'languages.en',
    flag: <UKImage />,
    key: 'en',
    text: 'languages.en',
  },
};

export default languages;

import { StyledInput } from 'components/Common/FormikInput';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { useFormikContext } from 'formik';

import { isUserOperator } from 'helpers/jwt_helper';
import { DEFAULT_POINTS } from 'helpers/styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { compareDates, useDescribeToEstimate } from './helpers';
import { addDays, subDays } from 'date-fns';
// import { min } from 'lodash';

export const ApplicantDates = ({ index, orderTypeFieldsSettings }) => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  const applicantData = formik.values.applicants?.[index];

  const isDisabledFields = !formik.values.orderDetail?.isEditable;

  const { minimalEstimate } = useDescribeToEstimate(applicantData);

  const isD1GtOrEqD2 = (date1, date2) => {
    if (date1?.getFullYear() > date2?.getFullYear()) {
      return true;
    } else if (date1?.getMonth() > date2?.getMonth()) {
      return true;
    } else {
      return date1?.getDate() >= date2?.getDate();
    }
  };
  const datesMatch = isD1GtOrEqD2(
    formik.values.applicants?.[index]?.documentsToApplicantEstimatedAt,
    minimalEstimate,
  );

  return (
    <div className="mt-4">
      {/* Documents to Applicant */}
      {orderTypeFieldsSettings?.enableDocumentsToApplicantEstimatedAt && (
        <FormGroup row className="mb-4 align-items-center">
          <Col sm={5} xxl={3}>
            <Label className="m-0">
              {t('order.documentsToApplicantEstimatedAt')}
            </Label>
          </Col>

          <Col xs={12} sm={6} lg={4}>
            <StyledDatePicker
              onChange={date => {
                formik.setFieldValue(
                  `applicants[${index}].documentsToApplicantEstimatedAt`,
                  date,
                );
                formik.values.applicants?.[index].statusApplicant === 'draft' &&
                  formik.setFieldValue(
                    `applicants[${index}].firstDateEntry`,
                    null,
                  );
              }}
              minDate={
                formik.values.applicants?.[index].statusApplicant === 'draft' &&
                minimalEstimate
              }
              maxDate={
                applicantData.statusApplicant !== 'draft'
                  ? applicantData?.firstDateEntry
                  : datesMatch
                  ? formik.values.applicants?.[index]?.firstDateEntry
                  : addDays(minimalEstimate, 90)
              }
              value={
                // formik.values.applicants?.[index]?.documentsToApplicantEstimatedAt
                applicantData.statusApplicant !== 'draft'
                  ? formik.values.applicants?.[index]
                      ?.documentsToApplicantEstimatedAt
                  : datesMatch
                  ? formik.values.applicants?.[index]
                      ?.documentsToApplicantEstimatedAt
                  : null
              }
              selected={
                // formik.values.applicants?.[index]?.documentsToApplicantEstimatedAt
                applicantData.statusApplicant !== 'draft'
                  ? formik.values.applicants?.[index]
                      ?.documentsToApplicantEstimatedAt
                  : datesMatch
                  ? formik.values.applicants?.[index]
                      ?.documentsToApplicantEstimatedAt
                  : null
              }
              placeholder={t('common.datePlaceholder')}
              disabled={isDisabledFields}
              placeholderText={t('common.datePlaceholder')}
              invalid={
                formik.errors.applicants?.[index]
                  ?.documentsToApplicantEstimatedAt ||
                (applicantData.statusApplicant === 'draft' && !datesMatch)
              }
            />
          </Col>
        </FormGroup>
      )}

      {orderTypeFieldsSettings?.enableFirstDateOfEntry && (
        <FormGroup row className="mt-4 align-items-center">
          <Col {...DEFAULT_POINTS.label.xs} sm={5} xxl={3}>
            <Label>{t('order.firstEntry')}:</Label>
          </Col>

          <Col {...DEFAULT_POINTS.input.xs} sm={6} lg={4}>
            <StyledDatePicker
              onChange={date =>
                formik.setFieldValue(
                  `applicants[${index}].firstDateEntry`,
                  date,
                )
              }
              disabled={isDisabledFields}
              selected={
                // formik.values.applicants?.[index]?.firstDateEntry
                applicantData.statusApplicant !== 'draft'
                  ? formik.values.applicants?.[index]?.firstDateEntry
                  : !datesMatch
                  ? null
                  : formik.values.applicants?.[index]?.firstDateEntry
              }
              placeholder={t('common.datePlaceholder')}
              value={
                // formik.values.applicants?.[index]?.firstDateEntry
                applicantData.statusApplicant !== 'draft'
                  ? formik.values.applicants?.[index]?.firstDateEntry
                  : !datesMatch
                  ? null
                  : formik.values.applicants?.[index]?.firstDateEntry
              }
              placeholderText={t('common.datePlaceholder')}
              invalid={
                formik.errors.applicants?.[index]?.firstDateEntry ||
                (applicantData.statusApplicant === 'draft' && !datesMatch)
              }
              minDate={
                formik.values.applicants?.[index].statusApplicant === 'draft' &&
                addDays(
                  compareDates(
                    minimalEstimate,
                    applicantData?.documentsToApplicantEstimatedAt,
                  ),
                  1,
                )
              }
            />
          </Col>
        </FormGroup>
      )}

      {/* Documents to Consulate  */}

      {isUserOperator &&
        orderTypeFieldsSettings?.enableDocumentsToConsEstimatedAt && (
          <FormGroup row className="mb-4 align-items-center">
            <Col sm={5} xxl={3}>
              <Label className="m-0">
                {t('order.documentsToConsulateEstimatedAt')}
              </Label>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <StyledDatePicker
                onChange={date =>
                  formik.setFieldValue(
                    `applicants[${index}].documentsToConsulateEstimatedAt`,
                    date,
                  )
                }
                selected={
                  formik.values.applicants?.[index]
                    ?.documentsToConsulateEstimatedAt
                }
                placeholder={t('common.datePlaceholder')}
                disabled={isDisabledFields}
                value={
                  formik.values.applicants?.[index]
                    ?.documentsToConsulateEstimatedAt
                }
                placeholderText={t('common.datePlaceholder')}
                invalid={
                  formik.errors.applicants?.[index]
                    ?.documentsToConsulateEstimatedAt
                }
              />
            </Col>
          </FormGroup>
        )}

      {isUserOperator &&
        orderTypeFieldsSettings?.enableDocumentsToConsActualAt && (
          <FormGroup row className="mb-4 align-items-center">
            <Col sm={5} xxl={3}>
              <Label className="m-0">
                {t('order.documentsToConsulateActualAt')}
              </Label>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <StyledDatePicker
                onChange={date =>
                  formik.setFieldValue(
                    `applicants[${index}].documentsToConsulateActualAt`,
                    date,
                  )
                }
                selected={
                  formik.values.applicants?.[index]
                    ?.documentsToConsulateActualAt
                }
                placeholder={t('common.datePlaceholder')}
                disabled={isDisabledFields}
                value={
                  formik.values.applicants?.[index]
                    ?.documentsToConsulateActualAt
                }
                placeholderText={t('common.datePlaceholder')}
                invalid={
                  formik.errors.applicants?.[index]
                    ?.documentsToConsulateActualAt
                }
              />
            </Col>
          </FormGroup>
        )}

      {/* Documents from Consulate  */}
      {isUserOperator &&
        orderTypeFieldsSettings?.enableDocumentsFromConsEstimatedAt && (
          <FormGroup row className="mb-4 align-items-center">
            <Col sm={5} xxl={3}>
              <Label className="m-0">
                {t('order.documentsFromConsulateEstimatedAt')}
              </Label>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <StyledDatePicker
                onChange={date =>
                  formik.setFieldValue(
                    `applicants[${index}].documentsFromConsulateEstimatedAt`,
                    date,
                  )
                }
                selected={
                  formik.values.applicants?.[index]
                    ?.documentsFromConsulateEstimatedAt
                }
                placeholder={t('common.datePlaceholder')}
                disabled={isDisabledFields}
                value={
                  formik.values.applicants?.[index]
                    ?.documentsFromConsulateEstimatedAt
                }
                placeholderText={t('common.datePlaceholder')}
                invalid={
                  formik.errors.applicants?.[index]
                    ?.documentsFromConsulateEstimatedAt
                }
                maxDate={subDays(
                  applicantData?.documentsToApplicantEstimatedAt,
                  1,
                )}
              />
            </Col>
          </FormGroup>
        )}

      {isUserOperator &&
        orderTypeFieldsSettings?.enableDocumentsFromConsActualAt && (
          <FormGroup row className="mb-4 align-items-center">
            <Col sm={5} xxl={3}>
              <Label className="m-0">
                {t('order.documentsFromConsulateActualAt')}
              </Label>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <StyledDatePicker
                onChange={date =>
                  formik.setFieldValue(
                    `applicants[${index}].documentsFromConsulateActualAt`,
                    date,
                  )
                }
                selected={
                  formik.values.applicants?.[index]
                    ?.documentsFromConsulateActualAt
                }
                placeholder={t('common.datePlaceholder')}
                disabled={isDisabledFields}
                value={
                  formik.values.applicants?.[index]
                    ?.documentsFromConsulateActualAt
                }
                placeholderText={t('common.datePlaceholder')}
                invalid={
                  formik.errors.applicants?.[index]
                    ?.documentsToConsulateActualAt
                }
              />
            </Col>
          </FormGroup>
        )}

      {isUserOperator &&
        orderTypeFieldsSettings?.enableDocumentsToApplicantActualAt && (
          <FormGroup row className="mb-4 align-items-center">
            <Col sm={5} xxl={3}>
              <Label className="m-0">
                {t('order.documentsToApplicantActualAt')}
              </Label>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <StyledDatePicker
                onChange={date =>
                  formik.setFieldValue(
                    `applicants[${index}].documentsToApplicantActualAt`,
                    date,
                  )
                }
                selected={
                  formik.values.applicants?.[index]
                    ?.documentsToApplicantActualAt
                }
                placeholder={t('common.datePlaceholder')}
                disabled={isDisabledFields}
                value={
                  formik.values.applicants?.[index]
                    ?.documentsToApplicantActualAt
                }
                placeholderText={t('common.datePlaceholder')}
                invalid={
                  formik.errors.applicants?.[index]
                    ?.documentsToApplicantActualAt
                }
              />
            </Col>
          </FormGroup>
        )}

      {/* Comments left temporarily for reference */}

      {/* <FormGroup row className="mb-4 align-items-center">
        <Col sm={5} xxl={3}>
          <Label className="m-0">{t('order.documentBack')}</Label>
        </Col>

        <Col xs={12} sm={6} lg={4}>
          <StyledDatePicker
            onChange={date =>
              formik.setFieldValue(`applicants[${index}].documentBack`, date)
            }
            minDate={
              formik.values.applicants?.[index]?.documentClient ??
              addDays(new Date(), 3)
            }
            selected={formik.values.applicants?.[index]?.documentBack}
            placeholder={t('common.datePlaceholder')}
            disabled={isDisabledFields}
            value={formik.values.applicants?.[index]?.documentBack}
            placeholderText={t('common.datePlaceholder')}
            invalid={formik.errors.applicants?.[index]?.documentBack}
          />
        </Col>
      </FormGroup>       */}

      {/* {isUserOperator && (
        <FormGroup row className="mb-4 align-items-center">
          <Col sm={5} xxl={3}>
            <Label className="m-0">{t('order.sendToConsulate')}:</Label>
          </Col>

          <Col xs={12} sm={6} lg={4}>
            <StyledDatePicker
              onChange={date => {
                if (!date) {
                  formik.setFieldValue(
                    `applicants[${index}].collectionConsulate`,
                    null,
                  );
                  formik.setFieldValue(
                    `applicants[${index}].documentClient`,
                    null,
                  );
                }
                formik.setFieldValue(
                  `applicants[${index}].sendConsulate`,
                  date,
                );
              }}
              minDate={new Date()}
              disabled={isDisabledFields}
              selected={formik.values.applicants?.[index]?.sendConsulate}
              placeholder={t('common.datePlaceholder')}
              value={formik.values.applicants?.[index]?.sendConsulate}
              placeholderText={t('common.datePlaceholder')}
              invalid={formik.errors.applicants?.[index]?.sendConsulate}
            />
          </Col>
        </FormGroup>
      )} */}

      {/* {isUserOperator && (
        <FormGroup row className="mb-4">
          <Col sm={5} xxl={3}>
            <Label className="m-0">{t('order.collectingDocuments')}:</Label>
          </Col>

          <Col xs={12} sm={6} lg={4}>
            <StyledDatePicker
              onChange={date =>
                formik.setFieldValue(
                  `applicants[${index}].collectionConsulate`,
                  date,
                )
              }
              disabled={
                !formik.values.applicants?.[index]?.sendConsulate ||
                isDisabledFields
              }
              selected={formik.values.applicants?.[index]?.collectionConsulate}
              placeholder={t('common.datePlaceholder')}
              value={formik.values.applicants?.[index]?.collectionConsulate}
              placeholderText={t('common.datePlaceholder')}
              minDate={addDays(
                formik.values.applicants?.[index]?.sendConsulate,
                1,
              )}
              invalid={formik.errors.applicants?.[index]?.collectionConsulate}
            />
          </Col>
        </FormGroup>
      )} */}

      {/* {isUserOperator && (
        <FormGroup row className="align-items-center">
          <Col {...DEFAULT_POINTS.label.xs} sm={5} xxl={3}>
            <Label className="m-0">{t('order.sendingClient')}:</Label>
          </Col>

          <Col xs={12} sm={6} lg={4}>
            <StyledDatePicker
              onChange={date =>
                formik.setFieldValue(
                  `applicants[${index}].documentClient`,
                  date,
                )
              }
              selected={formik.values.applicants?.[index]?.documentClient}
              placeholder={t('common.datePlaceholder')}
              disabled={
                !formik.values.applicants?.[index]?.collectionConsulate ||
                isDisabledFields
              }
              value={formik.values.applicants?.[index]?.documentClient}
              placeholderText={t('common.datePlaceholder')}
              invalid={formik.errors.applicants?.[index]?.documentClient}
              minDate={formik.values.applicants?.[index]?.collectionConsulate}
            />
          </Col>
        </FormGroup>
      )} */}

      {isUserOperator && orderTypeFieldsSettings?.enableGultigFrom && (
        <>
          <hr className="my-4" />

          <FormGroup row>
            <Col sm={5} xxl={3}>
              <Label className="m-0">{t('order.gultigFrom')}:</Label>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <StyledDatePicker
                onChange={date =>
                  formik.setFieldValue(`applicants[${index}].gultigFrom`, date)
                }
                disabled={isDisabledFields}
                selected={formik.values.applicants?.[index]?.gultigFrom}
                placeholder={t('common.datePlaceholder')}
                value={formik.values.applicants?.[index]?.gultigFrom}
                placeholderText={t('common.datePlaceholder')}
                invalid={formik.errors.applicants?.[index]?.gultigFrom}
              />
            </Col>
          </FormGroup>
        </>
      )}

      {isUserOperator && orderTypeFieldsSettings?.enableGultigTo && (
        <FormGroup row>
          <Col sm={5} xxl={3}>
            <Label className="m-0">{t('order.gultigTo')}:</Label>
          </Col>

          <Col xs={12} sm={6} lg={4}>
            <StyledDatePicker
              onChange={date =>
                formik.setFieldValue(`applicants[${index}].gultigTo`, date)
              }
              disabled={isDisabledFields}
              selected={formik.values.applicants?.[index]?.gultigTo}
              placeholder={t('common.datePlaceholder')}
              value={formik.values.applicants?.[index]?.gultigTo}
              placeholderText={t('common.datePlaceholder')}
              invalid={formik.errors.applicants?.[index]?.gultigTo}
            />
          </Col>
        </FormGroup>
      )}
      {isUserOperator && (
        <FormGroup row>
          <Col {...DEFAULT_POINTS.label.xs} sm={5} xxl={3}>
            <Label className="m-0">{t('common.comment')}</Label>
          </Col>

          <Col xs={12} sm={4}>
            <StyledInput
              disabled={isDisabledFields}
              onChange={formik.handleChange}
              type="textarea"
              name={`applicants.[${index}].comment`}
              value={formik.values.applicants?.[index]?.comment}
              placeholder={t('common.commentPlaceholder')}
              $noResize
            />
          </Col>
        </FormGroup>
      )}
    </div>
  );
};

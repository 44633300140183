import { createQueryActions } from 'helpers/store/createQueryActions';

export const getChats = createQueryActions('getChats');

export const getGroups = createQueryActions('getGroups');

export const getContacts = createQueryActions('getContacts');

export const getMessages = createQueryActions('getMessages');

export const addMessage = createQueryActions('addMessage');

export const changeChatStatus = createQueryActions('changeChatStatus');

export const selectChatRoom = createQueryActions('selectChatRoom');

export const getMoreMessages = createQueryActions('getMoreMessages');

export const getChatDetail = createQueryActions('getChatDetail');

import { useState } from 'react';
import {
  DATE_FORMAT_WITH_SECONDS,
  USER_ROLE_CHOICES,
} from 'common/data/constants';
import { CardTitle, ShadowCard, StyledPhoneInput } from 'components/Common';
import { StyledInput, StyledLabel } from 'components/Common/FormikInput';
import { Select } from 'components/Common/Select';
import { OrganizationsSelect } from 'components/OrganizationsSelect';
import { useFormikContext } from 'formik';
import { formatDate } from 'helpers/formatDate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { checkUsernameExists } from 'helpers/api-requests/references';

import { useParams } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import { showToastError } from 'helpers/utils/toast';

const UserForm = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const { id } = useParams();

  const { errors, values, handleChange, setFieldValue } = formik;
  const [usernameAvailableMessage, setUsernameAvailableMessage] = useState();
  const [messageClassName, setMessageClassName] = useState();

  const checkUsername = e => {
    const username = e.target.value.trim();
    setFieldValue('username', username);
    setUsernameAvailableMessage();

    if (username?.length >= 3) {
      const username = e.target.value;
      checkUsernameExists(username)
        .then(response => {
          if (response.success) {
            setMessageClassName('text-success');
            setUsernameAvailableMessage(response.data.message);
          } else {
            setMessageClassName('text-danger');
            setUsernameAvailableMessage(response.message);
          }
        })
        .catch(err => showToastError(err));
    } else if (username?.length > 0) {
      setMessageClassName('text-warning');
      setUsernameAvailableMessage('Provide 3 letters at least.');
    }
  };

  return (
    <ShadowCard bodyStyle={{ padding: 24 }}>
      <CardTitle title="common.information" />

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel>{t('auth.firstName')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name="first_name"
            placeholder={t('auth.enterFirstName')}
            value={values.first_name}
            invalid={errors.first_name}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel>{t('auth.lastName')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name="last_name"
            placeholder={t('auth.enterLastName')}
            value={values.last_name}
            invalid={errors.last_name}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel>{t('auth.email')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name="email"
            placeholder={t('auth.enterEmail')}
            value={values.email}
            invalid={errors.email}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel>{t('users.role')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <Select
            value={USER_ROLE_CHOICES.find(role => role.value === values.role)}
            classNamePrefix="select2-selection"
            isClearable
            placeholder={t('users.rolePlaceholder')}
            options={USER_ROLE_CHOICES}
            onChange={option => setFieldValue('role', option?.value)}
            invalid={errors.role}
          />
        </Col>
      </Row>

      {(values.role === 'manager' || values.role === 'employee') && (
        <Row className="p-0 mb-4 align-items-center">
          <Col xs={3}>
            <StyledLabel>{t('users.organization')}</StyledLabel>
          </Col>

          <Col xs={5}>
            <OrganizationsSelect
              value={values.organization}
              onChange={option => setFieldValue('organization', option)}
              className="w-100"
              invalid={errors.organization}
            />
          </Col>
        </Row>
      )}

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel>{t('users.phoneNumber')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledPhoneInput
            inputStyle={{ width: '100%' }}
            name="phone"
            placeholder={t('users.phoneNumberPlaceholder')}
            onChange={phone => setFieldValue('phone', phone)}
            value={values.phone}
          />
        </Col>
      </Row>

      {!id && (
        <>
          <Row className="p-0 align-items-center">
            <Col xs={3}>
              <StyledLabel>{t('auth.username')}</StyledLabel>
            </Col>

            <Col xs={5}>
              <StyledInput
                name="username"
                placeholder={t('auth.username')}
                value={values.username}
                invalid={errors.username}
                onChange={checkUsername}
              />
              <p className={`mt-1 ${messageClassName}`}>
                {usernameAvailableMessage}
              </p>
            </Col>
          </Row>
        </>
      )}

      {id && (
        <>
          <hr className="w-100 my-4" />

          <Row className="p-0 my-4 align-items-center">
            <Label xs={3} className="fw-400 fs-14 text-muted">
              {t('auth.username')}
            </Label>

            <Col xs={9}>
              <StyledLabel className="m-0">{values.username}</StyledLabel>
            </Col>
          </Row>

          <Row className="p-0 align-items-center">
            <Label className="fw-400 fs-14 text-muted" xs={3}>
              {t('client.lastLogin')}
            </Label>

            <Col xs={9}>
              <StyledLabel className="m-0">
                {formatDate(values.last_login, DATE_FORMAT_WITH_SECONDS)}
              </StyledLabel>
            </Col>
          </Row>
        </>
      )}
    </ShadowCard>
  );
};

export default UserForm;

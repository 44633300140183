import { DATE_FORMAT_WITH_SYMBOL } from 'common/data/constants';
import {
  BlueLink,
  ButtonCreate,
  PaginationLm,
  PerPageSelect,
  ShadowCard,
} from 'components/Common';

import { Layout } from 'components/Layout';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { StyledTable } from 'components/Table/StyledTable';
import { ButtonControlTable } from 'components/Tariffs/ButtonControlTable';
import { convertDateToAPI } from 'helpers/converters';
import { formatDate } from 'helpers/formatDate';
import { isUserManager } from 'helpers/jwt_helper';
import { useLoader } from 'hooks/useLoader';
import { useTable } from 'hooks/useTable';
import { useModal } from 'hooks/useModal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  getApplicants,
  inviteApplicant,
  deleteApplicant,
} from 'store/applicants/actions';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';

const getApplicantsState = state => state.applicants.applicants.applicants;

const additionalFields = {
  searchEmail: '',
  date: null,
  searchFirstName: '',
  searchLastName: '',
};

// const getUniqCountries = applicant => {
//   const countries = [];

//   applicant.passports.forEach(passport => {
//     if (!countries.includes(passport.country.country.name)) {
//       countries.push(passport.country.country.name);
//     }
//   });

//   return countries;
// };

export const Applicants = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t } = useTranslation();
  const { isLoading, count, applicants } = useSelector(getApplicantsState);
  const { handleChangeSearchParam, searchState, showPagination } = useTable(
    additionalFields,
    count,
  );
  const { Loader, notFound } = useLoader(isLoading);
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();

  useEffect(() => {
    dispatch(
      getApplicants({
        name: searchState.searchName,
        page: searchState.page,
        page_size: searchState.perPage.value,
        birthday: convertDateToAPI(searchState.date),
        email: searchState.searchEmail,
      }),
    );
  }, [searchState]);

  // const getApplicantActions = (id, isInvited, isLogin) => {
  const getApplicantActions = (id, isInvited) => {
    const actions = [
      {
        id: 1,
        title: 'common.open',
        function: () => push(`/applicants/${id}`),
      },
      {
        id: 2,
        title: 'common.delete',
        function: () => handleOpenModal({ id }),
      },
    ];

    // if (isUserManager && !isLogin) {
    if (isUserManager) {
      return actions.concat([
        {
          id: 3,
          // disabled: isInvited,
          title: isInvited ? 'invites.reInvite' : 'invites.invite',
          function: () => dispatch(inviteApplicant({ id })),
        },
      ]);
    }

    return actions;
  };

  const handleDeleteApplicant = id => {
    dispatch(deleteApplicant({ id, handleCloseModal }));
  };

  return (
    <Layout
      title="sideBar.applicants"
      extraContent={
        <ButtonCreate handleCreate={() => push('/applicants/create')} />
      }
    >
      <ShadowCard>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between  flex-wrap align-items-center">
              <div className="d-flex flex-wrap align-items-center ">
                <div className="me-3 mb-3 d-flex">
                  <SearchInput
                    handleChange={event =>
                      handleChangeSearchParam('searchName', event.target.value)
                    }
                  />
                </div>

                {/* <div className="me-3 mb-3">
                  <StyledDatePicker
                    placeholderText="auth.birthday"
                    value={searchState.date}
                    $isBlue
                    selected={searchState.date}
                    maxDate={new Date()}
                    onChange={date => handleChangeSearchParam('date', date)}
                  />
                </div> */}

                <div className="me-3 mb-3">
                  <SearchInput
                    placeholder="applicants.emailPlaceholder"
                    handleChange={event =>
                      handleChangeSearchParam('searchEmail', event.target.value)
                    }
                  />
                </div>
              </div>

              <div className="me-3 mb-3">
                <PerPageSelect
                  value={searchState.perPage}
                  perPage={searchState.perPage}
                  setPerPage={value =>
                    handleChangeSearchParam('perPage', value)
                  }
                  setPage={value => handleChangeSearchParam('page', value)}
                />
              </div>
            </div>

            {Loader && (
              <Row className="d-flex align-items-center justify-content-center p-5">
                {Loader}
              </Row>
            )}

            {isOpen && (
              <ModalConfirmDelete
                isOpenModal={isOpen}
                textHeader="applicants.delete"
                textBody="applicants.deleteConfirm"
                handleCloseModal={handleCloseModal}
                handleDelete={() => handleDeleteApplicant(isOpen.id)}
              />
            )}

            {notFound(t('common.notFound'), !applicants.length)}

            {!Loader && applicants.length > 0 && (
              <>
                <div className="table-responsive">
                  <StyledTable>
                    <thead>
                      <tr>
                        <th>{t('applicant.name')}</th>
                        {isUserManager && <th>{t('auth.username')}</th>}
                        <th>{t('client.email')}</th>
                        {isUserManager && <th>{t('client.lastLogin')}</th>}
                        <th />
                      </tr>
                    </thead>

                    <tbody>
                      {applicants.map(applicant => (
                        <tr key={applicant.id}>
                          <td>
                            <BlueLink to={`applicants/${applicant.id}`}>
                              {isUserManager &&
                                (applicant.user ? (
                                  <i className="bx bx-user-circle" />
                                ) : (
                                  <i className="bx bx-user-x" />
                                ))}
                              &nbsp;
                              {`${applicant.first_name} ${applicant.last_name}`}
                            </BlueLink>
                          </td>

                          {isUserManager && (
                            <td>
                              {/* {getUniqCountries(applicant).map(country => (
                              <div
                                key={country}
                                className="text-nowrap d-flex justify-content-between"
                              >
                                <p className="m-0">{country}</p>
                              </div>
                            ))} */}
                              {applicant.user?.username ?? '-'}
                            </td>
                          )}

                          <td>{applicant.email}</td>

                          {isUserManager && (
                            <td>
                              {applicant.user
                                ? applicant.user.last_login
                                  ? formatDate(
                                      applicant.user.last_login,
                                      DATE_FORMAT_WITH_SYMBOL,
                                    )
                                  : t('auth.neverLoggedIn')
                                : t('auth.neverLoggedIn')}
                            </td>
                          )}
                          <td>
                            <ButtonControlTable
                              actions={getApplicantActions(
                                applicant.id,
                                applicant.user?.is_invited,
                                // applicant.user?.last_login,
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                </div>

                <Col xs={12}>
                  {showPagination && (
                    <PaginationLm
                      count={count}
                      perPage={searchState.perPage.value}
                      active={searchState.page}
                      selectPage={value =>
                        handleChangeSearchParam('page', value)
                      }
                    />
                  )}
                </Col>
              </>
            )}
          </Col>
        </Row>
      </ShadowCard>
    </Layout>
  );
};

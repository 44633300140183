import { InviteButton } from 'pages/Applicant/InviteButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  padding: 12px 56px !important;

  i {
    line-height: 20px;
  }

  p {
    line-height: 20px;
  }
`;

const StyledBlock = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.red500};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.red500};
  }
`;

export const StyledUnblock = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.green500};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.green500};
  }
`;

export const UserControlButtons = ({
  status,
  unlockUser,
  lockUser,
  handleInviteUser,
  isOpenInviteButton,
  isDisabledInviteUser,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-0 d-flex flex-column">
      {status === 'blocked' && (
        <StyledUnblock
          className="w-100 border-0 d-flex justify-content-center mb-4"
          onClick={unlockUser}
        >
          <i className="bx bx-lock-open-alt fs-5 me-2" />

          <p className="mb-0">{t('users.unblock')}</p>
        </StyledUnblock>
      )}

      {status !== 'blocked' && (
        <StyledBlock
          className="w-100 border-0 d-flex justify-content-center mb-4"
          onClick={lockUser}
        >
          <i className="bx bx-lock-alt fs-5 me-2" />

          <p className="mb-0">{t('users.block')}</p>
        </StyledBlock>
      )}

      {isOpenInviteButton && (
        <InviteButton
          isDisabled={isDisabledInviteUser}
          handleInvite={handleInviteUser}
        />
      )}
    </div>
  );
};

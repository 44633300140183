import { EMPTY_VALUE, UPS_PROVIDER_NAME } from 'common/data/constants';
import { DELIVERY_METHODS } from 'common/data/delivery';
import { Select } from 'components/Common';
import { StyledInput } from 'components/Common/FormikInput';
import {
  StyledButtonCancel,
  StyledButtonSave,
} from 'components/Common/StyledButtonSave';
import { useFormik } from 'formik';
import {
  createDeliveryShipments,
  updateDeliveryShipments,
} from 'helpers/api-requests/admin';
import { convertDeliveryToApi } from 'helpers/converters';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { DeliveryMethodSelect } from '../DeliveryMethodSelect';
import { DeliveryForm } from './DeliveryForm';
import { useDispatch } from 'react-redux';
import { getReferencesConfig } from 'store/actions';
import styled from 'styled-components';

export const StyledError = styled.span`
  font-size: 12px;
`;

const initialValues = {
  provider: null,
  from: {
    address: '',
    city: '',
    code: '',
    companyName: '',
    country: null,
    email: '',
    name: '',
    phone: '',
    state: '',
  },
  to: {
    address: '',
    city: '',
    code: '',
    companyName: '',
    country: null,
    email: '',
    name: '',
    phone: '',
    state: '',
  },
};

export const DeliveryEdit = ({
  delivery = initialValues,
  handleCloseEditMode,
  index,
  setDeliveries,
  deliveries,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReferencesConfig());
  }, []);

  const handleSaveDelivery = (values, { resetForm }) => {
    if (values.id) {
      updateDeliveryShipments(
        values.id,
        convertDeliveryToApi({ ...values, order: id }),
      ).then(response => {
        if (response.success) {
          setDeliveries(prevDeliveries =>
            prevDeliveries.map(delivery => {
              // for the current delivery shipment:

              if (delivery.id === values.id) {
                // I have a tracking number is checked
                return values.number
                  ? {
                      ...values,
                      from: {},
                      to: {},
                      method: '',
                      methodWithId: null,
                    }
                  : {
                      // ...values,
                      id: response.data.id,
                      editable: response.data.editable,
                      number: response.data.track_number,
                      method: response.data.method,
                      from: {
                        address: response.data.from_address,
                        city: response.data.from_city,
                        // company: response.data.from_company,
                        companyName: response.data.from_company ?? '',
                        country: response.data.from_country,
                        country_name: response.data.from_country_name,
                        name: response.data.from_name,
                        email: response.data.from_email,
                        phone: response.data.from_phone,
                        // postal_code: response.data.from_postal_code,
                        code: response.data.from_postal_code,
                        state: response.data.from_state,
                      },
                      to: {
                        address: response.data.to_address,
                        city: response.data.to_city,
                        // company: response.data.to_company,
                        companyName: response.data.to_company ?? '',
                        country: response.data.to_country,
                        country_name: response.data.to_country_name,
                        name: response.data.to_name,
                        email: response.data.to_email,
                        phone: response.data.to_phone,
                        // postal_code: response.data.to_postal_code,
                        code: response.data.to_postal_code,
                        state: response.data.to_state,
                      },
                      comment: response.data.comment,
                      shipmentLabel: response.data.shipment_label_link,
                      provider: response.data.carrier,
                      providerDetail: response.data.carrier,
                      carrier: response.data.carrier,
                    };
              }

              return delivery;
            }),
          );

          handleCloseEditMode();
          showToastSuccess(t('delivery.updated'));
          // resetForm({ values: initialValues });
          return;
        }
        showToastError(response.message);
      });
    } else {
      createDeliveryShipments(convertDeliveryToApi({ ...values, order: id }))
        .then(response => {
          if (response.success) {
            setDeliveries(prevDeliveries =>
              prevDeliveries.concat({
                ...values,
                id: response.data.id,
                editable: response.data.editable,
                number: response.data.track_number,
                method: response.data.method,
                from: {
                  // country: response.data.from_country,
                  // city: response.data.from_city,
                  address: response.data.from_address,
                  city: response.data.from_city,
                  // company: response.data.from_company,
                  companyName: response.data.from_company ?? '',
                  country: response.data.from_country,
                  country_name: response.data.from_country_name,
                  name: response.data.from_name,
                  email: response.data.from_email,
                  phone: response.data.from_phone,
                  // postal_code: response.data.from_postal_code,
                  code: response.data.from_postal_code,
                  state: response.data.from_state,
                },
                to: {
                  // country: response.data.to_country,
                  // city: response.data.to_city,
                  address: response.data.to_address,
                  city: response.data.to_city,
                  // company: response.data.to_company,
                  companyName: response.data.to_company ?? '',
                  country: response.data.to_country,
                  country_name: response.data.to_country_name,
                  name: response.data.to_name,
                  email: response.data.to_email,
                  phone: response.data.to_phone,
                  // postal_code: response.data.to_postal_code,
                  code: response.data.to_postal_code,
                  state: response.data.to_state,
                },
                comment: response.data.comment,
                shipmentLabel: response.data.shipment_label_link,
                provider: response.data.carrier,
                providerDetail: response.data.carrier,
                carrier: response.data.carrier,
              }),
            );
            showToastSuccess(t('delivery.created'));
            resetForm({ values: initialValues });
            return;
          }
          showToastError(response.message);
        })
        .catch(err => showToastError(err));
    }
  };

  async function validate(values) {
    console.log('validate: values: ', values);

    let error = {};
    const provider = values.provider?.carrier ?? values.provider;

    if (provider !== UPS_PROVIDER_NAME) {
      if (values.hasTrackNumber && !values.number) {
        error.number = t('validation.required');
      }
    }

    // UPS

    if (provider === UPS_PROVIDER_NAME) {
      if (values.hasTrackNumber && !values.number) {
        error.number = t('validation.required');
      }

      if (!values.hasTrackNumber && !values.method) {
        error.method = t('validation.required');
      }

      // from
      let fromErrors = {};

      if (!values.hasTrackNumber && !values.from_addressSelector) {
        console.log('from_addressSelector: ', values.from_addressSelector);
        fromErrors.select_address = t('validation.required');
      } else {
        if (!values.hasTrackNumber && !values.from.name) {
          fromErrors.name = t('validation.required');
        }
        if (!values.hasTrackNumber && values.from.phone?.length < 8) {
          fromErrors.phone = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.from.email) {
          fromErrors.email = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.from.code) {
          fromErrors.code = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.from.country) {
          fromErrors.country = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.from.city) {
          fromErrors.city = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.from.address) {
          fromErrors.address = t('validation.required');
        }
      }
      if (Object.values(fromErrors).length > 0) {
        error.from = fromErrors;
      }

      // to
      let toErrors = {};

      if (!values.hasTrackNumber && !values.to_addressSelector) {
        toErrors.select_address = t('validation.required');
      } else {
        if (!values.hasTrackNumber && !values.to.name) {
          toErrors.name = t('validation.required');
        }
        if (!values.hasTrackNumber && values.to.phone?.length < 8) {
          toErrors.phone = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.to.email) {
          toErrors.email = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.from.code) {
          toErrors.code = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.to.country) {
          toErrors.country = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.to.city) {
          toErrors.city = t('validation.required');
        }
        if (!values.hasTrackNumber && !values.to.address) {
          toErrors.address = t('validation.required');
        }
      }

      if (Object.values(toErrors).length > 0) {
        error.to = toErrors;
      }
    }
    return Object.values(error) ? error : null;
  }

  const formikDelivery = useFormik({
    initialValues: {
      ...delivery,
      hasTrackNumber: !!delivery.number,
      from_addressSelector: !Object.values(delivery.from).every(
        x => x === null || x === '',
      )
        ? 'selected-address-from'
        : null,
      to_addressSelector: !Object.values(delivery.from).every(
        x => x === null || x === '',
      )
        ? 'selected-address-to'
        : null,
    },
    enableReinitialize: true,
    validate: validate,
    validateOnChange: false,
    onSubmit: handleSaveDelivery,
  });

  const formikDeliveryCarrier =
    formikDelivery.values?.provider?.carrier ?? formikDelivery.values?.provider;
  const formikDeliveryMethod =
    formikDelivery.values.method?.method ?? formikDelivery.values?.method;

  const handleClearDeliveryForm = () => {
    formikDelivery.resetForm({ values: initialValues });

    if (index !== undefined) {
      handleCloseEditMode();
    }
  };

  const handleChangeDeliveryProvider = provider => {
    formikDelivery.setFieldValue('provider', provider?.value ?? null);
    formikDelivery.setFieldValue('providerDetail', provider ?? null);

    if (provider !== UPS_PROVIDER_NAME) {
      formikDelivery.setFieldValue('method', null);
    }

    if (!provider) {
      formikDelivery.resetForm({ values: initialValues });
    }
  };

  const handleChangeTrack = () => {
    if (formikDelivery.values?.hasTrackNumber) {
      formikDelivery.setFieldValue('hasTrackNumber', false);
      formikDelivery.setFieldValue('number', '');
    } else {
      formikDelivery.setFieldValue('hasTrackNumber', true);
    }
  };

  return (
    <form onSubmit={formikDelivery.handleSubmit}>
      <FormGroup row className="align-items-center mb-4">
        {deliveries?.length > 0 && (
          <Col xs={12}>
            <hr className="my-4" />
          </Col>
        )}
        <Col lg={3}>
          <Label className="pt-0 m-0">{t('delivery.serviceProvider')}</Label>
        </Col>

        <Col lg={9}>
          <Select
            placeholder={t('delivery.serviceProviderPlaceholder')}
            isClearable
            classNamePrefix="select2-selection"
            options={[EMPTY_VALUE, ...DELIVERY_METHODS]}
            value={
              DELIVERY_METHODS.find(
                delivery => delivery.value === formikDeliveryCarrier,
              ) ?? null
            }
            menuPlacement="auto"
            onChange={handleChangeDeliveryProvider}
            invalid={formikDelivery.errors?.provider}
          />
        </Col>
      </FormGroup>

      {formikDeliveryCarrier && (
        <>
          <FormGroup row className="align-items-center mb-4">
            <Col lg={3}>
              <Label className="m-0">{t('delivery.haveTracking')}</Label>
            </Col>
            <Col xs={9}>
              <Label className="m-0">
                <Input
                  type="checkbox"
                  onChange={handleChangeTrack}
                  checked={formikDelivery.values.hasTrackNumber}
                  className="me-2"
                />
              </Label>
            </Col>
          </FormGroup>

          {formikDelivery.values?.hasTrackNumber && (
            <FormGroup row className="align-items-center mb-4">
              <Col lg={3}>
                <Label className="m-0">{t('delivery.trackNumber')}</Label>
              </Col>

              <Col lg={9}>
                <StyledInput
                  placeholder={t('delivery.trackNumberPlaceholder')}
                  name="number"
                  value={formikDelivery.values.number}
                  onChange={formikDelivery.handleChange}
                  invalid={formikDelivery.errors?.number}
                  // onBlur={formikDelivery.handleBlur}
                />
                {formikDelivery.errors?.number && (
                  <StyledError className="text-danger mt-1">
                    {formikDelivery.errors.number}
                  </StyledError>
                )}
              </Col>
            </FormGroup>
          )}

          {!formikDelivery.values?.hasTrackNumber &&
            formikDeliveryCarrier === UPS_PROVIDER_NAME && (
              <>
                <FormGroup row className="align-items-center mb-4">
                  <Col lg={3}>
                    <Label>{t('delivery.method')}</Label>
                  </Col>

                  <Col lg={9}>
                    <DeliveryMethodSelect
                      // onBlur={formikDelivery.handleBlur}
                      name="method"
                      handleChange={option => {
                        formikDelivery.setFieldValue(
                          'method',
                          option?.value ?? null,
                        );
                        formikDelivery.setFieldValue(
                          'methodWithId',
                          option?.item?.id ?? null,
                        );
                        formikDelivery.setFieldValue(
                          'methodDetail',
                          option?.item ?? null,
                        );
                      }}
                      value={formikDeliveryMethod}
                      invalid={
                        formikDelivery.errors?.method ||
                        // && formikDelivery.touched.method
                        (formikDelivery.submitCount > 0 &&
                          !formikDeliveryMethod)
                      }
                    />
                    {formikDelivery.errors?.method && (
                      <StyledError className="text-danger mt-1">
                        {formikDelivery.errors.method}
                      </StyledError>
                    )}
                  </Col>
                </FormGroup>

                {!!formikDeliveryMethod && (
                  <>
                    <DeliveryForm
                      formik={formikDelivery}
                      prefix="from"
                      title="delivery.from"
                      className="mb-4"
                    />

                    <DeliveryForm
                      formik={formikDelivery}
                      prefix="to"
                      title="delivery.to"
                    />
                    <FormGroup row className="my-4">
                      <Col xs={8}>
                        <Label className="m-0">
                          <Input
                            type="checkbox"
                            name="issue_now"
                            onChange={event =>
                              formikDelivery.setFieldValue(
                                'issue_now',
                                event.target.checked,
                              )
                            }
                            checked={formikDelivery.values.issue_now}
                            className="me-2"
                          />
                          {t('delivery.issueNow')}
                        </Label>
                      </Col>
                    </FormGroup>
                  </>
                )}
              </>
            )}

          <FormGroup row className="align-items-center mb-4">
            <Col lg={3}>
              <Label className="m-0">{t('common.comment')}</Label>
            </Col>

            <Col lg={9}>
              <Input
                placeholder={t('common.commentPlaceholder')}
                type="textarea"
                name="comment"
                style={{
                  resize: 'none',
                }}
                onChange={formikDelivery.handleChange}
                value={formikDelivery.values.comment}
              />
            </Col>
          </FormGroup>

          <div className="d-flex ">
            <Col className="p-0 m-0" lg={3} xs={0}></Col>

            <Col className="d-flex gap-2 ps-2" xs={12} lg={4}>
              <StyledButtonSave
                className="w-50"
                type="submit"
                disabled={!formikDelivery.dirty}
                color="primary"
              >
                {t('common.save')}
              </StyledButtonSave>

              <StyledButtonCancel
                onClick={handleClearDeliveryForm}
                className="w-50"
              >
                {t('common.cancel')}
              </StyledButtonCancel>
            </Col>
          </div>
        </>
      )}
    </form>
  );
};

import React from 'react';
import { useFormik } from 'formik';

import { Button } from 'reactstrap';

// Redux
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LOGIN_VALIDATOR } from 'forms/validators';
import { loginUser } from '../../store/actions';

import { useTranslation } from 'react-i18next';
import FormikInput from 'components/Common/FormikInput';
import { BlueLink, ShadowCard } from 'components/Common';
import styled from 'styled-components';
import NonAuthLayout from 'components/NonAuthLayout';

export const StyledTextHead = styled.h3`
  color: ${({ theme }) => theme.colors.mainText};
  font-size: 32px;
  line-height: 42px;
  font-weight: 600;
`;

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { username } = useParams();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validateOnChange: true,
    validationSchema: LOGIN_VALIDATOR,
    onSubmit: values =>
      dispatch(
        loginUser({
          user: { username: values.login, password: values.password },
          history,
        }),
      ),
  });

  const { errors, values, handleChange, handleSubmit, touched } = formik;

  if (!values.login) {
    username && formik.setFieldValue('login', username);
  }

  return (
    <NonAuthLayout>
      <ShadowCard className="w-100" bodyStyle={{ padding: 48 }}>
        <StyledTextHead className="mb-4">{t('auth.logIn')}</StyledTextHead>

        <form onSubmit={handleSubmit}>
          <FormikInput
            label={'auth.username'}
            name="login"
            groupStyle="mb-0"
            placeholder="auth.enterUserName"
            value={values.login}
            onBlur={formik.handleBlur}
            handleChange={handleChange}
            errors={errors.login && touched.login}
          />

          <FormikInput
            label={t('auth.password')}
            name="password"
            groupStyle="mb-0"
            type="password"
            onBlur={formik.handleBlur}
            value={values.password}
            handleChange={handleChange}
            errors={errors.password && touched.password}
            placeholder={t('auth.passwordPlaceholder')}
            link={
              <div className="d-flex">
                <BlueLink
                  to="/forgot-password"
                  className="fs-14 fw-500 text-decoration-underline"
                >
                  {t('auth.resetPassword')}
                </BlueLink>
              </div>
            }
          />

          <Button
            color="primary"
            className="mt-4"
            style={{ padding: '12px 0' }}
            block
            type="submit"
          >
            {t('auth.login')}
          </Button>

          <Button
            className="bg-transparent border-0 text-primary mt-4 text-decoration-underline"
            block
            onClick={() => history.push('/register')}
            type="link"
          >
            {t('auth.signUp')}
          </Button>
        </form>
      </ShadowCard>
    </NonAuthLayout>
  );
};

export default Login;

import { DATE_FORMAT_TIME } from 'common/data/constants';
import { formatDate } from 'helpers/formatDate';
import { converTextToHue, convertHslToRgb } from 'helpers/styles/statusToColor';
import React from 'react';
import Avatar from 'react-avatar';
import { Button, Col } from 'reactstrap';
import styled, { css } from 'styled-components';

const StyledChatCard = styled(Button)`
  cursor: pointer;
  border-radius: 0px !important;
  padding: 16px;

  ${({ theme }) => css`
    background-color: ${theme.colors.transparent};
    color: ${theme.colors.mainText};

    &:hover {
      background-color: ${theme.colors.gray100};
      color: ${theme.colors.mainText};
    }

    &:active {
      background-color: ${theme.colors.transparent} !important;
      color: ${theme.colors.mainText}!important;
    }
  `}

  ${({ $active, theme }) =>
    $active &&
    css`
      padding: 24px 16px;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      &:hover {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      }

      &:active {
        background-color: ${theme.colors.primary} !important;
        color: ${theme.colors.white} !important;
      }
    `}
`;

const StyledButtonMessage = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray400};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`;

export const StyledAvatar = styled(Avatar)`
  ${({ name }) => {
    const hue = converTextToHue(name);
    const colorMain = convertHslToRgb(hue, 40, 55);
    const colorSecond = convertHslToRgb(hue, 20, 95);

    return css`
      background: linear-gradient(
        34.4deg,
        ${colorMain} 13.06%,
        ${colorSecond}
      ) !important;
    `;
  }}
`;

export const ChatCard = ({
  name,
  date,
  active,
  isNewMessage,
  handleSelectMessage,
}) => {
  return (
    <StyledChatCard
      onClick={handleSelectMessage}
      $active={active}
      className="d-flex align-items-center border-0 w-100"
    >
      <StyledAvatar
        name={name}
        size={44}
        className="fs-4 fw-semibold me-2"
        maxInitials={2}
        round
      />

      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        className="d-flex w-100 align-items-center justify-content-between"
      >
        <p
          className="m-0 fs-6 fw-500 text-start"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {name}
        </p>

        <Col
          xs={4}
          className="m-0 fs-14 d-flex fw-normal flex-column align-items-end"
        >
          <span className={!active && 'text-muted'}>
            {formatDate(date, DATE_FORMAT_TIME)}
          </span>

          {!active && isNewMessage && (
            <StyledButtonMessage className="fw-500">1</StyledButtonMessage>
          )}
        </Col>
      </div>
    </StyledChatCard>
  );
};

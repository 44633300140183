import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.input`
  &:indeterminate {
    background-color: #9397a4 !important;
    border-color: #9397a4 !important;
  }
`;

const CHECKBOX_STATES = {
  Checked: 'Checked',
  Indeterminate: 'Indeterminate',
  Empty: 'Empty',
};

export const Checkbox = ({ checked, onChange, ...props }) => {
  const checkboxRef = useRef();

  useEffect(() => {
    if (checked === CHECKBOX_STATES.Checked) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else if (checked === CHECKBOX_STATES.Empty) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    } else if (checked === CHECKBOX_STATES.Indeterminate) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    }
  }, [checked]);

  return (
    <StyledCheckbox
      className="form-check-input"
      type="checkbox"
      ref={checkboxRef}
      onChange={event => {
        onChange(event.target.checked);
      }}
      {...props}
    />
  );
};

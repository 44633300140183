import { Badge } from 'components/Common';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { typeColor } from 'helpers/styles/typeColor';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import styled, { css } from 'styled-components';
import { ORDER_STATUS } from '../../common/data/constants';
import classNames from 'classnames';

const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 2px 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  border: none;

  ${({ $type, theme }) => {
    switch ($type) {
      case typeColor.primary:
        return css`
          color: ${theme.colors.primary}!important;
          background-color: ${theme.colors.blue100}!important;
        `;
      case typeColor.danger:
        return css`
          color: ${theme.colors.red500}!important;
          background-color: ${theme.colors.red200}!important;
        `;
      case typeColor.dark:
        return css`
          color: ${theme.colors.mainText}!important;
          background-color: ${theme.colors.gray100}!important;
        `;
      case typeColor.info:
        return css`
          color: ${theme.colors.primary}!important;
          background-color: ${theme.colors.blue100}!important;
        `;
      case typeColor.secondary:
        return css`
          color: ${theme.colors.mainText}!important;
          background-color: ${theme.colors.gray100}!important;
        `;
      case typeColor.success:
        return css`
          color: ${theme.colors.green500}!important;
          background-color: ${theme.colors.green100}!important;
        `;
      case typeColor.warning:
        return css`
          color: ${theme.colors.primary}!important;
          background-color: ${theme.colors.blue100}!important;
        `;

      default:
        break;
    }
  }}
`;

export const ChangeStatus = ({
  value,
  placeholder = 'common.select',
  onChange,
  statuses = ORDER_STATUS,
  className = 'justify-content-center ',
  disabled,
}) => {
  const { t } = useTranslation();
  const applicantStatus = useMemo(() => {
    if (typeof value === 'object') {
      return statuses.find(item => item.value === value?.value) ?? null;
    }

    return statuses.find(item => item.value === value) ?? null;
  }, [value, statuses]);

  return disabled ? (
    <div className={classNames('d-flex align-items-center', className)}>
      <Badge
        text={applicantStatus?.label}
        color={convertStatusToColor(applicantStatus?.value)}
      />
    </div>
  ) : (
    <UncontrolledDropdown className={className}>
      <StyledDropdownToggle
        disabled={disabled}
        $type={convertStatusToColor(applicantStatus?.value)}
        onClick={event => event.stopPropagation()}
        caret
      >
        {t(applicantStatus?.label ?? placeholder)}
      </StyledDropdownToggle>

      <DropdownMenu>
        {statuses
          .filter(status => status.value !== value)
          .map(status => (
            <DropdownItem
              key={status.value}
              onClick={event => {
                event.stopPropagation();
                onChange(status);
              }}
            >
              {t(status.label)}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

import {
  ADD_APPLICANT_SUCCESS,
  CREATE_CLIENT,
  CREATE_CLIENT_FAIL,
  CREATE_CLIENT_SUCCESS,
  GET_CLIENTS,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_SUCCESS,
  GET_CLIENT_DETAIL,
  GET_CLIENT_DETAIL_FAIL,
  GET_CLIENT_DETAIL_SUCCESS,
  GET_ORGANIZATION_INFO,
  GET_CLIENT_INFO_SUCCESS,
  GET_CLIENT_INFO_FAIL,
  GET_INDIVIDUAL_INFO,
  CHANGE_APPLICANT,
  GET_CLIENT_FILTER,
  GET_CLIENT_FILTER_SUCCESS,
  GET_CLIENT_FILTER_FAIL,
  CHANGE_APPLICANT_FAIL,
  CHANGE_APPLICANT_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  DELETE_CLIENT,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_APPLICANT,
  DELETE_CLIENT_APPLICANT_FAIL,
  DELETE_CLIENT_APPLICANT_SUCCESS,
} from './actionTypes';

export const getAllClients = () => ({
  type: GET_CLIENTS,
});

export const getClientsSuccess = clients => ({
  type: GET_CLIENTS_SUCCESS,
  payload: clients,
});

export const updateClient = client => ({
  type: UPDATE_CLIENT,
  payload: client,
});

export const updateClientFail = client => ({
  type: UPDATE_CLIENT_FAIL,
  payload: client,
});

export const updateClientSuccess = client => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client,
});

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
});

export const createClient = params => ({
  type: CREATE_CLIENT,
  payload: params,
});

export const createClientSuccess = client => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: client,
});

export const createClientFail = error => ({
  type: CREATE_CLIENT_FAIL,
  payload: error,
});

export const getClientDetail = params => ({
  type: GET_CLIENT_DETAIL,
  payload: params,
});

export const getClientDetailSuccess = client => ({
  type: GET_CLIENT_DETAIL_SUCCESS,
  payload: client,
});

export const getClientDetailFail = error => ({
  type: GET_CLIENT_DETAIL_FAIL,
  payload: error,
});

export const addApplicantSuccess = applicant => ({
  type: ADD_APPLICANT_SUCCESS,
  payload: applicant,
});

export const getClientInfo = id => ({
  type: GET_ORGANIZATION_INFO,
  payload: id,
});

export const getIndividualInfo = id => ({
  type: GET_INDIVIDUAL_INFO,
  payload: id,
});

export const getClientInfoSuccess = client => ({
  type: GET_CLIENT_INFO_SUCCESS,
  payload: client,
});

export const getClientInfoFail = error => ({
  type: GET_CLIENT_INFO_FAIL,
  payload: error,
});

export const changeApplicant = (
  values,
  currentApplicantId,
  id,
  currentClient,
  onClose,
) => {
  return {
    type: CHANGE_APPLICANT,
    payload: { values, currentApplicantId, id, currentClient, onClose },
  };
};

export const changeApplicantSuccess = applicant => ({
  type: CHANGE_APPLICANT_SUCCESS,
  payload: applicant,
});

export const changeApplicantFail = error => ({
  type: CHANGE_APPLICANT_FAIL,
  payload: error,
});

export const getClientFilter = data => ({
  type: GET_CLIENT_FILTER,
  payload: data,
});

export const getClientFilterSuccess = data => ({
  type: GET_CLIENT_FILTER_SUCCESS,
  payload: data,
});

export const getClientFilterFail = error => ({
  type: GET_CLIENT_FILTER_FAIL,
  payload: error,
});

export const deleteClient = params => ({
  type: DELETE_CLIENT,
  payload: params,
});

export const deleteClientSuccess = client => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: client,
});

export const deleteClientFail = error => ({
  type: DELETE_CLIENT_FAIL,
  payload: error,
});

// ClientApplicants

export const deleteClientApplicant = params => ({
  type: DELETE_CLIENT_APPLICANT,
  payload: params,
});

export const deleteClientApplicantSuccess = applicant => ({
  type: DELETE_CLIENT_APPLICANT_SUCCESS,
  payload: applicant,
});

export const deleteClientApplicantFail = error => ({
  type: DELETE_CLIENT_APPLICANT_FAIL,
  payload: error,
});

import React from 'react';
import { Col, Label as ReactstrapLabel } from 'reactstrap';
import { Label } from 'components/Services/ServiceForm';
import { convertPriceToAPI } from 'helpers/converters';
import { useFormikContext } from 'formik';
import { formatCurrency } from 'helpers/formatCurrency';
import { useTranslation } from 'react-i18next';

const getPriceTotal = prices =>
  prices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

export const InvoicesTotalPrice = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <>
      <hr className="w-100 my-4" />

      <Col className={'p-0 d-flex flex-wrap mb-0'} xs={12}>
        <ReactstrapLabel className="text-muted fs-14" xs={3} lg={2}>
          {t('common.total')}
        </ReactstrapLabel>

        <Col xs={9} lg={10} className="d-flex align-items-center">
          <Label className="m-0 fs-4 fw-semibold">
            {formatCurrency(
              getPriceTotal(
                formik.values.invoices.map(invoices =>
                  convertPriceToAPI(invoices.bill_total_price_euro),
                ),
              ),
            )}
          </Label>
        </Col>
      </Col>
    </>
  );
};

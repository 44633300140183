import { CardTitle, ShadowCard } from 'components/Common';
import React from 'react';
import { OrderTypeEditDates } from './OrderTypeEditDates';

export const OrderTypeDatesCard = () => {
  return (
    <ShadowCard
      id="datesSettings"
      className="mt-4"
      bodyStyle={{ padding: '24px' }}
    >
      <CardTitle title="orderTypes.dates" />

      <OrderTypeEditDates />
    </ShadowCard>
  );
};

import { get, post, put } from 'helpers/api_helper';
import authHeader from 'helpers/jwt-token-access/auth-token-header';
import { ORDERS_URL } from 'helpers/url-helpers/admin';
import { APPLICANTS } from 'helpers/url-helpers/manager';

// Applicants
export const fetchApplicants = params =>
  get(APPLICANTS, {
    headers: authHeader(),
    params,
  });
export const createApplicant = applicant =>
  post(APPLICANTS, applicant, {
    headers: authHeader(),
  });
export const fetchApplicant = id =>
  get(`${APPLICANTS}${id}/`, {
    headers: authHeader(),
  });
export const putApplicant = ({ id, applicant }) =>
  put(`${APPLICANTS}${id}/`, applicant, {
    headers: authHeader(),
  });
export const inviteApplicantRequest = id =>
  post(`${APPLICANTS}${id}/invite/`, null, {
    headers: authHeader(),
  });

export const confirmOrder = id =>
  post(`${ORDERS_URL}${id}/confirm/`, null, {
    headers: authHeader(),
  });

export const checkOrder = id =>
  post(`${ORDERS_URL}${id}/check/`, null, {
    headers: authHeader(),
  });

export const deleteApplicantRequest = id =>
  post(`${APPLICANTS}${id}/archive/`, _, { headers: authHeader() });

import {
  TOGGLE_LEFT_SIDEBAR,
  OPEN_LEFT_SIDEBAR,
  CLOSE_LEFT_SIDEBAR,
} from './actionTypes';

export const toggleLeftSidebar = () => ({
  type: TOGGLE_LEFT_SIDEBAR,
  payload: null,
});

export const openLeftSidebar = () => ({
  type: OPEN_LEFT_SIDEBAR,
  payload: null,
});

export const closeLeftSidebar = () => ({
  type: CLOSE_LEFT_SIDEBAR,
  payload: null,
});

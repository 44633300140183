import { ShadowCard, CardTitle } from 'components/Common';
import InvoiceInfo from 'components/Invoices/InvoiceInfo';
import React from 'react';

export const InvoiceInformationCard = ({ invoiceDetails }) => (
  <ShadowCard bodyStyle={{ padding: '24px' }} id="information">
    <CardTitle title="common.information" />

    <InvoiceInfo invoiceDetails={invoiceDetails} />
  </ShadowCard>
);

import { PaginationLm, ShadowCard } from 'components/Common';
import { Layout } from 'components/Layout';
import { StyledTable } from 'components/Table/StyledTable';
import { useLoader } from 'hooks/useLoader';
import { useModal } from 'hooks/useModal';
import { useTable } from 'hooks/useTable';
import { getDocumentOnClick } from 'helpers/downloadDocument';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { deleteDocument, getDocuments } from 'store/documents/actions';
import { ButtonControlTable } from 'components/Tariffs/ButtonControlTable';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { ViewCountry } from 'components/ViewCountry/ViewCountry';
import { UpdateDocumentModal } from './UpdateDocumentModal';
import { DownloadLink } from 'components/DownloadLink';

const DOCUMENTS_HEADER = [
  'documents.document',
  'client.country',
  'common.nationality',
  '',
];

export const getDocumentsState = state => state.documents.documents;

export const Documents = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { isLoading, count, documents } = useSelector(getDocumentsState);
  const { Loader, notFound } = useLoader(isLoading);
  const { handleChangeSearchParam, searchState, showPagination } =
    useTable(count);
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();
  const [
    showDocumentUpdateModal,
    handleCloseModalUpdate,
    handleOpenModalUpdate,
  ] = useModal();

  useEffect(() => {
    dispatch(
      getDocuments({
        page: searchState.page,
        page_size: searchState.perPage.value,
      }),
    );
  }, [searchState]);

  const getDocumentsActions = document => [
    {
      id: 1,
      title: 'documents.updateDocument',
      function: () => handleOpenModalUpdate(document),
    },
    {
      id: 2,
      title: 'documents.download',
      function: () => getDocumentOnClick(document, document.original_name),
    },
    {
      id: 3,
      title: 'common.delete',
      function: () => handleOpenModal(document),
    },
  ];

  const handleDeleteDocument = id => {
    dispatch(deleteDocument({ id, handleCloseModal }));
  };

  return (
    <Layout title="common.documents">
      <ShadowCard>
        {notFound('documents.notFound', !documents.length)}

        {Loader && (
          <Row className="d-flex p-5 align-items-center justify-content-center">
            {Loader}
          </Row>
        )}

        {isOpen && (
          <ModalConfirmDelete
            isOpenModal={isOpen}
            textHeader="documents.delete"
            textBody="documents.deleteConfirm"
            handleCloseModal={handleCloseModal}
            handleDelete={() => handleDeleteDocument(isOpen.id)}
          />
        )}

        {showDocumentUpdateModal && (
          <UpdateDocumentModal
            document={showDocumentUpdateModal}
            isOpenModal={!!showDocumentUpdateModal}
            handleCloseModal={handleCloseModalUpdate}
          />
        )}

        {documents && documents.length > 0 && !isLoading && (
          <div className="table-responsive">
            <StyledTable>
              <thead>
                {DOCUMENTS_HEADER.map(item => (
                  <th key={item}>{t(item)}</th>
                ))}
              </thead>

              <tbody>
                {documents.map(document => (
                  <tr key={document.id}>
                    <td>
                      <DownloadLink
                        filename={document.original_name}
                        url={document.url}
                      >
                        {document.document.name[i18n.language]}
                      </DownloadLink>
                    </td>

                    <td>
                      <ViewCountry id={document.country} />
                    </td>

                    <td>
                      <ViewCountry id={document.nationality} />
                    </td>

                    <td>
                      <ButtonControlTable
                        actions={getDocumentsActions(document)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>

            {showPagination && (
              <PaginationLm
                active={searchState.page}
                count={count}
                perPage={searchState.perPage.value}
                selectPage={page => handleChangeSearchParam('page', page)}
              />
            )}
          </div>
        )}
      </ShadowCard>
    </Layout>
  );
};

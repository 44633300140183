import {
  GET_VISA_TYPES,
  GET_VISA_TYPES_SUCCESS,
  GET_VISA_TYPES_FAIL,
  GET_COUNTRIES,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
  GET_ADDRESSES,
  GET_ADDRESSES_FAIL,
  GET_ADDRESSES_SUCCESS,
  CLEAR_ADDRESS_LIST,
  GET_ADDRESS,
  GET_ADDRESS_FAIL,
  GET_ADDRESS_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAIL,
  GET_REFERENCES_CONFIG,
  GET_REFERENCES_CONFIG_SUCCESS,
  GET_REFERENCES_CONFIG_FAIL,
} from './actionTypes';

// User List
export const getVisaTypes = query => ({
  type: GET_VISA_TYPES,
  payload: query,
});

export const getVisaTypesSuccess = visaTypes => ({
  type: GET_VISA_TYPES_SUCCESS,
  payload: visaTypes,
});

export const getVisaTypesFail = error => ({
  type: GET_VISA_TYPES_FAIL,
  payload: error,
});

// Countries
export const getCountries = () => ({
  type: GET_COUNTRIES,
});

export const getCountriesSuccess = countries => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
});

export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
});

// Addresses
export const getAddresses = param => ({
  type: GET_ADDRESSES,
  payload: param,
});

export const getAddressesSuccess = addresses => ({
  type: GET_ADDRESSES_SUCCESS,
  payload: addresses,
});

export const getAddressesFail = error => ({
  type: GET_ADDRESSES_FAIL,
  payload: error,
});

export const getAddress = param => ({
  type: GET_ADDRESS,
  payload: param,
});

export const getAddressSuccess = address => ({
  type: GET_ADDRESS_SUCCESS,
  payload: address,
});

export const getAddressFail = error => ({
  type: GET_ADDRESS_FAIL,
  payload: error,
});

export const clearAddressList = () => ({
  type: CLEAR_ADDRESS_LIST,
});

export const getRegions = param => ({
  type: GET_REGIONS,
  payload: param,
});

export const getRegionsSuccess = address => ({
  type: GET_REGIONS_SUCCESS,
  payload: address,
});

export const getRegionsFail = error => ({
  type: GET_REGIONS_FAIL,
  payload: error,
});

export const getReferencesConfig = param => ({
  type: GET_REFERENCES_CONFIG,
  payload: param,
});

export const getReferencesConfigSuccess = payload => ({
  type: GET_REFERENCES_CONFIG_SUCCESS,
  payload,
});

export const getReferencesConfigFail = error => ({
  type: GET_REFERENCES_CONFIG_FAIL,
  payload: error,
});

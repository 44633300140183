import { authLink } from 'helpers/jwt-token-access/auth-token-header';
import React, { useCallback } from 'react';
import { Card, CardImg, Col } from 'reactstrap';
import styled from 'styled-components';

const StyledDocumentPlace = styled.div`
  background-color: ${({ theme }) => theme.colors.transparent};
  padding: 8px 0px;
  border-radius: 8px;
  border: none;

  p {
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.gray400};
    border-radius: 50%;
  }

  i {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledCardImage = styled(CardImg)`
  border-radius: 8px;
`;

export const ChatDocument = ({ document }) => {
  const checkImage = useCallback(nameDoc => {
    const name = nameDoc.toLowerCase();

    return (
      name.endsWith('.png') || name.endsWith('.jpg') || name.endsWith('.jpeg')
    );
  }, []);

  return (
    <Col className="d-flex w-100" key={document.id}>
      {checkImage(document.original_name) ? (
        <Card className="border-0 w-75 mt-2">
          <a href={authLink(document.url)} target="_blank" rel="noreferrer">
            <StyledCardImage
              src={authLink(document.url)}
              alt={document.original_name}
            />
          </a>
        </Card>
      ) : (
        <StyledDocumentPlace className="d-flex align-items-center w-100">
          <a
            href={authLink(document.url)}
            className="w-100 d-flex align-items-center "
            target="_blank"
            rel="noreferrer"
          >
            <p className="mb-0 me-2 d-flex align-items-center">
              <i className="bx bxs-file-blank" />
            </p>

            {document.original_name}
          </a>
        </StyledDocumentPlace>
      )}
    </Col>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { EMPTY_VALUE_WITH_LABEL } from 'common/data/constants';
import { Select } from 'components/Common';
import styled from 'styled-components';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getVisumIOIntegrationListRequest } from 'helpers/api-requests/admin';

const StyledHelpText = styled(Label)`
  font-size: 12px;
  font-weight: 500;
`;

export const OrderTypeVisumIOSelector = ({ value, ...props }) => {
  const { t } = useTranslation();
  const [visumIOIntegrations, setVisumIOIntegrations] = useState([]);

  useEffect(() => {
    getVisumIOIntegrationListRequest()
      .then(response => {
        const results = response?.data?.results ?? [];
        if (results.length > 0) {
          setVisumIOIntegrations(results);
        }
      })
      .catch(err => console.log(err));
  }, []);

  const options = useMemo(() => {
    return visumIOIntegrations.map(item => ({
      value: item.id,
      label: item.document_type,
    }));
  }, [visumIOIntegrations]);

  const val = useMemo(() => {
    return options.find(item => item.value === value);
  }, [options, value]);

  return (
    <>
      <Select
        value={val}
        options={[EMPTY_VALUE_WITH_LABEL, ...options]}
        className="w-100"
        classNamePrefix="select2-selection"
        {...props}
      />
      <StyledHelpText>
        {t('orderTypes.visumIOIntegrationHelpText')}
      </StyledHelpText>
    </>
  );
};

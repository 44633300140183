import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { defaultTheme } from './theme';
import { GlobalStyle } from 'GlobalStyles';

import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';

import './i18n';

const app = (
  <Provider store={store}>
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

(() => {
  const preloader = document.getElementById('preloader');
  preloader.classList.add('preloader');
  preloader.classList.add('preloader__notVisible');

  setTimeout(() => {
    preloader.classList.add('preloader__removed');
  }, 300);
})();

serviceWorker.unregister();

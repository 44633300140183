import styled, { css } from 'styled-components';
import SelectComponent from 'react-select';
import SelectCreateble from 'react-select/creatable';

export const Select = styled(SelectComponent)`
  .select2-selection {
    &__indicators {
      padding: 0px;

      span {
        background-color: transparent;
      }
    }

    &__input {
      margin-left: 1px;
    }

    &__multi-value {
      background-color: ${({ theme }) => theme.colors.green500};
      border-radius: 20px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 8px;
      margin-right: 5px;

      &__label {
        padding: 0px;
        color: ${({ theme }) => theme.colors.white};
      }
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.placeholders}!important;
      opacity: 0.5;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__indicator {
      padding-right: 0;
    }

    &__control {
      border-radius: 8px;
      padding: 2px ${({ theme }) => theme.spacing.m};
      border-color: ${({ theme }) => theme.colors.gray200};

      svg {
        fill: ${({ theme }) => theme.colors.mainText};
      }
      ${({ invalid, theme }) =>
        invalid &&
        css`
          border-color: ${theme.colors.danger};
          &.select2-selection__control--is-focused {
            box-shadow: 0 0 0 0.15rem rgb(244 106 106 / 25%);
          }

          :hover {
            border-color: ${theme.colors.danger};
          }
        `};
    }

    &__single-value {
      font-size: 14px;
      margin: 0;
      margin-left: 2px;
    }

    &__value-container {
      padding: 0px;
    }
  }
`;

export const StyledSelectCreatable = styled(SelectCreateble)`
  .select2-selection {
    &__indicators {
      padding: 0px;

      span {
        background-color: transparent;
      }
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.placeholders}!important;
      opacity: 0.5;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__indicator {
      padding-right: 0;
    }

    &__control {
      border-radius: 8px;
      padding: 2px ${({ theme }) => theme.spacing.m};
      border-color: ${({ theme }) => theme.colors.gray200};

      svg {
        fill: ${({ theme }) => theme.colors.mainText};
      }
      ${({ invalid, theme }) =>
        invalid &&
        css`
          border-color: ${theme.colors.danger};
          &.select2-selection__control--is-focused {
            box-shadow: 0 0 0 0.15rem rgb(244 106 106 / 25%);
          }

          :hover {
            border-color: ${theme.colors.danger};
          }
        `};
    }

    &__single-value {
      font-size: 14px;
      margin: 0;
    }

    &__value-container {
      padding: 0px;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplicantsOrganizationList,
  getApplicantsUserList,
} from 'store/actions';

import { isUserEmployee, isUserClient } from 'helpers/jwt_helper';
import { StyledSelectCreatable } from 'components/Common';
import { fetchApplicants } from 'helpers/api-requests/manager';
import { fetchEmployee } from 'helpers/api-requests/employee';

import { Select } from 'components/Common/Select';

export const SelectApplicant = ({
  clientType,
  clientId,
  placeholder,
  selectedApplicants,
  handleSelectApplicant,
  handleChangeTextInput,
  className,
  value,
  invalid,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [applicants, setApplicants] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const users = useSelector(state => state.admin.rest.applicants.users);
  const organizations = useSelector(
    state => state.admin.rest.applicants.organizations,
  );

  useEffect(() => {
    if (clientType === 'legal' && !isUserClient) {
      dispatch(
        getApplicantsOrganizationList({
          organization_pk: clientId,
        }),
      );
      return;
    }

    if (clientType === 'individual' && !isUserClient) {
      dispatch(
        getApplicantsUserList({
          user_pk: clientId,
        }),
      );
    }

    if (isUserClient) {
      fetchApplicants({ page_size: 1000, page: 1 }).then(response => {
        if (response.success) {
          setApplicants(response.data.results);
        }
      });
    }

    if (isUserEmployee) {
      fetchEmployee().then(response => {
        if (response.success) {
          setApplicants([response.data]);
        }
      });
    }
  }, [clientType, clientId, isUserClient, isUserEmployee]);

  useEffect(() => {
    setFilteredItems(
      flatten(
        (isUserClient || isUserEmployee
          ? applicants
          : (clientType === 'legal' ? organizations : users) ?? []
        )?.map(user => {
          return user.passports
            .filter(
              passport =>
                passport.validity.is_valid &&
                !selectedApplicants.some(
                  applicant => applicant?.applicantDetail?.id === user.id,
                ),
            )
            .map(passport => {
              return { ...user, passport, passports: user.passports };
            });
        }),
      ),
    );
  }, [clientType, organizations, users, selectedApplicants, applicants]);

  const convertOptions = filteredItems =>
    filteredItems.map(applicant => ({
      value: `${applicant.id}`,
      label: `${applicant.first_name} ${applicant.last_name}, ${applicant.passport.country.country.name}, ${applicant.passport.number}, ${applicant.passport.end_date}`,
      user: applicant,
    }));

  const handleCreate = inputValue => {
    handleChangeTextInput(inputValue);
  };

  return !isUserEmployee ? (
    <StyledSelectCreatable
      isClearable
      classNamePrefix="select2-selection"
      className={className}
      value={typeof value === 'string' ? { value: value, label: value } : null}
      onCreateOption={handleCreate}
      placeholder={t(placeholder)}
      options={convertOptions(filteredItems)}
      onChange={option => handleSelectApplicant(option ? option.user : null)}
      invalid={invalid}
    />
  ) : (
    <Select
      classNamePrefix="select2-selection"
      value={typeof value === 'string' ? { value: value, label: value } : null}
      onChange={option => handleSelectApplicant(option ? option.user : null)}
      options={convertOptions(filteredItems)}
      invalid={invalid}
    />
  );
};

export const formatCurrency = value => {
  if (value !== 0 && !value) {
    return '-';
  }

  const numberValue =
    typeof value === 'number' ? Number(value / 100) : Number(value);

  return numberValue.toLocaleString('en', {
    style: 'currency',
    currency: 'EUR',
  });
};

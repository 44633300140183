import { getUserRole } from 'helpers/jwt_helper';

// APPLICANTS
const getUserClientRole = () => {
  const userRole = getUserRole();

  if (userRole === 'manager') {
    return userRole;
  }

  if (userRole === 'individual') {
    return userRole;
  }
};

export const APPLICANTS = `/${getUserClientRole()}/applicants/`;

import { BlueLink, CardTitle, StyledField } from 'components/Common';
import Loader from 'components/Common/Loader';
import { StyledTable, StyledTableHead } from 'components/Table/StyledTable';
import { ViewCountry } from 'components/ViewCountry/ViewCountry';
import { formatCurrency } from 'helpers/formatCurrency';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { fetchService } from 'store/services/actions';

const ServiceDetailModal = ({ isOpen, handleClose, id, serviceType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, isLoading } = useSelector(state => state.services.service);

  useEffect(() => {
    dispatch(fetchService({ serviceId: id, serviceType }));
  }, []);

  return (
    <Modal centered isOpen={isOpen} toggle={handleClose}>
      {isLoading && data ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <ModalHeader className="border-0 pb-2 p-4" toggle={handleClose}>
            {data?.title}
          </ModalHeader>

          <div className="w-100 ps-4 pe-4">
            <hr className="m-0" />
          </div>

          <ModalBody className="p-4">
            <StyledField className="p-4">
              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">{t('tariffs.name')}</Label>
                </Col>

                <Col xs={6}>
                  <BlueLink
                    to={`/services/${data?.id}/${data?.product_type.type}`}
                  >
                    {data?.title}
                  </BlueLink>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">{t('tariffs.nameDe')}</Label>
                </Col>

                <Col xs={6}>
                  <Label>{data?.name.de}</Label>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">{t('tariffs.nameEn')}</Label>
                </Col>

                <Col xs={6}>
                  <Label>{data?.name.en}</Label>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">
                    {t('services.serviceType')}:{' '}
                  </Label>
                </Col>

                <Col xs={6}>
                  <Label>{data?.product_type.type_display}</Label>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">
                    {t('common.vatEnabled')}:{' '}
                  </Label>
                </Col>

                <Col xs={6}>
                  <Label>
                    <Input
                      type="checkbox"
                      readOnly
                      disabled
                      checked={data?.tax_enabled}
                    />
                  </Label>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">{t('common.country')}: </Label>
                </Col>

                <Col xs={6} xl={2}>
                  <Label>
                    {data?.country ? (
                      <ViewCountry id={data.country} />
                    ) : (
                      t('common.all')
                    )}
                  </Label>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">
                    {t('common.nationality')}:{' '}
                  </Label>
                </Col>

                <Col xs={6}>
                  <Label>
                    {data?.customer_nationality_region?.name ?? t('common.all')}
                  </Label>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">{t('common.status')}: </Label>
                </Col>

                <Col xs={6}>
                  <Label>{data?.status?.status_display}</Label>
                </Col>
              </Row>

              <Row className="p-0 mb-3">
                <Col xs={6}>
                  <Label className="fw-normal">
                    {t('tariffs.descriptionDe')}:{' '}
                  </Label>
                </Col>

                <Col xs={6}>
                  <Label>
                    {data?.description?.de === '' ? '-' : data?.description?.de}
                  </Label>
                </Col>
              </Row>

              <Row className="p-0">
                <Col xs={6}>
                  <Label className="fw-normal">
                    {t('tariffs.descriptionEn')}:{' '}
                  </Label>
                </Col>

                <Col xs={6}>
                  <Label>
                    {data?.description?.en === '' ? '-' : data?.description?.en}
                  </Label>
                </Col>
              </Row>
            </StyledField>

            {data?.tariffs.length > 0 ? (
              <Col xs={12} className="mt-4">
                <CardTitle title="tariffs.tariffs" />

                <StyledTable $borderless $headerShort>
                  <thead>
                    <tr>
                      <StyledTableHead>{t('common.name')}</StyledTableHead>
                      <StyledTableHead>{t('invoices.price')}</StyledTableHead>
                    </tr>
                  </thead>

                  <tbody>
                    {data.tariffs.map(tariff => (
                      <tr key={tariff.id}>
                        <td className="col-6">{tariff.tariff.name}</td>

                        <td className="col-6">
                          {formatCurrency(tariff.price)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </Col>
            ) : (
              <Alert className="mb-0 mt-4" color="info">
                {t('tariffs.noServices')}
              </Alert>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default ServiceDetailModal;

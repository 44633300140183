import React, { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { PassportsWithFormik } from './PassportsWithFormik';
// import { VisasWithFormik } from './VisasWithFormik';
import { operatorAddApplicant } from '../../store/admin/actions';
import { getApplicant } from 'helpers/api-requests/admin';
import { changeApplicant as changeApplicantAction } from 'store/clients/actions';

import { APPLICANT_FORM_VALIDATOR } from 'forms/validators';
import styled from 'styled-components';
import { convertApplicantForm, toApplicantModalForm } from 'forms/shortcuts';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { ApplicantMainInfo } from './ApplicantMainInfo';
import { showToastError } from 'helpers/utils/toast';
import { getClientsState } from 'pages/Clients/Client';

export const StyledError = styled.span`
  font-size: 10px;
`;

const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledModal = styled(Modal)`
  min-width: 75vw;
`;

const initialData = {
  first_name: '',
  last_name: '',
  additional_name: '',
  birthday: null,
  email: '',
  passports: [
    {
      country: '',
      number: '',
      issue_date: null,
      end_date: null,
    },
  ],
  // visas: [{ country: '', started_at: null, ended_at: null }],
  sex: undefined,
  phone: '',
};

export const ApplicantModalForm = ({
  show,
  onClose,
  currentApplicantId,
  clientId,
  clientType,
}) => {
  const { client } = useSelector(getClientsState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isSubmitting } = client ?? {};

  const submittingForm = async values => {
    if (values.passports.length === 0) {
      showToastError(t('client.passportRequired'));
      return;
    }

    if (currentApplicantId) {
      dispatch(
        changeApplicantAction(
          convertApplicantForm(values),
          currentApplicantId,
          onClose,
        ),
      );
      return;
    }

    dispatch(
      operatorAddApplicant({
        clientType,
        clientId,
        query: convertApplicantForm(values),
        onClose,
      }),
    );

    await new Promise(resolve => {
      setTimeout(() => {
        resolve(1);
      }, 300);
    });
  };

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: APPLICANT_FORM_VALIDATOR,
    onSubmit: submittingForm,
  });

  const { handleSubmit, resetForm } = formik;

  const modalClose = () => {
    resetForm();
    onClose();
  };

  const getCurrentApplicant = async () => {
    const response = await getApplicant(currentApplicantId);

    resetForm({
      values: toApplicantModalForm(response.data),
    });
  };

  useEffect(() => {
    if (currentApplicantId) {
      getCurrentApplicant();
      return;
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <StyledModal isOpen={show} centered toggle={modalClose}>
        <StyledModalHeader className="border-0 pb-2 p-4">
          {currentApplicantId
            ? t('client.editApplicant')
            : t('client.addApplicant')}

          <div>
            <Button
              type="button"
              color="primary"
              className=" py-2 px-4 border-0"
              disabled={!formik.dirty || isSubmitting || formik.isSubmitting}
              onClick={handleSubmit}
            >
              {currentApplicantId ? t('common.save') : t('common.add')}
            </Button>

            <StyledUpload
              className="ms-4 py-2 px-4"
              type="reset"
              onClick={modalClose}
            >
              {t('common.cancel')}
            </StyledUpload>
          </div>
        </StyledModalHeader>

        <div className="w-100 ps-4 pe-4">
          <hr className="m-0" />
        </div>

        <ModalBody>
          <Row>
            <Col xs={12} md={6}>
              <ApplicantMainInfo />
            </Col>

            <Col xs={12} md={6}>
              <PassportsWithFormik />

              {/* <VisasWithFormik /> */}
            </Col>
          </Row>
        </ModalBody>
      </StyledModal>
    </FormikProvider>
  );
};

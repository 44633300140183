import { CardTitle, ShadowCard } from 'components/Common';
import React from 'react';
import { BlueLink } from 'components/Common';

export const OrderTypeDocumentsCard = ({ orderType }) => {
  return (
    <ShadowCard
      id="documentsSettings"
      className="mt-4"
      bodyStyle={{ padding: '24px' }}
    >
      <CardTitle title="orderTypes.documents" />
      <div>
        <BlueLink to={`/orderTypes/${orderType?.id}/documents`}>
          Manage documents
        </BlueLink>
      </div>
    </ShadowCard>
  );
};

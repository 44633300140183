import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { Nav, NavItem } from 'reactstrap';
import styled, { css } from 'styled-components';
import { ShadowCard } from './ShadowCard';

const StyledNavItem = styled(NavItem)`
  border-left: 4px solid #0000;

  color: ${({ theme }) => theme.colors.gray400};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  ${({ invalid, theme }) =>
    invalid &&
    css`
      color: ${theme.colors.danger};
    `}

  padding: 4px 0px 4px 20px !important;
  box-sizing: content-box;
`;

const StyledHead = styled.div`
  padding-left: 18px;

  span {
    color: ${({ theme }) => theme.colors.mainText};
    font-weight: 600;
    font-size: 14px;
  }

  p {
    font-weight: 600;
  }
`;

const StyledSticky = styled.div`
  top: 10px;

  ${({ $scrollableHeader }) =>
    $scrollableHeader &&
    css`
      top: 95px;
    `}

  ${({ $topHeigth }) =>
    $topHeigth &&
    css`
      top: ${$topHeigth};
    `}
`;

export const NavBar = ({
  components,
  headerComponent,
  scrollableHeader,
  topHeigth,
  extraContent,
}) => {
  const [active, setActive] = useState(components[0].id);
  const { t } = useTranslation();

  const handleSelectActiveItem = id =>
    setTimeout(() => {
      setActive(id);
    }, 500);

  const handleScroll = () => {
    setActive(null);
  };

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener('scroll', handleScroll);
    };

    watchScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledSticky
      $topHeigth={topHeigth}
      $scrollableHeader={scrollableHeader}
      className="position-sticky"
    >
      <ShadowCard
        bodyStyle={{
          borderRadius: '8px',
        }}
        className="mb-4"
      >
        {headerComponent && (
          <StyledHead className="d-flex flex-column">
            {headerComponent}

            <hr className="m-0 mb-3" />
          </StyledHead>
        )}

        <Nav vertical>
          {components &&
            components.map((component, index) => (
              <Link
                key={component.id}
                isDynamic
                saveHashHistory
                absolute
                onClick={() => handleSelectActiveItem(component.id)}
                className={classNames(
                  [components.length !== index + 1 && 'mb-3'],
                  [active === component.id && 'active-navbar'],
                )}
                activeClass={active ? undefined : 'active-navbar'}
                spy
                offset={component.offset ?? -100}
                tabIndex={0}
                to={component.link}
              >
                <StyledNavItem>{t(component.title)}</StyledNavItem>
              </Link>
            ))}
        </Nav>
      </ShadowCard>

      {extraContent}
    </StyledSticky>
  );
};

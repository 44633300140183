import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import {
  CHANGE_INVOICE_STATUS,
  GET_INVOICES,
  GET_INVOICE_DETAIL,
} from './actionTypes';
import {
  getInvoicesSuccess,
  getInvoicesFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  changeInvoiceStatus,
  getInvoiceDetail,
  getOperatorInvoices,
} from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';

function* fetchInvoices({ payload }) {
  try {
    const response = yield call(getOperatorInvoices, payload);
    yield put(
      getInvoicesSuccess({ invoices: response.data.results, ...response.data }),
    );
  } catch (error) {
    showToastError(error.message);
    yield put(getInvoicesFail(error));
  }
}

function* fetchInvoiceDetail({ invoiceId }) {
  try {
    const response = yield call(getInvoiceDetail, invoiceId);
    yield put(getInvoiceDetailSuccess(response.data));
  } catch (error) {
    showToastError(error.message);
    yield put(getInvoiceDetailFail(error));
  }
}

function* changeStatus({ payload }) {
  try {
    const response = yield call(changeInvoiceStatus, payload.id, {
      new_status: payload.new_status,
    });
    if (response.success) {
      showToastSuccess(response.message);
      yield put(getInvoiceDetailSuccess(response.data));
    } else {
      showToastError(response.message);
    }
  } catch (error) {
    showToastError('Status change error');
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices);
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail);
  yield takeEvery(CHANGE_INVOICE_STATUS, changeStatus);
}

export default invoiceSaga;

import { Select } from '../Common/Select';
import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import { EMPTY_VALUE } from 'common/data/constants';
// import { useDispatch, useSelector } from 'react-redux';
// import { getOrderCountries } from 'store/references/actionTypes';
import { useTranslation } from 'react-i18next';

// const getOrderCountriesState = state => state.references.orderCountries;

export const SelectOrderCountry = ({
  id,
  invalid,
  name,
  value,
  onChange,
  options,
  others = false,
  ...props
}) => {
  const { t } = useTranslation();
  // //
  // // const countries = useSelector(getOrderCountriesState);
  // // const dispatch = useDispatch();

  // // useEffect(() => {
  // //   dispatch(getOrderCountries({ available_for_order: true }));
  // // }, []);

  const countriesOptions = () => [
    ...options?.map(item => ({
      value: item.id,
      label: `[${item.country.code}] ${item.country.name}`,
      country: item,
    })),
    ...(others
      ? [
          {
            value: '_others_',
            label: t('common.countryOthers'),
            country: null,
          },
        ]
      : []),
  ];

  const val = useMemo(() => {
    if (isNil(value)) {
      return null;
    }
    return countriesOptions().find(item => item.value === value) ?? null;
  }, [options, value]);

  return (
    <Select
      value={val}
      onChange={onChange}
      name={name}
      classNamePrefix="select2-selection"
      className="vbo-select display-5"
      options={[EMPTY_VALUE, ...countriesOptions()]}
      id={id}
      isClearable
      invalid={invalid}
      {...props}
    />
  );
};

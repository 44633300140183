import { StyledInput } from 'components/Common/FormikInput';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap';
import styled from 'styled-components';

const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
`;

const name = 'address';

export const ClientEditAddress = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <>
      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.contactName')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.name`}
            value={formik.values.address?.name}
            placeholder={t('client.contactsNamePlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.address?.name}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.country')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <SelectCountry
            value={formik.values.address?.country}
            placeholder={t('client.countryPlaceholder')}
            onChange={country =>
              formik.setFieldValue(`${name}.country`, country?.value)
            }
            invalid={formik.errors?.address?.country}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.state')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledInput
            name={`${name}.state`}
            value={formik.values.address?.state}
            placeholder={t('client.statePlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.address?.state}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.zipCode')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledInput
            name={`${name}.postalCode`}
            value={formik.values.address?.postalCode}
            placeholder={t('client.zipCodePlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.address?.postalCode}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.city')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.city`}
            value={formik.values.address?.city}
            placeholder={t('client.cityPlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.address?.city}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">
            {t('client.addressLineFirst')}
          </StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.addressLineFirst`}
            value={formik.values.address?.addressLineFirst}
            placeholder={t('client.addressLineFirstPlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.address?.addressLineFirst}
          />
        </Col>
      </Row>
    </>
  );
};

import { StyledTable, StyledTableHead } from 'components/Table/StyledTable';
import { formatCurrency } from 'helpers/formatCurrency';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TableRow from './TableRow';
import i18n from 'i18n';

const ServicesTable = ({ services }) => {
  const { t } = useTranslation();

  const totalData = useMemo(
    () =>
      services.reduce(
        (accumulator, currentValue) => {
          return {
            net_price:
              accumulator.net_price +
              Number(currentValue.product_net_price_cents),
            tax: accumulator.tax + Number(currentValue.product_tax_total_cents),
            total_price:
              accumulator.total_price +
              Number(currentValue.product_total_price_cents),
          };
        },
        {
          net_price: 0,
          tax: 0,
          total_price: 0,
        },
      ),
    [services],
  );

  return (
    <div className="table-responsive">
      <StyledTable className="mb-0" $borderless>
        <thead>
          <tr>
            <StyledTableHead>{t('invoices.services')}</StyledTableHead>
            <StyledTableHead>{t('invoices.net')}</StyledTableHead>
            <StyledTableHead>{t('invoices.vat')}</StyledTableHead>
            <StyledTableHead>{t('invoices.total')}</StyledTableHead>
          </tr>
        </thead>

        <tbody>
          {services.map(service => (
            <TableRow
              key={service.id}
              service={service.name[i18n.language]}
              netPrice={formatCurrency(service.product_net_price_euro)}
              taxPercent={`${formatCurrency(
                service.product_tax_total_cents,
              )} (${service.product_tax_percent_human} %)`}
              totalPrice={formatCurrency(service.product_total_price_cents)}
            />
          ))}
        </tbody>

        <tfoot>
          <TableRow
            isTotalSums
            netPrice={formatCurrency(totalData.net_price)}
            taxPercent={formatCurrency(totalData.tax)}
            totalPrice={formatCurrency(totalData.total_price)}
          />
        </tfoot>
      </StyledTable>
    </div>
  );
};

export default ServicesTable;

import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, PaginationLm } from 'components/Common';
import { StyledTable, StyledTableHead } from 'components/Table/StyledTable';
import {
  getCurrentTariffServices,
  getTariffServices,
} from 'helpers/api-requests/admin';
import { showToastError } from 'helpers/utils/toast';

import { useParams } from 'react-router-dom';
import { Button, Alert, Col } from 'reactstrap';
import { AddTariffServiceRow } from './AddTariffServiceRow';
import ServiceDetailModal from './ServiceDetailModal';
import { useTranslation } from 'react-i18next';

const CHECKBOX_STATES = {
  checked: 'Checked',
  indeterminate: 'Indeterminate',
  empty: 'Empty',
};

export const TariffAddServiceTable = ({
  searchParams,
  selectedTariffId,
  setPage,
  selectedServices,
  setSelectedServices,
  selectedServicesAll,
  setSelectedServicesAll,
}) => {
  const [services, setServices] = useState({ results: [], count: null });
  const { id } = useParams();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(CHECKBOX_STATES.empty);
  const [isOpenServiceDetail, setIsOpenServiceDetail] = useState({
    open: false,
  });
  const isPartlySelected =
    selectedServices.length < services.count &&
    selectedServices.length === services.results.length;
  const copyFromTariff =
    selectedTariffId && Number(selectedTariffId) !== Number(id);

  useEffect(() => {
    if (!copyFromTariff) {
      getTariffServices({
        id,
        params: {
          product_type: searchParams.serviceType.value,
          country: searchParams.country.value,
          visa_type: searchParams.visaType?.value,
          visa_duration: searchParams.visaDuration?.value,
          visa_urgency: searchParams.visaUrgency?.value,
          region: searchParams.region.value,
          vat: searchParams.vat.value,
          search: searchParams.search,
          page: searchParams.page,
          page_size: searchParams.perPage.value,
        },
      })
        .then(response => {
          setServices({
            results: response.data.results,
            count: response.data.count,
          });
        })
        .catch(error => {
          showToastError(error);
        });
    } else {
      getCurrentTariffServices({
        id: selectedTariffId,
        idExclude: id,
        params: {
          product_type: searchParams.serviceType.value,
          country: searchParams.country.value,
          visa_type: searchParams.visaType?.value,
          visa_duration: searchParams.visaDuration?.value,
          visa_urgency: searchParams.visaUrgency?.value,
          region: searchParams.region.value,
          vat: searchParams.vat.value,
          search: searchParams.search,
          page: searchParams.page,
          page_size: searchParams.perPage.value,
        },
      })
        .then(response => {
          const servicesFromApi = response.data.results.map(product => ({
            ...product,
            id: product.product_id,
          }));

          setServices({
            results: servicesFromApi,
            count: response.data.count,
          });
        })
        .catch(error => {
          showToastError(error);
        });
    }
  }, [searchParams, selectedTariffId]);

  useEffect(() => {
    setSelectedServices([]);
    setSelectedServicesAll(false);
  }, [selectedTariffId, searchParams]);

  useEffect(() => {
    setSelectedServicesAll(false);
  }, [isPartlySelected]);

  useEffect(() => {
    if (
      selectedServices.length > 0 &&
      selectedServices.length === Number(services.count)
    ) {
      setChecked(CHECKBOX_STATES.checked);
      return;
    }

    if (selectedServices.length > 0) setChecked(CHECKBOX_STATES.indeterminate);

    if (selectedServices.length === 0) setChecked(CHECKBOX_STATES.empty);
  }, [selectedServices, services]);

  const showPagination = useMemo(
    () => searchParams.perPage.value < services.count,
    [services, searchParams.perPage],
  );

  const handleSelectService = id => {
    const serviceData = services.results.find(service => service.id === id);
    const priceDefault =
      serviceData?.price && (serviceData?.price / 100).toFixed(2);

    if (selectedServices.length === 0) {
      setSelectedServices([
        ...selectedServices,
        { id, price: '', priceDefault },
      ]);
      return;
    }

    if (selectedServices.find(service => service.id === id)) {
      setSelectedServices(() =>
        selectedServices.filter(sevice => sevice.id !== id),
      );
      return;
    }

    setSelectedServices([...selectedServices, { id, price: '', priceDefault }]);
  };

  // const handleSetPrice = (price, serviceId) => {
  //   setSelectedServices(prev =>
  //     prev.map(service => {
  //       if (service.id === serviceId) {
  //         return { ...service, price: price };
  //       }
  //
  //       return service;
  //     }),
  //   );
  // };

  const handleChange = () => {
    if (checked === CHECKBOX_STATES.checked) {
      setSelectedServices([]);
    } else if (checked === CHECKBOX_STATES.empty) {
      setSelectedServices(() =>
        services.results.map(service => ({
          id: service.id,
          price: '',
          priceDefault: service?.price && (service?.price / 100).toFixed(2),
        })),
      );
    } else if (checked === CHECKBOX_STATES.indeterminate) {
      setSelectedServices([]);
    }
  };

  const handleOpenServiceDetail = (id, serviceType) =>
    setIsOpenServiceDetail({ id, serviceType, open: true });

  return (
    <>
      {services.count > 0 ? (
        <StyledTable $headerShort className="mb-0">
          <thead>
            <tr>
              <StyledTableHead>
                <Checkbox onChange={handleChange} checked={checked} />
              </StyledTableHead>

              <StyledTableHead>{t('common.name')}</StyledTableHead>

              <StyledTableHead>{t('common.type')}</StyledTableHead>

              <StyledTableHead>{t('services.visaType')}</StyledTableHead>

              <StyledTableHead>{t('services.visaDuration')}</StyledTableHead>

              <StyledTableHead>{t('services.visaUrgency')}</StyledTableHead>

              <StyledTableHead>{t('common.country')}</StyledTableHead>

              <StyledTableHead>{t('common.region')}</StyledTableHead>

              <StyledTableHead>{t('passport.nationality')}</StyledTableHead>

              {copyFromTariff && (
                <StyledTableHead>{t('invoices.price')}</StyledTableHead>
              )}

              <StyledTableHead>{t('common.vatEnabled')}</StyledTableHead>

              {/*<StyledTableHead>{t('invoices.newprice')}</StyledTableHead>*/}
            </tr>
          </thead>

          <tbody>
            {isPartlySelected && (
              <tr>
                <td colSpan="100%">
                  {selectedServicesAll ? (
                    <>
                      <em className="text-danger">
                        You selected all {services.count} records
                      </em>
                      <Button
                        className="btn-sm ms-4"
                        onClick={() => setSelectedServicesAll(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <em className="text-muted">
                        You selected {selectedServices.length} /{' '}
                        {services.count}
                      </em>
                      <Button
                        className="btn-sm ms-4"
                        onClick={() => setSelectedServicesAll(true)}
                      >
                        Select all {services.count}
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            )}
            {services.results.map(service => (
              <AddTariffServiceRow
                selectedServices={selectedServices}
                service={service}
                key={service.id}
                handleOpenServiceDetail={handleOpenServiceDetail}
                // handleSetPrice={handleSetPrice}
                handleSelectService={handleSelectService}
                copyFromTariff={copyFromTariff}
              />
            ))}
          </tbody>
        </StyledTable>
      ) : (
        <Alert color="info">{t('services.notFound')}</Alert>
      )}

      {isOpenServiceDetail.open && (
        <ServiceDetailModal
          isOpen={isOpenServiceDetail.open}
          id={isOpenServiceDetail.id}
          handleClose={() => setIsOpenServiceDetail({ open: false })}
          serviceType={isOpenServiceDetail.serviceType.type}
        />
      )}

      {showPagination && (
        <Col xs={12}>
          <PaginationLm
            count={services.count}
            active={searchParams.page}
            perPage={searchParams.perPage.value}
            selectPage={setPage}
          />
        </Col>
      )}
    </>
  );
};

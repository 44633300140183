import { CardTitle, ShadowCard } from 'components/Common';
import React from 'react';
import { OrderTypeEditCountry } from './OrderTypeEditCountry';

export const OrderTypeCountryCard = () => {
  return (
    <ShadowCard
      id="countrySettings"
      className="mt-4"
      bodyStyle={{ padding: '24px' }}
    >
      <CardTitle title="orderTypes.country" />

      <OrderTypeEditCountry />
    </ShadowCard>
  );
};

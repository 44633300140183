import {
  deleteTariff,
  deleteTariffServices,
  fetchCurrentTariffServices,
  fetchTariff,
  fetchTariffs,
  fetchTariffServices,
  updateTariff,
  updateTariffServicePrice,
} from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as adminApi from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';
import i18n from 'i18n';

function* generalTariffFetch({ payload }) {
  try {
    const response = yield call(adminApi.getTariff, payload.id);
    if (response.success) {
      yield put(fetchTariff.success(response.data));
    } else {
      yield put(fetchTariff.failure(response));
      showToastError(i18n.t('notifications.errorGetTariff'));
    }
  } catch (error) {
    yield put(fetchTariff.failure(error));
  }
}

function* tariffUpdate({ payload }) {
  try {
    const response = yield call(adminApi.updateTariff, payload.tariff);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.tariffUpdated'));
      yield put(updateTariff.success(response.data));
      payload.closeEditMode();
    } else {
      yield put(updateTariff.failure(response));
      showToastError(
        response.message?.[0]?.name
          ? i18n.t('notifications.errorTariffCreate')
          : '',
      );
    }
  } catch (error) {
    yield put(updateTariff.failure(error));
    showToastError(error);
  }
}

function* getTariffs({ payload }) {
  try {
    const response = yield call(adminApi.getTariffs, payload);
    if (response.success) {
      yield put(
        fetchTariffs.success({
          tariffs: response.data.results,
          ...response.data,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(fetchTariffs.failure(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(fetchTariffs.failure(error));
  }
}

function* updatePrice({ payload }) {
  try {
    const response = yield call(adminApi.updatePriceTariffService, payload);

    if (response.success) {
      showToastSuccess(i18n.t('notifications.servicePriceUpdated'));
      yield put(updateTariffServicePrice.success(response.data));
    } else {
      yield put(updateTariffServicePrice.failure(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(updateTariffServicePrice.failure(error));
  }
}

function* getTariffServices({ payload }) {
  try {
    const response = yield call(adminApi.getTariffServices, payload);
    if (response.success) {
      yield put(fetchTariffServices.success(response.data));
    } else {
      showToastError(response.message);
      yield put(fetchTariffServices.failure(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(fetchTariffServices.failure(error));
  }
}

function* tariffServicesDelete({ payload }) {
  try {
    const response = yield call(adminApi.deleteTariffServices, payload);
    if (response.success) {
      yield put(deleteTariffServices.success(payload));
      showToastSuccess(i18n.t('notifications.servicesDeleted'));
    } else {
      showToastError(response.message);
      yield put(deleteTariffServices.failure(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(deleteTariffServices.failure(error));
  }
}

function* getCurrentTariffServices({ payload }) {
  try {
    const response = yield call(adminApi.getCurrentTariffServices, {
      id: payload.id,
      params: payload.params,
    });
    if (response.success) {
      yield put(fetchCurrentTariffServices.success(response.data));
    } else {
      yield put(fetchCurrentTariffServices.failure(response));
      showToastError(i18n.t('notifications.errorGetTariffServices'));
    }
  } catch (error) {
    yield put(fetchCurrentTariffServices.failure(error));
    showToastError(i18n.t('notifications.errorGetTariffServices'));
  }
}

function* tariffDelete({ payload }) {
  try {
    const response = yield call(adminApi.deleteTariff, payload.id);
    if (response.success) {
      yield put(deleteTariff.success(payload.id));
      showToastSuccess(i18n.t('notifications.deleteTariff'));
    } else {
      yield put(deleteTariff.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(deleteTariff.failure(error));
    showToastError(i18n.t('notifications.errorDeleteTariff'));
  }
}

function* tariffSaga() {
  yield takeEvery(fetchTariff, generalTariffFetch);
  yield takeEvery(updateTariff, tariffUpdate);
  yield takeEvery(fetchTariffs, getTariffs);
  yield takeEvery(updateTariffServicePrice, updatePrice);
  yield takeEvery(deleteTariffServices, tariffServicesDelete);
  yield takeEvery(fetchTariffServices, getTariffServices);
  yield takeEvery(fetchCurrentTariffServices, getCurrentTariffServices);
  yield takeEvery(deleteTariff, tariffDelete);
}

export default tariffSaga;

import { createQueryActions } from 'helpers/store/createQueryActions';

export const fetchTariff = createQueryActions('fetchTariff');

export const fetchTariffs = createQueryActions('fetchTariffs');

export const updateTariff = createQueryActions('updateTariff');

export const fetchTariffServices = createQueryActions('fetchTariffServices');

export const updateTariffServicePrice = createQueryActions(
  'updateTariffServicePrice',
);

export const deleteTariffServices = createQueryActions('deleteTariffServices');

export const fetchCurrentTariffServices = createQueryActions(
  'fetchCurrentTariffServices',
);

export const deleteTariff = createQueryActions('deleteTariff');

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';
import { Col } from 'reactstrap';

const StyledPhoneWrapper = styled.div`
  width: 100%;

  #phone-input {
    font-family: 'Poppins';
    font-weight: 400;
    max-height: 40px;
    color: ${({ theme }) => theme.colors.mainText};
    width: 100%;
    padding: 10px 16px 10px 60px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray200};

    ${({ errors, theme }) =>
      errors &&
      css`
        border-radius: 8px;
        border: 1px solid ${theme.colors.danger}!important;
      `}
  }

  .form-control:disabled {
    background-color: #e9ecef !important;
    opacity: 1;
  }
`;

export const StyledError = styled.span`
  font-size: 12px;
`;

const StyledErrorWrapper = styled.div`
  min-height: 25px;
`;

export const StyledPhoneInput = ({ errors, onChange, ...props }) => (
  <StyledPhoneWrapper errors={errors}>
    <PhoneInput
      inputProps={{
        id: 'phone-input',
        name: props.name,
      }}
      country="de"
      onChange={(_phone, _country, _event, formattedPhone) =>
        onChange(formattedPhone)
      }
      {...props}
    />
    <StyledErrorWrapper className="w-100 d-flex justify-content-between">
      <Col xs={12}>
        {Boolean(errors) && (
          <StyledError className="text-danger fw-lighter">{errors}</StyledError>
        )}
      </Col>
    </StyledErrorWrapper>
  </StyledPhoneWrapper>
);

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getClientDetail, deleteClientApplicant } from 'store/clients/actions';
import { Alert, Col, Row } from 'reactstrap';
import { FIRST_PAGE } from 'common/data/constants';
// import { formatDate } from 'helpers/formatDate';
import { ApplicantModalForm } from 'components/CreateApplicantModal/ApplicantModalForm';
import { StyledTable } from 'components/Table/StyledTable';
import { Loader, PaginationLm, ShadowCard } from 'components/Common';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { currentTableData } from 'helpers/currentTableDate';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';

const clientTypeUrl = {
  legal: 'organization',
  individual: 'user',
};

const getClientState = state => state.clients;

const ClientApplicants = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, client_type } = useParams();
  const [page, setPage] = useState(FIRST_PAGE);
  const [isOpenApplicantModal, setIsOpenApplicantModal] = useState(false);
  const [currentApplicant, setCurrentApplicant] = useState({});
  const { client, isLoading } = useSelector(getClientState);
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();

  useEffect(() => {
    dispatch(getClientDetail({ id, client_type: clientTypeUrl[client_type] }));
  }, []);

  const handleChangeApplicant = applicant => {
    setIsOpenApplicantModal(true);
    setCurrentApplicant(applicant);
  };

  const getUniqCountries = applicant => {
    const countries = [];

    applicant.passports.forEach(passport => {
      if (!countries.includes(passport.country.country.name)) {
        countries.push(passport.country.country.name);
      }
    });

    return countries;
  };

  const showPagination = useMemo(
    () => client.applicants?.length > 10,
    [client.applicants],
  );

  const handleDeleteClientApplicant = id => {
    dispatch(deleteClientApplicant({ id, handleCloseModal }));
  };

  return (
    <ShadowCard>
      {isLoading && (
        <Row className="justify-content-center">
          <Loader isLoading={isLoading} />
        </Row>
      )}

      {!isLoading && !client?.applicants?.length && (
        <Alert color="primary">{t('common.notFound')}</Alert>
      )}

      {!isLoading && client?.applicants?.length > 0 && (
        <>
          <StyledTable $headerShort>
            <thead>
              <tr>
                <th>{t('client.name')}</th>

                <th>{t('client.email')}</th>

                <th>{t('applicants.passports')}</th>

                <th />
                <th />
              </tr>
            </thead>

            <tbody>
              {currentTableData(client.applicants, page, 10).map(applicant => (
                <tr key={applicant.id}>
                  <td className="col-3">{`${applicant.first_name} ${applicant.last_name}`}</td>

                  <td className="col-3">{applicant.email}</td>

                  <td className="col-5">
                    {getUniqCountries(applicant).map(country => (
                      <div
                        key={country}
                        className="text-nowrap d-flex justify-content-between"
                      >
                        <p className="m-0">{country}</p>
                      </div>
                    ))}
                  </td>

                  <td className="">
                    <StyledEmptyButton
                      onClick={() => handleChangeApplicant(applicant)}
                    >
                      <i className="bx bxs-edit-alt" />
                    </StyledEmptyButton>
                  </td>
                  <td className="">
                    <StyledEmptyButton
                      onClick={() => handleOpenModal(applicant)}
                    >
                      <i className="bx bxs-trash-alt" />
                    </StyledEmptyButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>

          {showPagination && (
            <PaginationLm
              active={page}
              count={client.applicants.length}
              perPage={10}
              selectPage={page => setPage(page)}
            />
          )}
        </>
      )}

      <Row className="py-3">
        <Col>
          <StyledUpload
            className="d-flex"
            onClick={() => setIsOpenApplicantModal(true)}
          >
            <i className="bx bx-plus fs-5 me-2" /> {t('client.addApplicant')}
          </StyledUpload>
        </Col>
      </Row>

      {isOpenApplicantModal && (
        <ApplicantModalForm
          clientType={client_type}
          clientId={id}
          show={isOpenApplicantModal}
          onClose={() => {
            setIsOpenApplicantModal(false);
            setCurrentApplicant({});
          }}
          currentApplicantId={currentApplicant.id}
        />
      )}

      {isOpen && (
        <ModalConfirmDelete
          isOpenModal={isOpen}
          textHeader="applicants.delete"
          textBody="applicants.deleteConfirm"
          handleCloseModal={handleCloseModal}
          handleDelete={() => handleDeleteClientApplicant(isOpen.id)}
        />
      )}
    </ShadowCard>
  );
};

export default ClientApplicants;

import { DATE_FORMAT_SHORT } from 'common/data/constants';
import { format } from 'date-fns';
import i18n from 'i18n';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';

export const formatDate = (value, formatDate = DATE_FORMAT_SHORT) => {
  return value
    ? format(new Date(value), formatDate, {
        locale: i18n.language === 'de' ? de : en,
      })
    : '-';
};

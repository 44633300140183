import {
  DATE_FORMAT_WITH_SECONDS,
  EMPTY_VALUE_WITH_LABEL,
  FIRST_PAGE,
  PAGES,
  VERBS,
} from 'common/data/constants';
import { Loader, ShadowCard } from 'components/Common';
import { PaginationLm } from 'components/Common/PaginationLm';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { Layout } from 'components/Layout';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { StyledTable } from 'components/Table/StyledTable';
import { convertDateToAPI } from 'helpers/converters';
import { formatDate } from 'helpers/formatDate';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { getUserState } from 'pages/User/User';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import { getLogs, getUser } from 'store/actions';

const getLogsState = state => state.admin.rest.logs;

const Logs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentUser } = useSelector(getUserState);
  const { id } = useParams();
  const { logs, count, isLoading } = useSelector(getLogsState);

  const [searchState, setSearchState] = useState({
    perPage: PAGES[0],
    page: FIRST_PAGE,
    verb: EMPTY_VALUE_WITH_LABEL,
    dateFrom: null,
    dateTo: null,
  });
  const [sortDirection, setSortDirection] = useState(1);

  useEffect(() => {
    dispatch(
      getLogs({
        user: id,
        page: searchState.page,
        verb: searchState.verb.value,
        timestamp_start: convertDateToAPI(searchState.dateFrom),
        timestamp_end: convertDateToAPI(searchState.dateTo),
        page_size: searchState.perPage.value,
      }),
    );

    if ((id && !currentUser) || Number(currentUser?.id) !== Number(id)) {
      dispatch(getUser(id));
    }
  }, [searchState, id]);

  const handleChangeSearchParam = (field, value) => {
    setSearchState(prev => ({
      ...prev,
      page: FIRST_PAGE,
      [field]: value,
    }));
  };

  const sortByDate = () => {
    setSortDirection(-sortDirection);
  };

  const handleChangeDateRange = dates => {
    const [start, end] = dates;
    setSearchState({
      ...searchState,
      page: FIRST_PAGE,
      dateFrom: start,
      dateTo: end,
    });
  };

  const logsNormalized = useMemo(() => {
    if (logs.at()?.actor?.id === Number(id)) {
      return logs.sort(
        (prev, next) =>
          (new Date(next.timestamp) - new Date(prev.timestamp)) * sortDirection,
      );
    }

    return [];
  }, [logs, id]);

  const showPagination = searchState.perPage.value < count;

  const currentTitle = useMemo(
    () =>
      currentUser?.first_name
        ? `Logs ${currentUser?.first_name} ${currentUser?.last_name}`
        : 'Logs',
    [currentUser?.first_name],
  );

  return (
    <Layout title={currentTitle}>
      <ShadowCard>
        <Row className="mb-3 align-items-center">
          <Col xs={5}>
            <DateRangePicker
              onChange={handleChangeDateRange}
              endDate={searchState.dateTo}
              startDate={searchState.dateFrom}
            />
          </Col>

          <Col xs={4}>
            <StatusPicker
              $type="gray"
              placeholder="Action"
              statuses={VERBS}
              value={searchState.verb}
              handlePickStatus={value => handleChangeSearchParam('verb', value)}
            />
          </Col>

          <Col xs={3}>
            <PerPageSelect
              perPage={searchState.perPage}
              setPerPage={option => handleChangeSearchParam('perPage', option)}
              setPage={option => handleChangeSearchParam('page', option)}
            />
          </Col>
        </Row>

        {isLoading && (
          <Row className="d-flex align-items-center justify-content-center">
            <Loader isLoading={isLoading} />
          </Row>
        )}

        {!isLoading && logs?.length === 0 && (
          <Alert color="info">{t('logs.notFound')}</Alert>
        )}

        {!isLoading && logs?.length > 0 && (
          <>
            <StyledTable>
              <thead>
                <tr>
                  <th>
                    Date&nbsp;
                    <StyledEmptyButton onClick={sortByDate}>
                      <i className="bx bx-sort fs-6" />
                    </StyledEmptyButton>
                  </th>

                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {logsNormalized.map(({ verb, timestamp }) => (
                  <tr key={timestamp}>
                    <td className="col-6">
                      {formatDate(timestamp, DATE_FORMAT_WITH_SECONDS)}
                    </td>

                    <td className="col-6">
                      {VERBS.find(el => el.value === verb)?.label}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>

            <Row>
              <Col xs={12}>
                {showPagination && (
                  <PaginationLm
                    count={count}
                    perPage={searchState.perPage.value}
                    active={searchState.page}
                    selectPage={value => handleChangeSearchParam('page', value)}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </ShadowCard>
    </Layout>
  );
};

export default Logs;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import Routes
import { getAuthProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout/';
import NonAuth from './components/NonAuth';

import { getUserRole } from './helpers/jwt_helper';

import { getProfile } from './store/profile/actions';
import Toasts from 'components/Toasts/Toasts';

import './assets/scss/app.scss';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const role = getUserRole();
  const dispatch = useDispatch();

  useEffect(() => {
    if (role) {
      dispatch(getProfile());
    }

    const preloader = document.getElementById('preloader');
    setTimeout(() => {
      if (preloader) {
        preloader.remove();
      }
    }, 300);
  }, [role]);

  return (
    <>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuth}
              component={route.component}
              key={idx}
            />
          ))}

          {getAuthProtectedRoutes(role).map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected
              exact
            />
          ))}
        </Switch>
      </Router>
      <Toasts />
    </>
  );
};

export default App;

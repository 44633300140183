import classNames from 'classnames';
import { FormikInput } from 'components/Common';
import {
  StyledButtonCancel,
  StyledButtonSave,
} from 'components/Common/StyledButtonSave';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { useFormik } from 'formik';
import { ADDRESS_VALIDATOR } from 'forms/validators';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

const formFields = [
  {
    type: 'text',
    name: 'name',
    key: 3.0,
    label: 'delivery.name',
    placeholder: 'delivery.namePlaceholder',
  },
  {
    type: 'text',
    name: 'addressLineFirst',
    key: 3.1,
    label: 'client.addressLineFirst',
    placeholder: 'client.addressLineFirstPlaceholder',
  },
  {
    type: 'text',
    name: 'postalCode',
    key: 3.3,
    label: 'client.zipCode',
    placeholder: 'client.zipCodePlaceholder',
    maxLength: 15,
  },
  {
    type: 'text',
    name: 'city',
    key: 3.4,
    label: 'client.city',
    placeholder: 'client.cityPlaceholder',
  },
  {
    type: 'text',
    name: 'state',
    key: 3.5,
    label: 'client.state',
    placeholder: 'client.statePlaceholder',
  },
];

const getWeight = index => {
  if (index === 1 || index === 2) {
    return 6;
  }

  return 12;
};

export const AddressEditForm = ({
  address,
  handleCloseEditMode,
  handleSave,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: address,
    onSubmit: handleSave,
    validationSchema: ADDRESS_VALIDATOR,
  });

  const handleCancelEdit = () => {
    formik.resetForm({ values: address });

    if (handleCloseEditMode) {
      handleCloseEditMode();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="d-flex w-100 flex-wrap">
        {formFields.map((field, index) => (
          <Col key={field.id} xs={12} lg={getWeight(index)}>
            <FormikInput
              name={field.name}
              value={formik.values[field.name]}
              onBlur={formik.handleBlur}
              label={field.label}
              groupStyle={classNames(
                [index === 1 && 'pe-2'],
                [index === 2 && 'ps-2'],
              )}
              placeholder={field.placeholder}
              handleChange={formik.handleChange}
              errors={
                formik.errors[field.name] && formik.touched[field.name]
                  ? formik.errors[field.name]
                  : null
              }
            />
          </Col>
        ))}

        <Col xs={12}>
          <FormikInput
            label="common.country"
            name="country"
            errors={
              formik.errors.country && formik.touched.country
                ? formik.errors.country
                : null
            }
            onBlur={formik.handleBlur}
            customInput={
              <SelectCountry
                isAvailableDelivery
                invalid={
                  formik.errors.country && formik.touched.country
                    ? formik.errors.country
                    : null
                }
                onChange={country =>
                  formik.setFieldValue('country', country?.value)
                }
                value={formik.values.country}
                name="country"
                onBlur={formik.handleBlur}
              />
            }
          />
        </Col>

        <Col
          xs={12}
          sm={6}
          className="d-flex gap-2 flex-nowrap justify-content-between"
        >
          <StyledButtonSave
            className="w-50"
            onClick={formik.handleSubmit}
            disabled={!formik.dirty}
            color="primary"
          >
            {t(address.id ? 'common.save' : 'common.create')}
          </StyledButtonSave>

          <StyledButtonCancel
            type="button"
            className="w-50 btn"
            onClick={handleCancelEdit}
          >
            {t('common.cancel')}
          </StyledButtonCancel>
        </Col>
      </div>
    </form>
  );
};

import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label, Row } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';

const name = 'clientOptions';

export const ClientOptionsEdit = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const getOptionColor = value => (value ? 'info' : 'warning');

  return (
    <>
      {/* <Row
        className={`alert alert-${getOptionColor(
          formik.values.clientOptions?.employee_can_create_order,
        )} p-3 mb-2 align-items-center`}
      >
        <Col xs={12}>
          <div className="d-flex align-items-center mt-2">
            <Input
              className="mt-0"
              type="checkbox"
              checked={formik.values.clientOptions?.employee_can_create_order}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.employee_can_create_order`,
                  !formik.values.clientOptions?.employee_can_create_order,
                )
              }
            />

            <Label
              size="sm"
              className="ms-2 mb-0"
              id="employee_can_create_order"
            >
              {t('client.employeeCanCreateOrder')}
            </Label>
          </div>
        </Col>
      </Row> */}

      <Row
        className={`alert alert-${getOptionColor(
          formik.values.clientOptions?.notify_organization,
        )} p-3 mb-2 align-items-center`}
      >
        <Col xs={12}>
          <div className="d-flex align-items-center mt-2">
            <Input
              className="mt-0"
              type="checkbox"
              checked={formik.values.clientOptions?.notify_organization}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.notify_organization`,
                  !formik.values.clientOptions?.notify_organization,
                )
              }
            />

            <Label size="sm" className="ms-2 mb-0" id="notify_organization">
              {t('client.notifyOrganization')}
            </Label>
          </div>
        </Col>
      </Row>

      <Row
        className={`alert alert-${getOptionColor(
          formik.values.clientOptions?.notify_all_managers,
        )} p-3 mb-2 align-items-center`}
      >
        <Col xs={12}>
          <div className="d-flex align-items-center mt-2">
            <Input
              className="mt-0"
              type="checkbox"
              checked={formik.values.clientOptions?.notify_all_managers}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.notify_all_managers`,
                  !formik.values.clientOptions?.notify_all_managers,
                )
              }
            />

            <Label size="sm" className="ms-2 mb-0" id="notify_all_managers">
              {t('client.notifyManagers')}
            </Label>
          </div>
        </Col>
      </Row>

      <Row
        className={`alert alert-${getOptionColor(
          formik.values.clientOptions?.notify_created_by_manager,
        )} p-3 mb-2 align-items-center`}
      >
        <Col xs={12}>
          <div className="d-flex align-items-center mt-2">
            <Input
              className="mt-0"
              type="checkbox"
              checked={formik.values.clientOptions?.notify_created_by_manager}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.notify_created_by_manager`,
                  !formik.values.clientOptions?.notify_created_by_manager,
                )
              }
            />

            <Label
              size="sm"
              className="ms-2 mb-0"
              id="notify_created_by_manager"
            >
              {t('client.notifyCreatedByManager')}
            </Label>
          </div>
        </Col>
      </Row>

      <Row
        className={`alert alert-${getOptionColor(
          formik.values.clientOptions?.notify_applicant,
        )} p-3 mb-2 align-items-center`}
      >
        <Col xs={12}>
          <div className="d-flex align-items-center mt-2">
            <Input
              className="mt-0"
              type="checkbox"
              checked={formik.values.clientOptions?.notify_applicant}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.notify_applicant`,
                  !formik.values.clientOptions?.notify_applicant,
                )
              }
            />

            <Label size="sm" className="ms-2 mb-0" id="notify_applicant">
              {t('client.notifyApplicant')}
            </Label>
          </div>
        </Col>
      </Row>

      <Row
        className={`alert alert-${getOptionColor(
          formik.values.clientOptions?.managerViewPrice,
        )} p-3 mb-2 align-items-center`}
      >
        <Col xs={12}>
          <div className="d-flex align-items-center mt-2">
            <Input
              className="mt-0"
              type="checkbox"
              checked={formik.values.clientOptions?.managerViewPrice}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.managerViewPrice`,
                  !formik.values.clientOptions?.managerViewPrice,
                )
              }
            />

            <Label size="sm" className="ms-2 mb-0" id="managerViewPrice">
              {t('client.managerViewPrice')}
            </Label>

            <UncontrolledTooltip
              placement="right"
              autohide
              target="managerViewPrice"
            >
              {t('client.managerViewPriceTooltip')}
            </UncontrolledTooltip>
          </div>
        </Col>
      </Row>

      <Row
        className={`alert alert-${getOptionColor(
          formik.values.clientOptions?.employeeViewPrice,
        )} p-3 mb-2 align-items-center`}
      >
        <Col xs={12}>
          <div className="d-flex align-items-center mt-2">
            <Input
              className="mt-0"
              type="checkbox"
              checked={formik.values.clientOptions?.employeeViewPrice}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.employeeViewPrice`,
                  !formik.values.clientOptions?.employeeViewPrice,
                )
              }
            />

            <Label size="sm" className="ms-2 mb-0" id="employeeViewPrice">
              {t('client.employeeViewPrice')}
            </Label>

            <UncontrolledTooltip
              placement="right"
              autohide
              target="employeeViewPrice"
            >
              {t('client.employeeViewPriceTooltip')}
            </UncontrolledTooltip>
          </div>
        </Col>
      </Row>
    </>
  );
};

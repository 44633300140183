import classNames from 'classnames';
import { StyledInput } from 'components/Common/FormikInput';
import {
  StyledButtonCancel,
  StyledButtonSave,
} from 'components/Common/StyledButtonSave';
import { Label } from 'components/Services/ServiceForm';
import { useFormik } from 'formik';
import { INVITE_VALIDATOR } from 'forms/validators';
import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Col, Label as ReactstrapLabel } from 'reactstrap';
import { changeEmail, getProfile } from 'store/actions';

const EmailWithFormik = memo(({ profile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!profile) {
      dispatch(getProfile());
    }
  }, []);

  const formik = useFormik({
    initialValues: { email: profile?.email ?? '' },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: INVITE_VALIDATOR,
    onSubmit: values => dispatch(changeEmail(values.email)),
  });

  const handleCancel = () => {
    formik.resetForm({ values: { email: profile?.email } });
  };

  const proflieInputs = useMemo(
    () => [
      { title: 'auth.firstName', value: profile.first_name, key: 1 },
      { title: 'auth.lastName', value: profile.last_name, key: 2 },
      { title: 'auth.username', value: profile.username, key: 3 },
    ],
    [profile],
  );

  return (
    <form onSubmit={formik.handleSubmit} className="p-0 d-flex flex-wrap">
      {proflieInputs.map((profileField, index) => (
        <Col
          key={profileField.key}
          className={classNames('p-0 d-flex flex-wrap ', [
            proflieInputs.length !== index + 1 && 'mb-4',
          ])}
          xs={12}
        >
          <ReactstrapLabel className="text-muted fs-14" xs={6} sm={3} lg={2}>
            {t(profileField.title)}
          </ReactstrapLabel>

          <Col xs={6} sm={9} lg={10} className="d-flex align-items-center">
            <Label className="m-0">{profileField.value}</Label>
          </Col>
        </Col>
      ))}

      <Col xs={12}>
        <hr className="my-4" />
      </Col>

      <Col className="p-0 d-flex flex-wrap mb-4" xs={12}>
        <Label xs={3} lg={2}>
          {t('auth.email')}
        </Label>

        <Col xs={9} lg={10} className="d-flex align-items-center">
          <StyledInput
            value={formik.values.email}
            className="w-100"
            invalid={formik.errors.email}
            name="email"
            onChange={formik.handleChange}
            placeholder={t('auth.enterEmail')}
          />
        </Col>
      </Col>

      <Col className="p-0 d-flex flex-wrap" xs={12}>
        <Col
          xs={4}
          className="offset-xs-3 offset-lg-2 d-flex gap-2 flex-nowrap justify-content-between"
        >
          <StyledButtonSave
            disabled={!formik.dirty}
            color="primary"
            type="submit"
            className="w-100"
          >
            {t('common.save')}
          </StyledButtonSave>

          <StyledButtonCancel
            className="w-100 btn text-nowrap"
            onClick={handleCancel}
            disabled={!formik.dirty}
            type="button"
          >
            {t('common.cancel')}
          </StyledButtonCancel>
        </Col>
      </Col>
    </form>
  );
});

export default EmailWithFormik;

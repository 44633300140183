import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getApplicantsOrganizationListFail,
  getApplicantsOrganizationListSuccess,
  getApplicantsUserListFail,
  getApplicantsUserListSuccess,
  operatorAddApplicantFail,
  operatorAddApplicantSuccess,
  operatorCreateDeliveryShipments,
  operatorDeliveryShipmentsUpsCreate,
  operatorDeliveryShipmentsUpsGet,
  operatorDeliveryShipmentDelete,
  operatorGetDeliveryAddresses,
  operatorGetDeliveryMethodsFail,
  operatorGetDeliveryMethodsSuccess,
  operatorGetDeliveryShipments,
  operatorUpdateDeliveryShipments,
  ordersCreateCustomerFail,
  ordersCreateCustomerSuccess,
  ordersDeleteCustomerFail,
  ordersDeleteCustomerSuccess,
  ordersStatusChangeFail,
  ordersStatusChangeSuccess,
  ordersUpdateCustomerFail,
  ordersUpdateCustomerSuccess,
  setLoader,
  operatorDeleteService,
  // operatorDeleteServiceSuccess,
  // operatorDeleteServiceFail,
} from 'store/actions';

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_PROFILE,
  GET_LOGS,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  CREATE_ORGANIZATION,
  GET_USER,
  GET_SERVICE,
  GET_SERVICES,
  DELETE_USER,
  BLOCK_USER,
  UNBLOCK_USER,
  UPDATE_USER,
  UPDATE_SERVICES,
  CREATE_SERVICES,
  CREATE_USER,
  GET_ORDERS,
  CREATE_ORDER,
  GET_ORDER,
  UPDATE_ORDER,
  UPLOAD_DOCUMENT,
  ATTACH_MANAGER_TO_ORGANIZATION,
  GET_ORDERS_FEED,
  GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST,
  GET_OPERATOR_APPLICANTS_USER_LIST,
  OPERATOR_ADD_APPLICANT,
  ORDERS_UPDATE_CUSTOMER,
  ORDERS_DELETE_CUSTOMER,
  ORDERS_CREATE_CUSTOMER,
  ORDERS_STATUS_CHANGE,
  OPERATOR_GET_DELIVERY_METHODS,
  GET_FILTERED_SERVICES,
} from './actionTypes';
import {
  getUsersSuccess,
  getUsersFail,
  getUserSuccess,
  getUserFail,
  getUserProfileSuccess,
  getUserProfileFail,
  deleteUserFail,
  deleteUserSuccess,
  blockUserSuccess,
  blockUserFail,
  unBlockUserSuccess,
  unBlockUserFail,
  getLogsSuccess,
  getLogsFail,
  getOrganizationsSuccess,
  getOrganizationsFail,
  getOrganizationSuccess,
  getOrganizationFail,
  getServicesSuccess,
  getServicesFail,
  updateUserSuccess,
  updateUserFail,
  updateServiceSuccess,
  updateServiceFail,
  getServiceFail,
  getServiceSuccess,
  createUserSuccess,
  createUserFail,
  createOrganizationFail,
  createOrganizationSuccess,
  updateOrganizationFail,
  updateOrganizationSuccess,
  createServiceFail,
  createServiceSuccess,
  getOrdersFail,
  getOrdersSuccess,
  createOrderSuccess,
  createOrderFail,
  getOrderFail,
  getOrderSuccess,
  updateOrderFail,
  updateOrderSuccess,
  uploadDocumentSuccess,
  uploadDocumentFail,
  getOrdersFeedSuccess,
  getOrdersFeedFail,
  getFilteredServicesSuccess,
  getFilteredServicesFail,
  operatorGetDeliveryShipment,
  inviteUser,
} from './actions';

//Include Both Helper File with needed methods
import * as adminApi from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';
import i18n from 'i18next';
import {
  addApplicantSuccess,
  changeApplicantFail,
  changeApplicantSuccess,
} from 'store/clients/actions';
import { CHANGE_APPLICANT } from 'store/clients/actionTypes';

function* fetchUsers({ payload }) {
  yield put(setLoader({ users: true }));
  try {
    const response = yield call(adminApi.getUsers, payload);
    if (response.success) {
      yield put(
        getUsersSuccess({ users: response.data.results, ...response.data }),
      );
    } else {
      showToastError(response.message);
      yield put(getUsersFail(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(getUsersFail(error));
  } finally {
    yield put(setLoader({ users: false }));
  }
}

function* fetchUser({ payload }) {
  try {
    const response = yield call(adminApi.getUser, payload);
    if (response.success) {
      yield put(getUserSuccess({ user: response.data }));
    } else {
      showToastError(response.message);
      yield put(getUserFail(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(getUserFail(error));
  }
}

function* editUser({ payload }) {
  try {
    const response = yield call(adminApi.updateUser, payload);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.userUpdated'));
      yield put(updateUserSuccess(response));
    } else {
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error);
    yield put(updateUserFail(error));
  }
}

function* inviteUserSaga({ payload }) {
  try {
    const response = yield call(adminApi.inviteUserRequest, payload.id);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.userInvited'));
      yield put(inviteUser.success(payload.id));
    } else {
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error);
    yield put(inviteUser.failure(error));
  }
}

function* addUser({ payload }) {
  try {
    const response = yield call(adminApi.createUser, payload);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.userCreated'));
      payload.push(`/users/${response.data.id}`);
      yield put(createUserSuccess(response));
    } else {
      showToastError(response.message);
      yield put(createUserFail(response));
    }
  } catch (error) {
    showToastError(error);
    yield put(createUserFail(error));
  }
}

function* fetchLogs({ payload }) {
  try {
    const response = yield call(adminApi.getLogs, payload);
    if (response.success) {
      yield put(
        getLogsSuccess({ logs: response.data.results, ...response.data }),
      );
    } else {
      showToastError(i18n.t('notifications.loadLogs'));
      yield put(getLogsFail(response.data));
    }
  } catch (error) {
    showToastError(i18n.t('notifications.loadLogs'));
    yield put(getLogsFail(error));
  }
}

function* fetchServices({ payload }) {
  try {
    const response = yield call(adminApi.getServices, payload);
    if (response.success) {
      yield put(
        getServicesSuccess({
          services: response.data.results,
          ...response.data,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(getServicesFail(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(getServicesFail(error));
  }
}

function* fetchFilteredServices({ payload }) {
  try {
    const response = yield call(adminApi.getFilteredServices, payload);
    if (response.success) {
      yield put(getFilteredServicesSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(getFilteredServicesFail(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(getFilteredServicesFail(error));
  }
}

function* editService({ payload }) {
  try {
    const response = yield call(adminApi.updateService, payload);
    if (response.success) {
      yield put(updateServiceSuccess(response.data));
    } else {
      yield put(updateServiceFail(response.data));
    }
  } catch (error) {
    yield put(updateServiceFail(error));
  }
}

function* fetchService({ payload }) {
  try {
    const response = yield call(adminApi.getService, payload);
    if (response.success) {
      yield put(getServiceSuccess(response.data));
    } else {
      yield put(getServiceFail(response.data));
    }
  } catch (error) {
    yield put(getServiceFail(error));
  }
}

function* addService({ payload }) {
  try {
    const response = yield call(adminApi.createService, payload);
    if (response.success) {
      yield put(createServiceSuccess(response));
    } else {
      yield put(createServiceFail(response));
    }
  } catch (error) {
    yield put(createServiceFail(error));
  }
}

function* fetchOrganizations({ payload }) {
  try {
    const response = yield call(adminApi.getOrganizations, payload);
    if (response.success) {
      yield put(
        getOrganizationsSuccess({
          data: response.data.results,
          ...response.data,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(getOrganizationsFail(response.data));
    }
  } catch (error) {
    showToastError(error);
    yield put(getOrganizationsFail(error));
  }
}

function* fetchOrganization({ payload }) {
  try {
    const response = yield call(adminApi.getOrganization, payload);
    if (response.success) {
      yield put(getOrganizationSuccess(response.data));
    } else {
      yield put(getOrganizationFail(response.data));
    }
  } catch (error) {
    yield put(getOrganizationFail(error));
  }
}

function* editOrganization({ payload }) {
  try {
    const response = yield call(adminApi.updateOrganization, payload);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.updateOrganization'));
      yield put(updateOrganizationSuccess(response));
    } else {
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error);
    yield put(updateOrganizationFail(error));
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(adminApi.getUserProfile);
    if (response.success) {
      yield put(getUserProfileSuccess(response));
    } else {
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error);
    yield put(getUserProfileFail(error));
  }
}

function* fetchOrders({ payload }) {
  try {
    const state = yield select();
    const requestCount = state.admin.rest.orders.requestCount;

    const response = yield call(adminApi.getOrders, payload);

    if (response.success) {
      yield put(
        getOrdersSuccess({
          orders: response.data.results,
          requestCount: requestCount,
          ...response.data,
        }),
      );
    } else {
      yield put(getOrdersFail(response.data));
    }
  } catch (error) {
    yield put(getOrdersFail(error));
  }
}

function* fetchOrdersFeed({ payload }) {
  try {
    const response = yield call(adminApi.getOrdersFeed, payload);
    if (response.success) {
      yield put(
        getOrdersFeedSuccess({
          events: response.data.results,
          ...response.data,
        }),
      );
    } else {
      yield put(getOrdersFeedFail(response.data));
    }
  } catch (error) {
    yield put(getOrdersFeedFail(error));
  }
}

function* addOrder({ payload }) {
  try {
    const response = yield call(adminApi.createOrder, payload.param);

    if (response.success) {
      yield put(createOrderSuccess(response.data));

      if (payload.shouldRedirect) {
        payload.history.push('/orders');
        showToastSuccess(i18n.t('notifications.orderCreated'));
      } else {
        payload.history.push(`/orders/${response.data.id}`);
      }
    } else {
      yield put(createOrderFail(response));
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error.message);
    yield put(createOrderFail(error));
  }
}

function* statusChange({ payload }) {
  try {
    const response = yield call(adminApi.ordersStatusChange, payload);
    if (response.success) {
      yield put(
        ordersStatusChangeSuccess({
          id: payload.id,
          data: response.data,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(
        ordersStatusChangeFail({
          error: response.data,
          id: payload.id,
        }),
      );
    }
  } catch (error) {
    showToastError(error.message);
    yield put(
      ordersStatusChangeFail({
        error: error.message,
        id: payload.id,
      }),
    );
  }
}

function* editOrder({ payload }) {
  try {
    const response = yield call(adminApi.updateOrder, payload);
    if (response.success) {
      yield put(updateOrderSuccess(response.data));

      if (payload.shouldRedirect) {
        payload.history.push('/orders');
      }

      showToastSuccess(i18n.t('notifications.orderUpdated'));
    } else {
      showToastError(response.message);
      yield put(updateOrderFail(payload));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(updateOrderFail(payload));
  }
}

function* createOrderCustomer({ payload }) {
  try {
    const response = yield call(adminApi.ordersCreateCustomer, payload);
    if (response.success) {
      yield put(
        ordersCreateCustomerSuccess({
          id: payload.id,
          data: response.data,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(
        ordersCreateCustomerFail({
          data: response.data,
          id: payload.id,
        }),
      );
    }
  } catch (error) {
    console.error(error);
    showToastError(error.message);
    yield put(
      ordersCreateCustomerFail({
        error,
        id: payload.id,
      }),
    );
  }
}

function* updateOrderCustomer({ payload }) {
  try {
    const response = yield call(adminApi.ordersUpdateCustomer, payload);
    if (response.success) {
      yield put(
        ordersUpdateCustomerSuccess({
          id: payload.id,
          data: response.data,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(
        ordersUpdateCustomerFail({
          id: payload.id,
          data: response.data,
        }),
      );
    }
  } catch (error) {
    showToastError(error.message);
    yield put(
      ordersUpdateCustomerFail({
        id: payload.id,
        error,
      }),
    );
  }
}

function* deleteOrderCustomer({ payload }) {
  try {
    yield call(adminApi.ordersDeleteCustomer, payload);

    yield put(
      ordersDeleteCustomerSuccess({
        id: payload.id,
        customer_pk: payload.customer_pk,
      }),
    );
  } catch (error) {
    showToastError(error.message);
    yield put(
      ordersDeleteCustomerFail({
        error,
        id: payload.id,
      }),
    );
  }
}

function* fetchOrder({ payload }) {
  try {
    const response = yield call(adminApi.getOrder, payload);
    if (response.success) {
      yield put(getOrderSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(getOrderFail(response.data));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(getOrderFail(error));
  }
}

function* addOrganization({ payload }) {
  try {
    const response = yield call(adminApi.createOrganization, payload);
    if (response.success) {
      yield put(createOrganizationSuccess(response));
      window.location.href = '/organizations';
    } else {
      showToastError(response.message);
      yield put(createOrganizationFail(response));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(createOrganizationFail(error));
  }
}

function* uploadFile({ payload }) {
  try {
    const response = yield call(adminApi.uploadDocument, payload);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.documentUpload'));
      yield put(uploadDocumentSuccess(response));
    } else {
      showToastError(response.message);
      yield put(uploadDocumentFail(response));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(uploadDocumentFail(error));
  }
}

function* attachManager({ payload }) {
  try {
    const response = yield call(adminApi.createOrganizationManager, payload);
    if (response.success) {
      showToastSuccess(i18n.t('Manager attached successfully'));
    } else {
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error.message);
    return error;
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const response = yield call(adminApi.deleteUserRequest, payload.id);
    if (response.success) {
      showToastSuccess(i18n.t('users.deleted'));
      yield put(deleteUserSuccess(payload.id));
      payload.handleCloseUserModal();
    } else {
      showToastError(response.message);
      yield put(deleteUserFail(response));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(deleteUserFail(error));
  }
}

function* blockUserSaga(payload) {
  try {
    const response = yield call(adminApi.blockUser, payload);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.userBlocked'));
      yield put(blockUserSuccess({ user: response.data }));
    } else {
      showToastError(response.message);
      yield put(blockUserFail(response));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(blockUserFail(error));
  }
}

function* unBlockUserSaga(payload) {
  try {
    const response = yield call(adminApi.unBlockUser, payload);
    if (response.success) {
      showToastSuccess(i18n.t('notifications.userUnblocked'));
      yield put(unBlockUserSuccess({ user: response.data }));
    } else {
      showToastError(response.message);
      yield put(unBlockUserFail(response));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(unBlockUserFail(error));
  }
}

function* fetchApplicantsOrganizationList({ payload }) {
  try {
    const response = yield call(
      adminApi.operatorApplicantsOrganizationList,
      payload,
    );
    if (response.success) {
      yield put(getApplicantsOrganizationListSuccess(response.data));
    } else {
      yield put(getApplicantsOrganizationListFail(response));
    }
  } catch (error) {
    yield put(getApplicantsOrganizationListFail(error));
  }
}

function* fetchApplicantsUserList({ payload }) {
  try {
    const response = yield call(adminApi.operatorApplicantsUserList, payload);
    if (response.success) {
      yield put(getApplicantsUserListSuccess(response.data));
    } else {
      yield put(getApplicantsUserListFail(response));
    }
  } catch (error) {
    yield put(getApplicantsUserListFail(error));
  }
}

function* operatorAddApplicant({ payload }) {
  try {
    const response = yield call(
      payload.clientType === 'legal'
        ? adminApi.operatorOrganizationAddApplicants
        : adminApi.operatorUserAddApplicants,
      payload.clientId,
      payload.query,
    );
    if (response.success) {
      yield put(operatorAddApplicantSuccess(response.data));
      yield put(addApplicantSuccess(response.data));
      showToastSuccess(i18n.t('notifications.applicantCreated'));

      if (payload.onClose) {
        payload.onClose();
      }
    } else {
      yield put(operatorAddApplicantFail(response));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(operatorAddApplicantFail(error));
    showToastError(error);
  }
}

function* editApplicant({ payload }) {
  try {
    const response = yield call(
      adminApi.changeApplicant,
      payload.values,
      payload.currentApplicantId,
    );
    if (response.success) {
      yield put(changeApplicantSuccess(response.data));
      showToastSuccess(i18n.t('notifications.applicantEdited'));

      if (payload.onClose) {
        payload.onClose();
      }
    } else {
      yield put(changeApplicantFail(response.message));
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error);
    yield put(changeApplicantFail(error));
  }
}

function* fetchDeliveryMethods({ payload }) {
  try {
    const response = yield call(adminApi.getDeliveryMethods, payload);
    if (response.success) {
      yield put(operatorGetDeliveryMethodsSuccess(response.data));
    } else {
      yield put(operatorGetDeliveryMethodsFail(response));
    }
  } catch (error) {
    yield put(operatorGetDeliveryMethodsFail(error));
  }
}

function* fetchDeliveryAddresses({ payload }) {
  try {
    const response = yield call(adminApi.getDeliveryAddresses, payload);
    if (response.success) {
      yield put(operatorGetDeliveryAddresses.success(response.data));
    } else {
      yield put(operatorGetDeliveryAddresses.failure(response));
    }
  } catch (error) {
    yield put(operatorGetDeliveryAddresses.failure(error));
  }
}

function* fetchDeliveryShipments({ payload }) {
  try {
    const response = yield call(adminApi.getDeliveryShipments, payload);
    if (response.success) {
      yield put(operatorGetDeliveryShipments.success(response.data));
    } else {
      yield put(operatorGetDeliveryShipments.failure(response));
    }
  } catch (error) {
    yield put(operatorGetDeliveryShipments.failure(error));
  }
}

function* fetchDeliveryShipment({ payload }) {
  try {
    const response = yield call(adminApi.getDeliveryShipment, payload);
    if (response.success) {
      yield put(operatorGetDeliveryShipment.success(response.data));
    } else {
      yield put(operatorGetDeliveryShipment.failure(response));
    }
  } catch (error) {
    yield put(operatorGetDeliveryShipment.failure(error));
  }
}

function* deleteDeliveryShipmentSaga({ payload }) {
  try {
    const response = yield call(adminApi.deleteDelivery, payload.id);
    if (response.success) {
      yield put(operatorDeliveryShipmentDelete.success(payload.id));
      showToastSuccess(i18n.t('delivery.deleted'));
      payload.handleCloseModal();
    } else {
      showToastError(response.message);
      yield put(operatorDeliveryShipmentDelete.failure(response));
    }
  } catch (error) {
    yield put(operatorDeliveryShipmentDelete.failure(error));
  }
}

function* createDeliveryShipments({ payload }) {
  try {
    const response = yield call(adminApi.createDeliveryShipments, payload);
    if (response.success) {
      yield put(operatorCreateDeliveryShipments.success(response.data));
      yield put(operatorGetDeliveryShipments());
      showToastSuccess(i18n.t('Shipments successfully created'));
    } else {
      showToastError(response.message);
      yield put(operatorCreateDeliveryShipments.failure(response));
    }
  } catch (error) {
    yield put(operatorCreateDeliveryShipments.failure(error));
  }
}

function* updateDeliveryShipments({ payload: { id, ...payload } }) {
  try {
    const response = yield call(adminApi.updateDeliveryShipments, id, payload);
    if (response.success) {
      yield put(operatorUpdateDeliveryShipments.success(response.data));
      yield put(operatorGetDeliveryShipments());
      showToastSuccess(i18n.t('notifications.deliveryUpdated'));
    } else {
      showToastError(response.message);
      yield put(operatorUpdateDeliveryShipments.failure(response));
    }
  } catch (error) {
    yield put(operatorUpdateDeliveryShipments.failure(error));
  }
}

function* ShipmentsUpsCreate({ payload }) {
  try {
    const response = yield call(adminApi.deliveryCreateUps, payload);
    if (response.success) {
      yield put(operatorDeliveryShipmentsUpsCreate.success(response.data));
    } else {
      showToastError(response.message);
      yield put(operatorDeliveryShipmentsUpsCreate.failure(response));
    }
  } catch (error) {
    yield put(operatorDeliveryShipmentsUpsCreate.failure(error));
  }
}

function* ShipmentsUpsGet({ payload }) {
  try {
    const response = yield call(adminApi.deliveryGetUps, payload);
    if (response.success) {
      yield put(operatorDeliveryShipmentsUpsGet.success(response.data));
    } else {
      showToastError(response.message);
      yield put(operatorDeliveryShipmentsUpsGet.failure(response));
    }
  } catch (error) {
    yield put(operatorDeliveryShipmentsUpsGet.failure(error));
  }
}

function* deleteServiceSaga({ payload }) {
  try {
    const response = yield call(adminApi.deleteServiceRequest, payload.id);
    if (response.success) {
      showToastSuccess(i18n.t('services.deleted'));

      yield put(operatorDeleteService.success(payload.id));

      payload.handleCloseModal();
    } else {
      showToastError(response.message);
      yield put(operatorDeleteService.failure(response));
    }
  } catch (error) {
    showToastError(error.message);
    yield put(operatorDeleteService.failure(error));
  }
}

function* adminSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(GET_USER, fetchUser);
  yield takeEvery(GET_LOGS, fetchLogs);
  yield takeEvery(GET_ORGANIZATIONS, fetchOrganizations);
  yield takeEvery(GET_ORGANIZATION, fetchOrganization);
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
  yield takeEvery(GET_SERVICES, fetchServices);
  yield takeEvery(GET_FILTERED_SERVICES, fetchFilteredServices);
  yield takeEvery(UPDATE_USER, editUser);
  yield takeEvery(DELETE_USER, deleteUserSaga);
  yield takeEvery(BLOCK_USER, blockUserSaga);
  yield takeEvery(inviteUser, inviteUserSaga);
  yield takeEvery(UNBLOCK_USER, unBlockUserSaga);
  yield takeEvery(UPDATE_SERVICES, editService);
  yield takeEvery(GET_SERVICE, fetchService);
  yield takeEvery(CREATE_USER, addUser);
  yield takeEvery(CREATE_ORGANIZATION, addOrganization);
  yield takeEvery(UPDATE_ORGANIZATION, editOrganization);
  yield takeEvery(CREATE_SERVICES, addService);
  yield takeEvery(GET_ORDERS, fetchOrders);
  yield takeEvery(GET_ORDERS_FEED, fetchOrdersFeed);
  yield takeEvery(CREATE_ORDER, addOrder);
  yield takeEvery(ORDERS_STATUS_CHANGE, statusChange);
  yield takeEvery(GET_ORDER, fetchOrder);
  yield takeEvery(UPDATE_ORDER, editOrder);

  yield takeEvery(ORDERS_CREATE_CUSTOMER, createOrderCustomer);
  yield takeEvery(ORDERS_UPDATE_CUSTOMER, updateOrderCustomer);
  yield takeEvery(ORDERS_DELETE_CUSTOMER, deleteOrderCustomer);

  yield takeEvery(UPLOAD_DOCUMENT, uploadFile);
  yield takeEvery(ATTACH_MANAGER_TO_ORGANIZATION, attachManager);
  yield takeEvery(
    GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST,
    fetchApplicantsOrganizationList,
  );
  yield takeEvery(CHANGE_APPLICANT, editApplicant);
  yield takeEvery(GET_OPERATOR_APPLICANTS_USER_LIST, fetchApplicantsUserList);
  yield takeEvery(OPERATOR_ADD_APPLICANT, operatorAddApplicant);
  yield takeEvery(OPERATOR_GET_DELIVERY_METHODS, fetchDeliveryMethods);
  yield takeEvery(operatorGetDeliveryAddresses, fetchDeliveryAddresses);
  yield takeEvery(operatorGetDeliveryShipments, fetchDeliveryShipments);
  yield takeEvery(operatorGetDeliveryShipment, fetchDeliveryShipment);
  yield takeEvery(operatorCreateDeliveryShipments, createDeliveryShipments);
  yield takeEvery(operatorUpdateDeliveryShipments, updateDeliveryShipments);
  yield takeEvery(operatorDeliveryShipmentsUpsCreate, ShipmentsUpsCreate);
  yield takeEvery(operatorDeliveryShipmentsUpsGet, ShipmentsUpsGet);
  yield takeEvery(operatorDeliveryShipmentDelete, deleteDeliveryShipmentSaga);
  yield takeEvery(operatorDeleteService, deleteServiceSaga);
}

export default adminSaga;

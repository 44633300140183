import { takeEvery, put, call } from 'redux-saga/effects';
import {
  addMessage,
  changeChatStatus,
  getChatDetail,
  getChats,
  getMessages,
  getMoreMessages,
} from './actions';
import * as adminApi from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';
import i18n from 'i18n';

function* getChatsSaga({ payload }) {
  try {
    const response = yield call(adminApi.getChats, payload);
    if (response.success) {
      yield put(getChats.success(response.data));
    } else {
      yield put(getChats.failure(response.message));
      showToastError(i18n.t('notifications.errorLoadChats'));
    }
  } catch (error) {
    yield put(getChats.failure(error));
  }
}

function* getMessagesSaga({ payload }) {
  try {
    const response = yield call(adminApi.getMessages, payload);
    if (response.success) {
      yield put(
        getMessages.success({
          ...response.data,
          messages: response.data.results,
        }),
      );
    } else {
      yield put(getMessages.failure(response.message));
      showToastError(i18n.t('notifications.errorsLoadMessages'));
    }
  } catch (error) {
    yield put(getMessages.failure(error));
  }
}

function* getMoreMessagesSaga({ payload }) {
  try {
    const response = yield call(adminApi.getMessages, payload);
    if (response.success) {
      yield put(
        getMoreMessages.success({
          ...response.data,
          messages: response.data.results,
        }),
      );
    } else {
      yield put(getMoreMessages.failure(response.message));
      showToastError(i18n.t('notifications.errorsLoadMessages'));
    }
  } catch (error) {
    yield put(getMoreMessages.failure(error));
  }
}

function* addMessageSaga({ payload }) {
  try {
    const response = yield call(adminApi.addMessage, payload);
    if (response.success) {
      yield put(addMessage.success(response.data));
      const el = document.getElementById('scrollableDiv');

      el?.scrollTo(0, el.scrollHeight);
    } else {
      yield put(addMessage.failure(response.message));
      showToastError(i18n.t('notifications.errorPostMessage'));
    }
  } catch (error) {
    yield put(addMessage.failure(error));
  }
}

function* changeChatStatusSaga({ payload }) {
  try {
    const response = yield call(adminApi.changeChatStatus, payload);
    if (response.success) {
      yield put(changeChatStatus.success(response.data));
      showToastSuccess(i18n.t('notifications.chatStatusChange'));
    } else {
      yield put(changeChatStatus.failure(response.message));
      showToastError(i18n.t('notifications.errorChatStatusChange'));
    }
  } catch (error) {
    yield put(changeChatStatus.failure(error));
  }
}

function* getChatDetailSaga({ payload }) {
  try {
    const response = yield call(adminApi.getChat, payload.id);
    if (response.success) {
      yield put(getChatDetail.success(response.data.results[0]));
    } else {
      yield put(getChatDetail.failure(response.message));
      showToastError(i18n.t('notifications.getChatError'));
    }
  } catch (error) {
    yield put(getChatDetail.failure(error));
  }
}

function* chatSaga() {
  yield takeEvery(getChats, getChatsSaga);
  yield takeEvery(getMessages, getMessagesSaga);
  yield takeEvery(getMoreMessages, getMoreMessagesSaga);
  yield takeEvery(addMessage, addMessageSaga);
  yield takeEvery(changeChatStatus, changeChatStatusSaga);
  yield takeEvery(getChatDetail, getChatDetailSaga);
}

export default chatSaga;

import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  collectAddresses,
  createAddresses,
  deleteAddress,
  getAddresses,
  selectAddresses,
  updateAddress,
} from './actions';

const initialState = {
  isLoading: false,
  addresses: [],
  availableAddresses: [],
  selectedAddressValueFrom: undefined,
  selectedAddressValueTo: undefined,
};

const addresses = createReducer(initialState, {
  [getAddresses]: state => ({ ...state, isLoading: true }),
  [getAddresses.failure]: state => ({ ...state, isLoading: false }),
  [getAddresses.success]: (state, { payload }) => ({
    ...state,
    addresses: payload,
    isLoading: false,
  }),

  [deleteAddress]: state => ({ ...state }),
  [deleteAddress.failure]: state => ({ ...state }),
  [deleteAddress.success]: (state, { payload }) => ({
    ...state,
    addresses: state.addresses.filter(address => address.id !== payload),
  }),

  [createAddresses]: state => ({ ...state }),
  [createAddresses.failure]: state => ({ ...state }),
  [createAddresses.success]: (state, { payload }) => ({
    ...state,
    addresses: [...state.addresses, payload],
  }),

  [updateAddress]: state => ({ ...state }),
  [updateAddress.failure]: state => ({ ...state }),
  [updateAddress.success]: (state, { payload }) => ({
    ...state,
    addresses: state.addresses.map(address => {
      if (address.id === payload.id) {
        return payload.address;
      }

      return address;
    }),
  }),

  [collectAddresses]: state => {
    return {
      ...state,
    };
  },
  [collectAddresses.success]: (state, { payload }) => {
    return {
      ...state,
      availableAddresses: payload,
    };
  },

  [selectAddresses]: (state, { payload: { prefix, value } }) => ({
    ...state,
    [prefix]: value,
  }),
});

export default combineReducers({
  addresses,
});

import { ShadowCard } from 'components/Common/ShadowCard';
import React, { memo, useState } from 'react';
import { Accordion } from '../../Common/Accordion';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { DeliveryCard } from './DeliveryCard';
import { DeliveryEdit } from './DeliveryEdit';
import { useSelector } from 'react-redux';
import { convertDeliveryFromApi } from 'helpers/converters';
import { deleteDelivery } from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';

export const OrderDelivery = memo(() => {
  const { order } = useSelector(state => state.order);

  const [deliveries, setDeliveries] = useState(
    convertDeliveryFromApi(order.order.shipments),
  );
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(null);

  const handleOpenDeleteModal = id => setIsOpenModalDelete(id);

  const handleCloseModal = () => setIsOpenModalDelete(null);

  const handleDeleteDelivery = () => {
    deleteDelivery(isOpenModalDelete)
      .then(response => {
        if (response.success) {
          showToastSuccess(response.message);
          setDeliveries(prev =>
            prev.filter(delivery => delivery.id !== isOpenModalDelete),
          );
          handleCloseModal();
          return;
        }

        showToastError(response.message);
      })
      .catch(err => showToastError(err));
  };

  return (
    <ShadowCard id="delivery" className="mt-4" bodyStyle={{ padding: '24px' }}>
      <Accordion title="delivery.delivery">
        <div className="mt-4">
          {isOpenModalDelete && (
            <ModalConfirmDelete
              textHeader="delivery.deleteHeader"
              textBody="delivery.deleteBody"
              handleCloseModal={handleCloseModal}
              isOpenModal={isOpenModalDelete}
              handleDelete={handleDeleteDelivery}
            />
          )}

          {deliveries.length > 0 &&
            deliveries.map((delivery, index) => (
              <DeliveryCard
                key={index}
                deliveries={deliveries}
                setDeliveries={setDeliveries}
                index={index}
                handleOpenModal={handleOpenDeleteModal}
                delivery={delivery}
              />
            ))}

          <DeliveryEdit deliveries={deliveries} setDeliveries={setDeliveries} />
        </div>
      </Accordion>
    </ShadowCard>
  );
});

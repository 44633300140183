import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { fetchTariffs } from 'store/tariffs/actions';

import { StyledTable } from 'components/Table/StyledTable';
import { CardTitle, ShadowCard } from 'components/Common';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { gitTariffsState, ServiceTariffRow } from './ServiceTariffRow';
import { GENERAL_SERVICE_VALUE } from 'common/data/services';

export const ServicesTariffForm = () => {
  const formik = useFormikContext();
  const { tariffs } = useSelector(gitTariffsState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTariffs());
  }, []);

  const name = 'tariffs';

  return (
    <ShadowCard id="tariffs" bodyStyle={{ padding: '24px' }} className="mt-4">
      <Row>
        <FieldArray
          name={name}
          validateOnChange={false}
          render={({ push, remove }) => (
            <Col xs={12}>
              <CardTitle title="Tariffs" />

              {formik.values.tariffs?.length > 0 && (
                <StyledTable $headerShort className="mb-0">
                  <thead>
                    <tr>
                      <th>{t('common.tariff')}</th>

                      {formik.values.serviceType === GENERAL_SERVICE_VALUE && (
                        <th>{t('services.autoSelect')}</th>
                      )}

                      <th>
                        <div className="d-flex w-100 aling-items-center justify-content-center">
                          {t('services.tags')}
                        </div>
                      </th>

                      <th>{t('services.price')}</th>

                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {formik.values.tariffs?.length > 0 &&
                      formik.values.tariffs.map((tariff, index) => (
                        <ServiceTariffRow
                          key={index}
                          tariff={tariff}
                          index={index}
                          handleDeleteRow={() => remove(index)}
                        />
                      ))}
                  </tbody>
                </StyledTable>
              )}

              {tariffs.length > (formik.values.tariffs?.length ?? 0) && (
                <StyledUpload
                  onClick={() =>
                    push({
                      tariff: {},
                      price: '€0.00',
                    })
                  }
                  style={{ padding: '0 32px' }}
                  className="py-2 mt-3 d-flex flex-align-center"
                >
                  <i className="bx bx-plus fs-5" /> {t('services.addTariff')}
                </StyledUpload>
              )}
            </Col>
          )}
        ></FieldArray>
      </Row>
    </ShadowCard>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { Select } from '../Common/Select';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getOrderTypesRequest } from 'helpers/api-requests/admin';

export const SelectOrderType = ({
  id,
  invalid,
  name,
  value,
  onChange,
  placeholder = 'order.chooseOrderType',
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [orderTypes, setOrderTypes] = useState([]);

  const orderTypesConverted =
    orderTypes &&
    orderTypes?.map(item => ({
      value: item.id,
      label: item.translations?.[i18n.language],
    }));

  useEffect(() => {
    getOrderTypesRequest().then(response => {
      if (response.success) {
        setOrderTypes(response.data.results);
      }
    });
  }, []);

  const val = useMemo(() => {
    if (isNil(value)) {
      return null;
    }

    return orderTypesConverted.find(item => item.value === value) ?? null;
  }, [value, orderTypes, i18n.language]);

  return (
    <Select
      value={val}
      onChange={onChange}
      name={name}
      inputId={name}
      classNamePrefix="select2-selection"
      className="vbo-select display-5"
      options={orderTypesConverted}
      id={id}
      invalid={invalid}
      placeholder={t(placeholder)}
      isClearable
      {...props}
    />
  );
};

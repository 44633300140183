import {
  downloadAggregateInvoiceFile,
  getShipmentLabelRequest,
} from './api-requests/admin';
import { fetchDocument } from './api-requests/employee';
import { showToastError } from './utils/toast';

export const downloadDocument = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const getAggregatedInvoiceFile = async (report, typeFile) => {
  try {
    const response = await downloadAggregateInvoiceFile(report.id, typeFile, {
      responseType: 'blob',
    });
    downloadDocument(response, `Aggregated invoice ${report.number}`);
  } catch (error) {
    showToastError(error.message);
  }
};

export const getDocumentOnClick = async (document, fileName) => {
  try {
    const result = await fetchDocument(document.id);
    downloadDocument(result, fileName);
  } catch (error) {
    showToastError(error);
  }
};

export const getShipmentLabelPdf = async delivery => {
  try {
    const response = await getShipmentLabelRequest(delivery.id, {
      responseType: 'blob',
    });
    downloadDocument(response, `Shipment label_${delivery.id}`);
  } catch (error) {
    showToastError(error.message);
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikInput from 'components/Common/FormikInput';
import { Button, Col, Row } from 'reactstrap';

import { useHistory } from 'react-router-dom';

import { FORGET_PASSWORD_VALIDATOR } from 'forms/validators';
import { useFormik } from 'formik';
import { postJwtForgetPwd } from 'helpers/api-requests/auth';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import { ShadowCard } from 'components/Common';
import { StyledTextHead } from './Login';
import { NON_AUTH_PADDING } from 'helpers/styles/constants';

const initialValues = {
  username: '',
};

const ForgetPassword = () => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const handleResetPassword = values => {
    postJwtForgetPwd(values)
      .then(response => {
        if (response.success) {
          showToastSuccess(t('auth.notificationLink'));
          push('/login');
        } else {
          showToastError(response.message);
        }
      })
      .catch(error => showToastError(error));
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: FORGET_PASSWORD_VALIDATOR,
    onSubmit: handleResetPassword,
  });

  return (
    <ShadowCard
      className="m-4"
      bodyStyle={{ ...NON_AUTH_PADDING, maxWidth: 530 }}
    >
      <StyledTextHead className="mb-4">
        {t('auth.resetPassword')}
      </StyledTextHead>

      <p className="m-0 mb-4 text-muted fs-6 text-wrap">
        {t('auth.sendLinkText')}
      </p>

      <Row>
        <Col xs={12}>
          <FormikInput
            name="username"
            value={formik.values.username}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label={t('auth.username')}
            errors={
              formik.errors.username && formik.touched.username
                ? formik.errors.username
                : null
            }
            className="form-control"
            placeholder={t('Enter username')}
          />
        </Col>
      </Row>

      <Button
        style={{ padding: '12px 0' }}
        color="primary"
        block
        onClick={formik.handleSubmit}
      >
        {t('auth.resetPassword')}
      </Button>

      <Button
        className="bg-transparent border-0 text-primary mt-4 text-decoration-underline"
        block
        onClick={() => push('/login')}
        type="link"
      >
        {t('auth.goToLogin')}
      </Button>
    </ShadowCard>
  );
};

export default ForgetPassword;

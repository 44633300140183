export const GET_WIZARD = 'GET_WIZARD';
export const GET_WIZARD_SUCCESS = 'GET_WIZARD_SUCCESS';
export const GET_WIZARD_FAIL = 'GET_WIZARD_FAIL';

export const GET_WIZARD_STEP = 'GET_WIZARD_STEP';
export const GET_WIZARD_STEP_SUCCESS = 'GET_WIZARD_STEP_SUCCESS';
export const GET_WIZARD_STEP_FAIL = 'GET_WIZARD_STEP_FAIL';

export const GET_WIZARD_NEXT_STEP = 'GET_WIZARD_NEXT_STEP';
export const GET_WIZARD_NEXT_STEP_SUCCESS = 'GET_WIZARD_NEXT_STEP_SUCCESS';
export const GET_WIZARD_NEXT_STEP_FAIL = 'GET_WIZARD_NEXT_STEP_FAIL';

export const GET_VALIDATED_WIZARD_STEP = 'GET_VALIDATED_WIZARD_STEP';
export const GET_VALIDATED_WIZARD_STEP_SUCCESS =
  'GET_VALIDATED_WIZARD_STEP_SUCCESS';
export const GET_VALIDATED_WIZARD_STEP_FAIL = 'GET_VALIDATED_WIZARD_STEP_FAIL';

export const SET_WIZARD_FORM_FIELD = 'SET_WIZARD_FORM_FIELD';
export const SET_WIZARD_META = 'SET_WIZARD_META';
export const SET_WIZARD_FILE_FIELD = 'SET_WIZARD_FILE_FIELD';
export const SET_WIZARD_HELPER_FIELD = 'SET_WIZARD_HELPER_FIELD';
export const SET_WIZARD_FIELDS_BY_RULES = 'SET_WIZARD_FIELDS_BY_RULES';
export const SET_WIZARD_INITIAL = 'SET_WIZARD_INITIAL';

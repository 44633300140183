import { takeEvery, put, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER } from './actionTypes';
import { registerUserSuccessful, registerUserFailed } from './actions';
import { postJwtSignup } from '../../../helpers/api-requests/auth';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload }) {
  try {
    const response = yield call(postJwtSignup, payload.user);
    if (response.success) {
      showToastSuccess(response.message);
      yield put(registerUserSuccessful(response));

      if (payload.nextStep) {
        payload.nextStep();
      }
    } else {
      yield put(registerUserFailed(response.message));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(registerUserFailed(error));
    showToastError(error);
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export default accountSaga;

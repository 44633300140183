import styled from 'styled-components';
import { StyledTable } from './StyledTable';

export const StyledTableOrders = styled(StyledTable)`
  td {
    font-size: 13px;
    padding: 12px 10px;
  }
  th {
    padding: 12px 10px;
    vertical-align: middle;
  }
  tr.even {
    background: #f8f8fb;
  }
  tr:hover td {
    background: #fffbf5;
  }
`;

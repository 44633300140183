import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { downloadDocument } from 'helpers/downloadDocument';
import { showToastError } from 'helpers/utils/toast';
import { INVOICES_BREADCRUMBS } from 'common/data/beadcrumbs';

import { getInvoiceDetail } from 'store/actions';
import { downloadInvoicePDF } from 'helpers/api-requests/admin';
import { GeneratePDF } from 'components/Invoices/GeneratePDF';
import { NavBar } from 'components/Common/NavBar';
import {
  StyledCreate,
  StyledDiv,
} from 'components/HeadControllButtons/HeadControllButtons';
import { InvoiceInformationCard } from './InvoiceInformationCard';
import { InvoiceServiceCard } from './InvoceServiceCard';
import { InvoicePaymentCard } from './InvoicePaymentCard';
import { Badge } from 'components/Common';
import { INVOICE_STATUS } from 'common/data/constants';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import styled from 'styled-components';
import { useModal } from 'hooks/useModal';
import { ModalPayment } from 'components/Common/ModalPayment';
import { useTranslation } from 'react-i18next';
import { isUserIndividual } from 'helpers/jwt_helper';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { LayoutDetailPages } from 'hoc/LayoutDetailPages';
import { useMemo } from 'react';
import classNames from 'classnames';

const invoiceNav = [
  { title: 'common.information', id: 1, link: 'information', offset: -150 },
  { title: 'invoices.services', id: 2, link: 'services' },
  { title: 'invoices.forPayment', id: 3, link: 'payment' },
];

const StyledBadgeContainer = styled.div`
  margin: 12px 0px;
`;

const Invoice = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { push } = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const invoiceDetails = useSelector(state => state.invoices.invoiceDetail);
  const { isOpen, handleCloseModal, handleOpenModal } = useModal();

  const fetchInvoice = useCallback(
    () => dispatch(getInvoiceDetail(id)),
    [dispatch],
  );

  useEffect(() => {
    fetchInvoice();
  }, []);

  const getPdf = async () => {
    try {
      const response = await downloadInvoicePDF(id, { responseType: 'blob' });
      downloadDocument(response, `invoice-${id}.pdf`);
    } catch (error) {
      showToastError(error.message);
    }
  };

  const handlePushToOrder = () =>
    push(`/orders/${invoiceDetails.details.order?.id}`);

  const layoutConfig = useMemo(
    () => ({
      title: 'invoices.detail',
      loading: invoiceDetails.isLoading,
      isScrollableHeader: true,
      breadcrumbs: INVOICES_BREADCRUMBS,
      extraContent: (
        <StyledDiv
          className={classNames(
            'd-flex w-100 justify-content-end align-items-center',
          )}
        >
          {state?.fromOrder && (
            <StyledEmptyButton
              className="me-4 d-flex align-items-center"
              onClick={handlePushToOrder}
            >
              <i className="bx bxs-chevron-left me-2" />{' '}
              {t('invoices.backOrder')}
            </StyledEmptyButton>
          )}

          <GeneratePDF onClick={getPdf} />

          {isUserIndividual &&
            (invoiceDetails.details.status === 'wait_payment' ||
              invoiceDetails.details.status === 'draft') && (
              <StyledCreate
                $customPadding
                className="ms-4"
                onClick={handleOpenModal}
              >
                <i className="bx bx-check" />

                <span>{t('payments.pay')}</span>
              </StyledCreate>
            )}
        </StyledDiv>
      ),
    }),
    [invoiceDetails.details, invoiceDetails.isLoading],
  );

  return (
    <LayoutDetailPages
      navBarContent={
        <NavBar
          scrollableHeader
          headerComponent={
            <>
              <span>№{invoiceDetails.details.number}</span>

              {!(
                isUserIndividual && invoiceDetails.details.status === 'draft'
              ) && (
                <StyledBadgeContainer className="d-flex">
                  <Badge
                    className="text-wrap"
                    color={convertStatusToColor(invoiceDetails.details.status)}
                    text={INVOICE_STATUS[invoiceDetails.details.status]}
                  />
                </StyledBadgeContainer>
              )}
            </>
          }
          components={invoiceNav}
        />
      }
      layoutConfig={layoutConfig}
    >
      {isOpen && (
        <ModalPayment
          orderId={invoiceDetails.details.order?.id}
          billId={id}
          handleCloseModal={handleCloseModal}
          isOpenModal={isOpen}
        />
      )}

      <InvoiceInformationCard invoiceDetails={invoiceDetails} />

      <InvoiceServiceCard invoiceDetails={invoiceDetails} />

      <InvoicePaymentCard invoiceDetails={invoiceDetails} />
    </LayoutDetailPages>
  );
};

export default Invoice;

import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  BLOCK_USER,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UNBLOCK_USER,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_FAIL,
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAIL,
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATIONS_FAIL,
  GET_ORGANIZATION,
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  GET_SERVICE,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_FAIL,
  GET_SERVICES,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  CREATE_SERVICES,
  CREATE_SERVICES_SUCCESS,
  CREATE_SERVICES_FAIL,
  UPDATE_SERVICES,
  UPDATE_SERVICES_SUCCESS,
  UPDATE_SERVICES_FAIL,
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  GET_ORDER,
  GET_ORDER_FAIL,
  GET_ORDER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAIL,
  ATTACH_MANAGER_TO_ORGANIZATION,
  GET_ORDERS_FEED,
  GET_ORDERS_FEED_SUCCESS,
  GET_ORDERS_FEED_FAIL,
  GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST,
  GET_OPERATOR_APPLICANTS_USER_LIST,
  GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_SUCCESS,
  GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_FAIL,
  GET_OPERATOR_APPLICANTS_USER_LIST_SUCCESS,
  GET_OPERATOR_APPLICANTS_USER_LIST_FAIL,
  OPERATOR_ADD_APPLICANT,
  OPERATOR_ADD_APPLICANT_SUCCESS,
  OPERATOR_ADD_APPLICANT_FAIL,
  ORDERS_CREATE_CUSTOMER,
  ORDERS_CREATE_CUSTOMER_SUCCESS,
  ORDERS_CREATE_CUSTOMER_FAIL,
  ORDERS_UPDATE_CUSTOMER,
  ORDERS_UPDATE_CUSTOMER_SUCCESS,
  ORDERS_UPDATE_CUSTOMER_FAIL,
  ORDERS_DELETE_CUSTOMER,
  ORDERS_DELETE_CUSTOMER_SUCCESS,
  ORDERS_DELETE_CUSTOMER_FAIL,
  CREATE_ORDER_INIT,
  ORDERS_STATUS_CHANGE,
  ORDERS_STATUS_CHANGE_SUCCESS,
  ORDERS_STATUS_CHANGE_FAIL,
  OPERATOR_GET_DELIVERY_METHODS_SUCCESS,
  OPERATOR_GET_DELIVERY_METHODS_FAIL,
  OPERATOR_GET_DELIVERY_METHODS,
  GET_FILTERED_SERVICES,
  GET_FILTERED_SERVICES_SUCCESS,
  GET_FILTERED_SERVICES_FAIL,
  OPERATOR_CHANGE_APPLICANT,
  CLEAN_ADD_APPLICANT_STATE,
} from './actionTypes';
import { createQueryActions } from '../../helpers/store/createQueryActions';

// User List
export const getUsers = query => ({
  type: GET_USERS,
  payload: query,
});

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
});

// Services list
export const getServices = query => ({
  type: GET_SERVICES,
  payload: query,
});

export const getServicesSuccess = services => ({
  type: GET_SERVICES_SUCCESS,
  payload: services,
});

export const getServicesFail = error => ({
  type: GET_SERVICES_FAIL,
  payload: error,
});

export const getFilteredServices = query => ({
  type: GET_FILTERED_SERVICES,
  payload: query,
});

export const getFilteredServicesSuccess = services => ({
  type: GET_FILTERED_SERVICES_SUCCESS,
  payload: services,
});

export const getFilteredServicesFail = error => ({
  type: GET_FILTERED_SERVICES_FAIL,
  payload: error,
});

export const getService = param => ({
  type: GET_SERVICE,
  payload: param,
});

export const getServiceSuccess = service => ({
  type: GET_SERVICE_SUCCESS,
  payload: service,
});

export const getServiceFail = error => ({
  type: GET_SERVICE_FAIL,
  payload: error,
});

export const updateService = param => ({
  type: UPDATE_SERVICES,
  payload: param,
});

export const updateServiceSuccess = service => ({
  type: UPDATE_SERVICES_SUCCESS,
  payload: service,
});

export const updateServiceFail = error => ({
  type: UPDATE_SERVICES_FAIL,
  payload: error,
});

export const createService = param => ({
  type: CREATE_SERVICES,
  payload: param,
});

export const createServiceSuccess = service => ({
  type: CREATE_SERVICES_SUCCESS,
  payload: service,
});

export const createServiceFail = error => ({
  type: CREATE_SERVICES_FAIL,
  payload: error,
});

// User Profile
export const getUser = param => ({
  type: GET_USER,
  payload: param,
});

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user,
});

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
});

export const updateUser = param => ({
  type: UPDATE_USER,
  payload: param,
});

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const blockUser = param => ({
  type: BLOCK_USER,
  payload: param,
});

export const blockUserSuccess = user => ({
  type: BLOCK_USER_SUCCESS,
  payload: user,
});

export const blockUserFail = error => ({
  type: BLOCK_USER_FAIL,
  payload: error,
});

export const deleteUser = param => ({
  type: DELETE_USER,
  payload: param,
});

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const unBlockUser = param => ({
  type: UNBLOCK_USER,
  payload: param,
});

export const unBlockUserSuccess = user => ({
  type: UNBLOCK_USER_SUCCESS,
  payload: user,
});

export const unBlockUserFail = error => ({
  type: UNBLOCK_USER_FAIL,
  payload: error,
});

export const inviteUser = createQueryActions('inviteUser');

export const createUser = param => ({
  type: CREATE_USER,
  payload: param,
});

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
});

export const createUserFail = error => ({
  type: CREATE_USER_FAIL,
  payload: error,
});

//Single organization
export const getOrganization = param => ({
  type: GET_ORGANIZATION,
  payload: param,
});

export const getOrganizationSuccess = organization => ({
  type: GET_ORGANIZATION_SUCCESS,
  payload: organization,
});

export const getOrganizationFail = error => ({
  type: GET_ORGANIZATION_FAIL,
  payload: error,
});

export const updateOrganization = param => ({
  type: UPDATE_ORGANIZATION,
  payload: param,
});

export const updateOrganizationSuccess = organization => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload: organization,
});

export const updateOrganizationFail = error => ({
  type: UPDATE_ORGANIZATION_FAIL,
  payload: error,
});

export const createOrganization = param => ({
  type: CREATE_ORGANIZATION,
  payload: param,
});

export const createOrganizationSuccess = organization => ({
  type: CREATE_ORGANIZATION_SUCCESS,
  payload: organization,
});

export const createOrganizationFail = error => ({
  type: CREATE_ORGANIZATION_FAIL,
  payload: error,
});

// Logs list
export const getLogs = query => ({
  type: GET_LOGS,
  payload: query,
});

export const getLogsSuccess = logs => ({
  type: GET_LOGS_SUCCESS,
  payload: logs,
});

export const getLogsFail = error => ({
  type: GET_LOGS_FAIL,
  payload: error,
});

// Organization List
export const getOrganizations = query => ({
  type: GET_ORGANIZATIONS,
  payload: query,
});

export const getOrganizationsSuccess = organizations => ({
  type: GET_ORGANIZATIONS_SUCCESS,
  payload: organizations,
});

export const getOrganizationsFail = error => ({
  type: GET_ORGANIZATIONS_FAIL,
  payload: error,
});

//orders
export const getOrders = query => ({
  type: GET_ORDERS,
  payload: query,
});

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
});

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
});

//orders feed
export const getOrdersFeed = query => ({
  type: GET_ORDERS_FEED,
  payload: query,
});

export const getOrdersFeedSuccess = events => ({
  type: GET_ORDERS_FEED_SUCCESS,
  payload: events,
});

export const getOrdersFeedFail = error => ({
  type: GET_ORDERS_FEED_FAIL,
  payload: error,
});

export const updateOrder = params => ({
  type: UPDATE_ORDER,
  payload: params,
});

export const updateOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
});

export const updateOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
});

export const createOrderInit = () => ({
  type: CREATE_ORDER_INIT,
});

export const createOrder = param => ({
  type: CREATE_ORDER,
  payload: param,
});

export const createOrderSuccess = order => ({
  type: CREATE_ORDER_SUCCESS,
  payload: order,
});

export const createOrderFail = error => ({
  type: CREATE_ORDER_FAIL,
  payload: error,
});

export const getOrder = param => ({
  type: GET_ORDER,
  payload: param,
});

export const getOrderSuccess = order => ({
  type: GET_ORDER_SUCCESS,
  payload: order,
});

export const getOrderFail = error => ({
  type: GET_ORDER_FAIL,
  payload: error,
});

export const ordersCreateCustomer = payload => ({
  type: ORDERS_CREATE_CUSTOMER,
  payload,
});

export const ordersCreateCustomerSuccess = payload => ({
  type: ORDERS_CREATE_CUSTOMER_SUCCESS,
  payload,
});

export const ordersCreateCustomerFail = err => ({
  type: ORDERS_CREATE_CUSTOMER_FAIL,
  payload: err,
});

export const ordersStatusChange = payload => ({
  type: ORDERS_STATUS_CHANGE,
  payload,
});

export const ordersStatusChangeSuccess = payload => ({
  type: ORDERS_STATUS_CHANGE_SUCCESS,
  payload,
});

export const ordersStatusChangeFail = err => ({
  type: ORDERS_STATUS_CHANGE_FAIL,
  payload: err,
});

export const ordersUpdateCustomer = payload => ({
  type: ORDERS_UPDATE_CUSTOMER,
  payload,
});

export const ordersUpdateCustomerSuccess = payload => ({
  type: ORDERS_UPDATE_CUSTOMER_SUCCESS,
  payload,
});

export const ordersUpdateCustomerFail = err => ({
  type: ORDERS_UPDATE_CUSTOMER_FAIL,
  payload: err,
});

export const ordersDeleteCustomer = payload => ({
  type: ORDERS_DELETE_CUSTOMER,
  payload,
});

export const ordersDeleteCustomerSuccess = payload => ({
  type: ORDERS_DELETE_CUSTOMER_SUCCESS,
  payload,
});

export const ordersDeleteCustomerFail = err => ({
  type: ORDERS_DELETE_CUSTOMER_FAIL,
  payload: err,
});

export const uploadDocument = param => ({
  type: UPLOAD_DOCUMENT,
  payload: param,
});

export const uploadDocumentSuccess = doc => ({
  type: UPLOAD_DOCUMENT_SUCCESS,
  payload: doc,
});

export const uploadDocumentFail = error => ({
  type: UPLOAD_DOCUMENT_FAIL,
  payload: error,
});

export const attachManagerToOrganization = param => ({
  type: ATTACH_MANAGER_TO_ORGANIZATION,
  payload: param,
});

// not used yet
export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
});

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
});

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
});

export const getApplicantsOrganizationList = query => ({
  type: GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST,
  payload: query,
});

export const getApplicantsOrganizationListSuccess = query => ({
  type: GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_SUCCESS,
  payload: query,
});

export const getApplicantsOrganizationListFail = error => ({
  type: GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_FAIL,
  payload: error,
});

export const getApplicantsUserList = query => ({
  type: GET_OPERATOR_APPLICANTS_USER_LIST,
  payload: query,
});

export const getApplicantsUserListSuccess = query => ({
  type: GET_OPERATOR_APPLICANTS_USER_LIST_SUCCESS,
  payload: query,
});

export const getApplicantsUserListFail = error => ({
  type: GET_OPERATOR_APPLICANTS_USER_LIST_FAIL,
  payload: error,
});

export const operatorAddApplicant = query => ({
  type: OPERATOR_ADD_APPLICANT,
  payload: query,
});

export const operatorAddApplicantSuccess = query => ({
  type: OPERATOR_ADD_APPLICANT_SUCCESS,
  payload: query,
});

export const operatorAddApplicantFail = error => ({
  type: OPERATOR_ADD_APPLICANT_FAIL,
  payload: error,
});

export const operatorGetDeliveryMethods = query => ({
  type: OPERATOR_GET_DELIVERY_METHODS,
  payload: query,
});

export const operatorGetDeliveryMethodsSuccess = query => ({
  type: OPERATOR_GET_DELIVERY_METHODS_SUCCESS,
  payload: query,
});

export const operatorGetDeliveryMethodsFail = error => ({
  type: OPERATOR_GET_DELIVERY_METHODS_FAIL,
  payload: error,
});

export const operatorChangeApplicant = data => ({
  type: OPERATOR_CHANGE_APPLICANT,
  payload: data,
});

export const cleanAddApplicantState = () => ({
  type: CLEAN_ADD_APPLICANT_STATE,
});

export const operatorGetDeliveryAddresses = createQueryActions(
  'operatorGetDeliveryAddresses',
);

export const operatorGetDeliveryShipments = createQueryActions(
  'operatorGetDeliveryShipments',
);

export const operatorGetDeliveryShipment = createQueryActions(
  'operatorGetDeliveryShipment',
);

export const operatorCreateDeliveryShipments = createQueryActions(
  'operatorCreateDeliveryShipments',
);

export const operatorUpdateDeliveryShipments = createQueryActions(
  'operatorUpdateDeliveryShipments',
);

export const operatorDeliveryShipmentsUpsCreate = createQueryActions(
  'operatorDeliveryShipmentsUpsCreate',
);

export const operatorDeliveryShipmentsUpsGet = createQueryActions(
  'operatorDeliveryShipmentsUpsGet',
);

export const operatorDeliveryShipmentDelete = createQueryActions(
  'operatorDeliveryShipmentDelete',
);

export const operatorDeleteService = createQueryActions(
  'operatorDeleteService',
);

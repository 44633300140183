import { typeColor } from './typeColor';

export const convertStatusToColor = status => {
  switch (status ? status.toLowerCase() : status) {
    // case 'at_the_consulate':
    // case 'visa_ready':
    // case 'active':
    case 'new':
    case 'in_progress':
    case 'on the way': // delivery
      return typeColor.primary;

    case 'wait_payment':
    case 'not_ready': // order list payment status
      return typeColor.info;

    // case 'invitation_ready':
    // case 'created':
    // case 'invites.accepted':
    case 'completed':
    case 'returned':
    case 'delivered': // delivery
    case 'paid': // order list payment status
      return typeColor.success;

    case 'cancelled':
    case 'declined':
    case 'blocked':
    case 'not issued': // delivery
    case 'not_paid': // order list payment status
      // case 'docs_missing':
      // case 'invites.cancelled':
      return typeColor.danger;
    default:
      return typeColor.dark;
  }
};

export const convertHslToRgb = (hue, saturation, lightness) => {
  lightness /= 100;
  const a = (saturation * Math.min(lightness, 1 - lightness)) / 100;

  const f = n => {
    const k = (n + hue / 30) % 12;
    const color = lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const converTextToHue = string => {
  if (string) {
    let hash = 0;

    string.split('').forEach((_, index) => {
      hash = string.charCodeAt(index) + ((hash << 5) - hash);
    });

    const hue = hash % 360;
    return hue;
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { useTranslation } from 'react-i18next';

import { fetchService, updateService } from 'store/services/actions';
import { operatorDeleteService } from 'store/actions';
import { useModal } from 'hooks/useModal';

import { Layout } from 'components/Layout';
import { ServiceForm } from 'components/Services/ServiceForm';
import { SERVICES_BREADCRUMBS } from 'common/data/beadcrumbs';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import { ShadowCard } from 'components/Common/ShadowCard';
import { CardTitle } from 'components/Common';
import { FormikProvider, useFormik } from 'formik';
import {
  convertServicesFromApi,
  convertServiceToApi,
} from 'helpers/converters';
import { ServicesTariffForm } from 'components/Services/ServicesTariffForm';
import { HeadControlButtons } from 'components/HeadControllButtons/HeadControllButtons';
import { createService } from 'helpers/api-requests/admin';
import { SERVICE_VALIDATOR } from 'forms/validators';
import { NavBar } from 'components/Common/NavBar';
import { ServiceNameCard } from 'components/Services/ServiceNameCard';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';

const formInitialValues = {
  title: '',
  name: {
    en: '',
    de: '',
  },
  description: {
    en: '',
    de: '',
  },
  price: '',
  country: '',
  nationalityRegion: '',
  visaType: '',
  visaUrgency: '',
  visaDuration: '',
  taxEnabled: true,
};

const serviceNav = [
  { title: 'common.information', id: 1, link: 'information', offset: -110 },
  { title: 'common.nameAndTranslation', id: 2, link: 'names' },
  { title: 'sideBar.tariffs', id: 3, link: 'tariffs' },
];

const getServiceState = state => state.services.service;

const Service = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id: productId, service_type, copyingService } = useParams();
  const { push } = useHistory();
  const { t } = useTranslation();
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();

  const { data, isLoading } = useSelector(getServiceState);

  const id = productId || copyingService;

  const handleUpdateService = values => {
    setIsSubmitting(true);
    if (productId) {
      if (values.status === 'archived' && values.tariffs.length !== 0) {
        showToastError(t('services.deleteTariffs'));
        return;
      }
      dispatch(
        updateService({
          serviceId: id,
          serviceType: service_type,
          data: convertServiceToApi(values),
        }),
      );

      setIsSubmitting(false);
      return;
    }

    createService(convertServiceToApi(values), values.serviceType)
      .then(response => {
        if (response.success) {
          showToastSuccess(t('services.created'));
          push(
            `/services/${response.data.id}/${response.data.product_type.type}`,
          );
        } else {
          throw Error(response.message);
        }
      })
      .catch(err => {
        showToastError(err.toString());
      })
      .finally(() => setIsSubmitting(false));
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: SERVICE_VALIDATOR,
    onSubmit: handleUpdateService,
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchService({ serviceId: id, serviceType: service_type }));
    }
  }, []);

  useEffect(() => {
    if (data && id && Number(data.id) === Number(id)) {
      formik.resetForm({
        values: copyingService
          ? convertServicesFromApi({ ...data, status: undefined })
          : convertServicesFromApi(data),
      });
    }
  }, [data, id]);

  const handleDeleteService = id => {
    dispatch(operatorDeleteService({ id, handleCloseModal }));
    push('/services');
  };

  return (
    <FormikProvider value={formik}>
      <Layout
        isScrollableHeader
        loading={isLoading}
        extraContent={
          <HeadControlButtons
            path="/services"
            title="service"
            typeCancel="gray"
            handleSubmit={formik.handleSubmit}
            isDisabledButton={!formik.dirty || isSubmitting}
          />
        }
        title={id ? 'services.serviceDetail' : 'services.servicesCreate'}
        breadcrumbs={SERVICES_BREADCRUMBS}
      >
        {isOpen && (
          <ModalConfirmDelete
            isOpenModal={isOpen}
            textHeader="services.delete"
            textBody="services.deleteConfirm"
            handleCloseModal={handleCloseModal}
            handleDelete={() => handleDeleteService(isOpen)}
          />
        )}

        <Row className="p-0 g-0 h-100">
          <Col xs={3} className="p-0">
            <NavBar
              scrollableHeader
              components={serviceNav}
              extraContent={
                productId && (
                  <>
                    <StyledUpload
                      className="w-100 border-0 d-flex justify-content-center mb-4"
                      onClick={() =>
                        push(`/services/create/${service_type}/${id}`)
                      }
                      disabled={formik.dirty}
                    >
                      Copy
                    </StyledUpload>

                    <StyledUpload
                      className="w-100 border-0 d-flex justify-content-center mb-4"
                      onClick={() => handleOpenModal(id)}
                    >
                      <i className="bx bx-trash me-2" />
                      {t('common.delete')}
                    </StyledUpload>
                  </>
                )
              }
            />
          </Col>

          <Col className="ps-4" xs={9}>
            <ShadowCard bodyStyle={{ padding: '24px' }} id="information">
              <Row>
                <CardTitle title={t('common.information')} />
              </Row>

              <ServiceForm />
            </ShadowCard>

            <ServiceNameCard />

            <ServicesTariffForm />
          </Col>
        </Row>
      </Layout>
    </FormikProvider>
  );
};

export default Service;

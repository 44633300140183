/* REFERENCES */

import { createQueryActions } from 'helpers/store/createQueryActions';

/* VISA TYPES */
export const GET_VISA_TYPES = 'GET_VISA_TYPES';
export const GET_VISA_TYPES_SUCCESS = 'GET_VISA_TYPES_SUCCESS';
export const GET_VISA_TYPES_FAIL = 'GET_VISA_TYPES_FAIL';

/* COUNTRIES */
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';

/* ADDRESSES */
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAIL = 'GET_ADDRESSES_FAIL';

export const GET_ADDRESS = 'GET_ADDRESS';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';

export const CLEAR_ADDRESS_LIST = 'CLEAR_ADDRESS_LIST';

/* REGIONS */
export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAIL = 'GET_REGIONS_FAIL';

/* CONFIG */
export const GET_REFERENCES_CONFIG = 'GET_REFERENCES_CONFIG';
export const GET_REFERENCES_CONFIG_SUCCESS = 'GET_REFERENCES_CONFIG_SUCCESS';
export const GET_REFERENCES_CONFIG_FAIL = 'GET_REFERENCES_CONFIG_FAIL';

export const getOrderCountries = createQueryActions('getOrderCountries');
export const getDeliveryCountries = createQueryActions('getDeliveryCountries');
export const getCitizenshipCountries = createQueryActions(
  'getCitizenshipCountries',
);
export const getUPSLabelCountries = createQueryActions('getUPSLabelCountries');

/* VS Urgency list */
export const getUrgenciesAction = createQueryActions('getUrgenciesAction');

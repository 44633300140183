import React, { createRef } from 'react';
import MaskedInput from 'react-text-mask';
import classNames from 'classnames';
import styled from 'styled-components';
import { StyledInput } from 'components/Common/FormikInput';
import { DATE_FORMAT_SHORT } from 'common/data/constants';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import i18n from 'i18n';

registerLocale('lang', i18n.language === 'de' ? de : en);

const StyledIcon = styled.i`
  color: #484848 !important;
  position: absolute;
  padding-right: 4px;
  top: 10px;
  right: 12px;
  font-size: 18px;
`;

export const CustomDatePicker = props => (
  <DatePicker
    clearButtonClassName="clear-icon"
    className={classNames(
      'form-control d-block',
      {
        'border-danger': props.invalid,
      },
      props.classNameDate,
    )}
    peekNextMonth
    showMonthDropdown
    showYearDropdown
    dateFormat={DATE_FORMAT_SHORT}
    dropdownMode="select"
    {...props}
    customInput={
      <MaskedInput
        mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        keepCharPositions
        guide={false}
        showMask={false}
      />
    }
  />
);

export const StyledDatePicker = ({
  invalid,
  value,
  onChange,
  $isBlue,
  ...props
}) => {
  const datepickerRef = createRef();
  const { t } = useTranslation();

  const handleClickDatepickerIcon = () => datepickerRef.current.handleFocus();

  return (
    <DatePicker
      clearButtonClassName="clear-icon"
      className={classNames(
        'form-control d-block',
        {
          'border-danger': props.invalid,
        },
        props.classNameDate,
      )}
      peekNextMonth
      isClearable={props.selected && !props.disabled}
      showMonthDropdown
      locale={i18n.language === 'de' ? de : en}
      dateFormat={DATE_FORMAT_SHORT}
      showYearDropdown
      ref={datepickerRef}
      required
      dropdownMode="select"
      onChange={onChange}
      {...props}
      customInput={
        <MaskedInput
          mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          keepCharPositions
          guide={false}
          showMask={false}
          render={(ref, customProps) => (
            <div className="m-0">
              <StyledInput
                invalid={invalid}
                innerRef={ref}
                $isBlue={$isBlue}
                onChange={onChange}
                {...customProps}
                placeholder={t(customProps.placeholder)}
              />
              {!value && (
                <StyledIcon
                  onClick={handleClickDatepickerIcon}
                  className="bx bx-calendar"
                />
              )}
            </div>
          )}
        />
      }
    />
  );
};

import {
  DATE_FORMAT_SHORT,
  SELECT_CLASSNAME,
  USER_SEX,
} from 'common/data/constants';
import { Select, StyledPhoneInput } from 'components/Common';
import { StyledInput, StyledLabel } from 'components/Common/FormikInput';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

export const ApplicantMainInfo = () => {
  const { errors, handleChange, values, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  return (
    <div className="ps-2">
      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('auth.firstName')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name="first_name"
            value={values.first_name}
            placeholder={t('auth.enterFirstName')}
            onChange={handleChange}
            invalid={errors.first_name}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('auth.lastName')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name="last_name"
            value={values.last_name}
            placeholder={t('auth.enterLastName')}
            onChange={handleChange}
            invalid={errors.last_name}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('auth.additionalName')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name="additional_name"
            value={values.additional_name}
            placeholder={t('auth.enterAdditionalName')}
            onChange={handleChange}
            invalid={errors.additional_name}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.dateOfBirth')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledDatePicker
            name="birthday"
            placeholderText={t('common.datePlaceholder')}
            dateFormat={DATE_FORMAT_SHORT}
            selected={values.birthday}
            value={values.birthday}
            invalid={errors.birthday}
            onChange={option => setFieldValue('birthday', option)}
            maxDate={new Date()}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('common.gender')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <Select
            placeholder={t('common.genderPlaceholder')}
            classNamePrefix={SELECT_CLASSNAME}
            value={USER_SEX.find(({ value }) => value === values.sex) ?? null}
            onChange={option => setFieldValue('sex', option?.value)}
            options={USER_SEX}
            isClearable
            invalid={errors.sex}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('auth.email')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name="email"
            type="email"
            placeholder={t('auth.enterEmail')}
            value={values.email}
            onChange={handleChange}
            invalid={errors.email}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('common.phone')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledPhoneInput
            inputStyle={{ width: '100%' }}
            name="phone"
            errors={errors.phone}
            onChange={phone => setFieldValue('phone', phone)}
            value={values.phone}
          />
        </Col>
      </Row>
    </div>
  );
};

import { EMPTY_VALUE_WITH_LABEL } from 'common/data/constants';
import {
  StyledDropDownMenu,
  StyledState,
  StyledStatus,
  StyledDropdownToggle,
} from 'components/StatusPicker/StatusPicker';
import { getUsers } from 'helpers/api-requests/admin';
import { debounce } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, Input, UncontrolledDropdown } from 'reactstrap';

const PAGE_SIZE = 500;

export const AssignedToSelector = memo(
  ({ value, handlePickOperator, className, placeholder = 'State:' }) => {
    const { t } = useTranslation();

    const [operators, setOperators] = useState({
      operators: [],
    });

    const [search, setSearch] = useState({
      name: '',
      page: 1,
    });
    const isSelected =
      typeof value === 'object'
        ? value?.value
          ? true
          : false
        : value
        ? true
        : false;

    useEffect(() => {
      getUsers({
        is_operator_or_admin: true,
        search: search.name,
        page_sizae: PAGE_SIZE,
      }).then(response => {
        if (response.success) {
          setOperators(() => ({
            ...operators,
            operators: [...response.data.results],
          }));
        }
      });
    }, [search.name]);

    const convertOption = useMemo(
      () =>
        operators.operators.map(operator => ({
          label: operator.first_name + ' ' + operator.last_name,
          value: operator.id,
          item: operator,
        })),
      [operators],
    );

    const handleChangeSearch = debounce(text => {
      //   setOperators(() => ({
      //     ...operators,
      //     // operators: operators.operators.filter(operator => operator.id !== value.value),
      //   })),
      setSearch({ name: text });
    }, 300);

    return (
      <UncontrolledDropdown className={className}>
        <StyledDropdownToggle
          $type="gray"
          className="d-flex flex-row align-items-center justify-content-center"
          style={isSelected ? { background: '#495057', color: 'white' } : {}}
        >
          <StyledStatus className="text-nowrap">
            <StyledState className="me-1">{t(placeholder)}</StyledState>
            {t(value?.label)}
          </StyledStatus>
        </StyledDropdownToggle>

        <StyledDropDownMenu>
          <div tabIndex={0} role="menuitem" className="px-3 d-flex mb-2">
            <Input
              onChange={event => handleChangeSearch(event.target.value)}
              defaultValue={search.name}
              tabIndex={0}
              role="menuitem"
              placeholder="Search"
            />
          </div>

          {[EMPTY_VALUE_WITH_LABEL, ...convertOption].map((operator, index) => (
            <DropdownItem
              className="text-wrap"
              active={operator.value === value.value}
              onClick={() => {
                handlePickOperator(operator);
                handleChangeSearch('');
              }}
              key={index}
            >
              {t(operator.label)}
            </DropdownItem>
          ))}
        </StyledDropDownMenu>
      </UncontrolledDropdown>
    );
  },
);

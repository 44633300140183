import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddresses } from 'store/addresses/actions';
import { AddressForm } from './AddressForm';
import { convertAddressesFromApi } from 'helpers/converters';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

const getAddressState = state => state.addresses.addresses.addresses;

export const AddressesCard = () => {
  const addresses = useSelector(getAddressState);
  const [tempAddresses, setAddressesState] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAddresses({ page: 1, page_size: 100 }));
  }, []);

  const handleAddAddress = () => {
    setAddressesState(addresses => [
      ...addresses,
      {
        name: '',
        city: '',
        addressLineFirst: '',
        postalCode: '',
        state: '',
        country: null,
        tempId: performance.now(),
      },
    ]);
  };

  const handleCancelCreate = id =>
    setAddressesState(addresses =>
      addresses.filter(address => address.tempId !== id),
    );

  const currentAddresses = useMemo(
    () => [...convertAddressesFromApi(addresses), ...tempAddresses],
    [tempAddresses, addresses],
  );

  return (
    <>
      {currentAddresses.length > 0 ? (
        currentAddresses.map(
          (address, index) =>
            address && (
              <AddressForm
                initialEdit={!address.id}
                handleCancelCreate={handleCancelCreate}
                className={
                  currentAddresses.length !== 1 &&
                  index + 1 !== currentAddresses.length &&
                  'mb-4'
                }
                address={address}
                key={address.id ?? address.tempId}
              />
            ),
        )
      ) : (
        <Alert color="primary">{t('client.addressNotFound')}</Alert>
      )}

      <StyledUpload
        className="d-flex mt-4 fs-14 flex-nowrap align-items-center"
        onClick={handleAddAddress}
      >
        <i className="bx bx-plus fs-4 me-2" /> {t('client.addAddress')}
      </StyledUpload>
    </>
  );
};

import React from 'react';
import { ModalConfirmDelete } from './ModalConfirmDelete';
import { StyledModalCancel } from './ModalConfirmDelete';
import { useTranslation } from 'react-i18next';
import { payStripeInit } from 'helpers/api-requests/admin';
import { openNewPage } from 'helpers/redirect';
import { StripeImage } from 'assets/images/companies';
import { Col, Row } from 'reactstrap';
import { confirmOrder } from 'helpers/api-requests/manager';

export const ModalPayment = ({
  isOpenModal,
  billId,
  orderId,
  handleCloseModal,
}) => {
  const { t } = useTranslation();

  // const handlePaySofort = orderId => {
  //   confirmOrder(orderId).then(
  //     response =>
  //       response.success &&
  //       paySofortInit(billId).then(response => {
  //         if (response.success) {
  //           openNewPage(response.data.payment_url);
  //           handleCloseModal();
  //         }
  //       }),
  //   );
  // };

  const handlePayStripe = orderId => {
    confirmOrder(orderId).then(
      response =>
        response.success &&
        payStripeInit(billId).then(response => {
          if (response.success) {
            openNewPage(response.data.payment_url);
            handleCloseModal();
          }
        }),
    );
  };

  return (
    <ModalConfirmDelete
      handleCloseModal={handleCloseModal}
      textHeader="payments.payment"
      isOpenModal={isOpenModal}
      showBtn={false}
      showCancelBtn={false}
    >
      {/* <p className="display-4  mb-4 font-weight-bold">
        {t('payments.payConfirm')}
      </p> */}

      <Row className="d-flex justify-content-between g-3 flex-wrap">
        <Col xs={12} sm={12}>
          <StyledModalCancel
            className="text-nowrap w-100"
            onClick={() => handlePayStripe(orderId)}
          >
            {t('payments.continue', { paymentType: '' })}
            <StripeImage />
          </StyledModalCancel>
        </Col>

        {/* <Col xs={12} sm={6}>
          <StyledModalCancel
            className="text-nowrap w-100"
            onClick={() => handlePaySofort(orderId)}
          >
            {t('payments.continue', { paymentType: '' })}
            <SofortImage />
          </StyledModalCancel>
        </Col> */}
      </Row>
    </ModalConfirmDelete>
  );
};

import { DATE_FORMAT_WITH_SYMBOL } from 'common/data/constants';
import {
  StyledButtonGray,
  StyledColBold,
  StyledField,
} from 'components/Common';
import { ViewCountry } from 'components/ViewCountry/ViewCountry';
import { useFormikContext } from 'formik';
import { formatDate } from 'helpers/formatDate';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { ApplicantEditForm } from '../ApplicantEditForm';
import { useMatchMedia } from 'hooks/useMatchMedia';
import classNames from 'classnames';

export const StyledLine = styled.div`
  border-left: 1px solid #dee0e7;
`;

const StyledCol = styled(Col)`
  border-left: 1px solid #dee0e7;
`;

export const convertDataToDisplay = field => (field ? field : '-');

export const ApplicantDetail = ({
  applicant,
  index,
  removeApplicant,
  orderTypeFieldsSettings,
  setApplicantCount,
}) => {
  const [isOpenEditApplicant, setIsOpenEditApplicant] = useState(
    !applicant.firstName,
  );

  useEffect(() => {
    if (!applicant.firstName && isOpenEditApplicant) {
      setApplicantCount(1);
    }
  }, []);

  const { t } = useTranslation();
  const formik = useFormikContext();
  const { isMobile } = useMatchMedia();

  const handleCloseEditApplicant = () => setIsOpenEditApplicant(false);

  const isDisabledFields = useMemo(
    () => !formik.values.orderDetail?.isEditable,
    [formik.values.orderDetail?.isEditable],
  );

  return (
    <StyledField className="p-3 d-flex flex-wrap mt-4 w-100">
      {isOpenEditApplicant ? (
        <ApplicantEditForm
          removeApplicant={removeApplicant}
          applicant={applicant}
          index={index}
          handleClose={handleCloseEditApplicant}
          orderTypeFieldsSettings={orderTypeFieldsSettings}
        />
      ) : (
        <>
          <Col className="p-0" xs={6} sm={5}>
            <div className="d-flex flex-wrap p-0">
              <Col className="mb-3 fs-14" xs={12} lg={6}>
                {t('auth.firstName')}
              </Col>

              <StyledColBold className="mb-3 fs-14 fw-semibold" xs={12} lg={6}>
                {convertDataToDisplay(applicant.firstName)}
              </StyledColBold>

              <Col className="mb-3 fs-14" xs={12} lg={6}>
                {t('auth.lastName')}
              </Col>

              <StyledColBold className="mb-3 fs-14 fw-semibold" xs={12} lg={6}>
                {convertDataToDisplay(applicant.lastName)}
              </StyledColBold>

              <Col className="mb-3 fs-14" xs={12} lg={6}>
                {t('auth.additionalName')}
              </Col>

              <StyledColBold className="mb-3 fs-14 fw-semibold" xs={12} lg={6}>
                {convertDataToDisplay(applicant.additionalName)}
              </StyledColBold>

              {orderTypeFieldsSettings?.enableApplicantBirthdate && (
                <>
                  <Col className="fs-14" lg={6}>
                    {t('auth.birthday')}
                  </Col>

                  <StyledColBold className="fs-14 fw-semibold" xs={12} lg={6}>
                    {formatDate(applicant.birthday, DATE_FORMAT_WITH_SYMBOL)}
                  </StyledColBold>
                </>
              )}
            </div>
          </Col>

          {orderTypeFieldsSettings?.enableApplicantPassport ? (
            <StyledCol className="p-0 ps-3 " xs={6} sm={5}>
              <div className="d-flex flex-wrap p-0">
                <Col className="mb-3 fs-14" lg={6}>
                  {t('common.citizenship')}
                </Col>

                <StyledColBold
                  xs={12}
                  className="mb-3 fs-14 fw-semibold"
                  lg={6}
                >
                  <ViewCountry id={applicant.citizenship} />
                </StyledColBold>

                <Col className="mb-3 fs-14" xs={12} lg={6}>
                  {t('passport.number')}
                </Col>

                <StyledColBold
                  xs={12}
                  className="mb-3 fs-14 fw-semibold"
                  lg={6}
                >
                  {convertDataToDisplay(applicant.passport?.number)}
                </StyledColBold>

                <Col className="mb-3 fs-14" xs={12} lg={6}>
                  {t('passport.expirationDate')}
                </Col>

                <StyledColBold
                  xs={12}
                  className="mb-3 fs-14 fw-semibold"
                  lg={6}
                >
                  {formatDate(
                    applicant.passport?.expirationDate,
                    DATE_FORMAT_WITH_SYMBOL,
                  )}
                </StyledColBold>
              </div>
            </StyledCol>
          ) : (
            <StyledCol className="p-0 ps-3 " xs={6} sm={5}>
              <div className="d-flex flex-wrap p-0">
                <Col className="mb-3 fs-14" lg={6}>
                  {t('common.phone')}
                </Col>

                <StyledColBold
                  xs={12}
                  className="mb-3 fs-14 fw-semibold"
                  lg={6}
                >
                  {applicant.phone}
                </StyledColBold>

                <Col className="mb-3 fs-14" xs={12} lg={6}>
                  {t('auth.email')}
                </Col>

                <StyledColBold
                  xs={12}
                  className="mb-3 fs-14 fw-semibold"
                  lg={6}
                >
                  {applicant.email}
                </StyledColBold>
              </div>
            </StyledCol>
          )}

          <Col
            className="p-0 d-flex align-items-start justify-content-end"
            xs={12}
            sm={2}
            xxl={2}
          >
            <StyledButtonGray
              className={classNames(
                'd-flex flex-nowrap justify-content-center',
                {
                  'w-100 mt-3': isMobile,
                },
              )}
              disabled={isDisabledFields}
              onClick={() => setIsOpenEditApplicant(true)}
            >
              <i className="bx bxs-edit-alt" />

              {t('common.edit')}
            </StyledButtonGray>
          </Col>
        </>
      )}
    </StyledField>
  );
};

import React from 'react';
import FormikInput from 'components/Common/FormikInput';
import {
  StyledButtonCancel,
  StyledButtonSave,
} from 'components/Common/StyledButtonSave';
import { Label } from 'components/Services/ServiceForm';
import { useFormik } from 'formik';
import { PASSWORD_RESET_PROFILE_VALIDATOR } from 'forms/validators';
import { changePassword } from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';

import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const passwordFields = [
  {
    title: 'auth.password',
    name: 'oldPassword',
    autoComplete: 'current-password',
    placeholder: 'profile.passwordPlaceholder',
    key: 1,
  },
  {
    title: 'profile.newPassword',
    name: 'newPassword',
    autoComplete: 'new-password',
    placeholder: 'profile.newPasswordPlaceholder',
    key: 2,
  },
  {
    title: 'auth.repeatPassword',
    name: 'repeatPassword',
    autoComplete: 'new-password',
    placeholder: 'profile.repeatNewPassword',
    key: 3,
  },
];

const initialValues = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
};

const PasswordWithFormik = () => {
  const { t } = useTranslation();

  const handleSubmitForm = (values, { resetForm }) => {
    changePassword({
      old_password: values.oldPassword,
      new_password: values.newPassword,
      new_password2: values.repeatPassword,
    })
      .then(response => {
        if (response.success) {
          resetForm({ values: initialValues });
          showToastSuccess(t('profile.passwordEdited'));
          return;
        }

        showToastError(response.message);
      })
      .catch(err => showToastError(err));
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema: PASSWORD_RESET_PROFILE_VALIDATOR,
    onSubmit: handleSubmitForm,
  });

  const handleCancel = () => formik.resetForm({ values: initialValues });

  return (
    <form onSubmit={formik.handleSubmit} className="d-flex flex-wrap">
      {passwordFields.map(field => (
        <Col key={field.key} className="p-0 d-flex flex-wrap" xs={12}>
          <Label xs={6} sm={3} lg={2}>
            {t(field.title)}
          </Label>

          <Col xs={12} sm={9} lg={10} className="d-flex align-items-center">
            <FormikInput
              groupStyle="w-100"
              type="password"
              autoComplete={field.autoComplete}
              name={field.name}
              value={formik.values[field.name]}
              errors={formik.errors[field.name]}
              invalid={formik.errors[field.name]}
              handleChange={formik.handleChange}
              placeholder={field.placeholder}
            />
          </Col>
        </Col>
      ))}

      <Col className="p-0 d-flex flex-wrap" xs={12}>
        <Col
          xs={4}
          className="offset-xs-3 offset-lg-2 d-flex gap-2 flex-nowrap justify-content-between"
        >
          <StyledButtonSave
            disabled={!formik.dirty}
            color="primary"
            type="submit"
            className="w-100"
          >
            {t('profile.update')}
          </StyledButtonSave>

          <StyledButtonCancel
            className="w-100 btn text-nowrap"
            onClick={handleCancel}
            disabled={!formik.dirty}
            type="button"
          >
            {t('common.cancel')}
          </StyledButtonCancel>
        </Col>
      </Col>
    </form>
  );
};

export default PasswordWithFormik;

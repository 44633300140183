import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --toastify-color-success: #E8FFF7;
    --toastify-text-color-success: ${({ theme }) => theme.colors.gray800};
    --toastify-text-color-error: ${({ theme }) => theme.colors.gray800};
    --toastify-color-error: ${({ theme }) => theme.colors.red200};
    --toastify-toast-width: 70vw;
  }


  body {
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    overflow-y: overlay;
    color: ${({ theme }) => theme.colors.mainText} !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  select:-webkit-autofill,
  select:-webkit-autofill:hover {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.mainText};
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    transition: background-color 5000s ease-in-out 0s;
  }

  .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .selected-flag {
    padding-left: 16px !important;
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: ${({ theme }) => theme.colors.sideBarBg};
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
  }   

  .P-0 {
    padding: 0;
  }
  .pl-0 {
    padding-left: 0;
  }
  .pr-5 {
    padding-right: 5px; 
  }
  .pr-10 {
    padding-right: 10px;
  }

  .v-align-top {
    vertical-align: top;
  }
  .mt-30 {
    margin-top: 30px!important;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.mainText};

    &:hover {
      color: ${({ theme }) => theme.colors.mainText};
    }
  }

  hr {
    border: 1px solid #DEE0E7;
  }

  .form-control {
    font-size: 14px!important;
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .fs-14 {
    font-size: 14px !important
  }

  .btn-danger {
    color: white !important;
  }

  .btn-success {
    color: white !important;
  }

  .btn-primary {
    /* background: #556EE6; */
    color: white !important;
  }

  //Input reactstrap
  .form-control {
    background-color: ${({ theme }) => theme.colors.white}
  }

  .form-check-input {
    border-radius: 6px;
  }

  .form-check-input:disabled {
    background-color: ${({ theme }) => theme.colors.gray600};
    border-color:  ${({ theme }) => theme.colors.gray600};
   }

  .btn-secondary {
    color: white;
  }

  .active-navbar {
    border-left: 4px solid #556ee6;
    
    li {
      padding-left: 16px!important;
      color: ${({ theme }) => theme.colors.mainText};
    }
  }
  
  .btn {
    font-weight: 500;
    line-height: 20px;
    border-radius: ${({ theme }) => theme.radius.s};
    padding: 
     ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.sm};
  }

  .react-datepicker {
    border: 2px solid #f8f8fb;
    box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
    font-family: 'Poppins';
    border-radius: ${({ theme }) => theme.radius.s};

    select {
      border: none;
      font-size: 14px;
      background-color: transparent;
      cursor: pointer;

      &:focus {
        border: none;
      }

      &:focus-visible {
        border: none;
      }
    }

    &__close-icon {
      padding-right: 12px;

      &::after {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.mainText};
        border-radius: 50%;
        font-size: 28px;
        padding: 0;
        padding-top: 4px;
      }
    }

    &__triangle::after {
      border-bottom-color: #f8f8fb!important;
      border-top-color: transparent!important;
    }

    &__triangle::before {
      border-top-color: #f8f8fb!important;
      border-bottom-color: transparent!important;
    }

    &__header {
      border-radius: ${({ theme }) => theme.radius.s};
      border-bottom: none;
      background: #f8f8fb;
    }

    &__close-icon {
      padding-right: 18px;
    }

    &__day {
      &:hover {
        border-radius: 50%;
      }

      &--keyboard-selected {
        border-radius: 50%;
      }

      &--selected, &--in-range {
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
      }

      &-name {
        font-weight: 600;
      }
    }
  }

  .Toastify {
    font-family: 'Poppins', sans-serif;

    &__toast-container--top-center {
      top: 64px;
    }
    
    &__toast {
      padding: 12px 24px;
      box-shadow: 0px 8px 20px rgba(255, 0, 0, 0.15);
      border-radius: 8px;
    }

    &__toast-body {
      padding: 0;
      margin: 0;
      font-weight: 500;
    }

    &__toast-icon {
      width: 44px;
      margin-inline-end: 16px;
      -webkit-margin-end: 16px;
    }

    &__close-button--colored {
      color: ${({ theme }) => theme.colors.gray800};
      opacity: 1;
    }

    &__close-button {
      align-self: center;
    }

    &__progress-bar {
      height: 0px;
    }
  }
`;

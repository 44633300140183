import { useMatchMedia } from 'hooks/useMatchMedia';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import styled, { css } from 'styled-components';

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  height: 32px;

  ${({ $isSmallText }) =>
    $isSmallText &&
    css`
      span {
        font-size: 16px;
      }
    `}
`;

const StyledDeviver = styled.hr`
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const CardTitle = memo(
  ({ title, extraContent, isSmallText, isShort }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useMatchMedia();

    const getMinWidth = () => {
      if ((isMobile || isTablet) && isShort) {
        return { minWidth: 150 };
      }
    };

    return (
      <h2 className="m-0">
        <StyledTitle
          $isSmallText={isSmallText}
          className="fw-semibold d-flex align-items-center m-0"
        >
          <span
            style={getMinWidth()}
            className="me-3 text-nowrap text-truncate w-50"
          >
            {t(title)}
          </span>{' '}
          {extraContent}
        </StyledTitle>

        <Col xs={12}>
          <StyledDeviver />
        </Col>
      </h2>
    );
  },
);

import {
  CLIENT_CATEGORIES,
  CLIENT_STATUS,
  DATE_FORMAT_WITH_SECONDS,
} from 'common/data/constants';
import { ChangeStatus } from 'components/ChangeStatus/ChangeStatus';
import { StyledInput } from 'components/Common/FormikInput';
import SelectTariff from 'components/Common/SelectTariff';
import { Select } from 'components/Common';
import { useFormikContext } from 'formik';
import { formatDate } from 'helpers/formatDate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Input, Label, Row } from 'reactstrap';

import styled from 'styled-components';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';

const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
`;

const name = 'mainInfo';

export const ClientEditMainInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const formik = useFormikContext();

  return (
    <>
      {!id && (
        <Row className="p-0 mb-4 align-items-center">
          <Col xs={3}>
            <StyledLabel className="m-0">{t('client.clientType')}</StyledLabel>
          </Col>

          <Col xs={9}>
            <StyledLabel>
              <Input
                type="radio"
                checked={formik.values.clientType === 'individual'}
                onChange={() => {
                  formik.setFieldValue('clientType', 'individual');
                  formik.setFieldValue(`${name}.clientType`, 'individual');
                }}
                invalid={formik.errors?.clientType}
              />

              <span className="ms-2">{t('client.individual')}</span>
            </StyledLabel>

            <StyledLabel className="ms-2">
              <Input
                type="radio"
                checked={formik.values.clientType === 'legal'}
                onChange={() => {
                  formik.setFieldValue(`${name}.clientType`, 'legal');
                  formik.setFieldValue('clientType', 'legal');
                }}
                invalid={formik.errors?.clientType}
              />

              <span className="ms-2">{t('client.legal')}</span>
            </StyledLabel>
          </Col>
        </Row>
      )}

      {formik.values.clientType === 'individual' ? (
        <>
          <Row className="p-0 mb-4 align-items-center">
            <Col xs={3}>
              <StyledLabel className="m-0">{t('auth.firstName')}</StyledLabel>
            </Col>

            <Col xs={9}>
              <StyledInput
                name={`${name}.firstName`}
                value={formik.values.mainInfo?.firstName ?? ''}
                placeholder={t('auth.enterFirstName')}
                onChange={formik.handleChange}
                invalid={formik.errors?.mainInfo?.firstName}
              />
            </Col>
          </Row>

          <Row className="p-0 mb-4 align-items-center">
            <Col xs={3}>
              <StyledLabel className="m-0">{t('auth.lastName')}</StyledLabel>
            </Col>

            <Col xs={9}>
              <StyledInput
                name={`${name}.lastName`}
                value={formik.values.mainInfo?.lastName ?? ''}
                placeholder={t('auth.enterLastName')}
                onChange={formik.handleChange}
                invalid={formik.errors?.mainInfo?.lastName}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="p-0 mb-4 align-items-center">
            <Col xs={3}>
              <StyledLabel className="m-0">
                {t('client.organizationName')}
              </StyledLabel>
            </Col>

            <Col xs={9}>
              <StyledInput
                name={`${name}.organizationName`}
                value={formik.values.mainInfo.organizationName ?? ''}
                placeholder={t('client.organizationNamePlaceholder')}
                onChange={formik.handleChange}
                invalid={formik.errors.mainInfo?.organizationName}
              />
            </Col>
          </Row>

          <Row className="p-0 mb-4 align-items-center">
            <Col xs={3}>
              <StyledLabel className="m-0 mb-4">
                {t('client.category')}
              </StyledLabel>
            </Col>

            <Col xs={5}>
              <Select
                classNamePrefix="select2-selection"
                placeholder={t('client.categoryPlaceholder')}
                value={
                  CLIENT_CATEGORIES.find(
                    ({ value }) => value === formik.values.mainInfo.category,
                  ) ?? null
                }
                onChange={option =>
                  formik.setFieldValue('mainInfo.category', option?.value)
                }
                error={formik.errors.mainInfo?.category}
                options={CLIENT_CATEGORIES}
                isClearable
              />
            </Col>
          </Row>
        </>
      )}

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.description')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.description`}
            value={formik.values.mainInfo?.description ?? ''}
            placeholder={t('client.descriptionPlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.mainInfo?.description}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.accountBank')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.account_bank`}
            value={formik.values.mainInfo?.account_bank ?? ''}
            maxLength={100}
            placeholder={t('client.accountBankPlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.mainInfo?.account_bank}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.accountOwner')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.account_owner`}
            value={formik.values.mainInfo?.account_owner ?? ''}
            maxLength={100}
            placeholder={t('client.accountOwnerPlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.mainInfo?.account_owner}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.iban')}</StyledLabel>
        </Col>

        <Col xs={9}>
          <StyledInput
            name={`${name}.iban`}
            value={formik.values.mainInfo?.iban ?? ''}
            maxLength={34}
            placeholder={t('client.ibanPlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.mainInfo?.iban}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.accountDue')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledInput
            type="number"
            min="0"
            name={`${name}.account_due`}
            value={formik.values.mainInfo?.account_due ?? 0}
            placeholder={t('client.accountDuePlaceholder')}
            onChange={formik.handleChange}
            invalid={formik.errors?.mainInfo?.account_due}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={12}>
          <div className="d-flex align-items-center">
            <Input
              className="m-0"
              type="checkbox"
              name="account_collection_auth"
              checked={formik.values.mainInfo?.account_collection_auth}
              value={formik.values.mainInfo?.account_collection_auth}
              onChange={() =>
                formik.setFieldValue(
                  `${name}.account_collection_auth`,
                  !formik.values.mainInfo?.account_collection_auth,
                )
              }
            />

            <Label className="m-0 ms-2">
              {t('client.accountCollectionAuth')}
            </Label>
          </div>
        </Col>
      </Row>

      {id && (
        <Row className="p-0 mb-4 align-items-center">
          <Col xs={3}>
            <StyledLabel className="m-0">{t('common.status')}</StyledLabel>
          </Col>

          <Col xs={9}>
            <ChangeStatus
              value={formik.values.mainInfo.status}
              onChange={status =>
                formik.setFieldValue(`${name}.status`, status.value)
              }
              statuses={CLIENT_STATUS}
            />
          </Col>
        </Row>
      )}

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0 mb-4">{t('client.tariff')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <SelectTariff
            placeholder={t('client.tariffPlaceholder')}
            value={formik.values.mainInfo.tariff}
            handleChange={tariff => {
              formik.setFieldValue(`${name}.tariffDetail`, {
                name: tariff.label,
              });
              formik.setFieldValue(`${name}.tariff`, tariff.value);
            }}
            error={formik.errors.mainInfo?.tariff}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.taxId')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledInput
            placeholder={t('client.taxIdPlaceholder')}
            maxLength={15}
            value={formik.values.mainInfo.taxId ?? ''}
            name={`${name}.taxId`}
            onChange={formik.handleChange}
          />
        </Col>
      </Row>

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.clientId')}</StyledLabel>
        </Col>

        <Col xs={5}>{formik.values.mainInfo.clientId ?? ''}</Col>
      </Row>

      {formik.values.clientType === 'legal' && (
        <Row className="p-0 mb-4 align-items-center">
          <Col xs={3}>
            <StyledLabel className="m-0">
              {t('client.taxResidencyCountry')}
            </StyledLabel>
          </Col>

          <Col xs={5}>
            <SelectCountry
              value={formik.values.mainInfo.taxResidencyCountry}
              placeholder={t('client.countryPlaceholder')}
              onChange={country =>
                formik.setFieldValue(
                  `${name}.taxResidencyCountry`,
                  country?.value,
                )
              }
              invalid={formik.errors?.mainInfo?.taxResidencyCountry}
            />
          </Col>
        </Row>
      )}

      <Row className="p-0 mb-4 align-items-center">
        <Col xs={3}>
          <StyledLabel className="m-0">{t('client.comment')}</StyledLabel>
        </Col>

        <Col xs={5}>
          <StyledInput
            maxLength={255}
            value={formik.values.mainInfo.comment ?? ''}
            name={`${name}.comment`}
            onChange={formik.handleChange}
            type="textarea"
            $textarea={true}
          />
        </Col>
      </Row>

      {formik.values.clientType === 'individual' && id && (
        <>
          <Col xs={12}>
            <hr className="mt-3" />
          </Col>

          <Row className="mb-3 align-items-center p-0">
            <Col xs={3}>
              <Label className="text-muted fw-normal m-0">
                {t('client.login')}
              </Label>
            </Col>

            <Col xs={5}>
              <Label>{formik.values.mainInfo.login} </Label>
            </Col>
          </Row>

          <Row className="align-items-center p-0">
            <Col xs={3}>
              <Label className="text-muted fw-normal m-0">
                {t('client.lastLogin')}
              </Label>
            </Col>

            <Col xs={5}>
              <Label>
                {formatDate(
                  formik.values.mainInfo.lastLogin,
                  DATE_FORMAT_WITH_SECONDS,
                )}
              </Label>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  createAggregatedInvoice,
  deleteAggregatedInvoice,
  deleteInvoiceFromAggregate,
  getAggregatedInvoice,
  getAggregatedInvoices,
  getCandidateInvoice,
  updateAggregatedInvoice,
  updateStatusAggregatedInvoice,
} from './actions';

const initilalState = {
  aggregatedInvoices: {
    isLoading: false,
    invoices: [],
    count: null,
  },
  aggregatedInvoice: {
    isLoading: false,
    invoice: null,
  },
  invoicesCandidate: {
    isLoading: false,
    invoices: [],
    count: null,
  },
};

const aggregatedInvoice = createReducer(initilalState, {
  [getCandidateInvoice]: state => ({
    ...state,
    invoicesCandidate: {
      ...state.invoicesCandidate,
      isLoading: true,
    },
  }),
  [getCandidateInvoice.success]: (state, { payload }) => ({
    ...state,
    invoicesCandidate: {
      ...state.invoicesCandidate,
      invoices: payload.invoices,
      count: payload.count,
      countries: payload.countries,
      isLoading: false,
    },
  }),
  [getCandidateInvoice.failure]: state => ({
    ...state,
    invoicesCandidate: {
      ...state.invoicesCandidate,
      isLoading: false,
    },
  }),

  [getAggregatedInvoices]: state => ({
    ...state,
    aggregatedInvoices: {
      ...state.aggregatedInvoices,
      isLoading: true,
    },
  }),
  [getAggregatedInvoices.success]: (state, { payload }) => ({
    ...state,
    aggregatedInvoices: {
      ...state.aggregatedInvoices,
      invoices: payload.invoices,
      count: payload.count,
      isLoading: false,
    },
  }),
  [getAggregatedInvoices.failure]: state => ({
    ...state,
    aggregatedInvoices: {
      ...state,
      isLoading: false,
    },
  }),

  [getAggregatedInvoice]: state => ({
    ...state,
    aggregatedInvoice: {
      ...state.aggregatedInvoice,
      isLoading: true,
    },
  }),
  [getAggregatedInvoice.success]: (state, { payload }) => ({
    ...state,
    aggregatedInvoice: {
      ...state.aggregatedInvoice,
      invoice: payload,
      isLoading: false,
    },
  }),
  [getAggregatedInvoice.failure]: state => ({
    ...state,
    aggregatedInvoice: {
      ...state,
      isLoading: false,
    },
  }),

  [deleteAggregatedInvoice]: state => ({
    ...state,
  }),
  [deleteAggregatedInvoice.success]: (state, { payload }) => ({
    ...state,
    aggregatedInvoices: {
      ...state.aggregatedInvoices,
      invoices: state.aggregatedInvoices.invoices.filter(
        invoice => invoice.id !== payload,
      ),
    },
  }),
  [deleteAggregatedInvoice.failure]: state => ({
    ...state,
  }),

  [createAggregatedInvoice]: state => ({
    ...state,
  }),
  [createAggregatedInvoice.success]: (state, { payload }) => ({
    ...state,
    aggregatedInvoice: {
      ...state.aggregatedInvoice,
      invoice: payload,
    },
  }),
  [createAggregatedInvoice.failure]: state => ({
    ...state,
  }),

  [updateStatusAggregatedInvoice]: state => ({
    ...state,
  }),
  [updateStatusAggregatedInvoice.success]: (state, { payload }) => ({
    ...state,
    aggregatedInvoice: {
      ...state.aggregatedInvoice,
      invoice: { ...state.aggregatedInvoice.invoice, status: payload },
    },
  }),
  [updateStatusAggregatedInvoice.failure]: state => ({
    ...state,
  }),

  [updateAggregatedInvoice]: state => ({
    ...state,
  }),
  [updateAggregatedInvoice.success]: (state, { payload }) => ({
    ...state,
    aggregatedInvoice: {
      ...state.aggregatedInvoice,
      invoice: payload,
      isLoading: false,
    },
  }),
  [updateAggregatedInvoice.failure]: state => ({
    ...state,
  }),

  [deleteInvoiceFromAggregate]: state => ({
    ...state,
  }),
  [deleteInvoiceFromAggregate.success]: (state, { payload }) => ({
    ...state,
    aggregatedInvoice: {
      ...state.aggregatedInvoice,
      invoice: {
        ...state.aggregatedInvoice.invoice,
        bills: state.aggregatedInvoice.invoice.bills.filter(
          bill => bill.id !== payload,
        ),
      },
    },
  }),
  [deleteInvoiceFromAggregate.failure]: state => ({
    ...state,
  }),
});

export default combineReducers({
  aggregatedInvoice,
});

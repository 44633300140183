import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const DraftEditor = ({ initialValue, ...props }) => {
  const [editorState, setEditorState] = useState(() => {
    const blockArray = convertFromHTML(initialValue);

    const content = ContentState.createFromBlockArray(blockArray);
    return EditorState.createWithContent(content);
  });

  const handleEditorChange = state => {
    setEditorState(state);
  };

  return (
    <div>
      <Editor
        {...props}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'link',
          ],
          inline: {
            inDropdown: false,
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'monospace',
            ],
            bold: { className: undefined },
            italic: { className: undefined },
            underline: { className: undefined },
            strikethrough: { className: undefined },
            monospace: { className: undefined },
          },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14],
          },
        }}
      />
    </div>
  );
};

import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import classnames from 'classnames';
import styled from 'styled-components';

const defaultMaskOptions = {
  prefix: '€',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const StyledMaskedInput = styled(MaskedInput)`
  min-width: 80px !important;

  &:focus {
    box-shadow: none;
    background-color: white;
  }
`;

export const PriceInput = ({ className, ...props }) => {
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <StyledMaskedInput
      mask={currencyMask}
      className={classnames(
        'form-control',
        {
          'is-invalid': Boolean(props.error),
        },
        className,
      )}
      {...props}
    />
  );
};

import React, { createRef } from 'react';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';

export function DownloadLink({ url, filename, children }) {
  const link = createRef();

  const handleAction = async () => {
    if (link.current.href) {
      return;
    }

    const result = await fetch(url, {
      headers: { ...authHeader() },
    });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);

    link.current.download = filename;
    link.current.href = href;

    link.current.click();
  };

  return (
    <a className="text-primary" role="button" ref={link} onClick={handleAction}>
      {children}
    </a>
  );
}

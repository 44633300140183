import { PriceInput } from 'components/PriceInput';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import React from 'react';
import { InputGroup } from 'reactstrap';

export const EditPrice = ({
  handleChangePrice,
  price,
  defaultPrice,
  disabled,
  handleSavePrice,
  handleCloseEdit,
}) => {
  const handleSubmitPrice = event => {
    //On press enter update price
    if (event.keyCode === 13) {
      handleSavePrice();
      return;
    }
    //On press Esc blur
    if (event.keyCode === 27) {
      handleCloseEdit();
    }
  };

  return (
    <div className="d-flex flex-nowrap justify-content-between">
      <InputGroup className="d-flex flex-nowrap">
        <PriceInput
          onChange={handleChangePrice}
          value={price}
          defaultValue={defaultPrice}
          autoFocus
          placeholder="Enter price"
          className="border rounded-start border-end-0"
          onKeyDown={handleSubmitPrice}
        />

        <div className="d-flex py-2 pe-3 rounded-end border-bottom border-top border-end ">
          <StyledEmptyButton
            disabled={disabled}
            className="text-success me-3 d-flex align-items-center"
            onClick={handleSavePrice}
          >
            <i className="bx bx-check" />
          </StyledEmptyButton>

          <StyledEmptyButton
            className="text-danger d-flex align-items-center"
            onClick={handleCloseEdit}
          >
            <i className="bx bx-x" />
          </StyledEmptyButton>
        </div>
      </InputGroup>
    </div>
  );
};

import { EMPTY_VALUE_WITH_LABEL } from 'common/data/constants';
import { Loader } from 'components/Common';
import {
  StyledDropDownMenu,
  StyledState,
  StyledStatus,
  StyledDropdownToggle,
} from 'components/StatusPicker/StatusPicker';
import { getClientFilter } from 'helpers/api-requests/admin';
import { debounce } from 'lodash';
import React, { createRef, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { DropdownItem, Input, Row, UncontrolledDropdown } from 'reactstrap';

const PAGE_SIZE = 50;

export const SelectClientDropdown = memo(
  ({ value, handlePickStatus, className, placeholder = 'State:' }) => {
    const inputRef = createRef();
    const { t } = useTranslation();
    const [clients, setClients] = useState({
      clients: [],
      isLoading: false,
      next: null,
    });
    const [search, setSearch] = useState({
      name: '',
      page: 1,
    });
    const { ref, inView } = useInView({
      threshold: 0.1,
    });

    useEffect(() => {
      setClients(prev => ({ ...prev, isLoading: true }));
      getClientFilter({
        search: search.name,
        page: search.page,
        page_size: PAGE_SIZE,
        status: 'active',
      }).then(response => {
        if (response.success) {
          setClients(() => ({
            ...clients,
            clients: [...clients.clients, ...response.data.results],
            next: response.data.next,
            isLoading: false,
          }));
        }
      });
    }, [search.name, search.page]);

    useEffect(() => {
      if (inView) {
        setSearch(prev => ({ ...prev, page: prev.page + 1 }));
      }
    }, [inView]);

    const convertOption = useMemo(
      () =>
        clients.clients.map(client => ({
          label: client.name,
          value: client.id,
          item: client,
        })),
      [clients],
    );

    const handleChangeSearch = debounce(text => {
      setClients(prev => ({
        ...prev,
        clients: [],
      }));
      setSearch(prev => ({ ...prev, page: 1, name: text }));
    }, 300);

    return (
      <UncontrolledDropdown className={className}>
        <StyledDropdownToggle
          $type="gray"
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <StyledStatus className="text-nowrap">
            <StyledState className="me-1">{t(placeholder)}</StyledState>
            {t(value?.label)}
          </StyledStatus>
        </StyledDropdownToggle>

        <StyledDropDownMenu>
          <div tabIndex={0} role="menuitem" className="px-3 d-flex mb-2">
            <Input
              onChange={event => handleChangeSearch(event.target.value)}
              ref={inputRef}
              defaultValue={search.name}
              tabIndex={0}
              role="menuitem"
              placeholder="Search"
            />
          </div>

          {[EMPTY_VALUE_WITH_LABEL, ...convertOption].map((status, index) => (
            <DropdownItem
              className="text-wrap"
              active={status.value === value.value}
              onClick={() => {
                handlePickStatus(status);
                handleChangeSearch('');
              }}
              key={index}
            >
              {t(status.label)}
            </DropdownItem>
          ))}

          {clients.next && <div ref={ref} />}

          {convertOption.length !== 0 && clients.isLoading && (
            <Row className="d-flex justify-content-center">
              <Loader isLoading={clients.isLoading} />
            </Row>
          )}
        </StyledDropDownMenu>
      </UncontrolledDropdown>
    );
  },
);

import {
  GET_WIZARD_SUCCESS,
  GET_WIZARD_FAIL,
  GET_WIZARD_NEXT_STEP_SUCCESS,
  GET_WIZARD_NEXT_STEP_FAIL,
  SET_WIZARD_FORM_FIELD,
  SET_WIZARD_META,
  SET_WIZARD_FILE_FIELD,
  SET_WIZARD_HELPER_FIELD,
  SET_WIZARD_FIELDS_BY_RULES,
  GET_VALIDATED_WIZARD_STEP_SUCCESS,
  SET_WIZARD_INITIAL,
  GET_WIZARD_STEP_FAIL,
  GET_WIZARD_STEP_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  error: null,
  wizard: null,
  wizardStep: {},
  stepData: null,
  form: [],
  fieldsByRules: {},
  helper: [],
  files: {},
  meta: { step: 0 },
};

const wizard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WIZARD_STEP_SUCCESS:
    case GET_WIZARD_SUCCESS:
      return {
        ...state,
        form: [{ ...action.payload.data }],
        wizard: action.payload,
      };
    case GET_WIZARD_FAIL:
    case GET_WIZARD_STEP_FAIL:
    case GET_WIZARD_NEXT_STEP_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_WIZARD_NEXT_STEP_SUCCESS:
      return {
        ...state,
        wizardStep: action.payload,
      };
    case GET_VALIDATED_WIZARD_STEP_SUCCESS:
      return (() => {
        const stepData = action.payload.data;
        let newWizard = state.wizard;
        newWizard.wizard.steps[stepData.index - 1] = stepData;
        return {
          ...state,
          wizard: { ...newWizard },
        };
      })();

    case SET_WIZARD_FORM_FIELD:
      return (() => {
        const newForm = [...state.form];
        newForm[state.meta.step] = {
          ...state.form[state.meta.step],
          ...action.payload,
        };
        return {
          ...state,
          form: newForm,
        };
      })();

    case SET_WIZARD_HELPER_FIELD:
      return (() => {
        const newHelper = [...state.helper];
        newHelper[state.meta.step] = {
          ...state.helper[state.meta.step],
          ...action.payload,
        };
        return {
          ...state,
          helper: newHelper,
        };
      })();

    case SET_WIZARD_FILE_FIELD:
      return {
        ...state,
        files: { ...state.files, ...action.payload },
      };
    case SET_WIZARD_META: {
      return {
        ...state,
        meta: { ...state.meta, ...action.payload },
      };
    }
    case SET_WIZARD_FIELDS_BY_RULES:
      return { ...state.fieldsByRules, ...action.payload };
    case SET_WIZARD_INITIAL:
      return INIT_STATE;
    default:
      return state;
  }
};

export default wizard;

import * as React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CountrySelect from '../../components/Common/CountrySelect';
import { Layout } from 'components/Layout';
import { ShadowCard } from 'components/Common';
import { showToastError } from 'helpers/utils/toast';

const AVAILABLE_COUNTRIES = [183, 46, 104]; // Russia, China, India

const WizardCountryComponent = () => {
  const history = useHistory();

  const [country, setCountry] = useState(null);

  const onSelect = async value => {
    setCountry(value.id);
  };

  const next = () => {
    if (!country) {
      showToastError('Select country');
      return;
    }
    history.push(`/wizard?country=${country}`);
  };

  return (
    <Layout title="Wizard">
      <ShadowCard>
        <h4>Select visa country</h4>
        <Row>
          <Col md={12} className="center">
            <CountrySelect
              filters={AVAILABLE_COUNTRIES}
              value={country}
              update={onSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-2 text-center">
            <Button
              disabled={!country}
              type="button"
              color="primary"
              size="lg"
              onClick={next}
            >
              Go to wizard
            </Button>
          </Col>
        </Row>
      </ShadowCard>
    </Layout>
  );
};

export default memo(WizardCountryComponent);

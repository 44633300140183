import i18n from 'i18n';

export const GENERAL_SERVICE_VALUE = 'general';
export const DELIVERY_SERVICE_VALUE = 'delivery';
export const INVITATION_SERVICE_VALUE = 'invitation';
export const CONSULAR_FEE_SERVICE_VALUE = 'consular_fee';
export const VISA_APPLICATON_CENTER_FEE_SERVICE_VALUE =
  'visa_application_center_fee';
export const VISA_SUPPORT_FEE_SERVICE_VALUE = 'visa_support_fee';

export const SERVICE_TYPES = [
  { label: i18n.t('services.general'), value: GENERAL_SERVICE_VALUE },
  { label: i18n.t('services.delivery'), value: DELIVERY_SERVICE_VALUE },
  { label: i18n.t('services.invitation'), value: INVITATION_SERVICE_VALUE },
  { label: i18n.t('services.consularFee'), value: CONSULAR_FEE_SERVICE_VALUE },
  {
    label: i18n.t('services.visaApplication'),
    value: VISA_APPLICATON_CENTER_FEE_SERVICE_VALUE,
  },
  {
    label: i18n.t('services.visaSupport'),
    value: VISA_SUPPORT_FEE_SERVICE_VALUE,
  },
];

export const servicesWithVisa = [
  CONSULAR_FEE_SERVICE_VALUE,
  VISA_APPLICATON_CENTER_FEE_SERVICE_VALUE,
  VISA_SUPPORT_FEE_SERVICE_VALUE,
];

import { INVITATION_SERVICE_VALUE } from 'common/data/services';
import {
  isUserEmployee,
  isUserClient,
  isUserIndividual,
} from 'helpers/jwt_helper';
import { isVisaServiceType } from 'helpers/serviceType';
import i18n from 'i18n';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

const NAMES_REGEXP = /^[a-zA-Z\s]*$/;

// Validation for optional fields
Yup.addMethod(
  Yup.object,
  'optional',
  function (isOptional = true, defaultValue = undefined) {
    return this.transform(function (value) {
      if (!isOptional) return value;
      if (value && Object.values(value).some(v => !!v)) {
        return value;
      }

      return defaultValue;
    }).default(defaultValue);
  },
);

export const PHONE_VALIDATION = Yup.string().test(
  'isValidPhone',
  i18n.t('validation.phone'),
  phone =>
    phone && isValidPhoneNumber(phone.at() === '+' ? phone : `+${phone}`),
);

export const REQUIRED_PHONE_VALIDATION = PHONE_VALIDATION.min(1).required(
  i18n.t('validation.required'),
);

export const REQUIRED_FIELD = Yup.string()
  .min(1)
  .required(i18n.t('validation.required'));

export const PASSWORD_VALIDATOR = Yup.string()
  .min(8, i18n.t('validation.passwordMin'))
  .required(i18n.t('validation.required'))
  .matches(/(?=.*[0-9])/, i18n.t('validation.passwordNumbers'))
  .matches(/(?=.*[A-Z])/, i18n.t('validation.passwordCapital'))
  .matches(/(?=.*[a-z])/, i18n.t('validation.passwordLetters'));

export const EMAIL_VALIDATOR = Yup.string().email();

export const REQUIRED_NUMBER_FIELD = Yup.number().required(
  i18n.t('validation.required'),
);

export const INVITE_VALIDATOR = Yup.object().shape({
  email: EMAIL_VALIDATOR.required(i18n.t('validation.required')),
});

export const APPLICANT_FORM_VALIDATOR = Yup.object().shape({
  first_name: REQUIRED_FIELD.test(
    'notNumbers',
    i18n.t('validation.characters'),
    value => (value ? NAMES_REGEXP.test(value) : true),
  ),
  last_name: REQUIRED_FIELD,
  birthday: Yup.mixed().required(i18n.t('validation.required')),
  email: Yup.string().email().required(i18n.t('validation.required')),
  passports: Yup.array(
    Yup.object({
      country: REQUIRED_FIELD,
      number: REQUIRED_FIELD,
      issue_date: Yup.date().required(i18n.t('validation.required')),
      end_date: Yup.date()
        .required(i18n.t('validation.required'))
        .min(Yup.ref('issue_date')),
    }),
  ).required(i18n.t('validation.required')),
  sex: REQUIRED_FIELD,
  phone: REQUIRED_PHONE_VALIDATION,
});

export const APPLICANT_VALIDATOR = Yup.object().shape({
  firstName: REQUIRED_FIELD,
  lastName: REQUIRED_FIELD,
  birthday: Yup.mixed().required(i18n.t('validation.required')),
  email: Yup.string().email().required(i18n.t('validation.required')),
  passports: Yup.array(
    Yup.object({
      country: REQUIRED_FIELD,
      number: REQUIRED_FIELD,
      issue_date: Yup.date().required(i18n.t('validation.required')),
      end_date: Yup.date()
        .required(i18n.t('validation.required'))
        .min(Yup.ref('issue_date')),
    }),
  )
    .min(1)
    .required(i18n.t('validation.required')),
  sex: REQUIRED_FIELD,
  phone: REQUIRED_PHONE_VALIDATION,
});

export const APPLICANT_ORDER_FORM_VALIDATOR = Yup.object().shape({
  firstName: REQUIRED_FIELD,
  lastName: REQUIRED_FIELD,
  phone: REQUIRED_PHONE_VALIDATION,
  email:
    // !isUserClient && EMAIL_VALIDATOR.required(i18n.t('validation.required')),
    !isUserIndividual &&
    EMAIL_VALIDATOR.required(i18n.t('validation.required')),
  sex: REQUIRED_FIELD,
  // birthday: Yup.date().required(i18n.t('validation.required')),
  // passport: Yup.object({
  //   nationality: REQUIRED_FIELD,
  //   number: REQUIRED_FIELD,
  //   startDate: Yup.date().required(i18n.t('validation.required')),
  //   expirationDate: Yup.date().required(i18n.t('validation.required')),
  // }).required(i18n.t('validation.required')),
});

export const APPLICANT_WITH_BIRTHDATE_ORDER_FORM_VALIDATOR = Yup.object().shape(
  {
    firstName: REQUIRED_FIELD,
    lastName: REQUIRED_FIELD,
    phone: REQUIRED_PHONE_VALIDATION,
    email:
      // !isUserClient && EMAIL_VALIDATOR.required(i18n.t('validation.required')),
      !isUserIndividual &&
      EMAIL_VALIDATOR.required(i18n.t('validation.required')),
    sex: REQUIRED_FIELD,
    birthday: Yup.date().required(i18n.t('validation.required')),
    // passport: Yup.object({
    //   nationality: REQUIRED_FIELD,
    //   number: REQUIRED_FIELD,
    //   startDate: Yup.date().required(i18n.t('validation.required')),
    //   expirationDate: Yup.date().required(i18n.t('validation.required')),
    // }).required(i18n.t('validation.required')),
  },
);

export const APPLICANT_WITH_PASSPORT_ORDER_FORM_VALIDATOR = Yup.object().shape({
  firstName: REQUIRED_FIELD,
  lastName: REQUIRED_FIELD,
  phone: REQUIRED_PHONE_VALIDATION,
  email:
    // !isUserClient && EMAIL_VALIDATOR.required(i18n.t('validation.required')),
    !isUserIndividual &&
    EMAIL_VALIDATOR.required(i18n.t('validation.required')),
  sex: REQUIRED_FIELD,
  birthday: Yup.date().required(i18n.t('validation.required')),
  passport: Yup.object({
    nationality: REQUIRED_FIELD,
    number: REQUIRED_FIELD,
    startDate: Yup.date().required(i18n.t('validation.required')),
    expirationDate: Yup.date().required(i18n.t('validation.required')),
  }).required(i18n.t('validation.required')),
});

export const SERVICE_VALIDATOR = Yup.object().shape({
  // title: REQUIRED_FIELD,
  name: Yup.object().shape({
    en: REQUIRED_FIELD,
    de: REQUIRED_FIELD,
  }),
  tariffs: Yup.array(
    Yup.object().shape({
      tariff: Yup.object({
        value: REQUIRED_NUMBER_FIELD,
      }),
    }),
  ),
  serviceType: REQUIRED_FIELD,
  country: Yup.number().when('serviceType', (serviceType, field) =>
    isVisaServiceType(serviceType) || serviceType === INVITATION_SERVICE_VALUE
      ? REQUIRED_NUMBER_FIELD
      : field,
  ),
  visaType: Yup.mixed().when('serviceType', (serviceType, field) =>
    isVisaServiceType(serviceType) || serviceType === INVITATION_SERVICE_VALUE
      ? REQUIRED_FIELD
      : field,
  ),
  visaUrgency: Yup.mixed().when('serviceType', (serviceType, field) =>
    isVisaServiceType(serviceType) ? REQUIRED_FIELD : field,
  ),
  visaDuration: Yup.mixed().when('serviceType', (serviceType, field) =>
    isVisaServiceType(serviceType) || serviceType === INVITATION_SERVICE_VALUE
      ? REQUIRED_FIELD
      : field,
  ),
});

export const DELIVERY_VALIDATOR = Yup.object().shape({
  name: REQUIRED_FIELD,
  phone: REQUIRED_PHONE_VALIDATION,
  email: REQUIRED_FIELD.email('Enter valid email'),
  code: REQUIRED_FIELD,
  country: REQUIRED_FIELD,
  city: REQUIRED_FIELD,
  address: REQUIRED_FIELD,
});

export const ORDER_VALIDATOR = Yup.object().shape({
  client: Yup.object({
    client: !isUserClient && !isUserEmployee && REQUIRED_FIELD,
    country: REQUIRED_FIELD,
    // consulate: !isUserClient && !isUserEmployee && REQUIRED_FIELD,
  }),

  applicants: Yup.array(
    Yup.object({
      // visaType: REQUIRED_FIELD,

      // ups label
      upsName: Yup.mixed().when('upsLabel', (upsLabel, field) =>
        upsLabel === 'yes' ? REQUIRED_FIELD : field,
      ),
      upsAddressLineFirst: Yup.mixed().when('upsLabel', (upsLabel, field) =>
        upsLabel === 'yes' ? REQUIRED_FIELD : field,
      ),
      upsCity: Yup.mixed().when('upsLabel', (upsLabel, field) =>
        upsLabel === 'yes' ? REQUIRED_FIELD : field,
      ),
      upsPostalCode: Yup.mixed().when('upsLabel', (upsLabel, field) =>
        upsLabel === 'yes' ? REQUIRED_FIELD : field,
      ),
      upsCountry: Yup.mixed().when('upsLabel', (upsLabel, field) =>
        upsLabel === 'yes' ? REQUIRED_FIELD : field,
      ),

      // delivery method
      deliveryMethod: REQUIRED_FIELD,
      address: Yup.object()
        .shape({
          deliveryMethod: Yup.string(),
          deliveryName: Yup.mixed().when(
            'deliveryMethod',
            (deliveryMethod, field) =>
              deliveryMethod === 'delivery_method_to_address'
                ? REQUIRED_FIELD
                : field,
          ),
          addressLineFirst: Yup.mixed().when(
            'deliveryMethod',
            (deliveryMethod, field) =>
              deliveryMethod === 'delivery_method_to_address'
                ? REQUIRED_FIELD
                : field,
          ),
          city: Yup.mixed().when('deliveryMethod', (deliveryMethod, field) =>
            deliveryMethod === 'delivery_method_to_address'
              ? REQUIRED_FIELD
              : field,
          ),
          country: Yup.mixed().when('deliveryMethod', (deliveryMethod, field) =>
            deliveryMethod === 'delivery_method_to_address'
              ? Yup.mixed().required(i18n.t('validation.required'))
              : field,
          ),
          postalCode: Yup.mixed().when(
            'deliveryMethod',
            (deliveryMethod, field) =>
              deliveryMethod === 'delivery_method_to_address'
                ? REQUIRED_FIELD.max(15, i18n.t('validation.postalMax'))
                : field,
          ),
        })
        .nullable(),
      addressAnotherApplicant: Yup.string()
        .when('deliveryMethod', (deliveryMethod, field) =>
          deliveryMethod === 'delivery_method_with_applicant'
            ? REQUIRED_FIELD
            : field,
        )
        .nullable(),
    }),
  ).optional(),
});

export const DELIVERY_FULL_VALIDATOR = Yup.object().shape({
  isTrackNumber: Yup.boolean(),
  number: Yup.string().when('isTrackNumber', {
    is: isTrackNumber => isTrackNumber,
    then: REQUIRED_FIELD,
  }),
  to: Yup.object().when('isTrackNumber', {
    is: isTrackNumber => !isTrackNumber,
    then: DELIVERY_VALIDATOR,
  }),
  from: Yup.object().when('isTrackNumber', {
    is: isTrackNumber => !isTrackNumber,
    then: DELIVERY_VALIDATOR,
  }),
  method: Yup.string().when('isTrackNumber', {
    is: isTrackNumber => !isTrackNumber,
    then: REQUIRED_FIELD,
  }),
});

export const FORGET_PASSWORD_VALIDATOR = Yup.object().shape({
  username: REQUIRED_FIELD,
});

export const TAX_VALIDATOR = Yup.object().shape({
  tax: REQUIRED_NUMBER_FIELD,
  serviceType: REQUIRED_FIELD,
});

export const CLIENT_VALIDATOR = Yup.object().shape({
  clientType: REQUIRED_FIELD,

  mainInfo: Yup.object()
    .shape({
      clientType: REQUIRED_FIELD,
      tariff: REQUIRED_NUMBER_FIELD,

      firstName: Yup.string().when('clientType', (clientType, field) =>
        clientType === 'individual' ? REQUIRED_FIELD : field,
      ),

      lastName: Yup.string().when('clientType', (clientType, field) =>
        clientType === 'individual' ? REQUIRED_FIELD : field,
      ),

      organizationName: Yup.object().when('clientType', (clientType, field) =>
        clientType === 'legal' ? REQUIRED_FIELD : field,
      ),

      taxResidencyCountry: Yup.mixed().when('clientType', (clientType, field) =>
        clientType === 'legal' ? REQUIRED_FIELD : field,
      ),
    })
    .required(),

  contacts: Yup.object().shape({
    // email: EMAIL_VALIDATOR.test('isAddressEmpty', (value, values) => {
    //   if (
    //     values.from[1].value.address &&
    //     Object.values(values.from[1].value.address).some(v => !!v)
    //   ) {
    //     if (!value) {
    //       return false;
    //     }
    //   }

    //   return true;
    // }),
    // phone: Yup.string().test('isAddressEmpty', (value, values) => {
    //   if (
    //     values.from[1].value.address &&
    //     Object.values(values.from[1].value.address).some(v => !!v)
    //   ) {
    //     if (!value) {
    //       return false;
    //     }
    //   }

    //   return true;
    // }),
    // contactName: REQUIRED_FIELD,
    contactName: Yup.string().when('clientType', (clientType, field) =>
      clientType === 'legal' ? REQUIRED_FIELD : field,
    ),

    email: EMAIL_VALIDATOR.required(i18n.t('validation.required')),
    // email: Yup.string().when('clientType', (clientType, field) =>
    //   clientType === 'legal'
    //     ? EMAIL_VALIDATOR.required(i18n.t('validation.required'))
    //     : field,
    // ),

    phone: REQUIRED_PHONE_VALIDATION,
    // phone: Yup.string().when('clientType', (clientType, field) =>
    //   clientType === 'legal' ? REQUIRED_FIELD : field,
    // ),
  }),

  address: Yup.object({
    name: REQUIRED_FIELD,
    addressLineFirst: REQUIRED_FIELD,
    city: REQUIRED_FIELD,
    country: REQUIRED_FIELD,
    postalCode: REQUIRED_FIELD.max(15, i18n.t('validation.postalMax')),
  }),
  // }).optional(),
});

//Authorization
export const LOGIN_VALIDATOR = Yup.object().shape({
  login: REQUIRED_FIELD,
  password: REQUIRED_FIELD,
});

//ResetPasswordPage
export const RESET_PASSWORD_VALIDATOR = Yup.object().shape({
  password: PASSWORD_VALIDATOR.required(i18n.t('validation.required')),
  verifyPassword: Yup.string()
    .required(i18n.t('validation.required'))
    .oneOf([Yup.ref('password'), null], i18n.t('validation.passwordSame')),
});

export const CREATE_USER_VALIDATOR = Yup.object().shape({
  first_name: REQUIRED_FIELD,
  last_name: REQUIRED_FIELD,
  email: EMAIL_VALIDATOR.required(i18n.t('validation.required')),
  role: REQUIRED_FIELD,
  password: PASSWORD_VALIDATOR.notRequired(),
  verifyPassword: Yup.string().when('password', {
    is: password => password,
    then: REQUIRED_FIELD.oneOf(
      [Yup.ref('password'), null],
      i18n.t('validation.passwordSame'),
    ),
  }),
  organization: Yup.mixed().when('role', (role, field) =>
    role === 'employee' || role === 'manager' ? Yup.object().required() : field,
  ),
});

export const TARIFF_VALIDATOR = Yup.object().shape({
  name: REQUIRED_FIELD,
});

export const PASSWORD_RESET_PROFILE_VALIDATOR = Yup.object().shape({
  oldPassword: REQUIRED_FIELD,
  newPassword: PASSWORD_VALIDATOR,
  repeatPassword: Yup.string()
    .required(i18n.t('validation.required'))
    .oneOf([Yup.ref('newPassword'), null], i18n.t('validation.passwordSame')),
});

export const REGISTRATION_VALIDATOR = {
  email: Yup.object().shape({
    email: Yup.string()
      .email('Enter valid email')
      .required(i18n.t('validation.required')),
    phone: REQUIRED_PHONE_VALIDATION,
  }),
  password: Yup.object().shape({
    password: PASSWORD_VALIDATOR,
    verifyPassword: Yup.string()
      .required(i18n.t('validation.required'))
      .oneOf([Yup.ref('password'), null], i18n.t('validation.passwordSame')),
    termOfuse: Yup.boolean().isTrue(),
  }),
  address: Yup.object().shape({
    addressLineFirst: REQUIRED_FIELD,
    city: REQUIRED_FIELD,
    country: Yup.mixed().required(i18n.t('validation.required')),
    postalCode: REQUIRED_FIELD.max(15, i18n.t('validation.postalMax')),
  }),

  names: Yup.object().shape({
    first_name: REQUIRED_FIELD.test(
      'notNumbers',
      i18n.t('validation.characters'),
      value => (value ? NAMES_REGEXP.test(value) : true),
    ),
    last_name: REQUIRED_FIELD.test(
      'notNumbers',
      i18n.t('validation.characters'),
      value => (value ? NAMES_REGEXP.test(value) : true),
    ),
    additional_name: Yup.string().test(
      'notNumbers',
      i18n.t('validation.characters'),
      value => (value ? NAMES_REGEXP.test(value) : true),
    ),
  }),
};

export const ADDRESS_VALIDATOR = Yup.object().shape({
  addressLineFirst: REQUIRED_FIELD,
  city: REQUIRED_FIELD,
  country: Yup.mixed().required(i18n.t('validation.required')),
  postalCode: REQUIRED_FIELD.max(15, i18n.t('validation.postalMax')),
  name: REQUIRED_FIELD,
});

export const SET_PASSWORD_VALIDATOR = Yup.object().shape({
  password: PASSWORD_VALIDATOR,
  verifyPassword: Yup.string()
    .required(i18n.t('validation.required'))
    .oneOf([Yup.ref('password'), null], i18n.t('validation.passwordSame')),
});

export const INVOICE_VALIDATOR = Yup.object().shape({
  client: Yup.object().required(i18n.t('validation.required')),
  periodStart: Yup.date().required(i18n.t('validation.required')),
  periodEnd: Yup.date()
    .required(i18n.t('validation.required'))
    .min(Yup.ref('periodStart')),
  invoices: Yup.array().required().min(1),
});

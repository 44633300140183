export default function authHeader() {
  const obj = JSON.parse(localStorage.getItem('authUser'));

  if (obj && obj.access) {
    return { Authorization: `Bearer ${obj.access}` };
  } else {
    return {};
  }
}

export const authLink = url => {
  const obj = JSON.parse(localStorage.getItem('authUser'));

  if (obj && obj.access) {
    return url + '?jwtToken=Bearer ' + obj.access;
  }

  return url;
};

export const USERS_BREADCRUMBS = [{ title: 'sideBar.users', to: '/users' }];
export const ORGANIZATION_BREADCRUMBS = [
  { title: 'Organizations', to: '/organizations' },
];
export const ORDERS_BREADCRUMBS = [{ title: 'sideBar.order', to: '/orders' }];
export const ORDERS_FEED_BREADCRUMBS = [
  { title: 'sideBar.ordersFeed', to: '/orders/feed' },
];

export const SERVICES_BREADCRUMBS = [
  { title: 'sideBar.services', to: '/services' },
];
export const INVOICES_BREADCRUMBS = [
  { title: 'sideBar.invoices', to: '/invoices' },
];
export const CREATE_CLIENTS_BREADCRUMBS = [
  { title: 'sideBar.clients', to: '/clients' },
];

export const DELIVERIES_BREADCRUMBS = [
  { title: 'sideBar.delivery', to: '/deliveries' },
];

export const TAXES_BREADCRUMBS = [{ title: 'Taxes', to: '/taxes' }];

export const TARIFFS_BREADCRUMBS = [
  { title: 'sideBar.tariffs', to: '/tariffs' },
];

export const ORDERTYPES_BREADCRUMBS = [
  { title: 'orderTypes.orderTypes', to: '/orderTypes' },
];

export const CHAT_BREADCRUMBS = [{ title: 'sideBar.chats', to: '/chats' }];

export const INVITES_BREADCRUMBS = [
  { title: 'sideBar.invites', to: '/invites' },
];

export const REPORT_BREADCRUMBS = [
  { title: 'sideBar.reports', to: '/reports' },
];

export const APPLICANTS_BREADCRUMBS = [
  { title: 'sideBar.applicants', to: '/applicants' },
];

export const EMPLOYEE_BREADCRUMBS = [
  { title: 'profile.profile', to: '/profile' },
];

export const ACCOUNTING_CODES_BREADCRUMBS = [
  { title: 'common.export', to: '/export' },
];

export const ORDERTYPE_DOCUMENTS_BREADCRUMBS = [
  { title: 'orderTypes.orderType', to: '/orderTypes' },
];

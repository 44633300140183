import { createQueryActions } from 'helpers/store/createQueryActions';

export const openOrderAlert = createQueryActions('openOrderAlert');

export const closeOrderAlert = createQueryActions('closeOrderAlert');

export const fetchOrder = createQueryActions('fetchOrder');

export const updateOrder = createQueryActions('updateOrder');

export const createOrder = createQueryActions('createOrder');

import React from 'react';

const TableRow = ({ service, netPrice, taxPercent, totalPrice }) => (
  <tr>
    <td className="col-3" colSpan="1">
      {service ?? null}
    </td>

    <td className="col-3" width={200}>
      {netPrice}
    </td>

    <td className="col-3" width={200}>
      {taxPercent}
    </td>

    <td className="col-3 fw-semibold" width={200}>
      {totalPrice}
    </td>
  </tr>
);
export default TableRow;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  blockUser,
  createUser,
  getUser,
  inviteUser,
  unBlockUser,
  updateUser,
} from 'store/actions';
import { USERS_BREADCRUMBS } from 'common/data/beadcrumbs';
import UserForm from 'components/User/UserForm';
import { Layout } from 'components/Layout';
import { HeadControlButtons } from 'components/HeadControllButtons/HeadControllButtons';
import { FormikProvider, useFormik } from 'formik';
import { CREATE_USER_VALIDATOR } from 'forms/validators';
import { UserControlButtons } from 'components/Common/UserControlButtons';
import { Col, Row } from 'reactstrap';
import { NavBar } from 'components/Common/NavBar';
import { convertUserFromAPI, convertUserUpdateToAPI } from 'helpers/converters';
import { UserPasswordCard } from './UserPasswordCard';

const initialData = {
  first_name: '',
  last_name: '',
  email: '',
  role: undefined,
  phone: '',
  organization: undefined,
  password: '',
  verifyPassword: '',
};

const userNav = [
  { title: 'common.information', id: 1, link: 'information', offset: -110 },
  { title: 'auth.password', id: 2, link: 'password' },
];

export const getUserState = state => state.admin.rest.user;

const User = () => {
  const { currentUser, isLoading, isSubmitting } = useSelector(getUserState);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [id]);

  const handleSubmitForm = values => {
    if (id) {
      dispatch(
        updateUser({
          id: currentUser.id,
          user: convertUserUpdateToAPI(values),
        }),
      );
      return;
    }

    dispatch(
      createUser({
        push,
        user: convertUserUpdateToAPI(values),
      }),
    );
  };

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validateOnChange: Boolean(id),
    validationSchema: CREATE_USER_VALIDATOR,
    onSubmit: handleSubmitForm,
  });

  useEffect(() => {
    if (currentUser && id && Number(id) === Number(currentUser.id)) {
      formik.resetForm({
        values: convertUserFromAPI(currentUser),
      });
    }
  }, [currentUser, id]);

  const lockUser = () => dispatch(blockUser({ id }));

  const unlockUser = () => dispatch(unBlockUser({ id }));

  const handleInviteUser = () => dispatch(inviteUser({ id }));

  return (
    <FormikProvider value={formik}>
      <Layout
        isScrollableHeader
        title={
          id
            ? currentUser?.first_name
              ? `${currentUser?.first_name} ${currentUser?.last_name}`
              : 'users.userDetail'
            : 'users.createUser'
        }
        breadcrumbs={USERS_BREADCRUMBS}
        extraContent={
          <HeadControlButtons
            handleSubmit={formik.handleSubmit}
            isDisabledButton={!formik.dirty || isSubmitting}
            path="/users"
          />
        }
        loading={isLoading}
      >
        <Row>
          <Col xs={3}>
            <NavBar
              extraContent={
                id && (
                  <UserControlButtons
                    lockUser={lockUser}
                    unlockUser={unlockUser}
                    status={formik.values?.status}
                    handleInviteUser={handleInviteUser}
                    isOpenInviteButton={
                      !currentUser?.last_login &&
                      currentUser?.status === 'active'
                    }
                    isDisabledInviteUser={currentUser?.isInvited}
                  />
                )
              }
              components={id ? userNav : [userNav[0]]}
            />
          </Col>

          <Col className="ps-4" xs={9}>
            <UserForm />

            {id && <UserPasswordCard />}
          </Col>
        </Row>
      </Layout>
    </FormikProvider>
  );
};

export default User;

import {
  DATE_FORMAT_WITH_SYMBOL,
  EMPTY_VALUE_WITH_LABEL,
  PAGES,
  PDF,
  XLS,
} from 'common/data/constants';
import {
  Badge,
  BlueLink,
  ButtonCreate,
  Loader,
  PaginationLm,
  PerPageSelect,
  ShadowCard,
} from 'components/Common';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { Layout } from 'components/Layout';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { StyledTable } from 'components/Table/StyledTable';
import { ButtonControlTable } from 'components/Tariffs/ButtonControlTable';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { useTable } from 'hooks/useTable';
import { getAggregatedInvoiceFile } from 'helpers/downloadDocument';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import { useModal } from 'hooks/useModal';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAggregatedInvoice, getAggregatedInvoices } from 'store/actions';
import { SelectClientDropdown } from './SelectClientDropdown';
import { convertDateToAPI } from 'helpers/converters';
import { formatDate } from 'helpers/formatDate';
import { AGGREGATE_INVOICE_STATUSES } from 'common/data/constants';

const additionalSearch = {
  endDate: null,
  perPage: PAGES[0],
  startDate: null,
  status: EMPTY_VALUE_WITH_LABEL,
  client: EMPTY_VALUE_WITH_LABEL,
  search: '',
};

const headers = [
  'common.number',
  'reports.client',
  'reports.created',
  'reports.period',
  'reports.paymentStatus',
  '',
];

const getAggregatedInvoicesSelect = state =>
  state.aggregatedInvoice.aggregatedInvoice.aggregatedInvoices;

export const AggregatedInvoices = memo(() => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispath = useDispatch();

  const { count, invoices, isLoading } = useSelector(
    getAggregatedInvoicesSelect,
  );

  const { isOpen, handleCloseModal, handleOpenModal } = useModal();
  const { searchState, handleChangeSearchParam, showPagination } = useTable(
    additionalSearch,
    count,
  );

  useEffect(() => {
    dispath(
      getAggregatedInvoices({
        status: searchState.status.value,
        page_size: searchState.perPage.value,
        page: searchState.page,
        number: searchState.search,
        created_at_start: convertDateToAPI(searchState.startDate),
        created_at_end: convertDateToAPI(searchState.endDate),
        client_type: searchState.client.item?.client_type?.client_type,
        client_id: searchState.client.item?.id,
      }),
    );
  }, [searchState]);

  const getReportActions = useCallback(report => {
    const actions = [
      {
        title: t('reports.downloadInvoice'),
        id: 1,
        function: () => getAggregatedInvoiceFile(report, PDF),
      },
      {
        title: t('reports.downloadDetails'),
        id: 2,
        function: () => getAggregatedInvoiceFile(report, XLS),
      },
    ];

    if (report.status.status !== 'paid') {
      return actions.concat({
        title: 'Delete',
        id: 3,
        function: () => handleOpenModal(report.id),
      });
    }

    return actions;
  }, []);

  const handleDelete = () => {
    dispath(deleteAggregatedInvoice(isOpen));
    handleCloseModal();
  };

  return (
    <Layout
      extraContent={
        <ButtonCreate handleCreate={() => push('/reports/create')} />
      }
      title={t('reports.financialReport')}
    >
      {isOpen && (
        <ModalConfirmDelete
          textBody="reports.deleteConfirm"
          textHeader="reports.delete"
          isOpenModal={Boolean(isOpen)}
          handleDelete={handleDelete}
          handleCloseModal={handleCloseModal}
        />
      )}

      <ShadowCard>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between  flex-wrap align-items-center">
              <div className="d-flex flex-wrap align-items-center ">
                <div className="me-3 mb-3">
                  <SearchInput
                    handleChange={event =>
                      handleChangeSearchParam('search', event.target.value)
                    }
                  />
                </div>

                <div className="me-3 mb-3" style={{ minWidth: 300 }}>
                  <DateRangePicker
                    onChange={range =>
                      handleChangeSearchParam('dateRange', range)
                    }
                    endDate={searchState.endDate}
                    startDate={searchState.startDate}
                  />
                </div>

                <div className="me-3 mb-3">
                  <StatusPicker
                    placeholder="reports.paymentStatus"
                    value={searchState.status}
                    $type="gray"
                    handlePickStatus={status =>
                      handleChangeSearchParam('status', status)
                    }
                    statuses={AGGREGATE_INVOICE_STATUSES}
                  />
                </div>
                <div className="me-3 mb-3">
                  <SelectClientDropdown
                    placeholder="reports.client"
                    handlePickStatus={client =>
                      handleChangeSearchParam('client', client)
                    }
                    value={searchState.client}
                  />
                </div>
              </div>

              <div className="me-3 mb-3">
                <PerPageSelect
                  value={searchState.perPage}
                  perPage={searchState.perPage}
                  setPerPage={value =>
                    handleChangeSearchParam('perPage', value)
                  }
                  setPage={value => handleChangeSearchParam('page', value)}
                />
              </div>
            </div>

            {isLoading && (
              <Row className="d-flex justify-content-center">
                <Loader isLoading={isLoading} />
              </Row>
            )}

            {!isLoading && !invoices?.length && (
              <Alert color="info">{t('common.notFound')}</Alert>
            )}

            {!isLoading && invoices?.length > 0 && (
              <>
                <div className="table-responsive">
                  <StyledTable>
                    <thead>
                      <tr>
                        {headers.map(header => (
                          <th key={header}>{t(header)}</th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {invoices.map(report => (
                        <tr key={report.number}>
                          <td className="col-2">
                            <BlueLink to={`/reports/${report.id}`}>
                              {report.number}
                            </BlueLink>
                          </td>

                          <td className="col-2">{report.client?.name}</td>

                          <td className="col-2">
                            {formatDate(
                              report.created_at,
                              DATE_FORMAT_WITH_SYMBOL,
                            )}
                          </td>

                          <td className="col-3">
                            {`${formatDate(
                              report.period_from,
                              DATE_FORMAT_WITH_SYMBOL,
                            )} - ${formatDate(
                              report.period_to,
                              DATE_FORMAT_WITH_SYMBOL,
                            )}`}
                          </td>

                          <td className="col-4">
                            <Badge
                              color={convertStatusToColor(report.status.status)}
                              text={report.status.status_display}
                            />
                          </td>

                          <td>
                            <ButtonControlTable
                              actions={getReportActions(report)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                </div>

                <Col xs={12}>
                  {showPagination && (
                    <PaginationLm
                      count={count}
                      perPage={searchState.perPage.value}
                      active={searchState.page}
                      selectPage={value =>
                        handleChangeSearchParam('page', value)
                      }
                    />
                  )}
                </Col>
              </>
            )}
          </Col>
        </Row>
      </ShadowCard>
    </Layout>
  );
});

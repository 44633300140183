import { Layout } from 'components/Layout';
import { PaginationLm } from 'components/Common/PaginationLm';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { getInvoices } from 'store/actions';

import {
  INVOICE_STATUS,
  INVOICE_STATUSES,
  DATE_FORMAT_WITH_SYMBOL,
  EMPTY_VALUE_WITH_LABEL,
  FIRST_PAGE,
  PAGES,
} from '../../common/data/constants';
import { formatCurrency } from 'helpers/formatCurrency';
import { formatDate } from 'helpers/formatDate';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { StyledTable } from 'components/Table/StyledTable';
import { ShadowCard } from 'components/Common/ShadowCard';
import { Badge, BlueLink, Loader } from 'components/Common';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { useTable } from 'hooks/useTable';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { ResponsiveFilters } from 'components/Common/ResponsiveFilters';
import { isUserIndividual } from 'helpers/jwt_helper';

const getInvoiceState = state => state.invoices;

const invoiceAdditionalField = {
  status: EMPTY_VALUE_WITH_LABEL,
  page: FIRST_PAGE,
  perPage: PAGES[0],
  search: '',
};

const Invoices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const invoiceState = useSelector(getInvoiceState);
  const { invoices, count } = invoiceState.invoices;
  const { loading } = invoiceState;
  const { handleChangeSearchParam, searchState, showPagination } = useTable(
    invoiceAdditionalField,
    count,
  );

  useEffect(() => {
    dispatch(fetchInvoices());
  }, [searchState]);

  const fetchInvoices = () =>
    getInvoices({
      page_size: searchState.perPage.value,
      page: searchState.page,
      client: searchState.search,
      status: searchState.status.value,
    });

  return (
    <Layout title="sideBar.invoices">
      <ShadowCard>
        <ResponsiveFilters
          lastItem={
            <PerPageSelect
              perPage={searchState.perPage}
              setPage={page => handleChangeSearchParam('page', page)}
              setPerPage={perPage =>
                handleChangeSearchParam('perPage', perPage)
              }
            />
          }
        >
          {!isUserIndividual && (
            <div className="me-3 mb-3">
              <SearchInput
                handleChange={event =>
                  handleChangeSearchParam('search', event.target.value)
                }
              />
            </div>
          )}

          <div className="me-3 mb-3">
            {!isUserIndividual && (
              <StatusPicker
                value={searchState.status}
                placeholder={t('invoices.placeholder')}
                $type="gray"
                handlePickStatus={status =>
                  handleChangeSearchParam('status', status)
                }
                statuses={INVOICE_STATUSES}
              />
            )}
          </div>
        </ResponsiveFilters>

        {loading && (
          <Row className="d-flex align-items-center justify-content-center">
            <Loader isLoading={loading} />
          </Row>
        )}

        {!loading && invoices?.length === 0 && (
          <Alert color="info">{t('invoices.notFound')}</Alert>
        )}

        {!loading && invoices?.length > 0 && (
          <>
            <div className="table-responsive">
              <StyledTable>
                <thead>
                  <tr>
                    <th>{t('invoices.created')}</th>

                    <th>{t('invoices.invoiceNumber')}</th>

                    <th>{t('order.orderType')}</th>

                    <th>{t('invoices.orderNumber')}</th>

                    <th>{t('invoices.customer')}</th>

                    <th>{t('invoices.invoicePrice')}</th>

                    <th>{t('invoices.state')}</th>
                  </tr>
                </thead>

                <tbody>
                  {invoices.map(invoice => (
                    <tr key={invoice.id}>
                      <td>
                        {formatDate(
                          invoice.created_at,
                          DATE_FORMAT_WITH_SYMBOL,
                        )}
                      </td>

                      <td>
                        <BlueLink to={`/invoices/${invoice.id}`}>
                          {invoice.number}
                        </BlueLink>
                      </td>

                      <td>{invoice.order.order_type}</td>

                      <td className="text-nowrap">
                        {invoice.order ? (
                          <BlueLink to={`/orders/${invoice.order.id}`}>
                            {invoice.order.number}
                          </BlueLink>
                        ) : (
                          ''
                        )}
                      </td>

                      <td>
                        {invoice.client.type === 'legal' && invoice.client.name}

                        {invoice.client.type === 'individual' &&
                          invoice.client.name}
                      </td>

                      <td>{formatCurrency(invoice.bill_total_price_euro)}</td>

                      <td>
                        <Badge
                          text={INVOICE_STATUS[invoice.status]}
                          color={convertStatusToColor(invoice.status)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>

            <Row>
              <Col xs={12}>
                {showPagination && (
                  <PaginationLm
                    count={count}
                    perPage={searchState.perPage.value}
                    active={searchState.page}
                    selectPage={page => handleChangeSearchParam('page', page)}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </ShadowCard>
    </Layout>
  );
};

export default Invoices;

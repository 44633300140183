import { createQueryActions } from 'helpers/store/createQueryActions';

export const getAggregatedInvoices = createQueryActions(
  'getAggregatedInvoices',
);

export const getAggregatedInvoice = createQueryActions('getAggregatedInvoice');

export const deleteAggregatedInvoice = createQueryActions(
  'deleteAggregatedInvoice',
);

export const createAggregatedInvoice = createQueryActions(
  'createAggregatedInvoice',
);

export const updateStatusAggregatedInvoice = createQueryActions(
  'updateStatusAggregatedInvoice',
);

export const updateAggregatedInvoice = createQueryActions(
  'updateAggregatedInvoice',
);

export const getCandidateInvoice = createQueryActions('getCandidateInvoice');

export const deleteInvoiceFromAggregate = createQueryActions(
  'deleteInvoiceFromAggregate',
);

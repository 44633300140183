import { BlueLink } from 'components/Common';
import { EditPrice } from 'components/Common/EditPrice';

import { useFormikContext } from 'formik';
import { convertPriceToAPI } from 'helpers/converters';
import { formatCurrency } from 'helpers/formatCurrency';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import Dropdown from './Dropdown';
import { GENERAL_SERVICE_VALUE } from 'common/data/services';
import { showToastError } from 'helpers/utils/toast';
import { EditTags } from './EditTags';
import { isUserOperator } from 'helpers/jwt_helper';

export const gitTariffsState = state => state.tariffs.tariffs.tariffs;

const name = 'tariffs';

export const ServiceTariffRow = ({ index, handleDeleteRow, tariff }) => {
  const formik = useFormikContext();
  const { tariffs } = useSelector(gitTariffsState);
  const { t } = useTranslation();

  const [editedService, setEditedService] = useState({
    currentId: null,
    price: '',
    autoSelect: false,
  });

  const handleChangePrice = event =>
    setEditedService({ ...editedService, price: event.target.value });

  const handleAutoSelect = event => {
    formik.setFieldValue(`tariffs[${index}].autoSelect`, event.target.checked);
  };

  const tariffsOption = useMemo(() => {
    if (tariffs && formik.values?.tariffs) {
      return tariffs
        ?.map(tariff => ({
          value: tariff.id,
          label: tariff.name,
          item: { value: tariff.id, label: tariff.name },
        }))
        .filter(
          tariff =>
            !formik.values?.tariffs
              .map(tariffs => tariffs.tariff.value)
              .includes(tariff.value),
        );
    }
  }, [tariffs, formik.values.tariffs]);

  return (
    <tr key={index}>
      <td className="col-2">
        {tariff?.tariff?.label ? (
          <BlueLink to={`/${name}/${tariff?.tariff?.value}`}>
            {tariff?.tariff?.label}
          </BlueLink>
        ) : (
          <Dropdown
            invalid={formik.errors?.tariffs?.[index]?.tariff?.value}
            value={tariff?.tariff}
            options={tariffsOption}
            disabled={tariff?.tariff.id}
            handleSelect={tariff =>
              formik.setFieldValue(`tariffs[${index}].tariff`, tariff.item)
            }
            titleText={t('services.selectTariff')}
          />
        )}
      </td>

      {formik.values.serviceType === GENERAL_SERVICE_VALUE && (
        <td>
          <div className="d-flex w-100 h-100 align-items-center justify-content-center">
            <Input
              type="checkbox"
              className="m-0"
              name="autoSelect"
              checked={formik.values.tariffs[index].autoSelect}
              value={formik.values.tariffs[index].autoSelect}
              onChange={handleAutoSelect}
            />
          </div>
        </td>
      )}

      {isUserOperator && (
        <td className="col-4">
          <EditTags index={index} tariff={tariff} />
        </td>
      )}

      <td className="col-3 py-0">
        {editedService.currentId === index ? (
          <EditPrice
            price={editedService.price}
            disabled={editedService.price === formatCurrency(tariff?.price)}
            handleSavePrice={() => {
              if (!editedService.price) {
                showToastError('Please fill price field');
                return;
              }

              formik.setFieldValue(
                `tariffs[${index}].price`,
                editedService.price,
              );
              setEditedService({});
            }}
            handleCloseEdit={() => setEditedService({})}
            handleChangePrice={handleChangePrice}
          />
        ) : (
          <div className="d-flex align-items-center">
            <span className="me-2">{tariff?.price}</span>

            <StyledEmptyButton
              onClick={() =>
                setEditedService({
                  currentId: index,
                  price:
                    convertPriceToAPI(tariff.price) !== 0
                      ? tariff.price
                      : undefined,
                })
              }
            >
              <i className="bx bx-edit-alt fs-6" />
            </StyledEmptyButton>
          </div>
        )}
      </td>

      <td className="col-1 p-1 m-0">
        <div className="d-flex justify-content-end pe-3">
          <StyledEmptyButton className="text-dark" onClick={handleDeleteRow}>
            <i className="bx bx-trash" />
          </StyledEmptyButton>
        </div>
      </td>
    </tr>
  );
};

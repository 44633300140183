import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import {
  GET_VISA_TYPES,
  GET_COUNTRIES,
  GET_ADDRESSES,
  GET_ADDRESS,
  GET_REGIONS,
  getOrderCountries,
  getDeliveryCountries,
  GET_REFERENCES_CONFIG,
  getCitizenshipCountries,
  getUPSLabelCountries,
  getUrgenciesAction,
} from './actionTypes';
import {
  getVisaTypesFail,
  getVisaTypesSuccess,
  getCountriesFail,
  getCountriesSuccess,
  getAddressesFail,
  getAddressesSuccess,
  getAddressSuccess,
  getAddressFail,
  getRegionsSuccess,
  getRegionsFail,
  getReferencesConfigSuccess,
  getReferencesConfigFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getVisaTypes,
  getCountries,
  getAddresses,
  getGoogleAddress,
  getRegionsData,
  getReferencesConfig,
  getUrgenciesRequest,
} from 'helpers/api-requests/admin';

function* fetchVisaTypes({ payload }) {
  try {
    const response = yield call(getVisaTypes, payload);
    if (response.success) {
      yield put(getVisaTypesSuccess(response.data.results));
    } else {
      yield put(getVisaTypesFail(response.data));
    }
  } catch (error) {
    yield put(getVisaTypesFail(error));
  }
}

function* fetchCountries() {
  try {
    const response = yield call(getCountries);
    if (response.success) {
      yield put(getCountriesSuccess(response.data.results));
    } else {
      yield put(getCountriesFail(response.data));
    }
  } catch (error) {
    yield put(getCountriesFail(error));
  }
}

function* fetchOrderCountries({ payload }) {
  try {
    const response = yield call(getCountries, payload);
    if (response.success) {
      yield put(getOrderCountries.success(response.data.results));
    } else {
      yield put(getOrderCountries.failure(response.data));
    }
  } catch (error) {
    yield put(getOrderCountries.failure(error));
  }
}

function* fetchUrgenciesSaga({ payload }) {
  try {
    const response = yield call(getUrgenciesRequest, payload);
    if (response.success) {
      yield put(getUrgenciesAction.success(response.data.results));
    } else {
      yield put(getUrgenciesAction.failure(response.data));
    }
  } catch (error) {
    yield put(getUrgenciesAction.failure(error));
  }
}

function* fetchDeliveryCountries({ payload }) {
  try {
    const response = yield call(getCountries, payload);
    if (response.success) {
      yield put(getDeliveryCountries.success(response.data.results));
    } else {
      yield put(getDeliveryCountries.failure(response.data));
    }
  } catch (error) {
    yield put(getDeliveryCountries.failure(error));
  }
}

function* fetchCitizenshipCountries({ payload }) {
  try {
    const response = yield call(getCountries, payload);
    if (response.success) {
      yield put(getCitizenshipCountries.success(response.data.results));
    } else {
      yield put(getCitizenshipCountries.failure(response.data));
    }
  } catch (error) {
    yield put(getCitizenshipCountries.failure(error));
  }
}

function* fetchUPSLabelCountries({ payload }) {
  try {
    const response = yield call(getCountries, payload);
    if (response.success) {
      yield put(getUPSLabelCountries.success(response.data.results));
    } else {
      yield put(getUPSLabelCountries.failure(response.data));
    }
  } catch (error) {
    yield put(getUPSLabelCountries.failure(error));
  }
}

function* fetchAddresses({ payload }) {
  try {
    const response = yield call(getAddresses, payload);
    if (response.success) {
      yield put(getAddressesSuccess(response.data.addresses));
    } else {
      yield put(getAddressesFail(response.data));
    }
  } catch (error) {
    yield put(getAddressesFail(error));
  }
}

function* fetchAddress({ payload }) {
  try {
    const response = yield call(getGoogleAddress, payload);
    if (response.success) {
      yield put(getAddressSuccess(response.data.address));
    } else {
      yield put(getAddressFail(response.data));
    }
  } catch (error) {
    yield put(getAddressFail(error));
  }
}

function* fetchRegions() {
  try {
    const response = yield call(getRegionsData);
    if (response.success) {
      yield put(getRegionsSuccess(response.data));
    } else {
      yield put(getRegionsFail(response.data));
    }
  } catch (error) {
    yield put(getRegionsFail(error));
  }
}

function* fetchReferencesConfig() {
  try {
    const response = yield call(getReferencesConfig);
    if (response.success) {
      yield put(getReferencesConfigSuccess(response.data));
    } else {
      yield put(getReferencesConfigFail(response.data));
    }
  } catch (error) {
    yield put(getReferencesConfigFail(error));
  }
}

function* referencesSaga() {
  yield takeEvery(GET_VISA_TYPES, fetchVisaTypes);
  yield takeEvery(GET_COUNTRIES, fetchCountries);
  yield takeEvery(getOrderCountries, fetchOrderCountries);
  yield takeEvery(GET_ADDRESSES, fetchAddresses);
  yield takeEvery(GET_ADDRESS, fetchAddress);
  yield takeEvery(GET_REGIONS, fetchRegions);
  yield takeEvery(GET_REFERENCES_CONFIG, fetchReferencesConfig);
  yield takeEvery(getDeliveryCountries, fetchDeliveryCountries);
  yield takeEvery(getCitizenshipCountries, fetchCitizenshipCountries);
  yield takeEvery(getUPSLabelCountries, fetchUPSLabelCountries);
  yield takeEvery(getUrgenciesAction, fetchUrgenciesSaga);
}

export default referencesSaga;

import { Loader } from 'components/Common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

export const useLoader = (isLoadingApi = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(isLoadingApi);
  }, [isLoadingApi]);

  return {
    Loader: isLoading ? <Loader isLoading={isLoading} /> : null,
    handleEndLoad: () => setIsLoading(false),
    handleStartLoad: () => setIsLoading(true),
    notFound: (text, isNotFound) =>
      !isLoading && isNotFound ? <Alert color="info">{t(text)}</Alert> : null,
    isLoading,
  };
};

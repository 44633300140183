import classNames from 'classnames';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Label } from 'reactstrap';
import styled, { css } from 'styled-components';

export const StyledError = styled.span`
  font-size: 12px;
`;

const StyledErrorWrapper = styled.div`
  min-height: 25px;
`;

export const StyledInput = styled(Input)`
  background-image: none !important;
  height: 40px;
  border: 1px solid #dee0e7;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &::placeholder {
    color: #9397a4;
  }

  ${({ $isBlue, theme }) =>
    $isBlue &&
    css`
      border-color: ${theme.colors.blue200};
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.danger};
    `}

  ${({ $noResize }) =>
    $noResize &&
    css`
      resize: none;
      height: 80px;
    `}

  ${({ $textarea }) =>
    $textarea &&
    css`
      height: 80px;
    `}
`;

export const StyledLabel = styled(Label)`
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `}
`;

const StyledImage = styled.i`
  font-size: 22px !important;
  color: ${({ theme }) => theme.colors.gray400};
`;

const StyledEyeButton = styled(StyledEmptyButton)`
  position: absolute !important;
  right: 16px;
  top: 8px;
  z-index: 10;

  &:hover {
    i {
      color: ${({ theme }) => theme.colors.mainText};
    }
  }
`;

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;

  input {
    max-height: 40px;
  }

  ${({ type }) =>
    type === 'password'
      ? css`
          margin-bottom: 0rem !important;
        `
      : null}
`;

const FormikInput = memo(
  ({
    label,
    name,
    value,
    handleChange,
    errors,
    className,
    placeholder,
    type = 'text',
    link,
    labelStyle,
    customInput,
    groupStyle,
    $noResize,
    ...props
  }) => {
    const { t } = useTranslation();
    const [typeInput, setTypeInput] = useState(type);

    return (
      <StyledFormGroup className={groupStyle} type={type}>
        {label && (
          <div className="d-flex flex-wrap justify-content-between">
            <StyledLabel style={labelStyle} invalid={errors ? 1 : 0}>
              {t(label)}
            </StyledLabel>

            {link}
          </div>
        )}

        <div>
          {customInput ?? (
            <div className="d-flex flex-nowrap position-relative">
              <StyledInput
                name={name}
                type={typeInput}
                $noResize={$noResize}
                value={value ?? ''}
                className={classNames('form-control', className)}
                onChange={handleChange}
                invalid={Boolean(errors)}
                placeholder={t(placeholder)}
                {...props}
              />

              {type === 'password' &&
                (typeInput === 'password' ? (
                  <StyledEyeButton
                    className="d-flex align-items-center"
                    onClick={() => setTypeInput('text')}
                  >
                    <StyledImage className="bx bx-show" />
                  </StyledEyeButton>
                ) : (
                  <StyledEyeButton
                    className="d-flex align-items-center"
                    onClick={() => setTypeInput('password')}
                  >
                    <StyledImage className="bx bxs-show" />
                  </StyledEyeButton>
                ))}
            </div>
          )}

          <StyledErrorWrapper className="w-100 d-flex justify-content-between">
            <Col xs={12}>
              {Boolean(errors) && (
                <StyledError className="text-danger fw-lighter">
                  {errors}
                </StyledError>
              )}
            </Col>
          </StyledErrorWrapper>
        </div>
      </StyledFormGroup>
    );
  },
);

export default FormikInput;

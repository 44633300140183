import React, { useEffect, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { Select } from 'components/Common';
import { SELECT_CLASSNAME } from 'common/data/constants';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCountries } from 'helpers/api-requests/admin';

// const getOrderCountriesState = state => state.references.orderCountries;

export const SelectConsulate = ({
  value,
  onChange,
  country,
  isDisabled,
  invalid,
}) => {
  // const countries = useSelector(getOrderCountriesState);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries().then(response => {
      if (response.success) {
        setCountries(response.data.results);
      } else {
        console.log(response.message);
      }
    });
  }, [country]);

  const { t } = useTranslation();

  // const consulatesOptions = () =>
  //   countries
  //     .find(item => item.id === country)
  //     ?.consulates?.filter(consulate => consulate.is_active)
  //     ?.map(item => ({
  //       value: item.id,
  //       label: item.name,
  //     }));

  const consulatesOptions = () =>
    countries.length > 0
      ? countries
          .find(item => item.id === country)
          ?.consulates?.filter(consulate => consulate.is_active)
          ?.map(item => ({
            value: item.id,
            label: item.name,
          }))
      : [];

  const val = useMemo(() => {
    if (isNil(value)) {
      return null;
    }
    return consulatesOptions()?.find(item => item.value === value) ?? null;
  }, [consulatesOptions(), value]);

  return (
    <Select
      isDisabled={isDisabled}
      placeholder={t('common.consulatePlaceholder')}
      value={val}
      isClearable
      options={consulatesOptions()}
      classNamePrefix={SELECT_CLASSNAME}
      onChange={onChange}
      invalid={invalid}
    />
  );
};

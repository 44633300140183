import { Select } from '../Common/Select';
import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import { useCountries } from 'hooks/useCountries';
import { useTranslation } from 'react-i18next';

export const SelectCountry = ({
  id,
  invalid,
  name,
  value,
  onChange,
  isMulti,
  countries,
  placeholder = 'client.countryPlaceholder',
  isAvailableOrder = false,
  isAvailableDelivery = false,
  isAvailableCitizenship = false,
  isAvailableUPSLabel = false,
  ...props
}) => {
  const { countriesOptions } = useCountries(
    isAvailableOrder,
    isAvailableDelivery,
    isAvailableCitizenship,
    isAvailableUPSLabel,
  );
  const { t, i18n } = useTranslation();

  const countriesConverted =
    countries &&
    countries?.map(item => ({
      value: item.id,
      label: item.country?.name,
    }));

  const val = useMemo(() => {
    if (isNil(value)) {
      return null;
    }

    return (
      (countriesConverted ?? countriesOptions).find(
        item => item.value === value,
      ) ?? null
    );
  }, [countriesOptions, value, countries, i18n.language]);

  return (
    <Select
      value={isMulti ? value : val}
      onChange={onChange}
      name={name}
      inputId={name}
      classNamePrefix="select2-selection"
      className="vbo-select display-5"
      options={
        isMulti
          ? countriesConverted ?? countriesOptions
          : [
              { value: '', label: t('common.all') },
              ...(countriesConverted ?? countriesOptions),
            ]
      }
      id={id}
      isMulti={isMulti}
      isClearable={isMulti ? value?.length > 1 : true}
      invalid={invalid}
      placeholder={t(placeholder)}
      {...props}
    />
  );
};

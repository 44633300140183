import { ShadowCard } from 'components/Common/ShadowCard';
import { FieldArray, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'components/Common/Accordion';
import { ApplicantAdd } from './ApplicantAdd';
import { ApplicantBody } from '../Tabs/ApplicantBody';
import { ApplicantDetail } from '../Tabs/ApplicantDetail';
import { typeColor } from 'helpers/styles/typeColor';
import { StyledButtonGray } from 'components/Common';
import { useOrderAlert } from 'hooks/useOrderAlert';
import { ChangeStatus } from 'components/ChangeStatus/ChangeStatus';
import { PREVIOUS_VISA_STATE } from '../Tabs/ApplicantMainInfo';
import {
  isUserEmployee,
  isUserClient,
  isUserOperator,
} from 'helpers/jwt_helper';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';

import { orderApplicantOrderFormDownloadRequest } from 'helpers/api-requests/admin';
import { showToastError } from 'helpers/utils/toast';
import { downloadDocument } from 'helpers/downloadDocument';

export const OrderApplicants = ({
  useFieldPreviousVisa,
  useFieldPreviousUssr,
  orderTypeFieldsSettings,
}) => {
  const formik = useFormikContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const isApplicantSetOneTime = useRef(false);
  const { openAlert } = useOrderAlert();

  const [applicantMaxQuantity, setApplicantMaxQuantity] = useState(1);
  useEffect(
    () =>
      setApplicantMaxQuantity(orderTypeFieldsSettings?.applicantMaxQuantity),
    [orderTypeFieldsSettings?.applicantMaxQuantity],
  );

  const [applicantCount, setApplicantCount] = useState(0);

  useEffect(() => {
    setApplicantCount(formik.values?.applicants?.length);
  }, [formik.values?.applicants]);

  const isDisabledFields = useMemo(
    () => !formik.values.orderDetail?.isEditable,
    [formik.values.orderDetail?.isEditable],
  );

  const [isOpenAddApplicant, setIsOpenAddApplicant] = useState(false);

  const getIsOpenAddApplicant = () => {
    let baseCond = null;

    if (!formik.values.orderType) {
      return false;
    }

    if (isUserEmployee) {
      baseCond = orderTypeFieldsSettings?.enableCountry
        ? !!formik.values.client.country
        : true;
      return baseCond && applicantCount < 1;
    }

    if (isUserClient) {
      baseCond = orderTypeFieldsSettings?.enableCountry
        ? !!formik.values.client.country
        : true;
      return (
        baseCond && applicantCount < applicantMaxQuantity && !isDisabledFields
      );
    }

    // operator & admin

    if (orderTypeFieldsSettings?.enableCountry) {
      baseCond =
        !!formik.values.client.country &&
        !!formik.values.client.clientType &&
        !!formik.values.client.client;
    } else {
      baseCond =
        formik.values.client.clientType && !!formik.values.client.client;
    }
    return applicantCount < applicantMaxQuantity && baseCond;
  };

  useEffect(() => {
    setIsOpenAddApplicant(getIsOpenAddApplicant);
  }, [
    orderTypeFieldsSettings?.enableCountry,
    applicantMaxQuantity,
    formik.values.client.country,
    formik.values.client.client,
    formik.values.clientType,
    applicantCount,
  ]);

  const handleChangeApplicantStatus = (status, index) => {
    formik.setFieldValue(`applicants[${index}].statusApplicant`, status.value);
  };

  const handleCustomerOrderFormDownload = async (customer, fileType) => {
    try {
      const response = await orderApplicantOrderFormDownloadRequest(
        id,
        customer.customerId,
        fileType,
        {
          responseType: 'blob',
        },
      );

      downloadDocument(
        response,
        `Order-${id}-${customer.applicantDetail.firstName} ${customer.applicantDetail.lastName}-${fileType}.pdf`,
      );
    } catch (err) {
      showToastError(err.message);
    }
  };

  useEffect(() => {
    if (
      !id &&
      formik.values.applicants.length === 0 &&
      isOpenAddApplicant &&
      !isApplicantSetOneTime.current
    ) {
      formik.setFieldValue('applicants', [
        {
          services: [],
          applicantDetail: { firstname: null },
          statusApplicant: 'draft',
          files: [],
          previusVisa: PREVIOUS_VISA_STATE[0].value,
        },
      ]);

      isApplicantSetOneTime.current = true;
    }
  }, [id, formik.values.applicants, isOpenAddApplicant]);

  const [addApplicantBlockTitle, setApplicantBlockTitle] = useState();

  useEffect(() => {
    setApplicantBlockTitle(
      orderTypeFieldsSettings?.enableContactPerson
        ? t('order.addContactPersonData')
        : formik.values?.applicants?.length === 1
        ? t('order.moreApplicant')
        : t('order.addApplicantData'),
    );
  }, [applicantMaxQuantity, formik.values?.applicants]);

  return (
    <FieldArray
      name="applicants"
      render={({ push, remove }) => (
        <>
          {formik.values?.applicants?.length > 0 &&
            formik.values.applicants.map((applicant, index) => (
              <ShadowCard
                id={`applicant-${index + 1}`}
                className="mb-4"
                bodyStyle={{ padding: '24px' }}
                style={{ scrollMargin: '-110px' }}
                key={applicant?.applicantDetail?.id ?? index}
              >
                <Accordion
                  title={
                    applicant.applicantDetail.firstName &&
                    applicant.applicantDetail.lastName
                      ? ` ${applicant.applicantDetail.firstName} ${applicant.applicantDetail.lastName}`
                      : `${t('common.applicant')} ${index + 1}`
                  }
                  extraComponentBody={
                    <>
                      <ApplicantDetail
                        applicant={applicant.applicantDetail}
                        index={index}
                        removeApplicant={() => {
                          remove(index);
                          setApplicantCount(applicantCount - 1);
                        }}
                        orderTypeFieldsSettings={orderTypeFieldsSettings}
                        setApplicantCount={setApplicantCount}
                      />
                      {isUserOperator && applicant.customerId && (
                        <div className="d-flex align-items-left">
                          <StyledButtonGray
                            className="m-2"
                            disabled={isDisabledFields}
                            onClick={event => {
                              event.stopPropagation();
                              handleCustomerOrderFormDownload(
                                applicant,
                                'info',
                              );
                            }}
                          >
                            <i className="bx bx-download" /> info
                          </StyledButtonGray>
                          <StyledButtonGray
                            className="m-2"
                            disabled={isDisabledFields}
                            onClick={event => {
                              event.stopPropagation();
                              handleCustomerOrderFormDownload(
                                applicant,
                                'sticker',
                              );
                            }}
                          >
                            <i className="bx bx-download" />
                            sticker
                          </StyledButtonGray>
                          <StyledButtonGray
                            className="m-2"
                            disabled={isDisabledFields}
                            onClick={event => {
                              event.stopPropagation();
                              handleCustomerOrderFormDownload(
                                applicant,
                                'envelope',
                              );
                            }}
                          >
                            <i className="bx bx-download" /> envelope
                          </StyledButtonGray>
                        </div>
                      )}
                    </>
                  }
                  extraTitle={
                    applicant.applicantDetail.id &&
                    id && (
                      <ChangeStatus
                        disabled={
                          isDisabledFields || isUserClient || isUserEmployee
                        }
                        className="ms-2 me-4"
                        value={applicant.statusApplicant}
                        onChange={status =>
                          handleChangeApplicantStatus(status, index)
                        }
                      />
                    )
                  }
                  extraComponentHeader={
                    <StyledButtonGray
                      className="d-flex align-items-center"
                      disabled={isDisabledFields}
                      onClick={event => {
                        event.stopPropagation();
                        remove(index);
                        setApplicantCount(applicantCount - 1);

                        openAlert({
                          message: t('order.deleteApplicantNotification'),
                          type: typeColor.danger,
                        });
                      }}
                    >
                      <i className="bx bxs-trash" />

                      {t('common.delete')}
                    </StyledButtonGray>
                  }
                >
                  {applicant.applicantDetail.firstName && (
                    <ApplicantBody
                      useFieldPreviousVisa={useFieldPreviousVisa}
                      useFieldPreviousUssr={useFieldPreviousUssr}
                      index={index}
                      orderTypeFieldsSettings={orderTypeFieldsSettings}
                    />
                  )}
                </Accordion>
              </ShadowCard>
            ))}

          {isOpenAddApplicant && (
            <ApplicantAdd
              handleCreateApplicant={() => {
                setApplicantCount(applicantCount + 1);
                push({
                  services: [],
                  applicantDetail: { firstname: null },
                  statusApplicant: 'draft',
                  files: [],
                  previusVisa: PREVIOUS_VISA_STATE[0].value,
                });
                openAlert({
                  message: 'order.addApplicantNotification',
                  type: typeColor.success,
                });
              }}
              title={addApplicantBlockTitle}
            />
          )}
        </>
      )}
    />
  );
};

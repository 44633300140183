import classNames from 'classnames';
import React from 'react';
import { Col, Label as ReactstrapLabel } from 'reactstrap';
import { Label } from 'components/Services/ServiceForm';
import { ViewCountry } from 'components/ViewCountry/ViewCountry';
import { formatDate } from 'helpers/formatDate';
import { DATE_FORMAT_WITH_SYMBOL } from 'common/data/constants';
import { useTranslation } from 'react-i18next';

export const TextField = ({ field, length, index }) => {
  const { t } = useTranslation();
  const getField = field => {
    switch (field.type) {
      case 'text':
        return field.value;
      case 'country':
        return <ViewCountry id={field.value} />;
      case 'date':
        return formatDate(field.value, DATE_FORMAT_WITH_SYMBOL);
      case 'array':
        return field.value;
      default:
        return null;
    }
  };

  return (
    <Col
      className={classNames('p-0 d-flex flex-wrap mb-0', [
        length !== undefined &&
          index !== undefined &&
          length !== index + 1 &&
          'mb-4',
      ])}
      xs={12}
    >
      <ReactstrapLabel className="text-muted fs-14" xs={3} lg={2}>
        {t(field.title)}
      </ReactstrapLabel>

      <Col xs={9} lg={10} className="d-flex align-items-center">
        <Label className="m-0">{getField(field)}</Label>
      </Col>
    </Col>
  );
};

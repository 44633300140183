export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_RESULT = 'VERIFY_RESULT';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'AUTH_API_ERROR';

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';

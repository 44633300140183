export const WIZARD_CHECKBOX = {
  0: false,
  1: true,
};

export const HIDE = 'HIDE';
export const REQUIRED = 'REQUIRED';
export const SHOW = 'SHOW';
export const DATE = 'DATE';

export const MOCK_REQUEST = { customer: 4, organization: 11 };

//country: 183

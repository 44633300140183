import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = ({ isLoading }) => {
  return isLoading ? (
    <Spinner color="primary" className="m-5" type="grow">
      Loading...
    </Spinner>
  ) : null;
};

export default Loader;

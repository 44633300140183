import { isUserOperator } from 'helpers/jwt_helper';
import { Badge, BlueLink } from 'components/Common';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { DATE_FORMAT_WITH_SYMBOL, ORDERS_STATUS } from 'common/data/constants';
import { formatDate } from 'helpers/formatDate';
import React from 'react';

export const OrdersCustomerRow = ({ customer }) => {
  const showDate = (date, icon) => {
    return date ? (
      <>
        <i className={`bx bx-${icon} me-1`} />
        {formatDate(date, DATE_FORMAT_WITH_SYMBOL)}
      </>
    ) : (
      <>-</>
    );
  };

  return (
    <>
      <td>
        <div>
          <span className="text-nowrap">
            {customer?.full_name} - {isUserOperator && customer?.visa_type}
            &nbsp;
            <Badge
              color={convertStatusToColor(customer?.status?.value)}
              text={ORDERS_STATUS[customer?.status?.value]}
            />
          </span>
        </div>
      </td>
      {isUserOperator && (
        <td>
          {customer?.other_countries.length > 0 && (
            <>
              {/*<Badge*/}
              {/*  color={typeColor.danger}*/}
              {/*  text={t('order.otherCountries.alert')}*/}
              {/*/>*/}
              <ul className="list-group list-group-flush">
                {customer?.other_countries.map(order => (
                  <li
                    key={order.id}
                    className="list-group-item bg-transparent text-nowrap p-0 py-1"
                  >
                    <BlueLink to={`/orders/${order.id}`} target="_blank">
                      {order.number} | {order.country} | {order.visa_type}
                      {(order.documents_to_consulate_estimated_at ||
                        order.documents_from_consulate_estimated_at) && <> (</>}
                      {order.documents_to_consulate_estimated_at && (
                        <>
                          {formatDate(
                            order.documents_to_consulate_estimated_at,
                            DATE_FORMAT_WITH_SYMBOL,
                          )}
                        </>
                      )}
                      {order.documents_from_consulate_estimated_at && (
                        <>
                          {' '}
                          -{' '}
                          {formatDate(
                            order.documents_from_consulate_estimated_at,
                            DATE_FORMAT_WITH_SYMBOL,
                          )}
                        </>
                      )}
                      {(order.documents_to_consulate_estimated_at ||
                        order.documents_from_consulate_estimated_at) && <>)</>}
                    </BlueLink>
                  </li>
                ))}
              </ul>
            </>
          )}
        </td>
      )}

      {isUserOperator && (
        <td className="text-nowrap">
          {showDate(
            customer?.documents_to_consulate_estimated_at,
            'arrow-from-left',
          )}
        </td>
      )}

      {isUserOperator && (
        <td className="text-nowrap">
          {showDate(
            customer?.documents_from_consulate_estimated_at,
            'arrow-from-right',
          )}
        </td>
      )}

      {isUserOperator && (
        <td className="text-nowrap">
          {showDate(customer?.gultig_from, 'calendar-check')}
        </td>
      )}

      {isUserOperator && (
        <td className="text-nowrap">
          {showDate(customer?.gultig_to, 'calendar-x')}
        </td>
      )}
    </>
  );
};

import { Col, Label, Row } from 'reactstrap';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { FieldArray, useFormikContext } from 'formik';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { DATE_FORMAT_SHORT } from 'common/data/constants';
import { StyledButtonGray, StyledField } from 'components/Common';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { StyledInput } from 'components/Common/FormikInput';
import { addDays } from 'date-fns';

const name = 'passports';

export const PassportsWithFormik = () => {
  const { t } = useTranslation();
  const { setFieldValue, values, errors } = useFormikContext();

  const passports = useMemo(() => {
    return values.passports;
  }, [values.passports]);

  return (
    <StyledField className="p-4 mb-4">
      <FieldArray
        validateOnChange
        name={name}
        render={({ push, remove }) => (
          <>
            {passports?.length > 0 &&
              passports.map((_, index) => (
                <React.Fragment key={index}>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <Label className="fs-5 m-0">Passport {index + 1}</Label>

                    {passports?.length !== 1 && (
                      <StyledButtonGray
                        className="d-flex align-items-center "
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <i className="bx bx-trash" /> {t('common.delete')}
                      </StyledButtonGray>
                    )}
                  </div>

                  <Row className="my-2 align-items-center mb-3">
                    <Col xs={6}>
                      <SelectCountry
                        isAvailableCitizenship
                        invalid={errors.passports?.[index]?.country}
                        className="w-100"
                        placeholder={t('common.countryPlaceholder')}
                        value={passports[index].country}
                        name={`${name}[${index}].country`}
                        onChange={option =>
                          setFieldValue(
                            `${name}[${index}].country`,
                            option?.value,
                          )
                        }
                      />
                    </Col>

                    <Col xs={6}>
                      <StyledInput
                        placeholder={t('client.number')}
                        value={passports[index].number}
                        name={`${name}.${index}.number`}
                        invalid={errors.passports?.[index]?.number}
                        onChange={option =>
                          setFieldValue(
                            `${name}.${index}.number`,
                            option.target.value,
                          )
                        }
                      />
                    </Col>

                    <Col xs={6} className="mt-3">
                      <StyledDatePicker
                        name={`${name}.${index}.issue_date`}
                        placeholderText={t('passport.issueNumber')}
                        invalid={errors.passports?.[index]?.issue_date}
                        selectsStart
                        value={passports[index].issue_date}
                        dateFormat={DATE_FORMAT_SHORT}
                        startDate={passports[index].issue_date}
                        endDate={passports[index].end_date}
                        selected={passports[index].issue_date}
                        onChange={option =>
                          setFieldValue(`${name}.${index}.issue_date`, option)
                        }
                        maxDate={new Date()}
                      />
                    </Col>

                    <Col xs={6} className="mt-3">
                      <StyledDatePicker
                        name={`${name}.${index}.end_date`}
                        placeholderText={t('passport.endDate')}
                        invalid={errors.passports?.[index]?.end_date}
                        selectsEnd
                        value={passports[index].end_date}
                        selected={passports[index].end_date}
                        dateFormat={DATE_FORMAT_SHORT}
                        onChange={option =>
                          setFieldValue(`${name}.${index}.end_date`, option)
                        }
                        startDate={passports[index].issue_date}
                        endDate={passports[index].end_date}
                        minDate={
                          addDays(passports[index].issue_date, 1) ?? null
                        }
                      />
                    </Col>
                  </Row>

                  {passports.length > 0 && passports.length !== index + 1 && (
                    <hr />
                  )}
                </React.Fragment>
              ))}

            <StyledUpload
              style={{ fontSize: '14px' }}
              className="d-flex flex-nowrap align-items-center"
              onClick={() =>
                push({
                  country: null,
                  number: '',
                  issue_date: null,
                  end_date: null,
                })
              }
            >
              <i className="bx bx-plus fs-4 me-2" /> {t('passport.addPassport')}
            </StyledUpload>
          </>
        )}
      />
    </StyledField>
  );
};

import { createReducer } from 'helpers/store/createReducer';
import { combineReducers } from 'redux';
import {
  deleteDocument,
  getDocument,
  getDocuments,
  uploadDocument,
} from './actions';

const initialState = {
  isLoading: false,
  count: null,
  documents: [],
  documentDetails: { document: null, isLoading: false },
};

const documents = createReducer(initialState, {
  [getDocuments]: state => ({
    ...state,
    isLoading: true,
  }),
  [getDocuments.success]: (state, { payload }) => ({
    ...state,
    documents: payload.documents,
    count: payload.count,
    isLoading: false,
  }),
  [getDocuments.failure]: state => ({
    ...state,
    isLoading: false,
  }),

  [getDocument]: state => ({
    ...state,
    documentDetails: { ...state.documentDetails, isLoading: true },
  }),
  [getDocument.success]: (state, { payload }) => ({
    ...state,
    documentDetails: {
      ...state.documentDetails,
      document: payload,
      isLoading: false,
    },
  }),
  [getDocument.failure]: state => ({
    ...state,
    documentDetails: { ...state.documentDetails, isLoading: false },
  }),

  [uploadDocument]: state => ({
    ...state,
    documentDetails: { ...state.documentDetails },
  }),
  [uploadDocument.success]: (state, { payload }) => ({
    ...state,
    documents: state.documents.map(document => {
      if (document.id === payload.id) {
        return payload;
      }

      return document;
    }),
    documentDetails: {
      ...state.documentDetails,
      document: payload,
    },
  }),
  [uploadDocument.failure]: state => ({
    ...state,
    documentDetails: { ...state.documentDetails },
  }),

  [deleteDocument]: state => ({ ...state }),

  [deleteDocument.success]: (state, action) => ({
    ...state,
    documents: state.documents.filter(
      document => document.id !== action.payload,
    ),
    // documentDetails: {
    //   ...state.documentDetails,
    //   document: null,
    // },
  }),

  [deleteDocument.failure]: (state, action) => ({
    ...state,
    documents: { ...state.documents, error: action.payload },
  }),
});

export default combineReducers({
  documents,
});

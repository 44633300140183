import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { postJwtVerify } from 'helpers/api-requests/auth';
import ErrorBoundary from 'components/ErrorBoundary';

const renderRoute = props => {
  const {
    routeComponentProps,
    isAuthProtected,
    component: Component,
    Layout,
    isAuth,
  } = props;

  if (isAuthProtected && !isAuth) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: routeComponentProps.location },
        }}
      />
    );
  }
  return (
    <Layout>
      <ErrorBoundary>
        <Component {...routeComponentProps} />
      </ErrorBoundary>
    </Layout>
  );
};

const AppRoute = ({ component, layout: Layout, isAuthProtected, ...rest }) => {
  const [auth, setAuth] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);

  useEffect(() => {
    const authUser = localStorage.getItem('authUser');
    const { access } = JSON.parse(authUser) ?? {};
    if (access) {
      postJwtVerify({ token: access })
        .then(result => {
          if (result && result.success) {
            setAuth(true);
            return;
          }

          throw Error;
        })
        .catch(() => {
          setAuth(false);
          localStorage.removeItem('authUser');
        })
        .then(() => setIsTokenValidated(true));
    } else {
      setIsTokenValidated(true);
    }
  }, []);

  if (!isTokenValidated) return <Layout />;

  return (
    <Route
      {...rest}
      render={routeComponentProps =>
        renderRoute({
          routeComponentProps,
          component,
          Layout,
          isAuthProtected,
          isAuth: auth,
        })
      }
    />
  );
};

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AppRoute;

import React, { useState } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { registerUser } from 'store/actions';

import { BlueLink, ShadowCard } from 'components/Common';
import NonAuthLayout from 'components/NonAuthLayout';
import { StepRegistrationForm } from './StepRegistrationForm';
import { REGISTRATION_VALIDATOR } from 'forms/validators';
import i18n from 'i18n';
import { convertIndividualToApi } from 'helpers/converters';

const initialValues = {
  email: '',
  username: '',
  password: '',
  verifyPassword: '',
  first_name: '',
  last_name: '',
  additional_name: '',
  termOfuse: false,
  phone: '',
  postalCode: '',
  addressLineFirst: '',
  city: '',
  state: '',
  country: null,
};

const registrationSteps = {
  names: {
    title: 'auth.signUp',
    nextStep: 'email',
    inputs: [
      {
        type: 'text',
        name: 'first_name',
        key: 1.1,
        label: 'auth.firstName',
        placeholder: 'auth.enterFirstName',
      },
      {
        type: 'text',
        name: 'last_name',
        key: 1.2,
        label: 'auth.lastName',
        placeholder: 'auth.enterLastName',
      },
      {
        type: 'text',
        name: 'additional_name',
        key: 1.3,
        label: 'auth.additionalName',
        placeholder: 'auth.enterAdditionalName',
      },
    ],
  },
  email: {
    title: 'auth.email',
    prevStep: 'names',
    nextStep: 'address',
    inputs: [
      {
        type: 'text',
        name: 'email',
        key: 2.1,
        label: 'auth.email',
        placeholder: 'auth.enterEmail',
      },
      {
        type: 'phone',
        name: 'phone',
        key: 2.2,
        label: 'client.phone',
        placeholder: 'client.phonePlaceholder',
      },
    ],
  },
  address: {
    title: 'delivery.address',
    prevStep: 'email',
    nextStep: 'password',
    inputs: [
      {
        type: 'text',
        name: 'addressLineFirst',
        key: 3.1,
        label: 'client.addressLineFirst',
        placeholder: 'client.addressLineFirstPlaceholder',
      },
      {
        type: 'text',
        name: 'postalCode',
        key: 3.3,
        label: 'client.zipCode',
        placeholder: 'client.zipCodePlaceholder',
        maxLength: 15,
      },
      {
        type: 'text',
        name: 'city',
        key: 3.4,
        label: 'client.city',
        placeholder: 'client.cityPlaceholder',
      },
      {
        type: 'text',
        name: 'state',
        key: 3.5,
        label: 'client.state',
        placeholder: 'client.statePlaceholder',
      },
      {
        type: 'countrySelect',
        name: 'country',
        key: 3.6,
        label: 'client.country',
        placeholder: 'client.countryPlaceholder',
      },
    ],
  },
  password: {
    prevStep: 'address',
    title: 'auth.createPassword',
    inputs: [
      {
        type: 'password',
        name: 'password',
        key: 4.1,
        label: 'auth.password',
        placeholder: 'auth.passwordPlaceholder',
      },
      {
        type: 'password',
        name: 'verifyPassword',
        key: 4.2,
        label: 'auth.repeatPassword',
        placeholder: 'auth.passwordPlaceholder',
      },
    ],
  },
  confirmEmail: {
    title: 'auth.accountCreated',
    extraComponents: (
      <div>
        {i18n.t('auth.lastStepRegistration')}.{' '}
        <BlueLink className="text-decoration-underline" to="/login">
          {i18n.t('auth.goToLogin')}
        </BlueLink>
      </div>
    ),
  },
};

const initialTouched = {
  email: false,
  country: false,
  username: false,
  password: false,
  verifyPassword: false,
  first_name: false,
  last_name: false,
  additional_name: false,
  termOfuse: false,
  phone: false,
  postalCode: false,
  addressLineFirst: false,
  city: false,
  state: false,
};

const currentValidationSchema = {
  names: REGISTRATION_VALIDATOR.names,
  email: REGISTRATION_VALIDATOR.email,
  address: REGISTRATION_VALIDATOR.address,
  password: REGISTRATION_VALIDATOR.password,
};

const Register = () => {
  const dispatch = useDispatch();
  const [registrationStep, setRegistrationStep] = useState('names');

  const handleSubmit = values => {
    formik.setErrors({});
    formik.setTouched(initialTouched);

    if (registrationStep === 'password') {
      dispatch(
        registerUser({
          user: convertIndividualToApi(values),
          nextStep: () => setRegistrationStep('confirmEmail'),
        }),
      );
      return;
    }

    setRegistrationStep(registrationSteps[registrationStep].nextStep);
  };

  const formik = useFormik({
    initialValues,
    initialTouched,
    validateOnChange: true,
    validationSchema: currentValidationSchema[registrationStep],
    onSubmit: handleSubmit,
  });

  return (
    <NonAuthLayout isScrollable>
      <FormikProvider value={formik}>
        <ShadowCard className="w-100" bodyStyle={{ padding: 48 }}>
          <StepRegistrationForm
            registrationSteps={registrationSteps}
            registrationStep={registrationStep}
            setRegistrationStep={setRegistrationStep}
          />
        </ShadowCard>
      </FormikProvider>
    </NonAuthLayout>
  );
};

export default Register;

import { StyledSelectCreatable } from 'components/Common';
import { useFormikContext } from 'formik';
import { getTags } from 'helpers/api-requests/admin';
import { isUserOperator } from 'helpers/jwt_helper';
import React, { useMemo, useState } from 'react';

const components = {
  DropdownIndicator: null,
};

export const EditTags = ({ tariff, index }) => {
  const formik = useFormikContext();

  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);

  const value = useMemo(
    () => tariff.tags?.map((tag, index) => ({ value: index, label: tag })),
    [tariff.tags],
  );

  const onChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'create-option':
        return formik.setFieldValue(
          `tariffs[${index}].tags`,
          newValue.map(tag => tag?.label),
        );
      case 'clear':
        return formik.setFieldValue(`tariffs[${index}].tags`, []);
      case 'remove-value':
        return formik.setFieldValue(
          `tariffs[${index}].tags`,
          tariff.tags?.filter(tag => tag !== actionMeta.removedValue?.label),
        );
      case 'select-option':
        return formik.setFieldValue(
          `tariffs[${index}].tags`,
          newValue.map(tag => tag?.label),
        );
      default:
        break;
    }
  };

  const handleChangeInput = string => {
    setSearch(string);

    if (!string.trim() || !isUserOperator) {
      return;
    }

    getTags({
      tariff: tariff?.tariff?.id ?? tariff?.tariff?.value,
      name: string,
    }).then(response => {
      if (response.success) {
        setOptions(
          response.data?.results?.map(tag => ({
            value: tag.name,
            label: tag.name,
          })),
        );
      }
    });
  };

  const filteredOptions = useMemo(() => {
    return options.filter(option => {
      return !tariff.tags?.includes(option.label);
    });
  }, [options, tariff.tags]);

  return (
    <StyledSelectCreatable
      isClearable
      isMulti
      menuPlacement="top"
      value={value}
      inputValue={search}
      onInputChange={handleChangeInput}
      options={filteredOptions}
      onChange={onChange}
      classNamePrefix="select2-selection"
      components={components}
    />
  );
};

import { CardTitle, ShadowCard } from 'components/Common';
import React from 'react';
import { ClientEditMainInfo } from './ClientEditMainInfo';

export const ClientMainInfoCard = () => {
  return (
    <ShadowCard id="information" bodyStyle={{ padding: '24px' }}>
      <CardTitle title="common.information" />

      <ClientEditMainInfo />
    </ShadowCard>
  );
};

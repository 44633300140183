import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { RESET_PASSWORD_VALIDATOR } from 'forms/validators';
import { resetPassword } from 'helpers/api-requests/auth';
import FormikInput from 'components/Common/FormikInput';
import { StyledTextHead } from './Login';
import { ShadowCard } from 'components/Common';
import { NON_AUTH_PADDING } from 'helpers/styles/constants';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const { push } = useHistory();

  const passwordReset = async values => {
    resetPassword({
      token,
      password: values.password,
    })
      .then(response => {
        showToastSuccess(response.message);
        push('/login');
      })
      .catch(error => {
        showToastError(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      verifyPassword: '',
    },
    validateOnChange: true,
    validationSchema: RESET_PASSWORD_VALIDATOR,
    onSubmit: passwordReset,
  });

  const { errors, values, handleChange, handleSubmit, touched, handleBlur } =
    formik;

  return (
    <ShadowCard
      className="m-4"
      bodyStyle={{ ...NON_AUTH_PADDING, maxWidth: 530 }}
    >
      <form onSubmit={handleSubmit}>
        <StyledTextHead className="mb-4">
          {t('auth.resetPassword')}
        </StyledTextHead>

        <FormikInput
          label={t('auth.password')}
          name="password"
          type="password"
          value={values.password}
          onBlur={handleBlur}
          handleChange={handleChange}
          placeholder={t('auth.passwordPlaceholder')}
          errors={errors.password && touched.password ? errors.password : null}
        />

        <FormikInput
          label={t('auth.repeatPassword')}
          name="verifyPassword"
          type="password"
          value={values.verifyPassword}
          onBlur={handleBlur}
          handleChange={handleChange}
          placeholder={t('auth.passwordPlaceholder')}
          errors={
            errors.verifyPassword && touched.verifyPassword
              ? errors.verifyPassword
              : null
          }
        />

        <Button
          color="primary"
          style={{ padding: '12px 0' }}
          block
          type="submit"
          className="waves"
        >
          {t('auth.resetPassword')}
        </Button>
      </form>
    </ShadowCard>
  );
};

export default ResetPasswordPage;

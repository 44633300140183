import React from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Layout } from 'components/Layout';
import { Label } from 'components/Services/ServiceForm';
import { CardTitle, ShadowCard } from 'components/Common';
import { StyledDatePicker } from 'components/CustomDatepicker';

import { AccountingCodesHeaderControl } from './AccountingCodesHeaderControl';

const AccountingCodes = () => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      periodStart: null,
      periodEnd: null,
    },
  });

  return (
    <FormikProvider value={formik}>
      <Layout
        title={t('common.export')}
        extraContent={<AccountingCodesHeaderControl />}
      >
        <Row>
          <Col xs={12}>
            <ShadowCard id="client" bodyStyle={{ padding: 24 }}>
              <CardTitle title="common.information" />

              <Col className="d-flex flex-wrap" xs={12}>
                <Label
                  className="fs-14 d-flex align-items-center"
                  xs={3}
                  lg={2}
                >
                  {t('reports.period')}
                </Label>

                <Col xs={9} lg={6} className="d-flex">
                  <Col xs={6} className="pe-2">
                    <StyledDatePicker
                      value={formik.values.periodStart}
                      selected={formik.values.periodStart}
                      startDate={formik.values.periodStart}
                      maxDate={formik.values.periodEnd}
                      endDate={formik.values.periodEnd}
                      selectsStart
                      invalid={formik.errors.periodStart}
                      isClearable={formik.values.periodStart}
                      onChange={date => {
                        formik.setFieldValue('periodStart', date);
                        formik.setFieldValue('periodEnd', null);
                      }}
                      placeholderText={t('reports.dateFrom')}
                    />
                  </Col>
                  <Col xs={6} className="ps-2">
                    <StyledDatePicker
                      value={formik.values.periodEnd}
                      selected={formik.values.periodEnd}
                      minDate={formik.values.periodStart}
                      startDate={formik.values.periodStart}
                      endDate={formik.values.periodEnd}
                      invalid={formik.errors.periodEnd}
                      isClearable={formik.values.periodEnd}
                      selectsEnd
                      onChange={date => {
                        formik.setFieldValue('periodEnd', date);
                      }}
                      placeholderText={t('reports.dateTo')}
                    />
                  </Col>
                </Col>
              </Col>
            </ShadowCard>
          </Col>
        </Row>
      </Layout>
    </FormikProvider>
  );
};

export default AccountingCodes;

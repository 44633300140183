import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle, ShadowCard } from 'components/Common';
import { ClientOptionsEdit } from './ClientOptionsEdit';

export const ClientOptionsCard = () => {
  const { t } = useTranslation();

  return (
    <ShadowCard id="options" className="mt-4" bodyStyle={{ padding: '24px' }}>
      <CardTitle title={t('client.options')} />

      <ClientOptionsEdit />
    </ShadowCard>
  );
};

export const GET_ORDERS_USER = 'GET_ORDERS_USER';
export const GET_ORDERS_USER_SUCCESS = 'GET_ORDERS_USER_SUCCESS';
export const GET_ORDERS_USER_FAIL = 'GET_ORDERS_USER_FAIL';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';

import React, { useEffect } from 'react';
import { BlueLink } from 'components/Common';
import { Layout } from 'components/Layout';
import { ShadowCard } from 'components/Common/ShadowCard';
import { StyledTable } from 'components/Table/StyledTable';
import classNames from 'classnames';
import { fetchOrderTypes } from 'store/orderTypes/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import Loader from 'components/Common/Loader';

const getOrderTypesState = state => state.orderTypes.orderTypes.orderTypes;

const OrderTypes = () => {
  const dispatch = useDispatch();
  const { orderTypes, isLoading } = useSelector(getOrderTypesState);

  useEffect(() => {
    dispatch(fetchOrderTypes());
  }, []);

  return (
    <Layout title="orderTypes.orderTypes">
      <ShadowCard>
        {isLoading ? (
          <Row className="justify-content-center">
            <Loader isLoading={isLoading} />
          </Row>
        ) : (
          <StyledTable>
            <thead>
              <tr>
                <th>EN</th>

                <th>DE</th>

                <th>Active</th>
              </tr>
            </thead>

            <tbody>
              {orderTypes.map(orderType => (
                <tr key={orderType.id}>
                  <td>
                    <BlueLink
                      className="fw-semibold"
                      style={{ fontSize: '14px' }}
                      to={`/orderTypes/${orderType?.id}`}
                    >
                      {orderType.translations['en']}
                    </BlueLink>
                  </td>
                  <td>{orderType.translations['en']}</td>
                  <td>
                    <i
                      className={classNames('fs-5', [
                        orderType.is_active
                          ? 'bx bx-check-circle text-success'
                          : 'bx bx-x-circle text-danger',
                      ])}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        )}
      </ShadowCard>
    </Layout>
  );
};

export default OrderTypes;

import { FIRST_PAGE, PAGES } from 'common/data/constants';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

const initialSearchData = {
  page: FIRST_PAGE,
  perPage: PAGES[0],
  search: '',
};

export const useTable = (additionalFields, count) => {
  const [searchState, setSearchState] = useState({
    ...initialSearchData,
    ...additionalFields,
  });

  const debouncedHandleChange = debounce(
    (field, value) =>
      setSearchState(prev => ({
        ...prev,
        page: FIRST_PAGE,
        [field]: value,
      })),
    300,
  );

  const handleChangeSearchParam = useCallback((field, value) => {
    if (field.includes('search')) {
      debouncedHandleChange(field, value);

      return;
    }

    if (field === 'dateRange') {
      const [start, end] = value;

      setSearchState(prev => ({
        ...prev,
        startDate: start,
        endDate: end,
        page: FIRST_PAGE,
      }));
      return;
    }

    if (field === 'estimatedToConsulateDateRange') {
      const [start, end] = value;

      setSearchState(prev => ({
        ...prev,
        estimatedToConsulateStartDate: start,
        estimatedToConsulateEndDate: end,
        page: FIRST_PAGE,
      }));
      return;
    }

    if (field === 'estimatedFromConsulateDateRange') {
      const [start, end] = value;

      setSearchState(prev => ({
        ...prev,
        estimatedFromConsulateStartDate: start,
        estimatedFromConsulateEndDate: end,
        page: FIRST_PAGE,
      }));
      return;
    }

    if (field === 'visaStartDateRange') {
      const [start, end] = value;

      setSearchState(prev => ({
        ...prev,
        visaStartStartDate: start,
        visaStartEndDate: end,
        page: FIRST_PAGE,
      }));
      return;
    }

    if (field === 'visaEndDateRange') {
      const [start, end] = value;

      setSearchState(prev => ({
        ...prev,
        visaEndStartDate: start,
        visaEndEndDate: end,
        page: FIRST_PAGE,
      }));
      return;
    }

    setSearchState(prev => ({
      ...prev,
      page: FIRST_PAGE,
      [field]: value,
    }));
  }, []);

  const showPagination = useMemo(
    () => searchState.perPage.value < count,
    [count, searchState.perPage],
  );

  return { searchState, handleChangeSearchParam, showPagination };
};

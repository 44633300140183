import { Button, Col } from 'reactstrap';
import styled from 'styled-components';

export const StyledField = styled.div`
  background: #f8f8fb;
  border-radius: 8px;
`;

export const StyledColBold = styled(Col)`
  font-weight: 500;
`;

export const StyledButtonGray = styled(Button)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray600}!important;
  background: ${({ theme }) => theme.colors.gray200}!important;
  border: none;

  i {
    font-size: 16px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gray200}!important;
    background: ${({ theme }) => theme.colors.gray600}!important;
  }
`;

import {
  createClient,
  getClientDetails,
  getClientFilter,
  getClients,
  getIndividual,
  getOrganization,
  updateIndividual,
  updateOrganization,
  deleteClientRequest,
  deleteClientApplicantRequest,
} from 'helpers/api-requests/admin';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
  createClientFail,
  createClientSuccess,
  getClientDetailFail,
  getClientDetailSuccess,
  getClientFilterFail,
  getClientFilterSuccess,
  getClientInfoFail,
  getClientInfoSuccess,
  getClientsFail,
  getClientsSuccess,
  updateClientFail,
  updateClientSuccess,
  deleteClientFail,
  deleteClientSuccess,
  deleteClientApplicantFail,
  deleteClientApplicantSuccess,
} from './actions';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import {
  CREATE_CLIENT,
  GET_CLIENTS,
  GET_CLIENT_DETAIL,
  GET_ORGANIZATION_INFO,
  GET_INDIVIDUAL_INFO,
  GET_CLIENT_FILTER,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  DELETE_CLIENT_APPLICANT,
} from './actionTypes';
import i18n from 'i18n';

function* fetchClients() {
  try {
    const response = yield call(getClients);
    yield put(getClientsSuccess(response.data.results));
  } catch (error) {
    showToastError(error.message);
    yield put(getClientsFail(error));
  }
}

function* createNewClient({ payload }) {
  try {
    const response = yield call(createClient, payload.values);

    if (response.success) {
      yield put(createClientSuccess(response.data));
      payload.push(
        `/clients/${response.data.id}/${response.data.client_type.client_type}`,
      );
    } else {
      showToastError(response.message);
      yield put(createClientFail(response.message));
    }
  } catch (error) {
    showToastError(error);
    yield put(createClientFail(error));
  }
}

function* updateClient({ payload }) {
  try {
    let response;
    if (payload.values.client_type === 'individual') {
      response = yield call(updateIndividual, {
        individualId: payload.values.id,
        data: payload.values,
      });
    } else {
      response = yield call(updateOrganization, {
        id: payload.values.id,
        organization: payload.values,
      });
    }

    if (response.success) {
      yield put(updateClientSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(updateClientFail(response.message));
    }
  } catch (error) {
    showToastError(error);
    yield put(updateClientFail(error));
  }
}

function* fetchClientDetails({ payload }) {
  try {
    const response = yield call(getClientDetails, payload);

    if (response.success) {
      yield put(getClientDetailSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(getClientDetailFail(response.message));
    }
  } catch (error) {
    showToastError(error);
    yield put(getClientDetailFail(error));
  }
}

function* fetchClientOrganizationInfo({ payload }) {
  try {
    const response = yield call(getOrganization, payload);
    if (response.success) {
      yield put(getClientInfoSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(getClientInfoFail(response.message));
    }
  } catch (error) {
    showToastError(error);
    yield put(getClientInfoFail(error));
  }
}

function* fetchClientIndividualInfo({ payload }) {
  try {
    const response = yield call(getIndividual, payload);
    if (response.success) {
      yield put(getClientInfoSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(getClientInfoFail(response.message));
    }
  } catch (error) {
    showToastError(error);
    yield put(getClientInfoFail(error));
  }
}

function* fetchFilterClient({ payload }) {
  try {
    const response = yield call(getClientFilter, payload);
    if (response.success) {
      yield put(getClientFilterSuccess(response.data));
    } else {
      showToastError(response.message);
      yield put(getClientFilterFail(response.message));
    }
  } catch (error) {
    showToastError(error);
    yield put(getClientFilterFail(error));
  }
}

function* deleteClientSaga({ payload }) {
  try {
    const response = yield call(deleteClientRequest, payload.client);
    if (response.success) {
      yield put(deleteClientSuccess(payload));
      showToastSuccess(i18n.t('client.deleted'));
      payload.handleCloseModal();
    } else {
      yield put(deleteClientFail(response));
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error.message);
    yield put(deleteClientFail(error));
  }
}

function* deleteClientApplicantSaga({ payload }) {
  try {
    const response = yield call(deleteClientApplicantRequest, payload.id);
    if (response.success) {
      yield put(deleteClientApplicantSuccess(payload));
      showToastSuccess(i18n.t('applicants.deleted'));
      payload.handleCloseModal();
    } else {
      yield put(deleteClientApplicantFail(response));
      showToastError(response.message);
    }
  } catch (error) {
    showToastError(error.message);
    yield put(deleteClientApplicantFail(error));
  }
}

function* clientsSaga() {
  yield takeEvery(DELETE_CLIENT, deleteClientSaga);
  yield takeEvery(DELETE_CLIENT_APPLICANT, deleteClientApplicantSaga);
  yield takeEvery(GET_CLIENTS, fetchClients);
  yield takeEvery(UPDATE_CLIENT, updateClient);
  yield takeEvery(CREATE_CLIENT, createNewClient);
  yield takeEvery(GET_CLIENT_DETAIL, fetchClientDetails);
  yield takeEvery(GET_ORGANIZATION_INFO, fetchClientOrganizationInfo);
  yield takeEvery(GET_INDIVIDUAL_INFO, fetchClientIndividualInfo);
  yield takeEvery(GET_CLIENT_FILTER, fetchFilterClient);
}

export default clientsSaga;

import { capitalizeFirstLetter } from 'helpers/converters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled, { css } from 'styled-components';
import { StyledButtonCancel } from './StyledButtonSave';

const StyledBodyText = styled.p`
  font-weight: 500;
`;

export const StyledModalCancel = styled(StyledButtonCancel)`
  padding: 8px 32px;
`;

export const StyledButtonDelete = styled.button`
  padding: 8px 32px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.danger};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.danger};
  }

  ${({ $type, theme }) =>
    $type === 'save' &&
    css`
      background-color: ${theme.colors.green500} !important;
    `}
`;

export const ModalConfirmDelete = ({
  textHeader,
  textBody,
  handleCloseModal,
  handleDelete,
  isOpenModal,
  children,
  typeButtonConfirm,
  buttonText = 'common.delete',
  cancelText = 'common.cancel',
  showCancelBtn = true,
  showBtn = true,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpenModal} toggle={handleCloseModal} centered>
      <ModalHeader toggle={handleCloseModal} className="border-0 pb-2 p-4">
        {capitalizeFirstLetter(t(textHeader))}
      </ModalHeader>

      <div className="w-100 ps-4 pe-4">
        <hr className="m-0" />
      </div>

      <ModalBody className="p-4">
        {
          <>
            <StyledBodyText className="display-4  mb-4 font-weight-bold">
              {t(textBody)}
            </StyledBodyText>

            {children && (
              <div className="d-flex flex-nowrap align-items-center mb-4">
                {children}
              </div>
            )}

            <div className="d-flex flex-nowrap align-items-center">
              {showBtn && (
                <StyledButtonDelete
                  $type={typeButtonConfirm}
                  onClick={handleDelete}
                  className="btn me-3 text-nowrap"
                >
                  {t(buttonText)}
                </StyledButtonDelete>
              )}

              {showCancelBtn && (
                <StyledModalCancel
                  className="text-nowrap"
                  onClick={handleCloseModal}
                >
                  {t(cancelText)}
                </StyledModalCancel>
              )}
            </div>
          </>
        }
      </ModalBody>
    </Modal>
  );
};

import { CardTitle, ShadowCard } from 'components/Common';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrderTypeDocumentsRequest,
  getOrderTypeCountryDocumentsRequest,
  getOrderTypeCountryVisaTypeDocumentsRequest,
} from 'helpers/api-requests/admin';
import { useParams } from 'react-router-dom';
import { Layout } from 'components/Layout';
import { FormikProvider, useFormik } from 'formik';
import { HeadControlButtons } from 'components/HeadControllButtons/HeadControllButtons';
import { ORDERTYPE_DOCUMENTS_BREADCRUMBS } from 'common/data/beadcrumbs';
import { Row } from 'reactstrap';
import { fetchOrderType } from 'store/orderTypes/actions';
import { convertOrderTypeFromApi } from 'helpers/converters';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import { OrderTypeEditDocuments } from 'components/OrderType/OrderTypeEditDocuments';
import Loader from 'components/Common/Loader';
import {
  updateOrderTypeDocumentsRequest,
  updateOrderTypeCountryDocumentsRequest,
  updateOrderTypeCountryVisaTypeDocumentsRequest,
} from 'helpers/api-requests/admin';
import { convertOrderTypeDocumentsToApi } from 'helpers/converters';

const getOrderTypeState = state => state.orderTypes.orderTypes.orderType;

const OrderTypeDocuments = () => {
  const { id, countryId, visaTypeId } = useParams();
  const dispatch = useDispatch();
  const { orderType, isLoading } = useSelector(getOrderTypeState);
  const [orderTypeData, setOrderTypeData] = useState();
  const [country, setCountry] = useState(undefined);
  const [visa, setVisa] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    id: id,
    country: countryId ?? null,
    visa: visaTypeId ?? null,
    documents: [],
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchOrderType(id));
    }
  }, []);

  const [attached, setAttached] = useState([]);
  const [available, setAvailable] = useState([]);

  const handleResponseDocs = documents => {
    setAttached(documents.attached);
    setAvailable(documents.available);
  };

  useEffect(() => {
    //  handle request accordingly to country, visa...
    if (visaTypeId) {
      getOrderTypeCountryVisaTypeDocumentsRequest(
        id,
        countryId,
        visaTypeId,
      ).then(response => {
        if (response.success) {
          handleResponseDocs(response.data.documents);
        } else {
          showToastError(response.message);
        }
      });
    } else if (countryId) {
      getOrderTypeCountryDocumentsRequest(id, countryId).then(response => {
        if (response.success) {
          handleResponseDocs(response.data.documents);
        } else {
          showToastError(response.message);
        }
      });
    } else {
      getOrderTypeDocumentsRequest(id).then(response => {
        if (response.success) {
          handleResponseDocs(response.data.documents);
        } else {
          showToastError(response.message);
        }
      });
    }
  }, []);

  const handleSubmit = values => {
    setIsSubmitting(true);
    const data = convertOrderTypeDocumentsToApi(values);

    if (visaTypeId) {
      updateOrderTypeCountryVisaTypeDocumentsRequest(
        id,
        countryId,
        visaTypeId,
        data,
      ).then(response => {
        if (response.success) {
          setIsSubmitting(false);
          handleResponseDocs(response.data.documents);
          showToastSuccess('Updated successfully.');
        } else {
          setIsSubmitting(false);
          showToastError(response.message);
        }
      });
    } else if (countryId) {
      updateOrderTypeCountryDocumentsRequest(id, countryId, data).then(
        response => {
          if (response.success) {
            setIsSubmitting(false);
            handleResponseDocs(response.data.documents);
            showToastSuccess('Updated successfully.');
          } else {
            showToastError(response.message);
          }
        },
      );
    } else {
      updateOrderTypeDocumentsRequest(id, data).then(response => {
        if (response.success) {
          setIsSubmitting(false);
          handleResponseDocs(response.data.documents);
          showToastSuccess('Updated successfully.');
        } else {
          showToastError(response.message);
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    enableReinitialize: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (orderType) {
      setOrderTypeData(convertOrderTypeFromApi(orderType));
    }
  }, [id, orderType]);

  useEffect(() => {
    if (countryId) {
      setCountry(orderType?.countries?.find(c => c.id == countryId));
    }
  }, [countryId, orderType?.countries]);

  useEffect(() => {
    if (visaTypeId) {
      const currentVisa = country?.visas?.find(v => v.id == visaTypeId);
      setVisa(currentVisa);
    }
  }, [country]);

  const getTitle = () =>
    visa
      ? `${orderTypeData?.translationEn} (${country.name}, ${visa.name})`
      : country
      ? `${orderTypeData?.translationEn} (${country.name})`
      : `${orderTypeData?.translationEn}`;

  return (
    <FormikProvider value={formik}>
      <Layout
        isScrollableHeader
        title={getTitle()}
        breadcrumbs={ORDERTYPE_DOCUMENTS_BREADCRUMBS}
        extraContent={
          <HeadControlButtons
            typeCancel="gray"
            handleSubmit={formik.handleSubmit}
            path={`/orderTypes/${id}`}
            isDisabledButton={
              // !formik.dirty ||
              isSubmitting || isLoading
            }
          />
        }
      >
        <Row>
          <ShadowCard
            id="documentsSettings"
            className="mt-4"
            bodyStyle={{ padding: '24px' }}
          >
            <CardTitle title="orderTypes.documents" />
            {isLoading && (
              <Row className="justify-content-center">
                <Loader isLoading={isLoading} />
              </Row>
            )}

            <OrderTypeEditDocuments
              orderTypeData={orderTypeData}
              attached={attached}
              available={available}
              country={country}
              visa={visa}
            />
          </ShadowCard>
        </Row>
      </Layout>
    </FormikProvider>
  );
};

export default OrderTypeDocuments;

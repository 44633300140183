import React, { useMemo, useEffect, useState } from 'react';
import { Select } from 'components/Common/Select';
import { isNil } from 'lodash';
import { convertVisaOptions } from './utils';
import i18n from 'i18n';
import { getUrgenciesRequest } from 'helpers/api-requests/admin';

export const VisaUrgencySelector = ({
  value,
  onChange,
  error,
  placeholder = 'Select processing time',
  isDisable,
}) => {
  const [urgencies, setUrgencies] = useState([]);

  useEffect(async () => {
    await getUrgenciesRequest()
      .then(response => {
        if (response.success) {
          setUrgencies(response.data.results);
        } else {
          showToastError(response.message);
        }
      })
      .catch(err => console.log(err));
  }, []);

  const options = useMemo(() => {
    return convertVisaOptions(urgencies);
  }, [i18n.language, urgencies]);

  const val = useMemo(() => {
    if (isNil(value)) {
      return value;
    }
    return options.find(item => item.value === value) ?? null;
  }, [options, value]);

  return (
    <Select
      className="w-100"
      placeholder={placeholder}
      options={options}
      value={val}
      isClearable
      isDisabled={isDisable}
      onChange={onChange}
      classNamePrefix="select2-selection"
      invalid={Boolean(error)}
    />
  );
};

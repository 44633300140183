import { CardTitle, StyledButtonGray, StyledColBold } from 'components/Common';
import {
  StyledLine,
  convertDataToDisplay,
} from 'components/Order/Tabs/ApplicantDetail';
import { ViewCountry } from 'components/ViewCountry/ViewCountry';
import { useMatchMedia } from 'hooks/useMatchMedia';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

export const AddressDisplay = ({
  handleDeleteAddress,
  handleOpenEditMode,
  address,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  return (
    <>
      <CardTitle
        isSmallText
        isShort
        className="flex-wrap"
        extraContent={
          <div className="d-flex justify-content-end w-100">
            <StyledButtonGray
              className="d-flex flex-nowrap me-2"
              onClick={handleDeleteAddress}
            >
              <i className="bx bxs-trash" />

              {!isMobile && t('common.delete')}
            </StyledButtonGray>

            <StyledButtonGray
              className="d-flex flex-nowrap"
              onClick={handleOpenEditMode}
            >
              <i className="bx bxs-edit-alt" />

              {!isMobile && t('common.edit')}
            </StyledButtonGray>
          </div>
        }
        title={address?.name}
      />

      <div className="d-flex">
        <Col className="p-0" xs={6}>
          <div className="d-flex flex-wrap p-0">
            <Col className="mb-3 fs-14" xs={12} lg={6}>
              {t('client.addressLineFirst')}
            </Col>

            <StyledColBold className="mb-3 fs-14 fw-semibold" xs={12} lg={6}>
              {convertDataToDisplay(address.addressLineFirst)}
            </StyledColBold>

            <Col className="mb-3 fs-14" xs={12} lg={6}>
              {t('client.city')}
            </Col>

            <StyledColBold className="mb-3 fs-14 fw-semibold" xs={12} lg={6}>
              {convertDataToDisplay(address.city)}
            </StyledColBold>
          </div>
        </Col>

        <StyledLine className="m-0 p-0" />

        <Col className="p-0 ps-3 " xs={6}>
          <div className="d-flex flex-wrap p-0">
            <Col className="mb-3 fs-14" lg={6}>
              {t('common.country')}
            </Col>

            <StyledColBold xs={12} className="mb-3 fs-14 fw-semibold" lg={6}>
              <ViewCountry id={address.country} />
            </StyledColBold>

            <Col className="mb-3 fs-14" xs={12} lg={6}>
              {t('client.zipCode')}
            </Col>

            <StyledColBold className="mb-3 fs-14 fw-semibold" xs={12} lg={6}>
              {convertDataToDisplay(address.postalCode)}
            </StyledColBold>

            <Col className="mb-3 fs-14" xs={12} lg={6}>
              {t('client.state')}
            </Col>

            <StyledColBold className="mb-3 fs-14 fw-semibold" xs={12} lg={6}>
              {convertDataToDisplay(address.state)}
            </StyledColBold>
          </div>
        </Col>
      </div>
    </>
  );
};

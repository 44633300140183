import React from 'react';

const NonAuth = ({ children }) => {
  return (
    <div
      style={{ width: '100vw', height: '100vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      {children}
    </div>
  );
};

export default NonAuth;

import { useState, useLayoutEffect } from 'react';

export const queries = [
  '(max-width: 766px)',
  '(min-width: 767px) and (max-width: 1199px)',
  '(min-width: 1200px)',
];

export const useMatchMedia = (defaultValues = queries) => {
  const initialValues = defaultValues;

  if (typeof window === 'undefined') return initialValues;

  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  const getValue = () => mediaQueryLists.map(mql => mql.matches);

  const [value, setValue] = useState(getValue);

  useLayoutEffect(() => {
    const handler = () => setValue(getValue);

    mediaQueryLists.forEach(mql => mql.addListener(handler));

    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, []);

  return ['isMobile', 'isTablet', 'isDesktop'].reduce(
    (acc, screen, index) => ({
      ...acc,
      [screen]: value[index],
    }),
    {},
  );
};

import { EMPTY_VALUE_WITH_LABEL } from 'common/data/constants';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from 'store/actions';
import { Select } from './Common/Select';

export const SelectRegion = ({ value, ...props }) => {
  const dispatch = useDispatch();

  const regions = useSelector(state => state.references.regions);

  const options = useMemo(() => {
    return regions.map(item => ({
      value: item.id,
      label: item.name,
    }));
  }, [regions]);

  const val = useMemo(() => {
    return options.find(item => item.value === value);
  }, [options, value]);

  useEffect(() => {
    dispatch(getRegions());
  }, []);

  return (
    <Select
      value={val}
      options={[EMPTY_VALUE_WITH_LABEL, ...options]}
      className="w-100"
      classNamePrefix="select2-selection"
      {...props}
    />
  );
};

import { del, get, post, put } from 'helpers/api_helper';
import authHeader from 'helpers/jwt-token-access/auth-token-header';
import { ADDRESS, CALCULATE } from 'helpers/url-helpers/common';

export const fetchAddresses = params =>
  get(ADDRESS, {
    headers: authHeader(),
    params,
  });
export const postAddress = address =>
  post(ADDRESS, address, {
    headers: authHeader(),
  });
export const putAddress = (id, newAddress) =>
  put(`${ADDRESS}${id}/`, newAddress, {
    headers: authHeader(),
  });
export const deleteAddressRequest = id =>
  del(`${ADDRESS}${id}/`, {
    headers: authHeader(),
  });
export const checkUsernameExists = username =>
  get(`references/check/username/?username=${username}`);

export const calculateDate = productIds => {
  return post(
    CALCULATE,
    { products: productIds },
    {
      headers: authHeader(),
    },
  );
};

import React, { useMemo } from 'react';
import { StyledDropdownToggle } from 'components/Common/PerPageSelect';
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap';

const Dropdown = ({
  options,
  value,
  handleSelect,
  invalid,
  disabled,
  titleText,
}) => {
  const currentValues = useMemo(
    () => options.filter(option => option?.label !== value?.label),
    [options, value],
  );

  return (
    <UncontrolledDropdown>
      <StyledDropdownToggle disabled={disabled} invalid={invalid}>
        {value.label ?? titleText}

        {currentValues.length > 0 && <i className="bx bx-chevron-down" />}
      </StyledDropdownToggle>

      {currentValues.length > 0 && (
        <DropdownMenu>
          {currentValues.map(option => (
            <DropdownItem
              onClick={() => handleSelect(option)}
              key={option.value}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};

export default Dropdown;

import {
  GET_ORDERS_USER,
  GET_ORDERS_USER_SUCCESS,
  GET_ORDERS_USER_FAIL,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
} from './actionTypes';

export const getOrdersUser = params => ({
  type: GET_ORDERS_USER,
  payload: params,
});

export const getOrdersUserSuccess = orders => ({
  type: GET_ORDERS_USER_SUCCESS,
  payload: orders,
});

export const getOrdersUserFail = error => ({
  type: GET_ORDERS_USER_FAIL,
  payload: error,
});

export const getProfile = params => ({
  type: GET_PROFILE,
  payload: params,
});

export const getProfileSuccess = chats => ({
  type: GET_PROFILE_SUCCESS,
  payload: chats,
});

export const getProfileFail = error => ({
  type: GET_PROFILE_FAIL,
  payload: error,
});

export const updateProfile = data => ({
  type: UPDATE_PROFILE,
  data,
});

export const changePassword = data => ({
  type: CHANGE_PASSWORD,
  data,
});

import * as React from 'react';
import { Col, Row } from 'reactstrap';
import WizardFields from './WizardFields';
import { useTranslation } from 'react-i18next';
import { Markdown } from './Markdown';

const WizardLayout = props => {
  const { fields, columns, rows } = props;
  const {
    i18n: { language },
  } = useTranslation();
  const columnsList = Array.from(Array(columns).keys());
  const rowList = Array.from(Array(rows).keys());

  return (
    <div>
      {rowList.map((r, rowIndex) => {
        return (
          <Row key={rowIndex}>
            {columnsList.map((c, columnIndex) => {
              const field = fields.filter(
                f => f.column === columnIndex + 1 && f.row === rowIndex + 1,
              )[0];
              return (
                <Col key={columnIndex} md={12 / columns} xs={12}>
                  {field?.field && <WizardFields {...field} />}
                  {field?.data && (
                    <Markdown markdown={field.data.translations[language]} />
                  )}
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};

export default WizardLayout;

import * as React from 'react';
import { Button, Row, Col, NavLink, NavItem, Progress } from 'reactstrap';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getWizardNextStep,
  setWizardInitial,
  setWizardMeta,
  getWizardStep,
} from '../../store/wizard/actions';
import WizardLayout from './WizardLayout';
import { ToastContainer } from 'react-toastify';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';
import { MOCK_REQUEST } from './constants';
import classnames from 'classnames';
import { getTitles } from './utils/text';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/Layout';
import { ShadowCard } from 'components/Common';

const WizardComponent = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const { form, wizard, meta, wizardStep, error } = useSelector(
    state => state.wizard,
  );

  const setMeta = useCallback(
    value => dispatch(setWizardMeta(value)),
    [dispatch],
  );
  const getWizardStepNext = useCallback(
    query => dispatch(getWizardNextStep(query)),
    [dispatch],
  );
  const getWizardStepFirst = useCallback(
    query => dispatch(getWizardStep(query)),
    [dispatch],
  );
  const resetWizard = useCallback(
    () => dispatch(setWizardInitial()),
    [dispatch],
  );

  const step = meta.step ?? 0;

  useEffect(() => {
    return () => {
      resetWizard();
    };
  }, []);

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const country = useMemo(() => {
    return queryParams.get('country');
  }, [location.search]);

  useEffect(() => {
    setMeta({ ...MOCK_REQUEST, country, step: 0 });
    getWizardStepFirst({ ...MOCK_REQUEST, country, step: 1 });
  }, []);

  useEffect(() => {
    if (error) {
      showToastError(error.message);
    }
  }, [error]);

  const onCloseSuccess = () => {
    history.push('/orders');
  };

  useEffect(() => {
    if (wizardStep.success && wizardStep.status === 1) {
      setMeta({ ...meta, step: step + 1 });
    }
    if (wizardStep.success && wizardStep.status === 2) {
      showToastSuccess('Order successfully created', onCloseSuccess);
    }
  }, [wizardStep]);

  const next = async () => {
    await getWizardStepNext({
      ...MOCK_REQUEST,
      country,
      step: step + 1,
      data: form[step] ?? {},
    });
  };

  const back = async () => {
    setMeta({ ...meta, step: step - 1 });
  };

  const steps = useMemo(() => {
    return wizard?.wizard?.steps ?? [];
  }, [wizard]);

  const titles = getTitles(steps, language);

  return (
    <Layout>
      <ShadowCard>
        <div id="progrss-wizard" className="twitter-bs-wizard">
          <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
            {steps.map((s, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: step === index,
                    })}
                  >
                    <span className="step-number me-2">{index + 1}</span>
                    {titles[index]}
                  </NavLink>
                </NavItem>
              );
            })}
          </ul>
        </div>
        <div id="bar">
          <Progress
            color="primary"
            striped
            animated
            value={((step + 1) / steps.length) * 100}
          />
          <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
        </div>
        {steps[step]?.layouts.map((layout, index) => {
          return <WizardLayout {...layout} key={index} />;
        })}
        <Row>
          <Col md={12}>
            {step > 0 && (
              <Button type="button" color="danger" size="lg" onClick={back}>
                Back
              </Button>
            )}
            {step === steps.length - 1 ? (
              <Button
                type="button"
                color="primary"
                size="lg"
                className="ms-2"
                onClick={next}
              >
                Submit
              </Button>
            ) : (
              <Button
                type="button"
                color="primary"
                size="lg"
                className="ms-2"
                onClick={next}
              >
                Next
              </Button>
            )}
          </Col>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ShadowCard>
    </Layout>
  );
};

export default memo(WizardComponent);

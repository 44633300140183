import React, { useEffect, useMemo } from 'react';
import { Select } from 'components/Common/Select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTariffs } from 'store/tariffs/actions';
import styled, { css } from 'styled-components';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const StyledDropdownToggle = styled(DropdownToggle)`
  background: #495057;
  border: 0;
  padding: 8px 12px;
  color: white;
  max-height: 40px;
  min-height: 40px;

  ${({ $type, theme }) =>
    $type === 'gray' &&
    css`
      background-color: ${theme.colors.gray200};
      color: ${theme.colors.mainText};
    `}
`;

const StyledDropDownMenu = styled(DropdownMenu)`
  border-radius: 8px;
  box-shadow: -4px 8px 12px rgb(74 87 102 / 30%);
`;

const StyledState = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

const StyledStatus = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const SelectTariff = ({
  value,
  handleChange,
  error,
  className,
  placeholder,
  name,
  excludedId,
  type = 'select',
  ...props
}) => {
  const { tariffs } = useSelector(state => state.tariffs.tariffs.tariffs);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchTariffs());
  }, []);

  const tariffsOption = useMemo(() => {
    const preparedTariffs = tariffs?.map(tariff => ({
      value: tariff.id,
      label: tariff.name,
      item: { value: tariff.id, label: tariff.name },
    }));

    if (!excludedId) {
      return preparedTariffs;
    }

    return preparedTariffs?.filter(({ value }) => value !== Number(excludedId));
  }, [tariffs, excludedId]);

  return type === 'select' ? (
    <Select
      name={name}
      classNamePrefix="select2-selection"
      value={tariffsOption.find(option => option.value === value)}
      options={tariffsOption}
      onChange={handleChange}
      invalid={error}
      placeholder={t('client.tariffPlaceholder')}
      {...props}
    >
      {t('client.tariffPlaceholder')}
    </Select>
  ) : (
    <UncontrolledDropdown className={className}>
      <StyledDropdownToggle
        $type="gray"
        className="d-flex flex-row align-items-center justify-content-center"
      >
        <StyledStatus>
          <StyledState className="me-1">{t(placeholder)}</StyledState>
          {tariffsOption.find(option => option.value === value)?.label}
        </StyledStatus>
      </StyledDropdownToggle>

      <StyledDropDownMenu>
        {tariffsOption
          .filter(status => status?.label !== value?.label)
          .map(status => (
            <DropdownItem
              onClick={() => handleChange(status)}
              key={status.label}
            >
              {t(status.label)}
            </DropdownItem>
          ))}
      </StyledDropDownMenu>
    </UncontrolledDropdown>
  );
};

export default SelectTariff;

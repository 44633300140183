import React, { useEffect, useState, useRef } from 'react';
import { useFormik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Resizer from 'react-image-file-resizer';

import { Col, FormGroup, Label, Row } from 'reactstrap';
import { StyledInput } from 'components/Common/FormikInput';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';

import {
  APPLICANT_ORDER_FORM_VALIDATOR,
  APPLICANT_WITH_BIRTHDATE_ORDER_FORM_VALIDATOR,
  APPLICANT_WITH_PASSPORT_ORDER_FORM_VALIDATOR,
} from 'forms/validators';
import { SelectApplicant } from '../OrderEditor/SelectApplicant';
import {
  operatorOrganizationAddApplicants,
  operatorUserAddApplicants,
  changeApplicant,
  getApplicant,
} from 'helpers/api-requests/admin';
import {
  convertApplicantFromApi,
  convertApplicantBasicDataToApi,
  convertApplicantWithBirthdateToApi,
  convertApplicantToApi,
  convertDateToAPI,
  convertPassportFromApi,
} from 'helpers/converters';
import { Select } from 'components/Common/Select';
import { DATE_FORMAT_WITH_SYMBOL, USER_SEX } from 'common/data/constants';
import { StyledPhoneInput, Loader } from 'components/Common';
import {
  StyledButtonCancel,
  StyledButtonSave,
} from 'components/Common/StyledButtonSave';
import { showToastError } from 'helpers/utils/toast';
import { useOrderAlert } from 'hooks/useOrderAlert';
import { typeColor } from 'helpers/styles/typeColor';
import { addDays, addMonths } from 'date-fns';
import { formatDate } from 'helpers/formatDate';
import {
  isUserEmployee,
  isUserIndividual,
  isUserClient,
  isUserManager,
} from 'helpers/jwt_helper';
import {
  createApplicant,
  fetchApplicant,
  putApplicant,
} from 'helpers/api-requests/manager';
import { fetchEmployee, putEmployee } from 'helpers/api-requests/employee';
import { getProfileState } from 'components/CommonForBoth/TopbarDropdown/ProfileMenu';
import { useSelector } from 'react-redux';
import { StyledUpload } from './Tabs/ApplicantDocuments';
import {
  passportScannerRequest,
  passportScannerCheckRequest,
  extractPassportImgFromPdfRequest,
} from 'helpers/api-requests/admin';

const dateFeatureSixMouth = addMonths(new Date(), 6);

export const getExtenstion = filename => filename.split('.').pop();

async function converBase64toBlob(content, contentType) {
  contentType = contentType || '';
  var sliceSize = 512;
  var byteCharacters = window.atob(content); //method which converts base64 to binary
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {
    type: contentType,
  }); //statement which creates the blob
  return blob;
}

const extractImgFromPdf = async file => {
  try {
    const response = await extractPassportImgFromPdfRequest({ file: file });
    if (response.success) {
      return response.data.base64;
    } else {
      showToastError(response.message);
    }
  } catch (error) {
    showToastError('Error when opening a file');
  }
};

export const ApplicantEditForm = ({
  applicant,
  handleClose,
  index,
  removeApplicant,
  orderTypeFieldsSettings,
}) => {
  const formikOrder = useFormikContext();
  const profile = useSelector(getProfileState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openAlert } = useOrderAlert();
  const { t } = useTranslation();
  const [applicantInitEmail, setApplicantInitEmail] = useState();

  const applicantToApiConverter = values => {
    if (orderTypeFieldsSettings?.enableApplicantPassport) {
      return convertApplicantToApi(values);
    } else if (orderTypeFieldsSettings?.enableApplicantBirthdate) {
      return convertApplicantWithBirthdateToApi(values);
    } else {
      return convertApplicantBasicDataToApi(values);
    }
  };

  const setManagerEmail = () =>
    formikApplicant.setFieldValue('email', profile?.email);

  const setApplicantEmail = () =>
    formikApplicant.setFieldValue('email', applicantInitEmail);

  const handleSave = async values => {
    setIsSubmitting(true);
    const applicantsId = formikOrder.values.applicants.map(
      applicant => applicant?.applicantDetail?.id,
    );

    if (applicant.id !== values.id && applicantsId.includes(values.id)) {
      openAlert({
        message: `order.existingApplicantNotification`,
        type: typeColor.danger,
      });

      return;
    }

    const applicantCard = document.getElementById(`applicant-${index + 1}`);

    try {
      if (!values.id) {
        let response;

        if (isUserClient) {
          response = await createApplicant(applicantToApiConverter(values));
        }

        if (!isUserClient) {
          response =
            formikOrder.values.client.clientType === 'legal'
              ? await operatorOrganizationAddApplicants(
                  formikOrder.values.client.client,
                  applicantToApiConverter(values),
                )
              : await operatorUserAddApplicants(
                  formikOrder.values.client.client,
                  applicantToApiConverter(values),
                );
        }

        if (response.success) {
          formikOrder.setFieldValue(`applicants[${index}].applicantDetail`, {
            ...values,
            citizenship: values.passport?.nationality ?? null,
            requireResidency:
              response.data.passports[0]?.country.visa_order_require_residency,
            additionalName: values.additionalName ?? undefined,
            id: response.data.id,
          });

          applicantCard.scrollIntoView(false);
          handleClose();
        } else {
          openAlert({
            message: `${t('passport.notValidDate')} "${t(
              'passport.minimumDate',
            )} ${formatDate(
              addDays(dateFeatureSixMouth, 1),
              DATE_FORMAT_WITH_SYMBOL,
            )}"`,
            type: typeColor.danger,
          });
        }

        return;
      }

      let currentRequest = changeApplicant(
        applicantToApiConverter(values),
        values.id,
      );

      if (isUserClient) {
        currentRequest = putApplicant({
          applicant: applicantToApiConverter(values),
          id: values.id,
        });
      }

      if (isUserEmployee) {
        currentRequest = putEmployee({
          applicant: applicantToApiConverter(values),
        });
      }

      // handle permissions
      currentRequest
        .then(response => {
          if (response.success) {
            if (orderTypeFieldsSettings.enableApplicantPassport) {
              if (
                !response.data.passports.find(
                  passport =>
                    passport.number === values.passport.number &&
                    passport.end_date ===
                      convertDateToAPI(values.passport.expirationDate) &&
                    passport.issue_date ===
                      convertDateToAPI(values.passport.startDate),
                )?.validity?.is_valid
              ) {
                openAlert({
                  message: `${t('passport.notValidDate')} "${t(
                    'passport.minimumDate',
                  )} ${formatDate(
                    addDays(dateFeatureSixMouth, 1),
                    DATE_FORMAT_WITH_SYMBOL,
                  )}"`,
                  type: typeColor.danger,
                });
                return;
              }
            }

            formikOrder.setFieldValue(`applicants[${index}].applicantDetail`, {
              ...values,
              citizenship: values.passport.nationality,
              // visaOrderRequireResidency:
              // requireResidency:
              //   passport?.country?.visa_order_require_residency,
              additionalName: values.additionalName ?? undefined,
            });
            applicantCard.scrollIntoView(false);
            handleClose();
            return;
          }
          showToastError(response.message);
        })
        .catch(err =>
          showToastError(err ?? 'Error while editing the applicant'),
        );
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formikApplicant = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: applicant,
    validationSchema: orderTypeFieldsSettings?.enableApplicantPassport
      ? APPLICANT_WITH_PASSPORT_ORDER_FORM_VALIDATOR
      : orderTypeFieldsSettings?.enableApplicantBirthdate
      ? APPLICANT_WITH_BIRTHDATE_ORDER_FORM_VALIDATOR
      : APPLICANT_ORDER_FORM_VALIDATOR,
    onSubmit: handleSave,
  });

  useEffect(() => {
    if (isUserIndividual && profile?.email) {
      formikApplicant.setFieldValue('email', profile.email);
    }
  }, []);

  const getApplicantWithPassports = () => {
    const currentRequest = isUserClient
      ? fetchApplicant(applicant.id)
      : getApplicant(applicant.id);

    currentRequest
      .then(response => {
        if (response.success) {
          const passportFormApi = convertPassportFromApi(
            response.data.passports.find(
              passport => passport.number === applicant.passport.number,
            ),
          );

          formikApplicant.resetForm({
            values: {
              ...applicant,
              citizenship: passportFormApi.nationality,
              // requireResidency: passportFormApi.visa_order_require_residency,
              passport: passportFormApi,
              passports: response.data.passports,
            },
          });
        } else {
          showToastError(response.message);
        }
      })
      .catch(err => console.log(err));
  };

  const getApplicantEmployee = () => {
    fetchEmployee()
      .then(response => {
        if (response.success) {
          const passportFormApi = response.data.passport
            ? convertPassportFromApi(
                response.data.passports.find(
                  passport => passport.number === applicant?.passport?.number,
                ),
              )
            : {};

          formikApplicant.resetForm({
            values: {
              ...applicant,
              citizenship: passportFormApi.nationality,
              // requireResidency: passportFormApi.visa_order_require_residency,
              passport: passportFormApi,
              passports: response.data.passports,
            },
          });
        } else {
          showToastError(response.message);
        }
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (isUserEmployee && applicant.id) {
      getApplicantEmployee();
      return;
    }

    if (applicant.id) {
      getApplicantWithPassports();
    }
  }, []);

  const handleSetApplicant = applicantFromApi => {
    setApplicantInitEmail();
    if (applicantFromApi) {
      formikApplicant.resetForm({
        values: convertApplicantFromApi(applicantFromApi),
      });
      formikApplicant.setFieldValue('name', 'New Name');
    } else {
      formikApplicant.setFieldValue('firstName', null);
    }
  };

  useEffect(() => {
    if (
      formikApplicant.values?.id &&
      formikApplicant.values?.email &&
      !applicantInitEmail
    ) {
      setApplicantInitEmail(formikApplicant.values?.email);
    }
  }, [formikApplicant.values]);

  const isDisabledFields = isUserEmployee && !formikApplicant.values.id;
  const [isLoading, setIsLoading] = useState(false);
  const [showSubmitScanBtn, setShowSubmitScanBtn] = useState(false);

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        600,
        400,
        'JPEG',
        90,
        0,
        uri => {
          resolve(uri);
        },
        'base64',
      );
    });

  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const handleScanPassport = async blob => {
    setIsLoading(true);
    let result;

    let imageCrop = new File([blob], 'imagename.jpg');
    // formData.append('file', imageCrop, 'upload.jpg');
    imageCrop = await resizeFile(imageCrop);
    const file = DataURIToBlob(imageCrop);

    const formData = new FormData();
    formData.append('file', file, 'upload.jpg');

    passportScannerRequest(formData)
      .then(response => {
        if (response.success) {
          const taskId = response.data.task;
          let interValId;

          const passportScannerCheck = taskId => {
            passportScannerCheckRequest(taskId).then(response => {
              if (response?.success) {
                if (response.data.completed) {
                  result = response.data.result;

                  if (!response.data?.success) {
                    showToastError(response.data?.error);
                  } else {
                    formikApplicant.setFieldValue(
                      'firstName',
                      result?.first_name,
                    );
                    formikApplicant.setFieldValue('lastName', result.last_name);

                    formikApplicant.setFieldValue(
                      'birthday',
                      new Date(result?.date_of_birth),
                    );
                    const sex_val = USER_SEX.find(
                      ({ value }) => value === result?.sex,
                    );
                    formikApplicant.setFieldValue('sex', sex_val.value);
                    formikApplicant.setFieldValue(
                      'passport.nationality',
                      result?.country?.id,
                    );
                    formikApplicant.setFieldValue(
                      'passport.number',
                      result?.number,
                    );
                    formikApplicant.setFieldValue(
                      'passport.expirationDate',
                      new Date(result?.expiration_date),
                    );
                  }

                  clearInterval(interValId);
                  setIsLoading(false);
                  // return;
                }
              } else {
                showToastError(response.message);
              }
            });
          };

          if (!interValId) {
            interValId = setInterval(passportScannerCheck, 1000, taskId);
            passportScannerCheck(taskId);
            // setTimeout(() => {
            //   clearInterval(interValId);
            //   setIsLoading(false);

            //   !result && showToastError(t('passport.scanServiceNotAvailable'));
            // }, 15000);
          }
        } else {
          showToastError(t('passport.scanServiceNotAvailable'));
        }
      })
      .catch(err => showToastError(err));
  };

  // Cropper

  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  // const hiddenAnchorRef = useRef(null);
  // const blobUrlRef = useRef('');
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState();

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  };

  const onSelectFile = async e => {
    // hide canvas
    setScale(1);
    setRotate(0);
    setAspect();
    setCompletedCrop();
    setShowSubmitScanBtn(true);

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];
      const fileExtension = getExtenstion(file.name).toLowerCase();

      if (fileExtension === 'pdf') {
        const base64Img = await extractImgFromPdf(file);
        if (!base64Img) {
          return;
        }

        file = await converBase64toBlob(base64Img, 'image/png');
      }

      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();

      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      );
      reader.readAsDataURL(file);
    }
  };

  const onImageLoad = e => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 0.5));
  };

  const onSubmitScan = async () => {
    // we keep imgSrc inorder to show image box all the time, and hide canvas
    // setImgSrc();
    const image = imgRef.current;

    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size

    const blob = await offscreen.convertToBlob({
      type: 'image/jpg',
      quality: 0,
    });
    handleScanPassport(blob);

    // show cropped image only - not completed. issue with keeping scale & rotation

    // const reader = new FileReader();

    // reader.addEventListener('load', () =>
    //   setImgSrc(reader.result?.toString() || ''),
    // );
    // reader.readAsDataURL(blob);
  };

  const TO_RADIANS = Math.PI / 180;

  const canvasPreview = (image, canvas, crop, scale = 1, rotate = 0) => {
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    ctx.restore();
  };

  const useDebounceEffect = (fn, waitTime, deps) => {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps);
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps);
  };

  useDebounceEffect(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <div className="w-100 ">
      {orderTypeFieldsSettings?.enableApplicantPassport && (
        <Col xs={5} xxl={5} className="p-0" style={{ float: 'right' }}>
          {/* Scanner block */}
          <center>
            <div className="Crop-Controls">
              {t('passport.scanPassportUpload')}:{' '}
              <input
                type="file"
                accept="image/*,application/pdf"
                onChange={onSelectFile}
              />
            </div>

            <br />
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => {
                  if (percentCrop?.width && percentCrop?.height) {
                    setCrop(percentCrop);
                  } else {
                    // prevent generating one pixel frame by mistake (mouse left btn one click)
                    setCrop({
                      unit: percentCrop?.unit,
                      x: 0,
                      y: 0,
                      width: 100,
                      height: 100,
                    });
                  }
                  setShowSubmitScanBtn(true);
                }}
                onComplete={c => setCompletedCrop(c)}
                aspect={aspect}
                minWidth={200}
                minHeight={50}
                // circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}

            {!!completedCrop && (
              <>
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                      display: 'none',
                    }}
                  />
                </div>

                {!!showSubmitScanBtn && (
                  <div>
                    <StyledUpload onClick={onSubmitScan} disabled={isLoading}>
                      {/* <UploadControl onChange={onSubmitCropImg} accept="image/*"> */}
                      {t('Submit')}
                      {/* </UploadControl> */}
                    </StyledUpload>
                  </div>
                )}
              </>
            )}
          </center>

          {isLoading && (
            <Row className="justify-content-center">
              <Loader isLoading={isLoading} />
            </Row>
          )}
        </Col>
      )}

      <Col xs={7} xxl={7} className="p-0">
        <FormGroup row className="align-items-center">
          <Col sm={12} xxl={2}>
            <Label className="m-0">{t('auth.firstName')}</Label>
          </Col>

          <Col sm={12} xxl={8}>
            <SelectApplicant
              className="w-100"
              handleSelectApplicant={handleSetApplicant}
              clientType={formikOrder.values.client?.clientType}
              clientId={formikOrder.values.client?.client}
              value={formikApplicant.values?.firstName}
              handleChangeTextInput={event => {
                if (!event) {
                  formikApplicant.setFieldValue('firstName', null);
                }

                formikApplicant.setFieldValue('firstName', event);
              }}
              selectedApplicants={formikOrder.values?.applicants}
              placeholder={t('order.applicantPlaceholder')}
              invalid={formikApplicant.errors.firstName}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="align-items-center">
          <Col sm={12} xxl={2}>
            <Label className="m-0">{t('auth.lastName')}</Label>
          </Col>

          <Col sm={12} xxl={8}>
            <StyledInput
              name="lastName"
              onChange={formikApplicant.handleChange}
              className="p-2 ps-3"
              value={formikApplicant.values?.lastName}
              placeholder={t('auth.enterLastName')}
              disabled={isDisabledFields}
              invalid={formikApplicant.errors.lastName}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="align-items-center">
          <Col sm={12} xxl={2}>
            <Label className="m-0">{t('auth.additionalName')}</Label>
          </Col>

          <Col sm={12} xxl={8}>
            <StyledInput
              className="p-2 ps-3"
              name="additionalName"
              value={formikApplicant.values?.additionalName}
              disabled={isDisabledFields}
              onChange={formikApplicant.handleChange}
              placeholder={t('auth.enterAdditionalName')}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="align-items-center">
          <Col sm={12} xxl={2}>
            <Label className="m-0">{t('common.gender')}</Label>
          </Col>

          <Col sm={12} xxl={8}>
            <Select
              placeholder={t('common.genderPlaceholder')}
              isDisabled={isDisabledFields}
              classNamePrefix="select2-selection"
              value={
                USER_SEX.find(
                  ({ value }) => value === formikApplicant.values.sex,
                ) ?? null
              }
              onChange={option =>
                formikApplicant.setFieldValue(
                  'sex',
                  option ? option.value : null,
                )
              }
              options={USER_SEX}
              invalid={formikApplicant.errors.sex}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="align-items-center">
          <Col sm={12} xxl={2}>
            <Label className="m-0">{t('common.phone')}</Label>
          </Col>

          <Col sm={12} xxl={8}>
            <StyledPhoneInput
              disabled={isDisabledFields}
              onChange={phone => formikApplicant.setFieldValue('phone', phone)}
              value={formikApplicant.values?.phone}
              placeholder={t('common.phonePlaceholder')}
              errors={formikApplicant.errors.phone}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="align-items-center">
          <Col sm={12} xxl={2}>
            <Label className="m-0">{t('auth.email')}</Label>
          </Col>

          <Col sm={12} xxl={8}>
            <StyledInput
              name="email"
              onChange={formikApplicant.handleChange}
              className="p-2 ps-3"
              disabled={
                isDisabledFields ||
                isUserIndividual ||
                isUserEmployee ||
                (isUserManager &&
                  formikApplicant.values?.id &&
                  formikApplicant.values?.email)
              }
              value={formikApplicant.values?.email}
              placeholder={t('auth.enterEmail')}
              invalid={formikApplicant.errors.email}
            />
          </Col>
          {isUserManager &&
            profile?.email &&
            (formikApplicant.values?.email !== profile?.email ? (
              <p
                onClick={setManagerEmail}
                className="me-4"
                style={{
                  'font-size': '.8rem',
                  color: 'blue',
                  cursor: 'pointer',
                  'padding-top': '10px',
                }}
              >
                <i className="bx bx-paste"></i>&nbsp;{profile?.email}
              </p>
            ) : applicantInitEmail ? (
              <p
                onClick={setApplicantEmail}
                className="me-4"
                style={{
                  'font-size': '.8rem',
                  color: 'blue',
                  cursor: 'pointer',
                  'padding-top': '10px',
                }}
              >
                <i className="bx bx-paste"></i>&nbsp;{applicantInitEmail}
              </p>
            ) : (
              ''
            ))}
        </FormGroup>

        {orderTypeFieldsSettings?.enableApplicantBirthdate && (
          <FormGroup row className="align-items-center">
            <Col sm={12} xxl={2}>
              <Label className="m-0">{t('auth.birthday')}</Label>
            </Col>

            <Col sm={12} xxl={8}>
              <StyledDatePicker
                onChange={option =>
                  formikApplicant.setFieldValue('birthday', option)
                }
                disabled={isDisabledFields}
                value={formikApplicant.values?.birthday}
                selected={formikApplicant.values?.birthday}
                maxDate={new Date()}
                className="w-100 p-2 ps-3"
                placeholderText={t('auth.birthdayPlaceholder')}
                invalid={formikApplicant.errors.birthday}
              />
            </Col>
          </FormGroup>
        )}
        {orderTypeFieldsSettings?.enableApplicantPassport && (
          <>
            <hr className="m-0 mb-3" />

            <FormGroup row className="align-items-center">
              <Col sm={12} xxl={2}>
                <Label className="m-0">{t('passport.nationality')}</Label>
              </Col>

              <Col sm={12} xxl={8}>
                <SelectCountry
                  isAvailableCitizenship
                  onChange={option =>
                    formikApplicant.setFieldValue(
                      'passport.nationality',
                      option ? option.value : null,
                    )
                  }
                  isDisabled={isDisabledFields}
                  invalid={formikApplicant.errors.passport?.nationality}
                  value={formikApplicant.values?.passport?.nationality}
                  className="w-100"
                  placeholder={t('passport.nationalityPlaceholder')}
                />
              </Col>
            </FormGroup>

            <FormGroup row className="align-items-center">
              <Col sm={12} xxl={2}>
                <Label className="m-0">{t('passport.number')}</Label>
              </Col>

              <Col sm={12} xxl={8}>
                <StyledInput
                  className="w-100 p-2 ps-3"
                  name="passport.number"
                  onChange={formikApplicant.handleChange}
                  value={formikApplicant.values?.passport?.number}
                  placeholder={t('passport.numberPlaceholder')}
                  invalid={formikApplicant.errors.passport?.number}
                  disabled={isDisabledFields}
                />
              </Col>
            </FormGroup>

            <FormGroup row className="align-items-center">
              <Col sm={12} xxl={2}>
                <Label className="m-0">{t('passport.startDate')}</Label>
              </Col>

              <Col sm={12} xxl={8}>
                <StyledDatePicker
                  onChange={option =>
                    formikApplicant.setFieldValue('passport.startDate', option)
                  }
                  value={formikApplicant.values?.passport?.startDate}
                  selected={formikApplicant.values?.passport?.startDate}
                  invalid={formikApplicant.errors.passport?.startDate}
                  maxDate={new Date()}
                  className="w-100 p-2 ps-3"
                  placeholderText={t('common.datePlaceholder')}
                  selectsStart
                  disabled={isDisabledFields}
                  startDate={formikApplicant.values?.passport?.startDate}
                  endDate={formikApplicant.values?.passport?.expirationDate}
                />
              </Col>
            </FormGroup>

            <FormGroup row className="align-items-center">
              <Col sm={12} xxl={2}>
                <Label className="m-0">{t('passport.expirationDate')}</Label>
              </Col>

              <Col sm={12} xxl={8}>
                <StyledDatePicker
                  onChange={option =>
                    formikApplicant.setFieldValue(
                      'passport.expirationDate',
                      option,
                    )
                  }
                  value={formikApplicant.values?.passport?.expirationDate}
                  selected={formikApplicant.values?.passport?.expirationDate}
                  minDate={new Date()}
                  invalid={formikApplicant.errors.passport?.expirationDate}
                  className="w-100 p-2 ps-3"
                  placeholderText={t('common.datePlaceholder')}
                  selectsEnd
                  disabled={isDisabledFields}
                  startDate={formikApplicant.values?.passport?.startDate}
                  endDate={formikApplicant.values?.passport?.expirationDate}
                />
              </Col>
            </FormGroup>
          </>
        )}

        <FormGroup row>
          <Col sm={0} xxl={2}></Col>

          <Col
            sm={12}
            xxl={6}
            className="d-flex gap-2 flex-nowrap justify-content-between"
          >
            <StyledButtonSave
              className="w-50"
              onClick={formikApplicant.handleSubmit}
              disabled={!formikApplicant.dirty || isSubmitting}
              color="primary"
            >
              {t('common.save')}
            </StyledButtonSave>

            <StyledButtonCancel
              className="w-50 btn"
              onClick={
                !formikOrder.values.applicants?.[index].applicantDetail
                  .firstName
                  ? removeApplicant
                  : handleClose
              }
            >
              {t('common.cancel')}
            </StyledButtonCancel>
          </Col>
        </FormGroup>
      </Col>
    </div>
  );
};

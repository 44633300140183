import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import Profile from './auth/profile/reducer';

//chat
import chat from './chat/reducer';

//invoices
import invoices from './invoices/reducer';

//admin
import admin from './admin/reducers';

//references
import references from './references/reducer';

import wizard from './wizard/reducer';

import loader from './loader/reducer';

import clients from './clients/reducer';

import clientCabinet from './clientCabinet/reducer';

import services from './services/reducer';

import tariffs from './tariffs/reducer';

import order from './order/reducer';

import aggregatedInvoice from './aggregatedInvoices/reducer';
import applicants from './applicants/reducer';

import documents from './documents/reducer';

import addresses from './addresses/reducer';

import orderTypes from './orderTypes/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  Profile,
  chat,
  invoices,
  admin,
  references,
  wizard,
  loader,
  clients,
  clientCabinet,
  services,
  tariffs,
  order,
  aggregatedInvoice,
  applicants,
  documents,
  addresses,
  orderTypes,
});

export default rootReducer;

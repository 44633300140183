import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import {
  getValidatedWizardStep,
  setFileField,
  setWizardFormField,
  setWizardHelperField,
} from '../../store/wizard/actions';
import { MOCK_REQUEST } from './constants';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../components/Common/CountrySelect';
import { uploadWizardFile } from '../../helpers/api-requests/admin';
import { WizardFiles } from './WizardFiles';
import { Markdown } from './Markdown';
import classnames from 'classnames';
import CitiesSelect from '../../components/Common/CitiesSelect';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { DATE_FORMAT_SHORT } from 'common/data/constants';
import { FormikInput, Select, StyledPhoneInput } from 'components/Common';
import { convertDateToAPI } from 'helpers/converters';

const WizardFields = props => {
  const {
    field: { label, api_name, field_type, options },
  } = props;

  const wizardForm = useSelector(state => state.wizard.form);
  const errors = useSelector(state => state.wizard.error);
  const helperForm = useSelector(state => state.wizard.helper);
  const meta = useSelector(state => state.wizard.meta);
  const fileFields = useSelector(state => state.wizard.files);
  const error =
    errors &&
    errors.data &&
    errors.data.detail &&
    errors.data.detail[api_name] &&
    errors.data.detail[api_name].errors;

  const form = wizardForm[meta.step] ?? {};
  const helper = helperForm[meta.step] ?? {};

  const dispatch = useDispatch();
  const setField = useCallback(
    value => dispatch(setWizardFormField(value)),
    [dispatch],
  );
  const setHelper = useCallback(
    value => dispatch(setWizardHelperField(value)),
    [dispatch],
  );
  const setFileUploaded = useCallback(
    value => dispatch(setFileField(value)),
    [dispatch],
  );
  const validateWizard = useCallback(
    query => dispatch(getValidatedWizardStep(query)),
    [dispatch],
  );

  const {
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const country = useMemo(() => {
    return queryParams.get('country');
  }, [location.search]);

  useEffect(async () => {
    if (!isEmpty(form)) {
      validateWizard({
        ...MOCK_REQUEST,
        country,
        step: meta.step + 1,
        data: form,
      });
    }
  }, [form[api_name]]);

  const onTextChange = e => {
    setField({ [api_name]: e.currentTarget.value });
  };

  const onPhoneChange = e => {
    setField({ [api_name]: e });
  };

  const onDateChange = date => {
    setHelper({ [api_name]: date });
    setField({ [api_name]: convertDateToAPI(date) });
  };

  const onCheckboxChange = e => {
    setField({ [api_name]: e.currentTarget.checked ? 1 : 0 });
  };

  const onSelectChange = async value => {
    setField({ [api_name]: value?.id });
  };

  const onCitiesChange = values => {
    setHelper({ [api_name]: values });
    setField({ [api_name]: values.map(v => v.value) });
  };

  const onFileSelect = async file => {
    setField({ test: true });
    if (file) {
      let formData = new FormData();
      formData.append('document', file[0]);
      const result = await uploadWizardFile({
        ...MOCK_REQUEST,
        country,
        field: api_name,
        formData,
      });
      setFileUploaded({ [api_name]: result });
    }
  };

  const textType = field_type === 'text' || field_type === 'email';
  const isFile = field_type === 'file';
  const isCities = field_type === 'cities';
  const withCommonLabel = field_type !== 'checkbox' && !isFile;
  const phoneField = field_type === 'phone';

  return (
    <div>
      <FormGroup>
        {withCommonLabel && (
          <Label>
            <Markdown markdown={label} />
          </Label>
        )}
        {phoneField && (
          <StyledPhoneInput
            value={form[api_name]}
            onChange={onPhoneChange}
            name={api_name}
          />
        )}
        {textType && (
          <FormikInput
            handleChange={onTextChange}
            name={api_name}
            value={form[api_name]}
            errors={error}
          />
        )}
        {field_type === 'date' && (
          <StyledDatePicker
            className={classnames([
              'form-control d-block vbo-date',
              error && 'is-invalid',
            ])}
            selected={helper[api_name] ?? null}
            value={helper[api_name] ?? null}
            onChange={onDateChange}
            placeholder={DATE_FORMAT_SHORT}
            dateFormat={DATE_FORMAT_SHORT}
          />
        )}
        {field_type === 'select' && (
          <Select
            options={options}
            value={options.find(o => o.id === form[api_name])}
            getOptionValue={option => option.id}
            isClearable
            getOptionLabel={option => option.translations[language]}
            onChange={onSelectChange}
            className={classnames([
              'vbo-select col-12 pe-0 pe-0',
              error && 'is-invalid',
            ])}
            classNamePrefix="select2-selection"
          />
        )}
        {field_type === 'country' && (
          <CountrySelect
            isValid={!error}
            value={form[api_name]}
            update={onSelectChange}
          />
        )}
        {field_type === 'checkbox' && (
          <Label className="pe-4" check>
            <Input
              type="checkbox"
              checked={String(form[api_name]) === '1'}
              onChange={onCheckboxChange}
            />{' '}
            <Markdown markdown={label} />
          </Label>
        )}
        {isCities && (
          <CitiesSelect
            isValid={!error}
            value={helper[api_name]}
            update={onCitiesChange}
          />
        )}
        {isFile && (
          <WizardFiles
            selectedFiles={form[api_name] ?? []}
            success={fileFields[api_name]?.success}
            url={fileFields[api_name]?.data.url}
            onFileSelect={onFileSelect}
            label={label}
          />
        )}
        {error && <p className="text-danger">{error.join(' ')}</p>}
      </FormGroup>
    </div>
  );
};

export default WizardFields;

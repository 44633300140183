import React from 'react';
import Select from 'react-select';

export class SelectBox extends React.Component {
  render() {
    const { isValid = true, SelectComponent = Select } = this.props;

    const customStyles = {
      control: (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
        // overwrittes hover style
        '&:hover': {
          borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
        },
      }),
    };
    return (
      <>
        <SelectComponent styles={customStyles} {...this.props} />
      </>
    );
  }
}

import { DATE_FORMAT_WITH_SYMBOL } from 'common/data/constants';
import {
  Badge,
  BlueLink,
  CardTitle,
  ShadowCard,
  StyledColBold,
} from 'components/Common';
import { formatDate } from 'helpers/formatDate';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { getShipmentLabelPdf } from 'helpers/downloadDocument';

export const DeliveryGeneralInfo = ({ delivery, loading }) => {
  const { t } = useTranslation();

  return (
    <ShadowCard bodyStyle={{ padding: '24px' }} id="information">
      {!loading && delivery && (
        <>
          <CardTitle title="common.information" />

          <div className="d-flex w-100 flex-lg-nowrap flex-wrap">
            <Col xs={12}>
              {delivery.method ? (
                <FormGroup className="mb-4 " row>
                  <Col lg={3} xs={6}>
                    <Label className="text-muted fw-normal m-0">
                      {t('delivery.dpsMethod')}
                    </Label>
                  </Col>

                  <StyledColBold
                    style={{ fontSize: '14px' }}
                    className="d-flex flex-column"
                    lg={8}
                    xs={6}
                  >
                    <p className="m-0">
                      {delivery.method?.carrier_display ??
                        delivery.method?.carrier_display}
                    </p>

                    {delivery.method?.method_display && (
                      <p className="fw-normal m-0 text-muted">
                        {delivery.method?.method_display}
                      </p>
                    )}
                  </StyledColBold>
                </FormGroup>
              ) : (
                <FormGroup className="mb-4 align-items-center" row>
                  <Col lg={3} xs={6}>
                    <Label className="text-muted fw-normal m-0">
                      {t('delivery.dpsMethod')}
                    </Label>
                  </Col>

                  <StyledColBold
                    style={{ fontSize: '14px' }}
                    className="d-flex flex-column"
                    lg={8}
                    xs={6}
                  >
                    <p className="m-0">
                      {delivery.carrier?.carrier_display ?? '-'}
                    </p>
                  </StyledColBold>
                </FormGroup>
              )}

              {delivery.number && (
                <FormGroup className="mb-4 align-items-center" row>
                  <Col lg={3} xs={6}>
                    <Label className="text-muted fw-normal m-0">
                      {t('delivery.trackingNumber')}
                    </Label>
                  </Col>

                  <StyledColBold style={{ fontSize: '14px' }} xs={6} lg={8}>
                    {delivery.number}
                  </StyledColBold>
                </FormGroup>
              )}

              {delivery.comment && (
                <FormGroup className="mb-4 align-items-center" row>
                  <Col lg={3} xs={6}>
                    <Label className="text-muted fw-normal m-0">
                      {t('common.comment')}
                    </Label>
                  </Col>

                  <StyledColBold style={{ fontSize: '14px' }} xs={6} lg={8}>
                    {delivery.comment}
                  </StyledColBold>
                </FormGroup>
              )}

              {delivery.track_number && (
                <FormGroup className="mb-4 align-items-center" row>
                  <Col lg={3} xs={6}>
                    <Label className="text-muted fw-normal m-0">
                      {t('delivery.trackingNumber')}
                    </Label>
                  </Col>

                  <StyledColBold
                    style={{ fontSize: '14px' }}
                    className="d-flex flex-column"
                    lg={8}
                    xs={6}
                  >
                    {delivery.track_number ?? '-'}
                  </StyledColBold>
                </FormGroup>
              )}

              <FormGroup className="mb-4 align-items-center" row>
                <Col lg={3} xs={6}>
                  <Label className="text-muted fw-normal m-0">
                    {t('delivery.creationDate')}
                  </Label>
                </Col>

                <StyledColBold style={{ fontSize: '14px' }} lg={8} xs={6}>
                  {formatDate(delivery.created_at, DATE_FORMAT_WITH_SYMBOL)}
                </StyledColBold>
              </FormGroup>

              <FormGroup className="mb-4 align-items-center" row>
                <Col lg={3} xs={6}>
                  <Label className="text-muted fw-normal m-0">
                    {t('common.status')}
                  </Label>
                </Col>

                <StyledColBold lg={8} xs={6}>
                  {delivery.status ? (
                    <Badge
                      text={delivery.status}
                      color={convertStatusToColor(delivery.status)}
                    />
                  ) : (
                    '-'
                  )}
                </StyledColBold>
              </FormGroup>

              <FormGroup row className="align-items-center">
                <Col lg={3} xs={6}>
                  <Label className="text-muted fw-normal m-0">
                    {t('delivery.order')}
                  </Label>
                </Col>

                <StyledColBold
                  style={{ fontSize: '14px' }}
                  className="d-flex flex-column"
                  lg={8}
                  xs={6}
                >
                  {delivery?.order ? (
                    <BlueLink to={`/orders/${delivery.order.id}`}>
                      {delivery.order.number}
                    </BlueLink>
                  ) : (
                    <span>-</span>
                  )}
                </StyledColBold>
              </FormGroup>

              {delivery?.shipment_label_link && (
                <>
                  <FormGroup row className="align-items-center">
                    <Col lg={3} xs={6}>
                      <Label className="text-muted fw-normal m-0">
                        {t('delivery.shippingLabel')}
                      </Label>
                    </Col>

                    <StyledColBold
                      style={{ fontSize: '14px' }}
                      className="d-flex flex-column"
                      lg={8}
                      xs={6}
                    >
                      <BlueLink onClick={() => getShipmentLabelPdf(delivery)}>
                        {t('documents.download')}
                      </BlueLink>
                    </StyledColBold>
                  </FormGroup>
                </>
              )}
            </Col>
          </div>
        </>
      )}
    </ShadowCard>
  );
};

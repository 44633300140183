import React, { useEffect, useState } from 'react';

import { Row, Col, Alert } from 'reactstrap';

import { PaginationLm } from 'components/Common/PaginationLm';
import {
  USER_STATUS,
  EMPTY_VALUE_WITH_LABEL,
  USER_ROLE_CHOICES,
} from 'common/data/constants';

import { Badge, BlueLink, Loader } from 'components/Common';
import { useTranslation } from 'react-i18next';
import { isUserAdmin } from 'helpers/jwt_helper';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { Layout } from 'components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTable } from 'components/Table/StyledTable';
import {
  blockUser,
  getUsers,
  inviteUser,
  unBlockUser,
  deleteUser,
} from 'store/actions';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { useHistory } from 'react-router-dom';
import { ButtonCreate } from 'components/Common/ButtonCreate';
import { ShadowCard } from 'components/Common/ShadowCard';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { ButtonControlTable } from 'components/Tariffs/ButtonControlTable';
import { useTable } from 'hooks/useTable';
import { useModal } from 'hooks/useModal';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';

const getUsersState = state => state.admin.rest.users;

const usersAdditionalFields = {
  ordering: '',
  search: '',
  status: EMPTY_VALUE_WITH_LABEL,
  role: EMPTY_VALUE_WITH_LABEL,
};

const UsersComponent = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const { users, count, isLoading } = useSelector(getUsersState);
  const { handleChangeSearchParam, searchState, showPagination } = useTable(
    usersAdditionalFields,
    count,
  );

  const filterData = () => {
    const { perPage, page, ordering, search, status, role } = searchState;

    dispatch(
      getUsers({
        page,
        page_size: perPage.value,
        ordering,
        search,
        status: status?.value,
        role: role?.value,
      }),
    );
  };

  useEffect(() => {
    filterData();
  }, [searchState]);

  const getUserAction = (id, userStatus, lastLogin, isInvited) => {
    const actions = [
      {
        id: 1,
        title: 'common.open',
        function: () => push(`/users/${id}`),
      },
      {
        id: 2,
        title: isUserAdmin ? 'users.logs' : 'sideBar.orders',
        function: () =>
          push(isUserAdmin ? `/logs/${id}` : `/users/${id}/orders`),
      },
      {
        id: 3,
        title: userStatus === 'active' ? t('users.block') : t('users.unblock'),
        function: () => handleOpenModal({ id, userStatus }),
      },
      {
        id: 4,
        title: 'common.delete',
        function: () => handleChangeUser({ id }),
      },
    ];

    if (!lastLogin && userStatus === 'active') {
      return actions.concat({
        id: 5,
        disabled: isInvited,
        title: 'invites.inviteUser',
        function: () => dispatch(inviteUser({ id })),
      });
    }

    return actions;
  };

  const lockUser = id => {
    handleCloseModal();
    dispatch(blockUser({ id }));
  };

  const unlockUser = id => {
    handleCloseModal();
    dispatch(unBlockUser({ id }));
  };

  const handleChangeUser = user => {
    setIsOpenUserModal(true);
    setCurrentUser(user);
  };

  const handleCloseUserModal = () => {
    setIsOpenUserModal(false);
    setCurrentUser({});
  };

  const handleDeleteUser = id => {
    dispatch(deleteUser({ id, handleCloseUserModal }));
  };

  return (
    <Layout
      title="sideBar.users"
      extraContent={
        isUserAdmin && (
          <ButtonCreate handleCreate={() => push('/users/create')} />
        )
      }
    >
      {isOpen && (
        <ModalConfirmDelete
          textHeader={
            isOpen.userStatus === 'active'
              ? 'users.blockUser'
              : 'users.unBlockUser'
          }
          textBody={
            isOpen.userStatus === 'active'
              ? 'users.confirmBlock'
              : 'users.confirmUnBlock'
          }
          handleCloseModal={handleCloseModal}
          isOpenModal={isOpen}
          buttonText={
            isOpen.userStatus === 'active' ? 'users.block' : 'users.unblock'
          }
          handleDelete={
            isOpen.userStatus === 'active'
              ? () => lockUser(isOpen.id)
              : () => unlockUser(isOpen.id)
          }
        />
      )}

      {isOpenUserModal && (
        <ModalConfirmDelete
          isOpenModal={isOpenUserModal}
          textHeader="users.delete"
          textBody="users.deleteConfirm"
          handleCloseModal={handleCloseUserModal}
          handleDelete={() => handleDeleteUser(currentUser.id)}
        />
      )}

      <ShadowCard>
        <Col lg={12}>
          <Row className="align-items-center mb-3">
            <Col xs={3}>
              <SearchInput
                handleChange={event =>
                  handleChangeSearchParam('search', event.target.value)
                }
              />
            </Col>

            <Col xs={6} className="d-flex">
              <StatusPicker
                $type="gray"
                handlePickStatus={status =>
                  handleChangeSearchParam('status', status)
                }
                value={searchState.status}
                placeholder="users.status"
                statuses={USER_STATUS}
              />

              <StatusPicker
                handlePickStatus={role => handleChangeSearchParam('role', role)}
                value={searchState.role}
                $type="gray"
                className="ms-3"
                placeholder="users.role"
                statuses={USER_ROLE_CHOICES}
              />
            </Col>

            <Col xs={3}>
              <PerPageSelect
                perPage={searchState.perPage}
                setPage={page => handleChangeSearchParam('page', page)}
                setPerPage={perPage =>
                  handleChangeSearchParam('perPage', perPage)
                }
              />
            </Col>
          </Row>

          {isLoading && (
            <Row className="d-flex justify-content-center">
              <Loader isLoading={isLoading} />
            </Row>
          )}

          {!isLoading && users && users.length === 0 && (
            <Alert color="info">{t('users.notFound')}</Alert>
          )}

          {!isLoading && users && users.length > 0 && (
            <Row>
              <div className="table-responsive">
                <StyledTable>
                  <thead>
                    <tr>
                      <th>{t('auth.username')}</th>

                      <th>{t('auth.firstName')}&nbsp;</th>

                      <th>{t('auth.lastName')}&nbsp;</th>

                      <th>{t('users.role')}</th>

                      <th>{t('client.organizationName')}</th>

                      <th>{t('common.status')}</th>

                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {users.map(
                      ({
                        first_name,
                        last_name,
                        username,
                        id,
                        role,
                        status,
                        ...user
                      }) => (
                        <tr key={id}>
                          <td className="col-2 text-wrap">
                            {isUserAdmin ? (
                              <BlueLink to={`/users/${id}`}>
                                {username}
                              </BlueLink>
                            ) : (
                              username
                            )}
                          </td>

                          <td className="col-2">{first_name}</td>

                          <td className="col-2">{last_name}</td>

                          <td className="col-2">
                            {t(
                              USER_ROLE_CHOICES.find(
                                userRole => userRole.value === role,
                              )?.label,
                            )}
                          </td>

                          <td className="col-3">
                            {user?.organization && user.organization?.name}
                          </td>

                          <td className="col-1">
                            <Badge
                              text={
                                USER_STATUS.find(
                                  userStatus => userStatus.value === status,
                                )?.label
                              }
                              color={convertStatusToColor(status)}
                            />
                          </td>

                          <td>
                            <ButtonControlTable
                              actions={getUserAction(
                                id,
                                status,
                                user.last_login,
                                user.isInvited,
                              )}
                            />
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </StyledTable>
              </div>

              <Row>
                <Col xs={12}>
                  {showPagination && (
                    <PaginationLm
                      count={count}
                      perPage={searchState.perPage.value}
                      active={searchState.page}
                      selectPage={page => handleChangeSearchParam('page', page)}
                    />
                  )}
                </Col>
              </Row>
            </Row>
          )}
        </Col>
      </ShadowCard>
    </Layout>
  );
};

export default UsersComponent;

import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import logoLightSvg from '../../assets/images/logo-light.svg';
import logoLightSmSvg from '../../assets/images/logo-light-sm.svg';

//i18n
import SidebarContent from './SideBarContent';
import LanguageDropdown from 'components/CommonForBoth/TopbarDropdown/LanguageDropdown';
import ProfileMenu from 'components/CommonForBoth/TopbarDropdown/ProfileMenu';
import { Button } from 'reactstrap';
import styled, { css } from 'styled-components';
import { ChevronLeft } from 'assets/images/visa';
import { getLayoutState } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLeftSidebar } from 'store/actions';
import classNames from 'classnames';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { useMatchMedia } from 'hooks/useMatchMedia';

export const StyledHr = styled.hr`
  border-top: 2px solid ${({ theme }) => theme.colors.sideBarText} !important;
`;

const StyledImg = styled.img`
  margin-bottom: 28px;

  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      height: 30px;
    `}
`;

const StyledButton = styled(Button)`
  position: absolute;
  padding: 0;
  background: none !important;
  border: 0;
  right: -22px !important;
  top: 20px !important;
  transition: rotate 0.5s ease 0s;

  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      top: 17px;
    `}

  ${({ $type }) =>
    $type
      ? css`
          rotate: 0deg;
        `
      : css`
          rotate: 180deg;
        `}
`;

const Sidebar = memo(() => {
  const dispatch = useDispatch();
  const { isDesktop } = useMatchMedia();
  const typeSideBar = useSelector(getLayoutState);
  const { push } = useHistory();

  return (
    <div
      className={classNames(
        'h-100 d-flex flex-column justify-content-between py-4',
        [typeSideBar ? 'p-4' : 'px-2'],
      )}
    >
      <div>
        <StyledEmptyButton onClick={() => push('/orders/')}>
          <StyledImg
            $isDesktop={isDesktop}
            src={typeSideBar ? logoLightSvg : logoLightSmSvg}
            alt=""
          />
        </StyledEmptyButton>

        {isDesktop && (
          <StyledButton
            $isDesktop={isDesktop}
            $type={typeSideBar}
            onClick={() => dispatch(toggleLeftSidebar())}
          >
            <img src={ChevronLeft} />
          </StyledButton>
        )}

        <SidebarContent />
      </div>

      <div>
        <LanguageDropdown />

        <StyledHr className={classNames('my-4', [!typeSideBar && 'mx-3'])} />

        <ProfileMenu />
      </div>
    </div>
  );
});

export default Sidebar;

import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import logoLightSvg from 'assets/images/logo-light.svg';
import { Link, useLocation } from 'react-router-dom';
import { getFirstAvailableRoute } from 'helpers/jwt_helper';
import { UserActionDropDown } from './UserActionDropDown';
import { Button, Collapse, Nav, Navbar } from 'reactstrap';
import { menuItems } from './menuItems';
import { MenuListItem, StyledBeta, StyledLink } from './SideBarContent';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from 'components/CommonForBoth/TopbarDropdown/LanguageDropdown';
import { StyledHr } from './Sidebar';
import classNames from 'classnames';
import { ChevronImage } from 'assets/images/visa';

const StyledMobileSideBar = styled(Navbar)`
  width: 100vw;
  z-index: 1001;
  background-color: ${({ theme }) => theme.colors.sideBarBg};
`;

const StyledButton = styled(Button)`
  padding: 0;
  position: absolute;
  bottom: -24px;
  left: 0;
  background: none !important;
  border: 0;
  transition: rotate 0.5s ease 0s;

  ${({ $type }) =>
    $type
      ? css`
          rotate: 90deg;
        `
      : css`
          rotate: -90deg;
        `}
`;

const StyledCollapse = styled(Collapse)`
  overflow-y: auto;
`;

export const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const handleToggleNavbar = () => setIsOpen(prev => !prev);

  return (
    <StyledMobileSideBar fixed="top" className="p-2">
      <Link className="d-flex align-items-center" to={getFirstAvailableRoute()}>
        <img src={logoLightSvg} alt="" />
      </Link>

      <div className="d-flex gap-2">
        <UserActionDropDown />
      </div>

      <StyledCollapse isOpen={isOpen} navbar>
        <StyledHr className="my-4" />

        <Nav className="me-auto" navbar>
          {menuItems.map(
            (item, index) =>
              item.isVisible && (
                <Fragment key={item.key}>
                  {item.beta && <StyledBeta>Beta</StyledBeta>}
                  <StyledLink
                    onClick={handleToggleNavbar}
                    className={classNames('w-100 h-100', {
                      'mb-4': index !== menuItems.length + 1,
                    })}
                    $active={pathname.includes(item.link)}
                    to={item.link}
                    id={item.key}
                  >
                    <MenuListItem
                      className={
                        'd-flex align-items-center m-0 justify-content-start'
                      }
                      key={item.key}
                    >
                      {item.icon}

                      <div className="ms-2 fw-500">{t(item.title)}</div>
                    </MenuListItem>
                  </StyledLink>
                </Fragment>
              ),
          )}

          <LanguageDropdown direction="bottom" />
        </Nav>
      </StyledCollapse>

      <StyledButton $type={isOpen} onClick={handleToggleNavbar}>
        <ChevronImage />
      </StyledButton>
    </StyledMobileSideBar>
  );
};

import { BlueLink } from 'components/Common';
import { EditPrice } from 'components/Common/EditPrice';
import { ButtonControlTable } from 'components/Tariffs/ButtonControlTable';
import { convertPriceToAPI } from 'helpers/converters';
import { formatCurrency } from 'helpers/formatCurrency';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { deleteTariffServices } from 'store/tariffs/actions';
import { updateTariffServicePrice } from 'store/tariffs/actions';
import i18n from 'i18n';
import styled, { css } from 'styled-components';

export const StyledEmptyButton = styled(Button)`
  border-radius: 50% !important;
  padding: 0;
  color: ${({ theme }) => theme.colors.gray400};
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;

  &:hover {
    border: none;
    color: ${({ theme }) => theme.colors.mainText};
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  &&:active {
    border: none;
    color: ${({ theme }) => theme.colors.mainText};
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  &:focus-visible {
    border: none;
    color: ${({ theme }) => theme.colors.mainText};
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  &.btn.disabled {
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  i {
    font-size: 18px;
  }

  ${({ $isNoFocus }) =>
    $isNoFocus &&
    css`
      &:focus {
        border: none;
        color: ${({ theme }) => theme.colors.mainText};
        background-color: ${({ theme }) => theme.colors.transparent};
      }

      &:focus-visible {
        border: none;
        color: ${({ theme }) => theme.colors.mainText};
        background-color: ${({ theme }) => theme.colors.transparent};
        box-shadow: none !important;
      }
    `}

  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color]} !important;
    `}
`;

export const TariffServiceRow = ({ service }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const [isEditPriceMode, setIsEditPriceMode] = useState(false);
  const [servicePrice, setServicePrice] = useState(null);

  const handleChangePrice = event =>
    setServicePrice(prev => ({ ...prev, price: event.target.value }));

  const disableEditPrice = () => {
    setServicePrice(null);
    setIsEditPriceMode(false);
  };

  const updatePriceService = () => {
    dispatch(
      updateTariffServicePrice({
        id: params.id,
        service: {
          id: servicePrice.currentId,
          price: convertPriceToAPI(servicePrice.price),
        },
      }),
    );
    disableEditPrice();
  };

  const handleOpenEditMode = service => {
    setIsEditPriceMode(true);
    setServicePrice({
      currentId: service.id,
      price:
        service.product_net_price_cents === 0
          ? undefined
          : formatCurrency(service.product_net_price_cents),
    });
  };

  const getServiceAction = service => [
    {
      id: 1,
      title: 'tariffs.openDetail',
      function: () =>
        window.open(
          `/services/${service.product_id}/${service.product_type.type}`,
          '_blank',
        ),
    },
    {
      id: 2,
      title: isEditPriceMode ? 'tariffs.savePrice' : 'tariffs.editPrice',
      function: isEditPriceMode
        ? updatePriceService
        : () => handleOpenEditMode(service),
    },
    {
      id: 3,
      title: 'Delete',
      function: () =>
        dispatch(
          deleteTariffServices({
            id: params.id,
            selectedServices: [service.id],
          }),
        ),
    },
  ];

  return (
    <tr key={service.product_id}>
      <td className="col-2">
        <BlueLink
          to={`/services/${service.product_id}/${service.product_type.type}`}
        >
          {service.name[i18n.language]}
        </BlueLink>
      </td>

      <td className="col-1">{service.product_type.type_display}</td>

      <td className="col-1">
        {service.visa_type?.translations[i18n.language]}
      </td>

      <td className="col-1">
        {service.visa_duration?.translations[i18n.language]}
      </td>

      <td className="col-1">
        {service.visa_urgency?.translations[i18n.language]}
      </td>

      <td className="col-1">
        {service.country?.country?.name ?? t('common.all')}
      </td>

      <td className="col-1">
        {service.customer_nationality_region?.name ?? t('common.all')}
      </td>

      <td className="col-1">
        {service.customer_nationality?.country?.name ?? t('common.all')}
      </td>

      <td className="col-2">
        {service.tags && service.tags.map(tag => <p key={tag}>{tag}</p>)}
      </td>

      {isEditPriceMode ? (
        <>
          <td className="col-2">
            <EditPrice
              price={servicePrice?.price}
              handleChangePrice={handleChangePrice}
              handleCloseEdit={disableEditPrice}
              handleSavePrice={updatePriceService}
              disabled={
                formatCurrency(service.product_net_price_cents) ===
                servicePrice?.price
              }
            />
          </td>
        </>
      ) : (
        <>
          <td className="col-1 text-nowrap">
            {formatCurrency(service.product_net_price_cents)}
            <StyledEmptyButton
              className="ms-1"
              onClick={() => handleOpenEditMode(service)}
            >
              <i className="bx bx-edit-alt" />
            </StyledEmptyButton>
          </td>
        </>
      )}

      <td className="col-1">{`${formatCurrency(
        service.product_tax_total_cents,
      )} (${service.product_tax_percent_human}%)`}</td>

      <td className="col-1">
        {formatCurrency(service.product_total_price_cents)}
      </td>

      <td className="col-1 p-1">
        <ButtonControlTable actions={getServiceAction(service)} />
      </td>
    </tr>
  );
};

import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  VERIFY_TOKEN,
  VERIFY_RESULT,
  REFRESH_TOKEN,
  REFRESH_SUCCESS,
  API_ERROR,
} from './actionTypes';

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const verifyToken = history => {
  return {
    type: VERIFY_TOKEN,
    payload: { history },
  };
};

export const verifyResult = isAuth => {
  return {
    type: VERIFY_RESULT,
    payload: { isAuth },
  };
};

export const refreshToken = (user, history) => {
  return {
    type: REFRESH_TOKEN,
    payload: { user, history },
  };
};

export const refreshSuccess = () => {
  return {
    type: REFRESH_SUCCESS,
    payload: {},
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

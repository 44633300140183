import React, { useMemo, useState } from 'react';
import { Accordion } from 'components/Common/Accordion';
import { SelectClient } from 'components/Order/SelectClient';
import { Col, Fade, FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { CLIENT_TYPES } from 'common/data/constants';
import { ShadowCard } from 'components/Common/ShadowCard';
import { useSelector } from 'react-redux';
import { SelectOrderCountry } from 'components/SelectCountry/SelectOrderCountry';
import { SelectConsulate } from './SelectConsulate';
import { StyledInput } from 'components/Common/FormikInput';
import {
  isUserEmployee,
  isUserClient,
  isUserIndividual,
  isUserManager,
} from 'helpers/jwt_helper';
import { DEFAULT_POINTS } from 'helpers/styles/constants';
import { ModalOtherCountries } from '../../Common/ModalOtherCountries';

export const SelectClientCard = ({ change, orderTypeFieldsSettings }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { clients } = useSelector(state => state.clients);
  const [showOtherCountriesPopup, setShowOtherCountriesPopup] = useState(false);

  const clientValues = formik.values.client;

  const isDisabledField = useMemo(() => {
    return formik.values.applicants.length > 0;
  }, [formik.values.applicants]);

  const isDisabledFieldsByConfirm = useMemo(
    () => !formik.values.orderDetail?.isEditable,
    [formik.values.orderDetail?.isEditable],
  );

  const currentClientTariff = useMemo(() => {
    if (clientValues.client && clients.results) {
      return clients.results.find(client => client.id === clientValues.client)
        ?.tariff?.name;
    }
  }, [clientValues.client, clients]);

  const isDisabledByRole =
    !isUserClient && !isUserEmployee && !clientValues.clientType;

  return (
    <ShadowCard id="client" className="mb-4" bodyStyle={{ padding: '24px' }}>
      <Accordion title={isUserClient ? 'order.detail' : 'order.client'}>
        {!isUserClient && !isUserEmployee && (
          <div className="p-0 d-flex mt-4 flex-wrap">
            <Col className="p-0 d-flex" {...DEFAULT_POINTS.label}>
              <Label className="display-5">{t('client.clientType')}</Label>
            </Col>

            <Col {...DEFAULT_POINTS.input} className="d-flex p-0">
              {orderTypeFieldsSettings?.enableForIndividual && (
                <FormGroup check>
                  <Label check className="display-5">
                    <Input
                      name="radio2"
                      onChange={() => {
                        formik.setFieldValue(
                          'client.clientType',
                          CLIENT_TYPES[0].value,
                        );
                        formik.setFieldValue('client.client', null);
                      }}
                      disabled={isDisabledField}
                      checked={clientValues.clientType === 'individual'}
                      type="radio"
                    />
                    {t('client.individual')}
                  </Label>
                </FormGroup>
              )}
              {orderTypeFieldsSettings?.enableForLegal && (
                <FormGroup className="ms-4" check>
                  <Label check className="display-5">
                    <Input
                      name="radio2"
                      onChange={() => {
                        formik.setFieldValue(
                          'client.clientType',
                          CLIENT_TYPES[1].value,
                        );
                        formik.setFieldValue('client.client', null);
                      }}
                      disabled={isDisabledField}
                      type="radio"
                      checked={clientValues.clientType === 'legal'}
                    />
                    {t('client.legal')}
                  </Label>
                </FormGroup>
              )}
            </Col>
          </div>
        )}

        {!isUserClient && !isUserEmployee && (
          <div className="p-0 mt-3 d-flex flex-wrap">
            <Col
              className="d-flex align-items-center p-0"
              {...DEFAULT_POINTS.label}
            >
              <Label className="m-0 display-5">{t('order.client')}</Label>
            </Col>

            <Col className="p-0" {...DEFAULT_POINTS.input}>
              <SelectClient
                isDisabled={isDisabledField || !clientValues.clientType}
                placeholder={t('client.clientPlaceholder')}
                clientType={clientValues.clientType}
                value={clientValues.client}
                onChange={client =>
                  formik.setFieldValue(
                    'client.client',
                    client ? client.value : null,
                  )
                }
              />
            </Col>
          </div>
        )}

        {orderTypeFieldsSettings?.enableCountry && (
          <>
            <div className="d-flex mt-3 flex-wrap">
              <Col
                className="p-0 d-flex align-items-center"
                {...DEFAULT_POINTS.label}
              >
                <Label className="m-0 display-5">{t('common.country')}</Label>
              </Col>

              <Col className="p-0" {...DEFAULT_POINTS.input}>
                <SelectOrderCountry
                  isDisabled={
                    isDisabledField ||
                    (!isUserClient &&
                      !isUserEmployee &&
                      !clientValues.clientType)
                  }
                  placeholder={t('common.countryPlaceholder')}
                  value={clientValues.country}
                  isClearable
                  onChange={country => {
                    if (country?.value === '_others_') {
                      setShowOtherCountriesPopup(true);
                      formik.setFieldValue('client.country', null);
                    } else {
                      formik.setFieldValue(
                        'client.country',
                        country ? country.value : null,
                      );
                      change(
                        country ? country.country?.field_previous_visa : null,
                        country ? country.country?.field_previous_ussr : null,
                      );
                    }
                  }}
                  options={orderTypeFieldsSettings.availableForCountries}
                  others={isUserClient || isUserEmployee || isUserManager}
                />
              </Col>
            </div>

            {!isUserClient && !isUserEmployee && clientValues.country && (
              <div className="d-flex mt-3 flex-wrap">
                <Col
                  className="p-0 d-flex align-items-center"
                  {...DEFAULT_POINTS.label}
                >
                  <Label className="m-0 display-5">
                    {t('common.consulate')}
                  </Label>
                </Col>

                <Col className="p-0" {...DEFAULT_POINTS.input}>
                  <SelectConsulate
                    isDisabled={
                      !clientValues.country || !clientValues.clientType
                    }
                    value={clientValues?.consulate}
                    invalid={formik.errors.client?.consulate}
                    country={clientValues.country}
                    onChange={consulate => {
                      formik.setFieldValue(
                        'client.consulate',
                        consulate ? consulate.value : null,
                      );
                    }}
                  />
                </Col>
              </div>
            )}
          </>
        )}

        {!isUserIndividual && (
          <div className="d-flex flex-wrap mt-3">
            <Col
              className="p-0 d-flex align-items-center"
              {...DEFAULT_POINTS.label}
            >
              <Label className="m-0 display-5">
                {t('common.internalNumber')}
              </Label>
            </Col>

            <Col className="p-0" {...DEFAULT_POINTS.input}>
              <StyledInput
                onChange={formik.handleChange}
                name="client.internalNumber"
                disabled={isDisabledByRole || isDisabledFieldsByConfirm}
                placeholder={t('common.internalNumberPlaceholder')}
                className={
                  (isDisabledByRole || isDisabledFieldsByConfirm) &&
                  'opacity-50'
                }
                maxLength={30}
                value={clientValues.internalNumber ?? ''}
              />
            </Col>
          </div>
        )}

        {!isUserIndividual && (
          <div className="d-flex mt-3 flex-wrap">
            <Col
              className="p-0 d-flex align-items-center"
              {...DEFAULT_POINTS.label}
            >
              <Label className="m-0 display-5">{t('common.accountUnit')}</Label>
            </Col>

            <Col className="p-0" {...DEFAULT_POINTS.input}>
              <StyledInput
                disabled={isDisabledByRole || isDisabledFieldsByConfirm}
                onChange={formik.handleChange}
                className={
                  (isDisabledByRole || isDisabledFieldsByConfirm) &&
                  'opacity-50'
                }
                name="client.accountingUnit"
                maxLength={30}
                placeholder={t('common.accountUnitPlaceholder')}
                value={clientValues.accountingUnit ?? ''}
              />
            </Col>
          </div>
        )}

        {!isUserClient && !isUserEmployee && (
          <>
            <hr className="my-4" />

            {clientValues.client && (
              <Fade className="d-flex mt-3">
                <Col className="p-0 d-flex align-items-center" xs={3} xxl={2}>
                  <Label className="text-muted fw-normal m-0">
                    {t('common.tariff')}
                  </Label>
                </Col>

                <Label className="p-0 fs-14 fw-500" xs={9} xxl={10}>
                  {currentClientTariff}
                </Label>
              </Fade>
            )}
          </>
        )}

        <ModalOtherCountries
          isOpenModal={showOtherCountriesPopup}
          handleCloseModal={() => setShowOtherCountriesPopup(false)}
        />
      </Accordion>
    </ShadowCard>
  );
};

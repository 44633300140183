import { createQueryActions } from 'helpers/store/createQueryActions';

export const openOrderTypeAlert = createQueryActions('openOrderTypeAlert');

export const closeOrderTypeAlert = createQueryActions('closeOrderTypeAlert');

export const fetchOrderType = createQueryActions('fetchOrderType');

export const fetchOrderTypes = createQueryActions('fetchOrderTypes');

export const updateOrderType = createQueryActions('updateOrderType');

export const createOrderType = createQueryActions('createOrderType');

import React, { memo, useEffect, useState } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { map } from 'lodash';

//i18n
import i18n from '../../../i18n';

import languages from '../../../common/languages';
import styled from 'styled-components';
import { getLayoutState } from 'components/VerticalLayout';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from 'hooks/useMatchMedia';

const StyledDropdownToggle = styled(DropdownToggle)`
  border: 0;
  background: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.sideBarText};
`;

const LanguageDropdown = memo(
  ({ className = 'mb-4', short, direction = 'right' }) => {
    const [selectedLang, setSelectLang] = useState('en');
    const typeSideBar = useSelector(getLayoutState);
    const { isMobile } = useMatchMedia();
    const { t } = useTranslation();

    useEffect(() => {
      const currentLanguage = localStorage.getItem('I18N_LANGUAGE');
      setSelectLang(currentLanguage);
    }, []);

    const changeLanguageAction = lang => {
      i18n.changeLanguage(lang);
      localStorage.setItem('I18N_LANGUAGE', lang);
      setSelectLang(lang);
      location.reload();
    };

    return (
      <UncontrolledDropdown className={className} direction={direction}>
        <StyledDropdownToggle className="p-0 w-100" tag="button">
          <div
            className={classNames('d-flex align-items-center w-100', [
              typeSideBar || isMobile
                ? 'justify-content-between'
                : 'justify-content-center',
            ])}
          >
            <div className="d-flex">
              {languages[selectedLang].flag}

              {(typeSideBar || isMobile) && !short && (
                <p className="ms-2 m-0">{t(languages[selectedLang].text)}</p>
              )}
            </div>

            {(typeSideBar || isMobile) && (
              <i className="bx bx-chevron-right text-right" />
            )}
          </div>
        </StyledDropdownToggle>

        <DropdownMenu>
          {map(Object.keys(languages), key => (
            <DropdownItem
              active={selectedLang === key}
              key={key}
              onClick={() => changeLanguageAction(key)}
            >
              {languages[key].flag}

              <span className="align-middle ms-1">
                {t(languages[key].label)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  },
);

export default LanguageDropdown;

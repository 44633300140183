import { useFormikContext } from 'formik';
import { formatCurrency } from 'helpers/formatCurrency';
import { useMatchMedia } from 'hooks/useMatchMedia';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const StyledPrice = styled.span`
  font-weight: 400;
  font-size: 34px;
  line-height: 51px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 1.5rem;
      line-height: 20px;
    `}
`;

const StyledTotalPrice = styled.div`
  display: flex;
  padding: 0px 16px;
`;

const StyledTitlePrice = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const StyledHr = styled.hr`
  border: 1px solid #dee0e7;
`;

export const Price = ({ allServices, totalPrice }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { isMobile } = useMatchMedia();

  const totalData = useMemo(() => {
    return allServices?.reduce(
      (accumulator, currentValue) => ({
        totalPrice:
          accumulator.totalPrice +
          Number(currentValue.product_total_price_cents),
      }),
      {
        totalPrice: 0,
      },
    );
  }, [allServices]);

  return (
    <StyledTotalPrice className={isMobile ? 'd-flex' : 'mt-4 flex-column'}>
      <StyledTitlePrice className={isMobile && 'me-2'}>
        {t(
          formik
            ? formik?.values?.orderDetail
              ? 'order.totalPrice'
              : 'order.tempPrice'
            : 'order.totalPrice',
        )}
        :
      </StyledTitlePrice>

      {!isMobile && <StyledHr className="m-0 p-0 mb-3 mt-3" />}

      <StyledPrice $isMobile={isMobile}>
        {formatCurrency(totalPrice ?? totalData?.totalPrice)}
      </StyledPrice>
    </StyledTotalPrice>
  );
};

import { DATE_FORMAT_WITH_SYMBOL } from 'common/data/constants';
import { CardTitle, ShadowCard, Badge } from 'components/Common';
import { Label } from 'components/Services/ServiceForm';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Label as ReactstrapLabel } from 'reactstrap';
import { TextField } from './TextField';
import { SelectCountry } from 'components/SelectCountry/SelectCountry';
import { StyledDatePicker } from 'components/CustomDatepicker';
import { SelectClients } from './SelectClients';
import { useFormikContext } from 'formik';
import { getInvoiceState } from './ReportInvoiceCard';
import { useSelector } from 'react-redux';
import { formatDate } from 'helpers/formatDate';
import { useTranslation } from 'react-i18next';
import { convertDateToAPI } from 'helpers/converters';
import { Select } from 'components/Common/Select';
import { AGGREGATE_INVOICE_STATUSES } from 'common/data/constants';

const DRAFT_STATUS = AGGREGATE_INVOICE_STATUSES.find(
  ({ value }) => value === 'draft',
);
const WAIT_PAYMENT_STATUS = AGGREGATE_INVOICE_STATUSES.find(
  ({ value }) => value === 'wait_payment',
);
const PAID_STATUS = AGGREGATE_INVOICE_STATUSES.find(
  ({ value }) => value === 'paid',
);

export const ReportClientCard = ({ handleOpenModal, isEditMode, ...props }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { countries } = useSelector(getInvoiceState);
  const { report, setReportStatus } = props;
  const reportStatus = report?.status;
  const isDraftReport = reportStatus?.status === DRAFT_STATUS.value;
  const isWaitPaymentReport =
    reportStatus?.status === WAIT_PAYMENT_STATUS.value;
  const isPaidReport = reportStatus?.status === PAID_STATUS.value;
  const REPORT_STATUSES = [];

  if (isDraftReport) {
    REPORT_STATUSES.push(WAIT_PAYMENT_STATUS);
  }

  if (isWaitPaymentReport) {
    REPORT_STATUSES.unshift(PAID_STATUS);
  }

  const handleChangeReportStatus = status => {
    setReportStatus(status);
    handleOpenModal();
  };

  const clientFiled = useMemo(
    () => [
      {
        title: 'common.number',
        value: formik.values.number,
        key: 1,
        type: 'text',
      },
      {
        title: 'reports.client',
        value: formik.values.client?.name,
        key: 2,
        type: 'text',
      },
      {
        title: 'reports.created',
        value: formik.values.created,
        key: 3,
        type: 'date',
      },
      {
        title: 'reports.waitPaymentAt',
        value: formik.values.waitPaymentAt,
        key: 4,
        type: 'date',
      },
      {
        title: 'reports.period',
        value: `${formatDate(
          formik.values.periodStart,
          DATE_FORMAT_WITH_SYMBOL,
        )} - ${formatDate(formik.values.periodEnd, DATE_FORMAT_WITH_SYMBOL)}`,
        key: 5,
        type: 'text',
      },
    ],
    [formik.values],
  );

  const getCurrentInvoices = (
    bills,
    { periodEnd, periodStart, countriesFormik },
  ) => {
    const invoicesFilterByDate = bills.filter(bill => {
      if (periodEnd && periodStart) {
        if (convertDateToAPI(periodEnd) === convertDateToAPI(periodStart)) {
          return (
            convertDateToAPI(periodEnd) === convertDateToAPI(bill.created_at)
          );
        }

        return (
          convertDateToAPI(periodEnd) >= convertDateToAPI(bill.created_at) &&
          convertDateToAPI(bill.created_at) >= convertDateToAPI(periodStart)
        );
      }

      if (periodEnd) {
        return convertDateToAPI(bill.created_at) <= convertDateToAPI(periodEnd);
      }

      if (periodStart) {
        return (
          convertDateToAPI(periodStart) <= convertDateToAPI(bill.created_at)
        );
      }

      return true;
    });

    const countries = countriesFormik?.map(country => country.value);

    if (countries && countries.length > 0) {
      return invoicesFilterByDate.filter(invoice =>
        countries?.includes(invoice.country.id),
      );
    }

    return invoicesFilterByDate;
  };

  return (
    <ShadowCard id="client" bodyStyle={{ padding: 24 }}>
      <CardTitle title="common.information" />

      <div className="d-flex flex-wrap">
        {isEditMode ? (
          <>
            {id && (
              <>
                <Col className="p-0 d-flex flex-wrap mb-4" xs={12}>
                  <ReactstrapLabel className="text-muted fs-14" xs={3} lg={2}>
                    {t('common.number')}
                  </ReactstrapLabel>

                  <Col xs={9} lg={10} className="d-flex align-items-center">
                    <Label className="m-0">{formik.values.number}</Label>
                  </Col>
                </Col>

                <Col className="p-0 d-flex flex-wrap" xs={12}>
                  <ReactstrapLabel className="text-muted fs-14" xs={3} lg={2}>
                    {t('reports.client')}
                  </ReactstrapLabel>

                  <Col xs={9} lg={10} className="d-flex align-items-center">
                    <Label className="m-0">{formik.values.client.name}</Label>
                  </Col>
                </Col>

                <hr className="my-4 w-100" />
              </>
            )}

            {!id && (
              <Col className="mb-4 d-flex flex-wrap" xs={12}>
                <Label
                  className="fs-14 d-flex align-items-center"
                  xs={3}
                  lg={2}
                >
                  {t('reports.client')}
                </Label>

                <Col xs={9} lg={6}>
                  <SelectClients
                    placeholderText={t('reports.clientPlaceholder')}
                    value={formik.values.client}
                    onChange={client => {
                      formik.setFieldValue('client', client);
                      formik.setFieldValue('invoices', []);
                    }}
                    invalid={formik.errors.client && formik.touched.client}
                    placeholder={t('reports.clientPlaceholder')}
                  />
                </Col>
              </Col>
            )}

            <Col className="mb-4 d-flex flex-wrap" xs={12}>
              <Label className="fs-14 d-flex align-items-center" xs={3} lg={2}>
                {t('reports.countries')}
              </Label>

              <Col xs={9} lg={6}>
                <SelectCountry
                  isMulti
                  isDisabled={!formik.values.client}
                  value={formik.values.countries}
                  countries={countries}
                  invalid={formik.errors.countries}
                  placeholder={t('reports.countriesPlaceholder')}
                  onChange={countries => {
                    formik.setFieldValue('countries', countries);

                    formik.setFieldValue(
                      'invoices',
                      getCurrentInvoices(formik.values.invoices, {
                        periodEnd: formik.values.periodEnd,
                        periodStart: formik.values.periodStart,
                        countriesFormik: countries,
                      }),
                    );
                  }}
                />
              </Col>
            </Col>

            <Col className="d-flex flex-wrap" xs={12}>
              <Label className="fs-14 d-flex align-items-center" xs={3} lg={2}>
                {t('reports.period')}
              </Label>

              <Col xs={9} lg={6} className="d-flex">
                <Col xs={6} className="pe-2">
                  <StyledDatePicker
                    value={formik.values.periodStart}
                    selected={formik.values.periodStart}
                    startDate={formik.values.periodStart}
                    maxDate={formik.values.periodEnd}
                    endDate={formik.values.periodEnd}
                    selectsStart
                    invalid={formik.errors.periodStart}
                    isClearable={formik.values.periodStart}
                    onChange={date => {
                      formik.setFieldValue('periodStart', date);

                      formik.setFieldValue(
                        'invoices',
                        getCurrentInvoices(formik.values.invoices, {
                          periodEnd: formik.values.periodEnd,
                          periodStart: date,
                          countriesFormik: formik.values.countries,
                        }),
                      );
                    }}
                    placeholderText={t('reports.dateFrom')}
                  />
                </Col>
                <Col xs={6} className="ps-2">
                  <StyledDatePicker
                    value={formik.values.periodEnd}
                    selected={formik.values.periodEnd}
                    minDate={formik.values.periodStart}
                    startDate={formik.values.periodStart}
                    endDate={formik.values.periodEnd}
                    invalid={formik.errors.periodEnd}
                    isClearable={formik.values.periodEnd}
                    selectsEnd
                    onChange={date => {
                      formik.setFieldValue('periodEnd', date);
                      formik.setFieldValue(
                        'invoices',
                        getCurrentInvoices(formik.values.invoices, {
                          periodEnd: date,
                          periodStart: formik.values.periodStart,
                          countriesFormik: formik.values.countries,
                        }),
                      );
                    }}
                    placeholderText={t('reports.dateTo')}
                  />
                </Col>
              </Col>
            </Col>
          </>
        ) : (
          <>
            {clientFiled.map(
              (field, index) =>
                field.value && (
                  <TextField
                    key={field.key}
                    field={field}
                    length={clientFiled.length}
                    index={index}
                  />
                ),
            )}

            <hr className="my-4 w-100" />

            <Col xs={12} className="d-flex">
              <Label className="mb-0" xs={3} lg={2}>
                {t('common.status')}
              </Label>

              <Col xs={3} className="d-flex align-items-center">
                {!isPaidReport ? (
                  <Select
                    options={REPORT_STATUSES}
                    getOptionValue={option => option.value}
                    getOptionLabel={option => option.label}
                    value={AGGREGATE_INVOICE_STATUSES.find(
                      ({ value }) => value === report?.status.status,
                    )}
                    classNamePrefix="select2-selection"
                    className="w-100"
                    onChange={status => handleChangeReportStatus(status)}
                  />
                ) : (
                  <Badge color="success" text={t('reports.paid')} />
                )}
              </Col>
            </Col>
          </>
        )}
      </div>
    </ShadowCard>
  );
};

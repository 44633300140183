/* USERS */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

/* USER */
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL';

export const UNBLOCK_USER = 'UNBLOCK_USER';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAIL = 'UNBLOCK_USER_FAIL';

/* SERVICES */
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAIL = 'GET_SERVICES_FAIL';

export const GET_FILTERED_SERVICES = 'GET_SERVICES_COUNTRY_NATIONALITY';
export const GET_FILTERED_SERVICES_SUCCESS = 'GET_FILTERED_SERVICES_SUCCESS';
export const GET_FILTERED_SERVICES_FAIL = 'GET_FILTERED_SERVICES_FAIL';

/* SINGLE SERVICES */
export const GET_SERVICE = 'GET_SERVICE';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAIL = 'GET_SERVICE_FAIL';

export const UPDATE_SERVICES = 'UPDATE_SERVICES';
export const UPDATE_SERVICES_SUCCESS = 'UPDATE_SERVICES_SUCCESS';
export const UPDATE_SERVICES_FAIL = 'UPDATE_SERVICES_FAIL';

export const CREATE_SERVICES = 'CREATE_SERVICES';
export const CREATE_SERVICES_SUCCESS = 'CREATE_SERVICES_SUCCESS';
export const CREATE_SERVICES_FAIL = 'CREATE_SERVICES_FAIL';

/* LOGS */
export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_FAIL = 'GET_LOGS_FAIL';

/* ORGANIZATIONS */

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAIL = 'GET_ORGANIZATIONS_FAIL';

export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAIL = 'GET_ORGANIZATION_FAIL';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL';

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL';

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'UPLOAD_DOCUMENT_FAIL';

export const ATTACH_MANAGER_TO_ORGANIZATION = 'ATTACH_MANAGER_TO_ORGANIZATION';

/* ORDERS */
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

export const GET_ORDERS_FEED = 'GET_ORDERS_FEED';
export const GET_ORDERS_FEED_SUCCESS = 'GET_ORDERS_FEED_SUCCESS';
export const GET_ORDERS_FEED_FAIL = 'GET_ORDERS_FEED_FAIL';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const CREATE_ORDER_INIT = 'CREATE_ORDER_INIT';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const ORDERS_CREATE_CUSTOMER = 'ORDERS_CREATE_CUSTOMER';
export const ORDERS_CREATE_CUSTOMER_SUCCESS = 'ORDERS_CREATE_CUSTOMER_SUCCESS';
export const ORDERS_CREATE_CUSTOMER_FAIL = 'ORDERS_CREATE_CUSTOMER_FAIL';

export const ORDERS_STATUS_CHANGE = 'ORDERS_STATUS_CHANGE';
export const ORDERS_STATUS_CHANGE_SUCCESS = 'ORDERS_STATUS_CHANGE_SUCCESS';
export const ORDERS_STATUS_CHANGE_FAIL = 'ORDERS_STATUS_CHANGE_FAIL';

export const ORDERS_UPDATE_CUSTOMER = 'ORDERS_UPDATE_CUSTOMER';
export const ORDERS_UPDATE_CUSTOMER_SUCCESS = 'ORDERS_UPDATE_CUSTOMER_SUCCESS';
export const ORDERS_UPDATE_CUSTOMER_FAIL = 'ORDERS_UPDATE_CUSTOMER_FAIL';

export const ORDERS_DELETE_CUSTOMER = 'ORDERS_DELETE_CUSTOMER';
export const ORDERS_DELETE_CUSTOMER_SUCCESS = 'ORDERS_DELETE_CUSTOMER_SUCCESS';
export const ORDERS_DELETE_CUSTOMER_FAIL = 'ORDERS_DELETE_CUSTOMER_FAIL';

/* USERS PROFILE */
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

/* Applicants */
export const GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST =
  'GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST';
export const GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_SUCCESS =
  'GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_SUCCESS';
export const GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_FAIL =
  'GET_OPERATOR_APPLICANTS_ORGANIZATION_LIST_FAIL';

export const GET_OPERATOR_APPLICANTS_USER_LIST =
  'GET_OPERATOR_APPLICANTS_USER_LIST';
export const GET_OPERATOR_APPLICANTS_USER_LIST_SUCCESS =
  'GET_OPERATOR_APPLICANTS_USER_LIST_SUCCESS';
export const GET_OPERATOR_APPLICANTS_USER_LIST_FAIL =
  'GET_OPERATOR_APPLICANTS_USER_LIST_FAIL';

export const OPERATOR_ADD_APPLICANT = 'OPERATOR_ADD_APPLICANT';
export const OPERATOR_ADD_APPLICANT_SUCCESS = 'OPERATOR_ADD_APPLICANT_SUCCESS';
export const OPERATOR_ADD_APPLICANT_FAIL = 'OPERATOR_ADD_APPLICANT_FAIL';

export const CLEAN_ADD_APPLICANT_STATE = 'CLEAN_ADD_APPLICANT_STATE';

export const OPERATOR_GET_DELIVERY_METHODS = 'OPERATOR_GET_DELIVERY_METHODS';
export const OPERATOR_GET_DELIVERY_METHODS_SUCCESS =
  'OPERATOR_GET_DELIVERY_METHODS_SUCCESS';
export const OPERATOR_GET_DELIVERY_METHODS_FAIL =
  'OPERATOR_GET_DELIVERY_METHODS_FAIL';
export const OPERATOR_CHANGE_APPLICANT = 'OPERATOR_CHANGE_APPLICANT';

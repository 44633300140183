import { Loader, ShadowCard } from 'components/Common';
import React, { useEffect, useState } from 'react';
import { StyledScrollableDiv } from './ChatsCard';
import { useDispatch, useSelector } from 'react-redux';
import { getChatDetail, getMessages, getMoreMessages } from 'store/actions';
import { getActiveChatState } from './ChatsCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ChatRoomHeader } from './ChatRoomHeader';
import { FIRST_PAGE } from 'common/data/constants';
import { ChatMessage } from './ChatMessage';
import { ChatMessageBox } from './ChatMessageBox';
import { useTranslation } from 'react-i18next';

export const getCurrentChatState = state => state.chat.selectedChat;

export const ChatRoomCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(FIRST_PAGE);
  const activeChat = useSelector(getActiveChatState);
  const { messages, isLoading } = useSelector(getCurrentChatState);

  useEffect(() => {
    if (activeChat) {
      dispatch(getChatDetail({ id: activeChat }));
      dispatch(
        getMessages({
          chatId: activeChat,
          params: { page: FIRST_PAGE, page_size: 20, ordering: '-pk' },
        }),
      );
    }

    setPage(FIRST_PAGE);
  }, [activeChat]);

  return (
    <ShadowCard
      className="h-100"
      bodyStyle={{
        padding: '16px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div className="h-100">
        <ChatRoomHeader isLoading={isLoading} />

        <StyledScrollableDiv
          id="scrollableDiv"
          style={{ height: '69vh', maxHeight: '69vh', paddingRight: 5 }}
          className="w-100 h-100 d-flex flex-column-reverse "
        >
          {!activeChat && !isLoading && (
            <div className="d-flex w-100 h-100 flex-column align-items-center text-muted justify-content-center">
              <i style={{ fontSize: 50 }} className="bx bx-message-x" />
              {t('chat.selectChat')}
            </div>
          )}

          {isLoading && (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <Loader isLoading={isLoading} />
            </div>
          )}

          <InfiniteScroll
            dataLength={messages.messages.length}
            next={() => {
              setPage(prev => prev + 1);
              dispatch(
                getMoreMessages({
                  chatId: activeChat,
                  params: { page: page + 1, page_size: 20, ordering: '-pk' },
                }),
              );
            }}
            hasMore={messages.next && activeChat}
            loader={
              <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <Loader isLoading />
              </div>
            }
            scrollableTarget="scrollableDiv"
            inverse
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
          >
            {!isLoading &&
              messages.messages?.map((message, index) => (
                <ChatMessage
                  className={index === messages.count - 1 ? 'mt-4' : ''}
                  message={message}
                  nextMessage={
                    messages.messages[index === 0 ? index : index + 1]
                  }
                  key={index}
                />
              ))}
          </InfiniteScroll>
        </StyledScrollableDiv>
      </div>

      <ChatMessageBox disabled={isLoading || !activeChat} />
    </ShadowCard>
  );
};

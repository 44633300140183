import classNames from 'classnames';
import { isUserEmployee } from 'helpers/jwt_helper';
import { useMatchMedia } from 'hooks/useMatchMedia';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  border: 0;

  i {
    margin-right: ${({ theme }) => theme.spacing.s};
    font-size: 18px;
  }
`;

export const StyledClose = styled(StyledButton)`
  padding: 12px 50px !important;
  color: ${({ theme }) => theme.colors.mainText} !important;
  background-color: ${({ theme }) => theme.colors.transparent};

  &:hover {
    color: ${({ theme }) => theme.colors.mainText} !important;
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  ${({ theme, $type }) =>
    $type &&
    css`
      background-color: ${theme.colors.gray200};

      &:hover {
        background-color: ${theme.colors.gray400};
      }
    `}
`;

export const StyledCreate = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.green500}!important;
  padding: 12px 56px ${({ $customPadding }) => !$customPadding && '!important'};
  color: ${({ theme }) => theme.colors.white}!important;

  @media (max-width: 766px) {
    padding-right: 4px;
    padding-left: 4px;
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.gray600}!important;
      background-color: ${theme.colors.gray200}!important;
    `}
`;

export const StyledDiv = styled.div`
  margin-top: -20px;

  @media (max-width: 766px) {
    margin-top: 1rem;
  }
`;

export const HeadControlButtons = ({
  path,
  handleSubmit,
  isDisabledButton,
  extraButton,
  title = '',
  typeCancel,
  handleCancel,
  cancelText = 'common.cancel',
  hasTick,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { isMobile } = useMatchMedia();

  return (
    <StyledDiv
      className={classNames(
        'd-flex w-100 ',
        isMobile
          ? 'justify-content-start mt-1'
          : 'justify-content-end align-items-center',
      )}
    >
      <StyledClose
        $type={typeCancel}
        onClick={() => {
          if (handleCancel) {
            handleCancel();
            return;
          }
          push(path);
        }}
        className="btn me-3"
      >
        <span>
          {typeof cancelText === 'object' ? cancelText : t(cancelText)}
        </span>
      </StyledClose>

      <StyledCreate
        disabled={isDisabledButton}
        onClick={handleSubmit}
        className="d-flex"
      >
        {hasTick && <i className="bx bx-check fs-5 me-2" />}
        <span>
          {id || isUserEmployee
            ? t('common.save')
            : `${t('common.create')} ${t(title)}`}
        </span>
      </StyledCreate>

      {extraButton}
    </StyledDiv>
  );
};

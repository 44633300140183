import * as React from 'react';

import { connect } from 'react-redux';
import { getCountries } from '../../store/references/actions';

import classnames from 'classnames';
import { SelectBox } from './CustomSelect';
import { memo, useMemo } from 'react';

const CountrySelect = props => {
  const { isValid = true, value, filters, placeholder } = props;

  React.useEffect(() => {
    if (!props.countries || props.countries.length === 0) {
      props.getCountries();
    }
  }, []);

  const countrySelect = value => {
    props.update(value);
  };

  const values = useMemo(() => {
    if (filters && filters.length > 0) {
      return props.countries.filter(({ id }) => filters.includes(id));
    }

    return props.countries;
  }, [props.countries]);

  return (
    <SelectBox
      isValid={isValid}
      placeholder={placeholder}
      className={classnames(['vbo-select', !isValid && 'is-invalid'])}
      value={props.countries.find(c => c.id === value) ?? null}
      classNamePrefix="select2-selection"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.country.name}
      options={values}
      onChange={countrySelect}
    />
  );
};

const mapStateToProps = state => ({
  countries: state.references.countries,
});

export default connect(mapStateToProps, { getCountries })(memo(CountrySelect));

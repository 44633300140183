import { PDF, XLS } from 'common/data/constants';
import {
  StyledButtonCancel,
  StyledButtonSave,
} from 'components/Common/StyledButtonSave';
import {
  HeadControlButtons,
  StyledDiv,
} from 'components/HeadControllButtons/HeadControllButtons';
import { StyledUpload } from 'components/Order/Tabs/ApplicantDocuments';
import { useFormikContext } from 'formik';
import { downloadAggregateInvoiceFile } from 'helpers/api-requests/admin';
import { downloadDocument } from 'helpers/downloadDocument';
import { showToastError } from 'helpers/utils/toast';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';

export const ReportHaderControl = ({
  isEditMode,
  setIsEditMode,
  handleResetInitial,
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoadFile, setIsLoadFile] = useState({
    pdf: false,
    xls: false,
  });

  const isDraftReport = useMemo(
    () => formik.values?.status?.status === 'draft'.toLowerCase(),
    [formik.values?.status?.status],
  );

  const getAggregatedInvoiceFile = async (report, typeFile) => {
    try {
      setIsLoadFile(prev => ({ ...prev, [typeFile]: true }));
      const response = await downloadAggregateInvoiceFile(report.id, typeFile, {
        responseType: 'blob',
      });
      setIsLoadFile(prev => ({ ...prev, [typeFile]: false }));
      downloadDocument(
        response,
        `${t('reports.aggregatedInvoice')} ${report.number}`,
      );
    } catch (error) {
      setIsLoadFile(prev => ({ ...prev, [typeFile]: false }));
      showToastError(error.message);
    }
  };

  return isEditMode ? (
    id ? (
      <StyledDiv className="d-flex w-100 justify-content-end align-items-center">
        <StyledButtonCancel
          style={{ padding: '12px 32px' }}
          className="btn d-flex align-items-center justify-content-center me-4"
          onClick={handleResetInitial}
        >
          {t('common.cancel')}
        </StyledButtonCancel>

        <StyledButtonSave
          style={{ padding: '12px 32px' }}
          className="d-flex align-items-center justify-content-center"
          onClick={formik.handleSubmit}
          disabled={!formik.dirty || formik.values.invoices.length === 0}
          color="primary"
        >
          {id ? t('common.save') : t('common.create')}
        </StyledButtonSave>
      </StyledDiv>
    ) : (
      <HeadControlButtons
        hasTick
        handleSubmit={formik.handleSubmit}
        isDisabledButton={!formik.dirty || formik.values.invoices.length === 0}
        cancelText={
          <div className="d-flex align-items-center d-flex flex-nowrap">
            <i className="bx bx-x fs-5 me-2" />

            <p className="m-0 align-middle fs-6">
              {id ? t('common.cancel') : t('common.close')}
            </p>
          </div>
        }
        path="/reports"
      />
    )
  ) : (
    <StyledDiv className="d-flex w-100 justify-content-end align-items-center">
      <StyledUpload
        style={{ padding: '12px' }}
        disabled={isLoadFile.pdf}
        onClick={() => getAggregatedInvoiceFile({ ...formik.values, id }, PDF)}
        className="me-4 d-flex align-items-center justify-content-center"
      >
        {isLoadFile.pdf ? (
          <Spinner
            className="me-2"
            style={{ padding: '0.75rem' }}
            color="light"
            size="sm"
            type="grow"
          >
            Loading...
          </Spinner>
        ) : (
          <i className="bx bx-download me-2 fs-4" />
        )}
        {t('reports.downloadPdf')}
      </StyledUpload>

      <StyledUpload
        style={{ padding: '12px' }}
        disabled={isLoadFile.xls}
        onClick={() => getAggregatedInvoiceFile({ ...formik.values, id }, XLS)}
        className="d-flex align-items-center justify-content-center"
      >
        {isLoadFile.xls ? (
          <Spinner
            className="me-2"
            style={{ padding: '0.75rem' }}
            color="light"
            size="sm"
            type="grow"
          >
            Loading...
          </Spinner>
        ) : (
          <i className="bx bx-download me-2 fs-4" />
        )}
        {t('reports.exportToXls')}
      </StyledUpload>

      {isDraftReport && (
        <StyledButtonSave
          style={{ padding: '12px' }}
          className="ms-4 d-flex align-items-center justify-content-center"
          onClick={() => setIsEditMode(true)}
          color="primary"
        >
          <i className="bx bx-edit-alt me-2 fs-4" />

          {t('common.edit')}
        </StyledButtonSave>
      )}
    </StyledDiv>
  );
};

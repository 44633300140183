import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { formatBytes } from './utils/files';
import cn from 'classnames';
import { DownloadLink } from '../../components/DownloadLink';

export const WizardFiles = props => {
  const { label, onFileSelect, success, url } = props;
  const [filename, setFilename] = useState('');

  const handleAcceptedFiles = files => {
    setFilename(files[0].name);
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    onFileSelect(files);
  };

  return (
    <div>
      <Dropzone
        multiple={false}
        onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={cn(['dropzone', success && 'success', 'mb-1'])}>
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="wizard-file-text">
                <div className="text-center">{label}</div>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      {url && filename && (
        <div>
          <span>Download «{label}»: </span>
          <DownloadLink url={url} filename={filename}>
            {filename}
          </DownloadLink>
        </div>
      )}
    </div>
  );
};

import { convertDateToAPI } from 'helpers/converters';
import { values } from 'lodash';

export const optionalField = value => value || undefined;

export const getCountryValue = country => {
  if (typeof country === 'number') {
    return country;
  }

  return country.id;
};

export const getValueFromAPI = value => value ?? '';

export const convertFromAPI = value => {
  const neededFields = [
    'name',
    'country',
    'city',
    'state',
    'postal_code',
    'address1',
  ];

  return value
    ? Object.entries(value).reduce((acc, [key, value]) => {
        if (neededFields.includes(key)) {
          return {
            ...acc,
            [key]: getValueFromAPI(
              key === 'country' ? getCountryValue(value) : value,
            ),
          };
        }
        return acc;
      }, {})
    : {};
};

export const toAddressForm = value => {
  const neededFields = [
    'name',
    'country',
    'city',
    'state',
    'postal_code',
    'address1',
  ];

  if (Object.keys(value).length === 0) {
    return undefined;
  }

  if (values(value).filter(Boolean).length === 0) {
    return undefined;
  }

  const addres = Object.entries(value).reduce((acc, [key, value]) => {
    if (neededFields.includes(key)) {
      return {
        ...acc,
        [key]: optionalField(
          key === 'country' ? getCountryValue(value) : value,
        ),
      };
    }

    return acc;
  }, {});

  if (Object.keys(addres).length === 0) {
    return undefined;
  }

  return addres;
};

export const toOrganizationForm = values => ({
  ...values,
  contact_name: optionalField(values.contact_name),
  contact_job_title: optionalField(values.contact_job_title),
  phone: optionalField(values.phone),
  email: optionalField(values.email),
  address: values?.address ? toAddressForm(values?.address) : undefined,
  tariff: values.tariff.value,
});

export const toIndividualForm = values => ({
  ...values,
  email: optionalField(values.email),
  phone: optionalField(values.phone),
  address: values?.address ? toAddressForm(values?.address) : undefined,
  tariff: values.tariff.value,
});

export const toApplicantModalForm = values => ({
  ...values,
  birthday: new Date(values.birthday),
  nationality: values?.nationality?.id,
  passports: values.passports.map(passport => ({
    ...passport,
    country: passport.country.id,
    number: passport.number,
    issue_date: new Date(passport.issue_date),
    end_date: new Date(passport.end_date),
  })),
});

const convertVisaToAPI = visa => ({
  ...visa,
  country: optionalField(visa.country),
  started_at: optionalField(convertDateToAPI(visa.started_at)),
  ended_at: optionalField(convertDateToAPI(visa.ended_at)),
});

export const convertVisasToAPI = visas => {
  const visasToAPI = visas?.map(visa =>
    Object.values(convertVisaToAPI(visa)).some(visaVal => !visaVal)
      ? undefined
      : convertVisaToAPI(visa),
  );

  return visasToAPI.filter(visa => visa);
};

export const convertApplicantForm = values => ({
  ...values,
  birthday: convertDateToAPI(values.birthday),
  passports: values.passports.map(passport => ({
    ...passport,
    country: passport.country,
    number: passport.number,
    issue_date: convertDateToAPI(passport.issue_date),
    end_date: convertDateToAPI(passport.end_date),
  })),
});

import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as adminApi from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from '../../helpers/utils/toast';
import {
  createAggregatedInvoice,
  deleteAggregatedInvoice,
  deleteInvoiceFromAggregate,
  getAggregatedInvoice,
  getAggregatedInvoices,
  getCandidateInvoice,
  updateAggregatedInvoice,
  updateStatusAggregatedInvoice,
} from './actions';
import i18n from 'i18n';

function* getAggregatedInvoicesSaga({ payload }) {
  try {
    const response = yield call(adminApi.fetchAggregatedInvoices, payload);
    if (response.success) {
      yield put(
        getAggregatedInvoices.success({
          invoices: response.data.results,
          count: response.data.count,
        }),
      );
    } else {
      showToastError(response.message);
      yield put(getAggregatedInvoices.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(getAggregatedInvoices.failure());
  }
}

function* getAggregatedInvoiceSaga({ payload }) {
  try {
    const [responseReport, responseBills] = yield all([
      call(adminApi.fetchAggregatedInvoice, payload),
      call(adminApi.getOperatorInvoices, {
        aggregated_bill: payload,
        page_size: 1000,
        ordering: '-created_at',
        page: 1,
      }),
    ]);

    if (responseReport.success && responseBills.success) {
      yield put(
        getAggregatedInvoice.success({
          ...responseReport.data,
          bills: responseBills.data.results,
        }),
      );
    } else {
      showToastError(responseReport.message);
      yield put(getAggregatedInvoice.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(getAggregatedInvoice.failure());
  }
}

function* deleteAggregatedInvoiceSaga({ payload }) {
  try {
    const response = yield call(adminApi.deleteAggregatedInvoice, payload);
    if (response.success) {
      yield put(deleteAggregatedInvoice.success(payload));
      showToastSuccess(response.message);
    } else {
      showToastError(response.message);
      yield put(deleteAggregatedInvoice.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(deleteAggregatedInvoice.failure());
  }
}

function* createAggregatedInvoiceSaga({ payload }) {
  try {
    const response = yield call(
      adminApi.postAggregatedInvoice,
      payload.invoice,
    );
    if (response.success) {
      yield put(createAggregatedInvoice.success(response.data));
      showToastSuccess(i18n.t('notifications.reportCreate'));
      payload.push(`/reports/${response.data.id}`);
    } else {
      showToastError(response.message);
      yield put(createAggregatedInvoice.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(createAggregatedInvoice.failure());
  }
}

function* updateStatusAggregatedInvoiceSaga({ payload }) {
  try {
    const response = yield call(
      adminApi.updateStatusAggregatedInvoice,
      payload,
    );
    if (response.success) {
      yield put(updateStatusAggregatedInvoice.success(response.data.status));
      showToastSuccess(i18n.t('notifications.reportChangeStatus'));
    } else {
      showToastError(response.message);
      yield put(updateStatusAggregatedInvoice.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(updateStatusAggregatedInvoice.failure());
  }
}

function* getInvoicesCandidateSaga({ payload }) {
  try {
    const response = yield call(adminApi.getInvoicesForAggregate, payload);
    if (response.success) {
      yield put(
        getCandidateInvoice.success({
          invoices: response.data.results,
          count: response.data.count,
          countries: response.data.valid_countries,
          ordering: '-created_at',
        }),
      );
    } else {
      showToastError(response.message);
      yield put(getCandidateInvoice.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(getCandidateInvoice.failure());
  }
}

function* updateAggregatedInvoiceSaga({ payload }) {
  try {
    const response = yield call(
      adminApi.updateAggregatedInvoice,
      payload.id,
      payload.invoice,
    );
    if (response.success) {
      yield put(updateAggregatedInvoice.success(response.data));
      showToastSuccess(i18n.t('notifications.reportUpdated'));
      payload.closeEdit();
    } else {
      showToastError(response.message);
      yield put(updateAggregatedInvoice.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(updateAggregatedInvoice.failure());
  }
}

function* deleteInvoiceFromAggregateSaga({ payload }) {
  try {
    const response = yield call(
      adminApi.deleteInvoiceFromAggregate,
      payload.id,
      payload.invoiceId,
    );
    if (response.success) {
      yield put(deleteInvoiceFromAggregate.success(payload.invoiceId));
      showToastSuccess('Invoice deleted');
    } else {
      showToastError(response.message);
      yield put(deleteInvoiceFromAggregate.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(deleteInvoiceFromAggregate.failure());
  }
}

function* aggregatedSaga() {
  yield takeEvery(getAggregatedInvoices, getAggregatedInvoicesSaga);
  yield takeEvery(getAggregatedInvoice, getAggregatedInvoiceSaga);
  yield takeEvery(deleteAggregatedInvoice, deleteAggregatedInvoiceSaga);
  yield takeEvery(createAggregatedInvoice, createAggregatedInvoiceSaga);
  yield takeEvery(
    updateStatusAggregatedInvoice,
    updateStatusAggregatedInvoiceSaga,
  );
  yield takeEvery(getCandidateInvoice, getInvoicesCandidateSaga);
  yield takeEvery(updateAggregatedInvoice, updateAggregatedInvoiceSaga);
  yield takeEvery(deleteInvoiceFromAggregate, deleteInvoiceFromAggregateSaga);
}

export default aggregatedSaga;

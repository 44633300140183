import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from 'pages/Authentication/Login';
import Logout from 'pages/Authentication/Logout';
import Register from 'pages/Registration/Registration';
import ForgetPwd from 'pages/Authentication/ForgetPasswordFormik';
import ResetPasswordPage from 'pages/Authentication/ResetPassword';

import Logs from 'pages/Logs/Logs';
import Users from 'pages/Users';
import Services from 'pages/Services/Services';
import Orders from 'pages/Orders/Orders';
import ExactService from 'pages/Service/Service';
import Profile from 'pages/Profile/Profile';
import WizardComponent from 'pages/Wizards/Wizard';
import InviteRegistration from 'pages/Authentication/InviteRegistration';
import Invoice from 'pages/Invoices/Invoice';
import Invoices from 'pages/Invoices/Invoices';
import WizardCountryComponent from 'pages/Wizards/WizardCountry';
import PaymentsAbort from 'pages/PaymentsAbort';
import PaymentsSuccess from 'pages/PaymentsSuccess';
import Clients from 'pages/Clients/Clients';
import Client from 'pages/Clients/Client';
import SetPassword from 'pages/Authentication/SetPassword';
import Deliveries from 'pages/Deliveries/Deliveries';
import Delivery from 'pages/Deliveries/Delivery';
import Tariffs from 'pages/Tariffs/Tariffs';
import Tariff from 'pages/Tariff/Tariff';
import User from 'pages/User/User';
import Order from 'pages/Order/Order';
import AccountingCodes from 'pages/AccountingCodes/AccountingCodes';

import { AggregatedInvoices } from 'pages/AggregatedInvoices/AggregatedInvoices';
import { Report } from 'pages/Report/Report';
import { ConfirmEmail } from 'pages/Authentication/ConfirmEmail';
import { ChatNew } from 'pages/Chat_new/Chat';
import { OrdersFeedComponent } from 'components/OrdersFeed';
import { Applicants } from 'pages/Applicants/Applicants';
import { Applicant } from 'pages/Applicant/Applicant';
import { Documents } from 'pages/Documents/Documents';
import OrderTypes from 'pages/OrderTypes/OrderTypes';
import OrderType from 'pages/OrderType/OrderType';
import OrderTypeDocuments from 'pages/OrderType/OrderTypeDoucments';

const adminRoutes = [
  { path: '/users', component: Users },
  { path: '/users/create', component: User },
  { path: '/users/:id', component: User },
  { path: '/logs/:id', component: Logs },
  { path: '/orders', component: Orders },
  { path: '/order/feed', component: OrdersFeedComponent },
  { path: '/orders/create', component: Order },
  { path: '/orders/:id', component: Order },
  { path: '/orderTypes', component: OrderTypes },
  { path: '/orderTypes/:id', component: OrderType },
  { path: '/orderTypes/:id/documents', component: OrderTypeDocuments },
  {
    path: '/orderTypes/:id/country/:countryId/documents',
    component: OrderTypeDocuments,
  },
  {
    path: '/orderTypes/:id/country/:countryId/visa_type/:visaTypeId/documents',
    component: OrderTypeDocuments,
  },
  { path: '/services', component: Services },
  {
    path: '/services/create/:service_type?/:copyingService?',
    component: ExactService,
  },
  { path: '/services/:id/:service_type?', component: ExactService },
  { path: '/tariffs', component: Tariffs },
  { path: '/tariffs/create', component: Tariff },
  { path: '/tariffs/:id', component: Tariff },
  { path: '/profile', component: Profile },
  { path: '/reports/create', component: Report },
  { path: '/reports/:id', component: Report },
  { path: '/reports', component: AggregatedInvoices },
  { path: '/invoices', component: Invoices },
  { path: '/invoices/:id', component: Invoice },
  { path: '/chats', component: ChatNew },
  { path: '/wizard', component: WizardComponent },
  { path: '/wizard/start', component: WizardCountryComponent },
  { path: '/payments/abort', component: PaymentsAbort },
  { path: '/payments/success', component: PaymentsSuccess },
  { path: '/clients', component: Clients },
  { path: '/clients/create', component: Client },
  { path: '/clients/:id/:client_type?', component: Client },
  { path: '/deliveries', component: Deliveries },
  { path: '/deliveries/:id', component: Delivery },
  { path: '/export', component: AccountingCodes },
  { path: '*', exact: true, component: () => <Redirect to="/users" /> },
];

const operatorRoutes = [
  { path: '/orders', component: Orders },
  { path: '/order/feed', component: OrdersFeedComponent },
  { path: '/orders/create', component: Order },
  { path: '/orders/:id', component: Order },
  { path: '/profile', component: Profile },
  { path: '/invoices', component: Invoices },
  { path: '/invoices/:id', component: Invoice },
  { path: '/reports/create', component: Report },
  { path: '/reports/:id', component: Report },
  { path: '/reports', component: AggregatedInvoices },
  { path: '/chats', component: ChatNew },
  { path: '/wizard', component: WizardComponent },
  { path: '/wizard/start', component: WizardCountryComponent },
  { path: '/payments/abort', component: PaymentsAbort },
  { path: '/payments/success', component: PaymentsSuccess },
  { path: '/clients', component: Clients },
  { path: '/clients/create', component: Client },
  { path: '/clients/:id/:client_type?', component: Client },
  { path: '/deliveries', component: Deliveries },
  { path: '/deliveries/:id', component: Delivery },
  { path: '*', exact: true, component: () => <Redirect to="/orders" /> },
];

const managerRoutes = [
  { path: '/users', component: Users },
  { path: '/orders', component: Orders },
  { path: '/orders/create', component: Order },
  { path: '/orders/:id', component: Order },
  { path: '/chats', component: ChatNew },
  { path: '/profile', component: Profile },
  { path: '/payments/abort', component: PaymentsAbort },
  { path: '/payments/success', component: PaymentsSuccess },
  { path: '/applicants/create', component: Applicant },
  { path: '/applicants/:id', component: Applicant },
  { path: '/applicants', component: Applicants },
  { path: '*', exact: true, component: () => <Redirect to="/orders" /> },
];

const userRoutes = [
  { path: '/profile', component: Profile },
  { path: '/orders', component: Orders },
  { path: '/orders/create', component: Order },
  { path: '/orders/:id', component: Order },
  { path: '/payments/abort', component: PaymentsAbort },
  { path: '/payments/success', component: PaymentsSuccess },
  { path: '/applicants/create', component: Applicant },
  { path: '/applicants/:id', component: Applicant },
  { path: '/applicants', component: Applicants },
  { path: '/invoices', component: Invoices },
  { path: '/invoices/:id', component: Invoice },
  { path: '*', exact: true, component: () => <Redirect to="/orders" /> },
];

const employeeRoutes = [
  { path: '/profile', component: Profile },
  { path: '/applicant', component: Applicant },
  { path: '/documents', component: Documents },
  { path: '/orders', component: Orders },
  { path: '/orders/create', component: Order },
  { path: '/orders/:id', component: Order },
  { path: '*', exact: true, component: () => <Redirect to="/applicant" /> },
];

const anonymousRoutes = [
  { path: '*', exact: true, component: () => <Redirect to="/login" /> },
];

const getAuthProtectedRoutes = userRole => {
  // if (isOperatorAdmin(userRole)) {
  //   return adminRoutes;
  // }
  switch (userRole) {
    case 'admin':
      return adminRoutes;
    case 'operator':
      return operatorRoutes;
    case 'manager':
      return managerRoutes;
    case 'individual':
      return userRoutes;
    case 'employee':
      return employeeRoutes;
    default:
      return anonymousRoutes;
  }
};

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login/username/:username', component: Login },
  { path: '/login', component: Login },

  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', exact: true, component: Register },
  {
    path: '/invitation/confirm/:token/password/',
    exact: true,
    component: SetPassword,
  },
  { path: '/email/confirm/:token/username/:username', component: ConfirmEmail },
  { path: '/password/reset/:token', component: ResetPasswordPage },
  { path: '/invitation/confirm/:id', component: InviteRegistration },
];

export { getAuthProtectedRoutes, publicRoutes };

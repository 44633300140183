import React, { useState } from 'react';

// Redux
import { useHistory, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { REGISTRATION_VALIDATOR } from 'forms/validators';
import { useDispatch } from 'react-redux';
import { registerUser } from 'store/actions';
import { ShadowCard } from 'components/Common';
import NonAuthLayout from 'components/NonAuthLayout';
import { StepRegistrationForm } from 'pages/Registration/StepRegistrationForm';

const initialValues = {
  email: '',
  username: '',
  password: '',
  verifyPassword: '',
  first_name: '',
  last_name: '',
  additional_name: '',
  termOfuse: false,
};

const registrationSteps = {
  names: {
    title: 'auth.confirmInformation',
    nextStep: 'email',
    inputs: [
      {
        type: 'text',
        name: 'first_name',
        key: 1.1,
        label: 'auth.firstName',
        placeholder: 'auth.enterFirstName',
      },
      {
        type: 'text',
        name: 'last_name',
        key: 1.2,
        label: 'auth.lastName',
        placeholder: 'auth.enterLastName',
      },
      {
        type: 'text',
        name: 'additional_name',
        key: 1.3,
        label: 'auth.additionalName',
        placeholder: 'auth.enterAdditionalName',
      },
    ],
  },
  email: {
    title: 'auth.emailAndUserName',
    prevStep: 'names',
    nextStep: 'password',
    inputs: [
      {
        type: 'text',
        name: 'email',
        key: 2.1,
        label: 'auth.email',
        placeholder: 'auth.enterEmail',
      },
      {
        type: 'text',
        name: 'username',
        key: 2.2,
        label: 'auth.username',
        placeholder: 'auth.enterUserName',
      },
    ],
  },
  password: {
    prevStep: 'email',
    title: 'auth.createPassword',
    inputs: [
      {
        type: 'password',
        name: 'password',
        key: 3.1,
        label: 'auth.password',
        placeholder: 'auth.passwordPlaceholder',
      },
      {
        type: 'password',
        name: 'verifyPassword',
        key: 3.2,
        label: 'auth.repeatPassword',
        placeholder: 'auth.passwordPlaceholder',
      },
    ],
  },
};

const currentValidationSchema = {
  names: REGISTRATION_VALIDATOR.names,
  email: REGISTRATION_VALIDATOR.email,
  password: REGISTRATION_VALIDATOR.password,
};

const InviteRegistration = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { push } = useHistory();

  const [registrationStep, setRegistrationStep] = useState('names');

  const handleSubmit = values => {
    if (registrationStep === 'password') {
      dispatch(
        registerUser({
          user: { ...values, invitation_token: id },
          push,
        }),
      );
      return;
    }

    setRegistrationStep(registrationSteps[registrationStep].nextStep);
    formik.setErrors({});
    formik.setTouched({});
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: currentValidationSchema[registrationStep],
    onSubmit: handleSubmit,
  });

  return (
    <NonAuthLayout>
      <FormikProvider value={formik}>
        <ShadowCard className="w-100" bodyStyle={{ padding: 48 }}>
          <StepRegistrationForm
            registrationSteps={registrationSteps}
            registrationStep={registrationStep}
            setRegistrationStep={setRegistrationStep}
          />
        </ShadowCard>
      </FormikProvider>
    </NonAuthLayout>
  );
};

export default InviteRegistration;

export const createQueryActions = actionName => {
  const queryActionName = `${actionName}/request`;
  const successActionName = `${actionName}/success`;
  const failureActionName = `${actionName}/failure`;

  const query = request => ({
    type: queryActionName,
    payload: request,
  });

  query.toString = () => queryActionName;

  query.success = response => ({
    type: successActionName,
    payload: response,
  });

  query.success.toString = () => successActionName;

  query.failure = error => ({
    type: failureActionName,
    error,
  });

  query.failure.toString = () => failureActionName;

  return query;
};

import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  VERIFY_RESULT,
} from './actionTypes';

const initialState = {
  error: '',
  loading: false,
  isAuth: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT_USER:
      return state;
    case LOGOUT_USER_SUCCESS:
      return state;
    case API_ERROR:
      return { ...state, error: action.payload, loading: false };
    case VERIFY_RESULT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default login;

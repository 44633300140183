import { useDispatch } from 'react-redux';
import { openOrderAlert } from 'store/order/actions';

export const useOrderAlert = () => {
  const dispatch = useDispatch();
  const openAlert = ({ message, type }) =>
    dispatch(openOrderAlert({ message, type }));

  return { openAlert };
};

import React, { useEffect, useState } from 'react';
import { Col, Row, Label } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { changeInvoiceStatus } from 'store/actions';
import { formatDate } from 'helpers/formatDate';
import {
  INVOICE_STATUSES,
  DATE_FORMAT_WITH_SYMBOL,
} from 'common/data/constants';
import { ChangeStatus } from 'components/ChangeStatus/ChangeStatus';
import { BlueLink } from 'components/Common';
import { useTranslation } from 'react-i18next';
import { isUserIndividual } from 'helpers/jwt_helper';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';

// export const INVOICE_STATUSES = Object.entries(INVOICE_STATUS).map(
//   ([value, label]) => ({
//     value,
//     label,
//   }),
// );

const InvoiceInfo = ({ invoiceDetails }) => {
  const { order, client } = invoiceDetails.details;
  const { t } = useTranslation();
  const [newStatus, setNewStatus] = useState(
    INVOICE_STATUSES.find(
      status => status.value === invoiceDetails.details.status,
    ),
  );

  const [isOpenStatusToPaidWarning, setIsOpenStatusToPaidWarning] =
    useState(false);

  const handleCloseStatusToPaidWarning = () => {
    setIsOpenStatusToPaidWarning(false);
  };

  const isDraftInvoice = invoiceDetails.details.status === 'draft';
  const STATUSES = isDraftInvoice
    ? INVOICE_STATUSES
    : INVOICE_STATUSES.filter(status => status.value !== 'draft');
  const dispatch = useDispatch();

  const submitUpdateStatus = status => {
    dispatch(
      changeInvoiceStatus({
        id: invoiceDetails.details.id,
        new_status: status.value,
      }),
    );
  };

  const handleChangeStatus = status => {
    if (status.value === 'paid') {
      setIsOpenStatusToPaidWarning(true);
    } else {
      submitUpdateStatus(status);
    }
  };

  const handleModalSubmit = () => {
    submitUpdateStatus(STATUSES.find(status => status.value === 'paid'));
    handleCloseStatusToPaidWarning();
  };

  useEffect(() => {
    if (invoiceDetails.details.status) {
      setNewStatus(
        INVOICE_STATUSES.find(
          status => status.value === invoiceDetails.details.status,
        ),
      );
    }
  }, [invoiceDetails.details.status]);

  return (
    <>
      {isOpenStatusToPaidWarning && (
        <ModalConfirmDelete
          handleCloseModal={handleCloseStatusToPaidWarning}
          handleDelete={handleModalSubmit}
          textHeader="invoices.setPaidStatus"
          textBody="invoices.setPaidStatusConfirmText"
          typeButtonConfirm="save"
          isOpenModal={isOpenStatusToPaidWarning}
          buttonText="order.yes"
        />
      )}
      <Row className="p-0 mb-3">
        <Col xs={6} xl={4} xxl={3}>
          <Label className="text-muted fw-normal">{t('order.client')}</Label>
        </Col>

        <Col xs={6} xl={2}>
          <Label>{client?.name}</Label>
        </Col>
      </Row>

      <Row className="p-0 mb-3">
        <Col xs={6} xl={4} xxl={3}>
          <Label className="text-muted fw-normal">
            {t('invoices.orderNumber')}
          </Label>
        </Col>

        <Col xs={6} xl={2}>
          <BlueLink
            className="fw-semibold"
            style={{ fontSize: '14px' }}
            to={`/orders/${order?.id}`}
          >
            {order?.number}
          </BlueLink>
        </Col>
      </Row>

      <Row className="p-0 mb-3">
        <Col xs={6} xl={4} xxl={3}>
          <Label className="text-muted fw-normal">{t('order.orderType')}</Label>
        </Col>

        <Col xs={6} xl={2}>
          {invoiceDetails.details?.order?.order_type}
        </Col>
      </Row>

      {!!order?.country && (
        <Row className="p-0 mb-3">
          <Col xs={6} xl={4} xxl={3}>
            <Label className="text-muted fw-normal">
              {t('invoices.destinantCountry')}
            </Label>
          </Col>

          <Col xs={6} xl={2}>
            <Label>{order?.country?.country?.name}</Label>
          </Col>
        </Row>
      )}

      <Row className="p-0 mb-3">
        <Col xs={6} xl={4} xxl={3}>
          <Label className="text-muted fw-normal">
            {t('invoices.created')}
          </Label>
        </Col>

        <Col xs={6} xl={2}>
          <Label>
            {formatDate(
              invoiceDetails.details.created_at,
              DATE_FORMAT_WITH_SYMBOL,
            )}
          </Label>
        </Col>
      </Row>
      {invoiceDetails.details.wait_payment_at && (
        <Row className="p-0 mb-3">
          <Col xs={6} xl={4} xxl={3}>
            <Label className="text-muted fw-normal">
              {t('invoices.waitPaymentAt')}
            </Label>
          </Col>

          <Col xs={6} xl={2}>
            <Label>
              {formatDate(
                invoiceDetails.details.wait_payment_at,
                DATE_FORMAT_WITH_SYMBOL,
              )}
            </Label>
          </Col>
        </Row>
      )}

      {order?.internal_number && (
        <Row className="p-0 mb-3">
          <Col xs={6} xl={4} xxl={3}>
            <Label className="text-muted fw-normal">
              {t('invoices.internalNumber')}
            </Label>
          </Col>

          <Col xs={6} xl={2}>
            <Label>{order.internal_number}</Label>
          </Col>
        </Row>
      )}

      {order?.accounting_unit && (
        <Row className="p-0 mb-3">
          <Col xs={6} xl={4} xxl={3}>
            <Label className="text-muted fw-normal">
              {t('common.accountUnit')}
            </Label>
          </Col>

          <Col xs={6} xl={2}>
            <Label>{order.accounting_unit}</Label>
          </Col>
        </Row>
      )}

      {!(isUserIndividual && newStatus.value === 'created') && (
        <>
          <Col xs={12}>
            <hr className="mt-0" />
          </Col>

          <Row className="mb-2 align-items-center p-0">
            <Col xs={6} xl={4} xxl={3}>
              <Label className="mb-0">{t('common.status')}</Label>
            </Col>

            <Col xs={6} xl={2}>
              <ChangeStatus
                statuses={STATUSES}
                className="w-100 justify-content-start"
                disabled={
                  invoiceDetails.details.status === 'paid' || isUserIndividual
                }
                onChange={handleChangeStatus}
                value={newStatus?.value}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default InvoiceInfo;

import React from 'react';
import { Link } from 'react-router-dom';
import { DATE_FORMAT, ORDER_STATUS } from '../../../common/data/constants';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'helpers/formatDate';
import { StyledTable } from 'components/Table/StyledTable';

const OrdersTable = ({ events }) => {
  const { t } = useTranslation();

  return (
    <div className="table-responsive">
      <StyledTable>
        <thead>
          <tr>
            <th>{t('User')}</th>
            <th>{t('Order')}</th>
            <th>{t('Status')}</th>
            <th>{t('Time')}</th>
          </tr>
        </thead>

        <tbody>
          {events.map(
            ({ id, order_customer, created_at, user, new_status }) => (
              <tr key={id}>
                <td>{user.full_name}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/orders/${order_customer.id}`,
                      state: { from: '/orders/feed' },
                    }}
                  >
                    {order_customer.id}
                  </Link>
                </td>
                <td>
                  {ORDER_STATUS.find(({ value }) => value === new_status).label}
                </td>
                <td>{formatDate(created_at, DATE_FORMAT)}</td>
              </tr>
            ),
          )}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default OrdersTable;

import { fetchOrder, updateOrder, createOrder } from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { showToastError } from 'helpers/utils/toast';
import {
  createOrder as createOrderApi,
  getOrder as getOrderApi,
  updateOrder as updateOrderApi,
} from 'helpers/api-requests/admin';
import i18n from 'i18n';

function* generalOrderFetch({ payload }) {
  try {
    const response = yield call(getOrderApi, payload.id);
    if (response.success) {
      yield put(fetchOrder.success(response.data));
    } else {
      yield put(fetchOrder.failure(response));
      showToastError(i18n.t('notifications.orderGetError'));
    }
  } catch (error) {
    yield put(fetchOrder.failure(error));
  }
}

function* orderUpdate({ payload }) {
  try {
    const response = yield call(updateOrderApi, {
      id: payload.id,
      order: payload.order,
    });
    if (response.success) {
      yield put(updateOrder.success(response.data));
      if (payload.redirectPath) {
        if (
          payload.redirectPath === '/invoices/' &&
          response.data.bills?.length > 0
        ) {
          const invoice = response.data.bills.at();

          return payload.push(`${payload.redirectPath}${invoice.id}`, {
            fromOrder: true,
          });
        }

        payload.push(payload.redirectPath);
      }
    } else {
      yield put(updateOrder.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(updateOrder.failure(error));
    showToastError(i18n.t('notifications.errorOrderUpdate'));
  }
}

function* orderCreate({ payload }) {
  try {
    const response = yield call(createOrderApi, payload.order);
    if (response.success) {
      yield put(updateOrder.success(response.data));
      if (payload.redirectPath) {
        if (
          payload.redirectPath === '/invoices/' &&
          response.data.bills?.length > 0
        ) {
          const invoice = response.data.bills.at();

          return payload.push(`${payload.redirectPath}${invoice.id}`, {
            fromOrder: true,
          });
        }

        payload.push(payload.redirectPath);
      } else {
        payload.push(`/orders/${response.data.id}`);
      }
    } else {
      yield put(updateOrder.failure(response));
      showToastError(response.message);
    }
  } catch (error) {
    yield put(updateOrder.failure(error));
    showToastError(i18n.t('notifications.errorOrderSave'));
  }
}

function* orderSaga() {
  yield takeEvery(fetchOrder, generalOrderFetch);
  yield takeEvery(updateOrder, orderUpdate);
  yield takeEvery(createOrder, orderCreate);
}

export default orderSaga;

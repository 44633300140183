import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Col } from 'reactstrap';
import logoSvg from 'assets/images/logo.svg';
import styled, { css } from 'styled-components';
import { MainNonAuthImage } from 'assets/images/visa';
import { useMatchMedia } from 'hooks/useMatchMedia';
import classNames from 'classnames';
import mainImage from 'assets/images/visa/icons/nonAuth.svg';
import LanguageDropdown from './CommonForBoth/TopbarDropdown/LanguageDropdown';

const StyledMainContent = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background: #f8f8fb;

  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: 15%;
      background-image: url(${mainImage});
    `}

  ${({ $isScrollable }) =>
    $isScrollable &&
    css`
      overflow-y: auto;
    `}

  @media (max-height: 500px) {
    overflow-y: auto;
  }
`;

const capitalizeFirstLetter = string =>
  string.charAt(1).toUpperCase() + string.slice(2);

const NonAuthLayout = ({ children, isScrollable }) => {
  const { pathname } = useLocation();
  const { isDesktop, isTablet, isMobile } = useMatchMedia();

  return (
    <>
      <Helmet>
        <title>{capitalizeFirstLetter(pathname)}</title>
      </Helmet>

      <StyledMainContent
        $isDesktop={isDesktop || isTablet}
        className={classNames('d-flex flex-wrap', { 'pe-5': !isMobile })}
        $isScrollable={isScrollable}
      >
        {(isDesktop || isTablet) && (
          <Col xs={7} xxl={6} className="d-flex flex-column flex-wrap pt-4">
            <div className="d-flex align-items-center">
              <img
                src={logoSvg}
                style={{ marginLeft: 54 }}
                width={368}
                className="me-2"
                height={64}
                alt="main logo visa"
              />
              <LanguageDropdown short className="mb-0 mt-1" />
            </div>

            <div className="d-flex w-100 ps-4 pe-5">
              <MainNonAuthImage className="w-100" />
            </div>
          </Col>
        )}

        {isMobile && (
          <>
            <Col
              xs={12}
              style={{ height: 60 }}
              className="pt-4 d-flex justify-content-center align-items-center"
            >
              <img src={logoSvg} width={368} height={64} alt="main logo visa" />

              <LanguageDropdown direction="button" short className="d-flex" />
            </Col>
          </>
        )}

        <Col
          className={classNames('d-flex align-items-center', [
            (isDesktop || isTablet) && 'pe-4 ps-3',
            isMobile && 'p-3',
          ])}
        >
          {children}
        </Col>
      </StyledMainContent>
    </>
  );
};

export default NonAuthLayout;

import { Layout } from 'components/Layout';
import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { ChatRoomCard } from './ChatRoomCard';
import { ChatsCard } from './ChatsCard';

const StyledChatLayout = styled.div`
  height: 88vh;
  width: 100%;
  overflow: hidden;
`;

export const ChatNew = () => {
  return (
    <Layout title="Chat">
      <StyledChatLayout>
        <Row>
          <Col xs={3}>
            <ChatsCard />
          </Col>

          <Col xs={9}>
            <ChatRoomCard />
          </Col>
        </Row>
      </StyledChatLayout>
    </Layout>
  );
};

import { Badge, ShadowCard } from 'components/Common';
import { StyledTable } from 'components/Table/StyledTable';
import { useFormikContext } from 'formik';
import { formatCurrency } from 'helpers/formatCurrency';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import styled from 'styled-components';
import { Accordion } from '../../Common/Accordion';
import { convertStatusToColor } from '../../../helpers/styles/statusToColor';
import { INVOICE_STATUS } from 'common/data/constants';
import { isUserIndividual } from 'helpers/jwt_helper';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { ModalPayment } from 'components/Common/ModalPayment';
import { useModal } from 'hooks/useModal';

const StyledLink = styled(Link)`
  text-decoration-line: underline;
  font-weight: 500;
`;

export const InvoicesCard = () => {
  const formik = useFormikContext();
  const { id } = useParams();
  const { t } = useTranslation();
  const { isOpen, handleCloseModal, handleOpenModal } = useModal();
  const invoices = formik.values.invoices;

  const invoicesByRole = useMemo(() => {
    if (!invoices) {
      return [];
    }

    if (isUserIndividual) {
      return invoices.filter(
        invoice =>
          invoice.status === 'paid' || invoice.status === 'wait_payment',
      );
    }
    return invoices;
  }, [invoices, isUserIndividual]);

  return (
    <ShadowCard className="mt-4" bodyStyle={{ padding: '24px' }}>
      <Accordion title="sideBar.invoices" id="invoices" href="#invoices">
        {invoicesByRole && invoicesByRole.length > 0 ? (
          <div className="table-responsive">
            <StyledTable className="mt-4">
              <thead>
                <tr>
                  <th>{t('common.name')}</th>

                  <th>{t('invoices.net')}</th>

                  <th>{t('invoices.tax')}</th>

                  <th>{t('invoices.price')}</th>

                  <th>{t('common.status')}</th>

                  {isUserIndividual && <th />}
                </tr>
              </thead>

              <tbody>
                {invoicesByRole.map(invoice => (
                  <tr key={invoice.id}>
                    <td>
                      <StyledLink
                        to={{
                          pathname: `/invoices/${invoice.id}`,
                          state: { fromOrder: true },
                        }}
                      >
                        {invoice.number}
                      </StyledLink>
                    </td>

                    <td>{formatCurrency(invoice.bill_net_price_euro)}</td>

                    <td>{formatCurrency(invoice.bill_tax_total_euro)}</td>

                    <td>{formatCurrency(invoice.bill_total_price_euro)}</td>

                    <td>
                      <Badge
                        text={INVOICE_STATUS[invoice.status]}
                        color={convertStatusToColor(invoice.status)}
                      />
                    </td>

                    <td>
                      {isUserIndividual && invoice.status === 'wait_payment' && (
                        <StyledEmptyButton
                          className="d-flex align-items-center text-success"
                          onClick={() => handleOpenModal(invoice.id)}
                        >
                          <i className="bx bxs-credit-card me-2" />

                          <span className="fs-14">{t('payments.pay')}</span>
                        </StyledEmptyButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </div>
        ) : (
          <Alert className="mt-3" color="info">
            {t('invoices.noInvoices')}
          </Alert>
        )}

        {isUserIndividual && isOpen && (
          <ModalPayment
            billId={isOpen}
            orderId={id}
            handleCloseModal={handleCloseModal}
            isOpenModal={!!isOpen}
          />
        )}
      </Accordion>
    </ShadowCard>
  );
};

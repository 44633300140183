import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion as AccordionReactstrap,
} from 'reactstrap';
import styled, { css, keyframes } from 'styled-components';

const StyledAccordion = styled(AccordionReactstrap)`
  .accordion-button:not(.collapsed) {
    color: ${({ theme }) => theme.colors.mainText};
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  .accordion-item {
    border: 0;
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  .accordion-header {
    display: flex;
  }

  .accordion-button {
    padding: 0px;
    padding-bottom: 12px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.transparent};
  }

  .accordion-button:focus {
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }

  .accordion-button::after {
    background-image: none;
    width: 0px;
  }

  .accordion-body {
    padding: 0px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.mainText};
  }
`;

export const rotateOpen = keyframes`
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

export const rotateClose = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-90deg);
  }
`;

const StyledImage = styled.i`
  color: #9397a4;

  ${({ isOpen, isToggle }) =>
    isToggle
      ? isOpen
        ? css`
            animation: ${rotateOpen} 0.1s linear both;
          `
        : css`
            animation: ${rotateClose} 0.1s linear both;
          `
      : null}

  font-size: 28px;
`;

export const Accordion = ({
  children,
  title,
  href,
  extraComponentBody,
  extraComponentHeader,
  id,
  extraTitle,
  className,
}) => {
  const [isOpenAccordion, setIsOpenAccordion] = useState('1');
  const [isToggle, setIsToggle] = useState(false);
  const { t } = useTranslation();

  const handleOpenAccordion = id => {
    setIsToggle(true);
    id === isOpenAccordion ? setIsOpenAccordion('') : setIsOpenAccordion(id);
  };

  return (
    <StyledAccordion
      toggle={handleOpenAccordion}
      open={isOpenAccordion}
      className={className}
      flush
      href={href}
    >
      <AccordionItem>
        <AccordionHeader id={id} targetId="1">
          <div className="p-0 w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <StyledImage
                isOpen={isOpenAccordion}
                isToggle={isToggle}
                className="bx bx-chevron-down me-2"
              />

              <h5 className="mb-0 d-flex">
                {t(title)} {extraTitle}
              </h5>
            </div>
          </div>

          {extraComponentHeader}
        </AccordionHeader>

        {extraComponentBody}

        <AccordionBody accordionId="1">{children}</AccordionBody>
      </AccordionItem>
    </StyledAccordion>
  );
};
